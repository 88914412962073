import axios from 'axios';
import { ELASTIC_SEARCH_FIELDS } from '../constants/search';
import { parseAPIUrl } from './config';

/**
 * Verify if is a valid run without dots, with hyphen
 */
const checkIsRUN = (run) => {
  // eslint-disable-line func-names
  let rut = run.toLowerCase();
  if (rut.length < 5) {
    return false;
  }
  if (!/^[0-9]+[-|‐]{1}[0-9k]{1}$/.test(rut)) {
    return false;
  }
  const aux = rut.split('-');
  rut = aux[0]
    .split('')
    .map((element) => parseInt(element, 10))
    .reverse();
  const digv = aux[1] === 'k' ? 10 : parseInt(aux[1], 10);
  let total = 0;
  let mult = 2;
  for (let index = 0; index < rut.length; index += 1) {
    total += rut[index] * mult;
    mult = mult < 7 ? mult + 1 : 2;
  }
  let result = 11 - (total % 11);
  result = result === 11 ? 0 : result;
  return result === digv;
};

const checkIsIntGreaterOrEqualTo0 = (value) => {
  const parsedValue = parseInt(value, 10);
  return value === 0 || (!!value && parsedValue > -1);
};

const checkAtLeastOneSpecialCharacter = (password) => {
  const specialCharsRegExpExists = /.*[/!@#$%^&*)({}+=,._-]+.*/; // /!@#$%^&*)({}+=,._-
  const test = specialCharsRegExpExists.test(password);
  return test;
};

const checkAtLeastOneLetter = (password) => {
  if (!password) {
    return false;
  }
  const passStringify = `${password}`;
  const notNullString = passStringify.length > 0;
  const hastAtLeastOneLetter = /^.*[a-zA-Z]+.*$/;
  return hastAtLeastOneLetter.test(password) && notNullString;
};

const checkNotOnlyLetters = (password) => {
  const onlyLettersRegExp = /^[A-z]+$/;
  const hasOnlyLetters = !onlyLettersRegExp.test(password);
  const hastAtLeastOneLetter = checkAtLeastOneLetter(password);
  return hasOnlyLetters && hastAtLeastOneLetter;
};

const checkMixCases = (password) => {
  const onlyLettersRegExp = /^.*([a-z]+[A-Z]+|[A-Z]+[a-z]+).*$/;
  return onlyLettersRegExp.test(password);
};

const checkUpperCases = (password) => {
  const onlyLettersRegExp = /^.*([A-Z]+|[A-Z]+).*$/;
  return onlyLettersRegExp.test(password);
};

const checkLowerCases = (password) => {
  const onlyLettersRegExp = /^.*([a-z]+|[a-z]+).*$/;
  return onlyLettersRegExp.test(password);
};

const checkAtLeastOneNumber = (password) => {
  if (!password || (!Number.isInteger(password) && !Number.isInteger(parseInt(password, 10)))) {
    // exception to 0 and string with numbers that is not a integer
    if (password !== 0 && `${password}`.length < 1) return false;
  }
  const atLeastOneNumberRegExp = /.*[0-9].*/;
  return atLeastOneNumberRegExp.test(password);
};

const checkNotOnlyNumbers = (password) => {
  const onlyNumbersRegExp = /^[0-9]*$/;
  const hasAtLeastOneNumber = checkAtLeastOneNumber(password);
  return !onlyNumbersRegExp.test(password) && hasAtLeastOneNumber;
};

const atLeastSizeX = (password, n) => {
  if (!password) {
    if (password !== '' && password !== 0) {
      return false;
    }
  }
  return `${password}`.length >= n;
};

const checkValidPassword = (password) => checkNotOnlyNumbers(password)
  && checkMixCases(password)
  && checkNotOnlyLetters(password)
  && checkAtLeastOneSpecialCharacter(password)
  && atLeastSizeX(password, 8);

const scrollToElement = (element) => {
  element.scrollTo({ top: 0, behavior: 'smooth' });
};

const goToTop = () => {
  const element = document.getElementById('body');
  scrollToElement(element);
};
const isGetDetailsFavoritesSchools = (list) => {
  const hasDetails = list.map((school) => Object.keys(school).length);

  return hasDetails.find((keys) => keys > 5) === undefined;
};

const getCanonicalLink = (path = '') => {
  const url = window.location.href;
  if (path !== '') {
    return url.includes(`${path}`) ? url : `${url}${path}`;
  }
  return url;
};

const getBaseLink = (env = null) => {
  if (env === 'production') {
    return `https://${process.env.VUE_APP_SITE_URL}`;
  }
  return '';
};

const generateMetaArray = (metaArray = []) => {
  const toFilter = [null, undefined, ''];
  const result = metaArray.reduce((acc, item) => {
    const values = Object.values(item);
    if (!toFilter.some((value) => values.includes(value))) {
      acc.push(item);
    }
    return acc;
  }, []);
  return result;
};

const resetUrlFromDigitalProfile = () => {
  const urlDigitalProfile = window.location.href;
  const indexPathSchool = urlDigitalProfile.indexOf('/school');
  if (indexPathSchool !== -1) {
    const newUrl = urlDigitalProfile.slice(0, indexPathSchool);
    window.history.pushState({ path: newUrl }, '', newUrl);
  }
};

const gradeEnglish = [
  'PreK-3',
  'PreK-4',
  'Kindergarten',
  '1st Grade',
  '2nd Grade',
  '3rd Grade',
  '4th Grade',
  '5th Grade',
  '6th Grade',
  '7th Grade',
  '8th Grade',
  '9th Grade',
  '10th Grade',
  '11th Grade',
  '12th Grade',
];

const containsWhitespace = (str) => /\s/.test(str);

const getSchools = async () => {
  const schoolsWithCampusCode = [];

  if (process.env.VUE_APP_ENVIRONMENT === 'production') {
    const from = 0;
    let totalSchools = 0;
    let filterMatch = [];
    let tenant = process.env.VUE_APP_TENANT;

    if (tenant === 'palmira') {
      tenant = 'colombia';
      filterMatch = [
        {
          fieldname: ELASTIC_SEARCH_FIELDS.COMMUNE,
          fieldvalue: 'Palmira',
        },
      ];
    }

    const apiEnv = process.env.VUE_APP_API_ENVIRONMENT;
    const searchEnv = apiEnv === 'production' ? 'master' : apiEnv;

    const searchApi = axios.create({
      baseURL: parseAPIUrl({
        url: process.env.VUE_APP_ELASTIC_SEARCH_HOST || 'tether.education/search-engine/search/',
        env: process.env.VUE_APP_ELASTIC_SEARCH_ENVIRONMENT || process.env.VUE_APP_ENVIRONMENT,
      }),
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.VUE_APP_APIGATEWAY_KEY,
        'x-index': `${tenant}-campuses-${searchEnv}`,
        'User-Agent': `TetherSitemapBuilder/${process.env.VUE_APP_USER_AGENT_BYPASS_KEY}`,
      },
    });

    // eslint-disable-next-line no-inner-declarations
    async function getSchoolsCampusCode(fromSchool) {
      let partialTotal = 0;

      await searchApi
        .post('campuses/all', {
          search_from: fromSchool,
          search_size: 20000,
          fields_required: ['campus_code', 'campus_name', 'commune'],
          filter_match: filterMatch,
        })
        .then(({ data }) => {
          totalSchools += data.results.length;
          partialTotal = data.results.length;
          const formatUrlCampusCode = (campus) => {
            // This code formats the campus name for use in a URL slug.
            // It first trims any whitespace from the beginning or end of the campus name.
            // It then uses the NFD unicode normalization form to decompose combined characters into the combination
            // of simple characters. This allows us to convert accented characters into their basic latin equivalent.
            // It then uses a regex to remove any characters that are not letters or numbers.
            // Finally, it converts any remaining characters to lowercase.
            const normalizeString = (str) => str.trim()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .replace(/[^A-Z0-9]+/ig, '-')
              .toLowerCase();

            return [
              {
                code: campus.campus_code,
                name: '',
                priority: 0.5,
              },
              {
                code: campus.campus_code,
                name: normalizeString(`${campus.campus_name}-${campus.commune}`),
                priority: 1.0,
              },
            ];
          };
          // eslint-disable-next-line camelcase
          schoolsWithCampusCode.push(...data.results.flatMap((campus) => formatUrlCampusCode(campus)));
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('ERROR', error);
        });

      if (partialTotal > 19000) {
        await getSchoolsCampusCode(totalSchools + 1);
      }
    }
    await getSchoolsCampusCode(from);
  }

  return schoolsWithCampusCode;
};
export default {
  getSchools,
  isGetDetailsFavoritesSchools,
  checkIsRUN,
  checkIsIntGreaterOrEqualTo0,
  checkAtLeastOneSpecialCharacter,
  checkNotOnlyLetters,
  checkNotOnlyNumbers,
  checkValidPassword,
  checkMixCases,
  checkAtLeastOneLetter,
  checkAtLeastOneNumber,
  atLeastSizeX,
  goToTop,
  scrollToElement,
  checkLowerCases,
  checkUpperCases,
  gradeEnglish,
  containsWhitespace,
  getCanonicalLink,
  generateMetaArray,
  resetUrlFromDigitalProfile,
  getBaseLink,
};
