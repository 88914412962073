<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        :actions-tag="registerType === 1
          ? 'click_reg_quick-register-contact_back-button' : 'click_reg_detailed-register-contact_back-button'"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <RowStepper
        :mixpanel-tag="registerType === 1
          ? 'click_reg_quick-register-contact_row-stepper' : 'click_reg_detailed-register-contact_row-stepper'"
        :step="2"
      />
      <div :class="inWaitingList && !mobile ? 'deg-waiting-list--mobile' : ''">
        <ModalContent
          :content-sections="['smallTitle', 'registerInput']"
          small-title-left
          :small-title-text="
            inStudentFlow ? 'user_register.contact.title' : 'user_register.contact.legalguardian_title'
          "
          :input-section="inputContent()"
          input-text-select
          :text-select-index="1"
          :text-select-items="phoneAreaCodes"
          :error-types="['email']"
          @setValue="setInputValue"
          @setTextSelectValue="setTextValue"
          @isInvalidEmail="isInvalidEmail"
        />
        <ModalContent
          :content-sections="['subtitle', 'instructions', 'checkbox']"
          :subtitle-text="'user_register.contact.subtitle'"
          subtitle-light
          :instruction-text="'user_register.contact.instructions'"
          :checkbox-section="setCheckboxSection"
          @setCheckboxValue="setCheckboxValue"
        />
      </div>
    </div>
    <CallToActions
      :types="['main']"
      :active-value="isActive"
      :main-button-text="'user_register.continue'"
      :main-button-tag="registerType === 1
        ? 'click_reg_quick-register-contact_continue-button'
        : 'click_reg_detailed-register-contact_continue-button'"
      small
      :spaced="false"
      @continue="nextStep()"
    />
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import listCodeArea from '@/locales/code_area.json';
import { decomposePhoneNumber } from '@/utils/strings';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ContactStep',
  components: {
    ModalHeader,
    RowStepper,
    ModalContent,
    CallToActions,
  },
  props: {
    inStudentFlow: {
      type: Boolean,
      default: false,
    },
    preFill: {
      type: Boolean,
      default: false,
    },
    preFillData: {
      type: Object,
      default: () => ({}),
    },
    // 1 Quick - 2 Detailed
    registerType: {
      type: Number,
      default: null,
    },
    inWaitingList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: '',
      phone: '',
      defaultDialCode: this.$t('user_register.contact.default_dial_code'),
      dialCode: null,
      noInfoErrorSet: false,
      contactEmail: false,
      contactWsp: false,
      invalidEmail: null,
    };
  },
  computed: {
    ...mapGetters({
      authenticationPhone: 'authentication/userPhone',
      authenticationDialCode: 'authentication/userDialCode',
      authenticationEmail: 'authentication/userEmail',
    }),
    isActive() {
      const hasContactMethod = this.contactEmail || this.contactWsp;
      const hasValidEmail = this.email && !this.invalidEmail;
      return hasContactMethod && hasValidEmail;
    },
    phoneAreaCodes() {
      const dialCodes = listCodeArea.countries.map((country) => country.dial_code);
      return dialCodes;
    },
    setCheckboxSection() {
      const checkboxSection = [
        {
          text: 'user_register.contact.contact_email',
          disabledValue: this.checkboxInputValidation(this.email),
          value: this.contactEmail,
          tag: this.registerType === 1
            ? 'click_reg_quick-register-contact_email-checkbox'
            : 'click_reg_detailed-register-contact_email-checkbox',
        },
        {
          text: 'user_register.contact.contact_whatsapps',
          disabledValue: this.checkboxInputValidation(this.phone),
          value: this.contactWsp,
          tag: this.registerType === 1
            ? 'click_reg_quick-register-contact_whatsapp-checkbox'
            : 'click_reg_detailed-register-contact_whatsapp-checkbox',
        },
      ];
      return checkboxSection;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    preFill: {
      handler(value) {
        if (!value) {
          this.phone = '';
          this.email = '';
          this.contactEmail = false;
          this.contactWsp = false;
        }
      },
    },
    preFillData: {
      handler() {
        if (this.preFill) {
          this.doPreFill();
        }
      },
      deep: true,
    },
  },
  created() {
    this.dialCode = this.defaultDialCode;
    if (this.preFill) {
      this.doPreFill();
    } else {
      if (this.authenticationEmail) {
        this.email = this.authenticationEmail;
      }
      if (this.authenticationPhone) {
        this.phone = this.authenticationPhone;
      }
      if (this.authenticationDialCode) {
        this.dialCode = this.authenticationDialCode;
      }
    }
  },
  methods: {
    ...mapActions({
      setContactPreferences: 'userRegister/setContactPreferences',
    }),
    inputContent() {
      const inputContent = [
        {
          value: this.email,
          noInfoError: this.inputInfoValidation(this.email),
          placeholder: 'views.login.name.email',
          show: true,
          inputType: 'email',
        },
        {
          value: this.phone,
          noInfoError: false,
          placeholder: 'user_register.contact.placeholder_phone',
          selectValue: this.dialCode ?? this.defaultDialCode,
          inputType: 'number',
          show: true,
          tag: this.registerType === 1
            ? 'key-input_reg_quick-register-contact_phone-field'
            : 'key-input_reg_detailed-register-contact_phone-field',
        },
      ];
      return inputContent;
    },
    doPreFill() {
      if (this.preFillData && Object.keys(this.preFillData).length > 0) {
        const { countryCode, lineNumber } = decomposePhoneNumber(
          this.preFillData.phone ?? this.defaultDialCode,
          this.phoneAreaCodes,
        );
        this.email = this.preFillData.email ?? this.email;
        this.dialCode = countryCode ?? this.defaultDialCode;
        this.phone = lineNumber;
        this.contactEmail = this.preFillData.contactPreferenceEmail ?? this.contactEmail;
        this.contactWsp = this.preFillData.contactPreferenceWhatsApp ?? this.contactWsp;
      }
    },
    isInvalidEmail(bool) {
      this.invalidEmail = bool;
      return this.invalidEmail;
    },
    inputInfoValidation(value) {
      if (!value && this.noInfoErrorSet) {
        return true;
      }
      return false;
    },
    setInputValue(mainValue, passwordInput, index) {
      if (passwordInput === 0 && index === 0) {
        this.email = mainValue;
        return this.email;
      }
      if (passwordInput === 0 && index === 1) {
        this.phone = mainValue;
        if (!this.email && this.noInfoErrorSet) {
          this.noInfoErrorSet = false;
        }
        return this.phone;
      }
      return null;
    },
    setTextValue(index, value) {
      if (index) {
        this.dialCode = value;
      }
      return this.dialCode;
    },
    setCheckboxValue(index) {
      if (index === 0) {
        if (this.contactEmail) {
          this.contactEmail = false;
        } else {
          this.contactEmail = true;
          return this.contactEmail;
        }
      }
      if (index === 1) {
        if (this.contactWsp) {
          this.contactWsp = false;
        } else {
          this.contactWsp = true;
          return this.contactWsp;
        }
      }
      return null;
    },
    nextStep() {
      if (this.isActive) {
        const contactPreferences = {
          phone: this.dialCode + this.phone,
          email: this.email,
          contactPreferenceWhatsApp: this.contactWsp,
          contactPreferenceSms: false,
          contactPreferenceEmail: this.contactEmail,
        };
        this.setContactPreferences({ contactPreferences });
        this.$emit('nextStep');
      }
      if (!this.phone) {
        this.noInfoErrorSet = true;
      } else {
        this.noInfoErrorSet = false;
      }
    },
    checkboxInputValidation(value) {
      if (!value) {
        return true;
      }
      return false;
    },
  },
};
</script>
