var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search-location",class:{
    'search-location--mobile': _vm.isMobile && _vm.isInMap,
    'search-location--register': !_vm.isInMap && !_vm.isMobile,
    'search-location--mobile-register': !_vm.isInMap && _vm.isMobile,
  },style:({ '--height': _vm.height, '--height-mobile': _vm.heightMobile })},[(!_vm.isInMap)?_c('Actions',{attrs:{"mixpanel-tag":'click_reg_register-map-adjust-if-necessary_back-button',"absolute":""},on:{"back":function($event){return _vm.back()}}}):_vm._e(),_c('GmapMap',{ref:"locationMapRef",staticClass:"search-location--map",staticStyle:{"height":"100%"},style:(!_vm.isInMap ? 'position: absolute' : ''),attrs:{"center":_vm.mapCenter,"options":{
      zoom: _vm.inWelcome ? 13.5 : 16,
      disableDefaultUI: true,
      clickableIcons: false,
      draggableIcons: false,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      scaleControl: false,
      rotateControl: false,
      styles: _vm.mapStyle,
    }}},[_c('GmapCircle',{attrs:{"center":_vm.circleCenter,"radius":_vm.radius,"options":_vm.options}}),_c('GmapMarker',{attrs:{"position":_vm.markerCenter,"clickable":"","draggable":_vm.isDraggable,"icon":{
        url: require('@/assets/svg/customMarker.svg'),
        scaledSize: { width: 40, height: 40 },
      }},on:{"click":function($event){return _vm.markerClickTrack()},"dragend":function($event){return _vm.setPlace($event, true)}}})],1),(!_vm.isInMap)?_c('MapTitle',{attrs:{"text":'shared.location.title',"mixpanel-tag":'click_reg_register-map-adjust-if-necessary_helper-text'}}):_vm._e(),(_vm.inWelcome)?_c('MapTitle',{attrs:{"color":_vm.researchLocaleKeyRoot.includes('research-cl') ? '#62C7D3' : '#5627FF',"text":`shared.location.title2.${_vm.researchLocaleKeyRoot}`,"mixpanel-tag":'click_reg_register-map-adjust-if-necessary_helper-text'}}):_vm._e(),(_vm.inWelcome)?_c('div',{staticClass:"absolute right-10 top-10 flex flex-col space-y-2",staticStyle:{"z-index":"51"}},[_c('v-btn',{attrs:{"depressed":"","type":"button"},on:{"click":function($event){return _vm.goToDeviceLocation()}}},[_c('img',{attrs:{"src":require("@/assets/icons/my_location.svg"),"width":"27px","alt":"location"}})])],1):_vm._e(),(!_vm.isInMap)?_c('CenterMap',{on:{"setCenter":function($event){return _vm.setCenter()}}}):_vm._e(),(!_vm.isInMap)?_c('CallToActions',{attrs:{"types":['main'],"active-value":"","main-button-text":'user_register.continue',"main-button-tag":'click_reg_register-map-adjust-if-necessary_center-location',"small":"","is-main-absolute":""},on:{"continue":function($event){return _vm.nextStep()}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }