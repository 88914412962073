<template>
  <div class="register-main fill-width" :class="{ 'search-location--register': absolute }">
    <div class="d-flex justify-start fill-width" style="margin-bottom: 16px">
      <img
        :class="absolute ? 'search-location--back' : 'register-main--back'"
        :src="white ? require('@/assets/icons/back-arrow-white.svg') : require('@/assets/icons/back-arrow.svg')"
        alt="back"
        style="cursor: pointer"
        @click="actionClick()"
        @keydown.enter="actionClick()"
      />
    </div>
  </div>
</template>

<script>
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'HeaderActions',
  components: {},
  props: {
    absolute: {
      type: Boolean,
      default: false,
    },
    mixpanelTag: {
      type: String,
      default: null,
    },
    white: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    actionClick() {
      if (this.mixpanelTag) {
        trackMixPanel(this.mixpanelTag);
      }
      this.$emit('back');
    },
  },
};
</script>
