<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        :actions-tag="registerType === 1
          ? 'click_reg_quick-register-student-kinship_back-button'
          : 'click_reg_detailed-register-student-kinship_back-button'"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <RowStepper
        :mixpanel-tag="registerType === 1
          ? 'click_reg_quick-register-student-kinship_row-stepper'
          : 'click_reg_detailed-register-student-kinship_row-stepper'"
        :step="2"
      />
      <ModalContent
        :class="inWaitingList && !mobile ? 'deg-waiting-list--mobile' : ''"
        :content-sections="['smallTitle', 'subtitle', 'textSelect']"
        :small-title-text="'user_register.identification.student_title'"
        small-title-left
        :subtitle-text="'user_register.relationship.subtitle'"
        :subtitle-arg="Object.keys(currentStudent).length > 0 ? currentStudent.first_name : ''"
        :text-select-placeholders="'user_register.relationship.placeholder'"
        :text-select-items="relationshipOptions"
        :text-select-initial-value="relation"
        text-select-item-text="relationship_name"
        text-select-return-object
        :text-select-index="1"
        :text-select-tag="registerType === 1
          ? 'click_reg_quick-register-student-kinship_kinship-dropdown'
          : 'click_reg_detailed-register-student-kinship_kinship-dropdown'"
        :click-text-select-tag="registerType === 1
          ? 'click_reg_quick-register-student-kinship_kinship-dropdown-selector'
          : 'click_reg_detailed-register-student-kinship_kinship-dropdown-selector'"
        @setTextSelectValue="setValue"
      />
    </div>
    <CallToActions
      :types="['main']"
      active-value
      :main-button-text="'user_register.continue'"
      :main-button-tag="registerType === 1
        ? 'click_reg_quick-register-student-kinship_continue-button'
        : 'click_reg_detailed-register-student-kinship_continue-button'"
      small
      :spaced="false"
      @continue="nextStep()"
    />
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'RelationshipModal',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
    RowStepper,
  },
  props: {
    preFill: {
      type: Boolean,
      default: false,
    },
    preFillData: {
      type: Object,
      default: () => ({}),
    },
    // 1 Quick - 2 Detailed
    registerType: {
      type: Number,
      default: null,
    },
    inWaitingList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      relation: null,
    };
  },
  computed: {
    ...mapGetters({
      currentStudent: 'authentication/currentStudent',
      relationshipOptions: 'options/relationshipOptions',
    }),
    isActive() {
      return !!this.relation;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    preFillData: {
      handler() {
        if (this.preFill) {
          this.doPreFill();
        }
      },
      deep: true,
    },
    preFill: {
      handler(newVal) {
        if (!newVal) {
          this.relation = null;
        }
      },
    },
  },
  created() {
    if (this.preFill) {
      this.doPreFill();
    }
  },
  methods: {
    ...mapActions({ setIdentificationStepTwo: 'newStudentRegister/setIdentificationStepTwo' }),
    nextStep() {
      if (this.isActive) {
        const relationship = {
          relationship: this.relation,
          other: null,
          uuid: this.currentStudent.uuid,
        };
        this.setIdentificationStepTwo(relationship);
        this.$emit('nextStep');
      } else {
        this.$emit('nextStep');
      }
    },
    setValue(index, value) {
      if (index) {
        this.relation = value;
      }
    },
    doPreFill() {
      this.relation = this.preFillData?.relationship ?? null;
    },
  },
};
</script>
