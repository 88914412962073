<template>
  <v-dialog v-model="innerValue" @click:outside="false ? cancel : () => {}">
    <div
      v-click-outside="false ? cancel : () => {}"
      class="dialog"
      :class="{
        'dialog--mobile': mobile,
        'dialog--loading': loading,
      }"
    >
      <LoaderCircular
        v-if="loading"
        :show="loading"
        :size="60"
        :width="8"
      />
      <section v-else>
        <div v-if="title" class="dialog__title">
          <slot name="title" :title="title">
            {{ $t(title) }}
          </slot>
        </div>
        <div v-if="subtitle" class="dialog__subtitle">
          <slot name="subtitle">
            <span v-t="subtitle" />
          </slot>
        </div>
        <div v-if="remainingTime > 0" class="dialog__info">
          <slot name="info">
            {{ $t('dialog.redirect.info', { time: remainingTime }) }}
          </slot>
        </div>
        <div v-if="showWarning" class="dialog__warning">
          <i18n path="dialog.redirect.failed" tag="div" class="dialog__action">
            <template #site>
              <span>{{ site }}</span>
            </template>
            <template #here>
              <a
                :href="toLink"
                :target="inNewWindow ? '_blank' : '_self'"
                rel="noopener noreferrer"
                class="dialog__link"
                @click="$emit('redirected')"
              >
                {{ $t('dialog.redirect.here') }}
              </a>
            </template>
          </i18n>
        </div>
      </section>
    </div>
  </v-dialog>
</template>

<script>
import LoaderCircular from '@/components/atoms/loaders/LoaderCircular.vue';

export default {
  name: 'RedirectionDialog',
  components: {
    LoaderCircular,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: [String, Object],
      default: '',
      validator: (value) => {
        if (typeof value === 'string') {
          return true;
        }
        if (typeof value === 'object' && value.path) {
          return true;
        }
        return false;
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    waitTime: {
      type: Number,
      default: 5,
    },
    toLink: {
      type: String,
      default: '',
    },
    site: {
      type: String,
      default: '',
    },
    inNewWindow: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    innerValue: false,
    remainingTime: 0,
    timer: null,
    showWarning: false,
  }),
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    value() {
      this.innerValue = this.value;
    },
  },
  created() {
    this.innerValue = this.value;
    this.remainingTime = this.waitTime;

    if (this.innerValue && this.waitTime > 0) {
      this.startTimer();
    }
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.remainingTime -= 1;
        if (this.remainingTime <= 0) {
          this.cancelCounter();
        }
      }, 1000);
    },
    cancelCounter() {
      if (this.persistent) {
        this.showWarning = true;
        clearInterval(this.timer);
      }
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style>

</style>
