<template>
  <section class="campus-detail__travel-time campus-detail__module mt-2">
    <h3 v-t="'campus-details.travel_time.title'" class="campus-detail__module__title ml-2" />

    <div
      class="campus-detail__travel-time__boxes"
      :style="{ '--n-elements-per-row': mobile ? '1' : '2', '--gap': mobile ? '0' : '10px' }"
    >
      <div class="campus-detail__travel-time__institution mt-4">
        <!-- <div v-if="isGuest" class="d-flex flex-row justify-start align-center my-2">
          <img class="mr-2" src="@/assets/icons/exclamation-circle-white.svg" alt="exclamation" width="25px" />
          <p class="campus-detail__module__title font-weight-medium text-white">
            <button
              v-t="'campus-details.travel_time.message_guest.login'"
              type="button"
              class="mr-1 campus-detail__travel-time__border"
              @click="toLogin"
            />
            {{ $t('campus-details.travel_time.message_guest.text') }}
          </p>
        </div> -->

        <div class="campus-detail__travel-time__boxes--institution font-weight-medium text-white">
          <v-avatar size="25">
            <SVGIcon
              icon="place.svg"
            />
          </v-avatar>
          <p class="campus-detail__travel-time__boxes--address-inst">
            {{ institution.address === '' ? institution.name : institution.address }}
          </p>
        </div>
      </div>
      <div class="campus-detail__travel-time__from mt-4">
        <div class="campus-detail__travel-time__boxes--flex">
          <div class="flex campus-detail__module__subtitle campus-detail__travel-time__boxes--address-inst ml-0 pl-0 ">
            <em>
              <h3
                v-t="'campus-details.travel_time.from_location'"
                class="mr-1"
              />
            </em>
            <!-- <h3
              v-t="
                isGuest ? 'campus-details.travel_time.from' : 'campus-details.travel_time.from_location'
              "
              class="campus-detail__module__subtitle text-white mr-1"
            /> -->
            <em>{{ getInfoLocationUser.name }}: </em>
          </div>
          <!-- <v-avatar size="25">
            <v-img
              src="@/assets/digitalProfile/place.svg"
            />
          </v-avatar> -->
        </div>

        <!-- <div v-if="isGuest" class="d-flex flex-row justify-start align-center my-2">
          <img class="mr-2" src="@/assets/icons/exclamation-circle-white.svg" alt="exclamation" width="25px" />
          <p class="campus-detail__module__title font-weight-medium text-white">
            <button
              v-t="'campus-details.travel_time.message_guest.login'"
              type="button"
              class="mr-1 campus-detail__travel-time__border"
              @click="toLogin"
            />
            {{ $t('campus-details.travel_time.message_guest.text') }}
          </p>
        </div> -->

        <p class="campus-detail__module__title campus-detail__travel-time__boxes--user">
          {{ getInfoLocationUser.address }}
        </p>
      </div>
    </div>

    <!-- <div class="campus-detail__module__title font-weight-medium mt-7">
      {{ $t('campus-details.travel_time.how_do') }}
      <span class="campus-detail__module__title font-weight-medium">
        {{ institution.name }}
      </span>
    </div> -->

    <!-- <p
      v-t="'campus-details.travel_time.select_option'"
      class="campus-detail__module__title font-weight-light text-caption mb-2"
    /> -->

    <div class="travel-time__radio--button mt-2">
      <template
        v-for="{
          name, time, icon, alt,
        } in listTravelTime"
      >
        <!-- eslint-disable-next-line vue/require-v-for-key -->
        <input
          :id="name"
          v-model="selectedTravelTime"
          type="radio"
          :value="name"
        />
        <!-- <input
          :id="name"
          v-model="selectedTravelTime"
          type="radio"
          :value="name"
          :disabled="isGuest"
        /> -->
        <!-- eslint-disable-next-line vue/require-v-for-key -->
        <label class="mt-2" :for="name">
          <SVGIcon
            :icon="icon"
            size="25"
            :alt-text="alt"
            squared
          />
          <span class="mt-1">
            {{ time }}
          </span>
        </label>
      </template>
    </div>
    <p
      v-if="isErrorRequestTravelTime()"
      v-t="'campus-details.travel_time.error_request'"
      class="campus-detail__module__title font-weight-light mt-2"
    />

    <GmapMap
      ref="mapRef"
      :zoom="zoom"
      :center="institution.location"
      class="campus-detail__travel-time__map mt-4"
      :options="{
        disableDefaultUI: true,
        clickableIcons: false,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        styles: mapStyle,
        gestureHandling: 'greedy',
      }"
    >
      <GmapCustomMarker :marker="getInfoLocationUser.location" alignment="top">
        <SVGIcon
          icon="home-icon.svg"
          size="30"
          alt-text="home"
        />
      </GmapCustomMarker>

      <GmapCustomMarker :marker="institution.location" alignment="top">
        <SVGIcon
          icon="institution-icon.svg"
          size="30"
          alt-text="institution"
        />
      </GmapCustomMarker>

      <DirectionsRenderer
        v-if="selectedTravelTime"
        :key="keyDirectionsRenderer"
        :map="$refs"
        :travel-mode="selectedTravelTime"
        :origin="getInfoLocationUser.location"
        :destination="institution.location"
        :render="listTravelTime[`${selectedTravelTime.toLowerCase()}`].render"
        @response-directions="getResponseDirections"
      />
    </GmapMap>

    <picture class="d-flex flex-row align-middle mt-5">
      <img src="@/assets/icons/exclamation-purple.svg" width="20px" alt="exclamation" />
      <p
        v-t="'campus-details.travel_time.disclaimer'"
        class="campus-detail__travel-time__disclaimer ml-1"
      />
    </picture>
    <MainActivities
      v-if="partnerships.length > 0"
      class="mt-4"
      :partnerships="partnerships"
    />
  </section>
</template>

<script>
import mapStyle from '@/assets/mapStyle.json';
import MainActivities from '@/components/explorer/general/campus_detail/panels/MainActivities.vue';
import DirectionsRenderer from '@/utils/DirectionsRenderer';
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import SVGIcon from '@/components/atoms/icons/SvgIcon.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'TravelTime',
  components: {
    DirectionsRenderer,
    GmapCustomMarker,
    SVGIcon,
    MainActivities,
  },
  props: {
    institution: {
      type: Object,
      default: () => {},
    },
    partnerships: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedTravelTime: 'WALKING',
      keyDirectionsRenderer: 0,
      listTravelTime: [],
      mapStyle,
      zoom: 13,
    };
  },
  computed: {
    ...mapGetters({
      homeLocation: 'authentication/homeLocation',
      currentStudent: 'authentication/currentStudent',
      legalGuardianAddress: 'authentication/legalGuardianAddress',
      isShowDigitalProfile: 'feedback/isShowDigitalProfile',
      currentAddressStudentInFeedback: 'feedback/currentAddressStudentInFeedback',
    }),
    mobile() {
      return this.$vuetify.breakpoint.width < 670;
    },
    getAddressUser() {
      const info = {};
      const hasAddressCurrentStudent = this.currentStudent?.address ?? false;
      const hasAddressLegalGuardian = this.legalGuardianAddress ?? false;

      if (hasAddressCurrentStudent) {
        info.address = this.currentStudent?.address[0]?.address_details;
        info.location = {
          lat: this.currentStudent?.address[0]?.address_lat,
          lng: this.currentStudent?.address[0]?.address_lon,
        };
        info.name = this.getLocationTypeName(this.currentStudent?.address[0]?.address_name?.id);
      } else if (hasAddressLegalGuardian) {
        info.address = this.legalGuardianAddress[0]?.address_details;

        info.location = {
          lat: this.legalGuardianAddress[0]?.address_lat,
          lng: this.legalGuardianAddress[0]?.address_lon,
        };
        info.name = this.getLocationTypeName(this.legalGuardianAddress[0]?.address_name?.id);
      } else {
        info.address = this.$t('campus-details.travel_time.your_location');
        info.location = {
          lat: this.homeLocation.lat,
          lng: this.homeLocation.lng,
        };
        info.name = '';
      }

      return info;
    },
    getInfoLocationUser() {
      let info = {};

      if (this.isShowDigitalProfile) {
        info.address = this.currentAddressStudentInFeedback.address_details;
        info.location = {
          lat: this.currentAddressStudentInFeedback.address_lat,
          lng: this.currentAddressStudentInFeedback.address_lon,
        };
      } else {
        info = this.getAddressUser;
      }
      return info;
    },
  },
  watch: {
    selectedTravelTime() {
      this.keyDirectionsRenderer += 1;
    },
  },
  created() {
    this.setListTravelTime();
  },
  methods: {
    setListTravelTime() {
      this.listTravelTime = {
        transit: {
          name: 'TRANSIT',
          icon: 'bus-blue.svg',
          alt: `Tiempo en bus desde tu ubicación a ${this.institution.name}`,
          time: '',
          render: {},
          error: false,
        },
        driving: {
          name: 'DRIVING',
          icon: 'car-blue.svg',
          alt: `Tiempo en automóvil desde tu ubicación a ${this.institution.name}`,
          time: '',
          render: {},
          error: false,
        },
        walking: {
          name: 'WALKING',
          icon: 'walk-blue.svg',
          alt: `Tiempo caminando desde tu ubicación a ${this.institution.name}`,
          time: '',
          render: {},
          error: false,
        },
      };
    },
    isErrorRequestTravelTime() {
      if (this.selectedTravelTime) {
        const travel = this.selectedTravelTime?.toLowerCase() ?? 'WALKING';
        return this.listTravelTime[`${travel}`].error;
      }
      return false;
    },
    getLocationTypeName(id) {
      let infoName;
      if (id === 1) {
        infoName = this.$t('shared.location.home');
      }
      if (id === 2) {
        infoName = this.$t('shared.location.work');
      }
      if (id === 3) {
        infoName = this.$t('shared.location.other');
      }
      return infoName;
    },
    getResponseDirections($event, error = false) {
      const travel = this.selectedTravelTime.toLowerCase();
      this.listTravelTime[`${travel}`].error = error;

      if (error) {
        return;
      }
      const { routes } = $event;
      const duration = routes[0].legs[0].duration.text;
      // eslint-disable-next-line dot-notation
      this.listTravelTime[`${travel}`].render = $event;
      this.listTravelTime[`${travel}`].time = duration;
    },
    // toLogin() {
    //   this.$router.push({ name: 'Login' });
    //   this.$emit('close-modal');
    // },
  },
};
</script>
