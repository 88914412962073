<template>
  <section
    v-if="testimonials.length > 0"
    class="campus-detail__in-their-words campus-detail__module mt-2"
  >
    <h3 class="campus-detail__module__title">
      {{ $t('campus-details.in_their_own_words') }}
    </h3>
    <div class="campus-detail__in-their-words__video-container mt-3">
      <div
        v-for="({
          name, audiovisual_label, link, id,
        }, index) in testimonials"
        :key="index"
        class="d-flex flex-column"
      >
        <CampusDetailVideo :id="id" media :video="link" full-screen />
        <h3 class="mt-2">
          {{ name }} {{ name !== '' ? ',' : '' }}  {{ audiovisual_label }}
        </h3>
      </div>
    </div>
  </section>
</template>

<script>
import CampusDetailVideo from '@/components/explorer/general/campus_detail/CampusDetailVideo.vue';

export default {
  name: 'CampusDetailInTheirOwnWords',
  components: {
    CampusDetailVideo,
  },
  props: {
    testimonials: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
