<template>
  <div class="register-main register-main--spaced">
    <div>
      <ModalHeader :content-sections="['close']" @close="$emit('close')" />
      <ModalContent
        :content-sections="['subtitle', 'icon']"
        subtitle-center
        :subtitle-text="
          inNavButtons
            ? 'modals.applicatin_info_modal.title-in-nav'
            : 'modals.applicatin_info_modal.title'
        "
        :icon-color="'#00101A'"
        :icon-name="'mdi-information-outline'"
        :icon-type="'info'"
      />
      <ModalContent :content-sections="['subtitle']" :subtitle-text="setSubtitleText()" subtitle-light />
    </div>
    <CallToActions
      :option-text1="
        inNavButtons ? 'modals.applicatin_info_modal.btn1-nav' : 'modals.applicatin_info_modal.btn1'
      "
      :option-text2="
        inNavButtons ? 'modals.applicatin_info_modal.btn2-nav' : 'modals.applicatin_info_modal.btn2'
      "
      :types="['option']"
      :spaced="false"
      option-btn-no-margin
      @nextStep="nextStep"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';

export default {
  name: 'ApplicationInfoModal',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
  },
  props: {
    inNavButtons: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      modalInfoFlow: 'userRegister/modalInfoFlow',
      legalGuardianAddress: 'authentication/legalGuardianAddress',
    }),
    setModalUuid() {
      if (this.modalInfoFlow && this.modalInfoFlow.length > 0) {
        const infoModal = this.modalInfoFlow.find((modal) => {
          if (modal.modal_code.indexOf('i') > -1) {
            return modal;
          }
          return null;
        });
        if (infoModal) {
          return infoModal.uuid;
        }
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      setProfileStep: 'userRegister/setProfileStep',
      setModalFlowInfo: 'userRegister/setModalFlowInfo',
    }),
    setSubtitleText() {
      if (this.inNavButtons) {
        return 'modals.applicatin_info_modal.context-text-nav';
      }
      if (this.legalGuardianAddress && this.legalGuardianAddress.length > 0) {
        return 'modals.applicatin_info_modal.with-location-text';
      }
      return 'modals.applicatin_info_modal.content-text';
    },
    nextStep(i) {
      let missingDataFor = 'lg';
      let profileStepModal = 0;
      const modalInfo = {
        user: '',
        modal_code: '2M.i',
        modal_response: false,
        action_completed: false,
      };
      if (this.inNavButtons) {
        modalInfo.modal_code = '4M.i';
        this.$emit('toApplication');
      }
      if (this.legalGuardianAddress && this.legalGuardianAddress.length > 0 && !this.inNavButtons) {
        modalInfo.modal_code = '3M.i';
        missingDataFor = 'student';
        profileStepModal = 0;
      }
      const modalUuid = this.setModalUuid;
      if (i === 1) {
        modalInfo.modal_response = true;
        this.setModalFlowInfo({ modalInfo, modalUuid });
        if (this.inNavButtons) {
          return this.$router.push('/application');
        }
        this.setProfileStep({ step: { for: missingDataFor, step: profileStepModal } });
        return this.$router.push('/dashboard');
      }
      this.setModalFlowInfo({ modalInfo, modalUuid });
      return this.$emit('close');
    },
  },
};
</script>
