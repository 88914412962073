<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'title', 'logo']"
        :title-text="'views.login.password-recovery.title'"
        :actions-tag="
          showResend ? 'click_reg_reset-password-sended_back-button' : 'click_reg_reset-password_back-button'
        "
        small-logo
        @back="backMethod()"
      />
      <ModalContent
        :content-sections="infoSent ? ['smallTitle'] : ['subtitle', 'registerInput']"
        :subtitle-text="
          showPhoneRegister
            ? 'views.login.password-recovery.subtitle_phone'
            : 'views.login.password-recovery.subtitle'
        "
        :small-title-text="setSmallTitle()"
        small-title-primary-color
        :input-section="inputContent"
        :error-types="showPhoneRegister ? [] : ['email']"
        :input-text-select="showPhoneRegister ? true : false"
        :text-select-items="listCodeArea"
        @setValue="setValue"
        @isInvalidEmail="isInvalidEmail"
        @setTextSelectValue="setDialCode"
      />
      <CallToActions
        v-if="showResend && !resendError"
        :types="['question', 'link']"
        :link-text="'views.login.password-recovery.link-text'"
        :link-text-tag="'click_reg_reset-password-sended_resend-textlink'"
        :question-text="'views.login.password-recovery.question-text'"
        @toLink="resetPassword()"
      />
    </div>
    <CallToActions
      v-if="!showResend || (resendError && showResend)"
      :spaced="false"
      :loader="recoveryLoader"
      :types="resendError ? ['main'] : ['main', 'link']"
      :link-text="
        showPhoneRegister
          ? 'views.login.password-recovery.email_link'
          : 'views.login.password-recovery.phone_link'
      "
      :active-value="email"
      :main-button-text="
        resendError
          ? 'user_register.password-recovery.to_register_btn'
          : 'user_register.register_data.btn.send'
      "
      :main-button-tag="'click_reg_reset-password_send-link-button'"
      @continue="resendError ? toRegister() : resetPassword()"
      @toLink="setInputType"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import listCodeArea from '@/locales/code_area.json';
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'PasswordRecovery',
  components: {
    ModalHeader,
    ModalContent,
    CallToActions,
  },
  data() {
    return {
      inputContent: [
        {
          value: null,
          noInfoError: false,
          placeholder: 'user_register.register_data.btn.send',
          show: true,
          selectValue: '+57',
        },
      ],
      email: null,
      invalidEmail: null,
      recoveryLoader: false,
      infoSent: false,
      showResend: false,
      showPhoneRegister: false,
      dialCode: '+57',
      resendError: null,
    };
  },
  computed: {
    listCodeArea() {
      const dialCodes = listCodeArea.countries.map((country) => country.dial_code);
      return dialCodes;
    },
  },
  methods: {
    ...mapActions({
      retrieveResetPasswordToken: 'authentication/retrieveResetPasswordToken',
      success: 'utils/success',
    }),
    isInvalidEmail(bool) {
      this.invalidEmail = bool;
      return this.invalidEmail;
    },
    toRegister() {
      this.$emit('toRegister');
    },
    setSmallTitle() {
      if (this.resendError && this.showPhoneRegister) {
        return 'user_register.password-recovery.sent_phone_error';
      }
      if (this.resendError && !this.showPhoneRegister) {
        return 'user_register.password-recovery.sent_email_error';
      }
      if (!this.resendError && this.showPhoneRegister) {
        return 'views.login.password-recovery.send_subtitle_phone';
      }
      if (!this.resendError && !this.showPhoneRegister) {
        return 'views.login.password-recovery.send_subtitle';
      }
      return 'views.login.password-recovery.send_subtitle';
    },
    resetPassword() {
      this.infoSent = true;
      if (!this.email || this.invalidEmail) {
        if (!this.email) {
          this.inputContent[0].noInfoError = true;
        }
      } else {
        let authMethod = 'email';
        let phoneNumber;
        if (this.showPhoneRegister) {
          phoneNumber = this.dialCode + this.email;
          authMethod = 'phone';
        }
        this.recoveryLoader = true;
        this.retrieveResetPasswordToken({
          email: this.showPhoneRegister ? null : this.email,
          phone: this.showPhoneRegister ? phoneNumber : null,
          authMethod,
          callback: (error) => {
            this.recoveryLoader = false;
            let successMsg = 'views.reset_password.sent.email';
            if (authMethod === 'phone') {
              successMsg = 'views.reset_password.sent.sms';
            }
            this.showResend = true;
            if (error === 'ok') {
              this.success(this.$t(successMsg));
            } else {
              this.resendError = true;
            }
          },
        });
        const data = {
          email: phoneNumber ? null : this.email,
          phone: phoneNumber ? this.email : null,
          username: phoneNumber,
          authMethod,
        };
        trackMixPanel('click_send_link_reset_password', data);
      }
    },
    setValue(mainValue, passwordInput, index) {
      if (passwordInput === 0) {
        this.email = mainValue;
        this.inputContent[index].value = mainValue;
        this.inputContent[index].noInfoError = false;
      }
    },
    backMethod() {
      this.$emit('backStep');
    },
    setInputType() {
      if (!this.showPhoneRegister) {
        this.inputContent[0].placeholder = 'user_register.register_data.phone-placeholder';
        this.showPhoneRegister = true;
        return this.showPhoneRegister;
      }
      this.inputContent[0].placeholder = 'views.login.name.email';
      this.showPhoneRegister = false;
      return this.showPhoneRegister;
    },
    setDialCode(index, value) {
      if (index === 0) {
        this.dialCode = value;
      }
      return this.dialCode;
    },
  },
};
</script>
