import services from '@/services';
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import { ELASTIC_SEARCH_FIELDS } from '@/constants/search';
import CONFIG from '@/config';
import { schoolMatchesFilters } from '@/utils/filters';

const getDefaultState = () => ({
  campus: null,
  // TODO: Move to the future institutions/search module:
  previouslySearchedCampuses: [],
  previouslySearchedPlace: [],
  loading: false,
});

const state = {
  ...getDefaultState(),
};

const getters = {
  previouslySearchedCampuses: ({ previouslySearchedCampuses }) => previouslySearchedCampuses,
  previouslySearchedPlace: ({ previouslySearchedPlace }) => previouslySearchedPlace,
  campus: ({ campus }) => campus,
  loading: ({ loading }) => loading,
};

const mutations = {
  setSearchedCampuses(state, { previouslySearchedCampuses }) {
    state.previouslySearchedCampuses = previouslySearchedCampuses;
  },
  setSearchedPlace(state, { previouslySearchedPlace }) {
    state.previouslySearchedPlace = previouslySearchedPlace;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setCampus(state, { campus }) {
    state.campus = campus;
  },
};

const actions = {
  searchCampusesFromText({ commit }, { searchTerm, requiredFields, filters }) {
    commit('setLoading', true);
    return services.elasticSearchService
      .getCampusesFromText({ searchTerm, requiredFields, filters })
      .then((response) => {
        const campuses = response.data.results;
        const places = response.data.matching_places;
        return { campuses, places, status: 200 };
      }).catch((error) => {
        // Omit if it was intentionally cancelled
        if (error.message !== 'canceled') {
          throw error;
        }
        return { campuses: [], status: 418 }; // I'm a teapot, because why not (canceled request)
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  addSearchedCampus({ commit, state }, { campus }) {
    const newHistorial = [...state.previouslySearchedCampuses];
    const alreadySearchedIndex = newHistorial.findIndex(
      (historicCampus) => historicCampus.uuid === campus.uuid,
    );
    if (alreadySearchedIndex !== -1) {
      newHistorial.splice(
        alreadySearchedIndex,
        1,
      );
    } else if (newHistorial.length === 20) {
      newHistorial.pop();
    }
    commit('setSearchedCampuses', { previouslySearchedCampuses: [campus, ...newHistorial] });
  },
  addSearchedPlace({ commit }, { place }) {
    commit('setSearchedPlace', { previouslySearchedPlace: [place] });
  },
  // eslint-disable-next-line no-empty-pattern
  async getCampusesByValidations({ }, data = { campuses: [], validation: '', fields: [] }) {
    const campuses = [...new Set(data.campuses)];
    const payload = campuses.map((campus) => ({
      fieldname: data.validation,
      fieldvalue: campus,
    }));

    const { data: listCampuses } = await services.elasticSearchService.elasticFilteredSearch(payload, data.fields);
    return listCampuses.results ?? [];
  },
  async getCampusWithCampusCode({ commit }, { campusCode }) {
    const filters = [{
      fieldname: ELASTIC_SEARCH_FIELDS.CAMPUS_CODE,
      fieldvalue: campusCode,
    }];
    const fieldsRequired = [
      ELASTIC_SEARCH_FIELDS.CAMPUS_CODE,
      ELASTIC_SEARCH_FIELDS.INSTITUTION_CODE,
    ];
    services.elasticSearchService.elasticFilteredSearch(filters, fieldsRequired).then((response) => {
      const campus = response.data.results; // CAMBIAAAAAAR
      commit('setCampus', { campus });
    });
  },
  /**
   * Retrieve the campuses that are in the bounds of the map and match the filters
   * @param {*} param0 - The store object
   * @param {*} param1 - The payload to send to the API, with the filters and the bounds
   * @param {*} param1.bounds - The bounds of the map (if not provided, it will be retrieved from the store)
   * @param {*} param1.propagatePayload - The filters to apply to the search
   * @returns {Promise<AxiosResponse<any>>} - The response from the API with the results
   */
  retrieveCampusesInBounds({ rootGetters }, { bounds, payload }) {
    const searchBounds = bounds ?? rootGetters['explorer/mapCornerBounds'];
    if (!searchBounds?.length > 0) {
      return Promise.resolve({ data: { results: [] } });
    }
    const fullPayload = {
      ...payload,
      bounds: searchBounds.map((bound) => ({ lat: bound.lat, lon: bound.lng })),
    };
    return services.elasticSearchService.retrieveCampuses(fullPayload);
  },
  retrieveCampusesAround({ rootGetters }, { location, payload }) {
    const aroundLocation = location || rootGetters['authentication/homeLocation'];
    if (!aroundLocation || !aroundLocation.lat || !aroundLocation.lng) {
      return Promise.resolve({ data: { results: [] } });
    }
    return services.elasticSearchService.getCampusesAround({ location: aroundLocation, ...payload });
  },

  /**
   *  Get campus recommendations based on the user's favorite schools, active municipality and active grades
   * @param {*} param0
   * @param {*} param1
   * @returns
   */
  async getCampusRecommendations({ rootGetters }, {
    size = 10,
    from = 0,
    fieldsRequired,
    publicOnly = false,
  } = {}) {
    const isGuest = rootGetters['authentication/isGuest'];
    const favoriteSchools = rootGetters[isGuest ? 'favorites/favoriteGuestList' : 'favorites/listFavoriteSchools'];
    const activeMunicipality = rootGetters['authentication/activeMunicipality'];
    const isPalmira = CONFIG.tenant === 'palmira';
    const matchCommunes = new Set();
    const excludeCampuses = new Set();
    if (favoriteSchools.length > 0) {
      favoriteSchools.forEach((school) => {
        excludeCampuses.add(school.campus_code);
        matchCommunes.add(school.commune);
      });
    } else if (activeMunicipality) {
      matchCommunes.add(activeMunicipality);
    }
    const { grades } = rootGetters['authentication/activeGrades'];
    const matchGrades = new Set(grades);
    const matchFilters = [];
    if (isPalmira) {
      matchFilters.push({
        fieldname: ELASTIC_SEARCH_FIELDS.COMMUNE,
        fieldvalue: 'Palmira',
        exact: true,
      });
    } else if (matchCommunes.size > 0) {
      matchFilters.push({
        fieldname: ELASTIC_SEARCH_FIELDS.COMMUNE,
        fieldvalues: [...matchCommunes],
        exact: true,
      });
    }
    if (matchGrades.size > 0) {
      matchFilters.push({
        fieldname: ELASTIC_SEARCH_FIELDS.GRADE,
        fieldvalues: [...matchGrades],
      });
    }
    if (publicOnly || isPalmira) {
      const hasRiskFilter = {
        fieldname: ELASTIC_SEARCH_FIELDS.ADMISSION_SYSTEM,
        fieldvalues: TENANT_CONFIGURATION.SPECIAL_IDS.PUBLIC_ADMISSION_SYSTEMS,
      };
      matchFilters.push(hasRiskFilter);
    }

    const excludeFilters = [];
    if (excludeCampuses.size > 0) {
      excludeFilters.push({
        fieldname: ELASTIC_SEARCH_FIELDS.CAMPUS_CODE,
        fieldvalues: [...excludeCampuses],
        exact: true,
      });
    }

    return services.elasticSearchService.searchCampuses({
      size,
      from,
      mustFilters: matchFilters,
      mustNotFilters: excludeFilters,
      fieldsRequired,
      randomize: true,
    }).then(({ data: { results } }) => {
      const filters = rootGetters['filters/filters'];
      const activeFiltersCount = rootGetters['filters/activeFiltersCount'];

      if (activeFiltersCount > 0) {
        return results.filter((school) => schoolMatchesFilters(school, filters, rootGetters));
      }
      return results;
    })
      .catch(() => ([]));
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
