<template>
  <div>
    <section class="d-flex fill-width justify-center">
      <input
        id="input1"
        v-model="input1"
        pattern="[0-9]*"
        inputmode="numeric"
        class="register-main--verify-input"
        type="text"
        aria-label="First Verification Code Digit"
        @input="setNewNumber('input2')"
      />
      <input
        id="input2"
        v-model="input2"
        pattern="[0-9]*"
        inputmode="numeric"
        maxlength="1"
        class="register-main--verify-input"
        type="text"
        aria-label="Second Verification Code Digit"
        @input="setNewNumber('input3')"
      />
      <input
        id="input3"
        v-model="input3"
        pattern="[0-9]*"
        inputmode="numeric"
        maxlength="1"
        class="register-main--verify-input"
        type="text"
        aria-label="Third Verification Code Digit"
        @input="setNewNumber('input4')"
      />
      <input
        id="input4"
        v-model="input4"
        pattern="[0-9]*"
        inputmode="numeric"
        maxlength="1"
        class="register-main--verify-input"
        type="text"
        aria-label="Fourth Verification Code Digit"
        @input="setNewNumber('input5')"
      />
      <input
        id="input5"
        v-model="input5"
        pattern="[0-9]*"
        inputmode="numeric"
        maxlength="1"
        class="register-main--verify-input"
        type="text"
        aria-label="Fifth Verification Code Digit"
        @input="setNewNumber('input6')"
      />
      <input
        id="input6"
        v-model="input6"
        pattern="[0-9]*"
        inputmode="numeric"
        maxlength="1"
        class="register-main--verify-input"
        type="text"
        aria-label="Sixth Verification Code Digit"
        @input="setNewNumber('input1')"
      />
    </section>
    <p
      v-if="componentInfoError && !code"
      v-t="'views.login.error.email.required'"
      class="register-main--error"
    />
  </div>
</template>

<script>
export default {
  name: 'VerificationCodeInput',
  components: {},
  props: {
    noInfoError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input1: '',
      input2: '',
      input3: '',
      input4: '',
      input5: '',
      input6: '',
      code: '',
      componentInfoError: this.noInfoError,
    };
  },
  computed: {
    hasCode() {
      if (this.input1 && this.input2 && this.input3 && this.input4 && this.input5 && this.input6) {
        return true;
      }
      return false;
    },
  },
  watch: {
    noInfoError: {
      handler(newVal) {
        this.componentInfoError = newVal;
      },
    },
  },
  methods: {
    setNewNumber(input) {
      if (this.input1.length > 1) {
        const fullInput = this.input1;
        this.input1 = fullInput.slice(0, 1);
        this.input2 = fullInput.slice(1, 2);
        this.input3 = fullInput.slice(2, 3);
        this.input4 = fullInput.slice(3, 4);
        this.input5 = fullInput.slice(4, 5);
        this.input6 = fullInput.slice(5, 6);
      }
      if (this.input1 === '' && input === 'input2') {
        document.getElementById('input6').focus();
        document.getElementById('input6').select();
      } else if (this.input2 === '' && input === 'input3') {
        document.getElementById('input1').focus();
        document.getElementById('input1').select();
      } else if (this.input3 === '' && input === 'input4') {
        document.getElementById('input2').focus();
        document.getElementById('input2').select();
      } else if (this.input4 === '' && input === 'input5') {
        document.getElementById('input3').focus();
        document.getElementById('input3').select();
      } else if (this.input5 === '' && input === 'input6') {
        document.getElementById('input4').focus();
        document.getElementById('input4').select();
      } else if (this.input6 === '' && input === 'input1') {
        document.getElementById('input5').focus();
        document.getElementById('input5').select();
      } else {
        document.getElementById(input).focus();
        document.getElementById(input).select();
      }

      if (this.hasCode) {
        this.code = this.input1 + this.input2 + this.input3 + this.input4 + this.input5 + this.input6;
        return this.$emit('setCode', this.code);
      }
      return null;
    },
  },
};
</script>
