<template>
  <v-dialog v-model="innerValue" persistent>
    <div
      class="dialog"
      :class="{
        'dialog--mobile': mobile,
        'dialog--loading': loading,
      }"
    >
      <LoaderCircular
        v-if="loading"
        :show="loading"
        :size="60"
        :width="8"
      />
      <section v-else>
        <div v-if="title" class="dialog__title">
          <slot name="title" :title="title">
            {{ $t(title) }}
          </slot>
        </div>
        <div v-if="warning" class="dialog__warning">
          <slot name="warning" :warning="warning">
            <MDIIcon icon="mdi-alert-outline" color="#e94c6e" />
            {{ $t(warning) }}
          </slot>
        </div>
        <div v-if="subtitle" class="dialog__subtitle">
          <slot name="subtitle" :subtitle="subtitle">
            {{ $t(subtitle) }}
          </slot>
        </div>
        <div v-if="infoToConfirm" v-t="infoToConfirm" class="dialog__info">
          <slot name="info" :info-to-confirm="infoToConfirm">
            {{ $t(infoToConfirm) }}
          </slot>
        </div>
        <div
          v-for="(option, index) in options"
          :key="index"
        >
          <v-btn
            class="costs-category-box"
            :style="{
              'background-color': selected === index ? '#DDD4FF !important' : '#ddd4ff41 !important',
              height: '50px !important',
              border: '2px solid #DDD4FF !important',
              'border-radius': '20px !important',
            }"
            @click="setSelection(index)"
          >
            <div>
              <TextAtom
                :value="getDisplayText(option)"
                tag="p"
                font="inter"
                weight="400"
                color="primary-dark"
                size="normal"
              />
            </div>
          </v-btn>
        </div>
        <div
          class="dialog__actions w-100"
          :class="{
            'dialog__actions--mobile': mobile,
            'dialog__actions--single': !mobile && (hideCancelButton || hideConfirmButton),
          }"
        >
          <slot name="actions">
            <OptionButtons
              v-if="showCancelButton"
              class="dialog__actions__button mx-1"
              :text="cancelButtonText || 'dialog.confirm.no'"
              no-margin
              @nextStep="cancel"
            />
            <OptionButtons
              v-if="!hideConfirmButton"
              class="dialog__actions__button mx-1"
              :text="confirmButtonText || (showCancelButton ? 'dialog.confirm.yes' : 'dialog.confirm.acknowledged')"
              :disabled="selected === -1"
              is-active
              no-margin
              @nextStep="confirm"
            />
          </slot>
        </div>
      </section>
    </div>
  </v-dialog>
</template>

<script>
import OptionButtons from '@/components/atoms/buttons/OptionButtons.vue';
import MDIIcon from '@/components/atoms/icons/MdiIcon.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import LoaderCircular from '@/components/atoms/loaders/LoaderCircular.vue';

export default {
  name: 'SelectionDialog',
  components: {
    OptionButtons,
    LoaderCircular,
    MDIIcon,
    TextAtom,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    infoToConfirm: {
      type: String,
      default: '',
    },
    warning: {
      type: String,
      default: '',
    },
    hideCancelButton: {
      type: Boolean,
      default: false,
    },
    hideConfirmButton: {
      type: Boolean,
      default: false,
    },
    confirmButtonText: {
      type: String,
      default: '',
    },
    cancelButtonText: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    innerValue: false,
    selected: -1,
  }),
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    showCancelButton() {
      return this.mobile || !this.hideCancelButton;
    },
  },
  watch: {
    value() {
      this.innerValue = this.value;
    },
  },
  created() {
    this.innerValue = this.value;
  },
  methods: {
    getDisplayText(object) {
      const separator = object.title && object.text;
      if (separator) {
        return `${object.title} - ${object.text}`;
      }
      return object.title || '';
    },
    cancel() {
      this.$emit('cancel');
    },
    confirm() {
      this.$emit('confirm', this.selected);
    },
    setSelection(index) {
      if (this.selected === index) {
        this.selected = -1;
        return;
      }
      this.selected = index;
    },
  },
};
</script>

<style>

</style>
