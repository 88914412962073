<template>
  <section class="register-main">
    <v-text-field
      v-model="searchTerm"
      class="register-main--text-expand w-full"
      background-color="#EBEBEB"
      item-color="#1a0c4c"
      color="#1a0c4c"
      height="48px"
      :loading="indexTextExpand === 0 ? loading : loadingAddress"
      :label="setTextFieldLabel()"
      :disabled="indexTextExpand === 0 ? false : disabled"
      solo
      clearable
      hide-details
    />
    <v-expand-transition>
      <v-list
        v-if="
          suggestedPlaces
            && suggestedPlaces.length > 0
            && ((searchTerm?.length > 3 && !loading && indexTextExpand === 0)
              || (searchTerm?.length > 0 && !loadingAddress && indexTextExpand === 1))"
        class="places-search-bar"
        :class="suggestedPlaces.length > 5 ? 'places-search-height' : ''"
      >
        <v-list-item
          v-for="info in suggestedPlaces"
          :key="info.display1"
          class="places-search-bar__item"
          :title="`${info.display1}`"
          @click="select(info)"
        >
          <div v-if="info.searchAddress && indexTextExpand === 1">
            <TextAtom
              class="places-search-bar__city"
              :value="$t('user_register.register_location.search_option')
                + info.display1
                + $t('user_register.register_location.search_option_in_map')"
              tag="label"
              font="inter"
              color="primary-700"
              weight="400"
              size="subtitle"
            />
          </div>
          <div v-else-if="info.current" class="flex">
            <SVGIcon
              :size="'24'"
              :icon="'location-purple.svg'"
              :alt-text="info.alt"
            />
            <TextAtom
              class="places-search-bar__current ml-3"
              :value="info.currentLocationTitle"
              tag="label"
              font="inter"
              color="primary-700"
              weight="400"
              size="subtitle"
            />
          </div>
          <div v-else>
            <TextAtom
              v-if="indexTextExpand === 0"
              class="places-search-bar__city"
              :value="info.cityTitle"
              tag="label"
              font="inter"
              color="primary-700"
              weight="400"
              size="subtitle"
            />
            <TextAtom
              v-if="indexTextExpand === 0"
              class="places-search-bar__upper"
              :value="info.display2"
              tag="label"
              font="inter"
              color="primary-700"
              weight="400"
              size="subtitle"
            />
            <TextAtom
              v-if="indexTextExpand === 1"
              class="places-search-bar__city"
              :value="info.formatted_address"
              tag="label"
              font="inter"
              color="primary-700"
              weight="400"
              size="subtitle"
            />
          </div>
        </v-list-item>
      </v-list>
    </v-expand-transition>
  </section>
</template>

<script>
import SVGIcon from '@/components/atoms/icons/SvgIcon.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TextExpand',
  components: {
    TextAtom,
    SVGIcon,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    indexTextExpand: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      searchTerm: '',
      textSearchResults: [],
      loading: false,
      loadingAddress: false,
      infoSelected: '',
      addressSelected: '',
    };
  },
  computed: {
    ...mapGetters({
      location: 'userRegister/location',
      place: 'userRegister/place',
    }),
    validSearchTerm() {
      return (this.searchTerm && this.searchTerm.length > 3 && this.indexTextExpand === 0)
      || (this.searchTerm && this.searchTerm.length > 0 && this.indexTextExpand === 1);
    },
    suggestedPlaces() {
      if (this.validSearchTerm) {
        const currentLocation = {
          currentLocationTitle: this.$t('user_register.register_location.option3'),
          display1: this.$t('user_register.register_location.option3'),
          alt: 'location',
          current: true,
        };
        if (!this.loading && this.indexTextExpand === 0) {
          const results = this.textSearchResults.map((place) => ({
            display: place.display,
            display1: place.city,
            cityTitle: place.city,
            display2: place.upper_name,
            code_national_id: place.code_national_id,
            lat: place.centroide_lat,
            lng: place.centroide_lng,
            p_location_id: place.p_location_id,
            searchTerm: this.searchTerm,
            current: false,
          }));
          results.push(currentLocation);
          return results;
        }
        if (!this.loadingAddress && this.indexTextExpand === 1) {
          const searchAddress = {
            display1: this.searchTerm,
            searchAddress: true,
          };
          const results = this.textSearchResults.map((address) => ({
            display1: address.formatted_address,
            formatted_address: address.formatted_address,
            geocodification_type: address.geocodification_type,
            lat: address.lat,
            lng: address.lng,
            source: address.source,
            upper_location: address.upper_location,
            street_name: address.street_name,
            street_number: address.street_number,
            current: false,
            searchAddress: false,
          }));
          const isSearchTermInResults = results.some((result) => result.formatted_address === this.searchTerm);
          if (!isSearchTermInResults) {
            results.unshift(searchAddress);
          }
          results.push(currentLocation);
          return results;
        }
      }
      if (this.loading || this.loadingAddress) {
        return [];
      }
      return [];
    },
  },
  watch: {
    searchTerm(newValue) {
      if (newValue?.length > 3 && this.indexTextExpand === 0) {
        this.placeSelected = '';
        this.loading = true;
        this.searchCommune({ searchTerm: newValue });
      } else if (newValue?.length > 0 && this.indexTextExpand === 1) {
        this.addressSelected = '';
        this.loadingAddress = true;
        this.searchAddress({ searchTerm: newValue });
      }
    },
    textSearchResults() {
      this.loading = false;
      this.loadingAddress = false;
    },
  },
  methods: {
    ...mapActions({
      searchCommuneFromText: 'geoTools/searchCommuneFromText',
      searchAddressFromText: 'geoTools/searchAddressFromText',
      searchAddressInGoogle: 'geoTools/searchAddressInGoogle',
      changePlace: 'userRegister/changePlace',
      setSearchTerm: 'userRegister/setSearchTerm',
      setLocationAddress: 'userRegister/setLocationAddress',
      setFinalLocationAddress: 'userRegister/setFinalLocationAddress',
    }),
    searchCommune({ searchTerm }) {
      this.loading = true;
      this.setSearchTerm({ searchTerm });
      this.searchCommuneFromText({ searchTerm })
        .then((response) => {
          if (response.status === 200) {
            const { places } = response;
            this.textSearchResults = places;
          }
        })
        .catch(() => {
          this.textSearchResults = [];
        });
    },
    searchAddress({ searchTerm }) {
      this.loadingAddress = true;
      this.setLocationAddress({ searchTerm });
      this.searchAddressFromText({ searchTerm, codeNational: this.location.code_national })
        .then((response) => {
          if (response.status === 200) {
            const { address } = response;
            this.textSearchResults = address;
          }
        })
        .catch(() => {
          this.textSearchResults = [];
        });
    },
    select(info) {
      this.cleanResults();
      this.infoSelected = info;
      this.searchTerm = null;
      if (info.current) {
        this.$emit('setCurrentLocation', 0);
      } else if (this.indexTextExpand === 0) {
        this.$emit('setPlaceSelected', info);

        const value = {
          formatted_address: null,
          geocodification_type: null,
          lat: null,
          lng: null,
          source: null,
          upper_location: {},
          street_name: null,
          street_number: null,
        };
        this.setFinalLocationAddress({ address: value });

        this.changePlace({ place: info });
      } else if (this.indexTextExpand === 1 && !info.searchAddress) {
        this.$emit('setAddressSelected', info);
      } else {
        this.searchAddressInGoogle({ searchTerm: info.display1, codeNational: this.location.code_national })
          .then((response) => {
            if (response.status === 200) {
              const gmapsInfo = response.address[0];
              this.infoSelected = gmapsInfo;
              this.$emit('setAddressSelected', gmapsInfo);
            }
          })
          .catch(() => {
            const value = {
              formatted_address: null,
              geocodification_type: null,
              lat: null,
              lng: null,
              source: null,
              upper_location: {},
              street_name: null,
              street_number: null,
            };
            this.setFinalLocationAddress({ address: value });
            this.$emit('openNotFoundAddressModal');
          });
      }
    },
    cleanResults() {
      this.textSearchResults = [];
    },
    setTextFieldLabel() {
      if (this.place.display && !this.infoSelected && this.indexTextExpand === 0) {
        return this.place.display;
      }
      if (this.infoSelected && this.indexTextExpand === 0) {
        return this.infoSelected.display;
      }
      if (this.infoSelected && this.indexTextExpand === 1) {
        return this.location.final_address.formatted_address === null
          ? this.$t(this.placeholder) : this.location.final_address.formatted_address;
      }
      return this.$t(this.placeholder);
    },
  },
};
</script>
