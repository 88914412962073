<template>
  <section class="d-flex h-full" :class="customClassesOfDisplay">
    <div
      :class="{ 'bg-neutral partnership-only-logo': isDisplay('only-logo'), 'p-5 pt-2 px-7': isDisplay('small') }"
    >
      <img
        class="clickable"
        :src="logoPartnership.src"
        :alt="logoPartnership.alt"
        :width="logoPartnership.size"
        @click="actionButton()"
        @keydown="actionButton()"
      >
      <TextAtom
        v-if="!isDisplay('only-logo')"
        :value="partnershipDescription.text"
        tag="p"
        font="inter"
        weight="400"
        :size="isDisplay('full') ? 'title' : ''"
        line-height="medium"
        :color="partnershipDescription.color"
      />
    </div>
    <div
      v-if="!isDisplay('only-logo')"
      class="d-flex flex-column"
      :class="{ 'partnership-button': isDisplay('small') }"
    >
      <div
        v-if="isDisplay('full')"
        :class="isMobile ? 'partnership__video__item--mobile' : 'partnership-container__video__item'"
      >
        <CampusDetailVideo :video="PartnershipVideo" />
      </div>
      <v-btn
        v-if="!isDisplay('full')"
        class="d-flex justify-between mt-2"
        :color="PartnershipButtonColor"
        dark
        @click="actionButton()"
      >
        <TextAtom
          :value="textButton"
          color="secondary-light"
          tag="span"
          font="poppins"
          weight="500"
        />
        <v-icon left>
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>
    <div v-if="isDisplay('full')" class="partnership-container--main-buttons">
      <MainActionButtons
        :main-action-button-content="['option']"
        :options-buttons-text="buttonsText[0]"
        option-legacy-medium
        :color="PartnershipButtonColor"
        option-button-no-margin
        option-button-square
        class="mr-2"
        @setAction="actionButton()"
      />
      <MainActionButtons
        :main-action-button-content="['option']"
        :options-buttons-text="buttonsText[1]"
        option-legacy-medium
        :color="PartnershipButtonColor"
        option-button-no-margin
        option-button-square
        active-option-button
        class="mr-2"
        @setAction="toCalendly()"
      />
    </div>
    <v-dialog
      v-model="showCalendly"
      max-width="900"
      max-height="800"
      overlay-color="black"
      :fullscreen="mobile"
      @click:outside="showCalendly = false"
    >
      <div :class="mobile ? 'calendly-container-mobile' : 'calendly-container'">
        <iframe
          title="calendly"
          :style="mobile ? '' : 'margin-left:-50px;margin-top:-66px;overflow-y:hidden'"
          :width="1000"
          :height="800"
          :scrolling="mobile ? 'yes' : 'no'"
          frameborder="0"
          src="https://calendly.com/jacinta-tether/30min"
          alt="partnership calendly"
          loading="lazy"
        />
      </div>
    </v-dialog>
  </section>
</template>

<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import CampusDetailVideo from '@/components/explorer/general/campus_detail/CampusDetailVideo.vue';
import MainActionButtons from '@/components/molecules/sections/MainActionButtons.vue';
import { mapActions } from 'vuex';

export default {
  name: 'PartnershipCampus',
  components: {
    TextAtom,
    CampusDetailVideo,
    MainActionButtons,
  },
  props: {
    partnership: {
      type: Object,
      default: () => {},
    },
    display: {
      type: String,
      default: 'small',
      validators(value) {
        return ['small', 'full', 'only-logo'].includes(value);
      },
    },
  },
  data() {
    return {
      buttonsText: ['partnerships.meet_us', 'partnerships.enter'],
      showCalendly: false,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    textButton() {
      return this.isDisplay('small') ? 'Ver más' : 'Conócenos';
    },
    customClassesOfDisplay() {
      const classes = {
        small: 'w-full rounded-sm flex-column bg-neutral',
        full: `w-full partnership-full bg-neutral ${this.isMobile ? 'flex-column' : 'flex-row'}`,
        'only-logo': 'pt-4',
      };

      return classes[this.display];
    },
    logoPartnership() {
      return {
        src: this.isDisplay('only-logo') ? this.partnership.image2 : this.partnership.image3,
        alt: this.partnership.name,
        size: this.getSizeLogo(),
      };
    },
    partnershipDescription() {
      return {
        text: this.isDisplay('full') ? this.partnership.descrip : this.partnership.short_descrip,
        color: this.isDisplay('full') ? 'primary-dark' : 'neutral-800',
      };
    },
    PartnershipVideo() {
      return this.partnership.video ?? '';
    },
    PartnershipButtonColor() {
      return this.partnership.color ? `#${this.partnership.color}` : '#5F2AF3';
    },
  },
  mounted() {
    this.setBreadcrum(
      {
        breadcrumbs: [
          {
            text: 'dashboard.breadcrumbs.gallery',
            route: '/gallery',
          },
          {
            text: this.partnership.name,
            route: `partnership/${this.partnership.id}`,
          },
        ],
      },
    );
  },
  methods: {
    ...mapActions({
      setBreadcrum: 'utils/setBreadcrumbs',
    }),
    getSizeLogo() {
      if (this.isDisplay('only-logo')) {
        return 60;
      }
      if (this.isDisplay('small')) {
        return 180;
      }
      return 300;
    },
    isDisplay(type) {
      return this.display === type;
    },
    actionButton() {
      if (this.isDisplay('small')) {
        this.$router.push({
          name: 'Partnership',
          params: {
            id: this.partnership.id,
          },
        });
      } else {
        window.open(this.partnership.main_link, '_blank');
      }
    },
    toCalendly() {
      // window.open(this.partnership.calendly, '_blank');
      this.showCalendly = true;
    },
  },
};
</script>
<style lang="scss">
.partnership-full {
  & > div {
    padding: 1rem 2rem;
    justify-content: center;
  }
}

.partnership-only-logo {
  width: 90px;
  height: 40px;
  padding: 4px 4px;
  border-radius: 30px;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.partnership-button{
  .v-btn{
    border-radius: 0 0 4px 4px;
    height: 48px !important;
    box-shadow: none;
    margin-top: 0px !important;
    text-transform: none;
  }
}
</style>
