var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panels',{staticClass:"contact_panel__container pb-3",model:{value:(_vm.panelsContact),callback:function ($$v) {_vm.panelsContact=$$v},expression:"panelsContact"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"p-0 d-flex flex-column justify-start align-start",attrs:{"disable-icon-rotate":"","expand-icon":""},scopedSlots:_vm._u([{key:"default",fn:function({ open }){return [(_vm.buttonsContact[0].isDisabled && !open)?_c('p',{directives:[{name:"t",rawName:"v-t",value:('contact_panel.contact.school-error'),expression:"'contact_panel.contact.school-error'"}],staticClass:"contact_panel__container__buttons--error pl-8"}):_vm._e(),(!open)?_c('div',{staticClass:"contact_panel__container__buttons"},_vm._l((_vm.buttonsContact),function({ name, isDisabled, icon }){return _c('button',{key:name,class:{
              'contact_panel__container__buttons--plataform':
                name === _vm.$t('contact_panel.contact.plataform'),
              'contact_panel__container__buttons--school':
                name === _vm.$t('contact_panel.contact.school'),
            },attrs:{"type":"button","disabled":isDisabled},on:{"click":function($event){return _vm.selectContact(name)}}},[_c('img',{attrs:{"width":"25","src":require(`@/assets/icons/${icon}`),"alt":"message"}}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(name)+" ")])])}),0):_c('div',{staticClass:"contact_panel__container__header--white px-12 w-full"},[_c('div',{staticClass:"d-flex flex-row"},[_c('p',{directives:[{name:"t",rawName:"v-t",value:(
                _vm.isSelectedContactPlataform
                  ? 'contact_panel.contact.plataform'
                  : 'contact_panel.contact.school'
              ),expression:"\n                isSelectedContactPlataform\n                  ? 'contact_panel.contact.plataform'\n                  : 'contact_panel.contact.school'\n              "}],staticClass:"contact_panel__container__header--white--title"}),_c('button',{staticClass:"contact_panel__container__close",attrs:{"type":"button"}},[_c('v-icon',{attrs:{"color":"#F90E1F","size":"24"}},[_vm._v(" mdi-close-circle ")])],1)]),(!_vm.isSelectedContactPlataform)?_c('p',{staticClass:"contact_panel__container__text"},[_vm._v(" "+_vm._s(_vm.campusDetail.campus_name)+" ")]):_vm._e(),_c('hr')])]}}])}),_c('v-expansion-panel-content',{staticClass:"pb-5"},[_c('Contact',{attrs:{"contacts":_vm.campusDetail.institutioncontact_campus,"is-support-form":_vm.isSelectedContactPlataform},on:{"resetForm":_vm.resetFormContact}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }