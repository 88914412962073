<template>
  <SelectionDialog
    v-if="activeModal === 'LinkBankSelectAccount'"
    :title="`payments.link_bank.dialog.select_account.title`"
    :subtitle="`payments.link_bank.dialog.select_account.subtitle`"
    :options="getOptions()"
    :confirm-button-text="`payments.link_bank.dialog.select_account.confirm`"
    :cancel-button-text="`payments.link_bank.dialog.select_account.cancel`"
    value
    @confirm="confirm($event)"
    @cancel="cancel"
  />
</template>

<script>
import SelectionDialog from '@/components/molecules/dialogs/SelectionDialog.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'LinkBankSelectAccountModal',
  components: {
    SelectionDialog,
  },
  computed: {
    ...mapGetters({
      activeModal: 'utils/activeModal',
      activeModalData: 'utils/activeModalData',
      activeModalCallbacks: 'utils/activeModalCallbacks',
      campusDetail: 'roles/currentHeadMasterMainSchool',
    }),
  },
  methods: {
    ...mapActions({
      deactivateModal: 'utils/deactivateModal',
      setBankAccount: 'payments/setBankAccount',
      finishLinkAccount: 'payments/finishLinkAccount',
      snackbarError: 'utils/error',
    }),
    confirm(selected) {
      this.setBankAccount({ bankAccount: this.activeModalData.accounts[selected] });
      this.finishLinkAccount({
        bankAccount: this.activeModalData.accounts[selected],
        entityCode: this.campusDetail.campus_code,
        bankAccProvId: this.activeModalData.bankAccProvId,
      });
      if (this.activeModalCallbacks.onConfirm) this.activeModalCallbacks.onConfirm();
      this.deactivateModal();
    },
    cancel() {
      if (this.activeModalCallbacks.onCancel) this.activeModalCallbacks.onCancel();
      this.deactivateModal();
    },
    getOptions() {
      const options = [];
      this.activeModalData.accounts.forEach((account) => {
        options.push({
          title: account.name,
          text: account.number,
        });
      });
      return options;
    },
  },

};
</script>
