/**
 * Parse ids from string or number to array of integers
 * @param {*} value - value to parse
 * @returns {Set | number | boolean | string} - parsed value
 */
export const parseQueryParam = (value, typeOfReturn = 'number', multi = true) => {
  let parsedValue = value;

  const parseNumberish = (float = false) => () => {
    if (typeof value === 'string') {
      parsedValue = value.split(',');
    } else if (typeof value === 'number') {
      parsedValue = [value];
    }
    return parsedValue.map((v) => (float ? parseFloat(v) : parseInt(v, 10))).filter((v) => !Number.isNaN(v));
  };

  const parseTypeOfBoolean = () => parsedValue.toLowerCase() === 'true';

  const parseTypeOfString = () => parsedValue;

  const parseTo = {
    number: parseNumberish(),
    float: parseNumberish(true),
    boolean: parseTypeOfBoolean,
    string: parseTypeOfString,
  };

  const parsed = parseTo[typeOfReturn]();

  if (multi) {
    return new Set(Array.isArray(parsed) ? parsed : [parsed]);
  }
  return Array.isArray(parsed) ? parsed[0] : parsed;
};
