<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        :actions-tag=" registerType === 1
          ? 'click_reg_quick-register-name-location_back-button'
          : 'click_reg_detailed-register-name-location_back-button'"
        inline-logo
        small-logo
        @back="back()"
      />
      <RowStepper
        v-if="!isInMap"
        :mixpanel-tag="registerType === 1
          ? 'click_reg_quick-register-name-location_row-stepper'
          : 'click_reg_detailed-register-name-location_row-stepper'"
        :step="3"
      />
      <ModalContent
        :content-sections="['smallTitle', 'subtitle', 'infoBox', 'textSelect']"
        :small-title-text="'user_register.register_location.title'"
        small-title-left
        :subtitle-text="
          inStudentFlow
            ? 'user_register.location_type.arg_subtitle'
            : 'user_register.location_type.subtitle'
        "
        subtitle-light
        :subtitle-arg="
          Object.keys(currentStudent).length > 0 && inStudentFlow ? currentStudent.first_name : ''
        "
        :text-select-items="locationTypesFiltered"
        text-select-item-text="address_name"
        :text-select-initial-value="locationType"
        :text-select-no-info-error="missingInfo && !isActive"
        text-select-return-object
        :text-select-placeholders="'user_register.location_type.placeholder'"
        :info-box-text="setAddress()"
        :info-box-tag="registerType === 1
          ? 'click_reg_quick-register-name-location_address-card'
          : 'click_reg_detailed-register-name-location_address-card'"
        :info-box-icon="'location-pin.svg'"
        :text-select-tag="registerType === 1
          ? 'click_reg_quick-register-name-location_name-dropdown-option-'
          : 'click_reg_detailed-register-name-location_name-dropdown-option-'"
        :click-text-select-tag="registerType === 1
          ? 'click_reg_quick-register-name-location_name-dropdown-selector'
          : 'click_reg_detailed-register-name-location_name-dropdown-selector'"
        info-box-border
        info-box-fill-width
        @setTextSelectValue="setValue"
      />
    </div>
    <CallToActions
      :types="['main']"
      :active-value="isActive"
      :main-button-text="'user_register.continue'"
      :main-button-tag="registerType === 1
        ? 'click_reg_quick-register-name-location_continue-button'
        : 'click_reg_detailed-register-name-location_continue-button'"
      small
      :spaced="false"
      @continue="nextStep()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';

export default {
  name: 'LocationType',
  components: {
    ModalHeader,
    RowStepper,
    ModalContent,
    CallToActions,
  },
  props: {
    isInMap: {
      type: Boolean,
      default: false,
    },
    inStudentFlow: {
      type: Boolean,
      default: false,
    },
    // 1 Quick - 2 Detailed
    registerType: {
      type: Number,
      default: null,
    },
    preFill: {
      type: Boolean,
      default: false,
    },
    preFillData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      locationType: null,
      missingInfo: false,
    };
  },
  computed: {
    ...mapGetters({
      currentStudent: 'authentication/currentStudent',
      locationTypesLabels: 'options/locationTypesLabels',
      legalGuardianAddress: 'authentication/legalGuardianAddress',
      roadTypes: 'options/roadTypes',
      fullAddressDetails: 'userRegister/fullAddressDetails',
      modalInfoFlow: 'userRegister/modalInfoFlow',
    }),
    isActive() {
      return this.locationType !== null;
    },
    locationTypesFiltered() {
      return this.locationTypesLabels.filter((location) => location.id !== 99);
    },
    /* FOR SYSTEM MODAL ENABLED
    setModalUuid() {
      if (this.modalInfoFlow && this.modalInfoFlow.length > 0) {
        const infoModal = this.modalInfoFlow.find((modal) => {
          if (modal.modal_code.indexOf('w') > -1) {
            return modal;
          }
          return null;
        });
        if (infoModal) {
          return infoModal.uuid;
        }
      }
      return null;
    },
    currentRoute() {
      return this.$route.name;
    },
    */
  },
  watch: {
    legalGuardianAddress: {
      handler() {
        this.setAddress();
      },
    },
  },
  created() {
    if (this.preFill) {
      this.doPrefill();
    }
  },
  methods: {
    ...mapActions({
      setFastRegisterLocation: 'userRegister/setFastRegisterLocation',
      setStudentLocation: 'newStudentRegister/setStudentLocation',
      setModalFlowInfo: 'userRegister/setModalFlowInfo',
    }),
    setAddress() {
      if (this.inStudentFlow) {
        if (
          this.currentStudent
          && this.currentStudent.address.length > 0
          && this.currentStudent.address[0].address_details === 'Selecciona tu ubicación'
        ) {
          return this.currentStudent.address[0].location.name;
        }
        return this.currentStudent.address[0].address_details;
      }
      if (
        !this.inStudentFlow
        && this.legalGuardianAddress
        && this.legalGuardianAddress.length > 0
      ) {
        if (this.legalGuardianAddress[0].address_details === 'Selecciona tu ubicación') {
          return this.legalGuardianAddress[0].location;
        }
        if (this.legalGuardianAddress[0].address_details) {
          return this.legalGuardianAddress[0].address_details;
        }
        return this.legalGuardianAddress[0].location;
      }
      return '';
    },
    nextStep() {
      this.missingInfo = false;
      if (this.isActive) {
        if (this.inStudentFlow) {
          const newLocation = {
            uuid: this.currentStudent.uuid,
            address_details: this.currentStudent.address[0].address_details !== null ? this.currentStudent.address[0].address_details : '',
            address_lat: this.currentStudent.address[0].address_lat,
            address_lon: this.currentStudent.address[0].address_lon,
            address_name: this.locationType,
            address_number: this.currentStudent.address[0].address_details,
            location: this.currentStudent.address[0].location.name,
          };
          this.setStudentLocation({ location: newLocation, updateStudent: true });
          /*
          if (
            this.currentStudent.first_name &&
            this.currentStudent.first_lastname &&
            this.currentStudent.gender &&
            this.currentStudent.birth_date &&
            this.currentStudent.identification_number &&
            this.currentStudent.identification_type &&
            this.currentStudent.address &&
            this.currentRoute === 'Dashboard'
          ) {
            const modalUuid = this.setModalUuid;
            const modalInfo = {
              user: '',
              modal_code: '5M.w',
              modal_response: true,
              action_completed: true,
            };
            this.setModalFlowInfo({ modalInfo, modalUuid });
          }
          */
        } else {
          const newLocation = {
            address_details: this.legalGuardianAddress[0].address_details !== null ? this.legalGuardianAddress[0].address_details : '',
            address_lat: this.legalGuardianAddress[0].address_lat,
            address_lon: this.legalGuardianAddress[0].address_lon,
            address_name: this.locationType,
            address_street: this.legalGuardianAddress[0].address_details,
            address_number: this.legalGuardianAddress[0].address_details,
            location: this.legalGuardianAddress[0].location,
          };
          this.setFastRegisterLocation({
            location: newLocation,
          });
        }
        this.$emit('nextStep');
      } else {
        this.missingInfo = true;
      }
    },
    setValue(index, value) {
      if (index === 0) {
        this.locationType = value;
      }
    },
    back() {
      return this.$emit('backStep');
    },
    doPrefill() {
      if (this.preFillData.address?.length > 0) {
        this.locationType = this.preFillData.address[0].address_name;
      }
    },
  },
};
</script>
