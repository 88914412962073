<template>
  <div class="iconSelect">
    <v-select
      v-model="value"
      :placeholder="$t(placeholder)"
      height="48"
      item-value="item"
      class="iconSelect--select-icon"
      :class="{ 'register-main register-main--text-select-input': isInInputSection }"
      :items="items"
      :disabled="disabled"
      @change="$emit('setValue', index, value)"
      @click="hideLabel = false"
    >
      <template v-if="hideLabel" #label>
        <v-icon :color="iconColor">
          {{ items[0] }}
        </v-icon>
      </template>
      <template #selection="{ item }">
        <v-icon class="d-flex jusitfy-center" :color="iconColor">
          {{ item }}
        </v-icon>
      </template>
      <template #item="{ item }">
        <v-icon :color="iconColor">
          {{ item }}
        </v-icon>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'IconTextSelect',
  components: {},
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isInInputSection: {
      type: Boolean,
      default: false,
    },
    selectValue: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '#7a7a7a',
    },
  },
  data() {
    return {
      value: null,
      hideLabel: true,
    };
  },
  mounted() {
    if (this.selectValue) {
      this.value = this.selectValue;
    }
  },
};
</script>
