<template>
  <section class="bg-secondary-light">
    <template v-if="showRecommendations">
      <div
        v-for="(recommendation, index) in recommendationsFiltered"
        :key="index"
        class="w-full d-flex flex-column  bg-neutral rounded-lg px-5 py-5 reveal-animation"
        :class="index === 0 ? 'mt-7' : 'mt-5'"
        style="--d: 0.09s"
      >
        <!-- campus -->
        <div class="mb-4 d-flex flex-row reveal-animation" style="--d: 0.1s">
          <!-- campus information -->
          <div class="d-flex flex-column mx-3 w-full">
            <TextAtom
              :value="recommendation?.rec_campus_name"
              tag="h3"
              font="inter"
              color="primary-dark"
              weight="500"
              size="normal"
              class="text-left"
            />
            <!-- campus description -->
            <div class="d-flex w-full flex-row flex-wrap mt-2 feedback-text-wrap-balance">
              <TextAtom
                v-for="(description) in descriptionCampus(recommendation)"
                :key="description"
                :value="description"
                tag="span"
                font="inter"
                color="neutral-800"
                weight="400"
                size="default"
                class="text-left"
              />
            </div>
            <!-- campus agreements -->
            <div class="d-flex w-full flex-row flex-wrap mt-3 feedback-text-wrap-balance">
              <TextAtom
                v-for="(agreement, indexAgreement) in agreementsCampus(recommendation)"
                :key="agreement"
                :value="agreement"
                tag="span"
                font="inter"
                color="primary-dark"
                weight="500"
                size="default"
                class="campus-agreement"
                :class="indexAgreement === 0 ? '' : 'ml-2'"
              />
            </div>
          </div>
        </div>
        <!-- campus categories -->
        <CampusCategories
          class="reveal-animation"
          style="--d: 0.17s"
          :campus="getCampus(recommendation)"
          type-configuration="FEEDBACK"
          :default-data="{
            ADMISSION_RISK: recommendation?.rec_prob_string,
            PERFORMANCE: recommendation?.rec_quality_string,
            DISTANCE: recommendation?.rec_distance_category,
            PAYMENT: recommendation?.rec_cost_string,
          }"
        />
        <button
          v-t="'feedback.show_campus'"
          type="button"
          class="mt-2 show-profile"
          @click="exploreCampusDetail(recommendation.rec_link_perfil, index)"
        />
      </div>
    </template>
    <button
      v-t="'feedback.go_to_explorer'"
      type="button"
      class="go-to-explorer"
      :class="showRecommendations ? 'mt-10' : ''"
      @click="goToExplorer()"
    />
    <v-dialog
      v-model="campusDetailModal"
      transition="dialog-bottom-transition"
      max-width="800px"
      content-class="favorites__dialog"
    >
      <CampusDetail
        v-if="campusDetailModal"
        style="z-index: 1000"
        in-dashboard
        @close="closeCampusDetail"
      />
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import CampusDetail from '@/components/explorer/general/campus_detail/CampusDetail.vue';
import CampusCategories from '@/components/organisms/campus/information/CampusCategories.vue';
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'FeedbackRecommendations',
  components: {
    TextAtom, CampusDetail, CampusCategories,
  },
  props: {
    recommendations: {
      type: Array,
      required: true,
    },
    showRecommendations: {
      type: Boolean,
      default: false,
    },
    studentUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      campusDetailModal: false,
    };
  },
  computed: {
    ...mapGetters({
      studentList: 'authentication/students',
    }),
    recommendationsFiltered() {
      return this.recommendations
        .filter((recommendation) => recommendation.rec_order > 0)
        .sort((a, b) => a.rec_order - b.rec_order);
    },
  },
  methods: {
    ...mapActions({
      updateCurrentSchool: 'institutions/updateCurrentSchool',
      setCurrentStudent: 'authentication/setCurrentStudent',
      setIsShowDigitalProfile: 'feedback/setIsShowDigitalProfile',
      deactivateModal: 'utils/deactivateModal',
    }),
    goToExplorer() {
      const student = this.studentList.find((st) => st.uuid === this.studentUuid);
      this.setCurrentStudent({ student });
      trackMixPanel('fbin_postind_explorador');
      this.$router.push({ name: 'Explorer' });
      this.deactivateModal();
    },
    getCampus(recommendation) {
      return {
        performance_set: [
          {
            qualitycategory_level_label: {
              // eslint-disable-next-line no-unsafe-optional-chaining
              id: +recommendation?.rec_quality_category,
            },
          },
        ],
        institution_payment_campus: [
          {
            payment_type: { id: 2 },
            // eslint-disable-next-line no-unsafe-optional-chaining
            payment_category: { id: +recommendation?.rec_cost_category === 5 ? 1 : +recommendation?.rec_cost_category },
            program: null,
          },
        ],
        distance: recommendation?.rec_distance_string,
        // eslint-disable-next-line no-unsafe-optional-chaining
        riskLabel: +recommendation?.rec_prob_category,
      };
    },
    descriptionCampus(recommendation) {
      const descriptions = [];
      descriptions.push(`${recommendation?.rec_program_name} ・` || '');
      descriptions.push(`${recommendation?.rec_jornada_label} ・` || '');
      descriptions.push(`${recommendation?.rec_gender_label}` || '');
      return descriptions;
    },
    agreementsCampus(recommendation) {
      return [
        recommendation?.rec_PIE_label || '',
        recommendation?.rec_SEP_label || '',
      ].filter((label) => label !== '');
    },
    closeCampusDetail() {
      this.campusDetailModal = false;
    },
    async exploreCampusDetail(link, index) {
      this.setIsShowDigitalProfile(false);
      this.campusDetailModal = true;
      trackMixPanel('fbin_postind_explorar_rec', { campusCode: Number(link).toFixed(0), rec_order: index + 1, studentUuid: this.studentUuid });
      await this.updateCurrentSchool({ campusCode: Number(link).toFixed(0), fromUrl: true });
    },
  },
};
</script>
<style lang="scss" scoped>
.go-to-explorer {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-dark);
  color: var(--neutral);
  border-radius: 30px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.28px;
}

.reveal-animation {
  opacity: 0;
  animation: reveal 0.5s forwards var(--d)
}

.show-profile {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-medium-light);
  color: var(--neutral);
  border-radius: 30px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.28px;
}

@keyframes reveal {
  from {
    transform: translateY(20px);
  }
  to {
    opacity: 1; transform: none;
  }
}
</style>
