import { messagingApi, crmLeadsAPI } from '@/api';
import CONFIG from '@/config';

const getAuthHeader = (authToken) => {
  if (!authToken) {
    return undefined;
  }

  return {
    Authorization: `Bearer ${authToken}`,
  };
};

const tableName = CONFIG.environment === ('development' || 'staging') ? 'messages_staging' : 'messages';

export default {
  // TODO: LEGACY code, please remove getMessagesCount, getMessages, getGroupedMessages when CRM Leads and new support
  // messages are fully implemented
  getMessagesCount() {
    return messagingApi.get(`/count/table/${tableName}/`);
  },
  getMessages({ schoolUuid }) {
    const indexName = 'school_uuid';
    return messagingApi.get(`/mailing/${tableName}/search/${indexName}/${schoolUuid}/`);
  },
  getGroupedMessages({ index, uuid, groupBy }) {
    return messagingApi.get(`/stats/${tableName}/${index}/${uuid}/${groupBy}/`);
  },
  // NEW
  sendCustomerSupportMessage(data, authToken) {
    return messagingApi.post('mailing/support/sendmail/', data, {
      headers: getAuthHeader(authToken),
    });
  },
  sendLeadMessage(data, authToken) {
    return crmLeadsAPI.post('new-leads/messages', data, {
      headers: getAuthHeader(authToken),
    });
  },
  // TODO: NOT YET USED IN COMPONENTS
  getNewLeadsMessagesCount({ campusCode }, authToken) {
    return crmLeadsAPI.get('new-leads/messages/count', {
      headers: {
        'X-Campus-Id': campusCode,
        ...getAuthHeader(authToken),
      },
    });
  },
  getNewLeadsMessages({ campusCode }, authToken) {
    return crmLeadsAPI.get('new-leads/messages', {
      headers: {
        'X-Campus-Id': campusCode,
        ...getAuthHeader(authToken),
      },
    });
  },
  getGroupedNewLeadsMessages({ campusCode, groupBy }, authToken) {
    return crmLeadsAPI.get(`new-leads/messages/stats/${groupBy}`, {
      headers: {
        'X-Campus-Id': campusCode,
        ...getAuthHeader(authToken),
      },
    });
  },
};
