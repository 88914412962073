<template>
  <!-- eslint-disable vue/require-v-for-key -->
  <section v-if="pridePoints.length > 0" class="campus-detail__pride__point">
    <h2 class="campus-detail__pride__point--title">
      {{ $t('campus-details.pride_points') }}
    </h2>
    <template
      v-for="{ pridepoint_description, pridepoint_title, images_pridepoint } in sortPridePoints"
    >
      <div v-if="pridepoint_title" class="campus-detail__pride__point--container mt-1">
        <h3 class="campus-detail__pride__point--subtitle">
          {{ pridepoint_title }}
        </h3>
      </div>

      <p
        class="campus-detail__pride__point--text campus-detail__pride__point--container mt-2 ml-1"
        :class="{ 'mt-0 mb-3': !exitsImagesPridePoint(images_pridepoint) }"
      >
        {{ pridepoint_description }}
      </p>
      <!-- eslint-disable-next-line vue/valid-v-for -->
      <v-carousel
        v-if="exitsImagesPridePoint(images_pridepoint)"
        hide-delimiter-background
        show-arrows-on-hover
        class="mt-3 mb-5 campus-detail__pride__point--carrousel"
      >
        <v-carousel-item
          v-for="{ image, index } in images_pridepoint"
          :key="index"
          reverse-transition="fade-transition"
          transition="fade-transition"
        >
          <picture>
            <ImageAtom
              class="campus-detail__pride__point--image"
              :src="image"
              :alt="'imagePridepoint'"
              height="100%"
              width="100%"
              is-responsive
              object-fit="contain"
            />
          </picture>
        </v-carousel-item>
      </v-carousel>
      <!-- <hr v-if="pridePoints.length !== index + 1" /> -->
    </template>
  </section>
</template>

<script>
import ImageAtom from '@/components/atoms/images/ImageAtom.vue';

export default {
  name: 'PridePoints',
  components: {
    ImageAtom,
  },
  props: {
    pridePoints: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sortPridePoints: Array,
    };
  },
  computed: {
    exitsImagesPridePoint() {
      return (images) => images.length > 0;
    },
  },
  mounted() {
    this.sortPridePoints = [...this.pridePoints];

    if (this.sortPridePoints.length > 1) {
      this.sortPridePoints.sort((a, b) => a.pridepoint_order - b.pridepoint_order);
    }
  },
};
</script>
