<template>
  <v-dialog
    v-model="innerValue"
    scrollable
  >
    <v-card
      v-click-outside="(e) => persistent || cancel(e)"
      class="dialog mx-auto"
      :class="{
        'dialog--wide': wide,
        'dialog--mobile': mobile,
        'dialog--loading': loading,
      }"
    >
      <LoaderCircular
        v-if="loading"
        :show="loading"
        :size="60"
        :width="8"
      />
      <template v-else>
        <v-card-title>
          <slot name="banner" />

          <slot name="title" :title="title">
            <SmallTitle
              text="shared.grade.title"
              no-margin
            />
          </slot>
        </v-card-title>
        <v-card-subtitle>
          <div v-if="warning" class="dialog__warning">
            <slot name="warning" :warning="warning">
              <MDIIcon icon="mdi-alert-outline" color="#e94c6e" />
              <span v-t="warning" />
            </slot>
          </div>
          <div v-if="subtitle" class="dialog__subtitle">
            <slot name="subtitle" :subtitle="subtitle">
              <span v-t="subtitle" />
            </slot>
          </div>
        </v-card-subtitle>
        <v-card-text class="dashboard-scrollbar">
          <div v-if="infoToConfirm" v-t="infoToConfirm" class="dialog__info">
            <slot name="info" :info-to-confirm="infoToConfirm">
              <span v-t="infoToConfirm" />
            </slot>
          </div>
          <slot />
        </v-card-text>

        <v-card-actions
          class="w-full"
        >
          <slot name="pre-actions" />
          <div
            class="dialog__actions"
            :class="{
              'dialog__actions--mobile': mobile,
              'dialog__actions--single': !mobile && (hideCancelButton || hideConfirmButton),
            }"
          >
            <slot name="actions">
              <OptionButtons
                v-if="showCancelButton"
                class="dialog__actions__button mr-1"
                :text="cancelButtonText || 'dialog.confirm.no'"
                no-margin
                @nextStep="cancel"
              />
              <OptionButtons
                v-if="!hideConfirmButton"
                class="dialog__actions__button ml-1"
                :text="confirmButtonText || (showCancelButton ? 'dialog.confirm.yes' : 'dialog.confirm.acknowledged')"
                :is-active="canContinue"
                no-margin
                @nextStep="confirm"
              />
            </slot>
          </div>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import OptionButtons from '@/components/atoms/buttons/OptionButtons.vue';
import MDIIcon from '@/components/atoms/icons/MdiIcon.vue';
import LoaderCircular from '@/components/atoms/loaders/LoaderCircular.vue';
import SmallTitle from '@/components/atoms/titles/SmallTitle.vue';

export default {
  name: 'ConfirmationDialog',
  components: {
    OptionButtons,
    LoaderCircular,
    MDIIcon,
    SmallTitle,
  },
  props: {
    title: {
      type: [Object, String],
      default: () => null,
    },
    subtitle: {
      type: [Object, String],
      default: () => null,
    },
    infoToConfirm: {
      type: String,
      default: '',
    },
    warning: {
      type: String,
      default: '',
    },
    hideCancelButton: {
      type: Boolean,
      default: false,
    },
    hideConfirmButton: {
      type: Boolean,
      default: false,
    },
    confirmButtonText: {
      type: String,
      default: '',
    },
    cancelButtonText: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    wide: {
      type: Boolean,
      default: false,
    },
    canContinue: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    innerValue: false,
  }),
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    showCancelButton() {
      return this.mobile || !this.hideCancelButton;
    },
  },
  watch: {
    value() {
      this.innerValue = this.value;
    },
  },
  created() {
    this.innerValue = this.value;
  },
  methods: {
    cancel(e) {
      this.$emit('cancel', e);
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>

<style>

</style>
