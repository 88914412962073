<template>
  <div class="register-main register-main--spaced">
    <ModalHeader
      :content-sections="['logo']"
      inline-logo
      small-logo
    />
    <ModalContent
      :content-sections="['title', 'subtitle']"
      :title-text="'user_register.register_finale.final_message'"
      :subtitle-text="'user_register.register_finale.final_message_subtitle'"
      subtitle-center
      subtitle-light
    />
    <CallToActions
      :option-text1="'user_register.register_finale.role_btn'"
      :option-text-active-tag="registerType === 1
        ? 'click_reg_quick-register-account-created_add-role-button'
        : 'click_reg_detailed-register-account-created_add-role-button'"
      :option-text2="'user_register.register_finale.explore_btn'"
      :option-text-tag="registerType === 1
        ? 'click_reg_quick-register-account-created_go-to-explore-button'
        : 'click_reg_detailed-register-account-created_go-to-explore-button'"
      :types="['option']"
      :spaced="false"
      @nextStep="goTo"
    />
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import { mapActions } from 'vuex';

export default {
  name: 'RegisterFinalModal',
  components: { ModalHeader, ModalContent, CallToActions },
  props: {
    // 1 Quick - 2 Detailed
    registerType: {
      type: Number,
      default: null,
    },
  },
  methods: {
    ...mapActions({
      setCurrentStep: 'userRegister/setCurrentStep',
    }),
    goTo(to) {
      if (to === 2) {
        return this.$router.push('/map');
      }
      return this.$emit('nextStep');
    },
  },
};
</script>
