<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        v-if="!inSimulation"
        :content-sections="['actions', 'logo']"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <RowStepper v-if="!inSimulation" :step="3" />
      <section v-for="priority, index in prioritiesArray" :key="index">
        <ModalContent
          :content-sections="index === 0 && !inSimulation
            ? ['smallTitle', 'subtitle', 'selectionButton'] : ['subtitle', 'selectionButton']"
          :small-title-text="'user_register.additional_info.title'"
          small-title-left
          :subtitle-text="priority.title"
          :subtitle-arg="Object.keys(currentStudent).length > 0 ? currentStudent.first_name : ''"
          :selection-button-info="priority.selectionButtons"
          @setSelectionButtonValue="setButtonsValue($event, index)"
        />
      </section>
    </div>
    <section class="d-flex align-center justify-end fill-width">
      <CallToActions
        style="margin-top: 40px; margin-right: 8px"
        :types="['main']"
        :active-value="!inSimulation ? isActive : false"
        :main-button-text="inSimulation
          ? 'user_register.brother_in_campus.cancel' : 'user_register.continue'"
        small
        :main-button-tiny="mobile"
        :spaced="false"
        :main-active-light="inSimulation"
        @continue="inSimulation ? $emit('backStep') : nextStep()"
      />
      <CallToActions
        v-if="inSimulation"
        style="margin-top: 40px; margin-left: 8px"
        :types="['main']"
        :active-value="isActive"
        :main-button-text="'user_register.brother_in_campus.confirm'"
        small
        :main-button-tiny="mobile"
        width-stretch
        :spaced="false"
        @continue="nextStep()"
      />
    </section>
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import CONFIG from '@/config';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'BooleanPriorities',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
    RowStepper,
  },
  props: {
    booleanPriorities: {
      type: Array,
      default() {
        return [];
      },
    },
    inSimulation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      prioritiesLoader: false,
      sep: null,
      neep: null,
    };
  },
  computed: {
    ...mapGetters({
      currentStudent: 'authentication/currentStudent',
      guestSimulationData: 'simulation/guestSimulationData',
      isGuest: 'authentication/isGuest',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    // Computed for array of boolean priorities, question title and button title and active logic
    prioritiesArray() {
      const priorities = [];
      if (CONFIG.tenant === 'chile') {
        if (this.booleanPriorities.includes('sep')) {
          priorities.push(
            {
              title: this.isGuest
                ? 'user_register.boolean_priorities.sep_title_guest'
                : 'user_register.boolean_priorities.sep_title',
              selectionButtons: [
                { label: 'user_register.priority_question.yes', isActive: this.sep === 0 },
                { label: 'user_register.priority_question.no', isActive: this.sep === 1 },
              ],
            },
          );
        }
        if (this.booleanPriorities.includes('neep')) {
          priorities.push(
            {
              title: this.isGuest
                ? 'user_register.boolean_priorities.nee_title_guest'
                : 'user_register.boolean_priorities.nee_title',
              selectionButtons: [
                { label: 'user_register.priority_question.yes', isActive: this.neep === 0 },
                { label: 'user_register.priority_question.no', isActive: this.neep === 1 },
              ],
            },
          );
        }
      }
      return priorities;
    },
    isActive() {
      if (this.booleanPriorities.includes('sep') && this.booleanPriorities.includes('neep')) {
        if (this.neep !== null && this.sep !== null) {
          return true;
        }
        return false;
      }
      if (this.booleanPriorities.includes('sep')) {
        if (this.sep !== null) {
          return true;
        }
        return false;
      }
      if (this.booleanPriorities.includes('neep')) {
        if (this.neep !== null) {
          return true;
        }
        return false;
      }
      return false;
    },
  },
  mounted() {
    const hasSep = this.isGuest
      ? this.guestSimulationData.sep
      : this.currentStudent.prioritys.appl_prios.has_sep;
    if (this.booleanPriorities.includes('sep') && hasSep) {
      this.sep = 0;
    }
    const hasNeep = this.isGuest
      ? this.guestSimulationData.nee
      : this.currentStudent.prioritys.appl_prios.has_neep;
    if (this.booleanPriorities.includes('neep') && hasNeep) {
      this.neep = 0;
    }
  },
  methods: {
    ...mapActions({
      setSepPriority: 'newStudentRegister/setSepPriority',
      setNeepPriority: 'newStudentRegister/setNeepPriority',
      setGuestSimulationData: 'simulation/setGuestSimulationData',
    }),
    // patching and then NextStep in register
    nextStep() {
      if (this.isActive) {
        if (this.booleanPriorities.includes('sep')) {
          const sepPayload = {
            uuid: this.currentStudent.uuid,
            add: this.sep === 0,
          };
          if (this.isGuest) {
            this.setGuestSimulationData({ key: 'sep', value: sepPayload.add }).then(() => {
              this.prioritiesLoader = false;
              this.$emit('nextStep');
            });
          } else {
            this.setSepPriority({ sepPayload }).then(() => {
              this.prioritiesLoader = false;
              this.$emit('nextStep');
            });
          }
        }
        if (this.booleanPriorities.includes('neep')) {
          const neepPayload = {
            uuid: this.currentStudent.uuid,
            add: this.neep === 0,
          };
          if (this.isGuest) {
            this.setGuestSimulationData({ key: 'nee', value: neepPayload.add }).then(() => {
              this.prioritiesLoader = false;
              this.$emit('nextStep');
            });
          } else {
            this.setNeepPriority({ neepPayload }).then(() => {
              this.prioritiesLoader = false;
              this.$emit('nextStep');
            });
          }
        }
      }
      return null;
    },
    // Set Button value by index of the array of content, it depends of array given
    // Chile: index - 0 = SEP, index - 1 = NEEP / inputSelected = 0: First input, inputSelected = 1 second input
    setButtonsValue(inputSelected, index) {
      if (this.booleanPriorities.includes('sep') && this.booleanPriorities.includes('neep')) {
        if (index === 0) {
          this.sep = inputSelected;
        }
        if (index === 1) {
          this.neep = inputSelected;
        }
      } else {
        if (this.booleanPriorities.includes('sep')) {
          this.sep = inputSelected;
        }
        if (this.booleanPriorities.includes('neep')) {
          this.neep = inputSelected;
        }
      }
      return inputSelected;
    },
  },
};
</script>
