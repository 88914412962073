a
<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<template>
  <div class="all-wrapper">
    <div class="boton-chat-wrapper">
      <div
        v-if="!showChat"
        :class="['boton-chat', !askedForHuman ? 'chat-open' : 'human-chat-open']"
        @mouseenter="showHoverMessage = true"
        @mouseleave="showHoverMessage = false"
        @click="toggleChat"
        @keydown.enter="toggleChat"
      >
        <div v-if="showHoverMessage" class="boton-chat-message">
          <p>¿Necesitas ayuda?</p>
        </div>
        <v-avatar size="64" color="#5627FF">
          <v-icon color="white">
            mdi-message-outline
          </v-icon>
        </v-avatar>
        <!--<img src="./images/boton_chat.png" alt="Chat Icon" />-->
      </div>
      <div
        v-if="showChat"
        :class="['boton-close-chat', !askedForHuman ? 'chat-open' : 'human-chat-open']"
        @click="toggleChat"
        @keydown.enter="toggleChat"
      >
        <img src="./images/close_chat.png" alt="Close Chat Icon" />
      </div>
    </div>
    <div
      v-if="intercomPresent"
      class="back-to-chatbot"
      style="background: #3e1cc5"
      @click="initiateBotChat"
      @keydown.enter="initiateBotChat"
    >
      <img
        src="./images/exploguia.svg"
        style="width: 36px; height: 36px; margin-left: 6px; margin-top: 8px"
        alt="Exploguia logo"
      />
    </div>
    <div v-if="showChat" class="gpt-chat" :style="containerStyle">
      <div v-if="showInfoWindow" class="info-window">
        <div class="info-window-header chat-header">
          <div class="header-logo">
            <img src="./images/exploguia.svg" alt="Exploguia logo" />
          </div>
        </div>
        <div class="info-window-content chat-container">
          <div class="info-window-text-wrapper">
            <div class="info-window-text-section">
              <h2>Bienvenido/a</h2>
              <h1>a ExploGuía</h1>
            </div>
            <div class="info-window-text-section">
              <p>Resuelve todas tus dudas en este chat de asistencia.</p>
            </div>
            <div class="info-window-text-section">
              <p>
                Contamos con un guía personalizado que estará encantado de ayudarte a encontrar
                establecimientos educativos con vacantes.
              </p>
            </div>
            <!--<div class="info-window-text-section">
              <p>
                Puedes contarnos dónde estás buscando establecimientos, cuándo necesitas ingresar y
                para qué grado estás buscando, entre otros detalles.
              </p>
            </div>
            <div class="info-window-text-section">
              <p>
                Nuestro objetivo es proporcionarte la mejor asistencia posible para que encuentres
                la opción educativa que mejor se adapte a tus necesidades.
              </p>
            </div>-->
          </div>

          <div class="info-window-button mb-2" @click="initiateChat" @keydown.enter="initiateChat">
            <p>Encontrar establecimientos educativos</p>
            <v-icon alt="envía un mensaje" class="me-4" color="#3E1CC5">
              mdi-send-variant
            </v-icon>
            <!--<img src="./images/envia_mensaje.png" alt="envia un mensaje" />-->
          </div>
          <div
            class="info-window-button"
            @click="initiateHumanChat"
            @keydown.enter="initiateHumanChat"
          >
            <p>Necesito asistencia</p>
            <v-icon alt="envía un mensaje" class="me-4" color="#3E1CC5">
              mdi-account
            </v-icon>
          </div>
        </div>
      </div>
      <div v-if="!showInfoWindow" class="chat-c">
        <div class="chat-header">
          <div class="chat-back">
            <v-icon color="white" @click="showInfoWindow = true">
              mdi-chevron-left
            </v-icon>
          </div>
          <div class="header-avatar">
            <img src="./images/avatar_chat_light.png" alt="Bubble Icon" />
          </div>
          <div class="header-text ms-2">
            <h1>Chat</h1>
            <div class="connected">
              <svg
                v-if="isConnected"
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="#52D931"
              >
                <circle cx="5" cy="5" r="5" />
              </svg>
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="red"
              >
                <circle cx="5" cy="5" r="5" />
              </svg>
              <p v-if="isConnected">
                Tu guía esta en línea
              </p>
              <p v-else>
                Chat desconectado
              </p>
            </div>
          </div>
          <div class="header-logo ms-auto">
            <img src="./images/exploguia.png" alt="Exploguia logo" />
          </div>
        </div>
        <div class="chat-container">
          <div id="chatContent" class="chat-content">
            <div
              v-for="(message, index) in messages"
              :key="index"
              :class="['chat-message', message.type]"
            >
              <div :class="['message-icon', `${message.type}-i`]">
                <!-- todo: add user profile -->
                <v-avatar v-if="message.type == 'user'" size="40" color="#3E1CC5">
                  <v-icon small color="white">
                    mdi-account
                  </v-icon>
                </v-avatar>
                <!--<v-avatar size="40" color="#3E1CC5" v-else-if="message.type == 'response'">
                  <v-icon small color="white">mdi-robot</v-icon></v-avatar
                >-->
                <!--<img v-if="message.type === 'user'" src="./images/Avatar.png" alt="Bubble Icon" />-->
                <img
                  v-else-if="message.type === 'response'"
                  src="./images/avatar_chat.png"
                  alt="Bubble Icon"
                />
              </div>
              <!--<div :class="['svg-item', `${message.type}-svg`]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="25"
                  viewBox="0 0 13 25"
                  fill="none"
                >
                  <path d="M13 12.39L0.25 24.5143V0.265625L13 12.39Z" />
                </svg>
              </div>-->
              <div
                v-if="message.type === 'card'"
                style="width: 100%; min-width: 100%; padding-right: 20px"
              >
                <SchoolMessageCard :message="message" />
              </div>
              <div
                v-if="message.type === 'list-card'"
                style="width: 100%; min-width: 100%; padding-right: 40px !important"
              >
                <ProcessesMessageCard :message="message" />
              </div>
              <div
                v-else
                :class="['message-content', `${message.type}-m`]"
                style="position: relative"
                v-html="message.text"
              />
            </div>
            <div v-if="showLoading" :class="['chat-message', 'loading-container']">
              <div :class="['message-icon', `response-i`]">
                <!--<v-avatar size="40" color="#3E1CC5"
                  ><v-icon small color="white">mdi-robot</v-icon></v-avatar
                >-->
                <img src="./images/avatar_chat.png" alt="Bubble Icon" />
              </div>
              <div :class="['svg-item', `response-svg`]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="25"
                  viewBox="0 0 13 25"
                  fill="none"
                >
                  <path d="M13 12.39L0.25 24.5143V0.265625L13 12.39Z" />
                </svg>
              </div>
              <div :class="['message-content', `response-m`]">
                <div v-if="showLoading" style="min-width: 12px; width: 12px" class="loading" />
              </div>
            </div>
          </div>
          <div class="chat-input d-flex justify-space-between">
            <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
            <textarea
              v-model="newMessage"
              placeholder="Escribe tu mensaje..."
              @keyup.enter="sendMessage"
            />

            <div class="chat-button text-end">
              <v-avatar color="#4135f4" :size="42" rounded>
                <v-icon
                  small
                  color="white"
                  alt="Send Icon"
                  @click="sendMessage"
                  @keydown.enter="sendMessage"
                >
                  mdi-send
                </v-icon>
              </v-avatar>

              <!--<img
                src="./images/boton.png"
                alt="Send Icon"

              />-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { blankFilterContext } from '@/utils/filters';
import institutionsService from '@/services/institutions.services';
import trackMixPanel from '@/utils/mixpanel';
import CONFIG from '@/config';
import {
  TETHER_FILTER_KEYS as FILTER_KEYS,
  TETHER_FILTER_CONTEXTS as FILTER_CONTEXTS,
} from '@/constants/filters';
import SchoolMessageCard from './SchoolMessageCard.vue';
import ProcessesMessageCard from './ProcessesMessageCard.vue';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Chatbot',
  components: {
    SchoolMessageCard,
    ProcessesMessageCard,
  },
  props: {
    context: {
      type: String,
      default: () => FILTER_CONTEXTS.EXPLORER,
      validation: (value) => Object.values(FILTER_CONTEXTS).includes(value),
    },
  },
  data() {
    return {
      intercomPresent: false,
      schoolsWithVacanciesNumber: 0,
      schoolsWithNoVacanciesNumber: 0,
      schoolsWithUnknownVacanciesNumber: 0,
      applyingFilter: false,
      filterText: '',
      alreadyRecommended: [],
      showInfoWindow: true,
      showHoverMessage: false,
      filterApplied: false,
      askedForHuman: false,
      showChat: false,
      isConnected: false,
      stagedFilters: blankFilterContext(),
      containerStyle: {
        position: 'absolute',
        bottom: '80px',
        right: '20px',
      },
      showLoading: false,
      selectedGrades: new Set(),
      selectedStages: new Set(),
      messages: [],
      newMessage: '',
      sendFirstMessage: false,
    };
  },
  computed: {
    ...mapGetters({
      navbarVacanciesFilter: 'filters/navbarVacanciesFilter',
      schoolsVacancies: 'institutions/schoolsVacancies',
      storeFilters: 'filters/filters',
      schools: 'institutions/schools',
      filteredExplorerSchools: 'filters/filteredExplorerSchools',
      currentStudent: 'authentication/currentStudent',
      sessionUUID: 'authentication/sessionUUID',
      isGuest: 'authentication/isGuest',
      cornerBounds: 'explorer/mapCornerBounds',
      legalGuardianInterestGrades: 'authentication/legalGuardianInterestGrades',
      guestGrades: 'authentication/guestGrades',
    }),
    waitingForResponse() {
      return (
        this.messages.length > 0
        && this.messages[this.messages.length - 1].type === 'response_loading'
      );
    },
  },
  watch: {
    context(currentContext) {
      this.switchFiltersContext(currentContext);
    },
    messages: {
      handler() {
        this.showLoading = false; // Reset the loading state
        this.displayLoading(); // Check and display loading if needed
      },
      deep: true,
    },
  },
  beforeMount() {
    this.switchFiltersContext(this.context);
  },
  mounted() {
    if (this.$intercom && this.$intercom.update) this.$intercom.update({ hide_default_launcher: true });
  },
  methods: {
    ...mapActions({
      updateCurrentSchool: 'institutions/updateCurrentSchool',
      setContext: 'filters/setContext',
      setGuestData: 'authentication/setGuestData',
      commitFilters: 'filters/commitFilters',
      setSimulationModalCompleted: 'simulation/setSimulationModalCompleted',
      setGuestLocation: 'authentication/setGuestLocation',
      retrieveCampuses: 'institutions/retrieveCampuses',
      setFastRegisterGrade: 'userRegister/setFastRegisterGrade',
    }),
    transformLinks(s1) {
      const rxCommonMarkLink = /(\[([^\]]+)])\(([^)]+)\)/g;

      // replace markdown urls [text](url) -> <a href="url" target="_blank">text</a>
      let transformed = s1 ? s1.replace(rxCommonMarkLink, '<a href="$3" target="_blank"> $2 </a>') : s1;

      // Replace other urls not within anchor tags. url -> <a href="url" target="_blank"">url</a>"
      const urlPattern = /(?<!<a href=['"])(https?:\/\/[^\s<]+|www\.[^\s<]+)(?![^<>]*<\/a>)/gi;
      transformed = transformed.replace(urlPattern, (match) => {
        const url = match.startsWith('http') ? match : `http://${match}`;
        return `<a target='_blank' href='${url}'>${match}</a>`;
      });
      return transformed;
    },
    initiateBotChat() {
      this.askedForHuman = false;
      this.$intercom.hide();
      this.intercomPresent = false;
      this.showChat = true;
    },
    initiateHumanChat() {
      this.askedForHuman = true;
      this.$intercom.show();
      this.intercomPresent = true;
      this.finishChat();
    },
    getSchoolsWithinBounds() {
      let count = 0;
      if (!Array.isArray(this.cornerBounds) || this.cornerBounds.length < 4) {
        return 0;
      }
      const northMost = this.cornerBounds[1].lng;
      const southMost = this.cornerBounds[0].lng;
      const eastMost = this.cornerBounds[2].lat;
      const westMost = this.cornerBounds[1].lat;
      this.schoolsWithVacanciesNumber = 0;
      this.schoolsWithNoVacanciesNumber = 0;
      this.schoolsWithUnknownVacanciesNumber = 0;

      // let interestedGrades = this.getInterestedGrades();
      const year = Array.from(this.navbarVacanciesFilter);
      const vacancy = this.schoolsVacancies[year];
      this.schools.forEach((s) => {
        const { lat } = s.location;
        const lng = s.location.lon;
        if (lat > eastMost && lat < westMost && lng < northMost && lng > southMost) {
          if (vacancy[s.campus_code] > 0) {
            this.schoolsWithVacanciesNumber += 1;
          } else if (vacancy[s.campus_code] == -1) {
            this.schoolsWithUnknownVacanciesNumber += 1;
          } else if (vacancy[s.campus_code] == 0) {
            this.schoolsWithNoVacanciesNumber += 1;
          }
          count += 1;
        }
      });
      return count;
    },
    checkLastMessageFromUser() {
      return this.messages.length > 0 && this.messages[this.messages.length - 1].type === 'user';
    },
    async displayLoading() {
      const randomDelay = Math.random() * (3000 - 500) + 500;
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, randomDelay));
      if (this.sendFirstMessage && (this.checkLastMessageFromUser() || this.waitingForResponse)) {
        // eslint-disable-next-line no-promise-executor-return
        this.showLoading = true;
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      } else {
        this.showLoading = false;
      }
    },
    switchFiltersContext(context) {
      if (context) {
        this.setContext({ context });
      }
    },
    track(action, identifier) {
      const mixpanelContexts = {
        [FILTER_CONTEXTS.EXPLORER]: 'map',
        [FILTER_CONTEXTS.FAVORITES]: 'favorite',
      };
      const id = identifier ? `_${identifier}` : '';
      trackMixPanel(`${mixpanelContexts[this.context]}_filter_${action}${id}`);
    },
    sortSchools(schools) {
      return schools.sort((a, b) => {
        // Check if drone flight and tour 360 are available
        const hasDroneA = a.multimedia_options.has_drone_flight;
        const hasTourA = a.multimedia_options.has_tour;
        const hasDroneB = b.multimedia_options.has_drone_flight;
        const hasTourB = b.multimedia_options.has_tour;

        // Priority for drone flight and tour 360

        let multimediaPriorityA = hasDroneA || hasTourA ? -1 : 0;
        let multimediaPriorityB = hasDroneB || hasTourB ? -1 : 0;
        multimediaPriorityA = hasDroneA && hasTourA ? -2 : multimediaPriorityA;
        multimediaPriorityB = hasDroneB && hasTourB ? -2 : multimediaPriorityB;

        // Determine priority based on account type
        const getPriority = (school, multimediaPriority) => {
          switch (school.account_type) {
            case 'PREMIUM':
              return 1 * 10 + multimediaPriority * 50;
            case 'FREEMIUM':
              return 2 * 10 + multimediaPriority * 50;
            case 'NOT_CLAIMED':
              return 3 * 10 + multimediaPriority * 50;
            default:
              return 4 * 10 + multimediaPriority * 50;
          }
        };

        const priorityA = getPriority(a, multimediaPriorityA);
        const priorityB = getPriority(b, multimediaPriorityB);

        // Return difference in priority (lower is more important)
        return priorityA - priorityB;
      });
    },
    filterAppliedMessage() {
      this.filterApplied = true;
      setTimeout(() => {
        this.filterApplied = false;
      }, 2000);
    },
    getInterestedGrades() {
      // gets interested grades for given user
      let displayGrade; // Grade Label to display
      if (this.isGuest) {
        // If the user is a guest, we need to check if there are grades of interest
        // to display the first one and the count of the rest
        if (this.guestGrades && this.guestGrades.length > 0) {
          return this.guestGrades;
        }
      } else if (Object.keys(this.currentStudent).length > 0) {
        // If the user is not a guest, and a student is selected, we need to check
        // if there are grades of interest to display the first one and the count of the rest
        // If there are no grades of interest, we need to check if there is a current enrollment
        // to display the grade label
        if (this.currentStudent.grades && this.currentStudent.grades.length > 0) {
          return this.currentStudent.grades;
        } if (
          this.currentStudent.current_enrollment
          && this.currentStudent.current_enrollment.grade_label
        ) {
          if (this.gradeOptions && this.gradeOptions.length > 0) {
            displayGrade = this.gradeOptions.find(
              (grade) => grade.id === this.currentStudent.current_enrollment.grade_label,
            );
            return [displayGrade];
          }
        }
      } else if (this.legalGuardianInterestGrades?.length > 0) {
        return this.legalGuardianInterestGrades;
      }
      return [];
    },
    getGrades(grades) {
      if (!Array.isArray(grades)) {
        // eslint-disable-next-line no-param-reassign
        grades = JSON.parse(grades);
      }
      const gradesResponse = grades.map((grade) => {
        const lowercaseGrade = grade.toLowerCase();
        const gradeMap = {
          parvularia: { grades: [1, 2, 3, 4, 5, 6], stage: [1] },
          '1ro básico': { grades: [7], stage: [2] },
          '2do básico': { grades: [8], stage: [2] },
          '3ro básico': { grades: [9], stage: [2] },
          '4to básico': { grades: [10], stage: [2] },
          '5to básico': { grades: [11], stage: [2] },
          '6to básico': { grades: [12], stage: [2] },
          '7mo básico': { grades: [13], stage: [2] },
          '8vo básico': { grades: [14], stage: [2] },
          '1ro medio': { grades: [15], stage: [3] },
          '2do medio': { grades: [16], stage: [3] },
          '3ro medio': { grades: [17], stage: [3] },
          '4to medio': { grades: [18], stage: [3] },
        };
        const result = gradeMap[lowercaseGrade];
        if (result) {
          // reset grades
          this.selectedGrades = new Set();
          this.selectedStages = new Set();
          result.grades.forEach((g) => this.selectedGrades.add(g));
          result.stage.forEach((r) => {
            this.selectedStages.add(r);
          });
          return result;
        }
        return false;
      });
      return gradesResponse;
    },
    showSchoolRecommendations(schools) {
      schools.forEach((school) => {
        this.messages.push(school);
      });
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    getSchoolRecommendations(vacancies) {
      const year = Array.from(this.navbarVacanciesFilter);
      const vacancy = this.schoolsVacancies[year];
      let selectedData = this.schools.filter(
        (school) => this.filteredExplorerSchools.includes(school.uuid)
          && !this.alreadyRecommended.includes(school.uuid),
      );
      if (selectedData.length === 0) {
        selectedData = this.schools.filter(
          (school) => !this.alreadyRecommended.includes(school.uuid),
        );
      }

      // new: if filter by vacancies, show which schools have vacancies.
      let _selectedData = selectedData;
      if (vacancies && year) {
        _selectedData = selectedData.filter((s) => {
          if (vacancy[s.campus_code] > 0) {
            return true;
          }
          return false;
        });

        // if no schools have vacancies, retrieve data.
        if (_selectedData.length === 0) {
          _selectedData = selectedData;
        }
      }
      const sortedSchools = this.sortSchools(_selectedData);
      const firstThree = sortedSchools.slice(0, 3);
      // new: add specific link to process by retrieving campus detail
      if (vacancies && year) {
        const interestedGrades = this.getInterestedGrades();
        const promises = [];
        firstThree.forEach((campus) => {
          campus.external_url = false;

          // get relevant programs (i.e. programs associated with interested grades) from campus
          const relevantPrograms = campus.programs
            .filter((program) => interestedGrades.includes(program.grade.id))
            .map((program) => program.id);

          // check, for those programs, which programs have vacancies
          const promise = institutionsService
            .getProgramVacanciesVisibility({ campusCode: campus.campus_code })
            .then((response) => {
              const campusVacancies = response.data;
              for (const program of relevantPrograms) {
                if (campusVacancies[program] > 0) {
                  // set true to "apply" button
                  campus.external_url = true;
                }
              }
              // add apply button if no interested grades provided
              if (interestedGrades.length === 0) {
                campus.external_url = true;
              }
            });
          promises.push(promise);
        });

        Promise.all(promises).then(() => {
          // After checking vacancies for each, show
          this.listSchoolsAfterProcessing(firstThree, vacancies, vacancy);
        });
      } else {
        this.listSchoolsAfterProcessing(firstThree, vacancies, vacancy);
      }
    },
    listSchoolsAfterProcessing(firstThree, vacancies, vacancy) {
      this.alreadyRecommended = [
        ...this.alreadyRecommended,
        ...firstThree.map((school) => school.uuid),
      ];
      let messageToDebounce;
      if (firstThree && firstThree.length === 0) {
        // eslint-disable-next-line no-shadow
        const messageToDebounce = {
          chatId: this.sessionUUID,
          tenant: CONFIG.tenant,
          text: JSON.stringify({
            type: 'response',
            content:
              'Lamentablemente no encontré opciones que te pueda mostrar. ¡Te invito a seguir explorando el mapa!',
            m_event: 'text',
          }),
          debounce: true,
        };
        this.ws.send(JSON.stringify(messageToDebounce));
        return;
      }

      let messageContent = 'Aquí tienes un par de ejemplos de establecimientos con distinto contenido e información:';
      if (vacancies) {
        messageContent = 'Aquí está el listado de algunos colegios que encontré con vacantes:';
      }
      // debounce example introduction
      messageToDebounce = {
        chatId: this.sessionUUID,
        tenant: CONFIG.tenant,
        debounce: true,
        text: JSON.stringify({
          content: messageContent,
          type: 'response',
          m_event: 'text',
        }),
      };
      this.ws.send(JSON.stringify(messageToDebounce));
      // debounce examples
      const schools = firstThree.map((school) => ({
        text: '',
        type: 'card',
        external_url: school.external_url ?? null,
        img: school.image_thumb[0].image_link,
        uuid: school.uuid,
        campusCode: school.campus_code,
        idFavorite: school.idFavorite,
        title: school.campus_name,
        description: school.campus_address,
        campus_tether_pack: school.campus_tether_pack,
        vacancy: (vacancy[school.campus_code] && (vacancy[school.campus_code])) > 0 ? 1 : 0,
        campus: school,
      }));
      messageToDebounce = {
        chatId: this.sessionUUID,
        tenant: CONFIG.tenant,
        debounce: true,
        text: JSON.stringify({
          content: schools,
          m_event: 'list',
          type: 'schools_list',
        }),
      };
      this.ws.send(JSON.stringify(messageToDebounce));
    },
    scrollToBottom() {
      const chatContent = document.getElementById('chatContent');
      chatContent.scrollTop = chatContent.scrollHeight;
    },
    initiateChat() {
      this.showInfoWindow = false;
      this.initializeWebSocket();
    },
    finishChat() {
      this.showInfoWindow = true;
      this.showChat = false;
      this.ws.close();
    },
    sendMessage() {
      if (this.newMessage.trim() !== '') {
        this.messages.push({
          text: this.newMessage,
          type: 'user',
          icon: 'user-bubble-icon.png',
        });
        // Send the user's message to the WebSocket server
        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
          const messageToSend = {
            text: this.newMessage,
            type: 'user',
            icon: 'user-bubble-icon.png',
            chatId: this.sessionUUID,
            userId: this.sessionUUID,
            tenant: CONFIG.tenant,
            debounce: false,
          };
          this.ws.send(JSON.stringify(messageToSend));
          this.sendFirstMessage = true;
        }
        this.$nextTick(() => {
          this.scrollToBottom();
        });
        this.newMessage = '';
      }
    },
    stageFilter(filterKey, filterValue) {
      // new: stagedFilters will be currently active filters.
      this.stagedFilters = this.storeFilters;
      if (filterKey === FILTER_KEYS.HAS_VACANCIES) {
        if (filterValue) {
          this.track(filterKey);
        }
        this.stagedFilters[filterKey] = filterValue;
      } else {
        this.stagedFilters[filterKey] = new Set(filterValue);
        if (filterValue.length > 0) {
          const lastId = filterValue.slice(-1);
          this.track(filterKey, lastId);
        }
      }
    },
    commitChanges() {
      // TODO: [...this.$t('favorite.filtersList')] tiene los filtros que se muestran en favoritos,
      // se debe hacer un refactor para que se use el mismo array y no tener que hacerlo manualmente en cada tenant
      this.filterLoader = true;
      this.clickInClean = false;
      setTimeout(() => {
        this.track('apply');
        this.commitFilters({ filters: this.stagedFilters, updateSchools: true });

        this.filterLoader = false;
        this.$emit('ready');
      }, 500);
    },
    toggleChat() {
      this.showChat = !this.showChat;
    },
    // getUserName() {
    //   if (this.userUsername) {
    //     return ` ${this.userUsername}`;
    //   }
    //   return '';
    // },
    initializeWebSocket() {
      let getMessages = 1;
      if (this.messages.length > 0) {
        getMessages = 0;
      }

      this.ws = new WebSocket(
        `${CONFIG.websocketURL}/chatbot/openai_stream/?tenant=${CONFIG.tenant}&get_messages=${getMessages}&user_uuid=${this.sessionUUID}`,
        // `ws://127.0.0.1:8005/openai_stream/?tenant=${CONFIG.tenant}&get_messages=${getMessages}&user_uuid=${this.this.sessionUUID}`,
      );
      this.ws.onopen = () => {
        this.isConnected = true;
        /* this.messages.push({
          text: `Hola${this.getUserName()} soy TetherBot,
          estoy aquí para ayudarte a buscar establecimientos educacionales. Cuéntame, ¿dónde y para qué
          nivel (curso) estás buscando?`,
          type: "response",
          icon: "response-bubble-icon.png",
        }); */
      };

      this.ws.onclose = () => {
        this.isConnected = false;
        // retry on two seconds
        setTimeout(() => {
          this.initializeWebSocket();
        }, 2000);
      };

      this.ws.onmessage = (event) => {
        const message = event.data;
        const msgs = JSON.parse(message);
        msgs.forEach((msg) => {
          const {
            // eslint-disable-next-line camelcase
            content,
            type,
            icon,
            // eslint-disable-next-line camelcase
            m_event,
          } = msg;

          // first: process actions
          // eslint-disable-next-line camelcase
          if (m_event === 'action') {
            // new: show example
            if (type === 'examples_filter') {
              // new: always find with vacancies for default.
              this.getSchoolRecommendations(true);
            }
            // eslint-disable-next-line camelcase
            if (type === 'open_profile') {
              this.updateCurrentSchool({ campusCode: content, fromUrl: true });
              const routeRoot = window.location.href.split('?')[0];
              const useSlash = routeRoot.endsWith('/') ? '' : '/';
              const newUrl = `${routeRoot}${useSlash}school/${content}`;
              window.history.pushState({ path: newUrl }, '', newUrl);
            }

            if (type === 'human_talk') {
              this.messages.push({
                text: content,
                type,
                icon,
              });
              setTimeout(() => {
                this.finishChat();
                this.askedForHuman = true;
                this.$intercom.show();
              }, 2000);
            }
            if (type === 'location') {
              this.setGuestLocation({
                location: { coordinates: { lat: content.lat, lng: content.lng } },
              }).then(() => {
                setTimeout(() => {
                  const schoolCounts = this.getSchoolsWithinBounds();
                  if (schoolCounts > 0) {
                    let messageText = `Encontré ${schoolCounts} colegios en esta zona.`;
                    let foundVacancies = false;
                    if (this.schoolsWithNoVacanciesNumber > 0) {
                      messageText = `${messageText} ${this.schoolsWithNoVacanciesNumber} con lista de espera.`;
                    }
                    if (this.schoolsWithUnknownVacanciesNumber > 0) {
                      messageText = `${messageText} ${this.schoolsWithUnknownVacanciesNumber} desconozco si tienen vacantes.`;
                    }
                    if (this.schoolsWithVacanciesNumber > 0) {
                      messageText = `\n\n${messageText} ${this.schoolsWithVacanciesNumber} tienen vacantes.`;
                      foundVacancies = true;
                    }
                    const messageToDebounce = {
                      chatId: this.sessionUUID,
                      tenant: CONFIG.tenant,
                      text: JSON.stringify({
                        type: 'response',
                        content: messageText,
                        m_event: 'text',
                      }),
                      debounce: true,
                    };
                    this.ws.send(JSON.stringify(messageToDebounce));
                    if (foundVacancies) {
                      this.getSchoolRecommendations(true);
                    }
                    /* this.messages.push({
                      text: message,
                      type: 'response',
                      icon: 'response-bubble-icon.png',
                    }); */
                  }
                }, 3000);
              });
              // this.filterText = '';
              // this.applyingFilter = false;
            }
            // eslint-disable-next-line camelcase
            if (type === 'level_filter') {
              const gradesInformation = this.getGrades(content);
              if (gradesInformation) {
                if (this.currentStudent && Object.keys(this.currentStudent).length > 0) {
                  const gradeOptions = {
                    uuid: this.currentStudent.uuid,
                    grades: [...this.selectedGrades],
                  };
                  // new?
                  this.setStudentGrades({
                    gradeOptions,
                    updateStudent: this.currentStudent.uuid,
                  }).then(() => {
                    this.setSimulationModalCompleted(true);
                    this.$emit('closeModal');
                  });
                } else if (!this.isGuest) {
                  const gradeOptions = {
                    grades: [...this.selectedGrades],
                    stages: [...this.selectedStages],
                  };

                  this.setFastRegisterGrade({ gradesData: gradeOptions }).then(() => {
                    this.setSimulationModalCompleted(true);
                    this.$emit('closeModal');
                    this.retrieveCampuses({ showFilters: false }).then(() => {});
                  });
                } else {
                  this.setGuestData({ grades: [...this.selectedGrades] }).then(() => {
                    this.retrieveCampuses({});
                  });
                }
              }
            }

            if (type === 'vacancies_filter') {
              this.stageFilter(FILTER_KEYS.HAS_VACANCIES, new Set([parseInt(content, 10)]));
              this.commitChanges();
            }
            if (type === 'school_type_filter') {
              let filterColegios;
              if (content === 'JUNJI') {
                filterColegios = 4;
              }
              if (content === 'integra') {
                filterColegios = 5;
              }
              if (content === 'particular pagado') {
                filterColegios = 3;
              }
              if (content === 'público' || content === 'publico') {
                filterColegios = 1;
              }
              if (content === 'particular subvencionado') {
                filterColegios = 2;
              }
              this.stageFilter(FILTER_KEYS.DEPENDENCIES, [filterColegios]);
              this.commitChanges();
              setTimeout(() => {
                this.applyingFilter = false;
                this.filterText = '';
                this.filterAppliedMessage();
              }, 2000);
            }
            // eslint-disable-next-line camelcase
            if (type === 'cost_filter') {
              let filterCostos;
              if (content.toLowerCase() === 'gratuito') {
                filterCostos = 1;
              }
              if (content.toLowerCase() === 'bajo') {
                filterCostos = 2;
              }
              if (content.toLowerCase() === 'medio') {
                filterCostos = 3;
              }
              if (content.toLowerCase() === 'alto') {
                filterCostos = 4;
              }
              this.stageFilter(FILTER_KEYS.PAYMENT, [filterCostos]);
              this.commitChanges();
              setTimeout(() => {
                this.applyingFilter = false;
                this.filterText = '';
                this.filterAppliedMessage();
              }, 2000);
            }

            // eslint-disable-next-line camelcase
            if (type === 'performance_filter') {
              let filterRendimiento;
              if (content.toLowerCase() === 'bajo') {
                filterRendimiento = 1;
              }
              if (content.toLowerCase() === 'medio') {
                filterRendimiento = 2;
              }
              if (content.toLowerCase() === 'medio - alto') {
                filterRendimiento = 3;
              }
              if (content.toLowerCase() === 'alto') {
                filterRendimiento = 4;
              }
              this.stageFilter(FILTER_KEYS.PERFORMANCE, [filterRendimiento]);
              this.commitChanges();
              setTimeout(() => {
                this.applyingFilter = false;
                this.filterText = '';
                this.filterAppliedMessage();
              }, 2000);
            }
            // eslint-disable-next-line brace-style
          }

          // second: list
          // eslint-disable-next-line camelcase
          else if (m_event === 'list') {
            if (type === 'processes_list') {
              if (content && content.length > 0) {
                content.forEach((process) => {
                  this.messages.push({
                    text: '',
                    type: 'list-card',
                    title: process.title,
                    subtitle: process.subtitle,
                    description: process.description,
                    link: process.link,
                    thumbnail: process.thumbnail,
                  });
                });
              }
            } else if (type === 'schools_list') {
              this.showSchoolRecommendations(content);
            }
            // eslint-disable-next-line brace-style
          }

          // third: response
          // eslint-disable-next-line camelcase
          // else if (m_event === 'text') {
          // }

          if (type === 'response_loading' && content !== '') {
            this.messages.push({
              text: content,
              type,
              icon,
            });
          }
          if (type === 'response') {
            this.messages.push({
              text: this.transformLinks(content),
              type,
              icon,
            });
          }
          if (type === 'user') {
            this.messages.push({
              text: content,
              type: 'user',
              icon: 'user-bubble-icon.png',
            });
          }

          // if (m_event === 'text' && type === 'response_loading') {
          //   this.messages.push({
          //     text,
          //     type,
          //     icon,
          //   });
          //   // this.applyingFilter = true;
          //   // this.filterText = text;
          //   // this.filterAppliedMessage();
          // }
          // new: receive user type?

          // eslint-disable-next-line camelcase

          // this.setSimulationModalCompleted(true);
          // eslint-disable-next-line camelcase
          // new: vacancies filter

          this.$nextTick(() => {
            this.scrollToBottom();
          });
        });
      };
    },
  },
};
</script>

<style scoped>
.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
  background-color: white;
  padding: 25px 25px 25px 25px;
  border-radius: 0px 0px 17.321px 17.321px;
  min-width: 280px;
  max-width: 70vw;
  height: 80%;
  overflow-y: hidden;
}

.card-i {
  display: none;
}
.chat-back {
  position: absolute;
  top: 7px;
  left: 7px;
}
.chat-header {
  background-color: #5627ff;
  color: #ffffff;
  padding: 21.03px 21.03px 21.03px 21.03px;
  border-radius: 17.321px 17.321px 0px 0px;
  min-width: 280px;
  max-width: 70vw;
  height: 20%;
  text-align: center;
  display: flex;
  align-items: center;
}
.header-avatar img {
  height: 60px;
  width: 60px;
}
.header-text {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.header-text h1 {
  margin: 0;
  font-weight: bolder;
  text-align: start;
}

.chat-content {
  overflow-y: scroll;
  flex: 1;
  overflow-x: hidden;
}

/* For a specific element */
.chat-content::-webkit-scrollbar {
  width: 10px;
}

.chat-content::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

.chat-content::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.chat-message {
  display: flex;
  justify-content: flex-start;
  max-width: 330px;
  word-wrap: break-word;
}

.user {
  flex-direction: row-reverse;
}

.response {
  flex-direction: row;
}

.user-m {
  background-color: #e0e7ff;
}

.boton-chat {
  position: fixed;
  bottom: 20px;
  right: 18px;
  height: 60px;
  width: fit-content;
  z-index: 55;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  transition: transform 0.3s, background-color 0.3s;
}
.boton-chat img {
  height: 70px;
  z-index: 5;
}
.boton-close-chat {
  position: fixed;
  bottom: 14px;
  right: 20px;
  height: 60px;
  width: fit-content;
  z-index: 55;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  transition: transform 0.3s, background-color 0.3s;
}
.boton-close-chat img {
  height: 50px;
  z-index: 5;
}
.boton-chat:hover {
  transform: scale(1.1);
  opacity: 1;
}

.boton-chat-message {
  position: absolute;
  right: 50px;
  height: 50px;
  background-color: rgba(204, 204, 204, 0.6);
  padding: 5px;
  border-radius: 10.8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  z-index: 1;
  justify-content: center;
  width: 160px;
  align-items: center;
  transform: translateX(100%);
  transition: transform 1s ease;
}

.boton-chat:hover .boton-chat-message {
  transform: translateX(0);
}
.boton-chat-message p {
  color: white;
  font-weight: bolder;
  margin: 0 10px 0 0;
  font-size: 14px;
}

.boton-chat:hover .boton-chat-message {
  display: flex;
}

.response-m {
  border-radius: 14px 14px 14px 14px !important;
  background-color: #eff0f6;
  color: #262338;
}

.message-content {
  font-size: 14px;
  padding: 16px 24px;
  margin-bottom: 10px;
  border-radius: 14px 14px 14px 14px;
  width: 100%;
  max-width: 265px;
  text-align: left;
}

.message-icon {
  margin: 0 8px;
  position: relative;
  height: 100%;
}

.message-icon img {
  top: 0;
  max-height: 40px !important;
  max-width: 40px;
  height: 40px;
  width: 40px;
}

.message-icon img {
  max-height: 24px;
}

.chat-c {
  height: 100%;
  width: 400px;
  max-width: 70vw !important;
  min-width: 260px;
}

.chat-input {
  position: sticky;
  bottom: 0;
  background-color: white;
  display: flex;
  width: 100%;
  z-index: 1;
  background-color: #eff0f6;
}
.chat-input textarea {
  border: none;
  outline: none;
  padding: 10px 10px;
  font-size: 14px;
  color: #1f3467;
  font-weight: 500;
  height: 42px;
  background-color: #eff0f6;
  font-family: 'DM Sans', sans-serif;
  width: 100%; /* or any specific width */
  resize: none; /* disables user resizing */
  overflow: auto; /* adds scrollbar if content is too long */
  white-space: pre-wrap; /* respects line breaks and spaces */
  word-wrap: break-word; /* breaks words onto the next line */
}

.chat-input img {
  height: 100%;
  margin-left: 12px;
}

.chat-input input {
  border-radius: 12px;
  width: 100%;
  background: #f5f5f5;
  color: #1f3467;
  padding: 6px;
  font-size: 12px;
}

.svg-item svg path {
  fill: red;
}

.svg-item {
  padding-top: 8px;
}

.user-svg {
  margin-right: -5px;
}

.user-svg svg path {
  fill: #e0e7ff;
}

.response-svg {
  transform: rotate(180deg);
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-top: 6px;
  margin-right: -2px;
}

.response-svg svg path {
  margin-right: -2px;
  fill: #eff0f6;
}
.card {
  max-width: 330px;
}

.list-card {
  max-width: 330px;
}

.card-svg {
  display: none;
}

.card-m {
  flex: 1;
}

.connected {
  display: flex;
  align-items: center;
}

.connected img {
  height: 10px;
  width: 10px;
}

.connected p {
  color: white;
  margin-left: 8px;
}

.intro-window {
  background-color: blue;
}

.intro-window p {
  margin-bottom: 20px;
}

.intro-window button {
  padding: 8px 16px;
  background-color: #5627ff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.chat-wrapper {
  min-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.info-window-header {
  height: 20%;
  max-width: 400px;
  background-color: #3e1cc5;
  display: flex;
  justify-content: flex-end;
}
.info-window-content {
  max-height: 80%;
  max-width: 400px;
  justify-content: space-around;
  background-color: #5627ff;
  display: flex;
}
.info-window-text-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.info-window {
  height: 100%;
}

.info-window-text-section {
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.info-window-text-section :nth-last-child(3) {
  margin-bottom: 20px;
}
.info-window-text-section p {
  color: white;
  font-size: 16px;
}
.info-window-content h2 {
  font-size: 24px;
  font-weight: 500;
  color: #7d92fe;
  text-align: left;
  font-family: 'DM Sans', sans-serif;
}

.info-window-content h1 {
  font-size: 30px;
  font-weight: bolder;
  color: white;
  text-align: left;
}
.info-window-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 10px 0;
  cursor: pointer;
  filter: drop-shadow(0px 8.727779388427734px 9.974604606628418px rgba(0, 0, 0, 0.06));
}
.info-window-button p {
  color: #3e1cc5;
  padding-left: 20px;
  font-weight: bold;
}
.info-window-button img {
  height: 30px;
  padding-right: 20px;
}
.gpt-chat {
  height: 700px;
  max-height: 78vh;
  max-width: 80vw;
}
.human-chat-open {
  display: none;
}
.chat-open {
  z-index: 9999999999999;
}
.applying-filter-message {
  color: #262338;
  font-style: italic;
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.filter-applied-message {
  color: #262338;
  font-style: italic;
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
}
.filter-applied-message img {
  height: 20px;
  margin-right: 10px;
}

.loading-container {
  max-height: 50px;
}
.loading::after {
  display: inline-block;
  animation: dotty steps(1, end) 1s infinite;
  content: '';
}
.response_loading-m {
  color: #262338;
  font-size: 12px;
  background-color: #5627ff;
  color: white;
}
.response_loading-svg {
  display: none;
}
@keyframes dotty {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}
.back-to-chatbot {
  position: absolute;
  bottom: 20px;
  right: 80px;
  border-radius: 25px;
  background: transparent;
  width: 50px;
  height: 50px;
  text-align: center;
  transition: transform 0.2s ease; /* Smooth transition for transform */
}
.back-to-chatbot:hover {
  transform: scale(1.1);
  cursor: pointer;
}
</style>
