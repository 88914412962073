<template>
  <div class="register-main register-main--spaced">
    <div>
      <ModalHeader :content-sections="['close']" @close="$emit('close')" />
      <ModalContent
        :content-sections="['subtitle', 'logo']"
        subtitle-center
        :subtitle-text="'modals.init_Info_Modal.title'"
        :logo-name="'palmira-logo.svg'"
      />
      <ModalContent
        :content-sections="['subtitle']"
        :subtitle-text="'modals.init_Info_Modal.content-text'"
        subtitle-light
      />
    </div>
    <CallToActions
      :option-text1="'modals.init_Info_Modal.btn1'"
      :option-text2="'modals.init_Info_Modal.btn2'"
      :types="['option']"
      :spaced="false"
      option-btn-no-margin
      @nextStep="nextStep"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';

export default {
  name: 'InitInfoModal',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
  },
  computed: {
    ...mapGetters({
      modalInfoFlow: 'userRegister/modalInfoFlow',
      students: 'authentication/students',
      legalGuardianAddress: 'authentication/legalGuardianAddress',
    }),
    setModalUuid() {
      if (this.modalInfoFlow && this.modalInfoFlow.length > 0) {
        const infoModal = this.modalInfoFlow.find((modal) => {
          if (modal.modal_code.indexOf('i') > -1) {
            return modal;
          }
          return null;
        });
        if (infoModal) {
          return infoModal.uuid;
        }
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      setModalFlowInfo: 'userRegister/setModalFlowInfo',
    }),
    nextStep(i) {
      const modalInfo = {
        user: '',
        modal_code: '1M.i',
        modal_response: false,
        action_completed: false,
      };
      const modalUuid = this.setModalUuid;
      if (i === 1) {
        modalInfo.modal_response = true;
        modalInfo.action_completed = true;
        this.setModalFlowInfo({ modalInfo, modalUuid });
      }
      if (!this.legalGuardianAddress && this.students && this.students.length === 0) {
        return this.$emit('toRegisterInfo');
      }
      return this.$emit('close');
    },
  },
};
</script>
