var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-main register-main--svg-logo",class:{
    'ma-0': _vm.noMargin,
    'register-main--svg-logo--left': _vm.left,
    'register-main--svg-logo--right': _vm.right,
    'register-main--svg-logo--pointer': _vm.pointer,
    'w-auto': _vm.widthAuto,
  }},[_c('img',{staticClass:"register-main--svg-logo--main",attrs:{"src":_vm.logo.startsWith('http') ? _vm.logo : require('@/assets/logos/' + _vm.logo),"width":_vm.svgWidth,"alt":_vm.alt},on:{"click":function($event){return _vm.$emit('logoClick', _vm.index)},"keydown":function($event){return _vm.$emit('logoClick', _vm.index)}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }