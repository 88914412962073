<template>
  <section class="w-full d-flex flex-column bg-primary-dark py-12">
    <TextAtom
      :value="$t('feedback.probability_alert.title')"
      tag="h2"
      font="poppins"
      color="secondary-light"
      weight="400"
      size="subtitle"
      class="text-left"
    />

    <TextAtom
      v-for="(description, index) in $t('feedback.probability_alert.descriptions')"
      :key="index"
      :value="description"
      tag="h2"
      font="inter"
      color="secondary-light"
      weight="400"
      size="normal"
      class="mt-3 text-left"
    />

    <div
      class="mt-2 w-full d-flex flex-row justify-center"
      :class="isWarningAlert ? 'align-end' : 'align-center'"
    >
      <SvgIcon
        :icon="getIcon"
        size="130"
        alt="probability alert"
        class="mt-8"
      />

      <div
        :class="isWarningAlert ? 'alert-high' : 'alert-normal'"
        class="rounded-lg py-3 px-4 feedback-message-warning ml-5 d-flex"
      >
        <TextAtom
          :value="$t('feedback.probability_alert.chance')"
          tag="span"
          font="inter"
          color="secondary-light"
          weight="500"
          size="default"
          class="text-left"
        />
      </div>
    </div>

    <TextAtom
      :value="$t('feedback.probability_alert.message')"
      tag="h2"
      font="poppins"
      color="secondary-light"
      weight="400"
      size="subtitle"
      class="text-left mt-10"
    />
  </section>
</template>

<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';

export default {
  name: 'FeedbackProbabilityAlert',
  components: {
    TextAtom,
    SvgIcon,
  },
  props: {
    unmatched: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isWarningAlert() {
      return this.unmatched >= 0.7;
    },
    getIcon() {
      return this.isWarningAlert >= 0.7 ? 'probability_alert_high.svg' : 'probability_alert_normal.svg';
    },
  },
};
</script>

<style scoped>
  .alert-high {
    background-color: #FC2451;
  }

  .alert-normal {
    background-color: #F2994A;
  }
</style>
