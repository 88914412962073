<template>
  <div class="deg-waiting-list--container">
    <ImageAtom
      class="deg-waiting-list--line clickable"
      :source="require('@/assets/icons/back-blue.svg')"
      :alt-text="'back'"
      @click="$emit('back')"
      @keydown="$emit('back')"
    />
    <SmallTitle
      class="deg-waiting-list--welcome__title"
      :text="'digital_enrollment.waiting-list.summary.title'"
      center-left
      primary
    />
    <MainTitle class="pt-0" :text="'digital_enrollment.waiting-list.summary.title_2'" secondary left-align />
    <ImageAtom
      class="deg-waiting-list--line"
      :source="require('@/assets/waitingList/dge-line.svg')"
      :alt-text="'dge-line'"
    />
    <SmallTitle :text="campusDetail.campus_name" center-left />
    <TextAtom
      :value="shift?.gradeName + ' - ' + shift?.name"
      class="deg-waiting-list--welcome__shift"
      size="subtitle"
    />
    <div :class="!mobile ? 'deg-waiting-list--summary-page' : ''">
      <div
        class="deg-waiting-list--summary"
        :class="!mobile ? 'deg-waiting-list--summary-page--desktop' : ''"
      >
        <div class="flex deg-waiting-list--summary__name">
          <SvgIcon :icon="'person-purple.svg'" :size="'20'" style="margin-left: 20px; margin-right: 16px;" />
          <TextAtom
            :value="lgName"
            font="inter"
            color="neutral-850"
          />
        </div>
        <div class="pl-8 pt-5 pb-5">
          <div class="flex  mb-3">
            <TextAtom
              :value="$t('digital_enrollment.waiting-list.summary.id')"
              font="inter"
              weight="500"
              color="neutral-850"
            />
            <TextAtom
              :value="lgId"
              font="inter"
              color="neutral-850"
              class="ml-3"
            />
          </div>
          <div class="flex  mb-3">
            <TextAtom
              :value="$t('digital_enrollment.waiting-list.summary.email')"
              weight="500"
              font="inter"
              color="neutral-850"
            />
            <TextAtom
              :value="lgEmail"
              font="inter"
              color="neutral-850"
              class="ml-3"
            />
          </div>
          <div class="flex">
            <TextAtom
              :value="$t('digital_enrollment.waiting-list.summary.phone')"
              weight="500"
              font="inter"
              color="neutral-850"
            />
            <TextAtom
              :value="lgPhone"
              font="inter"
              color="neutral-850"
              class="ml-3"
            />
          </div>
        </div>
      </div>
      <div
        class="deg-waiting-list--summary"
        :class="!mobile ? 'deg-waiting-list--summary-page--desktop' : ''"
      >
        <div class="flex deg-waiting-list--summary__name">
          <SvgIcon :icon="'face-purple.svg'" :size="'20'" style="margin-left: 20px; margin-right: 16px;" />
          <TextAtom
            :value="stName"
            font="inter"
            color="neutral-850"
          />
        </div>
        <div class="pl-8 pt-5 pb-5">
          <div class="flex mb-3">
            <TextAtom
              :value="$t('digital_enrollment.waiting-list.summary.id')"
              weight="500"
              font="inter"
              color="neutral-850"
            />
            <TextAtom
              :value="stId"
              font="inter"
              color="neutral-850"
              class="ml-3"
            />
          </div>
          <div class="flex">
            <TextAtom
              :value="$t('digital_enrollment.waiting-list.summary.birth')"
              weight="500"
              font="inter"
              color="neutral-850"
            />
            <TextAtom
              :value="stBirthDate"
              font="inter"
              color="neutral-850"
              class="ml-3"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="deg-waiting-list--footer">
      <CallToActions
        class="mb-3"
        :class="!mobile ? 'deg-waiting-list--list__button' : 'deg-waiting-list--footer__button'"
        :main-button-text="'digital_enrollment.waiting-list.summary.enroll'"
        :types="['main']"
        tiny
        light
        active-value
        :loader="loadingWaitingList"
        option-btn-no-margin
        @continue="submitPublicRecord()"
      />
      <TextAtom
        :value="$t('digital_enrollment.waiting-list.summary.information')"
        color="neutral-700"
        font="inter"
        class="mb-5"
      />
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';
import ImageAtom from '@/components/atoms/images/Image.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import MainTitle from '@/components/atoms/titles/MainTitle.vue';
import SmallTitle from '@/components/atoms/titles/SmallTitle.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import CONFIG from '@/config';
import publicRecordsService from '@/services/publicRecords.service';

import { mapGetters } from 'vuex';

export default {
  name: 'WaitingListSummary',
  components: {
    ImageAtom,
    MainTitle,
    SmallTitle,
    SvgIcon,
    CallToActions,
    TextAtom,
  },
  props: {
    shift: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      lgName: '',
      lgId: '',
      lgEmail: '',
      lgPhone: '',
      stName: '',
      stId: '',
      stBirthDate: '',
      relationship: '',
      inWaitingList: false,
      loadingWaitingList: false,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'institutions/campusDetails',
      legalGuardian: 'authentication/legalGuardian',
      currentStudent: 'authentication/currentStudent',
      contactPreferences: 'userRegister/contactPreferences',
      sessionUUID: 'authentication/sessionUUID',
      shiftWaitingList: 'institutions/shiftWaitingList',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    this.lgName = `${this.legalGuardian?.firstName} ${this.legalGuardian?.firstLastname}`;
    this.lgId = this.legalGuardian?.identificationNumber;
    this.lgEmail = this.contactPreferences?.email;
    this.lgPhone = this.contactPreferences?.phone;
    this.stName = `${this.currentStudent?.first_name} ${this.currentStudent?.first_lastname}`;
    this.stId = this.currentStudent?.identification_number;
    this.stBirthDate = this.currentStudent?.birth_date;
    this.relationship = this.currentStudent?.relationship?.relationship_name;
  },
  methods: {
    submitPublicRecord() {
      this.loadingWaitingList = true;
      publicRecordsService.waitingList({
        user_uuid: this.sessionUUID,
        applicant_id: this.currentStudent.uuid,
        shift_id: this.shiftWaitingList.id.toString(),
        level_id: this.shiftWaitingList.gradeLabelId.toString(),
        campus_code: this.campusDetail.campus_code,
        tenant: CONFIG.tenant,
      }).then(() => {
        this.inWaitingList = false;
        this.loadingWaitingList = false;
        this.$emit('next', this.inWaitingList);
      }).catch(() => {
        this.inWaitingList = true;
        this.loadingWaitingList = false;
        this.$emit('next', this.inWaitingList);
      });
    },
  },
};
</script>
