<template>
  <section
    :style="mobile
      ? 'margin: 2px 0 24px 0;' : 'margin: 2px 24px 24px 24px'"
  >
    <section class="d-flex fill-width" style="padding: 0 28px">
      <div class="fill-width px-1">
        <MainActionButtons
          :main-action-button-content="['option']"
          :options-buttons-text="'campus-details.dge_buttons.button_waiting_list'"
          :option-mixpanel-tag="'click_profile_waiting_lis_dge_button'"
          option-button-square
          option-primary-medium
          option-button-no-margin
          option-small-text
          solid-background
          :option-disabled="!hasWaitingList"
          @setAction="toWaitingListFlow()"
        />
      </div>
      <div class="fill-width px-1">
        <MainActionButtons
          :main-action-button-content="['option']"
          :options-buttons-text="'campus-details.dge_buttons.button_application'"
          :option-mixpanel-tag="'click_profile_application_dge_button'"
          option-button-square
          option-primary-medium
          option-button-no-margin
          option-small-text
          solid-background
          :option-disabled="campusDetails?.campus_processes?.length === 0"
          @setAction="toApplicationFlow()"
        />
      </div>
    </section>

    <v-dialog
      v-model="showWaitingListFlow"
      transition="dialog-bottom-transition"
      content-class="favorites__dialog"
      max-width="800px"
      min-width="350px"
      overlay-color="transparent"
      persistent
      @click:outside="closePanels()"
    >
      <VacanciesList
        v-if="inStep(1) || inStep(2)"
        @close="showWaitingListFlow = false"
        @keydown="showWaitingListFlow = false"
        @next="nextStepVacancies"
      />
      <WaitingList
        v-if="inStep(3)"
        :program="programId"
      />
      <v-dialog
        v-if="inStep(2)"
        v-model="showApplicationModal"
        transition="scroll-x-reverse-transition"
        content-class="waiting-list-flow--dialog"
        overlay-color="black"
      >
        <ApplicationModal
          :program-id="programId"
          :has-vacancies="hasVacancies"
          @close="backStep() && (showApplicationModal = false)"
          @closeCampus="$emit('close-campus-detail')"
        />
      </v-dialog>
    </v-dialog>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ApplicationModal from '@/components/organisms/digital_enrollment/ApplicationModal.vue';
import VacanciesList from '@/components/organisms/digital_enrollment/VacanciesList.vue';
import MainActionButtons from '@/components/molecules/sections/MainActionButtons.vue';
import WaitingList from '@/views/explorer/WaitingList.vue';
import { TENANT_CONFIGURATION } from '@/constants/tenant';

export default {
  name: 'CampusDetailPostulationButtons',
  components: {
    MainActionButtons,
    VacanciesList,
    ApplicationModal,
    WaitingList,
  },
  data() {
    return {
      showWaitingListFlow: false,
      stepStack: [1],
      showApplicationModal: false,
      programId: null,
      hasVacancies: false,
      shift: {},
    };
  },
  computed: {
    ...mapGetters({
      campusDetails: 'institutions/campusDetails',
      students: 'authentication/students',
      currentStudent: 'authentication/currentStudent',
      currentRole: 'roles/currentRole',
      isGuest: 'authentication/isGuest',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    currentStep() {
      return this.stepStack[this.stepStack.length - 1];
    },
    hasWaitingList() {
      if (TENANT_CONFIGURATION.PUBLIC_ENROLLMENT.ENABLED) {
        return this.campusDetails?.program_campus?.length > 0
          && (this.campusDetails?.campus_processes?.some((process) => process.process_code
            === TENANT_CONFIGURATION.PUBLIC_ENROLLMENT.DEFAULT_PROCESS_CODE) || this.isPrime());
      }
      return this.isPrime();
    },
  },
  methods: {
    ...mapActions({
      setLoginModal: 'authentication/setLoginModal',
      setShowCard: 'explorer/setShowCard',
      setCurrentStudent: 'authentication/setCurrentStudent',
      setShiftWaitingList: 'institutions/setShiftWaitingList',
      setWaitingListFromProfile: 'institutions/setWaitingListFromProfile',
      setRedirectedToProcesses: 'institutions/setRedirectedToProcesses',
    }),
    toApplicationFlow() {
      this.setRedirectedToProcesses(true);
      document.getElementById('admission').scrollIntoView({ behavior: 'smooth' });
    },
    toWaitingListFlow() {
      this.showWaitingListFlow = !this.showWaitingListFlow;
    },
    isPrime() {
      return this.campusDetails?.config_campus?.[0]?.campus_tether_pack;
    },
    inStep(...steps) {
      return steps.includes(this.currentStep);
    },
    backStep() {
      this.stepStack.pop();
    },
    toStep(step, { skipCurrent = false } = {}) {
      // Jump to a specific step. Do not use to reset the process, use restartProcess instead.
      // If skipCurrent is true, the current step will be skipped in the historial
      if (skipCurrent) {
        this.stepStack = [...this.stepStack.slice(0, -1), step];
      } else {
        this.stepStack.push(step);
      }
    },
    nextStepVacancies(shift, gradeLabelId, programId, hasVacancies) {
      // Por ahora solo chile tiene lista pública para postular.
      // Conforme se vayan habilitando más tenants se deben habilitar desde el archivo de constantes
      if (TENANT_CONFIGURATION.PUBLIC_ENROLLMENT.ENABLED) {
        this.programId = programId;
        this.hasVacancies = hasVacancies;
        this.showApplicationModal = true;
        this.toStep(2);
      } else {
        this.shift = { ...shift, gradeLabelId };
        if (shift.vacancies > 0) {
          this.showApplicationModal = true;
          this.toStep(2);
        } else {
          this.setWaitingListFromProfile({ waitingList: true });
          this.setShiftWaitingList({ shift: this.shift });
          this.programId = shift.programId;
          this.$router.push({
            name: 'Waiting List',
            params: {
              campusCode: this.campusDetails.campus_code,
              programId: this.programId,
            },
          });
        }
      }
    },
  },
};
</script>
