const defaultModalCallbacks = () => ({
  onConfirm: () => {},
  onCancel: () => {},
});

const getDefaultState = () => ({
  content: '',
  level: null,
  show: false,
  timeout: 5000,
  breadcrumbs: [],
  activeModalName: null,
  activeModalData: null,
  activeModalCallbacks: defaultModalCallbacks(),
});
export const state = {
  ...getDefaultState(),
};

export const [SUCCESS, INFO, WARN, ERROR] = Object.freeze([0, 1, 2, 3]);

const getters = {
  content: ({ content }) => content,
  level: ({ level }) => level,
  show: ({ show }) => show,
  timeout: ({ timeout }) => timeout,
  breadcrumbs: ({ breadcrumbs }) => breadcrumbs,
  activeModal: ({ activeModalName }) => activeModalName,
  activeModalData: ({ activeModalData }) => activeModalData,
  activeModalCallbacks: ({ activeModalCallbacks }) => activeModalCallbacks,
};

const mutations = {
  resetStore(state) {
    Object.assign(state, getDefaultState());
  },
  setMessage(
    state,
    {
      content = 'snackbar.generic-error',
      level = getDefaultState().level,
      timeout = getDefaultState().timeout,
    },
  ) {
    let message = content;
    if (content === false || content === undefined || content === null) {
      message = 'snackbar.generic-error';
    }
    state.content = message;
    state.level = level;
    state.timeout = timeout;
    state.show = true;
  },
  setBreadcrumbs(state, breadcrumbs) {
    state.breadcrumbs = breadcrumbs;
  },
  /* For the future
  popBreadcrumbs(state, breadcrumbs) {
    state.breadcrumbs.pop(breadcrumb);
  },
  */
  resetSnackbar(state) {
    state.show = false;
  },
  setActiveModalName(state, modalName) {
    state.activeModalName = modalName;
  },
  setActiveModalData(state, modalData) {
    state.activeModalData = modalData;
  },
  setActiveModalCallbacks(state, callbacks) {
    state.activeModalCallbacks = callbacks;
  },
};

const actions = {
  resetStore({ commit }) {
    commit('resetStore');
  },
  /** Success log */
  success({ commit }, content) {
    // eslint-disable-next-line no-use-before-define
    commit('setMessage', { content, level: SUCCESS });
  },
  info({ commit }, content) {
    // eslint-disable-next-line no-use-before-define
    commit('setMessage', { content, level: INFO });
  },
  warn({ commit }, content) {
    // eslint-disable-next-line no-use-before-define
    commit('setMessage', { content, level: WARN });
  },
  error({ commit }, content) {
    // eslint-disable-next-line no-use-before-define
    commit('setMessage', { content, level: ERROR });
  },
  resetSnackbar({ commit }) {
    commit('resetSnackbar');
  },
  infoWithTimeout({ commit }, { content, timeout }) {
    commit('setMessage', { content, level: INFO, timeout });
  },
  setBreadcrumbs({ commit }, { breadcrumbs }) {
    commit('setBreadcrumbs', breadcrumbs);
  },
  addBreadcrumb({ commit, state }, { breadcrumb }) {
    commit('setBreadcrumbs', [...state.breadcrumbs, breadcrumb]);
  },
  // popBreadcrumb({ commit, state }, last = 1) {
  //   commit('setBreadcrumbs', state.breadcrumbs.slice(0, -last));
  // },
  /**
   * Initialize the modal. In order to toggle the modal, you need to call the
   * activateModal action. This action will set the modal name, data and callbacks.
   * @param {*} VuexContext Vuex context
   * @param {*} ModalContext Modal name, data and callbacks
   * @param {string} ModalContext.name Name of the modal to be activated
   * @param {Object} ModalContext.data Data to be passed to the modal. The data depends on the modal
   * @param {Object} ModalContext.callbacks Callbacks to be called when the modal is confirmed or canceled
   */
  activateModal({ commit }, { name, data, callbacks = {} }) {
    commit('setActiveModalData', data);
    commit('setActiveModalCallbacks', callbacks);
    commit('setActiveModalName', name);
  },
  deactivateModal({ commit }) {
    commit('setActiveModalName', null);
    commit('setActiveModalCallbacks', defaultModalCallbacks());
    commit('setActiveModalData', null);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
