var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"scrollable":""},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},[_c('v-card',{directives:[{name:"click-outside",rawName:"v-click-outside",value:((e) => _vm.persistent || _vm.cancel(e)),expression:"(e) => persistent || cancel(e)"}],staticClass:"dialog mx-auto",class:{
      'dialog--wide': _vm.wide,
      'dialog--mobile': _vm.mobile,
      'dialog--loading': _vm.loading,
    }},[(_vm.loading)?_c('LoaderCircular',{attrs:{"show":_vm.loading,"size":60,"width":8}}):[_c('v-card-title',[_vm._t("banner"),_vm._t("title",function(){return [_c('SmallTitle',{attrs:{"text":"shared.grade.title","no-margin":""}})]},{"title":_vm.title})],2),_c('v-card-subtitle',[(_vm.warning)?_c('div',{staticClass:"dialog__warning"},[_vm._t("warning",function(){return [_c('MDIIcon',{attrs:{"icon":"mdi-alert-outline","color":"#e94c6e"}}),_c('span',{directives:[{name:"t",rawName:"v-t",value:(_vm.warning),expression:"warning"}]})]},{"warning":_vm.warning})],2):_vm._e(),(_vm.subtitle)?_c('div',{staticClass:"dialog__subtitle"},[_vm._t("subtitle",function(){return [_c('span',{directives:[{name:"t",rawName:"v-t",value:(_vm.subtitle),expression:"subtitle"}]})]},{"subtitle":_vm.subtitle})],2):_vm._e()]),_c('v-card-text',{staticClass:"dashboard-scrollbar"},[(_vm.infoToConfirm)?_c('div',{directives:[{name:"t",rawName:"v-t",value:(_vm.infoToConfirm),expression:"infoToConfirm"}],staticClass:"dialog__info"},[_vm._t("info",function(){return [_c('span',{directives:[{name:"t",rawName:"v-t",value:(_vm.infoToConfirm),expression:"infoToConfirm"}]})]},{"infoToConfirm":_vm.infoToConfirm})],2):_vm._e(),_vm._t("default")],2),_c('v-card-actions',{staticClass:"w-full"},[_vm._t("pre-actions"),_c('div',{staticClass:"dialog__actions",class:{
            'dialog__actions--mobile': _vm.mobile,
            'dialog__actions--single': !_vm.mobile && (_vm.hideCancelButton || _vm.hideConfirmButton),
          }},[_vm._t("actions",function(){return [(_vm.showCancelButton)?_c('OptionButtons',{staticClass:"dialog__actions__button mr-1",attrs:{"text":_vm.cancelButtonText || 'dialog.confirm.no',"no-margin":""},on:{"nextStep":_vm.cancel}}):_vm._e(),(!_vm.hideConfirmButton)?_c('OptionButtons',{staticClass:"dialog__actions__button ml-1",attrs:{"text":_vm.confirmButtonText || (_vm.showCancelButton ? 'dialog.confirm.yes' : 'dialog.confirm.acknowledged'),"is-active":_vm.canContinue,"no-margin":""},on:{"nextStep":_vm.confirm}}):_vm._e()]})],2)],2)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }