<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <RowStepper :step="2" />
      <div :class="inWaitingList && !mobile ? 'deg-waiting-list--mobile' : ''">
        <ModalContent
          :content-sections="['smallTitle', 'textSelect']"
          :small-title-text="getTitle()"
          small-title-left
          :text-select-placeholders="'user_register.student_selection.input_placeholder'"
          :text-select-items="students"
          text-select-item-text="first_name"
          text-select-return-object
          :text-select-index="2"
          :text-select-no-info-error="noInfoError"
          @setTextSelectValue="setValue"
        />
        <ModalContent
          :content-sections="['subtitle', 'selectionButton']"
          :subtitle-text="'user_register.student_selection.subtitle'"
          :selection-button-info="selectionButtonInfo"
          subtitle-center
          @setSelectionButtonValue="triggerNewStudentRegister"
        />
      </div>
    </div>
    <CallToActions
      :types="['main']"
      :active-value="isActive"
      :main-button-text="'user_register.continue'"
      small
      :spaced="false"
      :loader="selectionLoader"
      @continue="nextStep()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';

export default {
  name: 'StudentSelection',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
    RowStepper,
  },
  props: {
    inWaitingList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectionLoader: false,
      student: null,
      noInfoError: false,
      selectionButtonInfo: [
        { label: 'user_register.student_selection.new_student', isActive: true },
      ],
    };
  },
  computed: {
    ...mapGetters({
      students: 'authentication/students',
      externalLoginInfo: 'authentication/externalLoginInfo',
      inDigitalEnrollment: 'digitalEnrollment/inDigitalEnrollment',
      loginModal: 'authentication/loginModal',
    }),
    isActive() {
      return this.student !== null;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  beforeMount() {
    if (this.students.length === 0) {
      this.$emit('toNewStudentRegister', { skipCurrent: true });
    }
  },
  methods: {
    ...mapActions({
      setCurrentStudent: 'authentication/setCurrentStudent',
      retrieveDigitalEnrollmentApplicantOptions: 'digitalEnrollment/retrieveDigitalEnrollmentApplicantOptions',
    }),
    nextStep() {
      this.selectionLoader = true;
      if (this.isActive) {
        this.setCurrentStudent({ student: this.student, updateMap: false }).then(() => {
          if (this.inDigitalEnrollment && this.loginModal !== 'applicationFlow') {
            this.retrieveDigitalEnrollmentApplicantOptions().then(() => {
              this.$emit('nextStep');
            });
          } else {
            this.$emit('nextStep');
          }
        });
      } else {
        this.noInfoError = true;
        this.selectionLoader = false;
      }
    },
    triggerNewStudentRegister() {
      this.setCurrentStudent({ student: {}, updateMap: false }).then(() => {
        this.$emit('toNewStudentRegister');
      });
    },
    setValue(index, value) {
      if (index) {
        this.noInfoError = false;
        this.student = value;
      }
    },
    getTitle() {
      if (this.loginModal === 'applicationFlow') {
        return this.$t('user_register.student_selection.application_title');
      }
      const { origin: redirectOrigin } = this.externalLoginInfo;
      const origin = this.$t(`user_register.redirect.origins.${redirectOrigin}.name`);
      const action = this.$t(`user_register.redirect.origins.${redirectOrigin}.action`);
      return this.$t('user_register.student_selection.title', { action, origin });
    },
  },
};
</script>
