import CONFIG from '@/config';
import i18n from '@/plugins/i18n';
import services from '@/services';
import { TETHER_FILTER_CONTEXTS as FILTER_CONTEXTS } from '@/constants/filters';
import { ELASTIC_SEARCH_FIELDS, GEO_SEARCH_MODES } from '@/constants/search';
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import { isEmbedded } from '@/utils/embedded';

const currentYear = new Date().getFullYear();
const getDefaultState = () => ({
  campusOfSearchBar: {},
  // TODO: loadingProfile se usa para mostrar el loader en el detalle de colegio - quizás se puede renombrar
  loadingProfile: false,
  schools: [],
  campusDetails: {},
  showCampusDetail: false,
  redirectedToProcesses: false,
  showWaitingList: false,
  exploredCampuses: [],
  exploredSchoolCards: [],
  // Deletion candidate
  elasticSchoolDetail: null,
  communeInfoList: null,
  communeSchoolList: [],
  communeInstitutionList: [],
  instagramPhotos: [],
  instagramUser: null,
  applicationsByUuid: [],
  gradeRecommendation: null,
  schoolInstitutionList: [],
  schoolCampusList: [],
  // TODO: Candidate to move to new explorer module
  locationFromUrl: false,
  shiftWaitingList: {
    gradeName: '',
    name: '',
  },
  waitingListFromProfile: false,
  schoolsVacancies: {
    [currentYear]: {},
    [currentYear + 1]: {},
    [currentYear + 2]: {},
  },
  vacanciesLoader: true,
});

const state = getDefaultState();

const getters = {
  campusOfSearchBar: ({ campusOfSearchBar }) => campusOfSearchBar,
  waitingListFromProfile: ({ waitingListFromProfile }) => waitingListFromProfile,
  shiftWaitingList: ({ shiftWaitingList }) => shiftWaitingList,
  locationFromUrl: (state) => state.locationFromUrl,
  textNotification: () => ({
    ...i18n.t('snackbar'),
  }),
  getMultimediaInstagram: ({ instagramPhotos }) => instagramPhotos,
  getUserInstagram: ({ instagramUser }) => instagramUser,
  getSchoolInstitutionList: ({ schoolInstitutionList }) => schoolInstitutionList,
  getSchoolCampusList: ({ schoolCampusList }) => schoolCampusList,
  getInstitutionCampusList: ({ institutionCampusList }) => institutionCampusList,
  applicationsByUuid(storeState) {
    return storeState.applicationsByUuid;
  },
  schools: ({ schools }) => schools,
  campusDetails: ({ campusDetails }) => campusDetails,
  showCampusDetail: ({ showCampusDetail }) => showCampusDetail,
  redirectedToProcesses: ({ redirectedToProcesses }) => redirectedToProcesses,
  showWaitingList: ({ showWaitingList }) => showWaitingList,
  loadingProfile: ({ loadingProfile }) => loadingProfile,
  exploredCampuses: ({ exploredCampuses }) => exploredCampuses,
  exploredSchoolCards: ({ exploredSchoolCards }) => exploredSchoolCards,
  communeInfoList: ({ communeInfoList }) => communeInfoList,
  communeSchoolList: ({ communeSchoolList }) => communeSchoolList,
  communeInstitutionList: ({ communeInstitutionList }) => communeInstitutionList,
  gradeRecommendation: ({ gradeRecommendation }) => gradeRecommendation,
  elasticSchoolDetail: ({ elasticSchoolDetail }) => elasticSchoolDetail,
  schoolsVacancies: ({ schoolsVacancies }) => schoolsVacancies,
  vacanciesLoader: ({ vacanciesLoader }) => vacanciesLoader,
};

const mutations = {
  setShiftWaitingList(state, shift) {
    state.shiftWaitingList = shift;
  },
  setWaitingListFromProfile(state, waitingList) {
    state.waitingListFromProfile = waitingList;
  },
  setCampusOfSearchBar(state, campus) {
    state.campusOfSearchBar = { ...campus };
  },
  setLocationFromUrl(state, value) {
    state.locationFromUrl = value;
  },
  reset(state) {
    Object.assign(state, getDefaultState());
  },
  setApplicationsByUuid(state, data) {
    state.applicationsByUuid = data;
  },
  setGuestExploredCards(state, campus) {
    if (!state.exploredSchoolCards.includes(campus.uuid)) {
      state.exploredSchoolCards.push(campus.uuid);
    }
  },
  setGuestExploredCampuses(state, campus) {
    if (!state.exploredCampuses.includes(campus.uuid)) {
      state.exploredCampuses.push(campus.uuid);
    }
  },
  setMultimediaInstagram(state, data) {
    state.instagramPhotos = data;
  },
  setUserInstagram(state, data) {
    state.instagramUser = data;
  },
  setSchools(state, schools) {
    state.schools = [...schools];
  },
  clearSchoolInstitution(state) {
    state.schoolInstitutionList = [];
  },
  addExploredMapCardToSchool(state, schooluuid) {
    const index = state.schools.findIndex((school) => school.uuid === schooluuid);
    if (index !== -1) {
      state.schools[index].exploredMapCard = true;
    }
  },
  updateCampusDetailFavorite(state, isFavorite) {
    state.campusDetails.isFavorite = isFavorite;
  },
  exploreCampus(state, campusUuid) {
    const index = state.schools.findIndex((element) => element.uuid === campusUuid);
    state.schools[index].explored = true;
  },
  exploreSchoolCard(state, campusUuid) {
    const index = state.schools.findIndex((element) => element.uuid === campusUuid);
    state.schools[index].exploredSchoolCard = true;
  },
  setCampusDetails(state, campusDetails) {
    state.campusDetails = { ...campusDetails };
  },
  setShowCampusDetail(state, newVal) {
    state.showCampusDetail = newVal;
  },
  setRedirectedToProcesses(state, newVal) {
    state.redirectedToProcesses = newVal;
  },
  setShowWaitingList(state, newVal) {
    state.showWaitingList = newVal;
  },
  setVacanciesLoader(state, newVal) {
    state.vacanciesLoader = newVal;
  },
  setSchoolElasticDetails(state, school) {
    state.elasticSchoolDetail = school;
  },
  setLoadingProfile(state, loadingProfile) {
    state.loadingProfile = loadingProfile;
  },
  setExploredCampuses(state, exploredCampuses) {
    state.exploredCampuses = [...exploredCampuses];
  },
  setExploredSchoolCards(state, exploredSchoolCards) {
    state.exploredSchoolCards = [...exploredSchoolCards];
  },
  setCommuneList(state, list) {
    state.communeInfoList = list;
  },
  setSchoolFromCommune(state, list) {
    state.communeSchoolList = list;
  },
  setInstitutionFromCommune(state, list) {
    state.communeInstitutionList = list;
  },
  setSchoolInstitution(state, list) {
    state.schoolInstitutionList.push(list);
  },
  setSchoolCampus(state, list) {
    state.schoolCampusList.push(list);
  },
  setGradeRecommendation(state, recommendation) {
    state.gradeRecommendation = recommendation;
  },
  setInfrastructureInCampusDetail(state, infrastructures) {
    state.campusDetails.infraestructure_campus = infrastructures;
  },
  setSafetyInCampusDetail(state, recommendation) {
    state.campusDetails.safety_campus = recommendation;
  },
  setSchoolsVacancies(state, { year, vacancies }) {
    state.schoolsVacancies[year] = vacancies;
  },
};

const actions = {
  setShiftWaitingList({ commit }, { shift }) {
    commit('setShiftWaitingList', shift);
  },
  setWaitingListFromProfile({ commit }, { waitingList }) {
    commit('setWaitingListFromProfile', waitingList);
  },
  setLocationFromUrl({ commit }, value) {
    commit('setLocationFromUrl', value);
  },
  hiddenCampusDetail({ commit }) {
    commit('setShowCampusDetail', false);
  },
  async retrieveApplicationsByUuid({ commit }) {
    const round = CONFIG.applicationRound;
    await services.institutionsService.retrieveLegalGuardianApplications(round).then((response) => {
      const dataApp = [];
      const getApplications = new Promise((resolve) => {
        response.data.forEach((application, index) => {
          const id = application.applicant;
          const status = application.application_status;
          const timestamp = application.modified ? application.modified : application.created;
          const ranking = application.appranking_application;
          dataApp.push({
            id, status, timestamp, ranking,
          });
          if (index === response.data.length - 1) {
            resolve();
          }
        });
      });
      getApplications.then(() => commit('setApplicationsByUuid', dataApp));
    });
  },
  async getSchoolElasticDetails({ commit }, { campusUuid }) {
    await services.elasticSearchService
      .getCampusFromKey({ key: campusUuid })
      .then((response) => {
        const campus = response.data.result;
        commit('setSchoolElasticDetails', campus);
        return campus;
      });
  },
  setSchools({ commit, dispatch }, { schools, inFilter = false }) {
    commit('setSchools', schools);
    dispatch('filters/updateSchools', { context: FILTER_CONTEXTS.EXPLORER, inFilter }, { root: true });
  },
  updateCampusDetailFavorite({ commit }, isFavorite) {
    commit('updateCampusDetailFavorite', isFavorite);
  },
  selectSchoolFromSearchBar({ commit, dispatch, rootGetters }, { campusSearchData }) {
    dispatch('explorer/setShowCard', { show: true }, { root: true });
    commit('setCampusOfSearchBar', campusSearchData);
    const {
      uuid, location: { lat, lon },
    } = campusSearchData;
    const location = {
      lat,
      lng: lon,
    };
    dispatch('explorer/navigateTo', location, { root: true });

    const isAmbassador = rootGetters['roles/isAmbassador'];
    const isGuest = rootGetters['authentication/isGuest'];

    if (isAmbassador) {
      dispatch('updateCurrentSchool', { uuid });
    }
    if (!isGuest) {
      dispatch('exploreSchoolCard', { campus: campusSearchData });
      dispatch('setShowMapCard', uuid);
    }
  },
  sendGeneralExploredCampus({ rootGetters }, { campusCode, campusUuid, event }) {
    const isGuest = rootGetters['authentication/isGuest'];
    const campusInfo = {
      is_guest: isGuest,
      event,
      campus_code: campusCode ?? undefined,
      campus_uuid: campusUuid ?? undefined,
    };
    try {
      services.institutionsService.postExploredCampus({ campusInfo });
    } catch (error) { /* empty */ }
  },
  async updateCurrentSchool(
    { dispatch, commit },
    {
      campusCode, uuid, fromUrl = false,
    },
  ) {
    const searchCode = uuid ?? campusCode;
    commit('setLoadingProfile', true);
    if (fromUrl) {
      commit('setShowCampusDetail', true);
    }

    return dispatch('retrieveCampusDetails', { searchCode, fromUrl });
  },
  setShowWaitingList({ commit }, newVal) {
    commit('setShowWaitingList', newVal);
  },
  setVacanciesLoader({ commit }, newVal) {
    commit('setVacanciesLoader', newVal);
  },
  async updateFavoritesMarkers({ dispatch, rootGetters }, { schools }) {
    const favorites = rootGetters['favorites/listFavoriteSchools'];

    const schoolsWithFavorites = schools.map((school) => ({
      ...school,
      isFavorite:
        favorites.find(
          // eslint-disable-next-line camelcase
          ({ uuid, campus_code }) => uuid === school.uuid || campus_code === school.campus_code,
        ) !== undefined,
      idFavorite:
        favorites.find(
          // eslint-disable-next-line camelcase
          ({ uuid, campus_code }) => uuid === school.uuid || campus_code === school.campus_code,
        )?.idFavorite ?? null,
    }));
    dispatch('setSchools', { schools: schoolsWithFavorites, inFilter: true });

    return schoolsWithFavorites;
  },
  async updateExploredMarkers({ dispatch, state, rootGetters }, schools) {
    // TODO: Refactorizar toda lógica de favoritos y explorados
    const newSchools = [];
    const { exploredCampuses } = state;
    if (schools.length === 1) {
      const rootSchools = rootGetters['institutions/schools'];
      if (rootSchools.length !== 0) {
        rootSchools.forEach((school) => {
          if (school.id === schools[0].id) {
            school.isExplored = true; // eslint-disable-line no-param-reassign
            newSchools.push(school);
          } else {
            newSchools.push(school);
          }
        });
        dispatch('setSchools', { schools: newSchools });
        return newSchools;
      }

      dispatch('setSchools', { schools });

      return schools;
    }
    schools.forEach((school) => {
      const isExplored = exploredCampuses.find(
        (campus) => campus.campus_code === parseInt(school.campus_code, 10),
      );
      if (isExplored !== undefined) {
        school.isExplored = true; // eslint-disable-line no-param-reassign
        newSchools.push(school);
      } else {
        school.isExplored = false; // eslint-disable-line no-param-reassign
        newSchools.push(school);
      }
    });
    dispatch('setSchools', { schools });
    return schools;
  },

  async updateExploredSchoolCards({ dispatch, state, rootGetters }, schools) {
    const newSchools = [];
    const { exploredSchoolCards } = state;
    if (schools.length === 1) {
      const rootSchools = rootGetters['institutions/schools'];
      if (rootSchools.length !== 0) {
        rootSchools.forEach((school) => {
          if (school.id === schools[0].id) {
            school.isExploredMapCard = true; // eslint-disable-line no-param-reassign
            newSchools.push(school);
          } else {
            newSchools.push(school);
          }
        });
        dispatch('setSchools', { schools: newSchools });
      } else {
        dispatch('setSchools', { schools });
      }
    } else {
      schools.forEach((school) => {
        const isExploredMapCard = exploredSchoolCards.find(
          (campus) => campus.campus_code === parseInt(school.campus_code, 10),
        );
        if (isExploredMapCard !== undefined) {
          school.isExploredMapCard = true; // eslint-disable-line no-param-reassign
          newSchools.push(school);
        } else {
          school.isExploredMapCard = false; // eslint-disable-line no-param-reassign
          newSchools.push(school);
        }
      });
      dispatch('setSchools', { schools });
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async retrieveVacanciesByCampus({ }, { campusCode }) {
    return services.institutionsService.retrieveVacanciesByCampus({ campusCode }).then((response) => {
      const { data } = response;
      // The sum of stock_available_crm for each grade and each year
      const result = data.reduce(
        (acc, stage) => {
          stage.program_vacancies.forEach((program) => {
            const { year, grade: gradeTitle } = program.program;

            acc[gradeTitle] = acc[gradeTitle] || { [year]: -1 };
            const currentValue = acc[gradeTitle][year];
            acc[gradeTitle][year] = Math.max(program?.show_vacancies, currentValue);
          });
          return acc;
        },
        {},
      );

      return result;
    });
  },
  async retrieveVacancies({ commit, rootGetters, dispatch }, { year, grade, campuses }) {
    commit('setVacanciesLoader', true);
    return services.institutionsService.retrieveVacancies({ year, grade, campuses }).then((response) => {
      const vacancies = response.data;
      commit('setSchoolsVacancies', { year, vacancies }); // per year
      commit('setVacanciesLoader', false);
      if (rootGetters['filters/anyVacanciesFilterActive']) {
        dispatch('filters/updateSchools', { context: FILTER_CONTEXTS.EXPLORER }, { root: true });
      }
      return vacancies;
    });
  },
  retrieveCampuses({ commit, dispatch, rootGetters }, { callback, bounds }) {
    commit('setVacanciesLoader', true);
    const isGuest = rootGetters['authentication/isGuest'];
    const externalLoginInfo = rootGetters['authentication/externalLoginInfo'];
    const guestLocationSet = rootGetters['authentication/guestLocationSet'];
    const { grades } = rootGetters['authentication/activeGrades'];
    const processId = rootGetters['explorer/processId'];
    const { NETWORK_ID } = rootGetters['filters/filters'];
    const { PLACE_ID } = rootGetters['filters/filters'];

    if (externalLoginInfo.origin || (isGuest && !guestLocationSet)) {
      // When the user is in the digital enrollment process, we don't have the data to fetch this, and don't need it.
      return [];
    }

    const searchFilters = [];
    if (grades?.length > 0) {
      searchFilters.push({
        fieldname: ELASTIC_SEARCH_FIELDS.GRADE,
        fieldvalues: grades,
      });
    }

    if (NETWORK_ID?.size > 0) {
      searchFilters.push({
        fieldname: ELASTIC_SEARCH_FIELDS.NETWORK_ID,
        fieldvalues: [Array.from(NETWORK_ID)[0]],
      });
    }

    if (PLACE_ID?.size > 0) {
      if (Array.isArray(PLACE_ID)) {
        searchFilters.push({
          fieldname: ELASTIC_SEARCH_FIELDS.PLACE_ID,
          fieldvalues: PLACE_ID,
        });
      } else {
        searchFilters.push({
          fieldname: ELASTIC_SEARCH_FIELDS.PLACE_ID,
          fieldvalues: Array.from(PLACE_ID),
        });
      }
    }

    if (processId?.length > 0) {
      searchFilters.push({
        fieldname: ELASTIC_SEARCH_FIELDS.PROCESS_ID,
        fieldvalues: Array.from(processId),
      });
    }

    const payload = {
      filters: searchFilters,
    };
    const searchMode = isEmbedded() ? TENANT_CONFIGURATION.SETTINGS.IFRAME.SEARCH_MODE : GEO_SEARCH_MODES.BOUNDS;
    const action = searchMode === GEO_SEARCH_MODES.RADIAL ? 'retrieveCampusesAround' : 'retrieveCampusesInBounds';
    const filterCampuses = (campuses) => {
      const filterAdmission = rootGetters['filters/filteredAdmissionSystem'];

      if (filterAdmission) {
        return campuses.filter(
          (campus) => campus.campus_admission_system.some((campus) => campus.admission_system.id === 1),
        );
      }

      return campuses;
    };

    return dispatch(`elasticSearch/${action}`, { payload, bounds }, { root: true })
      .then((response) => dispatch('updateFavoritesMarkers', { schools: filterCampuses(response.data.results) })
        .then((schools) => dispatch('updateExploredMarkers', schools)
          .then((response) => dispatch('updateExploredSchoolCards', response))))
      .finally(() => {
        if (callback) {
          callback();
        }
      });
  },
  /**
   * Retrieves the latest campus details from the backend (not elastic)
   * @param {String} searchCode - UUID or Campus Code of the school
   * @param {Boolean} fromURL - flag specifying whether the school is loaded because it's opened from a URL
   * @param {Boolean} waitingList - flag specifying whether the school is loaded because it's opened from the WL
   * @returns
   */
  async retrieveCampusDetails(
    {
      commit, dispatch, state, rootGetters,
    },
    { searchCode, fromUrl = false },
  ) {
    commit('setLoadingProfile', true);
    const { campusDetails: { uuid: defaultSearchCode } = {} } = state;
    const isGuest = rootGetters['authentication/isGuest'];
    const studentUUID = isGuest ? null : rootGetters['authentication/currentStudent'].uuid;

    const { grades: [grade] } = rootGetters['authentication/activeGrades'];

    const usingCampusCode = fromUrl && searchCode;

    const retrieveCampus = await services.institutionsService
      .retrieveSchoolDetail({
        searchCode: searchCode || defaultSearchCode,
        studentUUID,
        grade,
        useCode: usingCampusCode,
      })
      .then((campusDetails) => {
        const campus = { ...campusDetails.data };

        if (fromUrl) {
          const exitsInfoGuest = rootGetters['authentication/exitsInfoGuest'];
          const lat = campus?.campus_location[0]?.latitud;
          const lng = campus?.campus_location[0]?.longitud;

          if (lat && lng) {
            dispatch('explorer/navigateTo', { lat, lng }, { root: true });
          }

          if (!exitsInfoGuest) {
            commit('authentication/setExitsInfoGuest', { exitsInfoGuest: true }, { root: true });
          }
        }
        commit('setCampusDetails', campus);
        commit('setLoadingProfile', false);
        dispatch('setRedirectedToProcesses', false);

        const isHeadMaster = rootGetters['roles/isHeadMaster'];
        const isAmbassador = rootGetters['roles/isAmbassador'];
        if (isHeadMaster) {
          // FIXME: We should generalize this school name so its not only for headmasters
          dispatch('roles/updateHeadMasterSchool', { school: campus }, { root: true });
        } else if (isAmbassador) {
          dispatch('roles/setHeadMasterMainSchool', { mainSchool: campus }, { root: true });
        }

        return campus;
      })
      .catch((error) => {
        throw error;
      });
    return retrieveCampus;
  },
  retrieveMultimediaInstagram({ commit }, { token, isBusinessAccount = false, businessAccountId }) {
    if (!isBusinessAccount) {
      services.institutionsService
        .retrieveMultimediaInstagram(token)
        .then((response) => {
          commit('setMultimediaInstagram', response.data.data);
        })
        .catch(() => {
          commit('setMultimediaInstagram', []);
        });
    } else {
      services.institutionsService
        .retrieveMultimediaInstagramBusiness(token, businessAccountId)
        .then((response) => {
          commit('setMultimediaInstagram', response.data.data);
        })
        .catch(() => {
          commit('setMultimediaInstagram', []);
        });
    }
  },
  retrieveUserInstagram({ commit }, { token, isBusinessAcc = false, businessAccId = null }) {
    if (!isBusinessAcc) {
      services.institutionsService.retrieveUserInstagram(token).then((response) => {
        commit('setUserInstagram', response.data);
      });
    } else {
      services.institutionsService.retrieveUserInstagramBusiness(token, businessAccId).then((response) => {
        commit('setUserInstagram', response.data);
      });
    }
  },
  async retrieveExploredCampuses({ commit, rootGetters }) {
    const isGuest = rootGetters['authentication/isGuest'];
    let { uuid } = rootGetters['authentication/currentStudent'];
    if (isGuest) {
      uuid = rootGetters['authentication/legalGuardian'].guestStudentUuid;
    }
    services.institutionsService
      .retrieveExploredSchoolCards({
        uuid,
      })
      .then((exploredCampuses) => {
        const exploredCampusesList = [];
        exploredCampuses.data.forEach((campus) => {
          exploredCampusesList.push(campus.campus_uuid);
        });
        commit('setExploredCampuses', exploredCampusesList);
      });
  },
  async retrieveExploredSchoolCard({ commit, rootGetters }) {
    const isGuest = rootGetters['authentication/isGuest'];
    let { uuid } = rootGetters['authentication/currentStudent'];
    if (isGuest) {
      uuid = rootGetters['authentication/legalGuardian'].guestStudentUuid;
    }
    services.institutionsService
      .retrieveExploredCampuses({
        uuid,
      })
      .then((exploredSchoolCards) => {
        const exploredSchoolCardsList = [];
        exploredSchoolCards.data.forEach((campus) => {
          exploredSchoolCardsList.push(campus.campus_uuid);
        });
        commit('setExploredSchoolCards', exploredSchoolCardsList);
      });
  },
  // eslint-disable-next-line no-empty-pattern
  async gradeTracksByCombination({ }, {
    gradeId, stageId, modalityId, specialtyId,
  }) {
    const gradeTrack = await services.institutionsService
      .gradeTracksByCombination({
        gradeId, stageId, modalityId, specialtyId,
      })
      .then((response) => response.data)
      .catch(() => { });
    return gradeTrack;
  },
  reset({ commit }) {
    commit('reset');
  },
  async patchCampus({ dispatch, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    return services.institutionsService
      .patchCampus({ campusUuid, campusData })
      .then((response) => {
        const notification = response.status === 200 ? textNotification.success : textNotification.error;
        if (response.status === 200) {
          dispatch('institutions/retrieveCampusDetails', { searchCode: campusUuid }, { root: true });
        }
        dispatch('utils/success', notification, { root: true });
        return response;
      })
      .catch((error) => {
        dispatch('utils/error', textNotification.error, { root: true });
        return error;
      });
  },

  async campusImagesPatch({ dispatch, rootGetters }, { campusData, campusUuid }) {
    const textNotification = rootGetters['institutions/textNotification'];
    return services.institutionsService
      .campusImagesPatch({ campusData, campusUuid })
      .then((response) => {
        const imagesData = response.data;
        dispatch('roles/setImagesMainSchool', { images: imagesData }, { root: true });
        dispatch('utils/success', textNotification.success, { root: true });
        return response;
      })
      .catch((error) => {
        dispatch('utils/error', textNotification.error, { root: true });
        return error;
      });
  },
  async campusSafetyPatch({ dispatch, commit, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    await services.institutionsService.campusSafetyPatch({ campusUuid, campusData })
      .then((response) => {
        const safetyData = response.data;
        commit('setSafetyInCampusDetail', safetyData);
        dispatch('roles/setSafetyMainSchool', { safetyMeasures: safetyData }, { root: true });
      }).catch(() => {
        dispatch('utils/error', textNotification.error, { root: true });
      });
  },
  async campusInfrastructurePatch({ dispatch, commit, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    await services.institutionsService.campusInfrastructurePatch({ campusUuid, campusData })
      .then((response) => {
        dispatch('utils/success', textNotification.success, { root: true });
        const infraData = response.data;
        commit('setInfrastructureInCampusDetail', infraData);
        dispatch('roles/setInfrastructureMainSchool', { infrastructures: infraData }, { root: true });
      }).catch(() => {
        dispatch('utils/error', textNotification.error, { root: true });
      });
  },
  async campusStaffPatch({ dispatch, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    await services.institutionsService.campusStaffPatch({ campusUuid, campusData })
      .then((response) => {
        const staffData = response.data;
        dispatch('roles/setStaffMainSchool', { staff: staffData }, { root: true });
        dispatch('utils/success', textNotification.success, { root: true });
      }).catch(() => {
        dispatch('utils/error', textNotification.error, { root: true });
      });
  },
  async campusStudentsPatch({ dispatch, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    await services.institutionsService.campusStudentsPatch({ campusUuid, campusData })
      .then((response) => {
        const staffData = response.data;
        dispatch('roles/setStudentsMainSchool', { staff: staffData }, { root: true });
        dispatch('utils/success', textNotification.success, { root: true });
      }).catch(() => {
        dispatch('utils/error', textNotification.error, { root: true });
      });
  },
  async campusContactsPatch({ dispatch, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    await services.institutionsService.campusContactsPatch({ campusUuid, campusData })
      .then((response) => {
        const contactsData = response.data;
        dispatch('roles/setContactsMainSchool', { contactsData }, { root: true });
        dispatch('utils/success', textNotification.success, { root: true });
      }).catch(() => {
        dispatch('utils/error', textNotification.error, { root: true });
      });
  },
  async campusSocialTokenPatch({ dispatch, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    await services.institutionsService.campusSocialTokenPatch({ campusUuid, campusData })
      .then((response) => {
        const tokensData = response.data;
        dispatch('roles/setSocialTokenMainSchool', { tokensData }, { root: true });
        dispatch('utils/success', textNotification.success, { root: true });
      }).catch(() => {
        dispatch('utils/error', textNotification.error, { root: true });
      });
  },
  async campusAudiovisualsPatch({ dispatch, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    await services.institutionsService.campusAudiovisualsPatch({ campusUuid, campusData })
      .then((response) => {
        dispatch('utils/success', textNotification.success, { root: true });
        return dispatch('roles/setHeadMasterMainSchoolAudiovisuals', { audiovisuals: response.data }, { root: true });
      })
      .catch(() => {
        dispatch('utils/error', textNotification.error, { root: true });
      });
  },
  async campusEducationalProjectPatch({ dispatch, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    await services.institutionsService.campusEducationalProjectPatch({ campusUuid, campusData })
      .then((response) => {
        const eduProjectData = response.data;
        dispatch('utils/success', textNotification.success, { root: true });
        dispatch('roles/setEduProjectMainSchool', { eduProjectData }, { root: true });
      })
      .catch(() => {
        dispatch('utils/error', textNotification.error, { root: true });
      });
  },
  async campusExtracurricularPatch({ dispatch, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    await services.institutionsService.campusExtracurricularPatch({ campusUuid, campusData })
      .then((response) => {
        const extracurricularData = response.data;
        dispatch('roles/setExtracurricularSchool', { extracurricularData }, { root: true });
        dispatch('utils/success', textNotification.success, { root: true });
      })
      .catch(() => {
        dispatch('utils/error', textNotification.error, { root: true });
      });
  },
  async campusFaqsPatch({ dispatch, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    await services.institutionsService.campusFaqsPatch({ campusUuid, campusData })
      .then((response) => {
        const faqsData = response.data;
        dispatch('utils/success', textNotification.success, { root: true });
        dispatch('roles/setFaqsMainSchool', { faqsData }, { root: true });
      })
      .catch(() => {
        dispatch('utils/error', textNotification.error, { root: true });
      });
  },
  async campusAchievementsPatch({ dispatch, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    await services.institutionsService.campusAchievementsPatch({ campusUuid, campusData })
      .then((response) => {
        const achievementsData = response.data;
        dispatch('utils/success', textNotification.success, { root: true });
        dispatch('roles/setAchievementsMainSchool', { achievementsData }, { root: true });
      })
      .catch(() => {
        dispatch('utils/error', textNotification.error, { root: true });
      });
  },
  async campusGeneralInformationPatch({ dispatch, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    await services.institutionsService.campusGeneralInformationPatch({ campusUuid, campusData })
      .then((response) => {
        const generalInfoData = response.data;
        dispatch('utils/success', textNotification.success, { root: true });
        dispatch('roles/setGeneralInformationPatch', { generalInfoData }, { root: true });
      })
      .catch(() => {
        dispatch('utils/error', textNotification.error, { root: true });
      });
  },
  async campusVacanciesPatch({ dispatch, rootGetters }, { campusUuid, vacancies }) {
    const textNotification = rootGetters['institutions/textNotification'];
    await services.institutionsService.campusVacanciesPatch({ campusUuid, vacancies })
      .then((response) => {
        const vacanciesData = response.data;
        dispatch('utils/success', textNotification.success, { root: true });
        dispatch('roles/setVacanciesPatch', { vacanciesData }, { root: true });
      })
      .catch(() => {
        dispatch('utils/error', textNotification.error, { root: true });
      });
  },
  async campusLanguagesPatch({ dispatch, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    await services.institutionsService.campusLanguagesPatch({ campusUuid, campusData })
      .then((response) => {
        const languagesData = response.data;
        dispatch('utils/success', textNotification.success, { root: true });
        dispatch('roles/setLanguagesPatch', { languagesData }, { root: true });
      }).catch(() => {
        dispatch('utils/error', textNotification.error, { root: true });
      });
  },
  async campusAboutEstablishmentPatch({ dispatch, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    await services.institutionsService.campusAboutEstablishmentPatch({ campusUuid, campusData })
      .then((response) => {
        const aboutSchoolData = response.data;
        dispatch('utils/success', textNotification.success, { root: true });
        dispatch('roles/setAboutEstablishmentPatch', { aboutSchoolData }, { root: true });
      })
      .catch(() => {
        dispatch('utils/error', textNotification.error, { root: true });
      });
  },
  async campusLocationPatch({ dispatch, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    await services.institutionsService.campusLocationPatch({ campusUuid, campusData }).then((response) => {
      dispatch('utils/success', textNotification.success, { root: true });
      dispatch('roles/setHeadmasterMainSchoolLocation', { location: [response.data] }, { root: true });
    }).catch(() => {
      dispatch('utils/error', textNotification.error, { root: true });
    });
  },

  async campusSportsPatch({ dispatch, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    await services.institutionsService.campusSportsPatch({ campusUuid, campusData }).then((response) => {
      dispatch('utils/success', textNotification.success, { root: true });
      dispatch('roles/setHeadmasterMainSchoolSports', { sports: response.data }, { root: true });
    }).catch(() => {
      dispatch('utils/error', textNotification.error, { root: true });
    });
  },

  async campusPaymentPatch({ dispatch, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    await services.institutionsService.campusPaymentPatch({ campusUuid, campusData })
      .then((response) => {
        const paymentData = response.data;
        dispatch('utils/success', textNotification.success, { root: true });
        dispatch('roles/setPaymentPatch', { paymentData }, { root: true });
      }).catch(() => {
        dispatch('utils/error', textNotification.error, { root: true });
      });
  },

  async campusPaymentV2Patch({ dispatch, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    await services.institutionsService.campusPaymentV2Patch({ campusUuid, campusData })
      .then((response) => {
        const paymentV2Data = response.data;
        dispatch('utils/success', textNotification.success, { root: true });
        dispatch('roles/setPaymentV2Patch', { paymentV2Data }, { root: true });
      }).catch(() => {
        dispatch('utils/error', textNotification.error, { root: true });
      });
  },

  async campusPridePointsPatch({ dispatch, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    return services.institutionsService.campusPridePointsPatch({ campusUuid, campusData }).then((response) => {
      const newPridePoints = response.data;
      dispatch('roles/setMainSchoolPridepoints', { pridepoints: newPridePoints }, { root: true });
      dispatch('utils/success', textNotification.success, { root: true });
      return newPridePoints;
    }).catch(() => {
      dispatch('utils/error', textNotification.error, { root: true });
      return [];
    });
  },

  async campusAdmissionProcessPatch({ dispatch, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    return services.institutionsService.campusAdmissionProcessPatch({ campusUuid, campusData })
      .then(({ data: newAdmissionData }) => {
        const {
          admission_system: admissionSystem,
          required_documents: requiredDocuments,
          required_tests: requiredTests,
        } = newAdmissionData;
        dispatch('roles/setMainSchoolAdmissionSystem', { admissionSystem: [admissionSystem] }, { root: true });
        dispatch('roles/setMainSchoolAdmissionSystemRequiredDocuments', { requiredDocuments }, { root: true });
        dispatch('roles/setMainSchoolAdmissionSystemRequiredTests', { requiredTests }, { root: true });
        dispatch('utils/success', textNotification.success, { root: true });
        return newAdmissionData;
      }).catch(() => {
        dispatch('utils/error', textNotification.error, { root: true });
        return [];
      });
  },

  async campusConfigPatch({ dispatch, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    return services.institutionsService.campusConfigPatch({ campusUuid, campusData }).then((response) => {
      const newConfig = response.data;
      dispatch('roles/setConfigMainSchool', { configData: newConfig }, { root: true });
      dispatch('utils/success', textNotification.success, { root: true });
      return newConfig;
    }).catch(() => {
      dispatch('utils/error', textNotification.error, { root: true });
      return [];
    });
  },
  async campusGlobalContactsPatch({ dispatch, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    return services.institutionsService.campusGlobalContactsPatch({ campusUuid, campusData }).then((response) => {
      const newGlobalContacts = response.data;
      dispatch('roles/setGlobalContactsPatch', { globalContacts: newGlobalContacts }, { root: true });
      dispatch('utils/success', textNotification.success, { root: true });
      return newGlobalContacts;
    }).catch(() => {
      dispatch('utils/error', textNotification.error, { root: true });
      return [];
    });
  },
  async campusProgramsPatch({ dispatch, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    return services.institutionsService.campusProgramsPatch({ campusUuid, campusData }).then((response) => {
      const newPrograms = response.data;
      dispatch('roles/setProgramsPatch', { programs: newPrograms }, { root: true });
      dispatch('retrieveCampusDetails', { searchCode: campusUuid });
      dispatch('utils/success', textNotification.success, { root: true });
      return newPrograms;
    }).catch(() => {
      dispatch('utils/error', textNotification.error, { root: true });
      return [];
    });
  },

  async campusSectionTextPatch({ dispatch, rootGetters }, { campusUuid, campusData }) {
    const textNotification = rootGetters['institutions/textNotification'];
    return services.institutionsService.campusSectionTextPatch({ campusUuid, campusData }).then((response) => {
      const newSectionText = response.data;
      dispatch('roles/setSectionTextPatch', { sectionText: newSectionText }, { root: true });
      dispatch('utils/success', textNotification.success, { root: true });
      return newSectionText;
    }).catch(() => {
      dispatch('utils/error', textNotification.error, { root: true });
      return [];
    });
  },
  async exploreCampus({ dispatch, rootGetters, commit }, { campus }) {
    const isGuest = rootGetters['authentication/isGuest'];
    const currentStudent = rootGetters['authentication/currentStudent'];
    if (!isGuest && Object.keys(currentStudent).length > 0) {
      const campusUuid = campus.uuid;
      services.institutionsService
        .exploreCampus({
          currentStudent,
          campus: campusUuid,
        })
        .then(() => {
          dispatch('retrieveExploredCampuses').then(() => {
            dispatch('updateExploredMarkers', [campus]);
          });
        });
    } else {
      commit('setGuestExploredCampuses', campus);
    }
  },
  async exploreSchoolCard({ dispatch, rootGetters, commit }, { campus }) {
    const isGuest = rootGetters['authentication/isGuest'];
    const currentStudent = rootGetters['authentication/currentStudent'];
    if (!isGuest && Object.keys(currentStudent).length > 0) {
      const campusUuid = campus.uuid;
      services.institutionsService
        .exploreSchoolCard({
          currentStudent,
          campus: campusUuid,
        })
        .then(() => {
          dispatch('retrieveExploredSchoolCard').then(() => {
            dispatch('updateExploredSchoolCards', [campus]);
          });
        });
    } else {
      commit('setGuestExploredCards', campus);
    }
  },
  setShowCampusDetail({ state, commit, dispatch }, { bool, uuid }) {
    const confirm = bool || !!uuid;
    if (uuid) {
      commit('addExploredMapCardToSchool', uuid);
    }
    const { campusDetails } = state;

    const hasDetails = campusDetails && Object.keys(campusDetails).length > 0;
    const detailsMatch = hasDetails && campusDetails.uuid === uuid;

    if (confirm && !detailsMatch) {
      dispatch('retrieveCampusDetails', { searchCode: uuid });
    }
    commit('setShowCampusDetail', confirm);
    dispatch('setRedirectedToProcesses', false);
  },
  setShowMapCard({ commit }, newVal) {
    commit('addExploredMapCardToSchool', newVal);
  },
  setLoadingProfile({ commit }, newVal) {
    commit('setLoadingProfile', newVal);
  },
  async setCommuneList({ commit }) {
    services.elasticSearchService.elasticCommuneList().then((response) => {
      const list = response.data.results;
      commit('setCommuneList', list);
    });
  },
  // FIXME: Las dos actions siguientes hacen CASI lo mismo. De hecho, no se justifica
  // que sean actions (excepto quizás la segunda). El valor guardado no se usa
  getSchoolsFromCommune({ commit }, { filter }) {
    const communeList = services.elasticSearchService
      .elasticSchoolCommune(filter)
      .then((response) => {
        commit('setSchoolFromCommune', response.data);
        return response.data;
      });
    return communeList;
  },
  async getInstitutionsFromCommune({ commit }, { filter }) {
    const communeList = await services.elasticSearchService
      .elasticSchoolCommune(filter)
      .then((response) => {
        const schoolsInCommune = [];
        response.data.results.forEach((school) => {
          schoolsInCommune.push(school.institution_name);
        });
        commit('setInstitutionFromCommune', schoolsInCommune);
        return response.data;
      });
    return communeList;
  },
  async getSchoolInstitution({ commit }, { filter }) {
    commit('clearSchoolInstitution');
    const response = await services.elasticSearchService.elasticSchoolInstitution(filter);
    const institutionInfo = {
      name: response.data.results[0].institution_name,
      commune: response.data.results[0].commune,
    };
    commit('setSchoolInstitution', institutionInfo);
  },
  async getSchoolCampus({ commit }, { filter }) {
    commit('clearSchoolCampus');
    const response = await services.elasticSearchService.elasticSchoolCampus(filter);
    const institutionInfo = {
      name: response.data.results[0].campus_name,
      commune: response.data.results[0].commune,
    };
    commit('setSchoolCampus', institutionInfo);
  },
  clearSchoolInstitution({ commit }) {
    commit('clearSchoolInstitution');
  },
  getGradeRecommendation({ commit }, { birthDate, campusCode }) {
    const communeList = services.institutionsService
      .getGradeRecommendations({ birthDate, campusCode })
      .then((response) => {
        commit('setGradeRecommendation', response.data);
        return response.data;
      })
      .catch(() => {
        commit('setGradeRecommendation', null);
      });
    return communeList;
  },
  // eslint-disable-next-line no-empty-pattern
  getSchoolTravelTime({ }, travelTime) {
    return Promise.allSettled([
      services.geoToolsService.retrieveTravelTime({ travelTime }),
      services.geoToolsService.retrieveBusRoutes({ travelTime }),
    ]);
  },
  setRedirectedToProcesses({ commit }, value) {
    commit('setRedirectedToProcesses', value);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
