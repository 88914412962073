<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader :content-sections="['logo']" inline-logo small-logo />
      <ModalContent
        :content-sections="['smallTitle', 'subtitle', 'loader', 'link']"
        :small-title-text="'user_register.redirect.title'"
        :subtitle-text="getSubtitleText()"
        :loader-size="80"
        :loader-width="8"
        :link-text="'user_register.redirect.text_link'"
        subtitle-light
        subtitle-center
        @toLink="redirect"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import { getCookie } from '@/utils/cookies';

export default {
  name: 'RegisterRedirect',
  components: {
    ModalHeader,
    ModalContent,
  },
  data() {
    return {
      origin: null,
      url: null,
    };
  },
  computed: {
    ...mapGetters({
      externalLoginInfo: 'authentication/externalLoginInfo',
      currentStudent: 'authentication/currentStudent',
      digitalEnrollmentParameters: 'digitalEnrollment/parameters',
      sessionToken: 'authentication/sessionToken',
    }),
    userCookieIsSet() {
      return this.sessionToken === getCookie('tetherToken');
    },
  },
  watch: {
    userCookieIsSet() {
      this.redirect();
    },
  },
  created() {
    this.origin = this.externalLoginInfo.origin;
    this.url = this.externalLoginInfo.redirectURL;
    this.redirect();
  },
  methods: {
    ...mapActions({
      setExternalLoginFlow: 'authentication/setExternalLoginFlow',
    }),
    getSubtitleText() {
      const origin = this.$t(`user_register.redirect.origins.${this.origin}.name`);
      return this.$t('user_register.redirect.subtitle', { origin });
    },
    redirect() {
      if (this.userCookieIsSet) {
        const urlParams = new URLSearchParams();
        if (Object.keys(this.digitalEnrollmentParameters).length) {
          const { tenantId, userType } = this.digitalEnrollmentParameters;
          if (tenantId) {
            urlParams.append('tenantId', tenantId);
          }
          if (userType === 'legalguardian' && this.currentStudent) {
            urlParams.append('applicantId', this.currentStudent.uuid);
          }
        }
        const paramsString = urlParams.toString();
        const toURL = `${this.url}${paramsString ? `?${paramsString}` : ''}`;
        this.setExternalLoginFlow({ reset: true });
        window.location.href = toURL;
      }
    },
  },
};
</script>
