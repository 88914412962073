<template>
  <span class="campus-detail__panels__message">
    {{ $t('campus-details.message_in_panel') }}
    <button v-t="'campus-details.message_in_panel_contact'" type="button" @click="goToContactUs" />
  </span>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'MessageInPanel',
  props: {
    from: {
      type: String,
      default: () => null,
    },
  },
  methods: {
    ...mapActions({
      setGoToFormContactFrom: 'messaging/setGoToFormContactFrom',
    }),
    goToContactUs() {
      document.getElementById('contact-us').scrollIntoView({ behavior: 'smooth' });
      this.setGoToFormContactFrom({ from: this.from });
    },
  },
};
</script>
