<template>
  <section class="w-full d-flex flex-column bg-neutral">
    <TextAtom
      :value="$t('feedback.message.title', { name: student })"
      tag="h2"
      font="poppins"
      color="primary-700"
      weight="400"
      size="subtitle"
      class="text-left"
    />

    <figure class="mt-3">
      <SvgIcon
        icon="marker-around.svg"
        alt="application icon"
        size="25"
      />
      <figcaption class="ml-2">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="totalCampuses.around" />
      </figcaption>
    </figure>
    <figure class="mt-3">
      <SvgIcon
        icon="marker-payment-performance.svg"
        alt="application icon"
        size="21"
        class="ml-1 mr-1 image-icon"
      />
      <figcaption class="ml-1">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="totalCampuses.aroundPaymentAndPerformance" />
      </figcaption>
    </figure>
  </section>
</template>

<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';

export default {
  name: 'FeedbackMessage',
  components: {
    TextAtom, SvgIcon,
  },
  props: {
    campuses: {
      type: Object,
      default: () => ({}),
    },
    student: {
      type: String,
      default: '',
    },
  },
  computed: {
    totalCampuses() {
      return {
        around: this.$t('feedback.message.around', { total: this.campuses.around }),
        aroundPaymentAndPerformance: this.$t('feedback.message.payment_performance', { total: this.campuses.aroundPaymentAndPerformance }),
      };
    },
  },
};
</script>
<style scoped>
figure {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-items: center;
}

.image-icon {
  width: 70px;
}

figcaption{
  color: #4F4F4F;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.045px;
  width: calc(100% - 70px);
  text-align: left;
}
</style>
