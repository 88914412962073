<template>
  <section
    v-if="virtualTour"
    class="campus-detail__virtual-tour campus-detail__module mt-2"
    :class="{ 'campus-detail__virtual-tour--mobile': isMobile }"
  >
    <picture class="d-flex flex-row align-center">
      <img src="@/assets/icons/campus-properties/multimedia/tour360.svg" width="25px" alt="Tour virtual" />
      <h3 class="campus-detail__module__title ml-2"> {{ $t('campus-details.virtual_tour') }} </h3>
    </picture>
    <iframe
      id="iframeTour"
      title="Iframe 360 tour"
      class="fill-width"
      :src="virtualTour"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write;
      encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      loading="lazy"
    />
  </section>
</template>

<script>
import trackMixPanel from '@/utils/mixpanel';
import store from '@/store/index';

window.focus();
window.addEventListener(
  'blur',
  () => {
    setTimeout(() => {
      if (document.activeElement.tagName === 'IFRAME') {
        const campusDetails = store.getters['institutions/campusDetails'];
        trackMixPanel('sp_school_tour', {
          school_id: campusDetails.uuid,
          campus_code: campusDetails.campus_code,
          institution_code: campusDetails.institution_code,
          name: campusDetails.campus_name,
        });
      }
    });
  },
  { once: true },
);
export default {
  name: 'CampusDetailTour',
  props: {
    virtualTour: {
      type: String,
      default: null,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
