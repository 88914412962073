var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"register-main"},[_c('v-text-field',{staticClass:"register-main--text-expand w-full",attrs:{"background-color":"#EBEBEB","item-color":"#1a0c4c","color":"#1a0c4c","height":"48px","loading":_vm.indexTextExpand === 0 ? _vm.loading : _vm.loadingAddress,"label":_vm.setTextFieldLabel(),"disabled":_vm.indexTextExpand === 0 ? false : _vm.disabled,"solo":"","clearable":"","hide-details":""},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('v-expand-transition',[(
        _vm.suggestedPlaces
          && _vm.suggestedPlaces.length > 0
          && ((_vm.searchTerm?.length > 3 && !_vm.loading && _vm.indexTextExpand === 0)
            || (_vm.searchTerm?.length > 0 && !_vm.loadingAddress && _vm.indexTextExpand === 1)))?_c('v-list',{staticClass:"places-search-bar",class:_vm.suggestedPlaces.length > 5 ? 'places-search-height' : ''},_vm._l((_vm.suggestedPlaces),function(info){return _c('v-list-item',{key:info.display1,staticClass:"places-search-bar__item",attrs:{"title":`${info.display1}`},on:{"click":function($event){return _vm.select(info)}}},[(info.searchAddress && _vm.indexTextExpand === 1)?_c('div',[_c('TextAtom',{staticClass:"places-search-bar__city",attrs:{"value":_vm.$t('user_register.register_location.search_option')
              + info.display1
              + _vm.$t('user_register.register_location.search_option_in_map'),"tag":"label","font":"inter","color":"primary-700","weight":"400","size":"subtitle"}})],1):(info.current)?_c('div',{staticClass:"flex"},[_c('SVGIcon',{attrs:{"size":'24',"icon":'location-purple.svg',"alt-text":info.alt}}),_c('TextAtom',{staticClass:"places-search-bar__current ml-3",attrs:{"value":info.currentLocationTitle,"tag":"label","font":"inter","color":"primary-700","weight":"400","size":"subtitle"}})],1):_c('div',[(_vm.indexTextExpand === 0)?_c('TextAtom',{staticClass:"places-search-bar__city",attrs:{"value":info.cityTitle,"tag":"label","font":"inter","color":"primary-700","weight":"400","size":"subtitle"}}):_vm._e(),(_vm.indexTextExpand === 0)?_c('TextAtom',{staticClass:"places-search-bar__upper",attrs:{"value":info.display2,"tag":"label","font":"inter","color":"primary-700","weight":"400","size":"subtitle"}}):_vm._e(),(_vm.indexTextExpand === 1)?_c('TextAtom',{staticClass:"places-search-bar__city",attrs:{"value":info.formatted_address,"tag":"label","font":"inter","color":"primary-700","weight":"400","size":"subtitle"}}):_vm._e()],1)])}),1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }