<template>
  <div
    style="margin-bottom: 12px"
    class="register-main"
  >
    <img
      class="register-main--logo"
      :class="{
        'register-main--small-logo': small,
        'register-main--medium-logo': medium,
        'register-main--inline-logo': inline,
      }"
      src="@/assets/iconsBase/logos/tether-logo.png"
      alt="tether"
    />
    <div v-if="welcome" :style="setMargin()">
      <h1 v-t="'user_register.welcome.title-normal'" class="register-main--title" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegisterBigLogo',
  components: {},
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    welcome: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setMargin() {
      if (this.inline) {
        return null;
      }
      if (this.small) {
        return 'margin-top: 16px';
      }
      return 'margin-top: 32px';
    },
  },
};
</script>
