<template>
  <img
    :src="isResponsive ? resolvedSrc : src"
    :alt="alt"
    :width="width"
    loading="lazy"
    :style="stylesImageAtom"
    @error="errorLoadingImage($event)"
  />
</template>

<script>
// This is the last version of the ImageAtom.vue component
import { resolveImageUrl } from '@/utils/strings';

export default {
  name: 'ImageAtom',
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: 'No image',
    },
    objectFit: {
      type: String,
      default: 'cover',
      validator(value) {
        return ['fill', 'contain', 'cover'].includes(value);
      },
    },
    displayOption: {
      type: String,
      default: 'block',
      validator(value) {
        return ['inline-flex', 'block'].includes(value);
      },
    },
    width: {
      type: String,
      default: '100%',
    },
    isResponsive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    stylesImageAtom() {
      return {
        objectFit: this.objectFit,
        display: this.displayOption,
      };
    },
    currentBreakpoint() {
      if (this.$vuetify.breakpoint.xs) {
        return 'mobile';
      }
      if (this.$vuetify.breakpoint.smAndDown) {
        return 'tablet';
      }
      return 'desktop';
    },
    resolvedSrc() {
      return resolveImageUrl(this.src, this.currentBreakpoint);
    },
  },
  methods: {
    errorLoadingImage($event) {
      // TODO: add more images for errors
      // eslint-disable-next-line no-param-reassign, global-require
      $event.target.src = require('@/assets/icons/school.svg');
    },
  },
};
</script>
