<template>
  <div class="register-main register-main--spaced">
    <div>
      <HeaderModal :content-sections="['close']" @close="$emit('close')" />
      <ContentModal
        :content-sections="['subtitle', 'icon']"
        subtitle-center
        :subtitle-text="'modals.warning_no_student_modal.guest-title'"
        :icon-color="'#00101A'"
        :icon-name="'mdi-alert-outline'"
        :icon-type="'warning'"
      />
      <ContentModal
        :content-sections="['subtitle']"
        :subtitle-text="'modals.warning_no_student_modal.guest-text'"
        subtitle-light
      />
    </div>
    <CallToActions
      :option-text1="'modals.warning_no_student_modal.guest-btn1'"
      :option-text2="'modals.warning_no_student_modal.guest-btn2'"
      :types="['option']"
      :spaced="false"
      option-btn-no-margin
      @nextStep="nextStep"
    />
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ContentModal from '@/components/molecules/modals/Content.vue';
import HeaderModal from '@/components/molecules/modals/Header.vue';
import { mapActions } from 'vuex';

export default {
  name: 'FavWarningModal',
  components: {
    HeaderModal,
    CallToActions,
    ContentModal,
  },
  methods: {
    ...mapActions({
      setForcedStep: 'userRegister/setForcedStep',
    }),
    nextStep(i) {
      if (i === 1) {
        this.setForcedStep({ step: 0 }).then(() => {
          this.$router.push('/login');
          this.$emit('closeAllDialogs');
        });
        return;
      }
      this.setForcedStep({ step: 2 }).then(() => {
        this.$router.push('/login');
        this.$emit('closeAllDialogs');
      });
    },
  },
};
</script>
