<template>
  <div class="register-main">
    <p
      v-t="text"
      class="register-main--password-recover"
      style="margin-top: 12px"
      @keydown="/* TODO */"
      @click="recoverLinkClick()"
    />
  </div>
</template>

<script>
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'RecoverLink',
  components: {},
  props: {
    text: {
      type: String,
      default: '',
    },
    mixpanelTag: {
      type: String,
      default: null,
    },
  },
  methods: {
    recoverLinkClick() {
      if (this.mixpanelTag) {
        trackMixPanel(this.mixpanelTag);
      }
      this.$emit('toRecovery');
    },
  },
};
</script>
