var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-main--multiStyle",class:{ 'register-main--multiStyle--center': _vm.center.length > 0 },style:(_vm.small.length > 0
    ? 'margin-bottom: 12px' : 'margin-bottom: 20px')},_vm._l((_vm.textArrayLength),function(textIndex){return _c('div',{key:textIndex,staticStyle:{"height":"100%"}},[_c('p',{directives:[{name:"t",rawName:"v-t",value:(_vm.setText(textIndex)),expression:"setText(textIndex)"}],staticClass:"register-main--multiStyle--text",class:{
        'register-main--multiStyle--text--tiny': _vm.small.includes(textIndex),
        'register-main--multiStyle--text--small': _vm.small.includes(textIndex),
        'register-main--multiStyle--text--bold': _vm.bold.includes(textIndex),
        'register-main--multiStyle--text--bolder': _vm.bolder.includes(textIndex),
        'register-main--multiStyle--text--link': _vm.link.includes(textIndex),
        'register-main--multiStyle--text--underline': _vm.underline.includes(textIndex),
        'register-main--multiStyle--text--secondary': _vm.secondary.includes(textIndex),
        'register-main--multiStyle--text--white': _vm.white.includes(textIndex),
        'register-main--multiStyle--text--disabled': _vm.disabled,
        'register-main--multiStyle--text--center': _vm.center.includes(textIndex),
      },style:(_vm.spaced.includes(textIndex) ? 'margin: 0 4px;' : ''),on:{"keydown":function($event){return _vm.textClick(textIndex)},"click":function($event){return _vm.textClick(textIndex)}}})])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }