import { TENANT_CONFIGURATION } from '@/constants/tenant';

/**
 * Check if a student is eligible for a scholarship. Eligibility is defined by the matcher function of the scholarship
 *
 * If a campus is provided, we check if the campus has an agreement with the scholarship as well
 *
 * @param {*} student - The student (applicant) object
 * @param {*} campus - The campus object (optional)
 * @param {*} options - Options to force eligibility or offer of scholarships
 * @param {boolean} options.forceEligibility - Force the eligibility of the student for all scholarships
 * @param {boolean} options.forceOffer - Force the offer of all scholarships from the campus
 * @returns {Array} - An array of scholarships that the student is eligible for
 */
const matchScholarships = ({ student, campus, options = { forceEligibility: false, forceOffer: false } }) => {
  const {
    forceEligibility = false, // Force eligibility for the student
    forceOffer = false, // Force the offer of scholarships from the campus
  } = options;
  // No student, no scholarships (duh)
  const studentExists = Object.keys(student || {}).length > 0;
  if (!studentExists && !forceEligibility) return [];
  const { CATEGORIES: { SCHOLARSHIPS } } = TENANT_CONFIGURATION;
  const tenantOffersScholarships = Object.keys(SCHOLARSHIPS || {}).length > 0;
  // If the tenant doesn't offer scholarships, no scholarships
  if (!tenantOffersScholarships) return [];
  const campusAgreements = new Set(campus?.agreement_set?.map(({ id }) => id) || []);
  // If there's a campus and the campus doesn't have agreements, no scholarships
  if (!forceOffer && !campusAgreements.size) return [];
  // If there's a campus, we filter the scholarships by the ones that have an agreement with the campus and match the
  // student
  return Object.values(SCHOLARSHIPS).filter(
    (scholarship) => (
      (forceOffer || campusAgreements.has(scholarship.agreementId))
      && (forceEligibility || scholarship.matcher(student))
    ),
  );
};

export {
  matchScholarships,
};
