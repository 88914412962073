<template>
  <div class="panel_container_row justify-start fill-width">
    <img class="ml-0 panel_container_row__abs_img--v5" :src="iconSrc" alt="face" />
    <span :class="textClass" class="pl-3 whitespace-nowrap">{{ count }}</span>
    <span :class="titleClass" class="d-block my-auto">{{ title }}<span class="font-bold">{{ year }}</span></span>
  </div>
</template>

<script>
export default {
  props: {
    iconSrc: {
      type: String,
      default: '',
    },
    textClass: {
      type: String,
      default: '',
    },
    titleClass: {
      type: String,
      default: '',
    },
    count: {
      type: [Number, String],
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    year: {
      type: Number,
      default: 0,
    },
  },
};
</script>
