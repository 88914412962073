<template>
  <ConfirmationDialog
    v-if="isActive"
    value
    wide
    hide-cancel-button
    :loading="loading"
    :can-continue="canContinue"
    title="shared.grade.title"
    @confirm="redirect"
    @cancel="close"
  >
    <template v-if="plugin.data?.image" #banner>
      <ImageAtom
        :src="plugin.data.image"
        :alt="landingCode"
        class="w-1/2 mx-auto"
      />
    </template>
    <SelectEducationalLevels
      v-model="selection.levels"
      :educational-levels="plugin.levels"
      prefill-with-active-grades
      summary-chips
      collapse-out-of-focus
    />
    <template
      v-if="plugin.locations?.length > 0"
    >
      <SmallTitle
        text="modals.landing.location.title"
        center-left
      />
      <RegisterAutocomplete
        v-model="selection.location"
        :items="plugin.locations"
        :placeholder="$t('modals.landing.location.placeholder')"
        label-key="display_name"
      />
    </template>
  </ConfirmationDialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ImageAtom from '@/components/atoms/images/ImageAtom.vue';
import ConfirmationDialog from '@/components/molecules/dialogs/ConfirmationDialog.vue';
import SelectEducationalLevels from '@/components/shared/SelectEducationalLevels.vue';
import RegisterAutocomplete from '@/components/atoms/autocompletes/RegisterAutocomplete.vue';
import trackMixPanel from '@/utils/mixpanel';
import services from '@/services';
import { normalizeString } from '@/utils/strings';
import SmallTitle from '@/components/atoms/titles/SmallTitle.vue';

export default {
  name: 'LandingPluginModal',
  components: {
    ImageAtom,
    ConfirmationDialog,
    SelectEducationalLevels,
    RegisterAutocomplete,
    SmallTitle,
  },
  data() {
    return {
      loading: false,
      landingCode: null,
      plugin: {
        data: undefined,
        levels: undefined,
        locations: undefined,
      },
      selection: {
        location: null,
        levels: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      activeModal: 'utils/activeModal',
      activeModalData: 'utils/activeModalData',
    }),
    canContinue() {
      const validLocation = (this.plugin.locations || []).length === 0 || this.selection.location;
      const validLevels = this.selection.levels.length > 0;
      return validLocation && validLevels;
    },
    isActive() {
      return this.activeModal === 'LandingPlugin';
    },
  },
  watch: {
    '$route.query.landing': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.updateModal();
        }
      },
    },
  },
  created() {
    this.updateModal();
  },
  methods: {
    ...mapActions({
      deactivateModal: 'utils/deactivateModal',
    }),
    close(event) {
      const isAutocompleteSelection = !!event?.target?.closest('div.v-autocomplete__content');
      if (!isAutocompleteSelection) this.deactivateModal();
    },
    fetchPluginData() {
      services.pluginsService.retrieveLandingPluginData(this.landingCode)
        .then((response) => {
          this.plugin.data = response.data;
          if (this.plugin.data) {
            return Promise.all([
              services.pluginsService.retrieveLandingPluginLevels(this.landingCode),
              services.pluginsService.retrieveLandingPluginLocations(this.landingCode),
            ]);
          }
          return Promise.resolve([null, null]);
        }).then(([levels, locations]) => {
          this.plugin.levels = levels.data;
          this.plugin.locations = locations.data;
        })
        .catch()
        .finally(() => {
          this.loading = false;
        });
    },
    resetSelection() {
      this.selection = {
        location: null,
        levels: [],
      };
    },
    updateModal() {
      this.landingCode = this.$route.query.landing || this.activeModalData?.pluginCode || 'default';
      this.loading = true;
      this.resetSelection();
      // FIXME: actually, we always have a landing code, so this condition is not necessary
      if (this.landingCode) {
        this.fetchPluginData();
      } else {
        this.loading = false;
      }
    },
    redirect() {
      const { levels, location } = this.selection;
      const normalizedLocation = location ? normalizeString(location) : undefined;
      const gradeIds = levels.map(({ grade: { id } }) => id);
      // TODO: Validate if we need this, and if we do, validate whether the tag is correct
      trackMixPanel('click_continue_gob_plugin', {
        pluginCode: this.landingCode,
        location: normalizedLocation,
        grades: gradeIds,
      });
      const route = {
        name: 'Explorer',
        query: {
          locationName: normalizedLocation,
          network_id: this.plugin.data?.networks,
          grade: gradeIds,
        },
      };
      this.$router.push(route);
    },
  },
};
</script>

<style scoped>

</style>
