<template>
  <div
    class="register-main register-main--spaced"
    :class="{
      'justify-center':
        preCheckStatus === null || (preCheckStatus.found && preCheckStatus.is_verified),
    }"
  >
    <div
      v-if="preCheckStatus === null || (preCheckStatus.found && preCheckStatus.is_verified)"
      class="fill-width d-flex justify-center align-center"
    >
      <LoaderCircular
        v-if="preCheckStatus === null || preCheckStatus.found"
        :size="80"
        :color="'#1a0c4c'"
        :show="true"
      />
    </div>
    <div
      v-if="
        (preCheckStatus !== null && !preCheckStatus.found)
          || (preCheckStatus.found && !preCheckStatus.is_verified)
      "
      class="fill-width d-flex flex-column justify-center align-center"
    >
      <ModalHeader
        :content-sections="['actions', 'title', 'logo']"
        :actions-tag="'click_reg_verification-code_back-button'"
        :title-text="'user_register.register_code.title'"
        small-logo
        @back="$emit('backStep')"
      />
      <ModalContent
        :class="inWaitingList && !mobile ? 'deg-waiting-list--mobile' : ''"
        :content-sections="['subtitle', 'verification', 'question', 'link']"
        :subtitle-text="
          userPhone
            ? 'user_register.register_code.phone-subtitle'
            : 'user_register.register_code.subtitle'
        "
        :subtitle-center="inWaitingList"
        :verification-no-info-error="noInfoError"
        :question-text="'user_register.register_code.resend-text'"
        :link-text="'user_register.register_code.resend-link'"
        :link-text-tag="'click_reg_verification-code_resend-textlink'"
        @setCode="setCode"
        @toLink="resendCode()"
      />
    </div>
    <CallToActions
      v-if="
        (preCheckStatus !== null && !preCheckStatus.found)
          || (preCheckStatus.found && !preCheckStatus.is_verified)
      "
      :class="inWaitingList && !mobile ? 'deg-waiting-list--mobile' : ''"
      :loader="codeLoader"
      :types="['main']"
      :active-value="code === '' ? false : true"
      :main-button-text="'user_register.continue'"
      :main-button-tag="'click_reg_verification-code_continue-button'"
      :spaced="false"
      @continue="continueMethod()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import LoaderCircular from '@/components/atoms/loaders/LoaderCircular.vue';

export default {
  name: 'RegisterCode',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
    LoaderCircular,
  },
  props: {
    inWaitingList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      code: '',
      noInfoError: null,
      codeLoader: false,
    };
  },
  computed: {
    ...mapGetters({
      currentStep: 'userRegister/currentStep',
      validCode: 'authentication/validCode',
      getRegister: 'userRegister/register',
      userEmail: 'authentication/userEmail',
      userPhone: 'authentication/userPhone',
      preCheckStatus: 'authentication/preCheckStatus',
      legalGuardianAddress: 'authentication/legalGuardianAddress',
      loading: 'authentication/loading',
      students: 'authentication/students',
      inDigitalEnrollment: 'digitalEnrollment/inDigitalEnrollment',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    preCheckStatus: {
      handler(newVal) {
        if (newVal.is_verified !== null) {
          this.codeLoader = false;
          if (newVal.is_verified && newVal.found && newVal.same_password) {
            this.$emit('nextStep');
          }
          if (this.code && newVal.is_verified === false) {
            return this.error(this.$t('user_register.register_code.error'));
          }
          return null;
        }
        return null;
      },
    },
    students: {},
  },
  methods: {
    ...mapActions({
      resendVerificationCode: 'authentication/resendVerificationCode',
      verifyCode: 'authentication/verifyCode',
      error: 'utils/error',
      success: 'utils/success',
    }),
    resendCode() {
      this.resendVerificationCode({
        email: this.userEmail,
        phone: this.userPhone,
        authMethod: this.userPhone ? 'phone' : 'email',
      }).then(() => {
        this.success(this.$t('user_register.register_code.success'));
      });
    },
    async continueMethod() {
      this.codeLoader = true;
      if (!this.code) {
        this.noInfoError = true;
      } else {
        const data = {
          code: this.code,
          email: this.userPhone ? null : this.userEmail,
          phone: this.userPhone ?? null,
          authType: this.userPhone ? 'phone' : 'email',
        };
        this.verifyCode(data);
      }
      return null;
    },
    setCode(code) {
      this.code = code;
    },
  },
};
</script>
