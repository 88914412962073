import Vue from 'vue';

Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    // eslint-disable-next-line no-param-reassign
    el.handleOutsideClick = (e) => {
      e.stopPropagation();
      if (!el.contains(e.target)) {
        const { handler } = binding.value;
        if (handler instanceof Function) {
          handler();
        } else {
          vnode.context[handler]();
        }
      }
    };
    document.body.addEventListener('click', el.handleOutsideClick);
    document.body.addEventListener('touchstart', el.handleOutsideClick);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.handleOutsideClick);
    document.body.removeEventListener('touchstart', el.handleOutsideClick);
  },
});
