import services from '@/services';

const getDefaultState = () => ({
  goToFormContactFrom: null,
});

const state = {
  ...getDefaultState(),
};

const getters = {
  goToFormContactFrom(storeState) {
    return storeState.goToFormContactFrom;
  },
};

const mutations = {
  setGoToFormContactFrom(state, from) {
    state.goToFormContactFrom = from;
  },
};

const actions = {
  // TODO: remove this legacy code when fully replaced
  // eslint-disable-next-line
  getSchoolMessages({}, { schoolUuid }) {
    return services.messagingService.getMessages({ schoolUuid }).then((response) => response);
  },
  // TODO: remove this legacy code when fully replaced
  getMessagesCount() {
    return services.messagingService.getMessagesCount().then((response) => response);
  },
  // TODO: remove this legacy code when fully replaced
  // eslint-disable-next-line
  getGroupedMessages({}, { index, uuid, groupBy }) {
    return services.messagingService
      .getGroupedMessages({ index, uuid, groupBy })
      .then((response) => response)
      .catch(() => ({ status: 404 }));
  },
  setGoToFormContactFrom({ commit }, { from }) {
    commit('setGoToFormContactFrom', { from });
  },
  // TODO: remove this legacy code when fully replaced
  getMessages({ rootGetters }) {
    const legalGuardianUUID = rootGetters['authentication/legalGuardianUUID'];
    return services.messagingService.getMessages(legalGuardianUUID).then((data) => data);
  },
  async sendCustomerSupportMessage({ rootGetters }, { form, callback, callbackError }) {
    const snackbarError = rootGetters['utils/error'];
    const authToken = rootGetters['authentication/loggedToken'];
    await services.messagingServices
      .sendCustomerSupportMessage(form, authToken)
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        snackbarError(error);
        if (callbackError) {
          callbackError();
        }
      });
  },
  async sendLeadMessage({ rootGetters }, { form, callback, callbackError }) {
    const authToken = rootGetters['authentication/loggedToken'];
    await services.messagingServices
      .sendLeadMessage(form, authToken)
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch(() => {
        callbackError();
      });
  },
  // TODO: NOT YET USED IN COMPONENTS
  async getSchoolNewLeadsMessages({ rootGetters }, { campusCode }) {
    const authToken = rootGetters['authentication/loggedToken'];
    return services.messagingServices.getNewLeadsMessages({ campusCode }, authToken).then((response) => response);
  },
  async getSchoolNewLeadsMessagesCount({ rootGetters }, { campusCode }) {
    const authToken = rootGetters['authentication/loggedToken'];
    return services.messagingServices.getNewLeadsMessagesCount({ campusCode }, authToken).then((response) => response);
  },
  async getGroupedNewLeadsMessages({ rootGetters }, { campusCode, groupBy }) {
    const authToken = rootGetters['authentication/loggedToken'];
    return services.messagingServices
      .getGroupedNewLeadsMessages({ campusCode, groupBy }, authToken)
      .then((response) => response)
      .catch(() => ({ status: 404 }));
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
