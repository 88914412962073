import { TETHER_CATEGORY, TETHER_CATEGORY_STYLE } from '@/constants/category';
import { getI18nPropertyCategoryText } from '@/utils/locale';
import i18n from '@/plugins/i18n';

// This is a special value that can be used to indicate that the category should not have an icon
// To use it, pass it as the icon override to buildCategoryObject
const NO_ICON = 'NO_ICON';

const buildNoInfoCategoryObject = (propertyKey, overrides = {
  label: null,
  icon: null,
  title: null,
}) => ({
  category: TETHER_CATEGORY.NO_DATA,
  icon: overrides.icon === NO_ICON ? null : overrides.icon,
  label: overrides.label || 'map.card.no-info',
  style: TETHER_CATEGORY_STYLE[TETHER_CATEGORY.NO_DATA],
  title: overrides.title || getI18nPropertyCategoryText(`map.card.properties.${propertyKey}.title`, TETHER_CATEGORY.NO_DATA),
  description: getI18nPropertyCategoryText(`map.card.properties.${propertyKey}.description`, TETHER_CATEGORY.NO_DATA),
});

const buildCategoryObject = (
  propertyKey,
  categoryKey,
  // eslint-disable-next-line default-param-last
  overrides = {
    label: null,
    icon: null,
    title: null,
    description: null,
  },
  typeConfiguration,
) => {
  const style = TETHER_CATEGORY_STYLE[categoryKey];
  const label = overrides.label || getI18nPropertyCategoryText(`map.card.properties.${propertyKey}.label`, categoryKey);
  const icon = overrides.icon === NO_ICON ? null : (overrides.icon || `${propertyKey}-${categoryKey}`);
  const description = overrides.description || getI18nPropertyCategoryText(`map.card.properties.${propertyKey}.description`, categoryKey);
  let title;

  if (typeConfiguration === 'FEEDBACK' && propertyKey === 'ADMISSION_RISK') {
    title = i18n.t('map.card.properties.ADMISSION_RISK.title.FEEDBACK');
  } else if (typeConfiguration === 'FEEDBACK' && propertyKey === 'PAYMENT') {
    title = i18n.t('map.card.properties.PAYMENT.monthly');
  } else {
    title = overrides.title || getI18nPropertyCategoryText(`map.card.properties.${propertyKey}.title`, categoryKey);
  }
  return {
    category: categoryKey,
    icon,
    label,
    style,
    title,
    description,
  };
};

export {
  NO_ICON,
  buildCategoryObject,
  buildNoInfoCategoryObject,
};
