<template>
  <section class="w-full d-flex flex-column bg-white">
    <!-- Title -->
    <TextAtom
      class="text-left"
      :value="$t('feedback.family_application.description')"
      tag="p"
      font="inter"
      color="neutral-800"
      weight="400"
      size="normal"
    />
    <!-- video -->
    <a
      href="https://www.youtube.com/shorts/e_NKBsTBNMs"
      target="_blank"
      rel="noopener noreferrer"
      @click="handleClickVideo()"
    >
      {{ $t('feedback.family_application.show_video') }}
    </a>
    <!-- placard type of application -->
    <div class="type-application mt-5">
      <SvgIconV2 icon="check" class="type-application__icon" />
      <TextAtom
        class="text-left ml-2"
        :value="typeApplication"
        tag="p"
        font="inter"
        color="color-white"
        weight="600"
        size="normal"
      />
    </div>
    <!-- description -->
    <TextAtom
      class="text-left mt-6 mb-1"
      :value="$t('feedback.family_application.description_students')"
      tag="p"
      font="inter"
      color="neutral-800"
      weight="400"
      size="normal"
    />
    <!-- students -->
    <div
      v-for="({
        avatar, grade, name,
      }, index) in students"
      :key="index"
      class="student mt-3"
    >
      <!-- name -->
      <div class="student__name" :class="index === 1 ? 'student_menor' : ''">
        <SvgIconV2 :icon="avatar" class="type-application__icon" />
        <span>{{ name }}</span>
      </div>
      <!-- information -->
      <div class="student__information">
        <TextAtom
          :value="`Postulante ${index + 1}:`"
          tag="span"
          font="inter"
          color="neutral-800"
          weight="500"
          size="default"
        />
        <TextAtom
          :value="name"
          tag="span"
          font="inter"
          color="neutral-800"
          weight="500"
          size="default"
          class="-mt-1"
        />
        <TextAtom
          :value="grade"
          tag="span"
          font="inter"
          color="neutral-800"
          weight="400"
          size="small"
        />
      </div>
    </div>

    <TextAtom
      class="text-left mt-7"
      :value="$t('feedback.family_application.description_simulate')"
      tag="p"
      font="inter"
      color="neutral-800"
      weight="400"
      size="normal"
    />

    <section
      v-for="(probability, index) in getListProbabilityFamilyApplication"
      :key="index + probability.event + openDialog"
      class="family-application__probability mt-5 py-5"
    >
      <!-- dialog -->
      <div v-if="showDialogs[index]" class="family-application__modal">
        <section>
          <button type="button" @click="showDialog(index, false)">
            <TextAtom
              class="text-left mt-7"
              value="x"
              tag="span"
              font="inter"
              color="primary-dark"
              weight="400"
              size="small"
            />
          </button>
          <TextAtom
            class="text-left -mt-2"
            :value="probability.details"
            tag="span"
            font="inter"
            color="primary-dark"
            weight="400"
            size="small"
          />
        </section>
      </div>
      <!-- end dialog -->
      <div class="family-application__probability--header">
        <TextAtom
          :value="probability.event"
          tag="span"
          font="poppins"
          color="primary-dark"
          weight="500"
          size="normal"
          class="-mt-1 family-application__probability--header__event"
        />
        <button v-t="$t('feedback.family_application.show_details')" type="button" @click="showDialog(index, true)" />
      </div>
      <div
        v-for="(student, indexStudent) in probability.students"
        :key="indexStudent + student.name"
        class="mt-3 d-flex flex-row"
      >
        <div class="student__name student__name--probability" :class="indexStudent === 1 ? 'student_menor' : ''">
          <SvgIconV2 :icon="student.avatar" class="type-application__icon" />
          <span>{{ student.name }}</span>
        </div>
        <div class="d-flex flex-column mx-3">
          <TextAtom
            class="text-left"
            :value="student.campus"
            tag="p"
            font="inter"
            color="neutral-800"
            weight="400"
            size="normal"
          />
          <TextAtom
            class="text-left -mt-1"
            :value="student.program"
            tag="p"
            font="inter"
            color="neutral-800"
            weight="400"
            size="small"
          />
        </div>
      </div>
      <hr class="mt-4 mb-3" />
      <TextAtom
        :value="$t('feedback.family_application.probability.title')"
        tag="span"
        font="poppins"
        color="primary-dark"
        weight="500"
        size="normal"
        class="text-left"
      />
      <div class="d-flex flex-column justify-space-between mt-2">
        <div class="d-flex flex-row justify-space-between">
          <div
            class="students-probability"
            :class="familyApplication?.postulacion_familiar ? 'probability-selected' : ''"
          >
            <TextAtom
              :value="$t('feedback.family_application.probability.type.family')"
              tag="span"
              font="inter"
              :color="familyApplication?.postulacion_familiar ? 'primary-dark' : 'primary-medium'"
              weight="400"
              size="normal"
              class="text-left"
            />
            <TextAtom
              :value="String(probability.probability.family)"
              tag="span"
              font="inter"
              :color="familyApplication?.postulacion_familiar ? 'primary-dark' : 'primary-medium'"
              weight="500"
              size="subtitle"
              class="text-left"
            />
          </div>
          <div
            class="students-probability"
            :class="!familyApplication?.postulacion_familiar ? 'probability-selected' : ''"
          >
            <TextAtom
              :value="$t('feedback.family_application.probability.type.independent')"
              tag="span"
              font="inter"
              :color="!familyApplication?.postulacion_familiar ? 'primary-dark' : 'primary-medium'"
              weight="400"
              size="normal"
              class="text-left"
            />
            <TextAtom
              :value="String(probability.probability.independent)"
              tag="span"
              font="inter"
              :color="!familyApplication?.postulacion_familiar ? 'primary-dark' : 'primary-medium'"
              weight="500"
              size="subtitle"
              class="text-left"
            />
          </div>
        </div>
        <div
          class="mt-2 d-flex"
          :class="!familyApplication?.postulacion_familiar ? 'flex-row-reverse' : 'flex-row'"
        >
          <TextAtom
            class="students-probability__disclaimer"
            :value="$t('feedback.family_application.probability.selected')"
            tag="p"
            font="inter"
            color="neutral-800"
            weight="300"
            size="small"
          />
        </div>
      </div>
    </section>
  </section>
</template>
<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import SvgIconV2 from '@/components/atoms/icons/SvgIconV2.vue';
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'FamilyApplication',
  components: {
    TextAtom,
    SvgIconV2,
  },
  props: {
    familyApplication: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialogs: [],
      openDialog: 0,
    };
  },
  computed: {
    // isMobile() {
    //   return this.$vuetify.breakpoint.smAndDown;
    // },
    students() {
      return [
        {
          avatar: 'student-purple',
          grade: this.familyApplication?.grade_label_mayor ?? '',
          name: this.familyApplication?.nombre_hermano_mayor ?? '',
        }, {
          avatar: 'student-aqua',
          grade: this.familyApplication?.grade_label_menor ?? '',
          name: this.familyApplication?.nombre_hermano_menor ?? '',
        },
      ];
    },
    typeApplication() {
      const type = this.familyApplication?.postulacion_familiar ?? false;
      return `${this.$t('feedback.family_application.type_of_application', { type: type ? this.$t('feedback.family_application.type.family') : this.$t('feedback.family_application.type.independent') })}`;
    },
    getListProbabilityFamilyApplication() {
      const probabilities = [
        {
          event: this.familyApplication?.titulo_primer_evento ?? '',
          details: this.familyApplication?.e1_explicacion ?? '',
          students: [
            {
              avatar: 'student-purple',
              name: this.familyApplication?.nombre_hermano_mayor ?? '',
              campus: this.familyApplication?.e1_nombre_campus_mayor ?? '',
              program: this.familyApplication?.e1_program_mayor ?? '',
            }, {
              avatar: 'student-aqua',
              name: this.familyApplication?.nombre_hermano_menor ?? '',
              campus: this.familyApplication?.e1_nombre_campus_menor ?? '',
              program: this.familyApplication?.e1_program_menor ?? '',
            },
          ],
          probability: {
            family: this.familyApplication?.e1_prob_familiar ?? '',
            independent: this.familyApplication?.e1_prob_independiente ?? '',
          },
        },
      ];

      if (this.familyApplication?.tiene_segundo_evento) {
        probabilities.push({
          event: this.familyApplication?.titulo_segundo_evento ?? '',
          details: this.familyApplication?.e2_explicacion ?? '',
          students: [
            {
              avatar: 'student-purple',
              name: this.familyApplication?.nombre_hermano_mayor ?? '',
              campus: this.familyApplication?.e2_nombre_campus_mayor ?? '',
              program: this.familyApplication?.e2_program_mayor ?? '',
            }, {
              avatar: 'student-aqua',
              name: this.familyApplication?.nombre_hermano_menor ?? '',
              campus: this.familyApplication?.e2_nombre_campus_menor ?? '',
              program: this.familyApplication?.e2_program_menor ?? '',
            },
          ],
          probability: {
            family: this.familyApplication?.e2_prob_familiar ?? '',
            independent: this.familyApplication?.e2_prob_independiente ?? '',
          },
        });
      }

      if (this.familyApplication?.tiene_tercer_evento) {
        probabilities.push({
          event: this.familyApplication?.titulo_tercer_evento ?? '',
          details: this.familyApplication?.e3_explicacion ?? '',
          students: [
            {
              avatar: 'student-purple',
              name: this.familyApplication?.nombre_hermano_mayor ?? '',
              campus: this.familyApplication?.e3_nombre_campus_mayor ?? '',
              program: this.familyApplication?.e3_program_mayor ?? '',
            }, {
              avatar: 'student-aqua',
              name: this.familyApplication?.nombre_hermano_menor ?? '',
              campus: this.familyApplication?.e3_nombre_campus_menor ?? '',
              program: this.familyApplication?.e3_program_menor ?? '',
            },
          ],
          probability: {
            family: this.familyApplication?.e3_prob_familiar ?? '',
            independent: this.familyApplication?.e3_prob_independiente ?? '',
          },
        });
      }

      if (this.familyApplication?.tiene_cuarto_evento) {
        probabilities.push({
          event: this.familyApplication?.titulo_cuarto_evento ?? '',
          details: this.familyApplication?.e4_explicacion ?? '',
          students: [
            {
              avatar: 'student-purple',
              name: this.familyApplication?.nombre_hermano_mayor ?? '',
              campus: this.familyApplication?.e4_nombre_campus_mayor ?? '',
              program: this.familyApplication?.e4_program_mayor ?? '',
            }, {
              avatar: 'student-aqua',
              name: this.familyApplication?.nombre_hermano_menor ?? '',
              campus: this.familyApplication?.e4_nombre_campus_menor ?? '',
              program: this.familyApplication?.e4_program_menor ?? '',
            },
          ],
          probability: {
            family: this.familyApplication?.e4_prob_familiar ?? '',
            independent: this.familyApplication?.e4_prob_independiente ?? '',
          },
        });
      }
      return probabilities;
    },
  },
  mounted() {
    this.showDialogs = this.getListProbabilityFamilyApplication.map(() => false);
  },
  methods: {
    handleClickVideo() {
      trackMixPanel('fbin_postfam_videon');
    },
    showDialog(index, status) {
      trackMixPanel(`fbin_postfam_verdetallesa${index + 1}`);
      this.showDialogs[index] = status;
      this.openDialog += 1;
    },
  },
};
</script>
<style scoped>
a {
  color: #5627FF !important;
  font-family: Inter !important;
  font-size: 14px;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.035px;
  text-decoration-line: underline;
  text-align: left;
  margin-top: 1rem;
}

.type-application {
  color: white;
  background: var(--secondary-medium-light);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.7rem;
}

.type-application__icon {
  width: 40px;
}

.student {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: var(--neutral);
  border-radius: 8px;
}

.student__name {
  background: #EEE9FF;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 70px;
}

.student__name > span {
  margin-top: 0.3rem;
  background: white;
  border-radius: 4px;
  width: auto;
  color: #5627FF;
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  padding: 0.2rem 0.3rem;
}

.student__name--probability {
  border-radius: 8px;
  width: 50px !important;
  height: 50px !important;
  min-height: 0 !important;
}

.student__name--probability > span {
  font-size: 10px !important;
  width: 100%;
  padding: 0.2rem 0;
}

.student__name--probability > svg {
  width: 15px;
  height: 15px;
}

.students-probability__disclaimer {
  width: 48%;
  padding: 0 1.5rem;
  text-align: center !important;
  line-height: 14px !important;
  letter-spacing: 0.025px !important;
}
.student__information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  padding: 0.3rem 0.7rem;
}

.student_menor {
  background: #D5FDF9;
}

.student_menor > span {
  color: #20D1BF !important;
}

.family-application__modal {
  position: absolute;
  display: flex;
  justify-content: center;
  background: rgba(99, 99, 99, 0.50);
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: -1rem;
  margin-left: -1rem;
  border-radius: 8px;
  padding: 0;
  box-sizing: border-box;
}

.family-application__modal > section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 1rem;
  width: 80%;
  margin-top: 1rem;
  max-height: 130px;
  border-radius: 16px 7px 16px 16px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(113, 113, 113, 0.15);
}

.family-application__probability {
  position: relative;
  border-radius: 8px;
  background: #F5F5F5;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
}

.family-application__probability--header {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;

}

.family-application__probability--header__event {
  width: calc(100% - 100px);
  text-align: left;
}

.family-application__probability--header > button {
  color: #5627FF;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 9.6px; /* 80% */
  letter-spacing: 0.03px;
  text-decoration-line: underline;
}
.family-application__probability > hr {
  border: 0px;
  border-bottom: 0.5px solid #b7b4b4;
}

.students-probability {
  width: 48%;
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
}

.probability-selected {
  background: #DDD4FF;
}

</style>
