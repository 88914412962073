import { backendAPI } from '@/api';

export default {
  getResearchInformation({ researchId }) {
    /**
     * This function is used to get the research information from the backend.
     */
    return backendAPI.get(`/reports/interventions/${researchId}`);
  },
  generateResearch({ interventionLabelId }) {
    /**
     * This function is used to generate a new research report. Generally used to create an
     * organic report. (the user is not part of the original research sample, but we
     * still want to store their responses)
     */
    return backendAPI.post('/reports/interventions', {
      intervention_label: interventionLabelId,
    });
  },
  patchResearchInformation({ researchId, surveyId }, { context, answers, submitted }) {
    /**
     * This function is used to update the research information in the backend.
     */
    return backendAPI.patch(`/reports/interventions/${researchId}/answers/${surveyId}`, {
      context,
      answers,
      submitted,
    });
  },
};
