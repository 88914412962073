import { apiInstagram, backendAPI, apiMeta } from '@/api';

const appUrl = '/institutions';
const configInstagramApi = 'media?fields=id,caption,thumbnail_url,media_url&access_token=';
const configUserInstagram = '?fields=id,username&access_token=';
const crmVacancies = '/crm/vacancies';
export default {
  retrieveMultimediaInstagram(token) {
    return apiInstagram.get(`me/${configInstagramApi}${token}`);
  },
  retrieveMultimediaInstagramBusiness(token, businessAccId) {
    return apiMeta.get(`${businessAccId}/${configInstagramApi}${token}`);
  },

  retrieveUserInstagram(token) {
    return apiInstagram.get(`me${configUserInstagram}${token}`);
  },
  retrieveUserInstagramBusiness(token, businessAccId) {
    return apiMeta.get(`${businessAccId}/${configUserInstagram}${token}`);
  },
  /**
   * Retrieve the list of programs with the vacancies information by campus
   * @param campusCode
   * @returns {Promise<AxiosResponse<any>>}
   * */
  retrieveVacanciesByCampus({ campusCode }) {
    return backendAPI.get(`${crmVacancies}/campus/${campusCode}`);
  },
  /**
   * Retrieve the list of schools with the vacancies information
   * @param year
   * @param grade
   * @param campuses
   * @returns {Promise<AxiosResponse<any>>}
  */
  retrieveVacancies({ year, grade, campuses }) {
    return backendAPI.post(`${crmVacancies}/by-list`, {
      year,
      grade,
      campus_code: campuses,
    });
  },
  /**
   * Update the visibility of the vacancies for a program in the core.
   * @param programId - The program id to update
   * @param showVacancies - The new visibility of the vacancies
   */
  patchProgramVacanciesVisibility({ programId, showVacancies }) {
    return backendAPI.patch(`${crmVacancies}/${programId}/`, { show_vacancies: showVacancies });
  },
  /**
   * Get visibility of program Vacancies
   */
  getProgramVacanciesVisibility({ campusCode }) {
    return backendAPI.get(`${crmVacancies}/campus/${campusCode}/visibility`);
  },
  /**
   * Retrieve the detail of an school
   * @param uuid
   * @returns {Promise<AxiosResponse<any>>}
   */
  retrieveSchoolDetail({
    searchCode, studentUUID, grade, useCode = false,
  }) {
    const codeEndpoint = useCode ? '/campuses_v2/by_code/' : '/campuses_v2/';
    const params = { applicant_uuid: studentUUID, grade };
    return backendAPI.get(`${appUrl}${codeEndpoint}${searchCode}/`, { params });
  },
  /**
   * Create ExploredCampus for student
   * @param student
   * @param campus
   * @returns {Promise<AxiosResponse<any>>}
   */
  exploreCampus({ currentStudent, campus }) {
    return backendAPI.post(`${appUrl}/explored-map-cards/`, {
      campus_uuid: campus,
      applicant: currentStudent.id,
    });
  },
  exploreSchoolCard({ currentStudent, campus }) {
    return backendAPI.post(`${appUrl}/explored-campuses/`, {
      campus_uuid: campus,
      applicant: currentStudent.id,
    });
  },

  // eslint-disable-next-line
  applicationByStudent({ uuid, year, programs, applicationStatus, admissionSystemLabel, round }) {
    return backendAPI.post(`${appUrl}/application/new_application/`, {
      uuid,
      year,
      programs,
      application_status: applicationStatus,
      admission_system_label: admissionSystemLabel,
      round,
    });
  },

  gradeTracksByCombination(
    {
      gradeId, stageId, modalityId, specialtyId,
    },
  ) {
    return backendAPI.get(`${appUrl}/gradetracks`, {
      params: {
        grade: gradeId,
        stage: stageId,
        modality: modalityId,
        specialty: specialtyId,
      },
    });
  },

  retrieveApplicationsByUuid({ uuid }) {
    return backendAPI.get(`${appUrl}/application/${uuid}/`);
  },
  /**
   * Retrieve explored campuses for the current student
   * @param uuid
   */
  retrieveExploredCampuses({ uuid }) {
    const params = { applicant_uuid: uuid };
    return backendAPI.get(`${appUrl}/explored-campuses/filter_by_student/`, { params });
  },
  retrieveExploredSchoolCards({ uuid }) {
    const params = { applicant_uuid: uuid };
    return backendAPI.get(`${appUrl}/explored-map-cards/filter_by_student/`, { params });
  },
  retrieveLegalGuardianApplications(round) {
    return backendAPI.get(`${appUrl}/application/`, { params: { round } });
  },
  patchCampus({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/campuses/${campusUuid}/`, campusData);
  },

  getGradeRecommendations({ birthDate, campusCode }) {
    const params = {
      birth_date: birthDate,
      ...(campusCode ? { campus_code: campusCode } : {}),
    };
    return backendAPI.get(`${appUrl}/gradeslabel/recommendation/`, { params });
  },
  postExploredCampus({ campusInfo }) {
    return backendAPI.post(`${appUrl}/general-explored-campus/`, campusInfo);
  },

  // New Patch Campus

  // vacancies
  campusVacanciesPatch({ campusUuid, vacancies }) {
    return backendAPI.patch(`${appUrl}/v2/campuses/${campusUuid}/vacancies/`, vacancies);
  },

  // Safety
  campusSafetyPatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/v2/campuses/${campusUuid}/safety/`, campusData);
  },

  // Infrastructure
  campusInfrastructurePatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/v2/campuses/${campusUuid}/infraestructure/`, campusData);
  },

  // General Information
  campusGeneralInformationPatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/v2/campuses/${campusUuid}/general_information/`, campusData);
  },

  // Students
  campusStudentsPatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/v2/campuses/${campusUuid}/students_members/`, campusData);
  },

  // Teachers
  campusStaffPatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/v2/campuses/${campusUuid}/staff_members/`, campusData);
  },

  // Languages
  campusLanguagesPatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/v2/campuses/${campusUuid}/languages/`, campusData);
  },
  // Audiovisual
  campusAudiovisualsPatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/v2/campuses/${campusUuid}/audiovisuals/`, campusData);
  },

  // About establishment
  campusAboutEstablishmentPatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/v2/campuses/${campusUuid}/about_establishment/`, campusData);
  },

  // Payment
  campusPaymentPatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/v2/campuses/${campusUuid}/payment/`, campusData);
  },

  // PaymentV2
  campusPaymentV2Patch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/campuses_v2/${campusUuid}/paymentV2/`, campusData);
  },

  // Images
  campusImagesPatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/v2/campuses/${campusUuid}/images/`, campusData);
  },
  // Educational Project
  campusEducationalProjectPatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/v2/campuses/${campusUuid}/educational_project/`, campusData);
  },
  // PridePoints
  campusPridePointsPatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/v2/campuses/${campusUuid}/pridepoints/`, campusData);
  },
  // Admission System
  campusAdmissionProcessPatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/campuses_v2/${campusUuid}/postulation_process/`, campusData);
  },
  // Extracurricular
  campusExtracurricularPatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/campuses_v2/${campusUuid}/extraactivitys/`, campusData);
  },
  // Admission / FAQS
  campusFaqsPatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/campuses_v2/${campusUuid}/faqs/`, campusData);
  },
  // Achievements
  campusAchievementsPatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/campuses_v2/${campusUuid}/achievements/`, campusData);
  },
  // Location
  campusLocationPatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/v2/campuses/${campusUuid}/location/`, campusData);
  },
  // Contacts
  campusContactsPatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/v2/campuses/${campusUuid}/social_networks/`, campusData);
  },
  // Social Tokens
  campusSocialTokenPatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/campuses_v2/${campusUuid}/tokens/`, campusData);
  },
  // Sports
  campusSportsPatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/campuses_v2/${campusUuid}/sports/`, campusData);
  },
  // Campus Config
  campusConfigPatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/v2/campuses/${campusUuid}/campus_config/`, campusData);
  },
  // Campus Contacts
  campusGlobalContactsPatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/v2/campuses/${campusUuid}/contacts/`, campusData);
  },

  // campus Programs
  campusProgramsPatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/v2/campuses/${campusUuid}/programs/`, campusData);
  },

  // campus text description
  campusSectionTextPatch({ campusData, campusUuid }) {
    return backendAPI.patch(`${appUrl}/campuses_v2/${campusUuid}/section_text/`, campusData);
  },

  getProgramPaymentInfo({ programId, params = {} }) {
    return backendAPI.get(`${appUrl}/v2/payment/by_program/${programId}/`, { params });
  },
};
