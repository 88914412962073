<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        v-if="!inSimulation"
        :content-sections="['actions', 'logo']"
        :inline-logo="true"
        :small-logo="true"
        @back="$emit('backStep')"
      />
      <RowStepper v-if="!inSimulation" :step="3" />
      <ModalContent
        :content-sections="!inSimulation
          ? ['smallTitle', 'subtitle', 'selectionButton'] : ['subtitle', 'selectionButton', 'hint']"
        :small-title-text="'user_register.additional_info.title'"
        :small-title-left="true"
        :subtitle-text="getSubtitleText()"
        :subtitle-arg="getSubtitleArgs()"
        :hint-text="'user_register.campus_selection.old_campus_subtitle_hint'"
        :selection-button-info="selectionButtonInfo"
        @setSelectionButtonValue="setButtonsValue"
      />
      <section v-if="currentSchoolToSet" style="margin-top: 30px">
        <ModalContent
          :content-sections="['subtitle']"
          :subtitle-text="'user_register.campus_selection.which_one'"
        />
        <section :class="inSimulation ? 'd-flex fill-width' : ''" style="margin-top: -16px;">
          <div
            v-for="index in 2"
            :key="index"
            :class="inSimulation ? 'fill-width' : ''"
            :style="index === 1 && inSimulation ? 'margin-right: 12px' : ''"
          >
            <ModalContent
              style="margin-top: -1px;"
              :content-sections="['autoComplete']"
              :auto-complete-placeholder="
                index === 1
                  ? 'user_register.register_location.commune_placeholder'
                  : 'user_register.campus_selection.placeholder'
              "
              :auto-complete-items="index === 1 ? municipalityLabels : schoolNamesList"
              :disabled-autocomplete-value="index === 1 ? false : schoolNamesList.length === 0"
              :autocomplete-no-info-error="noInfoError && !commune"
              :autocomplete-index="index"
              :autocomplete-initial-value="index === 1 ? commune : campusName"
              @setAutoComplete="setAutoComplete"
            />
          </div>
        </section>
      </section>
    </div>
    <section class="d-flex align-center justify-end fill-width">
      <CallToActions
        style="margin-top: 40px; margin-right: 8px"
        :types="['main']"
        :active-value="!inSimulation ? isActive : false"
        :main-button-text="inSimulation
          ? 'user_register.brother_in_campus.cancel' : 'user_register.continue'"
        small
        :main-button-tiny="mobile"
        :spaced="false"
        :main-active-light="inSimulation"
        @continue="inSimulation ? $emit('backStep') : nextStep()"
      />
      <CallToActions
        v-if="inSimulation"
        style="margin-top: 40px; margin-left: 8px"
        :types="['main']"
        :active-value="isActive"
        :main-button-text="'user_register.brother_in_campus.confirm'"
        small
        :main-button-tiny="mobile"
        width-stretch
        :spaced="false"
        @continue="nextStep()"
      />
    </section>
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CampusSelection',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
    RowStepper,
  },
  props: {
    inSimulation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
      currentSchoolToSet: false,
      selectionButtonInfo: [
        { label: 'user_register.priority_question.yes', isActive: false },
        { label: 'user_register.priority_question.no', isActive: false },
      ],
      noInfoError: false,
      commune: null,
      schoolList: null,
      campusName: null,
    };
  },
  computed: {
    ...mapGetters({
      legalGuardian: 'authentication/legalGuardian',
      currentStudent: 'authentication/currentStudent',
      municipalityLabels: 'options/municipalityLabels',
      regions: 'options/regions',
      regionLabels: 'options/regionLabels',
      getSchoolInstitutionList: 'institutions/getSchoolInstitutionList',
      getSchoolCampusList: 'institutions/getSchoolCampusList',
      guestSimulationData: 'simulation/guestSimulationData',
      isGuest: 'authentication/isGuest',
    }),
    schoolNamesList() {
      if (this.schoolList && this.schoolList.length > 0) {
        const schoolNamesList = this.schoolList.map((school) => school.campus_name);
        return schoolNamesList;
      }
      return [];
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    const { former_student: [guestSimulationOldCampus] = [] } = this.guestSimulationData;
    if (this.currentStudent.previus_enrollment_priority || guestSimulationOldCampus) {
      this.setButtonsValue(0);
      const filter = [
        {
          fieldname: this.isGuest ? 'campus_code' : 'institution_code',
          fieldvalue: this.isGuest
            ? guestSimulationOldCampus
            : this.currentStudent.previus_enrollment_priority.institution_code,
        },
      ];
      if (!this.isGuest) {
        this.getSchoolInstitution({ filter }).then(() => {
          [this.currentSchoolInfo] = this.getSchoolInstitutionList;
          this.commune = this.currentSchoolInfo.commune;
          this.setSchoolListArray();
        });
      } else {
        this.getSchoolCampus({ filter }).then(() => {
          [this.currentSchoolInfo] = this.getSchoolCampusList;
          this.commune = this.currentSchoolInfo.commune;
          this.setSchoolListArray();
        });
      }
    }
  },
  methods: {
    ...mapActions({
      setStudents: 'newStudentRegister/setStudents',
      setStudentPreviousCampus: 'newStudentRegister/setStudentPreviousCampus',
      setParentCampus: 'newStudentRegister/setParentCampus',
      getSchoolsFromCommune: 'institutions/getSchoolsFromCommune',
      getSchoolInstitution: 'institutions/getSchoolInstitution',
      getSchoolCampus: 'institutions/getSchoolCampus',
      setGuestSimulationData: 'simulation/setGuestSimulationData',
    }),
    getSubtitleText() {
      return this.isGuest
        ? 'user_register.campus_selection.old_campus_subtitle_guest'
        : 'user_register.campus_selection.old_campus_subtitle';
    },
    getSubtitleArgs() {
      return Object.keys(this.currentStudent).length > 0 ? this.currentStudent.first_name : '';
    },
    setSchoolListArray() {
      if (this.commune) {
        const filter = [
          {
            fieldname: 'commune',
            fieldvalue: this.commune,
          },
        ];
        this.getSchoolsFromCommune({ filter }).then((response) => {
          this.schoolList = response.results;
          if (this.currentSchoolInfo && this.currentSchoolInfo.name) {
            this.campusName = this.currentSchoolInfo.name;
          }
        });
        return this.schoolList;
      }
      return null;
    },
    setAutoComplete(value, index) {
      if (value && index === 1) {
        this.commune = value;
        this.setSchoolListArray();
      }
      if (value && index === 2) {
        this.campusName = value;
      }
      return this.commune;
    },
    nextStep() {
      if (this.selectionButtonInfo[1].isActive) {
        const deletePayload = {
          uuid: this.currentStudent.uuid,
          campus_code: null,
          institution_code: null,
        };
        if (this.isGuest) {
          this.setGuestSimulationData({ key: 'former_student', value: [] });
        } else {
          this.setStudentPreviousCampus({ institutionPayload: deletePayload });
        }
        return this.$emit('nextStep');
      }
      const selectedSchool = this.schoolList.find((school) => school.campus_name === this.campusName);
      const personalInfo = {
        uuid: this.currentStudent.uuid,
        institution_code: selectedSchool.institution_code,
        campus_code: selectedSchool.campus_code,
      };
      if (this.isGuest) {
        this.setGuestSimulationData({ key: 'former_student', value: [selectedSchool.campus_code] });
      } else {
        this.setStudentPreviousCampus({ institutionPayload: personalInfo });
      }
      return this.$emit('nextStep');
    },
    setButtonsValue(i) {
      if (i === 0) {
        this.currentSchoolToSet = true;
        this.selectionButtonInfo[0].isActive = true;
        this.selectionButtonInfo[1].isActive = false;
      } else {
        this.currentSchoolToSet = false;
        this.selectionButtonInfo[0].isActive = false;
        this.selectionButtonInfo[1].isActive = true;
      }
      this.isActive = true;
      return i;
    },
  },
};
</script>
