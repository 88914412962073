<template>
  <section class="favorite-button-container">
    <v-btn
      v-if="!isLargeButton"
      :loading="loading"
      :icon="buttonInCampusDetail ? false : true"
      :class="customClass"
      @click="addFavorite()"
    >
      <template v-if="buttonInCampusDetail">
        <img :src="styleButton.icon" width="30px" :alt="styleButton.alt" />
        <span class="ml-1"> {{ styleButton.text }} </span>
      </template>

      <template v-else>
        <img :src="styleButton.icon" width="40px" height="40px" :alt="styleButton.alt" />
      </template>
    </v-btn>
    <template v-else>
      <v-btn
        :loading="loading"
        class="large-button-fav"
        :class="{
          'large-button-fav--selected': isFavorite,
          'large-reverse': buttonInCampusDetail,
        }"
        type="button"
        @click="addFavorite()"
      >
        <template v-if="!loading">
          <span v-t="isFavorite ? 'campus-details.favorite.added' : 'campus-details.favorite.add'" />
          <v-icon
            class="ml-2"
            :color="isFavorite ? 'white' : '#5627FF'"
          >
            {{ isFavorite ? 'mdi-playlist-check' : 'mdi-playlist-plus' }}
          </v-icon>
        </template>
      </v-btn>
    </template>
    <v-dialog
      v-model="showSystemModals"
      persistent
      :content-class="isMobile ? 'system-dialogs-mobile' : 'system-dialogs'"
      max-width="400"
    >
      <SystemModals
        :modal-index="1"
        @closeDialog="closeDialog()"
        @closeAllDialogs="closeAllDialogs()"
      />
    </v-dialog>
  </section>
</template>

<script>
import SystemModals from '@/components/templates/SystemModals.vue';
import trackMixPanel from '@/utils/mixpanel';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FavoriteButton',
  components: {
    SystemModals,
  },
  props: {
    campus: {
      type: Object,
      required: true,
    },
    isLargeButton: {
      type: Boolean,
      default: false,
    },
    buttonInCampusDetail: {
      type: Boolean,
      default: false,
    },
  },
  metaInfo() {
    return {};
  },
  data() {
    return {
      showSystemModals: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      isGuest: 'authentication/isGuest',
      favoriteSchools: 'favorites/listFavoriteSchools',
      currentStudent: 'authentication/currentStudent',
      favoriteGuestList: 'favorites/favoriteGuestList',
      elasticSchoolDetail: 'institutions/elasticSchoolDetail',
    }),
    isFavorite() {
      const favoritesList = this.isGuest ? this.favoriteGuestList : this.favoriteSchools;
      const isFavorite = favoritesList.find((favCampus) => (
        favCampus.uuid === this.campus.uuid || favCampus.campus_code === this.campus.campus_code
      ));
      // TODO: Deprecate the campus.isFavorite option, as we should always compute it
      return !!isFavorite || this.campus.isFavorite;
    },
    customClass() {
      let newStyle = 'favorite-button-campus-detail';

      if (this.isFavorite) {
        newStyle = ' favorite-button-campus-detail--selected';
      }

      return this.buttonInCampusDetail ? newStyle : '';
    },
    styleButton() {
      let style = {
        // eslint-disable-next-line global-require
        icon: require('@/assets/iconsChile/campusDetail/button2.svg'),
        text: this.$t('campus-details.favorite.add'),
        alt: `Añadir ${this.campus?.campus_name ?? this.campus.name} a su lista`,
      };
      if (this.isFavorite && !this.buttonInCampusDetail) {
        style = {
          // eslint-disable-next-line global-require
          icon: require('@/assets/icons/button1.svg'),
          text: this.$t('campus-details.favorite.add'),
          alt: `${this.campus?.campus_name ?? this.campus.name} ya se encuentra en su lista`,
        };
      }
      if (this.isFavorite && this.buttonInCampusDetail) {
        style = {
          // eslint-disable-next-line global-require
          icon: require('@/assets/iconsChile/campusDetail/list_selected.svg'),
          text: this.$t('campus-details.favorite.added'),
          alt: `${this.campus?.campus_name ?? this.campus.name} ya se encuentra en su lista`,
        };
      }
      if (!this.isFavorite && this.buttonInCampusDetail) {
        style = {
          // eslint-disable-next-line global-require
          icon: require('@/assets/iconsChile/campusDetail/list_add.svg'),
          text: this.$t('campus-details.favorite.add'),
          alt: `Añadir ${this.campus?.campus_name ?? this.campus.name} a su lista`,
        };
      }

      return style;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapActions({
      setNewFavorite: 'favorites/newFavorite',
      removeFavorite: 'favorites/removeFavorite',
      updateCampusDetailFavorite: 'institutions/updateCampusDetailFavorite',
      setGuestFavorite: 'favorites/setGuestFavorite',
      deleteGuestFavorite: 'favorites/deleteGuestFavorite',
      getSchoolElasticDetails: 'institutions/getSchoolElasticDetails',
    }),
    addFavorite() {
      let mainCampus = this.campus;
      if (this.isGuest) {
        trackMixPanel('click_guest_favorites_button', {
          school_id: mainCampus.uuid,
          campus_code: mainCampus.campus_code,
          institution_code: mainCampus.institution_code,
          name: mainCampus.campus_name,
        });
        if (this.$route.name === 'Favorites') {
          this.loading = true;
          mainCampus = this.favoriteGuestList.find((favCampus) => favCampus.uuid === mainCampus.uuid);
          if (mainCampus === undefined) {
            const campusUuid = this.campus.uuid;
            return this.getSchoolElasticDetails({ campusUuid })
              .then(() => {
                this.checkGuestStatusFavorite(this.elasticSchoolDetail);
                this.loading = false;
              });
          }
        }
        return this.checkGuestStatusFavorite(mainCampus);
      }
      return this.checkStatusFavorite(mainCampus);
    },
    checkGuestStatusFavorite(campus) {
      if (this.favoriteGuestList.some((campuses) => campuses.campus_code === campus.campus_code)) {
        this.deleteGuestFavorite({ favoriteCampusUuid: campus.uuid });
        this.loading = false;
      } else {
        this.setGuestFavorite({ favoriteCampus: campus });
        this.$emit('removeFromSuggestions', campus.uuid);
      }
    },
    async checkStatusFavorite() {
      const campusCode = this.campus.campus_code;
      this.loading = true;
      if (this.isFavorite) {
        const idFavorite = this.campus?.idFavorite
        ?? this.favoriteSchools.find(({ uuid }) => uuid === this.campus.uuid)?.idFavorite;
        await this.removeFavorite(idFavorite);
        // eslint-disable-next-line no-unused-expressions
        this.updateCampusDetailFavorite(false);
        this.sendTagMixPanel(this.isFavorite);
      } else {
        // eslint-disable-next-line camelcase
        const newFavorite = {
          campusCode,
        };
        await this.setNewFavorite(newFavorite);
        // eslint-disable-next-line no-unused-expressions
        this.updateCampusDetailFavorite(true);
        this.sendTagMixPanel(this.isFavorite);
      }
      this.loading = false;
    },
    sendTagMixPanel(isFavorite) {
      trackMixPanel(isFavorite ? 'remove_favorite_school_from_list' : 'favorite_school_from_list', {
        school_id: this.campus.uuid,
        campus_code: this.campus.campus_code,
        institution_code: this.campus.institution_code,
      });
    },
    closeDialog() {
      this.showSystemModals = false;
    },
    closeAllDialogs() {
      this.$emit('close-campus-detail');
      this.showSystemModals = false;
    },
  },
};
</script>
