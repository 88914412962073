<template>
  <section class="login">
    <v-dialog
      v-model="showUserRegister"
      persistent
      :max-width="'480'"
      transition="slide-x-transition"
      hide-overlay
      :content-class="isMobile ? 'user-dialog-mobile' : 'user-dialog'"
    >
      <UserRegister
        v-if="registerFlow"
        :step="userRegisterStep"
        @closeModal="closeRegisterModal"
        @resetPassword="showResetPassword()"
        @inLoginFlow="inLoginFlow = true"
      />
      <ApplicationDgeRegister
        v-if="applicationFlow"
        @closeModal="closeRegisterModal"
      />
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UserRegister from '@/components/templates/Register.vue';
import ApplicationDgeRegister from '@/components/templates/ApplicationDgeRegister.vue';

export default {
  name: 'LoginLanding',
  components: {
    UserRegister,
    ApplicationDgeRegister,
  },
  metaInfo() {
    return {
      title: `${this.$tc('dashboard.breadcrumbs.app_name')} | ${this.$tc('dashboard.breadcrumbs.login')}`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content:
            'Crea una cuenta en nuestro explorador y accede a '
            + 'información personalizada de colegios y procesos de admisión.',
        },
      ],
    };
  },
  data() {
    return {
      isResetPassword: false,
      showUserRegister: false,
      registerFlow: false,
      applicationFlow: false,
      userRegisterStep: 1,
      inLoginFlow: false,
    };
  },
  computed: {
    ...mapGetters({
      legalGuardianUUID: 'authentication/legalGuardianUUID',
      loginLoading: 'authentication/loginLoading',
      loginModal: 'authentication/loginModal',
    }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    loginLoading: {
      handler(newVal) {
        if (newVal === false) {
          if (this.legalGuardianUUID && this.inLoginFlow) {
            this.$router.push('/map');
          }
        }
      },
    },
  },
  mounted() {
    this.showUserRegister = true;
    if (this.loginModal !== 'applicationFlow') {
      this.registerFlow = true;
    } else {
      this.applicationFlow = true;
    }
    this.$recaptchaInstance.showBadge();
    this.setLoginLoading({ bool: false });
  },
  beforeDestroy() {
    this.$recaptchaInstance.hideBadge();
  },
  methods: {
    ...mapActions({
      setLoading: 'authentication/setLoading',
      setLoginLoading: 'authentication/setLoginLoading',
    }),
    showResetPassword() {
      this.isResetPassword = true;
    },
    closeRegisterModal() {
      this.showUserRegister = false;
    },
  },
};
</script>
<style>
.welcome-dialog {
  width: auto !important;
  box-shadow: none;
}
.grecaptcha-badge {
  z-index: 100 !important;
}
</style>
<style scoped>
.captcha {
  justify-content: center;
}
</style>
