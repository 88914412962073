<template>
  <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.906982 0.584488L6.17644 5.85398" stroke="#D60028" />
    <path d="M6.17651 0.584412L0.907059 5.8539" stroke="#D60028" />
  </svg>
</template>

<script>
export default {
  name: 'Cross',
};
</script>
