import services from '@/services';
import { studentCanSimulateRisk } from '@/utils/students';
import { TENANT_CONFIGURATION } from '@/constants/tenant';

const getDefaultState = () => ({
  simulationResults: [],
  loading: true,
  simulateList: [],
  fromPath: null,
  editSimulationModal: false,
  guestSimulationData: {},
});

const state = getDefaultState();

const getters = {
  loading: ({ loading }) => loading,
  simulationResults: ({ simulationResults }) => simulationResults,
  simulateList: ({ simulateList }) => simulateList,
  simulateFromPath: ({ fromPath }) => fromPath,
  editSimulationModal: ({ editSimulationModal }) => editSimulationModal,
  guestSimulationData: ({ guestSimulationData }) => guestSimulationData,
};

const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState());
  },
  setSimulationResults(state, data) {
    state.simulationResults = data;
  },
  setGuestSimulationData(state, { key, value }) {
    state.guestSimulationData = {
      ...state.guestSimulationData,
      [key]: value,
    };
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setSimulateList(state, simulationList) {
    state.simulateList = simulationList;
  },
  setFromPathInfo(state, path) {
    state.fromPath = path;
  },
  cleanSimulationData(state) {
    state.simulationResults = [];
  },
  setSimulationModalCompleted(state, bool) {
    state.editSimulationModal = bool;
  },
  cleanGuestSimulationData(state) {
    state.guestSimulationData = {};
    state.editSimulationModal = false;
  },
};

const actions = {
  setGuestSimulationData({ commit }, { key, value }) {
    commit('setGuestSimulationData', { key, value });
  },
  async simulateRisk({ rootGetters, getters, commit }) {
    commit('setLoading', true);
    const [startYear, endYear] = TENANT_CONFIGURATION.SETTINGS.SIMULATION.YEAR_RANGE;
    const yearsToSimulate = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);
    const isGuest = rootGetters['authentication/isGuest'];
    const student = rootGetters['authentication/currentStudent'] ?? {};
    const studentCanSimulate = studentCanSimulateRisk(student);
    const useOpenRisk = isGuest || !studentCanSimulate;

    const { guestSimulationData } = getters;

    const campuses = rootGetters['simulation/simulateList'];
    const { grades: [grade] } = rootGetters['authentication/activeGrades'];

    // guestSimulationData is an object that is dynamically updated with the guest simulation data in the simulation
    // form. It is used to get the data to simulate the risk for the guest user, and it's structure will end up
    // depending on the tenant and it's simulation flow

    const rootPayload = {
      campuses: campuses.map(({ campus_code: code }) => code),
      interest_grade: grade,
      ...(useOpenRisk ? guestSimulationData : { applicant_id: student.id }),
    };

    const parseYearlySimulationResult = ({ status, value: yearlyResult }) => {
      if (status === 'rejected') {
        return null;
      }
      // FIXME: For some reason, the risk response is different for different tenants (it's an array or an object)
      const result = Array.isArray(yearlyResult.data) ? yearlyResult.data[0] : yearlyResult.data;

      if (result.error || !result.api_response?.portfolio?.length) {
        return null;
      }
      result.api_response.portfolio.forEach((school) => {
        // TODO: En el back o api podemos perfectamente agregar la imagen de la escuela
        // eslint-disable-next-line no-param-reassign
        school.image = campuses.find(
          ({ campus_code: code }) => code === school.campus.campus_code,
        )?.image;
        return school;
      });
      return result;
    };

    const riskMethod = useOpenRisk ? services.simulationService.getOpenRisk : services.simulationService.reportRisk;

    Promise.allSettled(
      yearsToSimulate.map(
        (year) => riskMethod(
          {
            ...rootPayload,
            year,
          },
          false,
        ),
      ),
    ).then((values) => {
      const simulationResults = values.map(parseYearlySimulationResult)
        .filter(Boolean)
        .sort(({ api_response: { year: a } }, { api_response: { year: b } }) => b - a);
      commit('setSimulationResults', simulationResults);
      commit('setLoading', false);
    }).catch(() => {
      commit('setLoading', false);
    });
  },
  /**
   * Gets the latest risk for the given risk payload. If the user is guest, or there's no applicant ID in the payload,
   * it will use the open risk endpoint. Otherwise, it will use the applicant risk endpoint.
   * @param {*} param0
   * @param {*} param1
   * @param {*} param1.riskPayload - The risk payload to use to get the risk
   * @param {*} param1.riskPayload.campuses - The campuses to get the risk for
   * @param {*} param1.riskPayload.grade - The interest grade to get the risk for
   * @param {*=} param1.riskPayload.applicant - The applicant to get the risk for.
   *  If not provided, it will use the current student
   * @returns {Promise<{year: number, risk: number}>} - The year and risk for the latest risk
   */
  async getLatestRisk({ rootGetters }, {
    campuses, grade, applicant, abortPrevious,
  }) {
    const isGuest = rootGetters['authentication/isGuest'];
    const student = applicant ?? rootGetters['authentication/currentStudent'] ?? {};
    const studentCanSimulate = studentCanSimulateRisk(student);
    const useOpenRisk = isGuest || !studentCanSimulate;
    const getRisk = useOpenRisk ? services.simulationService.getOpenRisk : services.simulationService.reportRisk;

    const [startYear, endYear] = TENANT_CONFIGURATION.SETTINGS.SIMULATION.YEAR_RANGE;
    const yearsToTry = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);
    let risk = null;
    let year = null;
    while (!risk && yearsToTry.length > 0) {
      year = yearsToTry.pop();
      // eslint-disable-next-line no-await-in-loop
      const { data = { api_response: { portfolio: [] } } } = await getRisk(
        {
          campuses,
          interest_grade: grade,
          applicant_id: student.id,
          year,
        },
        abortPrevious,
      )
        .then((response) => response)
        .catch(() => ({}));

      // FIXME: For some reason, the risk response is different for different tenants (it's an array or an object)
      const dataObject = Array.isArray(data) ? data[0] : data;
      const { api_response: { portfolio: [portfolio] } } = dataObject;
      if (portfolio) {
        risk = portfolio.ratex;
      }
    }
    return { risk, year };
  },
  /**
   * Get the risk of a guest user for a specific campus in the map card
   * Based on an elastic response for the campus in the card, it obtains the minimum common grade between the
   * campus and the guest user and then calls the getOpenRisk function to get the risk for that grade
   * @param {*} campus - campus object
   */
  async simulateForCampusCard({ rootGetters, dispatch }, { campus, abortPrevious }) {
    const { grades } = rootGetters['authentication/activeGrades'];
    // By default, the grade used for the simulation is the minimum grade of the campus
    let simulationGrade = campus.grades_info?.grade_min_label?.id ?? null;
    if (grades.length > 0) {
      // There's a weak assumption here that the minimum active grade is the first one
      const [minGradeStudent] = grades;
      // The following reduce is used to find the minimum grade between the student and the campus
      // If the student grade is not available in the campus, the minimum grade from the campus is used
      const { grade } = campus.programs.reduce(
        (prev, current) => {
          const currentIsStudentGrade = current.grade.id === minGradeStudent;
          const prevIsStudentGrade = prev.grade.id === minGradeStudent;
          const currentHasLowerGrade = current.grade.min_age_to_apply < prev.grade.min_age_to_apply;
          if (currentIsStudentGrade) {
            return current;
          }
          if (prevIsStudentGrade) {
            return prev;
          }
          return currentHasLowerGrade ? current : prev;
        },
        { grade: { min_age_to_apply: Infinity } },
      );
      simulationGrade = grade.id;
    }
    return dispatch('getLatestRisk', {
      campuses: [campus.campus_code],
      grade: simulationGrade,
      abortPrevious,
    });
  },
  setSimulateList({ commit }, simulationList) {
    commit('setSimulateList', simulationList);
  },
  // Info needed when you go back of simulate view
  setFromPathInfo({ commit }, path) {
    commit('setFromPathInfo', path);
  },
  cleanSimulationData({ commit }) {
    commit('cleanSimulationData');
  },
  /**
   * Flag to know if the simulation modal was completed by the user
   * @param {Boolean} bool - guest simulation info object
   */
  async setSimulationModalCompleted({ commit }, bool) {
    await commit('setSimulationModalCompleted', bool);
  },
  /**
   * Clean Store of guest simulation data
   */
  cleanGuestSimulation({ commit }) {
    commit('cleanGuestSimulation');
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
