<template>
  <v-app id="app">
    <router-view />
    <ApplicationModals />
    <Chat v-if="isChatbotEnabled" />
  </v-app>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { backendAPI } from '@/api';
import ApplicationModals from '@/components/templates/ApplicationModals.vue';
import CONFIG from '@/config';
import utils from '@/utils/';
import { setFbTag } from '@/utils/tags';
import Chat from './components/organisms/chat/Chat.vue';
import { SHOW_CHATBOT } from './constants/tenant';
import { isEmbedded } from './utils/embedded';

export default {
  name: 'App',
  components: {
    ApplicationModals,
    Chat,
  },
  metaInfo() {
    return {
      // if no sub-components specify a metaInfo.title, this title will be used
      htmlAttrs: {
        lang: this.$i18n.locale,
        amp: false,
      },
      title: `${this.$t(`seo.${CONFIG.tenant}.site_title`)} | ${this.$t(
        `seo.${CONFIG.tenant}.welcome`,
      )}`,
      meta: [
        ...utils.generateMetaArray([
          {
            name: 'description',
            content: this.$t(`seo.${CONFIG.tenant}.description`),
            vmid: 'description',
          },
          { name: 'mobile-web-app-capable', content: 'yes' },
          { name: 'apple-mobile-web-app-capable', content: 'yes' },
          {
            name: 'apple-mobile-web-app-title',
            content: this.$t(`seo.${CONFIG.tenant}.site_title`),
            vmid: 'apple-mobile-web-app-title',
          },
          { name: 'theme-color', content: '#FFFFFF' },
          // OG Tags
          {
            property: 'og:title',
            content: `${this.$t(`seo.${CONFIG.tenant}.site_title`)}`,
            vmid: 'og:title',
          },
          {
            property: 'og:description',
            content: this.$t(`seo.${CONFIG.tenant}.description`),
            vmid: 'og:description',
          },
          { property: 'og:url', content: utils.getCanonicalLink(this.$route.path), vmid: 'og:url' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: `${this.$t(`seo.${CONFIG.tenant}.site_title`)}` },
          {
            property: 'og:image',
            content: `${utils.getBaseLink(
              process.env.VUE_APP_ENVIRONMENT,
            )}/img/explorer.f54b296e.svg`,
            vmid: 'og:image',
          },
        ]),
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'WebSite',
            name: `${this.$t(`seo.${CONFIG.tenant}.site_title`)}`,
            description: this.$t(`seo.${CONFIG.tenant}.description`),
            url: utils.getBaseLink(process.env.VUE_APP_ENVIRONMENT),
            image: `${utils.getBaseLink(
              process.env.VUE_APP_ENVIRONMENT,
            )}/img/explorer.f54b296e.svg`,
            mainEntityOfPage: this.$t(`seo.${CONFIG.tenant}.main_website`),
            sameAs: [
              'https://www.linkedin.com/company/tether-education/',
              'https://www.instagram.com/tether.education/?hl=en',
              'https://www.facebook.com/tethereducation',
              'https://mime.mineduc.cl/',
            ],
            potentialAction: {
              '@type': 'SearchAction',
              target: `${utils.getBaseLink(
                process.env.VUE_APP_ENVIRONMENT,
              )}/map/school/{search_term_string}`,
              'query-input': 'required name=search_term_string',
            },
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      language: 'authentication/language',
      locale: 'locale/locale',
      resetStore: 'authentication/resetStore',
    }),
    isChatbotEnabled() {
      let hasQueryParams = false;
      if (
        this.$route.query.process_id
        && this.$route.query.grade
        && this.$route.query.locationName
      ) {
        hasQueryParams = true;
      }

      const hideChatRouteMeta = this.$route.meta?.hideChat;

      const embedded = isEmbedded();

      return (SHOW_CHATBOT && !hideChatRouteMeta && !hasQueryParams && !embedded);
    },
  },
  created() {
    setFbTag();
    this.$i18n.locale = this.locale;
    let differenceDays;
    if (this.resetStore.timestamp !== undefined) {
      const dateChangeUrl = moment(this.resetStore.timestamp);
      const today = moment().format('YYYY-MM-DD');
      differenceDays = dateChangeUrl.diff(today, 'days');
    } else {
      const resetInfo = {
        timestamp: moment().format('YYYY-MM-DD'),
      };
      this.setResetStoreInfo({ data: resetInfo });
    }
    if (differenceDays < 0) {
      const resetInfo = {
        timestamp: moment().format('YYYY-MM-DD'),
      };
      this.resetStores().then(() => {
        this.setResetStoreInfo({ data: resetInfo });
      });
      this.retrieveOptions({ reset: false });
      this.setCommuneList();
    } else {
      this.retrieveOptions({ reset: false });
      // TODO: This was meant ot be used to update the vacancies counters,
      // but:
      // 1. It's not working
      // 2. It's not being used anywhere (for now)
      // So we're commenting it out for now and just keep the case where
      // the store has been reset above. If it actually needs to be updated, it should be done
      // when and where it's needed, because here it always loads
      // this.setCommuneList();
    }
  },
  mounted() {
    // TODO: Why is this here?
    backendAPI.setStore(this.$store);
    if (this.language === null) {
      this.setLanguage('es');
    }
    this.onResize();
    const now = moment();
    const lastUpdate = moment(this.guestLocationLastUpdated);
    const timeDifferenceInDays = now.diff(lastUpdate, 'days');
    if (timeDifferenceInDays >= 1) {
      this.cleanGuestData();
    }
    this.$intercom.boot();
  },
  methods: {
    ...mapActions({
      setLanguage: 'authentication/setLanguage',
      resetStores: 'authentication/resetStores',
      setResetStoreInfo: 'authentication/setResetStore',
      retrieveOptions: 'options/retrieveOptions',
      // setCommuneList: 'institutions/setCommuneList',
      cleanGuestData: 'authentication/cleanGuestData',
    }),
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
  },
};
</script>
