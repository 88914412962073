var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-main register-main--spaced"},[_c('div',{staticClass:"fill-width"},[_c('ModalHeader',{attrs:{"content-sections":['actions', 'logo'],"actions-tag":_vm.registerType === 1
        ? 'click_reg_quick-register-student-kinship_back-button'
        : 'click_reg_detailed-register-student-kinship_back-button',"inline-logo":"","small-logo":""},on:{"back":function($event){return _vm.$emit('backStep')}}}),_c('RowStepper',{attrs:{"mixpanel-tag":_vm.registerType === 1
        ? 'click_reg_quick-register-student-kinship_row-stepper'
        : 'click_reg_detailed-register-student-kinship_row-stepper',"step":2}}),_c('ModalContent',{class:_vm.inWaitingList && !_vm.mobile ? 'deg-waiting-list--mobile' : '',attrs:{"content-sections":['smallTitle', 'subtitle', 'textSelect'],"small-title-text":'user_register.identification.student_title',"small-title-left":"","subtitle-text":'user_register.relationship.subtitle',"subtitle-arg":Object.keys(_vm.currentStudent).length > 0 ? _vm.currentStudent.first_name : '',"text-select-placeholders":'user_register.relationship.placeholder',"text-select-items":_vm.relationshipOptions,"text-select-initial-value":_vm.relation,"text-select-item-text":"relationship_name","text-select-return-object":"","text-select-index":1,"text-select-tag":_vm.registerType === 1
        ? 'click_reg_quick-register-student-kinship_kinship-dropdown'
        : 'click_reg_detailed-register-student-kinship_kinship-dropdown',"click-text-select-tag":_vm.registerType === 1
        ? 'click_reg_quick-register-student-kinship_kinship-dropdown-selector'
        : 'click_reg_detailed-register-student-kinship_kinship-dropdown-selector'},on:{"setTextSelectValue":_vm.setValue}})],1),_c('CallToActions',{attrs:{"types":['main'],"active-value":"","main-button-text":'user_register.continue',"main-button-tag":_vm.registerType === 1
      ? 'click_reg_quick-register-student-kinship_continue-button'
      : 'click_reg_detailed-register-student-kinship_continue-button',"small":"","spaced":false},on:{"continue":function($event){return _vm.nextStep()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }