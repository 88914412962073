/* eslint-disable max-len */
import { CAMPUS_PROPERTIES } from '@/constants/category';
import { getCampusDistanceCategory } from './distance';
import { getCampusPaymentCategory } from './payment';
import { getCampusPerformanceCategory } from './performance';
import { getCampusGenericCategory } from './genericCategory';
import { getCampusAdmissionProbabilityCategory } from './admissionRisk';
import { getCampusTravelTimeCategory } from './travelTime';
import { buildNoInfoCategoryObject } from './shared';
/**
 * Get the category of a campus property based on the campus data, tenant and property key. It's possible
 * to pass property-dependant data as a second parameter, which will be used to calculate the category
 * @param {*} campus - The campus data, as returned by the elastic search API
 * @param {*} propertyKey - The key of the property to get the category for
 * @param {*=} propertyData - The property data, depends on the property key
 * @param {*=} store - The vuex store instance (`import store from '@/store'`)
 * @returns {PropertyCategory} - The category of the property
 */
const getCampusPropertyCategory = ({
  campus, propertyKey, propertyData, typeConfiguration, defaultData = {}, store,
}) => {
  const methodMapping = {
    [CAMPUS_PROPERTIES.PAYMENT]: () => getCampusPaymentCategory(campus, store, typeConfiguration, defaultData),
    [CAMPUS_PROPERTIES.PERFORMANCE]: () => getCampusPerformanceCategory(campus, typeConfiguration, defaultData),
    [CAMPUS_PROPERTIES.ACHIEVEMENT_GROWTH]: () => getCampusGenericCategory(campus, typeConfiguration, defaultData, CAMPUS_PROPERTIES.ACHIEVEMENT_GROWTH, 2),
    [CAMPUS_PROPERTIES.ACHIEVEMENT_INCOME]: () => getCampusGenericCategory(campus, typeConfiguration, defaultData, CAMPUS_PROPERTIES.ACHIEVEMENT_INCOME, 3),
    [CAMPUS_PROPERTIES.SOCIO_EMOTIONAL]: () => getCampusGenericCategory(campus, typeConfiguration, defaultData, CAMPUS_PROPERTIES.SOCIO_EMOTIONAL, 4),
    [CAMPUS_PROPERTIES.DISTANCE]: () => getCampusDistanceCategory(campus, store, typeConfiguration, defaultData),
    [CAMPUS_PROPERTIES.ADMISSION_RISK]:
      () => getCampusAdmissionProbabilityCategory(campus, propertyData, typeConfiguration, defaultData),
    [CAMPUS_PROPERTIES.WALK_TIME]: () => getCampusTravelTimeCategory(propertyKey, propertyData),
    [CAMPUS_PROPERTIES.CAR_TIME]: () => getCampusTravelTimeCategory(propertyKey, propertyData),
    [CAMPUS_PROPERTIES.BUS_TIME]: () => getCampusTravelTimeCategory(propertyKey, propertyData),
  };
  const method = methodMapping[propertyKey]
    || (() => buildNoInfoCategoryObject(propertyKey));
  return method();
};

export {
  getCampusPropertyCategory,
};
