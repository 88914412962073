<template>
  <section>
    <div class="fill-width performance">
      <TextAtom
        :value="getDescription()"
        class="mt-4 text-left d-flex pl-4 pb-3"
        tag="label"
        font="inter"
        color="primary-dark"
        weight="400"
        size="subtitle"
      />
      <!-- Id 6 equal no information -->
      <!-- v-if="getIdPerformanceInstitution !== 6 && getIdPerformanceInstitution !== undefined" -->
      <div
        class="panel_container_row mt-5 panel_container_row__items"
      >
        <div
          v-for="(performance, index) in performanceInstitution"
          :key="index"
          :class="[
            { performance__opacity: !isCampusPerformance(performance) },
            `panel_container_row__${performance.category}`,
          ]"
          class="panel_container_row__item"
        >
          <Flag
            :background="performance.style.backgroundColor"
            :color="performance.style.color"
            :label="performance.label"
            :icon="performance.icon"
            :styling="'icon-round'"
          />
          <SvgIconV2 :icon="'vertical-arrow'" />
          <Flag
            :background="performance.style.backgroundColor"
            :color="performance.style.color"
            :label="performance.label"
            :styling="'tiny-label'"
          />
        </div>
      </div>

      <!-- Description performance -->
      <div v-if="hasQualityCategory" class="description-performance mt-10">
        <p v-t="'campus-details.panels.categories'" class="text-left mb-2" />

        <p v-t="'campus-details.panels.evaluation'" class="text-left mb-2 font-weight-light mt-1" />
      </div>

      <!-- Stats "Saber" -->
      <v-expansion-panels accordion flat>
        <v-expansion-panel
          v-for="({ title, number, data }, index) in statsInstitution"
          :key="index"
          class="mt-2"
        >
          <v-expansion-panel-header
            class="campus-detail__panels__title font-weight-medium caption"
            @click="trackTestClick(number)"
          >
            <template #actions>
              <v-icon size="22" color="#2B5BFF">
                $expand
              </v-icon>
            </template>
            {{ title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content
            class="text-left px-6 pb-3 campus-detail__panels__title font-weight-light caption"
          >
            <PerformanceChartDonut v-if="number < 11" :number="number" :stats="data" />
            <PerformanceGlobal v-else-if="number === 11" :stats="data" />

            <template v-else>
              <template v-if="data.length > 0 && data[0].description">
                <p class="text-left font-weight-light campus-detail__panels__title">
                  {{ data[0].description }}
                </p>
                <hr class="divisor-white my-5" />
              </template>

              <template v-if="data.length > 1">
                <div v-for="(item, i) in data.length - 1" :key="i">
                  <div v-if="data[i + 1].description !== '' && data[i + 1].description" class="achievement">
                    <img class="absolute" src="@/assets/icons/star.png" alt="star" />
                    <p>
                      {{ data[i + 1].description }}
                    </p>
                  </div>
                </div>
              </template>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <SimcePanels v-if="isSimceAvailable()" />
    </div>
    <iframe
      v-if="widgetHighlightLoaded"
      title="performance-panel-highlight-widget"
      height="420"
      width="98%"
      class="campus-detail__panels__iframe"
      :src="showWidget('highlightstats')"
      loading="lazy"
    />
    <iframe
      v-if="widgetImageContainerLoaded"
      title="performance-panel-image-widget"
      height="780"
      width="98%"
      class="campus-detail__panels__iframe"
      :src="showWidget('imagecontainer')"
      loading="lazy"
    />
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import PerformanceChartDonut from '@/components/explorer/general/campus_detail/panels/PerformanceChartDonut.vue';
import PerformanceGlobal from '@/components/explorer/general/campus_detail/panels/PerformanceGlobal.vue';
import trackMixPanel from '@/utils/mixpanel';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import { getCampusPerformanceCategory, getPerformanceCategory } from '@/utils/categories/performance';
import Flag from '@/components/molecules/flags/Flag.vue';
import SvgIconV2 from '@/components/atoms/icons/SvgIconV2.vue';
import CONFIG from '@/config';
import SimcePanels from '@/components/explorer/general/campus_detail/panels/SimcePanels.vue';

export default {
  name: 'PerformancePanel',
  components: {
    PerformanceChartDonut,
    PerformanceGlobal,
    Flag,
    SvgIconV2,
    TextAtom,
    SimcePanels,
  },
  data() {
    return {
      hasQualityCategory: true,
      campusPerformance: {},
      campusCode: '',
      institutionCode: '',
      widgetHighlightLoaded: false,
      widgetImageContainerLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'institutions/campusDetails',
      performanceCategories: 'options/performanceCategories',
    }),
    statsInstitution() {
      const achievements = this.campusDetail.achievement_campus ?? [];
      achievements.sort((a, b) => a.order - b.order);
      let listAchievements = [
        {
          title: this.$t('campus-details.test.three'),
          data: this.campusDetail?.stats_saber_base?.saber_3 ?? [],
          number: 3,
          enable: this.hasDataStats(this.campusDetail?.stats_saber_base?.saber_3),
        },
        {
          title: this.$t('campus-details.test.five'),
          data: this.campusDetail?.stats_saber_base?.saber_5 ?? [],
          number: 5,
          enable: this.hasDataStats(this.campusDetail?.stats_saber_base?.saber_5),
        },
        {
          title: this.$t('campus-details.test.nine'),
          data: this.campusDetail?.stats_saber_base?.saber_9 ?? [],
          number: 9,
          enable: this.hasDataStats(this.campusDetail?.stats_saber_base?.saber_9),
        },
        {
          title: this.$t('campus-details.test.eleven'),
          data: this.campusDetail?.stats_saber_eleven ?? [],
          number: 11,
          enable: this.hasDataStats(this.campusDetail?.stats_saber_eleven, true),
        },
        {
          title: this.$t('campus-details.test.achievements'),
          data: achievements,
          number: 12,
          enable: this.hasAchievements(),
        },
      ];
      listAchievements = listAchievements.filter((achievement) => achievement.enable);

      return listAchievements;
    },
    // getIdPerformanceInstitution() {
    //   return this.campusDetail?.performance[0]?.qualitycategory_level_label?.id;
    // },
    // id 1 = Low; id 2 = MediumLow; id 3 = Medium; id 4 = High
    performanceInstitution() {
      const performanceCategories = [];
      // order by id performanceCategories
      const performanceFiltered = this.performanceCategories.filter((performance) => performance.id !== 6)
        .sort((a, b) => a.id - b.id);
      performanceFiltered.forEach((category) => {
        performanceCategories.push(getPerformanceCategory(category));
      });
      return performanceCategories;
    },
  },
  mounted() {
    if (this.campusDetail?.performance[0]?.qualitycategory_level_label?.id === 6) {
      this.hasQualityCategory = false;
    }
    // FIXME: this campusDetail that comes from the back has the information of 'performance_set' as 'performance'
    // so a copy is made so that the original is not modified and can be used in the categories component.
    const campusCopy = { ...this.campusDetail };
    const { performance } = this.campusDetail;
    campusCopy.performance_set = performance;
    this.campusPerformance = getCampusPerformanceCategory(campusCopy);
    this.campusCode = this.campusDetail.campus_code;
    this.institutionCode = this.campusDetail.institution_code;
    this.prefetchPerformanceWidgets();
  },
  methods: {
    ...mapActions({
      prefetchWidget: 'widgets/prefetchWidget',
    }),
    isSimceAvailable() {
      return CONFIG.tenant === 'chile';
    },
    getDescription() {
      return this.campusDetail?.institutiontext_campus[0]?.performance_text ?? '';
    },
    hasDataStats(data = [] || {}, isStatsEleven = false) {
      if (!isStatsEleven) {
        const filterStats = data
          .filter((item) => item.area_name === 'Matemáticas' || 'Lenguage')
          .map((item) => item.total_evaluated);

        return Math.max(...filterStats) > 0;
      }
      const filterStatsEleven = data.map((item) => item.results.length);

      return Math.max(...filterStatsEleven) > 0;
    },
    isCampusPerformance(performance) {
      return performance.category === this.campusPerformance.category;
    },
    trackTestClick(number) {
      trackMixPanel(
        number === 11
          ? 'sp_school_performance_higheredscore'
          : `sp_school_performance_test_${number}`,
        {
          school_id: this.campusDetail.uuid,
          campus_code: this.campusDetail.campus_code,
          institution_code: this.campusDetail.institution_code,
          name: this.campusDetail.campus_name,
        },
      );
    },
    hasAchievements() {
      const enable = this.campusDetail.achievement_campus.filter(
        (item) => item.description !== '',
      );
      return enable.length > 0;
    },
    prefetchPerformanceWidgets() {
      this.prefetchWidget({ campusCode: this.campusCode, endpoint: 'SchoolProfile_HighlightStats', extraParams: { institution_code: this.institutionCode } }).then((response) => {
        if (response.status === 200) {
          this.widgetHighlightLoaded = true;
        }
      });
      this.prefetchWidget({ campusCode: this.institutionCode, endpoint: 'ImageContainer', extraParams: { s: 1 } }).then((response) => {
        if (response.status === 200) {
          this.widgetImageContainerLoaded = true;
        }
      });
    },
    showWidget(widgetName) {
      if (widgetName === 'highlightstats') {
        return `${CONFIG.staticWidgetsBaseURL}/SchoolProfile_HighlightStats?campus_code=${this.campusCode}&institution_code=${this.institutionCode}`;
      }
      if (widgetName === 'imagecontainer') {
        return `${CONFIG.staticWidgetsBaseURL}/ImageContainer?campus_code=${this.institutionCode}&s=1`;
      }
      return '';
    },
  },
};
</script>
<style scoped>
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #eef5ff !important;
  box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.1) !important;
}
.description-performance {
  width: 100%;
  background: #deebff;
  border-radius: 4px;
  padding: 1.5rem 2rem;
}
.description-performance > p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 0.8rem;
  line-height: 20px;
  color: #2b5bff;
}
.description-performance > p:first-child {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.7rem;
  line-height: 15px;
}

.divisor-white {
  border: 0px;
  border-bottom: 2px solid white;
}

.divisor-blue {
  border: 0px;
  border-bottom: 2px solid #deebff;
}

.achievement {
  background-color: white;
  width: 100%;
  border-radius: 4px;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  padding: 2rem;
}

.achievement > p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: #2b5bff;
}

.achievement > img {
  margin-left: -3rem !important;
}
</style>
