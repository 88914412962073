<template>
  <div
    class="register-main"
    :class="{
      'register-main--width-auto': iconType === '',
      'register-main--warning-icon': iconType === 'warning',
      'register-main--info-icon': iconType === 'info',
    }"
  >
    <v-icon
      :size="setSize()"
      :class="{
        'register-main--avatar-icon': avatar,
      }"
      :style="`color: ${color} !important`"
    >
      {{ icon }}
    </v-icon>
  </div>
</template>

<script>
export default {
  name: 'MdiIcon',
  components: {},
  props: {
    color: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconType: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    avatar: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setSize() {
      if (!this.iconType) {
        return '25';
      }
      if (this.small) {
        return '18';
      }
      return '40';
    },
  },
};
</script>
