<template>
  <div
    :is="tag"
    :for="value === 'label' ? value : ''"
    class="space-x-1"
    :class="getClass()"
    :style="getStyle()"
    v-on="$listeners"
  >
    <slot name="pre" />
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-html="$t(value, { ...i18nArgs })" />
    <slot name="post" />
  </div>
</template>

<script>
export default {
  name: 'TextAtom',
  props: {
    value: {
      type: [String, Object],
      required: true,
      validator(value) {
        if (typeof value === 'string') {
          return true;
        }
        if (typeof value === 'object') {
          return value !== null && value !== undefined && value.path !== undefined && value.path !== null;
        }
        return false;
      },
    },
    tag: {
      type: String,
      default: 'h1',
      validator(value) {
        return ['h1', 'h2', 'h3', 'h4', 'p', 'label', 'p', 'span'].includes(value);
      },
    },
    font: {
      type: String,
      default: 'poppins',
      validator(value) {
        return ['poppins', 'roboto', 'inter'].includes(value);
      },
    },
    weight: {
      type: String,
      default: '400',
      validator(value) {
        return ['300', '400', '500', '600', '700'].includes(value);
      },
    },
    size: {
      type: String,
      default: 'normal',
      validator(value) {
        return ['normal', 'small', 'big', 'medium', 'large', 'subtitle', 'title', 'default'].includes(value);
      },
    },
    lineHeight: {
      type: String,
      default: 'default',
      validator(value) {
        return ['big', 'medium', 'default'].includes(value);
      },
    },
    color: {
      type: String,
      default: 'primary-dark',
      validator(value) {
        return [
          'error-check',
          'info',
          'neutral',
          'neutral-400',
          'neutral-600',
          'neutral-700',
          'neutral-800',
          'neutral-850',
          'neutral-900',
          'primary',
          'primary-600',
          'primary-700',
          'primary-dark',
          'primary-medium',
          'primary-light',
          'secondary-light',
          'secondary-dark',
          'text-neutral-600',
          'secondary-medium-light',
          'primary-blue',
          'color-white',
          'primary-blue',
        ].includes(value);
      },
    },
    i18nArgs: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    getClass() {
      const classFont = `font-${this.font}`;
      const classWeight = `font-weight-${this.weight}`;
      const classSize = `font-size-${this.size}`;
      const classLineHeight = `font-line-height-${this.lineHeight}`;

      return [classFont, classWeight, classSize, classLineHeight];
    },
    getStyle() {
      return {
        color: `var(--${this.color})`,
      };
    },
  },
};
</script>
