import CloseMapCard from '@/assets/icons/CloseMapCard.vue';
import Cross from '@/assets/icons/Cross.vue';
import Info from '@/assets/icons/Info.vue';
import InterdistrictMagnetSchool from '@/assets/icons/InterdistrictMagnetSchool.vue';
import logout from '@/assets/icons/logout.vue';
import MagnetCharterSchool from '@/assets/icons/MagnetCharterSchool.vue';
import MagnetSchool from '@/assets/icons/MagnetSchool.vue';
import neighborhood from '@/assets/icons/neighborhood.vue';
import NeighborhoodSchool from '@/assets/icons/NeighborhoodSchool.vue';
import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import PriorityOther from '@/assets/iconsChile/priority/priorityOther.vue';
import PrioritySEP from '@/assets/iconsChile/priority/prioritySEP.vue';
import PrioritySibling from '@/assets/iconsChile/priority/prioritySibling.vue';

Vue.use(Vuetify);

// Custom NH icons:

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 'md',
  },
  icons: {
    iconfont: 'mdi',
    values: {
      neighborhood: {
        component: neighborhood,
      },
      neighborhoodSchool: {
        component: NeighborhoodSchool,
      },
      interdistrictMagnetSchool: {
        component: InterdistrictMagnetSchool,
      },
      magnetCharterSchool: {
        component: MagnetCharterSchool,
      },
      magnetSchool: {
        component: MagnetSchool,
      },
      cross: {
        component: Cross,
      },
      logout: {
        component: logout,
      },
      info: {
        component: Info,
      },
      closeMapCard: {
        component: CloseMapCard,
      },
      priorityOther: {
        component: PriorityOther,
      },
      prioritySEP: {
        component: PrioritySEP,
      },
      prioritySibling: {
        component: PrioritySibling,
      },
    },
  },
});
