<template>
  <div>
    <p
      v-t="linkText"
      class="register-main--register-link"
      :class="{ 'register-main--register-link--primary': primary }"
      @click="linkClick()"
      @keydown="/* TODO */"
    />
  </div>
</template>

<script>
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'TextLink',
  props: {
    linkText: {
      type: String,
      default: '',
    },
    mixpanelTag: {
      type: String,
      default: null,
    },
    primary: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    linkClick() {
      if (this.mixpanelTag) {
        trackMixPanel(this.mixpanelTag);
      }
      this.$emit('toLink');
    },
  },
};
</script>
