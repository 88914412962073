<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width d-flex flex-column justify-center align-center">
      <ModalHeader
        :content-sections="headerContentSections"
        :actions-tag="'click_reg_user-register_back-button'"
        :title-text="'user_register.register_data.title'"
        small-logo
        @back="backMethod()"
      />
      <ModalContent
        :class="inWaitingList && !mobile ? 'deg-waiting-list--mobile' : ''"
        :content-sections="['registerInput', 'google', 'passValidation']"
        :error-types="setErrorTypes()"
        :input-section="inputContent"
        :recover-link-text="'views.login.to-forgot-password'"
        :input-text-select="showPhoneRegister ? true : false"
        :text-select-index="0"
        :text-select-items="listCodeArea"
        :google-input-tag="'click_reg_user-register_google-button'"
        @signInGoogle="signInGoogle()"
        @setValue="setValue"
        @isInvalidEmail="isInvalidEmail"
        @setTextSelectValue="setTextValue"
      />
      <span
        v-if="identificationResult.done && !identificationResult.success"
        v-t="'views.login.exists-account'"
        class="register-main--message-error"
      />
    </div>
    <div>
      <CallToActions
        :loader="registerLoading"
        :types="['main', 'terms']"
        :link-text="
          showPhoneRegister
            ? 'user_register.register_data.email-link'
            : 'user_register.register_data.mobile-link'
        "
        :link-text-tag="'click_reg_user-register_signin-using-phone-textlink'"
        :active-value="isActive"
        :main-button-text="'user_register.register_data.btn.register'"
        :main-button-tag="'click_reg_user-register_signin-button'"
        @continue="continueMethod()"
        @toLink="setInputType"
      />
    </div>
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import listCodeArea from '@/locales/code_area.json';
import utils from '@/utils/';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'RegisterData',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
  },
  props: {
    inWaitingList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputContent: [
        {
          value: null,
          noInfoError: false,
          placeholder: 'views.login.name.email',
          show: true,
          selectValue: this.$t('user_register.contact.default_dial_code'),
          inputType: 'email',
        },
        {
          value: '',
          noInfoError: false,
          placeholder: 'views.login.name.password',
          password: 1,
          show: true,
          inputType: 'password',
        },
      ],
      identification: null,
      password: null,
      invalidEmail: null,
      registerLoading: false,
      showPhoneRegister: false,
      dialCode: this.$t('user_register.contact.default_dial_code'),
    };
  },
  computed: {
    ...mapGetters({
      externalLoginInfo: 'authentication/externalLoginInfo',
      loginModal: 'authentication/loginModal',
      identificationResult: 'authentication/identificationResult',
      googleIdentificationResult: 'authentication/googleIdentificationResult',
      legalGuardianNeedsData: 'authentication/legalGuardianNeedsData',

    }),
    isActive() {
      if (
        this.identification
        && !this.invalidEmail
        && this.password
      ) {
        return true;
      }
      return false;
    },
    listCodeArea() {
      const dialCodes = listCodeArea.countries.map((country) => country.dial_code);
      return dialCodes;
    },
    headerContentSections() {
      if (this.externalLoginInfo.origin === 'digitalenrollment') {
        return ['title', 'logo'];
      }
      return ['actions', 'title', 'logo'];
    },
    phoneData() {
      if (this.showPhoneRegister) {
        return {
          dialCode: this.dialCode,
          number: this.identification,
        };
      }
      return null;
    },
    email() {
      if (!this.showPhoneRegister) {
        return this.identification?.toLowerCase() ?? null;
      }
      return null;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    identificationResult(newValue) {
      const {
        done, found, success, verify,
      } = newValue;
      const inApplicationFlow = this.loginModal === 'applicationFlow';
      this.registerLoading = !done;
      if (done) {
        if (success && verify) {
          if (found) this.error(this.$t('views.login.error.verification'));
          this.$emit('nextStep'); // Verify Code
        } else if (found && !success && inApplicationFlow) {
        // When the user is in the application flow and the identification is found but not successful
        // we send them to the password recovery flow
          this.$emit('toResetPass');
        } else if (success && this.legalGuardianNeedsData) {
          this.$emit('skipVerification');
        } else if (success) {
          this.$router.push('/map');
        }
      }
    },
    googleIdentificationResult(newVal) {
      const {
        done, success, newUser, canLink,
      } = newVal;
      if (done) {
        if (success && (newUser || this.legalGuardianNeedsData)) {
          this.$emit('skipVerification');
        } else if (canLink) {
          this.$emit('toAccountLinking');
        } else if (success) {
          this.$router.push('/map');
        }
      }
    },
  },
  mounted() {
    this.setForcedStep({ step: null });
    this.setInRegisterFlow(true);
  },
  methods: {
    ...mapActions({
      doIdentification: 'authentication/doIdentification',
      error: 'utils/error',
      doGoogleIdentification: 'authentication/doGoogleIdentification',
      setInRegisterFlow: 'userRegister/setInRegisterFlow',
      setPhoneNumber: 'authentication/setPhoneNumber',
      setForcedStep: 'userRegister/setForcedStep',
    }),
    setErrorTypes() {
      if (!this.showPhoneRegister && this.email) {
        if (
          utils.containsWhitespace(this.email)
          && (this.invalidEmail || this.invalidEmail === null)
        ) {
          return ['email', 'email-space'];
        }
        if (utils.containsWhitespace(this.email) && !this.invalidEmail) {
          return ['email-space'];
        }
      }

      return this.showPhoneRegister ? [] : ['email'];
    },
    async continueMethod() {
      if (this.isActive) {
        this.registerLoading = true;
        await this.$recaptchaLoaded();
        const captcha = await this.$recaptcha('login');
        const identificationData = {
          email: this.email,
          phoneData: this.phoneData,
          password: this.password,
          captcha,
        };

        this.doIdentification({ identification: identificationData, register: true });
      } else {
        this.inputContent[0].noInfoError = this.invalidEmail || !this.identification;
        this.inputContent[1].noInfoError = !this.password;
      }
    },
    backMethod() {
      this.$emit('backStep');
    },
    async signInGoogle() {
      const successCallback = (googleAuth) => {
        this.doGoogleIdentification({
          googleAuth,
        }).then((response) => {
          if (response) {
            return this.$emit('setLocationModal');
          }
          return this.$emit('nextStep');
        });
      };

      this.$gAuth.signIn(successCallback);
    },
    setValue(mainValue, passwordInput, index) {
      if (index === 0) {
        this.identification = mainValue;
      }
      if (passwordInput === 1) {
        this.password = mainValue;
      }
      this.inputContent[index].value = mainValue;
      this.inputContent[index].noInfoError = false;
    },
    isInvalidEmail(bool) {
      this.invalidEmail = bool;
      return this.invalidEmail;
    },
    setTextValue(index, value) {
      if (index === 0) {
        this.dialCode = value;
      }
      return this.dialCode;
    },
    setInputType() {
      if (!this.showPhoneRegister) {
        this.inputContent[0].placeholder = 'user_register.register_data.phone-placeholder';
        this.showPhoneRegister = true;
        this.inputContent[0].inputType = 'number';
        this.invalidEmail = false;
        return this.showPhoneRegister;
      }
      this.inputContent[0].inputType = 'email';
      this.inputContent[0].placeholder = 'views.login.name.email';
      this.showPhoneRegister = false;
      return this.showPhoneRegister;
    },
  },
};
</script>
