<template>
  <div
    class="register-main"
    :class="{ 'register-main--width-auto': rowInstructions }"
  >
    <h3
      v-t="setText()"
      :class="{
        'register-main--subtitle--secondary': secondary,
        'register-main--subtitle--center': center,
        'register-main--subtitle--light': light,
        'register-main--subtitle--neutral-800': neutral,
        'ma-0': noMargin,
      }"
      class="register-main--subtitle"
    />
  </div>
</template>

<script>
export default {
  name: 'SubtitleAtom',
  components: {},
  props: {
    subtitle: {
      type: String,
      default: '',
    },
    center: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    args: {
      type: String,
      default: null,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    neutral: {
      type: Boolean,
      default: false,
    },
    rowInstructions: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setText() {
      if (this.args) {
        const text = {
          path: this.subtitle,
          args: { name: this.args },
        };
        return text;
      }
      return this.subtitle;
    },
  },
};
</script>
