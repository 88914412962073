<template>
  <div class="fill-width">
    <section v-for="(step, index) in stepper" :key="index">
      <div class="register-main--step" @click="mixpanelClick()" @keydown="/* TODO */">
        <img style="margin-right: 24px" src="@/assets/icons/grey-check.svg" alt="check" />
        <div>
          <h6 class="register-main--step--title">
            {{ $t('user_register.steps_modal.step', { number: index + 1 }) }}
          </h6>
          <p v-t="step.text" class="register-main--step--subTitle" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'ColumnStepper',
  components: {},
  props: {
    stepperInfo: {
      type: Array,
      default() {
        return [];
      },
    },
    mixpanelTag: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      stepper: [],
    };
  },
  mounted() {
    this.stepper = this.stepperInfo;
  },
  methods: {
    mixpanelClick() {
      trackMixPanel(this.mixpanelTag);
    },
  },
};
</script>
