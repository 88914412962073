<template>
  <div class="register-main d-flex" style="margin-top: 16px">
    <p
      v-t="'user_register.register_data.terms'"
      class="register-main--terms-link"
      @click="openTerms()"
      @keydown="openTerms()"
    />
  </div>
</template>

<script>
import userRegistrationService from '@/services/userRegistration.services';

export default {
  name: 'TermsSection',
  methods: {
    openTerms() {
      userRegistrationService.getTermsAndConditions(['tether_base']).then(({ data }) => {
        const url = data[0]?.pdf_url;
        if (url) {
          window.open(url, '_blank');
        }
      });
    },
  },
};
</script>
