<template>
  <section id="flag" class="d-flex flex-column align-center justify-center" :class="flagStyles">
    <SvgIconV2
      v-if="showIcon"
      :icon="icon"
      :color="iconStyle.color"
      :background="iconStyle.background"
    />
    <TextAtom
      v-if="showLabel"
      class="mt-1"
      :value="label"
      :color="outlined ? 'primary-dark' : color"
      font="inter"
      size="small"
      tag="span"
    />
  </section>
</template>

<script>
import SvgIconV2 from '@/components/atoms/icons/SvgIconV2.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';

export default {
  name: 'FlagMolecule',
  components: {
    TextAtom, SvgIconV2,
  },
  props: {
    background: {
      type: String,
      default: 'primary-dark',
      validator(value) {
        return ['primary-700', 'primary-light', 'primary-medium', 'primary', 'secondary-dark', 'info', 'neutral-50', 'primary-dark'].includes(value);
      },
    },
    styling: {
      type: String,
      default: 'icon-label',
      validator(value) {
        return ['icon-label', 'icon', 'label', 'icon-round', 'tiny-label'].includes(value);
      },
    },
    label: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: 'primary-dark',
      validator(value) {
        return ['primary-700', 'primary-light', 'primary-medium', 'primary', 'secondary-dark', 'info', 'neutral-50', 'primary-dark', 'secondary-light', 'text-neutral-600'].includes(value);
      },
    },
    icon: {
      type: String,
      default: null,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    flagStyles() {
      const styles = [`flag-${this.styling}`, `bg-${this.outlined ? 'white' : this.background}`];
      return styles;
    },
    iconStyle() {
      if (this.outlined) {
        return {
          color: 'white',
          background: `var(--${this.background})`,
        };
      }
      return {
        color: 'white',
        background: 'transparent',
      };
    },
    showIcon() {
      return this.icon && ['icon', 'icon-label', 'icon-round'].includes(this.styling);
    },
    showLabel() {
      return this.label && ['label', 'icon-label', 'tiny-label'].includes(this.styling);
    },
  },
};
</script>

<style lang="scss" scoped>
#flag {
  border-radius: 4px;

  span {
    line-height: 14px !important;
  }

  &.flag-icon-label {
    height: 4.37rem;
  }

  &.flag-icon-round {
    border-radius: 50% !important;
    width: fit-content;
  }

  &.flag-tiny-label {
    height: 2rem;
    width: 5rem;
    padding: 0px 4px 5px;
  }
}

</style>
