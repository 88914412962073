var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-main",class:{ 'register-main--width-auto': _vm.rowInstructions }},[_c('h3',{directives:[{name:"t",rawName:"v-t",value:(_vm.setText()),expression:"setText()"}],staticClass:"register-main--subtitle",class:{
      'register-main--subtitle--secondary': _vm.secondary,
      'register-main--subtitle--center': _vm.center,
      'register-main--subtitle--light': _vm.light,
      'register-main--subtitle--neutral-800': _vm.neutral,
      'ma-0': _vm.noMargin,
    }})])
}
var staticRenderFns = []

export { render, staticRenderFns }