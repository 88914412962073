/* eslint-disable no-undef */
// Facebook SDK api is defined when loaded the dashboard

import CONFIG from '@/config';

export default {
  async login() {
    return new Promise((resolve, reject) => {
      FB.login((loginResponse) => {
        if (loginResponse.authResponse) {
          const shortLivedToken = loginResponse.authResponse.accessToken;
          FB.api(`/oauth/access_token?grant_type=fb_exchange_token&client_id=719428950315244&client_secret=3f7e2bfc7ab6f154c6343c6e183cd9b9&fb_exchange_token=${shortLivedToken}`, (tokenExchangeResponse) => {
            const longLivedToken = tokenExchangeResponse.access_token;
            FB.api(`/me/accounts?access_token=${longLivedToken}`, (accountsResponse) => {
              resolve(accountsResponse);
            });
          });
        } else {
          reject(new Error('User cancelled login or did not fully authorize.'));
        }
      }, { scope: CONFIG.metaIntegrationPermissions });
    });
  },

  async getBusinessAccountId(businessAccountId) {
    return new Promise((resolve, reject) => {
      FB.api(`${businessAccountId}?fields=instagram_business_account`, (response) => {
        if ('instagram_business_account' in response) {
          resolve(response.instagram_business_account.id);
        } else {
          reject(new Error('No se encontró el business account'));
        }
      });
    });
  },

};
