import CONFIG from '@/config';

export default {
  state: {
    locale: process.env.VUE_APP_I18N_LOCALE,
  },

  getters: {
    locale: (state) => state.locale,
  },

  mutations: {
    setLocale: (state, locale) => {
      state.locale = locale;
    },
  },

  actions: {
    setLocale: ({ commit }, locale) => {
      commit('setLocale', locale);
    },
    setLocaleNumber: ({ commit }, localeNumber) => {
      const locale = CONFIG.language[localeNumber];
      commit('setLocale', locale);
    },
  },

  namespaced: true,
};
