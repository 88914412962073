import moment from 'moment';

function parseName(name) {
  // Capitalize the first letter of each word, leaving the rest of the word in lowercase.
  // Example 1: "hello world" -> "Hello World"
  // Example 2: "HELLO WORLD" -> "Hello World"
  return name
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

function decomposePhoneNumber(phoneNumber, areaCodes) {
  /**
   * Very weak phone number decomposer.
   * Decompose a phone number into its country code and line number.
   * It parses the phone number from left to right, and stops when it finds a dial number.
   */
  let index = 1;
  while (index <= phoneNumber.length) {
    const prefix = phoneNumber.substring(0, index);
    if (areaCodes.includes(prefix)) {
      return {
        countryCode: prefix,
        lineNumber: phoneNumber.substring(index),
      };
    }
    index += 1;
  }
  return {
    countryCode: '',
    lineNumber: phoneNumber,
  };
}

function agreementAbbreviation(agreement) {
  const abbreviationRegex = /[A-Z]{3,}/;
  const abbreviationMatch = agreement.match(abbreviationRegex);

  if (abbreviationMatch) {
    return abbreviationMatch[0]; // Return the matched abbreviation
  }

  return agreement;
}

function isValidEmail(email) {
  // Check if an email is valid.
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

function snakeToCamel(str) {
  // Convert a snake case string to camel case.
  // Example: "hello_world" -> "helloWorld"
  return str.replace(/([-_][a-z])/gi, ($1) => $1.toUpperCase().replace('-', '').replace('_', ''));
}

function camelToSnake(str) {
  // Convert a camel case string to snake case.
  // Example: "helloWorld" -> "hello_world"
  return str.replace(/([A-Z])/g, ($1) => `_${$1.toLowerCase()}`);
}

/**
 * Remove the tailing slash from a URL.
 * @param {String} url - URL to remove the tailing slash from.
 * @returns {String} - URL without the tailing slash.
 *
 * @example
 * stripTailingSlash('https://google.com/') // 'https://google.com'
 */
function stripTailingSlash(url) {
  if (!url) {
    return '';
  }
  return url.endsWith('/') ? url.slice(0, -1) : url;
}

function urlWithHTTP(url) {
  // Add the HTTP protocol to a URL if it doesn't have it.
  // Example: "google.com" -> "http://google.com"
  if (!url || url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }
  return `http://${url}`;
}

function extractAfterLastSlash(url) {
  // Extract the part of the URL after the last forward slash
  if (!url) {
    return url;
  }
  const match = url.match(/([^/]+)$/);
  // If there is a match, return the captured group, otherwise, return the original URL
  return match ? match[1] : url;
}

function prettyDate(date) {
  if (!date) {
    return '';
  }
  // Convert a date to a pretty string.
  // Example: "2020-01-01T10:20:30Z" -> "January 1, 2020 10:20:30"
  const momentDate = moment(date).locale(process.env.VUE_APP_I18N_LOCALE);
  return momentDate.format('MMMM D, YYYY HH:mm:ss');
}

const repeatUntilLength = (str, length) => (
  // Repeat a string until it reaches a certain length.
  // Example: repeatUntilLength("123", 5) -> "12312"
  str.repeat(Math.ceil(length / str.length)).substring(0, length)
);

function resolveImageUrl(src, currentBreakpoint) {
  const s3Url = 's3.amazonaws.com';
  if (!src.includes(s3Url)) {
    return src;
  }
  const breakpoints = {
    desktop: '/desktop.jpg',
    mobile: '/mobile.jpg',
    tablet: '/tablet.jpg',
  };

  const replacement = breakpoints[currentBreakpoint];
  const finalUrl = src.replace(/(\/desktop.jpg|\/mobile.jpg|\/tablet.jpg)$/, replacement);

  return replacement && !src.endsWith(replacement) ? finalUrl : src;
}

const normalizeString = (str) => str.trim()
  .normalize('NFD')
  .replace(/[\u0300-\u036f]/g, '')
  .replace(/[^A-Z0-9]+/ig, '-')
  .toLowerCase();

export {
  camelToSnake,
  decomposePhoneNumber,
  isValidEmail,
  parseName,
  prettyDate,
  repeatUntilLength,
  snakeToCamel,
  stripTailingSlash,
  urlWithHTTP,
  agreementAbbreviation,
  resolveImageUrl,
  extractAfterLastSlash,
  normalizeString,
};
