<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        :actions-tag="registerType === 1
          ? 'click_reg_quick-register-headmaster_back-button'
          : 'click_reg_detailed-register-headmaster_back-button'"
        small-logo
        inline-logo
        @back="$emit('backStep')"
      />
      <ModalContent
        :content-sections="['smallTitle', 'primaryText']"
        small-title-left
        :small-title-text="'user_register.headmaster_info.title'"
        :primary-text-label="'user_register.headmaster_info.info'"
      />
    </div>
    <CallToActions
      :types="['main']"
      :main-button-tag="registerType === 1
        ? 'click_reg_quick-register-headmaster_schedule-button'
        : 'click_reg_detailed-register-headmaster_schedule-button'"
      active-value
      :main-button-text="'user_register.headmaster_info.button1'"
      :spaced="false"
      @continue="nextStep"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';

export default {
  name: 'HeadmasterInfo',
  components: {
    ModalHeader,
    ModalContent,
    CallToActions,
  },
  props: {
    // 1 Quick - 2 Detailed
    registerType: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({}),
    nextStep() {
      this.$emit('nextStep');
      return null;
    },
  },
};
</script>
