import { backendAPI } from '@/api';

let openRiskRequestController;
let userRiskRequestController;

export default {
  reportRisk(payloadSimulation, abortPrevious = true) {
    if (abortPrevious && userRiskRequestController) {
      userRiskRequestController.abort();
    }
    userRiskRequestController = new AbortController();
    return backendAPI.post('/reports/risk/risk_v2', {
      ...payloadSimulation,
    }, {
      signal: userRiskRequestController.signal,
    });
  },
  getOpenRisk(openRiskPayload, abortPrevious = true) {
    if (abortPrevious && openRiskRequestController) {
      openRiskRequestController.abort();
    }
    openRiskRequestController = new AbortController();
    return backendAPI.post(
      '/reports/risk/risk_open',
      openRiskPayload,
      {
        signal: openRiskRequestController.signal,
      },
    );
  },
};
