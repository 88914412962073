<template>
  <section class="w-full d-flex flex-column bg-secondary-light">
    <!-- title -->
    <TextAtom
      class="text-left mb-2"
      :value="$t('feedback.schools_you_applied.title')"
      tag="h2"
      font="poppins"
      color="primary-700"
      weight="400"
      size="subtitle"
    />
    <!-- descriptions -->
    <TextAtom
      v-for="(description) in $t('feedback.schools_you_applied.descriptions')"
      :key="description"
      class="text-left mt-3"
      :value="description"
      tag="p"
      font="inter"
      color="neutral-800"
      weight="400"
      size="normal"
    />
    <!-- application card -->
    <div
      v-for="(application, index) in applications"
      :key="application.campus.campus_code"
      class="w-full d-flex flex-column  bg-neutral rounded-lg px-5 py-5 reveal-animation"
      :class="index === 0 ? 'mt-7' : 'mt-5'"
      style="--d: 0.09s"
    >
      <!-- campus -->
      <div class="mb-4 d-flex flex-row reveal-animation" style="--d: 0.1s">
        <TextAtom
          :value="$t('feedback.schools_you_applied.index', { number: index + 1 })"
          tag="h2"
          font="inter"
          color="secondary-light"
          weight="600"
          size="subtitle"
          class="feedback-index bg-primary-dark rounded-lg d-flex justify-center align-center"
        />
        <!-- campus information -->
        <div class="d-flex flex-column mx-3 w-full">
          <TextAtom
            :value="application.campus.campus_name"
            tag="h3"
            font="inter"
            color="primary-dark"
            weight="500"
            size="normal"
            class="text-left"
          />
          <!-- campus description -->
          <div class="d-flex w-full flex-row flex-wrap mt-2 feedback-text-wrap-balance">
            <TextAtom
              v-for="(description) in descriptionCampus(application)"
              :key="description"
              :value="description"
              tag="span"
              font="inter"
              color="neutral-800"
              weight="400"
              size="default"
              class="text-left"
            />
          </div>
          <!-- campus agreements -->
          <div class="d-flex w-full flex-row flex-wrap mt-3 feedback-text-wrap-balance">
            <TextAtom
              v-for="(agreement, indexAgreement) in agreementsCampus(application)"
              :key="agreement"
              :value="agreement"
              tag="span"
              font="inter"
              color="primary-dark"
              weight="500"
              size="default"
              class="campus-agreement"
              :class="indexAgreement === 0 ? '' : 'ml-2'"
            />
          </div>
        </div>
      </div>
      <!-- campus categories -->
      <CampusCategories
        class="reveal-animation"
        style="--d: 0.17s"
        :campus="application.campus"
        type-configuration="FEEDBACK"
        :default-data="{ ADMISSION_RISK: application?.ratex }"
      />
      <ApplicationsToDate
        :stats="application.stats"
        :open-applications="index === 0"
        class="mt-4 d-flex flex-row justify-start applications-to-date"
        :campus-code="application.campus.campus_code"
        :application="application"
      />
      <button
        v-t="'feedback.show_campus'"
        type="button"
        class="mt-2 show-profile"
        @click="exploreCampusDetail(application.campus.campus_code, application)"
      />
    </div>
    <v-dialog
      v-model="campusDetailModal"
      transition="dialog-bottom-transition"
      max-width="800px"
      content-class="favorites__dialog"
    >
      <CampusDetail
        v-if="campusDetailModal"
        style="z-index: 1000"
        in-dashboard
        @close="closeCampusDetail"
      />
    </v-dialog>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import ApplicationsToDate from '@/components/organisms/feedback/ApplicationsToDate.vue';
import CampusCategories from '@/components/organisms/campus/information/CampusCategories.vue';
import CampusDetail from '@/components/explorer/general/campus_detail/CampusDetail.vue';
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'FeedbackSchoolsYouApplied',
  components: {
    TextAtom,
    ApplicationsToDate,
    CampusCategories,
    CampusDetail,
  },
  props: {
    applications: {
      type: Array,
      required: true,
    },
    studentUui: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      listShowApplicationsToDate: [],
      campusDetailModal: false,
    };
  },
  mounted() {
    this.listShowApplicationsToDate = new Array(this.applications.length).fill(false);
  },
  methods: {
    ...mapActions({
      updateCurrentSchool: 'institutions/updateCurrentSchool',
    }),
    closeCampusDetail() {
      this.campusDetailModal = false;
    },
    async exploreCampusDetail(campusCode, application) {
      this.campusDetailModal = true;
      trackMixPanel('fbin_postind_explorar_application ', { campusCode, application, studentUuid: this.studentUui });
      await this.updateCurrentSchool({ campusCode, fromUrl: true });
    },
    descriptionCampus(application) {
      const descriptions = [''];
      descriptions.push(`${application?.program?.grade_name} ・` || '');
      descriptions.push(`${application?.program?.shift_label} ・` || '');
      descriptions.push(` ${application?.program?.gender}` || '');
      return descriptions.filter((description) => description);
    },
    agreementsCampus(application) {
      const agreementLabel = {
        1: 'SEP',
        2: 'PIE',
      };

      return application?.agreements?.map((agreement) => agreementLabel[agreement.id]);
    },
    // eslint-disable-next-line vue/no-unused-properties
    availableSeats(application) {
      const nRejected = application.n_considered_tot - application.seats;

      return nRejected < 0 ? 0 : nRejected;
    },
  },
};
</script>
<style lang="scss" scoped>
.campus-agreement {
  padding: 0.1rem 0.3rem 0.01rem;
  border-radius: 3px;
  background-color: var(--gray-200);
}
.applications-to-date {
  width: 100%;
  justify-content: space-between;
  padding: 0;
  max-width: 280px;
}
.reveal-animation {
  opacity: 0;
  animation: reveal 0.5s forwards var(--d)
}

.show-profile {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-medium-light);
  color: var(--neutral);
  border-radius: 30px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.28px;
}

@keyframes reveal {
  from {
    transform: translateY(20px);
  }
  to {
    opacity: 1; transform: none;
  }
}
</style>
