<template>
  <div class="campus-detail__panels__container campus-detail__panels__container__3 pl-6 pr-6">
    <section class="fill-width mb-6">
      <p :style="{ color: 'var(--primary-dark)' }">
        {{ $t('campus-details.current_performance.enrollment_title_1') }}
        <span class="font-bold">
          {{ $t('campus-details.current_performance.enrollment_title_2') }}
        </span>
        {{ $t('campus-details.current_performance.enrollment_title_3') }}
      </p>
    </section>
    <EnrollmentInfo
      v-if="getStudentsInUniversity(new Date().getFullYear() - 1)"
      :icon-src="require('@/assets/icons/face-primary.svg')"
      text-class="panel_container_row__white panel_container_row--text ma-0 panel_container_row--text__green"
      title-class="campus-detail__panels__performance-title--green-enrollment text-left font-weight-medium"
      :count="getStudentsInUniversity(new Date().getFullYear() - 1)"
      :title="$t('campus-details.panels.university')"
      :year="new Date().getFullYear() - 1"
    />
    <EnrollmentInfo
      v-if="getStudentsInUniversity(new Date().getFullYear() - 2)"
      :icon-src="require('@/assets/icons/face-primary.svg')"
      text-class="panel_container_row__white panel_container_row--text ma-0 panel_container_row--text__green"
      title-class="campus-detail__panels__performance-title--green-enrollment text-left font-weight-medium"
      :count="getStudentsInUniversity(new Date().getFullYear() - 2)"
      :title="$t('campus-details.panels.university')"
      :year="new Date().getFullYear() - 2"
    />
    <EnrollmentInfo
      v-if="getStudentsInUniversity(new Date().getFullYear() - 3)"
      :icon-src="require('@/assets/icons/face-primary.svg')"
      text-class="panel_container_row__white panel_container_row--text ma-0 panel_container_row--text__green"
      title-class="campus-detail__panels__performance-title--green-enrollment text-left font-weight-medium"
      :count="getStudentsInUniversity(new Date().getFullYear() - 3)"
      :title="$t('campus-details.panels.university')"
      :year="new Date().getFullYear() - 3"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EnrollmentInfo from './EnrollmentInfo.vue';

export default {
  name: 'EnrollmentPanel',
  components: {
    EnrollmentInfo,
  },
  computed: {
    ...mapGetters({
      campusDetail: 'institutions/campusDetails',
    }),
  },
  methods: {
    getStudentsInUniversity(year) {
      return this.getStudentsCount(year);
    },
    getStudentsCount(year) {
      const students = this.campusDetail.higheredstats_campus.find(
        (stats) => stats.highered_stats_label === 4 && stats.year === year,
      );
      return students !== undefined ? `${Math.round(students.proportion * 1000) / 10} %` : false;
    },
  },
};
</script>
