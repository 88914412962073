<template>
  <div class="register-main" @click="clickTextSelect()" @keydown="/* TODO */">
    <v-select
      v-model="value"
      :items="items"
      :placeholder="$t(placeholder)"
      :item-text="itemText"
      :item-value="itemValue"
      class="register-main--select"
      :class="{ 'register-main--text-select-input': isInInputSection }"
      height="48"
      :disabled="disabled"
      :return-object="returnObject"
      @change="setValue(index, value)"
    />
  </div>
</template>

<script>
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'TextSelect',
  components: {},
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isInInputSection: {
      type: Boolean,
      default: false,
    },
    selectValue: {
      type: [Object, String],
      default: null,
    },
    returnObject: {
      // Determines whether the v-select component returns an object or the value specified in the item-value prop
      type: Boolean,
      default: false,
    },
    itemText: {
      // Defines the property to use as display text for the item
      // Vuetify uses by default 'text', and we're wrapping this setting
      // Example: itemText="label" -> item.label will be used as display text
      type: String,
      default: 'text',
    },
    itemValue: {
      // Defines the property to return as value for the item
      // Vuetify uses by default 'value', and we're wrapping this setting
      // Example: itemValue="number" -> item.number will be returned
      // If returnObject is true, this prop is ignored
      type: String,
      default: 'value',
    },
    clickMixpanelTag: {
      type: String,
      default: null,
    },
    mixpanelTag: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      value: null,
    };
  },
  watch: {
    selectValue() {
      this.value = this.selectValue;
    },
  },
  mounted() {
    if (this.selectValue) {
      this.value = this.selectValue;
    }
  },
  methods: {
    setValue(index, value) {
      if (this.mixpanelTag) {
        if (value.address_name) {
          trackMixPanel(`${this.mixpanelTag}${value.address_name.toLowerCase()}`);
        } else {
          trackMixPanel(this.mixpanelTag);
        }
      }
      this.$emit('setValue', index, value);
    },
    clickTextSelect() {
      if (this.clickMixpanelTag) {
        trackMixPanel(this.clickMixpanelTag);
      }
    },
  },
};
</script>
