<template>
  <section>
    <!-- id 6 is equals not information -->
    <TextAtom
      :value="getDescription()"
      class="mt-4 text-left d-flex pl-4 pb-3"
      tag="label"
      font="inter"
      color="primary-dark"
      weight="400"
      size="subtitle"
    />
    <div class="full-width d-flex flex-row">
      <!-- Monthly payment -->
      <div v-if="getPayment() !== null" class="campus-detail__panels__container w-1/2">
        <p v-t="'campus-details.panels.monthly'" class="campus-detail__panels__title" />

        <img
          v-if="getPayment().id !== 7"
          class="school-card__info__icon mt-10"
          :src="iconPriceSelection(getPayment().id)"
          alt="icon"
        />

        <p class="campus-detail__panels__title subtitle-1 mt-1 font-weight-light">
          {{ getPayment().name }}
        </p>
      </div>

      <!-- Yearly fee -->
      <div v-if="getYearlyFee() !== null" class="campus-detail__panels__container w-1/2">
        <p v-t="'campus-details.panels.yearly_fee'" class="campus-detail__panels__title" />

        <img
          v-if="getYearlyFee().id !== 7"
          class="school-card__info__icon mt-10"
          :src="iconPriceSelection(getYearlyFee().id)"
          alt="icon"
        />

        <p class="campus-detail__panels__title subtitle-1 mt-1 font-weight-light">
          {{ getYearlyFee().name }}
        </p>
      </div>
    </div>

    <div
      v-if="getTotalScholrships() !== 0 || getTotalScholrships() !== null"
      class="campus-detail__panels__container full-width mt-2 mb-3"
    >
      <p v-t="'campus-details.panels.scholarships'" class="campus-detail__panels__title" />

      <p class="campus-detail__panels__title subtitle-1 mt-1 font-weight-light">
        {{ getTotalScholrships() }}
      </p>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';

export default {
  name: 'PriceSection',
  components: {
    TextAtom,
  },
  props: {
    isDashboardPanel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      campusDetail: {},
    };
  },
  computed: {
    ...mapGetters({
      campusDetailDigitalProfile: 'institutions/campusDetails',
      campusDetailDashboardPanel: 'roles/currentHeadMasterMainSchool',
    }),
  },
  watch: {
    campusDetailDashboardPanel: {
      handler() {
        this.campusDetail = this.isDashboardPanel
          ? this.campusDetailDashboardPanel
          : this.campusDetailDigitalProfile;
      },
      deep: true,
    },
  },
  mounted() {
    this.campusDetail = this.isDashboardPanel
      ? this.campusDetailDashboardPanel
      : this.campusDetailDigitalProfile;
  },
  methods: {
    getDescription() {
      return this.campusDetail?.institutiontext_campus[0].payment_text ?? '';
    },
    iconPriceSelection(paymentId) {
      let icon = '';

      switch (paymentId) {
        case 1:
          icon = 'price-free.svg';
          break;
        case 2:
        case 3:
        case 4:
          icon = 'price-10-50.svg';
          break;
        case 5:
          icon = 'price-50-100.svg';
          break;
        case 6:
          icon = 'price-100.svg';
          break;
        default:
          icon = '';
          break;
      }
      // eslint-disable-next-line import/no-dynamic-require, global-require
      const image = require(`@/assets/iconsChile/campusDetail/${icon}`);
      return image;
    },
    getPayment() {
      if (this.campusDetail.payment_campus) {
        const payment = {
          id: this.campusDetail?.payment_campus[0]?.payment_category_label?.id,
          name: this.campusDetail?.payment_campus[0]?.payment_category_label?.payment_category_name,
        };
        return payment;
      }
      return null;
    },
    getYearlyFee() {
      if (this.campusDetail.payment_campus) {
        const yearlyFee = {
          id: this.campusDetail?.payment_campus[0]?.tuition_category_label?.id,
          name: this.campusDetail?.payment_campus[0]?.tuition_category_label?.tuition_category_name,
        };
        return yearlyFee;
      }
      return null;
    },
    getTotalScholrships() {
      if (this.campusDetail.payment_campus) {
        return this.campusDetail?.payment_campus[0]?.num_scholarships ?? this.$t('campus-details.not-info');
      }
      return null;
    },
  },
};
</script>
