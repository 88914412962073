<template>
  <div
    class="register-main z-index--3"
    :class="{ 'register-main register-main--spaced': spaced }"
    style="margin-top: 16px; padding-bottom: 16px"
    :style="mainActiveLight || widthStretch ? 'width: max-content' : ''"
  >
    <InfoButton
      v-if="inSimulation"
      class="fill-width"
      :section-info="infoButtonsSection[0]"
      :mixpanel-tag="infoButtonsSection[0][0].tag"
      purple
      :center="infoColorCenter"
      :origin="'call'"
      :without-title="infoButtonWithoutTitle"
      @infoNextStep="$emit('infoNextStep', 0)"
    />
    <div
      v-if="types.includes('info')"
      class="fill-width"
      :class="{
        'd-flex fill-width': (inSimulation || inWelcome) && !mobile,
        'welcome-explorer__register--location': inWelcome,
      }"
      :style="{ '--padding': mobile ? '30px' : '58px' }"
    >
      <section
        v-for="(section, i) in buttonSection"
        :key="section.key || i"
        :style="i === 0 && inSimulation ? 'margin-right: 20px' : ''"
        :class="{ 'fill-width': inSimulation || inWelcome }"
      >
        <InfoButton
          :section-info="section"
          :title="i === 0 ? infoTitleText1 : infoTitleText2"
          :mixpanel-tag="section[0].tag"
          :time="i === 0 ? time1 : time2"
          :purple="i === 0 && !inSimulation && infoColorPurple"
          :center="infoColorCenter"
          :origin="'call'"
          :without-title="infoButtonWithoutTitle"
          @infoNextStep="$emit('infoNextStep', inSimulation ? i + 1 : i)"
        />
      </section>
    </div>
    <div v-if="types.includes('option')" class="fill-width">
      <section v-for="i in numberOfOptions" :key="i">
        <OptionButtons
          v-if="(i === 1 && optionText1 !== '') || (i === 2 && optionText2 !== '')"
          :is-active="isActive(i)"
          :svg-icon="i === 1 ? svgIcon1 : svgIcon2"
          :text="i === 1 ? optionText1 : optionText2"
          :no-margin="optionBtnNoMargin"
          :mixpanel-tag=" i === 1 ? optionTextActiveTag : optionTextTag"
          :light="light"
          @nextStep="$emit('nextStep', i)"
        />
      </section>
    </div>
    <MainButton
      v-if="types.includes('main')"
      :active-value="activeValue"
      :text="mainButtonText"
      :small="small"
      :medium="medium"
      :loader="loader"
      :absolute="isMainAbsolute"
      :mixpanel-tag="mainButtonTag"
      :mixpanel-payload="mainButtonPayload"
      :light="mainActiveLight"
      :tiny="mainButtonTiny"
      @continue="$emit('continue')"
    />
    <div
      v-if="types.includes('question') || types.includes('link')"
      style="margin-top: 16px"
      class="d-flex"
    >
      <QuestionLabel v-if="types.includes('question')" :question-text="questionText" />
      <TextLink
        v-if="types.includes('link')"
        :link-text="linkText"
        :mixpanel-tag="linkTextTag"
        @toLink="$emit('toLink')"
      />
    </div>
    <Terms v-if="types.includes('terms')" />
    <SvgLogo
      v-if="types.includes('svgLogo')"
      :logo="svgLogo"
      :svg-width="svgWidth"
      :left="svgLeftAlign"
    />
  </div>
</template>

<script>
import InfoButton from '@/components/atoms/buttons/InfoButton.vue';
import MainButton from '@/components/atoms/buttons/MainButton.vue';
import OptionButtons from '@/components/atoms/buttons/OptionButtons.vue';
import TextLink from '@/components/atoms/buttons/TextLink.vue';
import QuestionLabel from '@/components/atoms/labels/QuestionLabel.vue';
import Terms from '@/components/atoms/labels/Terms.vue';
import SvgLogo from '@/components/atoms/logos/SvgLogo.vue';

export default {
  name: 'CallToActions',
  components: {
    MainButton,
    OptionButtons,
    QuestionLabel,
    TextLink,
    Terms,
    InfoButton,
    SvgLogo,
  },
  props: {
    types: {
      type: Array,
      default() {
        return [];
      },
    },
    small: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    activeValue: {
      type: [Boolean, String],
      default: false,
    },
    linkText: {
      type: String,
      default: '',
    },
    linkTextTag: {
      type: String,
      default: '',
    },
    mainButtonText: {
      type: String,
      default: '',
    },
    mainButtonTag: {
      type: String,
      default: '',
    },
    mainButtonPayload: {
      type: String,
      default: '',
    },
    mainButtonTiny: {
      type: Boolean,
      default: false,
    },
    optionText1: {
      type: String,
      default: '',
    },
    optionTextActiveTag: {
      type: String,
      default: '',
    },
    optionText2: {
      type: String,
      default: '',
    },
    optionTextTag: {
      type: String,
      default: '',
    },
    loader: {
      type: Boolean,
      default: false,
    },
    infoButtonsSection: {
      type: Array,
      default() {
        return [];
      },
    },
    svgIcon1: {
      type: String,
      default: '',
    },
    svgIcon2: {
      type: String,
      default: '',
    },
    infoTitleText1: {
      type: String,
      default: '',
    },
    infoTitleText2: {
      type: String,
      default: '',
    },
    time1: {
      type: String,
      default: '',
    },
    time2: {
      type: String,
      default: '',
    },
    infoColorPurple: {
      type: Boolean,
      default: false,
    },
    infoColorCenter: {
      type: Boolean,
      default: false,
    },
    isMainAbsolute: {
      type: Boolean,
      default: false,
    },
    mainActiveLight: {
      type: Boolean,
      default: false,
    },
    widthStretch: {
      type: Boolean,
      default: false,
    },
    infoButtonWithoutTitle: {
      type: Boolean,
      default: false,
    },
    questionText: {
      type: String,
      default: '',
    },
    spaced: {
      type: Boolean,
      default: true,
    },
    optionBtnNoMargin: {
      type: Boolean,
      default: true,
    },
    svgLogo: {
      type: String,
      default: '',
    },
    svgWidth: {
      type: String,
      default: 'auto',
    },
    svgLeftAlign: {
      type: Boolean,
      default: false,
    },
    inSimulation: {
      type: Boolean,
      default: false,
    },
    numberOfOptions: {
      type: Number,
      default: 2,
      validator(value) {
        return value === 1 || value === 2;
      },
    },
    light: {
      type: Boolean,
      default: false,
    },
    inWelcome: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonSection() {
      if (this.inSimulation) {
        const newArray = this.infoButtonsSection.filter((info) => info[0].text !== 'user_register.register_location.option3');
        return newArray;
      }
      return this.infoButtonsSection;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    isActive(i) {
      if (i === 1 && this.optionText1 && !this.light) {
        return true;
      }
      if (i === 2 && this.optionText2 && this.optionText1 === '') {
        return true;
      }
      return false;
    },
  },
};
</script>
