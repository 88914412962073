<template>
  <section class="campus-detail__about__social-media">
    <picture
      v-for="{ icon, url, label } in socialMediaList"
      :key="label"
      class="d-flex flex-row align-center justify-center"
    >
      <img :src="require(`@/assets/icons/${icon}`)" alt="" width="15px" />
      <link rel="alternate" :href="url" />
      <button type="button" class="ml-2 social-media" target="_blank" @click="trackOpenLink(url, label)">
        {{ label }}
      </button>
    </picture>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import trackMixPanel from '@/utils/mixpanel';
import { urlWithHTTP } from '@/utils/strings';

export default {
  name: 'CampusDetailSocialMedia',
  props: {
    contacts: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  metaInfo() {},
  data() {
    return {
      socialMediaList: [],
    };
  },
  computed: {
    ...mapGetters({
      currentStudent: 'authentication/currentStudent',
      campusDetail: 'institutions/campusDetails',
    }),
  },
  mounted() {
    this.contacts.forEach((contact) => {
      const contactLabel = contact.contact_label;
      const icon = this.setIcon(contactLabel.id);
      const url = this.setUrl(contactLabel.id, contact.name, contact.webpage);
      const label = this.setLabel(contactLabel.id, contactLabel.contact_name);

      if (url) {
        const isDuplicate = this.socialMediaList.some((item) => item.id === contactLabel.id);
        if (!isDuplicate) {
          this.socialMediaList.push({ icon, url, label, id: contactLabel.id });
        }
      }
    });
  },
  methods: {
    setIcon(id) {
      const icons = {
        1: 'web.svg',
        7: 'twitter.svg',
        4: 'facebook.svg',
        5: 'instagram.svg',
        6: 'youtube.svg',
      };

      return icons[id] ?? false;
    },
    setLabel(id, label) {
      const labels = {
        1: this.$t('campus-details.social_media.page'),
      };
      return labels[id] ?? label;
    },
    setUrl(id, url, webpage) {
      const types = {
        1: webpage,
        7: webpage,
        4: webpage,
        5: webpage,
        6: webpage,
        // 7: `https://twitter.com/${url.slice(1)}`,
        // 4: `https://facebook.com${url}`,
        // 5: `https://instagram.com${url}`,
        // 6: `https://youtube.com${url}`,
      };
      return types[id] || false;
    },
    trackOpenLink(url, label) {
      let eventName = 'click_school_social_media';
      if (label === 'Página web') {
        eventName = 'click_school_website';
      }
      trackMixPanel(eventName, {
        school_id: this.campusDetail.uuid,
        campus_code: this.campusDetail.campus_code,
        institution_code: this.campusDetail.institution_code,
      });
      const HTTPUrl = urlWithHTTP(url);
      window.open(HTTPUrl, '_blank');
    },
  },
};
</script>
