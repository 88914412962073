const MEASUREMENT_UNIT = Object.freeze({
  MI: 'mi',
  KM: 'km',
});

const TRAVEL_METHOD = Object.freeze({
  WALK: 'WALK',
  CAR: 'CAR',
  BUS: 'BUS',
});

export { MEASUREMENT_UNIT, TRAVEL_METHOD };
