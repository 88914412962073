/**
 *
 * @param {number} x
 * @returns radian value of x
 */
const rad = (x) => (x * Math.PI) / 180;

/**
 *
 * @param {*Position 1{lat, lng}} p1
 * @param {*Position 2{lat, lng}} p2
 * @returns distance between p1 and p2 in meters
 */
const getDistanceInMeters = (p1, p2) => {
  const R = 6378137; // Earth’s mean radius in meter
  /* Auxiliary functions from
   https://stackoverflow.com/questions/1502590/calculate-distance-between-two-points-in-google-maps-v3
   */
  const p1Lat = parseFloat(p1.lat);
  const p2Lat = parseFloat(p2.lat);
  const p1Lng = parseFloat(p1.lng);
  const p2Lng = parseFloat(p2.lng);

  const dLat = rad(p2Lat - p1Lat);
  const dLong = rad(p2Lng - p1Lng);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(rad(p1Lat)) * Math.cos(rad(p2Lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d; // returns the distance in meter
};

/**
 *
 * @param {*Position 1{lat, lng}} p1
 * @param {*Position 2{lat, lng}} p2
 *  * @returns distance between p1 and p2 in feet
 */
const getDistanceInFeet = (p1, p2) => {
  const distance = getDistanceInMeters(p1, p2);
  return (distance * 3.28084);
};

/**
 *
 * @param {*Position 1{lat, lng}} p1
 * @param {*Position 2{lat, lng}} p2
 *  * @returns distance between p1 and p2 in milles
 */
const getDistanceInMiles = (p1, p2) => {
  const distance = getDistanceInMeters(p1, p2);
  return (distance * 0.000621371).toFixed(1);
};

/**
 *
 * @param {*Position 1{lat, lng}} p1
 * @param {*Position 2{lat, lng}} p2
 *  * @returns distance between p1 and p2 in kilometers
 */
const getDistanceInKm = (p1, p2) => {
  const distance = getDistanceInMeters(p1, p2);
  return (distance / 1000);
};

export const kilometersToMiles = (km) => (km * 0.621371).toFixed(1);
export const milesToFeet = (meters) => (meters * 3.28084);

/**
 * @param {*Position 1{lat, lng}} p1 - Position 1
 * @param {*Position 2{lat, lng}} p2 - Position 2
 * @param {string} unit - unit of measurement using SI unit symbols (m, km, ft, mi)
 * @returns distance between p1 and p2 in kilometers or miles, depending of the active tenant (newhaven is imperial)
 */
export const getDistanceBetweenCoordinates = (
  p1 = { lat: null, lng: null },
  p2 = { lat: null, lng: null },
  unit = 'km',
  precision = 1,
) => {
  let distance = 0;
  switch (unit) {
    case 'm':
      distance = getDistanceInMeters(p1, p2);
      break;
    case 'km':
      distance = getDistanceInKm(p1, p2);
      break;
    case 'ft':
      distance = getDistanceInFeet(p1, p2);
      break;
    case 'mi':
      distance = getDistanceInMiles(p1, p2);
      break;
    default:
      distance = getDistanceInKm(p1, p2);
      break;
  }
  return Number(distance.toFixed(precision));
};

/**
 * Utility function to extract coordinates from a location object. Helpful for building payloads based on
 * a more complex location object, and we only need the coordinates.
 *
 * @param {*} locationObject - The location object to extract coordinates from. Should have lat and lng (lon) properties
 * @param {*} format - The format in which the longitude should be fetched. Default is 'lng'
 * @returns {*} - An object with lat and lng properties
 */
export const extractCoordinates = (locationObject, format = 'lng') => {
  if (!locationObject) return null;
  return {
    lat: locationObject.lat,
    lng: locationObject[format],
  };
};
