const getVacancies = ({ campusCode, years = new Set(), schoolsVacancies }) => {
  const hasVacancy = Array.from(years).some((year) => {
    const vacancies = schoolsVacancies[year][campusCode];
    return vacancies === 1;
  });
  return hasVacancy;
};

const validateModalities = ({ highSchoolPrograms, selectedModalities, modalityLabels }) => {
  let matches = false;
  if (selectedModalities.has('TP')) {
    matches = matches
      || highSchoolPrograms.some((program) => modalityLabels.TP.includes(program.gradetrack.modality_label.id));
  }
  if (selectedModalities.has('HC')) {
    matches = matches
      || highSchoolPrograms.some((program) => modalityLabels.HC.includes(program.gradetrack.modality_label.id));
  }
  if (selectedModalities.has('AR')) {
    matches = matches
      || highSchoolPrograms.some((program) => modalityLabels.AR.includes(program.gradetrack.modality_label.id));
  }

  return matches;
};

export { getVacancies, validateModalities };
