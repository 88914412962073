const ADMISSION_STATUS = Object.freeze({
  CREATED: 'CREATED',
  CANCELLED: 'CANCELLED',
  NON_SCHEDULED: 'NON_SCHEDULED',
  SCHEDULED: 'SCHEDULED',
  INTERVIEWING: 'INTERVIEWING',
  WAITING_CONFIRMATION: 'WAITING_CONFIRMATION',
  CONFIRMED: 'CONFIRMED',
  WAITING_SIGNATURE: 'WAITING_SIGNATURE',
  CONTRACT_SIGNED: 'CONTRACT_SIGNED',
  WAITING_PAYMENT: 'WAITING_PAYMENT',
  PAID: 'PAID',
  FILLING_ENROLLMENT_FORM: 'FILLING_ENROLLMENT_FORM',
  ENROLLMENT_FORM_FILLED: 'ENROLLMENT_FORM_FILLED',
  COMPLETED: 'COMPLETED',
  WAITING_FULL_PAYMENT: 'WAITING_FULL_PAYMENT',
  FULLY_PAID: 'FULLY_PAID',
});

export { ADMISSION_STATUS };
