<template>
  <section class="d-flex flex-column w-full pt-1">
    <Partnership
      v-for="Partnership in partnerships"
      :key="Partnership.id"
      class="mb-5"
      :partnership="Partnership"
    />
  </section>
</template>

<script>
import Partnership from '@/components/organisms/campus/Partnership.vue';

export default {
  name: 'MainActivities',
  components: {
    Partnership,
  },
  props: {
    partnerships: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
