<template>
  <section v-if="videoDrone" class="campus-detail__video-drone campus-detail__module mt-0">
    <picture class="d-flex flex-row align-center">
      <img src="@/assets/icons/campus-properties/multimedia/drone.svg" width="25px" alt="Sobrevuelo con dron" />
      <h3
        v-if="!isLinkYoutube"
        class="campus-detail__module__title ml-2"
      >
        {{ $t('campus-details.drone_footage') }}
      </h3>
      <a
        v-else
        :href="videoDrone?.link"
        target="_blank"
        rel="noopener noreferrer"
        class="campus-detail__module__title ml-2"
      >
        {{ $t('campus-details.drone_footage') }}
        <v-icon small color="#1976d2">mdi-open-in-new</v-icon>
      </a>
    </picture>
    <div v-if="!isLinkYoutube" class="mt-4">
      <CampusDetailVideo :id="videoDrone?.id" media :video="videoDrone?.link" fullscreen />
    </div>
  </section>
</template>

<script>
import CampusDetailVideo from '@/components/explorer/general/campus_detail/CampusDetailVideo.vue';

export default {
  name: 'CampusDetailDrone',
  components: {
    CampusDetailVideo,
  },
  props: {
    videoDrone: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isLinkYoutube() {
      return this.videoDrone?.link?.includes('youtu.be');
    },
  },
};
</script>
