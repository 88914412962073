<template>
  <section class="instagram_panel">
    <div class="title-ig">
      <h2>+ {{ len }} {{ $t('campus-details.instagram') }}</h2>
      <div class="at-ig">
        @{{ user_name }}
      </div>
    </div>

    <div>
      <div
        class="portfolio"
        :style="{ '--col': columns, '--mx': maxHeight, '--mxOne': maxOneHeight }"
      >
        <div
          v-for="(ig, index) in photos"
          :key="index"
          :class="index === 0 ? 'portfolio-item-one one' : 'portfolio-item'"
        >
          <img
            v-if="index === photos.length - 1"
            class="img clickable"
            :src="$i18n.locale === 'en'
              ? require('@/assets/icons/instagram-more-en.svg') : require('@/assets/icons/instagram-more.svg')"
            alt="mosaic collection image"
            loading="lazy"
            @click="externalGoTo(user_name)"
            @keydown="externalGoTo(user_name)"
          />

          <img
            v-else
            class="img"
            :src="ig.thumbnail_url ? ig.thumbnail_url : ig.media_url"
            alt="portfolio image"
          />
        </div>
      </div>
    </div>

    <div v-if="has_caption" class="caption-ig">
      {{ caption }}
    </div>
  </section>
</template>

<script>
export default {
  name: 'CampusDetailInstagram',
  props: {
    instagram: {
      type: Array,
      required: false,
      default: () => [],
    },
    user: {
      type: Object,
      required: false,
      default: () => {},
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      len: 0,
      has_caption: false,
      caption: '',
      user_name: '',
      photos: [],
      columns: 5,
      maxHeight: '146px',
      maxOneHeight: '299px',
    };
  },
  watch: {
    isMobile() {
      this.columns = this.isMobile ? 4 : 5;
      this.photos = this.isMobile ? this.instagram.slice(0, 5) : this.instagram.slice(0, 7);
      this.maxHeight = this.isMobile ? '90px' : '146px';
      this.maxOneHeight = this.isMobile ? '190px' : '299px';
      this.user_name = this.user.username;
      this.len = this.instagram.length;
    },
    instagram() {
      this.len = this.instagram.length;
      this.has_caption = this.instagram[0].caption;
      if (this.has_caption) {
        this.caption = this.instagram[0].caption;
      }
      this.photos = this.isMobile ? this.instagram.slice(0, 5) : this.instagram.slice(0, 7);
    },
    user() {
      this.user_name = this.user.username;
    },
  },
  created() {
    this.columns = this.isMobile ? 4 : 5;
    this.has_caption = this.instagram[0].caption;
    if (this.has_caption) {
      this.caption = this.instagram[0].caption;
    }
    this.photos = this.isMobile ? this.instagram.slice(0, 5) : this.instagram.slice(0, 7);
    this.maxHeight = this.isMobile ? '76px' : '146px';
    this.maxOneHeight = this.isMobile ? '160px' : '299px';
    this.user_name = this.user.username;
    this.len = this.instagram.length;
  },
  methods: {
    externalGoTo(user) {
      window.open(`https://www.instagram.com/${user}`, '_blank').focus();
    },
  },
};
</script>
