<template>
  <v-tabs v-model="tab" align-tabs="right">
    <v-tab :value="0" :disabled="disabled[0]" @click="onTabClick(0)">
      {{ currentYear }}
    </v-tab>
    <v-tab :value="1" :disabled="disabled[1]" @click="onTabClick(1)">
      {{ secondYear }}
    </v-tab>
    <v-tab :value="2" :disabled="disabled[2]" @click="onTabClick(2)">
      {{ thirdYear }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: 'TabsComponent',
  props: {
    currentYear: {
      type: Number,
      required: true,
    },
    secondYear: {
      type: Number,
      required: true,
    },
    thirdYear: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Array,
      required: false,
      default: () => [false, false, false],
    },
  },
  data() {
    return {
      tab: this.value,
    };
  },
  watch: {
    tab(newValue) {
      this.$emit('input', newValue);
    },
  },
  methods: {
    onTabClick(index) {
      if (!this.disabled[index]) {
        this.internalTab = index;
      }
    },
  },
};
</script>
