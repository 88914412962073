/**
 * Utils for config file
 */

import { urlWithHTTP } from './strings';

/**
 * Creates an API URL based on the environment and gateway.
 *
 * If `env === 'local'`, it will just replicate the URL given in the .env file.
 *
 * @param {Object} config - Config object
 * @param {String} config.url - URL to be used in the API
 * @param {String} config.env - Environment to be used
 * @param {String} config.tenant - Api Tenant - Specifying this will add the tenant to the URL.
 * @returns {String} - API URL
 *
 * @example
 *
 * parseAPIUrl({
 *  url: 'tether.education/core/',
 *  env: 'staging',
 *  tenant: 'colombia',
 * });
 *
 * // Returns: https://staging.api.tether.education/core/colombia/
 *
 * parseAPIUrl({
 *  url: 'tether.education/core/',
 *  env: 'production',
 *  tenant: null,
 * });
 *
 * // Returns: https://api.tether.education/core/
 */
const parseAPIUrl = ({
  url, env, tenant = null,
  apiProtocol = 'https',
}) => {
  if (env === 'local') {
    return urlWithHTTP(url);
  }
  const protocol = {
    http: 'http://',
    https: 'https://',
    wss: 'wss://',
  };

  const envContext = `${env === 'production' ? '' : 'staging.'}api`;
  const cleanUrl = url.endsWith('/') ? url.slice(0, -1) : url;
  const trueTenant = tenant === 'palmira' ? 'colombia' : tenant;

  return `${protocol[apiProtocol]}${envContext}.${cleanUrl}${trueTenant ? `/${trueTenant}` : ''}`;
};

/**
 * Kong
 * Servicios multi tenant
 * - https://kong-staging.<<>>/{nombre-servicio}/{tenant}/{uri}
 * Servicios single tenant
 * - https://kong-staging.<<>>/{nombre-servicio}/{uri}
 */

export { parseAPIUrl };
