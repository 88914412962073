const extractStagesAndGrades = ({ gradeTracks, sort = false, year }) => {
  if (sort) {
    gradeTracks.sort(
      (a, b) => a.stage_label.id - b.stage_label.id || a.grade_label.id - b.grade_label.id,
    );
  }
  const stages = [];
  const grades = [];
  // Partial stage keeps track of the current stage we are processing
  let partialStage;
  // DummyGradeTrack is used to pad the GradeTrack array to make it easier to iterate over it
  const dummyStage = { stage_label: { id: -1 }, year };
  [...gradeTracks, dummyStage].forEach((gradeTrack) => {
    const { id: gradetrackId, stage_label: stageLabel, grade_label: gradeLabel } = gradeTrack;

    // Check if the gradeTrack's year is equal to year
    if (year && gradeTrack.year !== year) {
      return;
    }

    if (partialStage === undefined) {
      // First iteration of the loop - create a new stage
      gradeLabel.gradetrackId = gradetrackId;
      partialStage = { ...stageLabel, grades: [gradeLabel] };
    } else if (partialStage.id !== stageLabel.id) {
      // Different stage - add the previous stage to the completed list and create a new stage
      stages.push(partialStage);
      partialStage = { ...stageLabel, grades: [] };
    }
    if (gradeLabel) {
      // TODO: Handle specific name labels for grades ("name 1 / name 2")
      // note -> Maybe specific to Chile?
      // Challenging because they are not consistent across the different grade tracks
      // If there is a grade, add it to the current stage
      if (partialStage.grades.find((grade) => grade.id === gradeLabel.id) === undefined) {
        gradeLabel.gradetrackId = gradetrackId;
        partialStage.grades.push(gradeLabel);
      }
      // Add the current stage to the completed lists if it is not there yet
      if (grades.find((grade) => grade.id === gradeLabel.id) === undefined) {
        const gradeWithStage = { ...gradeLabel, stage: stageLabel };
        grades.push(gradeWithStage);
      }
    }
  });
  stages.map((stage) => {
    stage.grades.sort((a, b) => a.order - b.order);
    return stage;
  });
  return { stages, grades };
};

export { extractStagesAndGrades };
