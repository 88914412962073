<template>
  <section
    class="d-flex align-center flex-column contact bg-white"
  >
    <picture v-if="contactSupport" class="d-flex flex-row mt-5 w-100">
      <img width="30" src="@/assets/icons/messageBlue.svg" alt="mensaje" />
      <p
        v-t="'contact_panel.contact.plataform'"
        class="contact_panel__container__header--white--title"
      />
    </picture>

    <!-- Form contact -->
    <v-form
      ref="form"
      v-model="validFormContact"
      lazy-validation
      class="contact__form"
      @submit.prevent="sendContact"
    >
      <template v-if="!successSendMail">
        <!-- Step 1 -->
        <div class="w-full d-flex flex-row align-center mt-6">
          <span class="contact__step contact__step__one"> 1 </span>
          <span v-t="'contact.step_one'" class="contact__title ml-2 contact__asterisk" />
        </div>

        <label
          v-t="'contact.contact_info.first_name.label'"
          class="mt-5 contact__title font-weight-light text-left d-block contact__asterisk"
          for="first_name"
        />
        <v-text-field
          id="first_name"
          v-model="form.contact_info.first_name"
          dense
          outlined
          :rules="errorForm.contact_info.first_name"
          :placeholder="$t('contact.contact_info.first_name.placeholder')"
          class="mt-2"
          color="#2B5BFF"
          :background-color="form.contact_info.first_name ? '#2B5BFF' : '#FFFFFF'"
          type="text"
        />
        <label
          v-t="'contact.contact_info.last_name.label'"
          class="mt-5 contact__title font-weight-light text-left d-block contact__asterisk"
          for="last_name"
        />
        <v-text-field
          id="last_name"
          v-model="form.contact_info.last_name"
          dense
          outlined
          :rules="errorForm.contact_info.last_name"
          :placeholder="$t('contact.contact_info.last_name.placeholder')"
          class="mt-2"
          color="#2B5BFF"
          :background-color="form.contact_info.last_name ? '#2b5bff' : '#FFFFFF'"
          type="text"
        />

        <!-- email -->
        <label
          v-t="'contact.contact_info.email.label'"
          class="contact__title font-weight-light text-left d-block mt-2 contact__asterisk"
          for="email"
        />
        <v-text-field
          id="email"
          v-model="form.contact_info.email"
          dense
          outlined
          :rules="errorForm.contact_info.email"
          :placeholder="$t('contact.contact_info.email.placeholder')"
          class="mt-2"
          color="#2B5BFF"
          :background-color="form.contact_info.email ? '#2b5bff' : '#FFFFFF'"
          type="email"
        />

        <!-- phone -->
        <label
          v-t="'contact.contact_info.phone.label'"
          class="contact__title font-weight-light text-left d-block mt-2"
          for="phone"
        />
        <div class="w-full d-flex flex-row">
          <v-autocomplete
            v-model="form.contact_info.phone_country_code"
            background-color="#2b5bff"
            dense
            outlined
            :items="listCodeArea"
            item-text="dial_code"
            item-value="dial_code"
            class="mt-2"
            :class="isMobile ? 'w-6/12' : 'w-4/12'"
          >
            <template slot="item" slot-scope="{ item }">
              {{ item.dial_code }} {{ item.name_es }}
            </template>
          </v-autocomplete>
          <v-text-field
            id="phone"
            v-model="form.contact_info.phone"
            dense
            outlined
            :placeholder="$t('contact.contact_info.phone.placeholder')"
            class="mt-2 w-11/12 ml-3"
            :class="isMobile ? 'w-6/12' : 'w-8/12'"
            color="#2B5BFF"
            :background-color="form.contact_info.phone ? '#2b5bff' : '#FFFFFF'"
            type="number"
          />
        </div>

        <!-- Step 2 -->
        <template v-if="showStepTwo">
          <div class="w-full d-flex flex-row align-center mt-3 animation-fadeIndDown">
            <span class="contact__step contact__step__two"> 2 </span>
            <span
              v-t="'contact.step_two'"
              class="contact__title contact__width text-left ml-2 contact__asterisk"
            />
          </div>

          <!-- position -->
          <template v-if="contactSupport">
            <p
              v-t="'contact.metadata.position.label'"
              class="mt-5 contact__title font-weight-light text-left d-block contact__asterisk animation-fadeIndDown"
            />
            <div class="w-full d-flex flex-row gap-2 flex-wrap justify-start animation-fadeIndDown">
              <div
                v-for="(position, index) in Object.values(positionOptions)"
                :key="index"
                class="contact__option__radio--button"
              >
                <input
                  :id="position"
                  v-model="form.metadata.position"
                  type="radio"
                  :value="position"
                />
                <label class="mt-2" :for="position">
                  {{ $t(`contact.metadata.position.options.${position}`) }}
                </label>
              </div>
            </div>
          </template>

          <!-- school contact -->
          <template v-else>
            <p
              v-t="'contact.to_email.label'"
              class="mt-5 contact__title font-weight-light text-left d-block contact__asterisk animation-fadeIndDown"
            />
            <div class="w-full d-flex flex-row gap-2 flex-wrap justify-start animation-fadeIndDown">
              <div
                v-for="({ name, email }, index) in listContacts"
                :key="index"
                class="contact__option__radio--button"
              >
                <input
                  :id="name"
                  v-model="form.to_email"
                  type="radio"
                  :value="email"
                />
                <label class="mt-2" :for="name">
                  {{ name }}
                </label>
              </div>
            </div>

            <!-- METADATA -->
            <!-- relation_with_campus -->
            <template v-if="form.to_email">
              <p
                v-t="'contact.metadata.relation_with_campus.label'"
                class="mt-5 contact__title font-weight-light text-left d-block contact__asterisk animation-fadeIndDown"
              />
              <div
                class="w-full d-flex flex-row gap-2 flex-wrap justify-start animation-fadeIndDown"
              >
                <div
                  v-for="(relation_with_campus, index) in Object.values(relationWithCampusOptions)"
                  :key="index"
                  class="contact__option__radio--button"
                >
                  <input
                    :id="relation_with_campus"
                    v-model="form.metadata.relation_with_campus"
                    type="radio"
                    :value="relation_with_campus"
                  />
                  <label class="mt-2" :for="relation_with_campus">
                    {{ $t(`contact.metadata.relation_with_campus.options.${relation_with_campus}`) }}
                  </label>
                </div>
              </div>
            </template>

            <!-- self_identification -->
            <template v-if="form.metadata.relation_with_campus">
              <p
                v-t="'contact.metadata.self_identification.label'"
                class="mt-5 contact__title font-weight-light text-left d-block contact__asterisk"
              />
              <div
                class="w-full d-flex flex-row gap-2 flex-wrap justify-start"
              >
                <div
                  v-for="(self_identification, index) in $t(
                    form.metadata.relation_with_campus === relationWithCampusOptions.INTERNAL
                      ? Object.values(internalSelfIdentificationsOptions)
                      : Object.values(externalSelfIdentificationsOptions),
                  )"
                  :key="index"
                  class="contact__option__radio--button"
                >
                  <input
                    :id="self_identification"
                    v-model="form.metadata.self_identification"
                    type="radio"
                    :value="self_identification"
                  />
                  <label class="mt-2" :for="self_identification">
                    {{ $t('contact.metadata.self_identification.options.'
                      + form.metadata.relation_with_campus + '.' + self_identification) }}
                  </label>
                </div>
              </div>
            </template>
          </template>
        </template>

        <!-- Step 3 -->
        <template v-if="showStepThree">
          <div class="w-full d-flex flex-row align-center mt-8">
            <span class="contact__step contact__step__three"> 3 </span>
            <span
              v-t="'contact.step_three'"
              class="contact__title contact__width text-left ml-2 contact__asterisk"
            />
          </div>

          <!-- title message -->
          <p
            v-t="'contact.message.title.label'"
            class="mt-5 contact__title font-weight-light text-left d-block contact__asterisk"
          />
          <div class="w-full d-flex flex-row gap-2 flex-wrap justify-start">
            <div
              v-for="(titleOption, index) in getMessageTitles"
              :key="index"
              class="contact__option__radio--button contact__option__radio--button--purple"
            >
              <input
                :id="titleOption"
                v-model="form.message.title"
                type="radio"
                :value="titleOption"
              />
              <label class="mt-2" :for="titleOption">
                {{ $t(`contact.message.title.options.${titleOption}`) }}
              </label>
            </div>
          </div>

          <!-- Personalize message -->
          <label
            v-t="'contact.message.body.label'"
            class="mt-7 contact__title font-weight-light text-left d-block"
            for="large message"
          />
          <textarea
            id="large message"
            v-model="form.message.body"
            class="contact__text-area mt-3"
            :placeholder="$t('contact.message.body.placeholder')"
          />

          <!-- button send message -->
          <div class="d-flex w-full justify-between">
            <!-- terms -->
            <p
              v-t="'contact.terms'"
              class="my-3 contact__title contact__disclaimer font-medium text-left w-2/3"
            />
            <button class="mt-7 mb-6 contact__send" type="submit" :disabled="loading">
              <span v-if="!loading" v-t="'contact.send'" />
              <v-progress-circular v-else indeterminate color="white" />
            </button>
          </div>
        </template>
      </template>
      <!-- Check message -->
      <section v-else>
        <div class="contact__sucess-email">
          <v-icon large color="white">
            mdi-check
          </v-icon>
          <p v-t="'contact.message_sent'" />
        </div>
        <button class="contact__another_email" type="button" @click="sendAnotherMessage()">
          <v-icon size="25" color="white">
            mdi-reload
          </v-icon>
          {{ $t('contact.send_another_message') }}
        </button>
      </section>
    </v-form>
  </section>
</template>

<script>
import CONFIG from '@/config';
import listCodeArea from '@/locales/code_area.json';
import trackMixPanel from '@/utils/mixpanel';
import {
  POSITION_OPTIONS, CONTACT_SUPPORT_ADMIN_TEACHER_MESSAGE_TITLES, CONTACT_SUPPORT_MESSAGE_TITLES,
  SIMPLE_MESSAGE_TITLES, RELATION_WITH_CAMPUS_OPTIONS, EXTERNAL_SELF_IDENTIFICATIONS_OPTIONS,
  OTHER_MESSAGE_TITLES, INTERNAL_SELF_IDENTIFICATIONS_OPTIONS,
} from '@/constants/contact';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ContactModal',
  props: {
    contactSupport: {
      type: Boolean,
      required: false,
      default: false,
    },
    contacts: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      relationWithCampusOptions: RELATION_WITH_CAMPUS_OPTIONS,
      internalSelfIdentificationsOptions: INTERNAL_SELF_IDENTIFICATIONS_OPTIONS,
      externalSelfIdentificationsOptions: EXTERNAL_SELF_IDENTIFICATIONS_OPTIONS,
      positionOptions: POSITION_OPTIONS,
      validFormContact: false,
      loading: false,
      successSendMail: false,
      form: {
        contact_info: {
          first_name: undefined,
          last_name: undefined,
          email: undefined,
          phone_country_code: this.$t('user_register.contact.default_dial_code'),
          phone: undefined,
        },
        message: {
          type: undefined,
          title: undefined,
          body: undefined,
        },
        channel: 'explorer',
        metadata: {
          relation_with_campus: undefined,
          self_identification: undefined,
          position: undefined,
        },
        to_email: undefined,
        country: CONFIG.tenant,
      },
      errorForm: {
        contact_info: {
          first_name: [(v) => !!v || this.$t('contact.required_field')],
          last_name: [(v) => !!v || this.$t('contact.required_field')],
          email: [
            (v) => !!v || this.$t('contact.required_field'),
            (v) => /.+@.+/.test(v) || this.$t('contact.invalid_mail'),
          ],
          phone_country_code: [(v) => !!v || this.$t('contact.required_field')],
          phone: [(v) => !!v || this.$t('contact.required_field')],
        },
        message: {
          title: [(v) => !!v || this.$t('contact.required_field')],
          body: [(v) => !!v || this.$t('contact.required_field')],
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'institutions/campusDetails',
    }),
    listCodeArea() {
      return listCodeArea.countries;
    },
    showStepTwo() {
      return !!this.form.contact_info.first_name
        && !!this.form.contact_info.last_name
        && !!this.form.contact_info.email;
    },
    showStepThree() {
      if (this.contactSupport) {
        return !!this.form.metadata.position;
      }
      return this.form.to_email && this.form.metadata.relation_with_campus;
    },
    getMessageTitles() {
      // messages with contact platform
      if (this.contactSupport) {
        const isModifyChangeDigitalProfile = this.form.message.position === POSITION_OPTIONS.ADMINISTRATIVE
        || this.form.message.position === POSITION_OPTIONS.TEACHER;
        return isModifyChangeDigitalProfile
          ? Object.values(CONTACT_SUPPORT_ADMIN_TEACHER_MESSAGE_TITLES)
          : Object.values(CONTACT_SUPPORT_MESSAGE_TITLES);
      }

      // messages with contact institution
      const titles = Object.values(SIMPLE_MESSAGE_TITLES);
      const isExternal = this.form.metadata.relation_with_campus === RELATION_WITH_CAMPUS_OPTIONS.EXTERNAL;
      if (isExternal) {
        switch (this.form.metadata.self_identification) {
          case EXTERNAL_SELF_IDENTIFICATIONS_OPTIONS.INTERESTED_FAMILY:
            titles.shift();
            titles.unshift(OTHER_MESSAGE_TITLES.APPLY_TO_CAMPUS);
            break;
          case EXTERNAL_SELF_IDENTIFICATIONS_OPTIONS.SERVICE_PROVIDER:
            titles.shift();
            titles.unshift(OTHER_MESSAGE_TITLES.OFFER_MY_SERVICES);
            break;
          case EXTERNAL_SELF_IDENTIFICATIONS_OPTIONS.JOB_SEEKER:
            titles.shift();
            titles.unshift(OTHER_MESSAGE_TITLES.FIND_JOB);
            break;
          default:
            break;
        }
      }

      return titles;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    listContacts() {
      const list = [];

      if (this.contactSupport) {
        const name = this.$t('contact.plataform_administrators');
        const email = this.mailPlatform;

        list.push({ name, email });
      } else {
        // eslint-disable-next-line
        for (const { email, contact_label } of this.contacts) {
          // eslint-disable-next-line
          const name = contact_label.contact_name;
          // eslint-disable-next-line camelcase
          const { id } = contact_label;
          const hasEmailContact = id < 5 && email;

          if (hasEmailContact) {
            list.push({ name, email });
          }
        }
      }

      return list;
    },
    mailPlatform() {
      return `${CONFIG.tenant}@explorador.com`;
    },
    mailTo() {
      if (CONFIG.environment !== 'production') {
        return this.form.mail_from;
      }
      if (this.contactSupport) {
        return this.mailPlatform;
      }
      return this.selectedContact;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.metadata.relation_with_campus': function () {
      this.form.metadata.self_identification = null;
    },
  },
  mounted() {
    this.$emit('setResetModal');
  },
  methods: {
    ...mapActions({
      sendCustomerSupportMessage: 'messaging/sendCustomerSupportMessage',
      sendLeadMessage: 'messaging/sendLeadMessage',
      errorSnackbar: 'utils/error',
      successSnackbar: 'utils/success',
    }),
    sendContact() {
      const campusCode = this.campusDetail.campus_code;
      trackMixPanel('app_send_message', {
        school_id: this.campusDetail.uuid,
        campus_code: campusCode,
        institution_code: this.campusDetail.institution_code,
        name: this.campusDetail.campus_name,
      });

      this.loading = true;
      this.form.campus_code = campusCode;
      this.form.to_email = this.mailTo;
      this.form.message.type = this.form.message.title;
      this.form.message.title = this.$t(`contact.message.title.options.${this.form.message.type}`);
      this.form.self_identification = this.contactSupport ? this.form.metadata.position
        : this.form.metadata.self_identification;
      const { form } = this;
      if (this.contactSupport) {
        this.sendCustomerSupportMessage({
          form,
          callback: () => {
            this.loading = false;
            this.successSnackbar(this.$t('contact.snackbar.success'));
          },
          callbackError: () => {
            this.loading = false;
            this.errorSnackbar(this.$t('contact.snackbar.error'));
          },
        });
      } else {
        this.sendLeadMessage({
          form,
          callback: () => {
            this.loading = false;
            this.successSnackbar(this.$t('contact.snackbar.success'));
          },
          callbackError: () => {
            this.loading = false;
            this.errorSnackbar(this.$t('contact.snackbar.error'));
          },
        });
      }
    },
    sendAnotherMessage() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$emit('resetForm');
    },
  },
};
</script>
