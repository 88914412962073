var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-main register-main--spaced"},[_c('div',{staticClass:"fill-width"},[_c('ModalHeader',{attrs:{"content-sections":['actions', 'logo'],"actions-tag":_vm.registerType === 1
        ? 'click_reg_quick-register-student-birthday_back-button'
        : 'click_reg_detailed-register-student-birthday_back-button',"inline-logo":"","small-logo":""},on:{"back":function($event){return _vm.$emit('backStep')}}}),_c('RowStepper',{attrs:{"mixpanel-tag":_vm.registerType === 1
        ? 'click_reg_quick-register-student-birthday_row-stepper'
        : 'click_reg_detailed-register-student-birthday_row-stepper',"step":_vm.inStudentFlow ? 2 : 1}}),_c('ModalContent',{class:_vm.inWaitingList && !_vm.mobile ? 'deg-waiting-list--mobile' : '',attrs:{"content-sections":['smallTitle', 'subtitle', 'numberSelect'],"subtitle-text":'user_register.date_of_birth.subtitle',"subtitle-arg":Object.keys(_vm.currentStudent).length > 0 ? _vm.currentStudent.first_name : '',"small-title-text":_vm.inStudentFlow
          ? 'user_register.identification.student_title'
          : 'user_register.identification.title',"small-title-left":"","number-slot-content":_vm.setNumberSlotContent(),"number-info-error":_vm.noInfoError},on:{"setValue":_vm.setValue}})],1),_c('CallToActions',{attrs:{"types":['main'],"active-value":_vm.isActive,"main-button-text":'user_register.continue',"main-button-tag":_vm.registerType === 1
      ? 'click_reg_quick-register-student-birthday_continue-button'
      : 'click_reg_detailed-register-student-birthday_continue-button',"small":"","spaced":false,"loader":_vm.dateLoader},on:{"continue":function($event){return _vm.nextStep()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }