var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},[_c('div',{staticClass:"dialog",class:{
      'dialog--mobile': _vm.mobile,
      'dialog--loading': _vm.loading,
    }},[(_vm.loading)?_c('LoaderCircular',{attrs:{"show":_vm.loading,"size":60,"width":8}}):_c('section',[(_vm.title)?_c('div',{staticClass:"dialog__title"},[_vm._t("title",function(){return [_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")]},{"title":_vm.title})],2):_vm._e(),(_vm.warning)?_c('div',{staticClass:"dialog__warning"},[_vm._t("warning",function(){return [_c('MDIIcon',{attrs:{"icon":"mdi-alert-outline","color":"#e94c6e"}}),_vm._v(" "+_vm._s(_vm.$t(_vm.warning))+" ")]},{"warning":_vm.warning})],2):_vm._e(),(_vm.subtitle)?_c('div',{staticClass:"dialog__subtitle"},[_vm._t("subtitle",function(){return [_vm._v(" "+_vm._s(_vm.$t(_vm.subtitle))+" ")]},{"subtitle":_vm.subtitle})],2):_vm._e(),(_vm.infoToConfirm)?_c('div',{directives:[{name:"t",rawName:"v-t",value:(_vm.infoToConfirm),expression:"infoToConfirm"}],staticClass:"dialog__info"},[_vm._t("info",function(){return [_vm._v(" "+_vm._s(_vm.$t(_vm.infoToConfirm))+" ")]},{"infoToConfirm":_vm.infoToConfirm})],2):_vm._e(),_vm._l((_vm.options),function(option,index){return _c('div',{key:index},[_c('v-btn',{staticClass:"costs-category-box",style:({
            'background-color': _vm.selected === index ? '#DDD4FF !important' : '#ddd4ff41 !important',
            height: '50px !important',
            border: '2px solid #DDD4FF !important',
            'border-radius': '20px !important',
          }),on:{"click":function($event){return _vm.setSelection(index)}}},[_c('div',[_c('TextAtom',{attrs:{"value":_vm.getDisplayText(option),"tag":"p","font":"inter","weight":"400","color":"primary-dark","size":"normal"}})],1)])],1)}),_c('div',{staticClass:"dialog__actions w-100",class:{
          'dialog__actions--mobile': _vm.mobile,
          'dialog__actions--single': !_vm.mobile && (_vm.hideCancelButton || _vm.hideConfirmButton),
        }},[_vm._t("actions",function(){return [(_vm.showCancelButton)?_c('OptionButtons',{staticClass:"dialog__actions__button mx-1",attrs:{"text":_vm.cancelButtonText || 'dialog.confirm.no',"no-margin":""},on:{"nextStep":_vm.cancel}}):_vm._e(),(!_vm.hideConfirmButton)?_c('OptionButtons',{staticClass:"dialog__actions__button mx-1",attrs:{"text":_vm.confirmButtonText || (_vm.showCancelButton ? 'dialog.confirm.yes' : 'dialog.confirm.acknowledged'),"disabled":_vm.selected === -1,"is-active":"","no-margin":""},on:{"nextStep":_vm.confirm}}):_vm._e()]})],2)],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }