import { TRAVEL_METHOD } from '@/constants/general';
import { TRAVEL_TIME_LABELS } from '@/constants/labels';
import { getI18nPropertyCategoryText } from '@/utils/locale';
import { buildCategoryObject, buildNoInfoCategoryObject } from './shared';

// Travel Times

const findTravelTimeLabel = ({ maxTime, mode }) => (
  Object.values(TRAVEL_TIME_LABELS).find(
    ({ UPPER_BOUND }) => maxTime <= UPPER_BOUND[mode],
  )
);

/**
 * Get the travel mode from a travel time property key
 * @param {string} propertyKey - The property key
 * @returns {string} - The travel method key
 * @example
 *
 * const propertyKey = 'WALK_TIME';
 * const travelMode = getTravelModeFromTravelTimePropertyKey(propertyKey);
 * // travelMode === 'WALK'
 */
const getTravelModeFromTravelTimePropertyKey = (propertyKey) => {
  const re = new RegExp(`^(${Object.values(TRAVEL_METHOD).join('|')})_TIME$`);
  return propertyKey.match(re)?.[1];
};

/**
 * Get the category of a campus travel time property based on the campus data and the tenant
 * @param {*} travelTimeData - The travel time data, obtained from geotools
 * @returns {PropertyCategory} - The category of the travel time property
 */
const getCampusTravelTimeCategory = (propertyKey, travelTimeData) => {
  const rootPropertyKey = 'TRAVEL_TIME';
  const travelMethod = getTravelModeFromTravelTimePropertyKey(propertyKey);
  const { maxTime, rangeLabel: label, mode } = travelTimeData ?? {};
  const title = getI18nPropertyCategoryText(`map.card.properties.${rootPropertyKey}.title`, travelMethod);
  if (!maxTime) return buildNoInfoCategoryObject(rootPropertyKey, { title });
  const tooltip = getI18nPropertyCategoryText(`map.card.properties.${rootPropertyKey}.tooltip`, travelMethod);
  const { TETHER_CATEGORY: categoryKey } = findTravelTimeLabel({ maxTime, mode }) || {};
  const icon = travelMethod;
  return buildCategoryObject(
    propertyKey,
    categoryKey,
    {
      label, icon, title, tooltip,
    },
  );
};

export {
  getCampusTravelTimeCategory,
  getTravelModeFromTravelTimePropertyKey,
  findTravelTimeLabel,
};
