<template>
  <div
    v-if="hideAlert(passwordConditions)"
    class="register-main flex-row justify-start"
    style="margin-left: 14px"
  >
    <img src="@/assets/icons/validation-warning.svg" alt="validation" />
    <section class="d-flex flex-wrap fill-width" style="margin-left: 14px">
      <div v-for="(error, index) in passwordConditions" :key="index">
        <div
          v-if="showText(error)"
          class="register-main--password-errors"
          :class="{
            'register-main--password-errors--success': error.active,
          }"
        >
          {{ error.text }}
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import utils from '@/utils/';
import { minLength, required } from 'vuelidate/lib/validators';

export default {
  name: 'PasswordValidation',
  components: {},
  props: {
    password: {
      type: String,
      default: '',
    },
  },
  validations: {
    password: {
      required,
      minLength(password) {
        return minLength(8)(password);
      },
      hasNumbers(password) {
        return utils.checkAtLeastOneNumber(password);
      },
      hasLowercaseLetter(password) {
        return utils.checkLowerCases(password);
      },
      hasUppercaseLetter(password) {
        return utils.checkUpperCases(password);
      },
    },
  },
  computed: {
    passwordConditions() {
      const errors = [];
      let passwordInputFilled = false;
      if (this.password !== '') {
        passwordInputFilled = true;
      }
      let hasMinLength = false;
      if (this.password !== '') {
        hasMinLength = this.$v.password.minLength;
      }
      errors.push({
        text: this.$t('user_register.register_data.error.password.min_length'),
        active: hasMinLength,
        filledInput: passwordInputFilled,
      });
      errors.push({
        text: this.$t('user_register.register_data.error.password.has_number'),
        active: this.$v.password.hasNumbers,
        filledInput: passwordInputFilled,
      });
      const upperCaseValidation = this.$v.password.hasUppercaseLetter;
      const lowerCaseValidation = this.$v.password.hasLowercaseLetter;
      errors.push({
        text: this.$t('user_register.register_data.error.password.has_uppercase'),
        active: upperCaseValidation,
        filledInput: passwordInputFilled,
      });
      errors.push({
        text: this.$t('user_register.register_data.error.password.has_lowercase'),
        active: lowerCaseValidation,
        filledInput: passwordInputFilled,
      });
      return errors;
    },
  },
  methods: {
    showText(error) {
      if (error.active === false) {
        return true;
      }
      return false;
    },
    hideAlert(passwordConditions) {
      let hasErrors = 0;
      passwordConditions.forEach((error) => {
        if (error.active) {
          hasErrors += 1;
        }
      });
      if (hasErrors === 4) {
        return false;
      }
      return true;
    },
  },
};
</script>
