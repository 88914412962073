<template>
  <PropertiesGrid
    :campus="campus"
    :property-keys="enabledPropertyKeys"
    :type-configuration="typeConfiguration"
    :default-data="defaultData"
    :location="location"
    :abort-previous="false"
  />
</template>

<script>
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import PropertiesGrid from '@/components/organisms/campus/properties/PropertiesGrid.vue';

export default {
  name: 'CampusCategories',
  components: {
    PropertiesGrid,
  },
  props: {
    campus: {
      type: Object,
      default: () => {},
    },
    typeConfiguration: {
      type: String,
      default: '',
    },
    defaultData: {
      type: Object,
      default: () => ({}),
    },
    location: {
      type: Object,
      validate: (location) => {
        if (!location || (location.lat && location.lng)) return true;
        return false;
      },
      default: () => undefined,
    },
  },
  data() {
    return {
      enabledPropertyKeys: TENANT_CONFIGURATION.SETTINGS.CARD.PROPERTIES,
    };
  },
};
</script>
