import jwtDecode from 'jwt-decode';
import Vue from 'vue';
import CONFIG from '@/config';
import store from '../store/index';
/*
Distinct_id = UserID
always lg, guest and student info
*/
function parseData(data) {
  const token = store.getters['authentication/loggedToken'];
  let userId = null;
  if (token) {
    userId = jwtDecode(token).user_id;
  }
  const legalGuardianUUID = store.getters['authentication/legalGuardianUUID'];
  const sessionUUID = store.getters['authentication/sessionUUID'];
  const isGuest = store.getters['authentication/isGuest'];
  const currentStudent = store.getters['authentication/currentStudent'];
  let mainStudent;
  if (Object.keys(currentStudent).length === 0) {
    if (isGuest) {
      mainStudent = 'guestUser';
    } else {
      mainStudent = 'no-student-selected';
    }
  } else {
    mainStudent = currentStudent.uuid;
  }
  const mixData = {};
  mixData.env = CONFIG.environment;
  mixData.distinct_id = userId;
  mixData.legalGuardianUuid = legalGuardianUUID;
  mixData.userUuid = sessionUUID;
  mixData.guest = isGuest;
  mixData.student = mainStudent;
  const mixedData = { ...mixData, ...data };
  return mixedData;
}

function trackMixPanel(tag, data) {
  if (CONFIG.environment === 'development') {
    // eslint-disable-next-line no-console
    console.debug(`MixpanelTag(${tag})`, data);
  } else {
    const mixpanel = Vue.prototype.$mixpanel;
    // !! ENABLED ONLY FOR PRODUCTION !!
    // if (CONFIG.environment === 'production') {
    const myData = parseData(data);
    mixpanel.track(tag, myData);
  }
}

export default trackMixPanel;
