<template>
  <div class="search-location--title">
    <h3
      v-t="text"
      class="search-location--title--text"
      :style="{ background: color }"
      @click="mapTitleClick()"
      @keydown="/* TODO */"
    />
  </div>
</template>

<script>
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'MapTitle',
  components: {},
  props: {
    text: {
      type: String,
      default: '',
    },
    mixpanelTag: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: '#5627FF',
    },
  },
  methods: {
    mapTitleClick() {
      if (this.mixpanelTag) {
        trackMixPanel(this.mixpanelTag);
      }
    },
  },
};
</script>
