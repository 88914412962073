<template>
  <div class="register-main register-main--spaced">
    <div>
      <ModalHeader :content-sections="['close']" @close="$emit('close')" />
      <ModalContent
        :content-sections="['subtitle', 'icon']"
        subtitle-center
        :subtitle-text="'modals.no_info_warn_modal.title'"
        :icon-color="'#00101A'"
        :icon-name="'mdi-alert-outline'"
        :icon-type="'warning'"
      />
      <ModalContent
        :content-sections="['subtitle']"
        :subtitle-text="
          hasLocation
            ? 'modals.no_info_warn_modal.with-location-text'
            : 'modals.no_info_warn_modal.content-text'
        "
        subtitle-light
      />
    </div>
    <CallToActions
      :option-text1="'modals.no_info_warn_modal.btn1'"
      :option-text2="'modals.no_info_warn_modal.btn2'"
      :types="['option']"
      :spaced="false"
      option-btn-no-margin
      @nextStep="nextStep"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';

export default {
  name: 'NoInfoWarningModal',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
  },
  props: {
    hasLocation: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      setProfileStep: 'userRegister/setProfileStep',
    }),
    nextStep(i) {
      if (i === 1) {
        if (!this.hasLocation) {
          const stepInfo = { for: 'lg', step: 0 };
          this.setProfileStep({ step: stepInfo }).then(() => {
            this.$router.push('/dashboard');
            this.$emit('close');
          });
          return stepInfo;
        }
        const stepInfo = { for: 'student', step: 1 };
        this.setProfileStep({ step: stepInfo }).then(() => {
          this.$router.push('/dashboard');
          return this.$emit('close');
        });
      }
      return this.$emit('close');
    },
  },
};
</script>
