<template>
  <v-progress-circular
    v-if="show"
    class="d-flex align-center justify-center"
    :size="size"
    indeterminate
    :color="color"
    :width="width"
  />
</template>

<script>
export default {
  name: 'LoaderCircular',
  components: {},
  props: {
    size: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: '#5627ff',
    },
    show: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 4,
    },
  },
};
</script>
