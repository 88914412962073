var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isActive)?_c('ConfirmationDialog',{attrs:{"title":_vm.titleKey,"loading":_vm.loading,"subtitle":_vm.hasSuccessfulAttempt
      ? null : (
        {
          path: _vm.subtitleKey,
          args: {
            description: _vm.payment.name,
          },
        }),"warning":_vm.warningKey,"info-to-confirm":(_vm.hasSuccessfulAttempt || _vm.paymentFlags.payAtInstitution)
      ? null
      : _vm.amountDisplayName(_vm.amountShown, _vm.payment.currency),"hide-cancel-button":!_vm.hasSuccessfulAttempt && !_vm.paymentFlags.payAtInstitution,"hide-confirm-button":_vm.hasSuccessfulAttempt || _vm.paymentFlags.payAtInstitution,"confirm-button-text":!_vm.hasSuccessfulAttempt ? 'payments.confirm.yes' : null,"cancel-button-text":_vm.paymentFlags.payAtInstitution ? 'payments.confirm.return' : null,"value":""},on:{"confirm":_vm.confirm,"cancel":_vm.cancel}}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }