import CONFIG from '@/config';
/**
 * Checks if the student has a valid national identification. This check depends on the tenant.
 * FIXME: this seems very hardcoded and should be changed to a more generic solution.
 * @param {object} student
 * @returns {boolean}
 */
const hasValidIdentificationType = (student) => {
  // For colombia, there is the possibility of not having an identification.]
  const tenantBypassIds = {
    colombia: [10], // national_identification_type_id for "Sin Documento"
    palmira: [10], // national_identification_type_id for "Sin Documento" (same as colombia)
  };
  const bypassIDs = tenantBypassIds[process.env.VUE_APP_TENANT] ?? [];
  return bypassIDs.includes(student.national_identification_type_id) || student.national_identification_number;
};

/**
 * Checks a student's nationality to see if it is valid for applications.
 * @param {object} student
 * @returns {boolean}
 */
const hasValidNationality = (student) => {
  const tenantNationalityID = 1; // Seems to be the same for all tenants
  return student.identification_type.nationality.id !== tenantNationalityID
    || (student.identification_type.nationality.id === tenantNationalityID && student.birth_location);
};

/**
 * Checks if a student has a detailed address. A detailed address is one that has a street, number, and commune.
 * @param {object} student - The student to check.
 * @returns {boolean} - True if the student has a detailed address, false otherwise.
 */
const hasDetailedAddress = (student) => (
  student.address
  && student.address.length > 0
  && student.address[0].address_street
  && student.address[0].address_number
  && student.address[0].location
);

/**
 * Checks if a student has completed the registration process.
 * @param {object} student
 * @returns {boolean}
 */
const studentHasCompletedRegistration = (student) => (
  student.personalized_name
  && student.identification_type
  && hasValidIdentificationType(student)
  && student.first_name
  && student.first_lastname
  && student.other_lastname
  && student.gender
  && student.relationship
  && student.birth_date
  && student.grade
  && hasValidNationality(student)
  && student.same_location !== null
);

/**
 * Checks if a student can apply through the platform.
 * @param {object} student
 * @returns {boolean}
 */
const studentCanApply = (student) => (
  hasValidIdentificationType(student)
  && student.same_location !== null
);

/**
 * Checks if a student can simulate an admission risk.
 * This is needed, because in some tenants, the student must have certain fields filled in order to simulate.
 * @param {object} student - The student to check.
 * @returns {boolean} - True if the student can simulate, false otherwise.
 */
const studentCanSimulateRisk = (student) => {
  const hasId = !!student.id;
  if (CONFIG.tenant === 'newhaven') {
    return hasId && hasDetailedAddress(student);
  }
  return hasId;
};

export {
  studentHasCompletedRegistration,
  studentCanApply,
  studentCanSimulateRisk,
};
