<template>
  <div class="register-main register-main--spaced">
    <div>
      <HeaderModal :content-sections="['close']" @close="$emit('close')" />
      <SvgIcon
        :icon="inWaitingList ? 'warning-confirm.svg' : 'check-confirm.svg'"
        :size="'50'"
        style="margin-left: auto; margin-right: auto;"
      />
      <SmallTitle
        :text="inWaitingList ? 'digital_enrollment.waiting-list.confirmation.title_warning'
          : 'digital_enrollment.waiting-list.confirmation.title'"
        primary
      />
      <div v-if="waitingListType !== 'PRIVATE'" class="deg-waiting-list--confirm__purple">
        <TextAtom
          :value="studentName"
          class="deg-waiting-list--confirm__name"
          font="inter"
        />
        <p
          v-t="{
            path: 'digital_enrollment.waiting-list.confirmation.position',
            args: { pos: position },
          }"
          class="deg-waiting-list--confirm__position"
        />
        <TextAtom
          :value="$t('digital_enrollment.waiting-list.confirmation.in-list')"
          size="subtitle"
        />
      </div>
      <div v-if="waitingListType !== 'PRIVATE'" class="deg-waiting-list--confirm__purple">
        <TextAtom
          :value="campusDetail.campus_name"
          font="inter"
          weight="700"
          size="subtitle"
        />
        <TextAtom
          :value="shift.gradeName + ' - ' + shift.name"
          size="subtitle"
          class="mt-2"
        />
      </div>
      <ContentModal
        class="mt-16"
        :content-sections="['subtitle']"
        subtitle-center
        :subtitle-arg="studentName"
        :subtitle-text="'digital_enrollment.waiting-list.confirmation.information'"
        subtitle-light
      />
    </div>
    <CallToActions
      v-if="!error && waitingListType !== 'PRIVATE'"
      :main-button-text="'digital_enrollment.waiting-list.confirmation.button'"
      :types="['main']"
      active-value
      :spaced="false"
      option-btn-no-margin
      @continue="$emit('next', position)"
    />
    <CallToActions
      v-if="waitingListType === 'PRIVATE'"
      :main-button-text="'digital_enrollment.waiting-list.list.button-profile'"
      :types="['main']"
      active-value
      :spaced="false"
      option-btn-no-margin
      @continue="$emit('showCampus')"
    />
  </div>
</template>

<script>
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import SmallTitle from '@/components/atoms/titles/SmallTitle.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ContentModal from '@/components/molecules/modals/Content.vue';
import HeaderModal from '@/components/molecules/modals/Header.vue';
import CONFIG from '@/config';
import publicRecordsService from '@/services/publicRecords.service';

import { mapGetters } from 'vuex';

export default {
  name: 'WaitingListConfirm',
  components: {
    HeaderModal,
    CallToActions,
    ContentModal,
    SvgIcon,
    SmallTitle,
    TextAtom,
  },
  props: {
    shift: {
      type: Object,
      default: () => { },
    },
    inWaitingList: {
      type: Boolean,
      default: false,
    },
    programId: {
      type: [Number, String],
      default: 0,
    },
    waitingListType: {
      type: String,
      default: 'PUBLIC',
    },
  },
  data() {
    return {
      studentName: '',
      position: 0,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'institutions/campusDetails',
      legalGuardian: 'authentication/legalGuardian',
      currentStudent: 'authentication/currentStudent',
      contactPreferences: 'userRegister/contactPreferences',
    }),
  },
  mounted() {
    const applicant = this.currentStudent.uuid;
    this.studentName = this.currentStudent.first_name;
    publicRecordsService.getWaitingList({
      tenant: CONFIG.tenant,
      campusCode: this.campusDetail.campus_code,
      programId: this.programId,
    }).then((response) => {
      const responseData = response.data;
      responseData.findIndex((item, index) => {
        if (item.applicant.id === applicant) {
          this.position = index + 1;
          return true;
        }
        return false;
      });
    });
  },
};
</script>
