var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-main register-main--spaced",class:{
    'justify-center':
      _vm.preCheckStatus === null || (_vm.preCheckStatus.found && _vm.preCheckStatus.is_verified),
  }},[(_vm.preCheckStatus === null || (_vm.preCheckStatus.found && _vm.preCheckStatus.is_verified))?_c('div',{staticClass:"fill-width d-flex justify-center align-center"},[(_vm.preCheckStatus === null || _vm.preCheckStatus.found)?_c('LoaderCircular',{attrs:{"size":80,"color":'#1a0c4c',"show":true}}):_vm._e()],1):_vm._e(),(
      (_vm.preCheckStatus !== null && !_vm.preCheckStatus.found)
        || (_vm.preCheckStatus.found && !_vm.preCheckStatus.is_verified)
    )?_c('div',{staticClass:"fill-width d-flex flex-column justify-center align-center"},[_c('ModalHeader',{attrs:{"content-sections":['actions', 'title', 'logo'],"actions-tag":'click_reg_verification-code_back-button',"title-text":'user_register.register_code.title',"small-logo":""},on:{"back":function($event){return _vm.$emit('backStep')}}}),_c('ModalContent',{class:_vm.inWaitingList && !_vm.mobile ? 'deg-waiting-list--mobile' : '',attrs:{"content-sections":['subtitle', 'verification', 'question', 'link'],"subtitle-text":_vm.userPhone
          ? 'user_register.register_code.phone-subtitle'
          : 'user_register.register_code.subtitle',"subtitle-center":_vm.inWaitingList,"verification-no-info-error":_vm.noInfoError,"question-text":'user_register.register_code.resend-text',"link-text":'user_register.register_code.resend-link',"link-text-tag":'click_reg_verification-code_resend-textlink'},on:{"setCode":_vm.setCode,"toLink":function($event){return _vm.resendCode()}}})],1):_vm._e(),(
      (_vm.preCheckStatus !== null && !_vm.preCheckStatus.found)
        || (_vm.preCheckStatus.found && !_vm.preCheckStatus.is_verified)
    )?_c('CallToActions',{class:_vm.inWaitingList && !_vm.mobile ? 'deg-waiting-list--mobile' : '',attrs:{"loader":_vm.codeLoader,"types":['main'],"active-value":_vm.code === '' ? false : true,"main-button-text":'user_register.continue',"main-button-tag":'click_reg_verification-code_continue-button',"spaced":false},on:{"continue":function($event){return _vm.continueMethod()}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }