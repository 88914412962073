<template>
  <div
    :class="{ 'd-flex align-center': getLocationLoader, 'register-main register-main--spaced': !inWelcome }"
  >
    <div v-if="getLocationLoader" class="fill-width">
      <ModalHeader
        v-if="!inSimulation && !inWelcome"
        :content-sections="['actions', 'logo']"
        inline-logo
        small-logo
        :actions-tag="getActionsTags()"
        @back="back()"
      />
      <div v-if="inWelcome">
        <ModalHeader
          class="mt-15 ml-15"
          :content-sections="['actions']"
          inline-logo
          small-logo
          :actions-tag="getActionsTags()"
          @back="$emit('backStep')"
        />
        <ModalContent
          :content-sections="['instructions']"
          :loader-size="80"
          :loader-width="8"
          :instruction-text="'user_register.register_location.give_permission'"
        />
        <ModalContent
          class="welcome-explorer__register--loader"
          :content-sections="['loader']"
          :loader-size="80"
          :loader-width="8"
          :instruction-text="'user_register.register_location.give_permission'"
        />
      </div>
    </div>
    <LoaderCircular :show="requestingLocationAccess" :size="60" :width="8" />
    <TextAtom
      v-if="noLocationAccess"
      :value="$t('user_register.register_location.no_access_to_location')"
      :color="'primary-dark'"
      font="poppins"
      weight="400"
      size="normal"
      class="mt-7"
    />
    <div v-if="!getLocationLoader" class="fill-width">
      <ModalHeader
        v-if="!inSimulation && !inWelcome"
        :content-sections="['actions', 'logo']"
        :actions-tag="getActionsTags()"
        inline-logo
        small-logo
        @back="back()"
      />
      <ModalHeader
        v-if="inWelcome"
        :content-sections="['actions']"
        :title-text="'views.login.password-recovery.title'"
        inline-logo
        class="welcome-explorer__register--back"
        @back="$emit('backStep')"
      />
      <RowStepper
        v-if="!isInMap"
        :step="3"
        :mixpanel-tag="getStepperTags()"
      />
      <div>
        <SvgLogo
          v-if="inWelcome"
          :logo="'only-explorer.svg'"
          svg-width="50"
          alt="explorer icon"
          right
          class="welcome-explorer__logo"
        />
        <div v-if="inWelcome" :class="mobile ? 'ml-10 mr-10' : 'ml-15 mr-15'">
          <template v-if="!isTenantDominicana">
            <TextAtom
              :value="$t('start_explorer.pre_created.account_title')"
              :color="'primary-dark'"
              font="poppins"
              weight="500"
              size="big"
              class="mb-7 d-flex"
            />
            <SvgLogo
              :logo="'inline-tether-logo.svg'"
              svg-width="250"
              left
              alt="explorer icon"
              class="welcome-explorer__register--logo"
            />
          </template>
          <SvgLogo
            v-else
            :logo="'logo-dom.svg'"
            svg-width="200"
            left
            alt="Gobierno de la república dominicana"
          />
          <ImageAtom
            class="deg-waiting-list--line mt-4"
            :source="require('@/assets/waitingList/dge-line.svg')"
            :alt-text="'dge-line'"
          />
        </div>
        <div :class="mobile && inWelcome ? 'ml-10 mr-10' : inWelcome ? 'ml-15 mr-15' : ''">
          <ModalContent
            :content-sections="inSimulation ? ['subtitle'] : ['smallTitle', 'subtitle']"
            :small-title-text="
              locationFor === 'student'
                ? 'user_register.location_student.title'
                : 'user_register.register_location.title'
            "
            small-title-left
            :subtitle-text="
              locationFor === 'student'
                ? 'user_register.register_location.student_subtitle'
                : 'user_register.register_location.subtitle'
            "
          />
          <section
            v-if="selectedOption === 3"
            :class="mobile ? '' : inWelcome ? 'welcome-explorer__register--no-exact' : ''"
          >
            <!-- Sitúa punto en el mapa -->
            <ModalContent
              style="margin: -4px 0"
              :content-sections="['textExpand']"
              :text-placeholder="'user_register.register_location.commune_placeholder'"
              :autocomplete-no-info-error="noInfoError"
              :index-text-expand="0"
              @setPlaceSelected="setPlaceSelected"
              @setAddressSelected="setAddressSelected"
              @setCurrentLocation="setOption"
            />
          </section>
          <section
            v-if="selectedOption === 2"
            :class="mobile ? '' : inWelcome ? 'welcome-explorer__register--exact' : ''"
          >
            <!-- Escribe dirección exacta -->
            <ModalContent
              :style="!inWelcome ? 'margin: -4px 0' : ''"
              :content-sections="['textExpand']"
              :text-placeholder="inSimulation
                ? 'user_register.register_location.commune_placeholder'
                : 'user_register.register_location.commune_placeholder'"
              :autocomplete-no-info-error="noInfoError"
              :index-text-expand="0"
              @setPlaceSelected="setPlaceSelected"
              @setAddressSelected="setAddressSelected"
              @setCurrentLocation="setOption"
            />
            <ModalContent
              :content-sections="['textExpand']"
              :text-placeholder="exact
                ? 'user_register.register_location.address_placeholder_head'
                : 'user_register.register_location.address_placeholder'"
              :index-text-expand="1"
              :autocomplete-no-info-error="noInfoErrorAddress"
              :text-expand-disabled="location.code_national !== '' ? false : true"
              @setPlaceSelected="setPlaceSelected"
              @setAddressSelected="setAddressSelected"
              @openNotFoundAddressModal="showNotFoundAddress = true"
              @setCurrentLocation="setOption"
            />
          </section>
        </div>
      </div>
      <CallToActions
        v-if="!selectedOption && !getLocationLoader"
        :info-buttons-section="sectionInfo"
        :types="isInMap || inDigitalEnrollment ? ['info'] : ['info', 'link']"
        :link-text="'user_register.register_location.text_link'"
        :link-text-tag="registerType === 1
          ? !isInMap ? 'click_reg_quick-register-choose-location_at-another-time-textlink' : null
          : !isInMap ? 'click_reg_detailed-register-choose-location_at-another-time-textlink' : null"
        info-color-purple
        info-color-center
        :in-simulation="inSimulation"
        :in-welcome="inWelcome"
        @infoNextStep="setOption"
        @toLink="$emit('toFinale')"
      />
      <ModalContent
        v-if="!preCreated && !selectedOption && inWelcome"
        class="clickable"
        :class="mobile ? 'welcome-explorer__continue--mobile-skip' : 'welcome-explorer__continue pr-15'"
        :content-sections="['MultiStyleText']"
        :multi-text-array="skipArray"
        @underlineClick="$emit('skip')"
      />
      <div :class="(mobile && !preCreated && inWelcome) ? 'welcome-explorer__button--absolute' : ''">
        <section
          :class="(mobile && !preCreated && inWelcome) ? 'welcome-explorer__button--mobile' : inWelcome
            ? 'welcome-explorer__button welcome-explorer__margin' : 'd-flex align-center justify-end fill-width'"
        >
          <ModalContent
            v-if="!preCreated && selectedOption && !getLocationLoader && inWelcome"
            class="clickable"
            :class="!mobile ? 'welcome-explorer__skip' : ''"
            :content-sections="['MultiStyleText']"
            :multi-text-array="skipArray"
            @underlineClick="$emit('skip')"
          />
          <CallToActions
            v-if="selectedOption && !getLocationLoader"
            :style="inSimulation ? 'margin-right: 16px' : ''"
            :class="(mobile && inWelcome) ? 'welcome-explorer__continue--mobile' : inWelcome
              ? 'welcome-explorer__continue' : ''"
            :types="['main']"
            :active-value="!inSimulation ? isActive : false"
            :main-button-text="inSimulation
              ? 'user_register.brother_in_campus.cancel' : 'user_register.continue'"
            :main-button-tag="getMainButtonsTag()"
            :small="!inWelcome"
            :medium="inWelcome"
            :loader="geoLocatorLoader"
            :spaced="false"
            :main-active-light="inSimulation"
            :main-button-tiny="mobile"
            @continue="inSimulation ? back() : nextStep()"
          />
          <CallToActions
            v-if="selectedOption && !getLocationLoader && inSimulation"
            :types="['main']"
            :active-value="isActive"
            :main-button-text="'user_register.brother_in_campus.confirm'"
            :main-button-tag="getMainButtonsTag()"
            small
            :loader="geoLocatorLoader"
            :spaced="false"
            width-stretch
            :main-button-tiny="mobile"
            @continue="nextStep()"
          />
        </section>
      </div>
      <v-dialog
        v-model="showNotFoundAddress"
        max-width="300"
        transition="slide-x-transition"
      >
        <NotFoundAddress :in-register="inRegister" @close="showNotFoundAddress = false" @nextStep="nextStep()" />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ImageAtom from '@/components/atoms/images/Image.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import SvgLogo from '@/components/atoms/logos/SvgLogo.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import NotFoundAddress from '@/components/organisms/modals/NotFoundAddress.vue';
import trackMixPanel from '@/utils/mixpanel';
import LoaderCircular from '@/components/atoms/loaders/LoaderCircular.vue';
import CONFIG from '@/config';

export default {
  name: 'MainLocation',
  components: {
    ModalHeader,
    RowStepper,
    ModalContent,
    CallToActions,
    NotFoundAddress,
    SvgLogo,
    ImageAtom,
    TextAtom,
    LoaderCircular,
  },
  props: {
    isInMap: {
      type: Boolean,
      default: false,
    },
    locationFor: {
      type: String,
      default: '',
    },
    // 1 Quick - 2 Detailed
    registerType: {
      type: Number,
      default: null,
    },
    inSimulation: {
      type: Boolean,
      default: false,
    },
    exact: {
      type: Boolean,
      default: false,
    },
    inRegister: {
      type: Boolean,
      default: false,
    },
    inWelcome: {
      type: Boolean,
      default: false,
    },
    preCreated: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedOption: null,
      placeSelected: '',
      commune: '',
      getLocationLoader: false,
      geoLocatorLoader: false,
      noInfoError: false,
      noInfoErrorAddress: false,
      showNotFoundAddress: false,
      requestingLocationAccess: false,
      noLocationAccess: false,
      skipArray: [
        {
          text: this.$t('start_explorer.new_flow.skip'),
          underline: [1],
          bold: [1],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      // legalGuardian getter unused
      legalGuardianUUID: 'authentication/legalGuardianUUID',
      allMunicipalities: 'options/municipalities',
      allMunicipalityLabels: 'options/municipalityLabels',
      regions: 'options/regions',
      regionLabels: 'options/regionLabels',
      geocodeInfo: 'userRegister/geocodeInfo',
      inDigitalEnrollment: 'digitalEnrollment/inDigitalEnrollment',
      location: 'userRegister/location',
      place: 'userRegister/place',
    }),
    isTenantDominicana() {
      return CONFIG.tenant === 'dom';
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isActive() {
      if (this.selectedOption === 1 && this.commune) {
        return true;
      }
      if (this.selectedOption === 3 && this.place.lat) {
        return true;
      }
      if (this.selectedOption === 2 && this.place.lat) {
        if (this.exact && this.placeSelected && this.location?.final_address?.formatted_address) { // and address selected
          return true;
        }
        if (!this.exact && (this.placeSelected || this.location.code_national)) {
          return true;
        }
        return false;
      }
      return false;
    },
    sectionInfo() {
      const useLocation = [
        {
          key: 'use-location',
          img: 'location-purple.svg',
          text: 'user_register.register_location.option3',
          tag: this.registerType === 1
            ? 'click_reg_quick-register-choose-location_use-location-button'
            : 'click_reg_detailed-register-choose-location_use-location-button',
        },
      ];
      const writeAddress = [
        {
          key: 'write-address',
          img: 'edit.svg',
          text: 'user_register.register_location.option1',
          tag: this.registerType === 1
            ? 'click_reg_quick-register-choose-location_set-address-button'
            : 'click_reg_detailed-register-choose-location_set-address-button',
        },
      ];

      const pointOnMap = [
        {
          key: 'point-on-map',
          img: 'pin.svg',
          text: 'user_register.register_location.option2',
          tag: this.registerType === 1
            ? 'click_reg_quick-register-choose-location_point-on-map-button'
            : 'click_reg_detailed-register-choose-location_point-on-map-button',
        },
      ];
      if (this.exact) {
        return [writeAddress];
      }
      return [useLocation, writeAddress, pointOnMap];
    },
  },
  mounted() {
    if (this.exact) {
      this.selectedOption = 2;
    }
    const value = {
      formatted_address: null,
      geocodification_type: null,
      lat: null,
      lng: null,
      source: null,
      upper_location: {},
      street_name: null,
      street_number: null,
    };
    const coordinates = {
      lat: null,
      lng: null,
    };
    const place = {
      display: null,
      display1: null,
      display2: null,
      code_national_id: null,
      lat: null,
      lng: null,
      p_location_id: null,
      searchTerm: null,
    };
    this.changePlace({ place });
    this.changeLatLong({ lat: coordinates.lat, lng: coordinates.lng });
    this.setPinMoved({ pinMoved: false });
    this.setInitialCoordinates({ coordinates });
    this.setFinalLocationAddress({ address: value });
    this.changeLocationFormattedAddress({ locationAddress: null });
    this.changeLocationSource({ locationSource: '' });
    this.setLocationAddress({ searchTerm: null });
    this.changeGeoType({ geoType: '' });
  },
  methods: {
    ...mapActions({
      getAddressGeocoding: 'userRegister/getAddressGeocoding',
      setFullAddressDetails: 'userRegister/setFullAddressDetails',
      getAddressReverseGeocoding: 'userRegister/getAddressReverseGeocoding',
      changeCodeNational: 'userRegister/changeCodeNational',
      setFinalLocationAddress: 'userRegister/setFinalLocationAddress',
      sendPlaceHistory: 'geoTools/sendPlaceHistory',
      setLocationType: 'userRegister/setLocationType',
      setLocationAddress: 'userRegister/setLocationAddress',
      changeLocationSource: 'userRegister/changeLocationSource',
      changeLocationFormattedAddress: 'userRegister/changeLocationFormattedAddress',
      changeGeoType: 'userRegister/changeGeoType',
      setInitialCoordinates: 'userRegister/setInitialCoordinates',
      setPinMoved: 'userRegister/setPinMoved',
      changeLatLong: 'userRegister/changeLatLong',
      changePlace: 'userRegister/changePlace',
    }),
    getMainButtonsTag() {
      if (this.selectedOption === 2) {
        if (this.inWelcome && !this.preCreated) {
          return 'click_UsuariosGuest_3_a';
        }
        if (this.registerType === 1) {
          return 'click_reg_quick-register-set-address_continue-button';
        }
        return 'click_reg_detailed-register-set-address_continue-button';
      }
      if (this.selectedOption === 3) {
        if (this.inWelcome && !this.preCreated) {
          return 'click_UsuariosGuest_3_b';
        }
        if (this.registerType === 1) {
          return 'click_reg_quick-register-point-on-map_continue-button';
        }
        return 'click_reg_detailed-register-point-on-map_continue-button';
      }
      if (this.registerType === 1) {
        return 'click_reg_quick-register-set-address_continue-button';
      }
      return 'click_reg_detailed-register-set-address_continue-button';
    },
    nextStep() {
      if (this.selectedOption === 1 && this.place.display1) {
        this.$emit('setCommune', this.place.display1);
      }
      if (this.selectedOption === 3 && !this.place.lat) {
        this.noInfoError = true;
        return null;
      }
      if (this.selectedOption === 3 && this.place.lat) {
        const placeHistory = {
          user: this.legalGuardianUUID || null,
          user_text_input: this.place.searchTerm,
          geotools_code_national: this.location.code_national,
          geotools_display_name: this.place.display,
        };
        const coordinates = {
          lat: this.place.lat,
          lng: this.place.lng,
        };
        this.setInitialCoordinates({ coordinates });
        this.sendPlaceHistory({ place: placeHistory });
        this.$emit('setCommune', this.place.display1);
      }
      if (this.selectedOption === 2 && (!this.place.lat
        || this.location?.final_address?.formatted_address === null) && this.exact && !this.showNotFoundAddress) {
        this.noInfoErrorAddress = this.location?.final_address?.formatted_address === null;
        this.noInfoError = !this.place.lat;
        return null;
      }
      if (this.selectedOption === 2 && this.place && !this.place.lat) {
        this.noInfoError = true;
        return null;
      }
      if (this.selectedOption === 2) {
        this.geoLocatorLoader = true;
        if (this.location?.final_address?.formatted_address !== null) {
          const coordinates = {
            lat: this.location?.final_address?.lat,
            lng: this.location?.final_address?.lng,
          };
          this.setInitialCoordinates({ coordinates });
        } else {
          const coordinates = {
            lat: this.place.lat,
            lng: this.place.lng,
          };
          this.setInitialCoordinates({ coordinates });
        }

        const locationArray = {
          coordinates: {
            lat: this.location.final_address.lat,
            lng: this.location.final_address.lon,
          },
          addressDetails: this.location.final_address.formatted_address,
        };
        this.$emit('setUserLocation', this.location.final_address, locationArray);

        const placeHistory = {
          user: this.legalGuardianUUID || null,
          user_text_input: this.place.searchTerm,
          geotools_code_national: this.location.code_national,
          geotools_display_name: this.place.display,
        };
        this.sendPlaceHistory({ place: placeHistory });

        this.geoLocatorLoader = false;
      }
      return null;
    },
    async getCurrentLocation() {
      this.requestingLocationAccess = true;
      this.getLocationLoader = true;
      await this.$getLocation({})
        .then((coordinates) => {
          const reversGeocoding = {
            lat: coordinates.lat,
            long: coordinates.lng,
            accuracy: 200,
            new_version: true,
          };
          this.getAddressReverseGeocoding({ reversGeocoding })
            .then(() => {
              const userLocation = {
                coordinates: { lat: this.geocodeInfo[0].lat, lng: this.geocodeInfo[0].lng },
                addressDetails: this.geocodeInfo[0].formatted_address,
                municipalityName: this.geocodeInfo[0].upper_location.upper_location_name,
                address_name: null,
              };
              const coordinatesGeo = {
                lat: this.geocodeInfo[0].lat,
                lng: this.geocodeInfo[0].lng,
              };
              this.changeLatLong({ lat: coordinatesGeo.lat, lng: coordinatesGeo.lng });
              this.setInitialCoordinates({ coordinates: coordinatesGeo });
              const value = {
                formatted_address: this.geocodeInfo[0].formatted_address,
                geocodification_type: this.geocodeInfo[0].geocodification_type,
                lat: this.geocodeInfo[0].lat,
                lng: this.geocodeInfo[0].lng,
                source: this.geocodeInfo[0].source,
                upper_location: this.geocodeInfo[0].upper_location,
              };
              this.setFinalLocationAddress({ address: value });
              this.getLocationLoader = false;
              this.$emit('setUserLocation', userLocation, true);
              this.requestingLocationAccess = false;
            })
            .catch(() => {
              this.getLocationLoader = false;
            });
        }).catch(() => {
          this.requestingLocationAccess = false;
          this.noLocationAccess = true;
        });
    },
    setPlaceSelected(value) {
      this.placeSelected = value.display;
      this.noInfoError = false;
      this.changeCodeNational({ codeNational: value.code_national_id });
    },
    setAddressSelected(value) {
      this.setFinalLocationAddress({ address: value });
    },
    setOption(i) {
      // Index have to be > 0 for hide options
      const indexFrom1 = i + 1;
      if (indexFrom1 === 1 && this.inWelcome && !this.preCreated) {
        trackMixPanel('click_UsuariosGuest_2_UbicacionActual');
      }
      if (indexFrom1 === 2 && this.inWelcome && !this.preCreated) {
        trackMixPanel('click_UsuariosGuest_2_UbicacionExacta');
      }
      if (indexFrom1 === 3 && this.inWelcome && !this.preCreated) {
        trackMixPanel('click_UsuariosGuest_2_PuntoenelMapa');
      }
      if (indexFrom1 === 1) {
        this.selectedOption = indexFrom1;
        this.getCurrentLocation();
        this.setLocationType({ locationType: this.selectedOption });
        return null;
      }
      this.selectedOption = indexFrom1;
      this.setLocationType({ locationType: this.selectedOption });
      return this.selectedOption;
    },
    back() {
      if (this.selectedOption && !this.exact) {
        this.selectedOption = null;
        this.getLocationLoader = false;
        this.noLocationAccess = false;
        this.setPinMoved({ pinMoved: false });
        return this.selectedOption;
      }
      if (this.selectedOption && this.exact) {
        return this.$emit('backStep');
      }
      this.setPinMoved({ pinMoved: false });
      return this.$emit('backStep');
    },
    getActionsTags() {
      if (!this.isInMap) {
        if (this.selectedOption === 2) {
          if (this.registerType === 1) {
            return 'click_reg_quick-register-set-address_back-button';
          }
          return 'click_reg_detailed-register-set-address_back-button';
        }
        if (this.selectedOption === 3) {
          if (this.registerType === 1) {
            return 'click_reg_quick-register-point-on-map_back-button';
          }
          return 'click_reg_detailed-register-point-on-map_back-button';
        }
        if (this.registerType === 1) {
          return 'click_reg_quick-register-choose-location_back-button';
        }
        return 'click_reg_detailed-register-choose-location_back-button';
      }
      return null;
    },
    getStepperTags() {
      if (!this.isInMap) {
        if (this.selectedOption === 2) {
          if (this.registerType === 1) {
            return 'click_reg_quick-register-set-address_row-stepper';
          }
          return 'click_reg_detailed-register-set-address_row-stepper';
        }
        if (this.selectedOption === 3) {
          if (this.registerType === 1) {
            return 'click_reg_quick-register-point-on-map_row-stepper';
          }
          return 'click_reg_detailed-register-point-on-map_row-stepper';
        }
        if (this.registerType === 1) {
          return 'click_reg_quick-register-choose-location_row-stepper';
        }
        return 'click_reg_detailed-register-choose-location_row-stepper';
      }
      return null;
    },
  },
};
</script>
