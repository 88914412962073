const INTERNAL_SELF_IDENTIFICATIONS_OPTIONS = Object.freeze({
  GUARDIAN: 'guardian',
  TEACHER: 'teacher',
  ADMINISTRATIVE: 'administrative',
  STUDENT: 'student',
  OTHER: 'other',
});

const EXTERNAL_SELF_IDENTIFICATIONS_OPTIONS = Object.freeze({
  INTERESTED_FAMILY: 'interested_family',
  SERVICE_PROVIDER: 'service_provider',
  JOB_SEEKER: 'job_seeker',
  OTHER: 'other',
});

const RELATION_WITH_CAMPUS_OPTIONS = Object.freeze({
  INTERNAL: 'internal',
  EXTERNAL: 'external',
});

const POSITION_OPTIONS = Object.freeze({
  GUARDIAN: 'guardian',
  TEACHER: 'teacher',
  ADMINISTRATIVE: 'administrative',
  SERVICE_PROVIDER: 'service_provider',
  RESEARCHER: 'researcher',
  OTHER: 'other',
});

const CONTACT_SUPPORT_MESSAGE_TITLES = Object.freeze({
  FIND_INFO: 'find_info',
  REPORT_ISSUE: 'report_issue',
  REQUEST_HELP: 'request_help',
  MEET_SUPPORT_TEAM: 'meet_support_team',
});

const CONTACT_SUPPORT_ADMIN_TEACHER_MESSAGE_TITLES = Object.freeze({
  ADD_MEDIA_TO_DIGITAL_PROFILE: 'add_media_to_digital_profile',
  REPORT_ISSUE: 'report_issue',
  UPDATE_DIGITAL_PROFILE: 'update_digital_profile',
  MEET_SUPPORT_TEAM: 'meet_support_team',
});

const SIMPLE_MESSAGE_TITLES = Object.freeze({
  REQUEST_INFO: 'request_info',
  INQUIRY: 'inquiry',
  COMMENT: 'comment',
});

const OTHER_MESSAGE_TITLES = Object.freeze({
  APPLY_TO_CAMPUS: 'apply_to_campus',
  OFFER_MY_SERVICES: 'offer_my_services',
  FIND_JOB: 'find_job',
});

export {
  INTERNAL_SELF_IDENTIFICATIONS_OPTIONS,
  EXTERNAL_SELF_IDENTIFICATIONS_OPTIONS,
  RELATION_WITH_CAMPUS_OPTIONS,
  POSITION_OPTIONS,
  CONTACT_SUPPORT_MESSAGE_TITLES,
  CONTACT_SUPPORT_ADMIN_TEACHER_MESSAGE_TITLES,
  SIMPLE_MESSAGE_TITLES,
  OTHER_MESSAGE_TITLES,
};
