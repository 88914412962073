<template>
  <div class="message-card">
    <!-- Add your card content here -->
    <div class="card-content">
      <div style="position: relative">
        <v-icon
          color="#5627FF"
          style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)"
        >
          mdi-town-hall
        </v-icon>
        <v-img
          v-if="message.img"
          height="80px"
          width="80px"
          style="border-radius: 11px 0px 0px 11px"
          aspect-ratio="1/1"
          :src="message.img"
          alt="imagen-colegio"
        />
      </div>
      <div class="card-text">
        <h2>{{ message.title }}</h2>
        <div class="card-actions">
          <v-btn
            small
            style="background: #eff0f6"
            icon
            alt="bookmark"
            @click="handleFavorite"
            @keyup.enter="handleFavorite"
          >
            <v-icon color="#5627ff" small>
              {{ isFavorite ? 'mdi-bookmark' : 'mdi-bookmark-outline' }}
            </v-icon>
          </v-btn>
          <v-btn
            small
            flat
            color="#E0E7FF"
            style="color: #0c1461"
            append-icon="chevron-right"
            class="text-none ms-2"
            @click="openSchool"
          >
            Ver
            <v-icon color="0c1461"> mdi-chevron-right </v-icon>
          </v-btn>
          <v-btn
            v-if="message.external_url"
            small
            @click="openApplications()"
            flat
            color="#313f89"
            style="color: white"
            append-icon="chevron-right"
            class="text-none ms-2"
          >
            <div class="text-caption">Postular</div>
          </v-btn>
          <!--<v-btn
            small
            v-if="!message.campus_tether_pack"
            @click="openLink(message.campusCode)"
            flat
            color="#5627FF"
            style="color: white"
            append-icon="chevron-right"
            class="text-none ms-2"
          >
            Postular
          </v-btn>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import CONFIG from '@/config';

export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isFavorite: false,
    };
  },
  computed: {
    ...mapGetters({
      favoriteGuestList: 'favorites/favoriteGuestList',
    }),
  },
  created() {
    this.isFavorite = this.checkIfIsFavorite();
  },

  methods: {
    ...mapActions({
      updateCurrentSchool: 'institutions/updateCurrentSchool',
      setGuestFavorite: 'favorites/setGuestFavorite',
      deleteGuestFavorite: 'favorites/deleteGuestFavorite',
    }),
    openApplications() {
      // similar to open schools, but at admission part
      this.updateCurrentSchool({ campusCode: this.message.campusCode, fromUrl: true });
      const routeRoot = window.location.href.split('?')[0];
      const useSlash = routeRoot.endsWith('/') ? '' : '/';
      const newUrl = `${routeRoot}${useSlash}school/${this.message.campusCode}`;
      window.history.pushState({ path: newUrl }, '', newUrl);

      // find and click admissions
      this.findAndClickAdmission();
    },
    findAndClickAdmission() {
      // Find admission
      const admissionDiv = document.getElementById('admission');

      // if found, scroll into view and open
      if (admissionDiv) {
        setTimeout(() => {
          admissionDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
          const firstButton = admissionDiv.querySelector('button');
          firstButton.click();
        }, 500);
      } else {
        // If not found, wait 500ms and try again
        setTimeout(this.findAndClickAdmission, 500);
      }
    },
    openSchool() {
      this.updateCurrentSchool({ campusCode: this.message.campusCode, fromUrl: true });
      const routeRoot = window.location.href.split('?')[0];
      const useSlash = routeRoot.endsWith('/') ? '' : '/';
      const newUrl = `${routeRoot}${useSlash}school/${this.message.campusCode}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
      // this.$router.push({ name: 'campus-detail', params: { campusCode: this.message.campusCode } });
    },
    setFavorite() {
      this.setGuestFavorite({ favoriteCampus: this.message.campus });
      this.isFavorite = true;
    },
    unSetFavorite() {
      this.deleteGuestFavorite(this.message.campus.uuid);
      this.isFavorite = false;
    },
    checkIfIsFavorite() {
      return this.favoriteGuestList.some(
        (favorite) => favorite.campus_code === this.message.campusCode,
      );
    },
    handleFavorite() {
      if (this.isFavorite) {
        this.unSetFavorite();
      } else {
        this.setFavorite();
      }
    },
  },
};
</script>

<style scoped>
.message-card {
  /* Add your custom styles for the message card */
  border-radius: 10.211px;
  margin-bottom: 10px;
  width: 100%;
  height: 80px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
  margin-left: 10px;
}
.card-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.image-wrapper {
  flex: 1;
  max-width: 100px;
  border-radius: 8px;
}
.card-content img {
  max-height: 100px;
}
.card-text {
  flex: 2;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 10px;
}
.card-text h2 {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}
.card-actions {
  display: flex;
  margin-top: 5px;
}
.card-ver {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-left: 10px;
  background-color: #e0e7ff;
  border-radius: 8px;
  width: 118px;
  cursor: pointer;
}
.card-postular {
  background-color: #5627ff;
  height: 100%;
}
.card-postular p {
  color: white;
  font-weight: bold;
}
.set-favorite {
  cursor: pointer;
}
.set-favorite img {
  height: 40px;
  width: 40px;
}
</style>
