import {
  TETHER_CATEGORY,
} from '@/constants/category';
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import { buildCategoryObject, buildNoInfoCategoryObject } from './shared';

/**
 * Get the category of a campus quality category property based on the campus data and the tenant
 * @param {*} campusData - The campus data, as returned by the elastic search API
 * @param {*} propertyKey - The key of the property to get the category for
 * @param {*} qualityLabelId - The quality category label id
 * @returns {PropertyCategory} - The category of the performance property
 */
const getCampusGenericCategory = (campus, typeConfiguration, defaultData, propertyKey, qualityLabelId) => {
  const qualityCategories = campus?.quality_categories;

  if (!qualityCategories) return buildNoInfoCategoryObject(propertyKey);

  const qualityCategory = qualityCategories.find((category) => category.qualitycategory_label.id === qualityLabelId);
  if (!qualityCategory) return buildNoInfoCategoryObject(propertyKey);

  const { CATEGORIES: { GENERIC_CATEGORY } } = TENANT_CONFIGURATION;
  const categoryKey = GENERIC_CATEGORY[qualityCategory?.qualitycategory_level_label?.id];

  if (!categoryKey || categoryKey === TETHER_CATEGORY.NO_DATA) return buildNoInfoCategoryObject(propertyKey);
  return buildCategoryObject(
    propertyKey,
    categoryKey,
  );
};

export {
  getCampusGenericCategory,
};
