const RESEARCH_STEP = Object.freeze({
  WELCOME: 'welcome',
  INFORMATION_PAGES: 'information_pages',
  TERMS_AND_CONDITIONS: 'terms_and_conditions',
  FLOW_LOCATION: 'flow_location',
  RESEARCH: 'research',
});

const RESEARCH_FLOW = Object.freeze({
  ORGANIC: 'organic',
  PREEXISTING: 'preexisting',
});

const CHILE_RESEARCH_STEP = Object.freeze({
  [RESEARCH_FLOW.PREEXISTING]: {
    1: RESEARCH_STEP.WELCOME,
    3: RESEARCH_STEP.TERMS_AND_CONDITIONS,
    4: RESEARCH_STEP.FLOW_LOCATION,
    5: RESEARCH_STEP.RESEARCH,
  },
  [RESEARCH_FLOW.ORGANIC]: {
    1: RESEARCH_STEP.WELCOME,
    2: RESEARCH_STEP.INFORMATION_PAGES,
    3: RESEARCH_STEP.FLOW_LOCATION,
    4: RESEARCH_STEP.RESEARCH,
  },
});

const DOM_RESEARCH_STEP = Object.freeze({
  [RESEARCH_FLOW.PREEXISTING]: {
    1: RESEARCH_STEP.WELCOME,
    2: RESEARCH_STEP.TERMS_AND_CONDITIONS,
    3: RESEARCH_STEP.FLOW_LOCATION,
    4: RESEARCH_STEP.RESEARCH,
  },
  [RESEARCH_FLOW.ORGANIC]: {
    1: RESEARCH_STEP.WELCOME,
    3: RESEARCH_STEP.FLOW_LOCATION,
    4: RESEARCH_STEP.RESEARCH,
  },
});

export {
  RESEARCH_STEP,
  RESEARCH_FLOW,
  CHILE_RESEARCH_STEP,
  DOM_RESEARCH_STEP,
};
