<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <RowStepper :step="2" />
      <ModalContent
        :content-sections="['smallTitle', 'textSelect']"
        :small-title-text="'user_register.identification.student_title'"
        small-title-left
        :text-select-placeholders="'user_register.gender.placeholder'"
        :text-select-items="genders"
        text-select-item-text="gender_label"
        :text-select-initial-value="gender"
        text-select-return-object
        :text-select-index="2"
        @setTextSelectValue="setValue"
      />
    </div>
    <CallToActions
      :types="['main']"
      active-value
      :main-button-text="'user_register.continue'"
      small
      :spaced="false"
      @continue="nextStep()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import { LABELS } from '@/constants/explorer/labels';

export default {
  name: 'RegisterGender',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
    RowStepper,
  },
  props: {
    preFillData: {
      type: Object,
      default() {
        return {};
      },
    },
    preFill: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      gender: null,
    };
  },
  computed: {
    ...mapGetters({
      currentStudent: 'authentication/currentStudent',
      labelsByKey: 'explorer/labelsByKey',
    }),
    genders() {
      return this.labelsByKey(LABELS.GENDERS);
    },
    isActive() {
      return !!this.gender;
    },
  },
  watch: {
    preFillData: {
      handler() {
        if (this.preFill) {
          this.doPreFill();
        }
      },
      deep: true,
    },
    preFill: {
      handler(newVal) {
        if (!newVal) {
          this.gender = null;
        }
      },
    },
  },
  mounted() {
    if (this.preFill) {
      this.doPreFill();
    }
  },
  methods: {
    ...mapActions({
      setStudents: 'newStudentRegister/setStudents',
    }),
    nextStep() {
      if (this.isActive) {
        const personalInfo = {
          uuid: this.currentStudent.uuid,
          gender: this.gender.id,
        };
        this.setStudents({ studentsForm: personalInfo, current: true });
        this.$emit('nextStep');
      } else {
        this.$emit('nextStep');
      }
    },
    setValue(index, value) {
      if (index) {
        this.gender = value;
      }
    },
    doPreFill() {
      this.gender = this.preFillData?.gender ?? null;
    },
  },
};
</script>
