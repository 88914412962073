<template>
  <div class="grade--selected-grade">
    <div class="d-flex">
      <h6
        v-t="stageName + ':'"
        class="grade--selected-grade--stage grade--selected-grade--stage--bold"
      />
      <p v-t="gradeName" class="grade--selected-grade--stage" />
    </div>
    <img
      style="cursor: pointer"
      src="@/assets/icons/close.svg"
      alt="close"
      @click="$emit('deleteOption')"
      @keydown="$emit('deleteOption')"
    />
  </div>
</template>

<script>
export default {
  name: 'GradeChip',
  props: {
    stageName: {
      type: [String, Boolean],
      default: '',
    },
    gradeName: {
      type: [String, Boolean],
      default: '',
    },
  },
};
</script>
