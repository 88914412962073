<template>
  <div id="player" class="fill-width fill-height" style="border-radius: 4px">
    <vimeo-player
      v-if="isVimeoVideo(video)"
      ref="player"
      :video-id="video"
      :options="{
        responsive: true,
        playsinline: isFullscreen,
        title: false,
      }"
      @ready="onReady"
      @play="play"
    />
    <youtube v-else :video-id="videoId(video)" class="youtubeVideo" />
  </div>
</template>
<script>
import trackMixPanel from '@/utils/mixpanel';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CampusDetailVideo',
  props: {
    video: {
      type: String,
      default: null,
    },
    id: {
      type: Number,
      default: -1,
    },
    isVideoPartnership: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      playerReady: false,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'institutions/campusDetails',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isFullscreen() {
      if (this.mobile) {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapActions({
      setActualVideo: 'options/setActualVideo',
      stopActualVideo: 'options/stopActualVideo',
    }),
    videoId(link) {
      return this.$youtube.getIdFromUrl(link);
    },
    isVimeoVideo(link) {
      if (link) {
        return link.includes('vimeo');
      }
      return null;
    },
    onReady() {
      this.playerReady = true;
    },
    play() {
      if (!this.isVideoPartnership) {
        let trackName = 'sp_school_testimonials';
        if (this.id === 1) {
          trackName = 'sp_school_drone';
        }
        trackMixPanel(trackName, {
          school_id: this.campusDetail.uuid,
          campus_code: this.campusDetail.campus_code,
          institution_code: this.campusDetail.institution_code,
          name: this.campusDetail.campus_name,
          audiovisual_label_id: this.id,
        });
        if (this.mobile) {
          this.$refs.player.play();
          const { player } = this.$refs.player;
          player.getIframe().then((iframe) => {
          // eslint-disable-next-line
          var requestFullScreen =
            iframe.requestFullScreen
            || iframe.mozRequestFullScreen
            || iframe.webkitRequestFullScreen;
            if (requestFullScreen) {
              requestFullScreen.bind(iframe)();
            }
          });
        }
      }
    },
  },
};
</script>
