<template>
  <section>
    <TextAtom
      :value="description"
      class="mt-4 text-left d-flex pl-4 pb-3"
      tag="label"
      font="inter"
      color="primary-dark"
      weight="400"
      size="subtitle"
    />
    <div class="campus-detail__panels__container">
      <p
        v-for="({ name, total }, index) in getGrades"
        :key="index"
        class="campus-detail__panels__title text-left mb-2 font-weight-medium"
      >
        <span class="font-bold">
          {{ total }}
        </span>

        <span
          v-t="{
            path: 'campus-details.student',
            args: { grade: name },
          }"
          class="ml-1"
        />
      </p>

      <MessageInPanel from="students-panel" />
    </div>
    <div v-for="(stage, index) in stages" :key="index">
      <iframe
        :class="index === 0 ? 'mt-4' : ''"
        title="students-panel"
        height="400"
        width="98%"
        class="campus-detail__panels__iframe"
        :src="showWidget(stage)"
        loading="lazy"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CONFIG from '@/config';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import MessageInPanel from './MessageInPanel.vue';

export default {
  name: 'StudentsPanel',
  components: {
    MessageInPanel,
    TextAtom,
  },
  props: {
    members: {
      type: Array,
      default: () => [],
    },
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      campusCode: '',
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'institutions/campusDetails',
      stages: 'widgets/stages',
    }),
    getGrades() {
      const members = [];
      const totalMembers = [...this.members];

      const hasMember = totalMembers.filter((m) => m.member_label.id === 1 && m.stage_label !== null)
        .map((m) => ({ total: m.total_members, name: m.stage_label.stage_name, year: m.year }))
        .sort((a, b) => b.year - a.year) ?? [];

      hasMember.forEach((member) => {
        const existMemberInList = members.findIndex((m) => m.name === member.name) ?? {};

        if (existMemberInList === -1) {
          members.push(member);
        } else {
          members[existMemberInList].year = member.year > members[existMemberInList].year
            ? member.year
            : members[existMemberInList].year;
        }
      });
      return members;
    },
  },
  mounted() {
    this.campusCode = this.campusDetail.campus_code;
    this.getStages({ campusCode: this.campusCode });
  },
  methods: {
    ...mapActions({
      getStages: 'widgets/getStages',
    }),
    showWidget(stage) {
      return `${CONFIG.staticWidgetsBaseURL}/SchoolProfile_ExAlumnTransitions?campus_code=${this.campusCode}&stage=${stage}`;
    },
  },
};
</script>
