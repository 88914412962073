<template>
  <section
    style="margin-top: 12px"
    :class="{
      'register-main--call-info-button': origin === 'call',
      'register-main--info-button': !origin,
      'register-main--info-button--active': isActive,
    }"
    @click="infoButtonClick()"
    @keydown="/* TODO */"
  >
    <div v-if="!withoutTitle" class="d-flex justify-space-between">
      <h3 v-t="title" class="register-main--info-button--title" />
      <p v-t="time" class="register-main--info-button--time" />
    </div>
    <div
      v-for="(section, index) in sectionContent"
      :key="index"
      class="d-flex align-center"
      :class="{ 'justify-center': center }"
      :style="center || withoutTitle ? '' : 'margin-top: 12px'"
    >
      <img
        v-if="!withoutImg"
        style="margin-right: 12px"
        :src="require('@/assets/icons/' + section.img)"
        alt="bookmark"
      />
      <p
        v-t="section.text"
        class="register-main--info-button--list"
        :class="{
          'register-main--info-button--list--center': center,
          'register-main--info-button--list--purple-center': purple,
        }"
      />
    </div>
  </section>
</template>

<script>
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'InfoButton',
  components: {},
  props: {
    withoutTitle: {
      type: Boolean,
      default: false,
    },
    withoutImg: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
    sectionInfo: {
      type: Array,
      default() {
        return [];
      },
    },
    center: {
      type: Boolean,
      default: false,
    },
    purple: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: null,
    },
    origin: {
      type: String,
      default: null,
    },
    mixpanelTag: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      sectionContent: [],
    };
  },
  mounted() {
    this.sectionContent = this.sectionInfo;
  },
  methods: {
    infoButtonClick() {
      if (this.mixpanelTag) {
        trackMixPanel(this.mixpanelTag);
      }
      this.$emit('infoNextStep');
    },
  },
};
</script>
