import moment from 'moment';
import services from '@/services';
import { uniqueArrayObjects } from '@/utils/arrays';
import { extractStagesAndGrades } from '@/utils/gradetracks';
import { ADMISSION_STATUS } from '@/constants/digitalEnrollment';
/**
 * Define the default state for the module
 * @returns {Object} - The state object
 */
const getDefaultState = () => ({
  parameters: {
    tenantId: null,
    userType: null,
  },
  inDigitalEnrollment: false,
  tenant: null,
  applicationGrade: null,
  tenantStages: [],
  tenantGrades: [],
  tenantShifts: [],
  filteredTenantShifts: [],
  applicantShiftPriorities: [],
  dgeHeadmasterInfo: null,
  dgeLegalGuardianInfo: null,
  digitalEnrollmentApplications: [],
  dgeSelectedApplication: null,
  // TEMP - has to be separate from the applications array because the applications array is
  // updated constantly
  successfulPaymentTimes: {},
  applicationPaymentStatus: null,
  applicationStatus: null,
  applicationMetadata: null,
  currentStatusPoll: null,
  yearToApply: null,
});

const state = {
  ...getDefaultState(),
};

const getters = {
  inDigitalEnrollment: ({ inDigitalEnrollment }) => inDigitalEnrollment,
  tenant: ({ tenant }) => tenant,
  applicationGrade: ({ applicationGrade }) => applicationGrade,
  tenantShifts: ({ allShifts }) => allShifts,
  tenantGrades: ({ tenantGrades }) => tenantGrades,
  tenantStages: ({ tenantStages }) => tenantStages,
  dgeHeadmasterInfo: ({ dgeHeadmasterInfo }) => dgeHeadmasterInfo,
  dgeLegalGuardianInfo: ({ dgeLegalGuardianInfo }) => dgeLegalGuardianInfo,
  dgeSelectedApplication: ({ dgeSelectedApplication }) => dgeSelectedApplication,
  digitalEnrollmentApplications: ({ digitalEnrollmentApplications }) => digitalEnrollmentApplications,
  filteredTenantShifts: ({ filteredTenantShifts }) => filteredTenantShifts,
  applicantShiftPriorities: ({ applicantShiftPriorities }) => applicantShiftPriorities,
  parameters: ({ parameters }) => parameters,
  snakeCaseParameters: ({ parameters }) => ({
    tenant_id: parameters.tenantId,
    user_type: parameters.userType,
  }),
  userType: ({ parameters }) => parameters.userType,
  tenantId: ({ parameters }) => parameters.tenantId,
  applicationPaymentTime: ({ successfulPaymentTimes, applicationMetadata, applicationStatus }) => {
    const paymentId = applicationMetadata?.payment.transactionId;
    const admissionStatus = applicationStatus?.status;
    return successfulPaymentTimes[`${paymentId}_${admissionStatus}`] ?? null;
  },
  applicationPaymentStatus: ({ applicationPaymentStatus }) => applicationPaymentStatus,
  applicationStatus: ({ applicationStatus }) => applicationStatus,
  applicationMetadata: ({ applicationMetadata }) => applicationMetadata,
  yearToApply: ({ yearToApply }) => yearToApply,
};

const mutations = {
  /**
   * Reset the state to the default state
   * @param {Object} state
   */
  resetStore(state) {
    const currentYearToApply = state.yearToApply;

    Object.assign(state, getDefaultState());
    // Restore the preserved yearToApply value
    state.yearToApply = currentYearToApply;
  },
  /**
   * Set the parameters received for the digital enrollment process
   * @param {Object} parameters
   * @param {Object} parameters.tenantId
   * @param {Object} parameters.user_type
   */
  setParameters(state, { parameters }) {
    const { tenantId, user_type: userType } = parameters;
    state.parameters = { tenantId, userType };
  },
  /**
   * Set the digital enrollment state in the store
   * @param {Boolean} inDigitalEnrollment - The digital enrollment state
   */
  setDigitalEnrollment(state, { inDigitalEnrollment }) {
    state.inDigitalEnrollment = inDigitalEnrollment;
  },
  /**
   * Set the tenant in the store for the digital enrollment process
   * @param {Object} tenant - The tenant object obtained from ElasticSearch
   * @param {String} tenant.id - The tenant id
   */
  setDigitalEnrollmentTenant(state, { tenant }) {
    state.tenant = tenant;
  },
  /**
   * Set the application grade in the store
   * @param {String} grade - The application grade
   */
  setApplicationGrade(state, { grade }) {
    state.applicationGrade = grade;
  },
  /**
   * Set the tenant stages in the store
   * @param {Array} stages - The tenant stages
   */
  setTenantStages(state, { stages }) {
    state.tenantStages = stages;
  },
  /**
   * Set the tenant shifts in the store
   * @param {Array} shifts - The tenant shifts
   */
  setTenantShifts(state, { shifts }) {
    state.tenantShifts = shifts;
  },
  /**
   * Set the tenant grades in the store
   * @param {Array} grades - The tenant grades
   */
  setTenantGrades(state, { grades }) {
    state.tenantGrades = grades;
  },
  /**
   * Set the filtered tenant shifts in the store.
   * This includes only the shifts that are available for the selected grade
   * @param {Array} shifts - The tenant shifts
   */
  setFilteredTenantShifts(state, { shifts }) {
    state.filteredTenantShifts = shifts;
  },
  /**
   * Set the applicant shift priorities in the store
   * @param {Array} priorities - The applicant shift priorities
   */
  setApplicantShiftPriorities(state, { priorities }) {
    state.applicantShiftPriorities = priorities;
  },
  setDgeHeadmasterInfo(state, { dgeInfo }) {
    state.dgeHeadmasterInfo = dgeInfo;
  },
  setDgeLegalGuardianInfo(state, { dgeInfo }) {
    state.dgeLegalGuardianInfo = dgeInfo.resume;
  },
  setTenantId(state, { tenantId }) {
    state.parameters.tenantId = tenantId;
  },
  async setDigitalEnrollmentApplications(state, { applications }) {
    state.digitalEnrollmentApplications = applications;
  },
  setDgeSelectedApplication(state, { application }) {
    state.dgeSelectedApplication = application;
  },
  setSuccessfulPaymentTime(state, { paymentId, paymentTime, currentState }) {
    state.successfulPaymentTimes = {
      ...state.successfulPaymentTimes,
      [`${paymentId}_${currentState}`]: paymentTime,
    };
  },
  setApplicationPaymentStatus(state, { status }) {
    state.applicationPaymentStatus = status;
  },
  setApplicationStatus(state, { status }) {
    state.applicationStatus = status;
  },
  setApplicationMetadata(state, { metadata }) {
    state.applicationMetadata = metadata;
  },
  setCurrentStatusPoll(state, { poll }) {
    state.currentStatusPoll = poll;
  },
  setYearToApply(state, { yearToApply }) {
    state.yearToApply = yearToApply;
  },
};

const actions = {
  /**
   * Reset the state to the default state
   */
  resetStore({ commit }) {
    commit('resetStore');
  },
  /**
   * Reset the states that have been set during a specific digital enrollment process
   */
  resetSavedFlowData({ commit }) {
    commit('setApplicantShiftPriorities', { priorities: [] });
  },
  setTenantId({ commit }, { tenantId }) {
    commit('setDigitalEnrollment', { inDigitalEnrollment: true });
    commit('setTenantId', { tenantId });
  },
  /**
   * Execute the digital enrollment process setup
   * @param {Object} metadata - Metadata for the digital enrollment process
   * @param {String} metadata.tenantId - The tenant id
   * @param {String} metadata.user_type - The DGE user type for which the process is being executed
   * @returns {Promise} - The promise of the commit
   */
  async setup({ commit, dispatch, state }, { metadata }) {
    const { tenantId } = metadata;
    const cleanTenantId = (tenantId ?? '').replace(/[\W]+$/, '');

    const shouldFetchNewTenant = !!(tenantId && (!state.tenant || state.tenantId !== cleanTenantId));
    if (shouldFetchNewTenant) {
      const cleanMetadata = { ...metadata, tenantId: cleanTenantId };

      return dispatch('resetStore').then(() => {
        commit('setDigitalEnrollment', { inDigitalEnrollment: true });
        commit('setParameters', { parameters: cleanMetadata });
        return dispatch('retrieveDigitalEnrollmentTenantOptions', { tenantId: cleanTenantId });
      });
    }
    commit('setDigitalEnrollment', { inDigitalEnrollment: true });
    dispatch('resetSavedFlowData');
    return true;
  },
  /**
   * Retrieve the tenant options for the digital enrollment process
   * @param {String} tenantId - The campus code for which to retrieve the options
   */
  async retrieveDigitalEnrollmentTenantOptions({ commit, dispatch, getters }, { tenantId }) {
    return services.elasticSearchService
      .elasticCampusAttributeSearch({
        campus: tenantId,
        fieldsRequired: [
          'campus_code',
          'campus_name',
          'programs',
          'programs.gradetrack',
          'programs.shift',
          'image_thumb.image_link',
        ],
      })
      .then((response) => {
        if (response.data.total_found) {
          const [tenant] = response.data.results;

          commit('setDigitalEnrollmentTenant', { tenant });
          const gradeTracks = tenant.programs.map((program) => ({
            grade_label: program.gradetrack.grade_label,
            stage_label: program.gradetrack.stage_label,
            year: program.year,
          }));

          const { stages, grades } = extractStagesAndGrades(
            { gradeTracks, sort: true, year: getters.yearToApply },
          );
          const uniqueStages = uniqueArrayObjects(stages);
          const uniqueGrades = uniqueArrayObjects(grades);
          const uniqueShifts = uniqueArrayObjects(tenant.programs.map(({ shift }) => shift));
          if (uniqueShifts.length > 0) {
            uniqueShifts.push({
              id: 0,
              shift_name: '-',
              shift_name_en: '-',
              shift_name_es: '-',
            });
          }
          commit('setTenantStages', { stages: uniqueStages });
          commit('setTenantGrades', { grades: uniqueGrades });
          commit('setTenantShifts', { shifts: uniqueShifts });
          commit('setFilteredTenantShifts', { shifts: uniqueShifts });
          return true;
        }

        dispatch(
          'utils/error',
          'Invalid campus on Digital Enrollment Application',
          { root: true },
        );
        return false;
      });
  },
  /**
   * Set the year to apply in the store
   * @param {String} yearToApply - The year to apply
   */
  setYearToApply({ commit }, { yearToApply }) {
    commit('setYearToApply', { yearToApply });
  },
  /**
   * Retrieve the applicant options for the digital enrollment process
   */
  retrieveDigitalEnrollmentApplicantOptions({
    commit, dispatch, state, rootGetters,
  }) {
    const { tenantId } = state.parameters;
    services.digitalEnrollmentService.retrieveShiftPriorities({
      applicantUUID: rootGetters['authentication/currentStudent'].uuid,
      campusCode: tenantId,
    }).then((response) => {
      const { data } = response;
      if (data) {
        commit('setApplicantShiftPriorities', { priorities: data });
      } else {
        dispatch('utils/error', 'Error retrieving shift priorities for applicant', { root: true });
      }
    }).catch((error) => {
      dispatch('utils/error', error, { root: true });
    });
  },
  /**
   * Set the application grade in the store
   * @param {String} grade - The application grade
   */
  async setApplicationGrade({ state, commit }, { grade }) {
    commit('setApplicationGrade', { grade });
    if (grade === null || state.tenant === null) {
      await commit('setFilteredTenantShifts', { shifts: state.tenantShifts });
    } else {
      const gradeShifts = state.tenant.programs
        .filter((program) => program.gradetrack.grade_label.id === grade.id)
        .flatMap(({ shift }) => shift);
      if (gradeShifts.length > 0) {
        gradeShifts.push({
          id: 0,
          shift_name: '-',
          shift_name_en: '-',
          shift_name_es: '-',
        });
      }
      const uniqueGradeShifts = uniqueArrayObjects(gradeShifts);
      await commit('setFilteredTenantShifts', { shifts: gradeShifts.length ? uniqueGradeShifts : state.tenantShifts });
    }
  },
  /**
   * Set the applicant shift priority in the store and in the database
   * @param {Object} shiftPriorities - Payload containing the shift priorities, the applicant UUID and the campus code
   * @param {Array} shiftPriorities.shift_priorities - The shift ids ordered by priority
   * @param {String} shiftPriorities.applicant_uuid - The applicant UUID
   * @param {String} shiftPriorities.campus_code - The campus code
   */
  setApplicantShiftPriorities({ commit, dispatch }, { shiftPriorities }) {
    services.digitalEnrollmentService.postApplicantShiftPriorities({ shiftPriorities }).then((response) => {
      const { data } = response;
      if (data && data.shift_priorities) {
        commit('setApplicantShiftPriorities', { priorities: data.shift_priorities });
      }
    }).catch((error) => {
      dispatch('utils/error', error, { root: true });
    });
  },
  /**
   * Retrieve the dge info of the digital enrollment process
   */
  getDgeHeadmasterResume({ commit, rootGetters }) {
    // NOTE: These are the fields allowed by the API, but we don't need to send them all
    const body = {
    //   page: 0,
    //   pageSize: 10,
    //   filter: {
    //     states: [],
    //     startInterviewDate: null,
    //     endInterviewDate: null,
    //     sortBy: 'CREATED_AT',
    //     sortDirection: 'ASC',
    //   },
    };
    const headmasterSchools = rootGetters['roles/currentHeadMasterSchools'];
    if (!headmasterSchools || headmasterSchools?.length === 0) {
      return;
    }
    const campusCode = rootGetters['roles/currentHeadMasterSchools'][0].campus_code;
    services.digitalEnrollmentService.getDgeResumeHeadMaster({ body, tenantId: campusCode }).then((response) => {
      const dgeInfo = response.data;
      // TODO: HEADMASTER LOGIC ?
      commit('setDgeHeadmasterInfo', { dgeInfo });
    }).catch(() => {
      const dgeInfo = {};
      commit('setDgeHeadmasterInfo', { dgeInfo });
    });
  },
  async getDgeResumeLegalGuardian({ commit, dispatch, state }, { useCache = false, clearCurrent = true } = {}) {
    if (clearCurrent) {
      commit('setDgeSelectedApplication', { application: null });
    }
    // Because we don't have the capability to get a single application, we need to get all of them.
    // We can use the cache if we have already retrieved the applications. This is only used when
    // an individual application is selected from the list. Otherwise, we always retrieve the list.
    // However, we always retrieve the list when the is coming directly from the URL.
    if (useCache) {
      const applications = state.digitalEnrollmentApplications;
      if (applications && applications.length > 0) {
        return Promise.resolve(applications);
      }
    }

    // NOTE: These are the fields allowed by the API, but we don't need to send them all
    const body = {
      // page: 0,
      // pageSize: 15,
      // filter: {
      //   states: [],
      //   startInterviewDate: null,
      //   endInterviewDate: null,
      //   sortBy: 'CREATED_AT',
      //   sortDirection: 'ASC',
      // },
    };
    return services.digitalEnrollmentService.getDgeResumeLegalGuardian({ body }).then(
      (response) => {
        const applications = [];
        const digitalEnrollmentInfo = response.data;
        digitalEnrollmentInfo.resume.forEach((campus) => {
          campus.applications.forEach((application) => {
            const parsed = {
              // FIXME: Maybe the renaming here is confusing, and we should just add the campus info to the
              // application object
              id: application.admissionId,
              status: application.admissionStatus,
              program: {
                id: application.programId,
                name: application.program,
              },
              metadata: {
                paymentId: application.paymentId,
                interview: {
                  date: application.interview?.startAt || null,
                  location: application.interview?.location || null,
                },
                formId: application.formId,
              },
              updated: application.creationDate,
              pendingProcesses: application.pendingProcesses,
              completedProcesses: application.completedProcesses,
              campus: {
                code: campus.campusCode,
                name: campus.campusName,
                thumb: campus.campusLogoUrl,
                address: campus.campusAddress,
              },
              applicant: {
                name: application.applicantName,
                id: application.applicantId,
              },
            };
            applications.push(
              parsed,
            );
            if (parsed.id === state.dgeSelectedApplication?.id) {
              dispatch('setDgeSelectedApplication', { application: parsed });
            }
          });
        });
        commit('setDgeLegalGuardianInfo', { dgeInfo: digitalEnrollmentInfo });
        commit('setDigitalEnrollmentApplications', { applications });
        return Promise.resolve(applications);
      },
    ).catch(() => {
      const applications = [];
      const dgeInfo = {
        resume: applications,
      };
      commit('setDgeLegalGuardianInfo', { dgeInfo });
      commit('setDigitalEnrollmentApplications', { applications });
      return Promise.resolve(applications);
    });
  },
  async getApplicationStatus({ commit, state }, { applicationId }) {
    const admissionId = applicationId ?? state.dgeSelectedApplication?.id ?? null;
    if (admissionId === null) {
      return Promise.resolve(null);
    }
    return services.digitalEnrollmentService.getAdmissionStatus({ admissionId }).then(
      (response) => {
        const status = response.data;
        commit('setApplicationStatus', { status });
        return Promise.resolve(status);
      },
    ).catch(() => {
      const status = null;
      commit('setApplicationStatus', { status });
      return Promise.resolve(status);
    });
  },
  setSuccessfulPaymentTime({ state, commit }, { time }) {
    const paymentTime = time ?? moment().format();
    const paymentId = state.applicationMetadata?.payment?.transactionId;
    const currentState = state.applicationStatus?.status;
    if (paymentId) {
      commit('setSuccessfulPaymentTime', { paymentId, paymentTime, currentState });
    }
  },
  /**
   * Set the selected application in the store. Also, if the application has an id, it will retrieve
   * the status and metadata for that application.
   */
  setDgeSelectedApplication({ commit, dispatch }, { application }) {
    commit('setDgeSelectedApplication', { application });
    if (application?.id) {
      dispatch('getApplicationStatus', { applicationId: application.id });
      dispatch('getApplicationMetadata', { applicationId: application.id });
    }
  },
  async getApplicationMetadata({ commit, dispatch, state }, { applicationId }) {
    const admissionId = applicationId ?? state.dgeSelectedApplication?.id ?? null;
    if (admissionId === null) {
      return Promise.resolve(null);
    }
    const parseMetadata = (metadata) => {
      const interviewDateTime = metadata.interview.start_time ? moment(metadata.interview.start_time) : null;
      const interviewParsedDate = interviewDateTime ? {
        date: interviewDateTime.format('DD/MM/YYYY'),
        time: interviewDateTime.format('hh:mm a'),
        dateTime: interviewDateTime,
      } : {};
      return {
        ...metadata,
        interview: {
          date: interviewParsedDate,
          location: metadata.interview.location,
        },
      };
    };
    return services.digitalEnrollmentService.getAdmissionMetadata({ admissionId }).then(
      (response) => {
        const metadata = parseMetadata(response.data);
        commit('setApplicationMetadata', { metadata });
        const {
          payment: { transactionId: paymentId } = {},
        } = metadata;
        if (paymentId) {
          dispatch('payments/getPaymentStatus', { paymentId }, { root: true })
            .then((status) => {
              commit('setApplicationPaymentStatus', { status });
            });
        }
        return Promise.resolve(metadata);
      },
    ).catch(() => {
      const metadata = null;
      commit('setApplicationMetadata', { metadata });
      commit('setApplicationPaymentStatus', { status: null });
      return Promise.resolve(metadata);
    });
  },
  endStatusPolling({ commit, state }) {
    if (state.currentStatusPoll) {
      clearInterval(state.currentStatusPoll);
      commit('setCurrentStatusPoll', { poll: null });
    }
  },

  /**
   * Polls the application status until a determined success criteria is met.
   * The success criteria can be either a specific status or a change in status (default). To specify a specific status,
   * use the expectedStatus parameter.
   * The polling stops when the success criteria is met, or when the maxAttempts is reached. If the maxAttempts is not
   * specified, the polling will continue indefinitely.
   *
   * The polling is done every intervalTime milliseconds, and the polling is done in the background, so the user can
   * continue using the application while the polling is happening.
   *
   * The polling is done by calling the getApplicationStatus action, so the application status is updated in the store.
   *
   * Callbacks can be specified to be called when the polling is successful or when the polling fails. The callbacks
   * receive three parameters: the latest state returned, the time and the initial state.
   * @param {*} param0
   * @param {*} param1
   */
  async pollApplicationStatus({ commit, dispatch, state }, {
    applicationId,
    intervalTime = 5000, // 5 seconds
    expectedStatus,
    maxAttempts = Infinity,
    successCallback = () => {},
    errorCallback = () => {},
    initialTime = moment(),
    updateApplicationsOnSuccess = true,
  }) {
    dispatch('endStatusPolling');
    const initialStatus = state.applicationStatus?.status;
    const successCriteria = expectedStatus
      ? (status) => status === expectedStatus
      : (status) => status !== initialStatus;
    let remainingAttempts = maxAttempts ?? Infinity;
    let poll = null;
    const now = initialTime ?? moment();
    function pollCallback() {
      dispatch('getApplicationStatus', { applicationId })
        .then(({ status, updatedAt }) => {
          // FIXME: The time returned by the service does not have the timezone, so there's actually no way to know
          // if the time is in UTC or in the local timezone. For now, we assume that the time is in UTC.
          const time = moment(updatedAt).utc();
          // We have moved to another step, so we stop polling the payment status and we update the application list
          if (time > now && (status && successCriteria(status))) {
            clearInterval(poll);
            // Update the application list if the status is successful and the application is not in the list
            if (updateApplicationsOnSuccess) {
              dispatch('getDgeResumeLegalGuardian', { useCache: false, clearCurrent: false });
            }
            successCallback(status, time, initialStatus);
          } else if (remainingAttempts <= 0) {
            clearInterval(poll);
            if (errorCallback) {
              errorCallback(status, time, initialStatus);
            }
          }
          remainingAttempts -= 1;
        });
    }
    poll = setInterval(pollCallback, intervalTime);
    commit('setCurrentStatusPoll', { poll });
    return poll;
  },

  logSuccessfulPaymentTime({ commit, state, dispatch }) {
    const applicationId = state.dgeSelectedApplication?.id;
    const paymentId = state.applicationMetadata?.payment?.transactionId;
    const status = state.applicationStatus?.status;
    if (state.dgeSelectedApplication === null || paymentId === null) {
      return;
    }

    const now = moment();

    dispatch('setSuccessfulPaymentTime', { time: now.format() });

    if (status === ADMISSION_STATUS.COMPLETED) {
      // FIXME: Temporary until digital enrollment service handles the PARTIALLY_PAID status or similar
      // If the application is in the COMPLETED step, we need to poll the payment status until it changes
      // to PAID, and then update the application list
      dispatch(
        'payments/pollPaymentStatus',
        {
          paymentId,
          initialTime: now,
          expectedStatus: ADMISSION_STATUS.PAID,
          iterationCallback: (status, time) => commit('setApplicationPaymentStatus', { status: { status, time } }),
          successCallback: () => dispatch('getDgeResumeLegalGuardian', { useCache: false, clearCurrent: false }),
        },
        { root: true },
      );
    } else {
    // Otherwise, what we actually need to do is to poll the application status until it leaves the WAITING_PAYMENT step
    // and then update the application list
      dispatch('pollApplicationStatus', {
        applicationId,
        initialTime: now,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
