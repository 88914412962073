<template>
  <div class="register-main" style="width: auto">
    <p
      v-t="questionText"
      class="register-main--register-question"
      :class="{ 'register-main--register-question--gray': grayQuestion }"
    />
  </div>
</template>

<script>
export default {
  name: 'QuestionLabel',
  props: {
    questionText: {
      type: String,
      default: '',
    },
    grayQuestion: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
