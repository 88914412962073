var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-main",class:{
    // TODO: CHANGE SECONDARY COLOR rule to fix align
    'align-start': (_vm.secondaryColor || _vm.alignLeft),
    'register-main--width-auto': _vm.widthAuto,
  },on:{"click":function($event){return _vm.trackPrimaryTextClick()},"keydown":function($event){/* TODO */}}},[_c('p',{directives:[{name:"t",rawName:"v-t",value:(_vm.text),expression:"text"}],staticClass:"register-main--primary-text",class:{
      'register-main--primary-text--secondary': _vm.secondaryColor,
      'register-main--primary-text--tertiary': _vm.tertiaryColor,
      'register-main--primary-text--disabled': _vm.disabled,
      'register-main--primary-text--bold': _vm.bold,
      'register-main--primary-text--roboto': _vm.roboto || _vm.fontFamily === 'roboto',
      'register-main--primary-text--poppins': _vm.poppins || _vm.fontFamily === 'poppins',
      'register-main--primary-text--big': _vm.big,
      'register-main--primary-text--small': _vm.small,
      'register-main--primary-text--selected-tertiary': _vm.invertTertiaryColor,
      'register-main--primary-text--neutral500': _vm.neutral500,
      'register-main--primary-text--neutral800': _vm.neutral800,
      'register-main--primary-text--width-auto': _vm.widthAuto,
      'register-main--primary-text--center': _vm.center,
    }})])
}
var staticRenderFns = []

export { render, staticRenderFns }