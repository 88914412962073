import { publicRecordsAPI } from '@/api';

const waitingListRoot = '/waiting-list';

// FIXME: This could be a WaitingList Object passed as a parameter instead of a list of parameters
const waitingList = ({
  // eslint-disable-next-line camelcase
  user_uuid, applicant_id, shift_id, level_id, campus_code, tenant,
}) => {
  const payload = {
    // eslint-disable-next-line camelcase
    user_uuid,
    // eslint-disable-next-line camelcase
    applicant_id,
    // eslint-disable-next-line camelcase
    shift_id,
    // eslint-disable-next-line camelcase
    level_id,
    // eslint-disable-next-line camelcase
    campus_code,
    tenant,
  };
  return publicRecordsAPI.post(`${waitingListRoot}/`, payload);
};

// FIXME: This could in another module as it is not related to payments at all
const getWaitingList = ({ tenant, campusCode, programId }) => publicRecordsAPI.get(`${waitingListRoot}/${tenant}/${campusCode}/${programId}`);

export default {
  waitingList,
  getWaitingList,
};
