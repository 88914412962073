<template>
  <section>
    <TextAtom
      :value="description"
      class="mt-4 text-left d-flex pl-4 pb-3"
      tag="label"
      font="inter"
      color="primary-dark"
      weight="400"
      size="subtitle"
    />
    <div class="d-inline-flex flex-nowrap fill-width align-center">
      <v-expansion-panels accordion flat class="panels__expansions">
        <v-expansion-panel
          v-for="({ language, level }, index) in languages"
          :key="index"
          class="mt-2"
          :readonly="level.length === 0"
          itemprop="availableLanguage"
          itemscope
          itemtype="https://schema.org/Language"
        >
          <v-expansion-panel-header
            class="panels__expansions__headers"
            :hide-actions="level !== null ? true : false"
          >
            <span itemprop="name">{{ getLangName(language) }}</span>
            <template #actions>
              <v-icon color="#2B5BFF">
                $expand
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content v-if="level.length > 0">
            <div class="container-expansion">
              <p class="w-full">
                {{ $t('campus-details.languages.level') }}:
                <span itemprop="unitText" class="ml-1">{{ getLangLvlName(level[0]) }}</span>
              </p>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </section>
</template>
<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';

export default {
  name: 'LanguagePanel',
  components: {
    TextAtom,
  },
  props: {
    languages: {
      type: Array,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
  },
  methods: {
    getLangName(language) {
      if (this.$i18n.locale === 'en') {
        return language.language_name_en;
      }
      return language.language_name;
    },
    getLangLvlName(langLvl) {
      if (this.$i18n.locale === 'en') {
        return langLvl.language_level_name_en;
      }
      return langLvl.language_level_name;
    },
  },
};
</script>
<style scoped>
.container-expansion {
  margin-top: 0.5rem;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  background: #deebff !important;
}

p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 22px;
  text-align: right;
  letter-spacing: 0.05em;
  color: #0d22a8;
}

span {
  font-weight: 400;
}
</style>
