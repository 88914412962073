import CONFIG from '@/config';

// ***********************************************************************************************************
//                                      Digital Enrollment Redirects
// ***********************************************************************************************************

const envDGE = ['production', 'master'].includes(CONFIG.environment) ? '' : 'staging.';
const DGERedirectRoot = `https://${envDGE}${CONFIG.tenant}.digitalenrollment.explorador.com/digital-enrollment`;

/**
 *
 * @param {*} param0.path - relative path to redirect to (e.g. '/new-admission')
 * @param {*} param0.query - query parameters to append to the URL (e.g. { name: 'John', age: 30 })
 * @returns {URL} - URL object with the DGE redirect URL
 */
const computeDGERedirectURL = ({
  path = '', query = {},
}) => {
  const paddedPath = path.startsWith('/') ? path : `/${path}`;
  const url = new URL(`${DGERedirectRoot}${paddedPath}`);
  Object.entries(query).forEach(([key, value]) => url.searchParams.append(key, value));
  return url;
};

/**
 * Redirects to the Digital Enrollment platform
 * @param {*} param0.path - relative path to redirect to (e.g. '/new-admission')
 * @param {*} param0.query - query parameters to append to the URL (e.g. { name: 'John', age: 30 })
 * @param {*} param0.newTab - whether to open the URL in a new tab or not
 * @returns {void}
 * @example
 * redirectToDGE({ path: '/new-admission', query: { name: 'John', age: 30 }, newTab: true });
 * // => opens https://staging.tenant.digitalenrollment.explorador.com/digital-enrollment/new-admission?name=John&age=30 in a new tab
 */
const redirectToDGE = ({ path = '', query = {}, newTab = true }) => {
  const url = computeDGERedirectURL({ path, query });
  window.open(url, newTab ? '_blank' : '_self');
};

export { computeDGERedirectURL, redirectToDGE };
