<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <RowStepper :step="3" />
      <ModalContent
        :content-sections="['smallTitle', 'subtitle', 'selectionButton']"
        :small-title-text="'user_register.additional_info.title'"
        small-title-left
        :subtitle-arg="Object.keys(currentStudent).length > 0 ? currentStudent.first_name : ''"
        :subtitle-text="'user_register.additional_info.subtitle'"
        :selection-button-info="selectionButtonInfo"
        @setSelectionButtonValue="setButtonsValue"
      />
    </div>
    <CallToActions
      :types="['main']"
      active-value
      :main-button-text="'user_register.continue'"
      small
      :spaced="false"
      @continue="nextStep()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';

export default {
  name: 'AdditionalInfoInstitution',
  components: {
    ModalHeader,
    RowStepper,
    ModalContent,
    CallToActions,
  },
  data() {
    return {
      activeIndex: 0,
      selectionButtonInfo: [
        { label: 'user_register.additional_info.yes', isActive: true },
        { label: 'user_register.additional_info.no', isActive: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      students: 'authentication/students',
      currentStudent: 'authentication/currentStudent',
    }),
  },
  methods: {
    ...mapActions({}),
    nextStep() {
      if (this.activeIndex === 1) {
        return this.$emit('setFinalModal');
      }
      this.$emit('nextStep');
      return null;
    },
    setButtonsValue(i) {
      this.activeIndex = i;
      if (i === 0) {
        this.selectionButtonInfo[0].isActive = true;
        this.selectionButtonInfo[1].isActive = false;
      } else {
        this.selectionButtonInfo[0].isActive = false;
        this.selectionButtonInfo[1].isActive = true;
      }
      return this.activeIndex;
    },
  },
};
</script>
