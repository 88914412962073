var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-main register-main--spaced"},[_c('div',{staticClass:"fill-width"},[_c('ModalHeader',{attrs:{"content-sections":['actions', 'logo', 'svgIcon'],"actions-tag":_vm.registerType === 1
        ? 'click_reg_quick-register-legal-guardian-want-add-student_back-button'
        : 'click_reg_detailed-register-legal-guardian-want-add-student_back-button',"small-logo":"","inline-logo":"","svg-icon":'face.svg'},on:{"back":function($event){return _vm.$emit('backStep')}}})],1),_c('ModalContent',{attrs:{"content-sections":['subtitle'],"subtitle-text":'user_register.legal_Step_Modal.subtitle',"subtitle-center":""}}),_c('CallToActions',{attrs:{"option-text1":'user_register.legal_Step_Modal.button1',"option-text-active-tag":_vm.registerType === 1
      ? 'click_reg_quick-register-legal-guardian-want-add-student_add-student-button'
      : 'click_reg_detailed-register-legal-guardian-want-add-student_add-student-button',"option-text2":'user_register.legal_Step_Modal.button2',"option-text-tag":_vm.registerType === 1
      ? 'click_reg_quick-register-legal-guardian-want-add-student_go-to-explore-button'
      : 'click_reg_detailed-register-legal-guardian-want-add-student_go-to-explore-button',"types":['option'],"spaced":false},on:{"nextStep":_vm.nextStep}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }