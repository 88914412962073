<template>
  <section class="register-main">
    <Actions
      v-if="contentSections.includes('actions')"
      :mixpanel-tag="actionsTag"
      :white="white"
      @back="$emit('back')"
    />
    <Close
      v-if="contentSections.includes('close')"
      :no-margin="closeNoMargin"
      @close="$emit('close')"
    />
    <MainTitle
      v-if="contentSections.includes('title')"
      :text="titleText"
      :secondary="secondaryColorTitle"
      :left-align="leftAlignTitle"
      :no-margin="true"
    />
    <Subtitle
      v-if="contentSections.includes('subtitle')"
      :subtitle="subtitleText"
      :secondary="secondaryColorSubtitle"
      :center="centerSubtitle"
      :no-margin="true"
      light
    />
    <div
      :style="contentSections.includes('partnershipLogo')
        ? 'margin-top: 40px' : ''"
      class="d-flex align-center justify-center fill-width"
    >
      <RegisterLogo
        v-if="contentSections.includes('logo')"
        :style="spacedLogo ? 'margin-top: 50px' : 'margin-top: 12px'"
        :small="smallLogo"
        :inline="inlineLogo"
        :medium="mediumLogo"
        :welcome="welcomeTextLogo"
      />
      <Plus v-if="contentSections.includes('partnershipLogo') && partnerLogo" />
      <ImageAtom
        v-if="contentSections.includes('partnershipLogo')"
        :source="partnerLogo"
      />
    </div>
    <SvgIcon v-if="contentSections.includes('svgIcon')" :icon="svgIcon" rounded has-top-margin />
  </section>
</template>

<script>
import Actions from '@/components/atoms/headers/Actions.vue';
import Close from '@/components/atoms/headers/Close.vue';
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';
import ImageAtom from '@/components/atoms/images/Image.vue';
import Plus from '@/components/atoms/labels/Plus.vue';
import RegisterLogo from '@/components/atoms/logos/RegisterLogo.vue';
import Subtitle from '@/components/atoms/subtitles/Subtitle.vue';
import MainTitle from '@/components/atoms/titles/MainTitle.vue';

export default {
  name: 'ModalHeader',
  components: {
    Actions,
    Close,
    MainTitle,
    RegisterLogo,
    SvgIcon,
    Plus,
    ImageAtom,
    Subtitle,
  },
  props: {
    contentSections: {
      type: Array,
      default() {
        return [];
      },
    },
    actionsTag: {
      type: String,
      default: '',
    },
    titleText: {
      type: String,
      default: '',
    },
    secondaryColorTitle: {
      type: Boolean,
      default: false,
    },
    leftAlignTitle: {
      type: Boolean,
      default: false,
    },
    subtitleText: {
      type: String,
      default: '',
    },
    secondaryColorSubtitle: {
      type: Boolean,
      default: false,
    },
    smallLogo: {
      type: Boolean,
      default: false,
    },
    mediumLogo: {
      type: Boolean,
      default: false,
    },
    inlineLogo: {
      type: Boolean,
      default: false,
    },
    welcomeTextLogo: {
      type: Boolean,
      default: false,
    },
    spacedLogo: {
      type: Boolean,
      default: false,
    },
    svgIcon: {
      type: String,
      default: '',
    },
    partnerLogo: {
      type: String,
      default: '',
    },
    centerSubtitle: {
      type: Boolean,
      default: false,
    },
    closeNoMargin: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
