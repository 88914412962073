<template>
  <section class="register-main">
    <v-autocomplete
      v-model="internalValue"
      class="register-main--autocomplete w-full"
      :items="items"
      :item-text="labelKey"
      :placeholder="$t(placeholder)"
      solo
      flat
      background-color="#EBEBEB"
      item-color="#1a0c4c"
      color="#1a0c4c"
      height="48px"
      :return-object="returnObject"
      :disabled="value ? false : disabledValue"
      :readonly="readonly"
      :loading="loading"
      hide-details
      @update:search-input="($event) => $emit('searchInputUpdate', $event)"
      @change="setValue(value)"
      @click="clickRegisterInput()"
      @keyup="keyRegisterInput()"
    />
  </section>
</template>

<script>
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'RegisterAutocomplete',
  components: {},
  props: {
    items: {
      type: [Array, Object],
      default() {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabledValue: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    resetValue: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    labelKey: {
      type: String,
      default: 'name',
    },
    clickMixpanelTag: {
      type: String,
      default: null,
    },
    keyMixpanelTag: {
      type: String,
      default: null,
    },
    mixpanelTag: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Object],
      default: null,
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.setValue(value);
        this.$emit('input', value);
      },
    },
  },
  watch: {
    resetValue: {
      handler() {
        this.internalValue = null;
      },
    },
  },
  methods: {
    setValue(value) {
      if (this.mixpanelTag) {
        trackMixPanel(this.mixpanelTag);
      }
      this.$emit('setValue', value, this.index);
    },
    clickRegisterInput() {
      if (this.clickMixpanelTag) {
        trackMixPanel(this.clickMixpanelTag);
      }
    },
    keyRegisterInput() {
      if (this.keyMixpanelTag) {
        trackMixPanel(this.keyMixpanelTag);
      }
    },
  },
};
</script>
