var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-main register-main--spaced"},[_c('div',{staticClass:"fill-width"},[_c('ModalHeader',{attrs:{"content-sections":['actions', 'title', 'logo'],"title-text":'views.login.password-recovery.title',"actions-tag":_vm.showResend ? 'click_reg_reset-password-sended_back-button' : 'click_reg_reset-password_back-button',"small-logo":""},on:{"back":function($event){return _vm.backMethod()}}}),_c('ModalContent',{attrs:{"content-sections":_vm.infoSent ? ['smallTitle'] : ['subtitle', 'registerInput'],"subtitle-text":_vm.showPhoneRegister
          ? 'views.login.password-recovery.subtitle_phone'
          : 'views.login.password-recovery.subtitle',"small-title-text":_vm.setSmallTitle(),"small-title-primary-color":"","input-section":_vm.inputContent,"error-types":_vm.showPhoneRegister ? [] : ['email'],"input-text-select":_vm.showPhoneRegister ? true : false,"text-select-items":_vm.listCodeArea},on:{"setValue":_vm.setValue,"isInvalidEmail":_vm.isInvalidEmail,"setTextSelectValue":_vm.setDialCode}}),(_vm.showResend && !_vm.resendError)?_c('CallToActions',{attrs:{"types":['question', 'link'],"link-text":'views.login.password-recovery.link-text',"link-text-tag":'click_reg_reset-password-sended_resend-textlink',"question-text":'views.login.password-recovery.question-text'},on:{"toLink":function($event){return _vm.resetPassword()}}}):_vm._e()],1),(!_vm.showResend || (_vm.resendError && _vm.showResend))?_c('CallToActions',{attrs:{"spaced":false,"loader":_vm.recoveryLoader,"types":_vm.resendError ? ['main'] : ['main', 'link'],"link-text":_vm.showPhoneRegister
        ? 'views.login.password-recovery.email_link'
        : 'views.login.password-recovery.phone_link',"active-value":_vm.email,"main-button-text":_vm.resendError
        ? 'user_register.password-recovery.to_register_btn'
        : 'user_register.register_data.btn.send',"main-button-tag":'click_reg_reset-password_send-link-button'},on:{"continue":function($event){_vm.resendError ? _vm.toRegister() : _vm.resetPassword()},"toLink":_vm.setInputType}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }