<template>
  <div id="panels" class="campus-detail__panels mx-0">
    <v-expansion-panels accordion flat>
      <v-expansion-panel
        v-for="({ name, title }, index) in panels"
        :key="index"
        class="mt-2 pl-0 pr-0"
      >
        <v-expansion-panel-header
          v-if="showPanel(name)"
          class="campus-detail__panels__panel-header ml-0 pl-0"
          @click="trackMixPanel(name)"
        >
          <picture class="d-flex align-center">
            <h3 class="panels__performance-title ml-6">
              {{ title }}
            </h3>
          </picture>
          <template #actions>
            <v-icon color="#AEAEAE">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <Simce v-if="name === 'simce'" />
          <PSU v-if="name === 'psu'" />
          <Enrollment v-if="name === 'matricula'" />
          <!-- <Achievements v-if="name === 'logros'" /> -->
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import trackMixPanel from '@/utils/mixpanel';
import Simce from './mini_panels/Simce.vue';
import PSU from './mini_panels/PSU.vue';
import Enrollment from './mini_panels/Enrollment.vue';
// import Achievements from './mini_panels/Achievements.vue';

export default {
  name: 'SimcePanels',
  components: {
    Simce,
    PSU,
    Enrollment,
    // Achievements,
  },
  data() {
    return {
      panels: [],
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'institutions/campusDetails',
      currentStudent: 'authentication/currentStudent',
      isGuest: 'authentication/isGuest',
    }),
  },
  created() {
    this.setPanelsCampusDetail();
  },
  methods: {
    setPanelsCampusDetail() {
      this.panels = [
        {
          name: 'simce',
          title: 'SIMCE 2022-2023',
          isEnable: this.hasScores('simce'),
        },
        {
          name: 'psu',
          title: 'PSU 2018-2021',
          isEnable: this.hasScores('psu'),
        },
        {
          name: 'matricula',
          title: 'Matrícula educación superior',
          isEnable: this.hasEnrollmentInfo(),
        },
        // {
        //   name: 'logros',
        //   title: 'Logros destacados',
        //   isEnable: true,
        // },
      ];
      this.panels = this.panels.filter((panel) => panel.isEnable);
    },
    getNationalScores() {
      const studentsInUniversity = this.campusDetail.higheredstats_campus.find(
        (stats) => stats.highered_stats === 3,
      );
      if (studentsInUniversity !== undefined) {
        return studentsInUniversity.number;
      }
      return false;
    },
    trackMixPanel(nameTrack) {
      if (nameTrack === this.showingPanel) {
        this.showingPanel = '';
      } else {
        const track = `click_school_${nameTrack}`;
        trackMixPanel(track, {
          school_id: this.campusDetail.uuid,
        });
        this.showingPanel = nameTrack;
      }
    },
    showPanel(name) {
      if (name === 'logros' && this.getNationalScores() === false) {
        return false;
      }
      return true;
    },
    hasScores(scoreCategory) {
      let hasScores = false;
      if (this.campusDetail.testscore_campus.length > 0) {
        this.campusDetail.testscore_campus.forEach((score) => {
          if (scoreCategory === 'simce') {
            if (score.testscore_category.testscore_variable_label.id < 5) {
              hasScores = true;
            }
          }
          if (scoreCategory === 'psu') {
            if (score.testscore_category.testscore_variable_label.id === 5) {
              hasScores = true;
            }
          }
        });
      }
      return hasScores;
    },
    hasEnrollmentInfo() {
      if (this.campusDetail.higheredstats_campus.some((stats) => stats.highered_stats_label === 4)) {
        return true;
      }
      return false;
    },
  },
};
</script>
