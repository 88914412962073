<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="
          currentPath === '/login/phone_reset' ? ['title', 'logo'] : ['actions', 'title', 'logo']
        "
        :title-text="'user_register.register_code.title'"
        small-logo
        @back="backMethod()"
      />
      <ModalContent
        :content-sections="showResend ? ['smallTitle'] : ['smallTitle', 'subtitle', 'registerInput']"
        :small-title-text="setSmallTitle()"
        :subtitle-text="
          !storeEmail
            ? 'user_register.password-recovery.subtitle_phone'
            : 'user_register.password-recovery.subtitle_email'
        "
        :input-section="inputContent"
        :error-types="!storeEmail ? [] : ['email']"
        :input-text-select="!storeEmail ? true : false"
        :text-select-items="listCodeArea"
        :small-title-primary-color="showResend ? true : false"
        @setValue="setValue"
        @isInvalidEmail="isInvalidEmail"
        @setTextSelectValue="setDialCode"
      />
      <CallToActions
        v-if="showResend && !resendError"
        style="margin-top: 40px"
        :types="['question', 'link']"
        :link-text="
          !storeEmail
            ? 'user_register.password-recovery.question_phone_text'
            : 'views.login.password-recovery.question-text'
        "
        :question-text="
          !storeEmail
            ? 'user_register.password-recovery.link_phone_text'
            : 'views.login.password-recovery.link-text'
        "
        @toLink="resetPassword()"
      />
    </div>
    <CallToActions
      :spaced="false"
      :types="['main']"
      :active-value="true"
      :loader="recoveryLoader"
      :main-button-text="
        resendError
          ? 'user_register.password-recovery.to_register_btn'
          : 'user_register.register_data.btn.send'
      "
      @continue="resendError ? toRegister() : resetPassword()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import listCodeArea from '@/locales/code_area.json';
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'RegisterPasswordRecovery',
  components: {
    ModalHeader,
    ModalContent,
    CallToActions,
  },
  data() {
    return {
      inputContent: [
        {
          value: null,
          noInfoError: false,
          placeholder: 'user_register.contact.placeholder_email',
          show: true,
          selectValue: '+57',
        },
      ],
      email: null,
      invalidEmail: null,
      recoveryLoader: false,
      infoSent: false,
      showResend: false,
      dialCode: '+57',
      resendError: null,
    };
  },
  computed: {
    ...mapGetters({
      storeEmail: 'authentication/userEmail',
      phoneNumber: 'authentication/userPhone',
    }),
    listCodeArea() {
      const dialCodes = listCodeArea.countries.map((country) => country.dial_code);
      return dialCodes;
    },
    currentPath() {
      return this.$route.path;
    },
  },
  mounted() {
    this.setForcedStep({ step: null });
    if (this.storeEmail) {
      this.email = this.storeEmail;
      this.inputContent[0].value = this.storeEmail;
      return this.email;
    }
    if (this.phoneNumber) {
      this.email = this.phoneNumber;
      this.inputContent[0].value = this.phoneNumber;
      this.inputContent[0].placeholder = 'user_register.register_data.phone-placeholder';
      return this.email;
    }
    if (this.currentPath === '/login/phone_reset') {
      this.inputContent[0].placeholder = 'user_register.register_data.phone-placeholder';
    }
    return null;
  },
  methods: {
    ...mapActions({
      retrieveResetPasswordToken: 'authentication/retrieveResetPasswordToken',
      success: 'utils/success',
      error: 'utils/error',
      setForcedStep: 'userRegister/setForcedStep',
    }),
    isInvalidEmail(bool) {
      this.invalidEmail = bool;
      return this.invalidEmail;
    },
    toRegister() {
      this.setForcedStep({ step: 2 }).then(() => {
        this.$router.push('/login');
      });
    },
    resetPassword() {
      this.infoSent = true;
      if (!this.email || this.invalidEmail) {
        if (!this.email) {
          this.inputContent[0].noInfoError = true;
        }
      } else {
        let authMethod = 'email';
        let phoneNumber;
        if ((this.phoneNumber && !this.storeEmail) || this.currentPath === '/login/phone_reset') {
          phoneNumber = this.dialCode + this.email;
          authMethod = 'phone';
        }
        this.recoveryLoader = true;
        this.retrieveResetPasswordToken({
          email: !this.storeEmail && this.currentPath === '/login/phone_reset' ? null : this.email,
          phone: !this.storeEmail ? phoneNumber : null,
          authMethod,
          callback: (error) => {
            this.recoveryLoader = false;
            let successMsg = 'views.reset_password.sent.email';
            if (authMethod === 'phone') {
              successMsg = 'views.reset_password.sent.sms';
            }
            this.showResend = true;
            if (error === 'ok') {
              this.success(this.$t(successMsg));
            } else {
              this.resendError = true;
            }
          },
        });
        const data = {
          email: phoneNumber ? null : this.email,
          phone: phoneNumber ? this.email : null,
          username: phoneNumber,
          authMethod,
        };
        trackMixPanel('click_send_link_reset_password', data);
      }
    },
    setValue(mainValue, passwordInput, index) {
      if (passwordInput === 0) {
        this.email = mainValue;
        this.inputContent[index].value = mainValue;
        this.inputContent[index].noInfoError = false;
      }
    },
    backMethod() {
      this.$emit('backStep');
    },
    setSmallTitle() {
      if (!this.storeEmail && this.resendError) {
        return 'user_register.password-recovery.sent_phone_error';
      }
      if (this.storeEmail && this.resendError) {
        return 'user_register.password-recovery.sent_email_error';
      }
      if (this.showResend && this.storeEmail) {
        return 'user_register.password-recovery.title_sent_email';
      }
      if (this.showResend && !this.storeEmail) {
        return 'user_register.password-recovery.title_sent_phone';
      }
      if (!this.storeEmail) {
        return 'user_register.password-recovery.title_phone';
      }
      return 'user_register.password-recovery.title';
    },
    setDialCode(index, value) {
      if (index === 0) {
        this.dialCode = value;
      }
      return this.dialCode;
    },
  },
};
</script>
