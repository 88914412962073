<template>
  <div class="register-main register-main--spaced" :class="{ 'roles--center': getLocationLoader }">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        :actions-tag="registerType === 1
          ? 'click_reg_quick-register-add-role_back-button'
          : 'click_reg_detailed-register-add-role_back-button'"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <ModalContent
        :content-sections="['smallTitle', 'instructions', 'infoButton']"
        :small-title-text="'user_register.register_role.title'"
        small-title-left
        small-title-primary-color
        :instruction-text="'user_register.register_role.subtitle'"
        :info-buttons-section="setInfoButtonSection()"
        @setInfoButtonValue="setOption"
      />
      <section v-if="selectedOption.includes(2)">
        <ModalContent
          :content-sections="['subtitle', 'registerInput']"
          :subtitle-text="'user_register.register_role.other_title'"
          subtitle-center
          subtitle-light
          :input-section="inputContent"
          @setValue="setValue"
        />
      </section>
    </div>
    <CallToActions
      :types="['main']"
      :active-value="selectedOption.length > 0"
      :main-button-text="'user_register.continue'"
      :main-button-tag="registerType === 1
        ? 'click_reg_quick-register-add-role_continue-button'
        : 'click_reg_detailed-register-add-role_continue-button'"
      :spaced="false"
      @continue="nextStep()"
    />
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'RolesModal',
  components: {
    ModalHeader,
    ModalContent,
    CallToActions,
  },
  props: {
    // 1 Quick - 2 Detailed
    registerType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selectedOption: [],
      getLocationLoader: false,
      otherString: '',
      inputContent: [
        {
          value: null,
          noInfoError: false,
          placeholder: 'user_register.register_role.other_placeholer',
          show: true,
          clickTag: this.registerType === 1
            ? 'click_reg_quick-register-add-role_other-input' : 'click_reg_detailed-register-add-role_other-input',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      municipalityLabels: 'options/municipalityLabels',
    }),
  },
  methods: {
    ...mapActions({
      identificationLgStep1: 'authentication/identificationLgStep1',
    }),
    nextStep() {
      if (this.selectedOption.includes(0)) {
        this.$emit('askForStudent');
      }
      if (this.selectedOption.includes(1)) {
        return this.$emit('setInterview');
      }
      if (this.selectedOption.includes(2)) {
        return this.$emit('endRegistration');
      }
      return this.$emit('nextStep');
    },
    setOption(i) {
      if (!this.selectedOption.includes(i)) {
        this.selectedOption.push(i);
        return this.selectedOption;
      }
      const newList = this.selectedOption.filter((option) => option !== i);
      this.selectedOption = newList;
      return this.selectedOption;
    },
    setValue(mainValue, passwordInput, index) {
      if (passwordInput === 0 && index === 0) {
        this.otherString = mainValue;
      }
      return null;
    },
    setInfoButtonSection() {
      const sectionInfo = [
        {
          text: 'user_register.register_role.option1',
          withoutTitle: true,
          withoutImg: true,
          isActive: this.selectedOption.includes(0),
          tag: this.registerType === 1
            ? 'click_reg_quick-register-add-role_legal-guardian-button'
            : 'click_reg_detailed-register-add-role_legal-guardian-button',
        },
        {
          text: 'user_register.register_role.option2',
          withoutTitle: true,
          withoutImg: true,
          isActive: this.selectedOption.includes(1),
          tag: this.registerType === 1
            ? 'click_reg_quick-register-add-role_headmaster-button'
            : 'click_reg_detailed-register-add-role_headmaster-button',
        },
        {
          text: 'user_register.register_role.option3',
          withoutTitle: true,
          withoutImg: true,
          isActive: this.selectedOption.includes(2),
          tag: this.registerType === 1
            ? 'click_reg_quick-register-add-role_other-button'
            : 'click_reg_detailed-register-add-role_other-button',
        },
      ];
      return sectionInfo;
    },
  },
};
</script>
