import { TETHER_CATEGORY } from '@/constants/category';
import { TRAVEL_METHOD } from '@/constants/general';

const DISTANCE_LABELS = Object.freeze({
  1: {
    id: 1,
    TETHER_CATEGORY: TETHER_CATEGORY.HIGH,
    TRAVEL_METHOD: TRAVEL_METHOD.WALK,
    UPPER_BOUND: 1,
  },
  2: {
    id: 2,
    TETHER_CATEGORY: TETHER_CATEGORY.MEDIUM,
    TRAVEL_METHOD: TRAVEL_METHOD.WALK,
    UPPER_BOUND: 2,
  },
  3: {
    id: 3,
    TETHER_CATEGORY: TETHER_CATEGORY.MEDIUM_LOW,
    TRAVEL_METHOD: TRAVEL_METHOD.CAR,
    UPPER_BOUND: 5,
  },
  4: {
    id: 4,
    TETHER_CATEGORY: TETHER_CATEGORY.LOW,
    TRAVEL_METHOD: TRAVEL_METHOD.CAR,
    UPPER_BOUND: 10,
  },
  5: {
    id: 5,
    TETHER_CATEGORY: TETHER_CATEGORY.LOW,
    TRAVEL_METHOD: TRAVEL_METHOD.CAR,
    UPPER_BOUND: Infinity,
  },
});

const TRAVEL_TIME_LABELS = Object.freeze({
  1: {
    id: 1,
    UPPER_BOUND: {
      [TRAVEL_METHOD.WALK]: 28,
      [TRAVEL_METHOD.CAR]: 6,
      [TRAVEL_METHOD.BUS]: Infinity,
    },
    TETHER_CATEGORY: TETHER_CATEGORY.HIGH,
  },
  2: {
    id: 2,
    UPPER_BOUND: {
      [TRAVEL_METHOD.WALK]: 47,
      [TRAVEL_METHOD.CAR]: 9,
      [TRAVEL_METHOD.BUS]: Infinity,
    },
    TETHER_CATEGORY: TETHER_CATEGORY.MEDIUM,
  },
  3: {
    id: 3,
    UPPER_BOUND: {
      [TRAVEL_METHOD.WALK]: 75,
      [TRAVEL_METHOD.CAR]: 15,
      [TRAVEL_METHOD.BUS]: Infinity,
    },
    TETHER_CATEGORY: TETHER_CATEGORY.MEDIUM_LOW,
  },
  4: {
    id: 4,
    UPPER_BOUND: {
      [TRAVEL_METHOD.WALK]: Infinity,
      [TRAVEL_METHOD.CAR]: Infinity,
      [TRAVEL_METHOD.BUS]: Infinity,
    },
    TETHER_CATEGORY: TETHER_CATEGORY.LOW,
  },
});

const ADMISSION_RISK_LABELS = Object.freeze({
  1: {
    id: 1,
    TETHER_CATEGORY: TETHER_CATEGORY.LOW,
    UPPER_BOUND: 0.1,
  },
  2: {
    id: 2,
    TETHER_CATEGORY: TETHER_CATEGORY.MEDIUM_LOW,
    UPPER_BOUND: 0.5,
  },
  3: {
    id: 3,
    TETHER_CATEGORY: TETHER_CATEGORY.MEDIUM,
    UPPER_BOUND: 0.9,
  },
  4: {
    id: 4,
    TETHER_CATEGORY: TETHER_CATEGORY.HIGH,
    UPPER_BOUND: Infinity,
  },
});

const MODALITY_LABELS = Object.freeze({
  TP: [
    7, 8, 9, 10, 11, 32, 33, 34, 35, 36,
  ],
  HC: [
    1,
  ],
  AR: [
    12,
  ],
});

export {
  DISTANCE_LABELS, TRAVEL_TIME_LABELS, ADMISSION_RISK_LABELS, MODALITY_LABELS,
};
