<template>
  <div class="register-main register-main--spaced">
    <div>
      <HeaderModal :content-sections="['close']" @close="$emit('close')" />
      <MainTitle
        class="pt-0"
        :text="'digital_enrollment.waiting-list.welcome.login_title'"
        primary
        left-align
      />
      <ContentModal
        :content-sections="['subtitle']"
        subtitle-center
        :subtitle-text="'digital_enrollment.waiting-list.welcome.login_subtitle'"
        subtitle-light
      />
    </div>
    <CallToActions
      :option-text1="'digital_enrollment.waiting-list.welcome.login_button'"
      :option-text2="'digital_enrollment.waiting-list.welcome.register_button'"
      :types="['option']"
      :spaced="false"
      option-btn-no-margin
      @nextStep="nextStep"
    />
  </div>
</template>

<script>
import MainTitle from '@/components/atoms/titles/MainTitle.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ContentModal from '@/components/molecules/modals/Content.vue';
import HeaderModal from '@/components/molecules/modals/Header.vue';
import { mapActions } from 'vuex';

export default {
  name: 'LogInModal',
  components: {
    HeaderModal,
    CallToActions,
    ContentModal,
    MainTitle,
  },
  methods: {
    ...mapActions({
      setForcedStep: 'userRegister/setForcedStep',
    }),
    nextStep(i) {
      this.$emit('next', i - 1);
    },
  },
};
</script>
