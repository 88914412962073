import { TENANT_CONFIGURATION } from '@/constants/tenant';

const { DEFAULTS: { LOCALE: locale, CURRENCY: fallbackCurrency } } = TENANT_CONFIGURATION;

/**
 * Display an amount of money with the correct currency. If the currency is not
 * valid or not available, the fallback currency will be used.
 * @param {*} amount - The amount of money to display
 * @param {*} currency - The currency to use
 * @returns {string} The amount of money with the correct currency format
 */
function amountDisplayName(amount, currency = fallbackCurrency) {
  if (!amount) {
    return '';
  }
  try {
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    });
    return formatter.format(amount);
  } catch (error) {
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: fallbackCurrency,
    });
    return formatter.format(amount);
  }
}

export { amountDisplayName };
