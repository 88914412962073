<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        :actions-tag="'click_reg_register-quick-or-detailed_back-button'"
        inline-logo
        small-logo
        @back="$emit('toRegister')"
      />
      <ModalContent
        :content-sections="['subtitle']"
        :subtitle-text="'user_register.register_type.title'"
        subtitle-center
      />
      <CallToActions
        :info-buttons-section="sectionInfo"
        :types="['info']"
        :info-title-text1="'user_register.register_type.fast_title'"
        :info-title-text2="'user_register.register_type.detail_title'"
        :time1="'user_register.register_type.fast_time'"
        :time2="'user_register.register_type.detail_time'"
        @infoNextStep="nextStep"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';

export default {
  name: 'RegisterType',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
  },
  data() {
    return {
      isFastSelected: null,
      sectionInfo: [
        [
          {
            img: 'bookmark.svg',
            text: 'user_register.register_type.list_text',
            tag: 'click_reg_register-quick-or-detailed_quick-button',
          },
          {
            img: 'road.svg',
            text: 'user_register.register_type.road_text',
          },
        ],
        [
          {
            img: 'bookmark.svg',
            text: 'user_register.register_type.list_text',
            tag: 'click_reg_register-quick-or-detailed_detailed-button',
          },
          {
            img: 'road.svg',
            text: 'user_register.register_type.road_text',
          },
          {
            img: 'chart-icon.svg',
            text: 'user_register.register_type.simulate-text',
          },
        ],
      ],
    };
  },
  methods: {
    ...mapActions({}),
    nextStep(index) {
      // 2 for slow, 1 for fast
      let registerType = 2;
      if (index === 0) {
        this.isFastSelected = true;
        registerType = 1;
      }
      this.$emit('setRegisterType', registerType);
      this.$emit('nextStep');
    },
  },
};
</script>
