import { backendAPI, digitalEnrollmentAdmissionsAPI, digitalEnrollmentResumedAPI } from '@/api';

const institutionsUrl = '/institutions';
const resumeUrl = 'v1/admissions/resume';
export default {
  // Shift Priorities should move to the registration service
  postApplicantShiftPriorities({ shiftPriorities }) {
    return backendAPI.post(`${institutionsUrl}/shift-priorities/`, shiftPriorities);
  },
  retrieveShiftPriorities({ applicantUUID, campusCode }) {
    const params = {
      applicant_uuid: applicantUUID,
      campus_code: campusCode,
    };
    return backendAPI.get(`${institutionsUrl}/shift-priorities/for-applicant/`, { params });
  },
  getDgeResumeHeadMaster({ body, tenantId }) {
    digitalEnrollmentResumedAPI.defaults.headers['X-TenantId'] = tenantId;
    return digitalEnrollmentResumedAPI.post(`${resumeUrl}/headmaster/`, body);
  },
  async getDgeResumeLegalGuardian({ body }) {
    return digitalEnrollmentResumedAPI.post(`${resumeUrl}/legal-guardian/`, body);
  },

  async getAdmissionInfo({ admissionId }) {
    return digitalEnrollmentAdmissionsAPI.get(`${admissionId}/`);
  },

  async getAdmissionStatus({ admissionId }) {
    return digitalEnrollmentAdmissionsAPI.get(`${admissionId}/status/`);
  },

  async getAdmissionMetadata({ admissionId }) {
    return digitalEnrollmentAdmissionsAPI.get(`${admissionId}/metadata/`);
  },
};
