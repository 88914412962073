<template>
  <div>
    <FeedbackModal />
  </div>
</template>

<script>
import FeedbackModal from './modals/FeedbackModal.vue';

export default {
  name: 'FeedbackModals',
  components: {
    FeedbackModal,
  },
};

</script>
