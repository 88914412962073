import { MapElementFactory } from 'vue2-google-maps';

const getRendererOptions = (travelMode, optionalColor) => {
  const color = optionalColor ?? (travelMode === 'WALKING' ? '#27F3DE' : '#DDD4FF');
  const pathSettings = {
    strokeColor: color,
    strokeOpacity: 0.8,
    strokeWeight: 4,
  };
  const circleSettings = {
    fillColor: color,
    strokeColor: color,
    scale: 3,
    strokeOpacity: 1,
    fillOpacity: 0.8,
    strokeWeight: 1,
  };

  const circleSymbol = {
    path: window.google.maps.SymbolPath.CIRCLE,
    ...circleSettings,
  };

  const options = {
    suppressMarkers: true,
    polylineOptions: {
      ...pathSettings,
    },
  };

  if (travelMode === 'WALKING') {
    options.polylineOptions = {
      ...options.polylineOptions,
      strokeOpacity: 0,
      icons: [{
        icon: circleSymbol,
        offset: '0px',
        repeat: '15px',
      }],
    };
  }
  return options;
};

export default MapElementFactory({
  name: 'directionsRenderer',
  ctr() {
    return window.google.maps.DirectionsRenderer;
  },
  events: [],
  mappedProps: {},
  props: {
    origin: { type: [Object, Array] },
    destination: { type: [Object, Array] },
    travelMode: { type: String },
    map: { type: Object },
    render: { type: Object },
    strokeColor: { type: String },
  },
  afterCreate(directionsRenderer) {
    const directionsService = new window.google.maps.DirectionsService();

    const rendererOptions = getRendererOptions(this.travelMode, this.strokeColor);
    if (this.render.request) {
      directionsRenderer.setOptions(rendererOptions);
      directionsRenderer.setDirections(this.render);
    } else {
      const { origin, destination, travelMode } = this;
      directionsService.route(
        {
          origin,
          destination,
          travelMode,
        },
        (response, status) => {
          if (status !== 'OK') {
            this.$emit('response-directions', response, true);
            return;
          }
          directionsRenderer.setOptions(rendererOptions);
          directionsRenderer.setDirections(response);
          this.$emit('response-directions', response);
        },
      );
    }
  },
});
