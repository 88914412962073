<template>
  <div class="register-main">
    <div
      class="register-main--btn-div flex-column"
      :class="{
        'register-main--btn-div--no-margins': noMargin,
      }"
      style="margin-top: 12px"
    >
      <div
        v-if="loader"
        class="
          register-main--btn-div--btn-main
          register-main--btn-div--btn-main--square-border
          register-main--btn-div--btn-main--square-border-active
        "
        :class="{
          'register-main--btn-div--btn-main--legacy-primary-loader': legacyPrimary,
        }"
      >
        <v-progress-circular
          style="margin: 4px;"
          class="d-flex align-center justify-center"
          indeterminate
          color="white"
        />
      </div>
      <button
        v-if="!loader"
        type="button"
        class="register-main--btn-div--btn-main"
        :style="{ '--color': color }"
        :class="{
          'register-main--btn-div--btn-main--active': isActive,
          'register-main--btn-div--btn-main--square-border': squareBorders,
          'register-main--btn-div--btn-main--legacy-primary': legacyPrimary,
          'register-main--btn-div--btn-main--legacy-medium': legacyMedium,
          'register-main--btn-div--btn-main--legacy-medium-active': legacyMedium && isActive,
          'register-main--btn-div--btn-main--legacy-primary-active': legacyPrimary && isActive,
          'register-main--btn-div--btn-main--primary-medium': primaryMedium,
          'register-main--btn-div--btn-main--primary-medium--mobile': primaryMedium && mobile,
          'register-main--btn-div--btn-main--primary-medium--active': primaryMedium && isActive,
          'register-main--btn-div--btn-main--text-small': textSmall,
          'register-main--btn-div--btn-main--disabled-active': disabledActive,
          'register-main--btn-div--btn-main--disabled': disabled,
          'register-main--btn-div--btn-main--borders': !light,
          'register-main--btn-div--btn-main--light': light,
          'register-main--btn-div--btn-main--solid-background': solidBackground,
          'register-main--btn-div--btn-main--solid-background--disabled': solidBackground && disabled,
          'register-main--btn-div--btn-main--solid-background--mobile': solidBackground && mobile,
        }"
        @click="disabled || disabledActive ? null : buttonClick()"
      >
        <SvgIcon v-if="svgIcon" :icon="svgIcon" :size="'24'" class="mr-auto ml-5" />
        <span :class="svgIcon ? 'mr-auto d-flex' : ''"> {{ $t(text) }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'OptionButton',
  components: {
    SvgIcon,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
    squareBorders: {
      type: Boolean,
      default: false,
    },
    loader: {
      type: Boolean,
      default: false,
    },
    mixpanelTag: {
      type: String,
      default: null,
    },
    legacyPrimary: {
      type: Boolean,
      default: false,
    },
    legacyMedium: {
      type: Boolean,
      default: false,
    },
    primaryMedium: {
      type: Boolean,
      default: false,
    },
    textSmall: {
      type: Boolean,
      default: false,
    },
    svgIcon: {
      type: String,
      default: null,
    },
    disabledActive: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    solidBackground: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#5F2AF3',
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    buttonClick() {
      if (this.mixpanelTag) {
        trackMixPanel(this.mixpanelTag);
      }
      this.$emit('nextStep');
    },
  },
};
</script>
