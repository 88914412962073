import { backendAPI } from '@/api';

const appUrl = '/plugins';

export function retrieveLandingPluginData(pluginCode) {
  return backendAPI.get(`${appUrl}/landing/${pluginCode}`);
}
export function retrieveLandingPluginLevels(pluginCode) {
  return backendAPI.get(`${appUrl}/landing/${pluginCode}/grades`);
}
export function retrieveLandingPluginLocations(pluginCode) {
  return backendAPI.get(`${appUrl}/landing/${pluginCode}/locations`);
}
