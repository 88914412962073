/**
 * Service handling all payments logic - connects two APIs: our own payments orchestrator and the providers API
 */
import { paymentsAPI } from '@/api';
import { stripTailingSlash } from '@/utils/strings';

const paymentsRoot = '/payments';
const methodsRoot = '/payment-methods';
const linkBankRoot = '/link-account';
const providerRoot = '/providers';
const bankAccountRoot = '/bank-accounts';
const customersRoot = '/customers';

/**
 * Build the URI to submit the payment flow in the payments service
 * @param {*} param0 - payment and flowId
 * @returns {string} - URI to submit the payment flow
 */
const buildSubmitFlowUri = ({ paymentId, flowId }) => paymentsAPI.getUri({
  url: `${paymentsRoot}/${paymentId}/submit_flow/${flowId}/`,
});

/**
 * Some providers submit the payment flow by sending a POST request themselves, so we need to be able to build the URL
 * consistently with our API
 * @param {*} param0 - payment and flowId
 * @returns {string} - URL to submit the payment flow
 */
const buildSubmitFlowUrl = ({ paymentId, flowId }) => (
  stripTailingSlash(paymentsAPI.defaults.baseURL) + buildSubmitFlowUri({ paymentId, flowId })
);

const initializePaymentFlow = ({
  provider, method, payment, ...otherFields
}) => {
  const payload = {
    provider: provider.provider_code,
    method: method.code_name,
    ...otherFields,
  };
  return paymentsAPI.post(`${paymentsRoot}/${payment.id}/initialize_flow/`, payload);
};

const initializeLinkIntent = (fullPayload) => paymentsAPI.post(`${linkBankRoot}/initialize/`, fullPayload);

const exchangeToken = ({ payload }) => paymentsAPI.post(`${linkBankRoot}/exchange-token/`, payload);

const finishLinkAccount = ({ payload }) => paymentsAPI.post(`${linkBankRoot}/finish/`, payload);

const getBankAccount = ({ entityCode }) => paymentsAPI.get(`${bankAccountRoot}/by-entity-code/${entityCode}`);

const submitPaymentFlow = ({
  payment, flowId, flowData = {},
}) => paymentsAPI.post(buildSubmitFlowUri({ paymentId: payment.id, flowId }), flowData);

// Customer is obtained from the auth header, so we don't need to pass it here
const initializeCustomer = (
  { providerCode, precheck = false },
) => paymentsAPI.post(`${customersRoot}/initialize/`, { provider: providerCode, precheck });

const updateCustomer = ({ customerData }) => paymentsAPI.put(`${customersRoot}/update/`, { ...customerData });

const getPayment = ({ id }) => paymentsAPI.get(`${paymentsRoot}/${id}/`);

const getPaymentStatus = ({ paymentId }) => paymentsAPI.get(`${paymentsRoot}/${paymentId}/status/`);

const getPayments = () => paymentsAPI.get(`${paymentsRoot}/`);

const getProvider = ({ providerCode }) => paymentsAPI.get(`${providerRoot}/by-code/${providerCode}/`);

const getAvailablePaymentMethods = ({ entity }) => paymentsAPI.get(`${methodsRoot}/entity/${entity}/`);

const getAllAvailablePaymentMethods = () => paymentsAPI.get(`${methodsRoot}/`);

export default {
  initializeLinkIntent,
  initializePaymentFlow,
  submitPaymentFlow,
  initializeCustomer,
  updateCustomer,
  getAvailablePaymentMethods,
  getAllAvailablePaymentMethods,
  getPayment,
  getPaymentStatus,
  getPayments,
  exchangeToken,
  getProvider,
  finishLinkAccount,
  getBankAccount,
  buildSubmitFlowUrl,
};
