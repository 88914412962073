import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import authentication from './authentication.module';
import digitalEnrollment from './digitalEnrollment.module';
import elasticSearch from './elasticSearch.module';
import explorer from './explorer.module';
import favorites from './favorites.module';
import feedback from './feedback.module';
import filters from './filters.module';
import geoTools from './geoTools.module';
import institutions from './institutions.module';
import loading from './loading.module';
import locale from './locale.module';
import messaging from './messaging.module';
import options from './options.module';
import payments from './payments.module';
import roles from './roles.module';
import simulation from './simulation.module';
import newStudentRegister from './studentRegister.module';
import userRegister from './userRegister.module';
import utils from './utils.module';
import widgets from './widgets.module';

Vue.use(Vuex);

const dataState = createPersistedState({
  paths: ['authentication', 'locale', 'favorites', 'roles', 'userRegister'],
});

export default new Store({
  plugins: [dataState],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    authentication,
    digitalEnrollment,
    elasticSearch,
    explorer,
    favorites,
    feedback,
    filters,
    geoTools,
    institutions,
    loading,
    locale,
    messaging,
    newStudentRegister,
    options,
    payments,
    roles,
    widgets,
    simulation,
    userRegister,
    utils,
  },
});
