import { geoToolsAPI, webhooksAPI } from '@/api';
import CONFIG from '@/config';

const busRoutes = '/transport/bus/routes/';
const travel = '/transport/traveltime/';
// const bus = '/transport/bus/routes';
const geocoding = '/geocoding';
const places = '/places';

let textSearchRequestController;

export default {
  getAddressGeocoding(location) {
    return geoToolsAPI.post(`${geocoding}/geocode`, { location, new_version: true });
  },
  getAddressReverseGeocoding(reversGeocoding) {
    return geoToolsAPI.post(`${geocoding}/revgeocode`, reversGeocoding);
  },
  getCommuneFromText({ searchTerm }) {
    // Abort previous request
    if (textSearchRequestController) {
      textSearchRequestController.abort();
    }
    textSearchRequestController = new AbortController();
    const { signal } = textSearchRequestController;
    return geoToolsAPI.post(
      `${places}/search_places`,
      {
        substring: searchTerm,
      },
      {
        signal,
      },
    );
  },
  getAddressFromText({ searchTerm, codeNational }) { // now this works instead of getAddressGeocoding
    // Abort previous request
    if (textSearchRequestController) {
      textSearchRequestController.abort();
    }
    textSearchRequestController = new AbortController();
    const { signal } = textSearchRequestController;
    return geoToolsAPI.post(
      `${geocoding}/single_text_geocode`,
      {
        address: searchTerm,
        code_national: codeNational,
      },
      {
        signal,
      },
    );
  },

  getAddressInGoogle({ searchTerm, codeNational }) {
    return geoToolsAPI.post(
      `${geocoding}/search_gmaps`,
      {
        address: searchTerm,
        code_national: codeNational,
      },
    );
  },

  setGeoHistory({ params, typeHistory }) {
    return webhooksAPI.post('geotools-events/', {
      ...params,
      type_history: typeHistory,
      tenant: CONFIG.tenant,
      env: CONFIG.env,
    });
  },

  sendPlaceHistory({ place }) {
    return geoToolsAPI.post(`${places}/search_places_history`, place);
  },

  sendGeocodingHistory({ geoInfo }) {
    return geoToolsAPI.post(`${geocoding}/geocoding_history`, geoInfo);
  },
  // // eslint-disable-next-line camelcase
  // retrieveBusRoutes(lat, lng, campus_code) {
  //   return geoToolsAPI.post(`${busRoutes}`, {
  //     lat,
  //     lng,
  //     campus_code,
  //   });
  // },

  retrieveBusRoutes({ travelTime }) {
    return geoToolsAPI.post(`${busRoutes}`, {
      lat: travelTime.lat,
      lng: travelTime.lng,
      campus_code: travelTime.campusCode,
    });
  },

  retrieveTravelTime({ travelTime }) {
    return geoToolsAPI.post(`${travel}`, {
      src_lat: travelTime.campusLat,
      src_lng: travelTime.campusLng,
      dst_lat: travelTime.lat,
      dst_lng: travelTime.lng,
    });
  },
};
