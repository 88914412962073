<template>
  <div class="campus-detail__panels__container campus-detail__panels__container__3 pl-6 pr-6">
    <TextAtom
      class="text-left"
      :value="$t('campus-details.current_performance.achievements_title')"
      :color="'primary-dark'"
      font="inter"
      weight="400"
    />
    <div class="d-flex flex-wrap fill-width justify-center">
      <div
        v-for="(year, index) in yearList"
        :key="index"
        class="panel_container_row mb-3"
        :class="{ 'mt-0': index === 3 }"
      >
        <button
          v-if="hasPsuScore(year)"
          v-t="year"
          type="button"
          class="panel_button__color panel_button__color__text"
          :class="yearButtonClasses(year, index)"
          @click="chooseYear(year)"
        />
      </div>
    </div>
    <section
      class="fill-width mt-7 mb-2"
      :class="{ 'mb-0': !mobile }"
    >
      <div
        v-for="(numberList, index) in yearList.length"
        :key="index"
        class="row mr-2 ml-2 panel_container_column"
      >
        <div
          v-if="getPsuScore(numberList)"
          v-t="getPsuLabel(numberList)"
          class="panel_container_column--item column"
        />
        <div
          v-if="getPsuScore(numberList)"
          class="panel_container_column--item column"
          :style="mobile ? 'border-radius: 0px 4px 4px 0px;' : ''"
        >
          <span>{{ getPsuScore(numberList) }}</span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';

export default {
  name: 'PSU',
  components: {
    TextAtom,
  },
  data() {
    return {
      moment,
      current_chosen: '2021',
      psuScores: {},
      yearList: [],
    };
  },
  computed: {
    ...mapGetters({
      campusDetails: 'institutions/campusDetails',
    }),
    mobile() {
      return this.$vuetify.breakpoint.width < 630;
    },
  },
  mounted() {
    this.initializeYearListAndScores();
  },
  methods: {
    initializeYearListAndScores() {
      this.yearList = [
        (this.moment().year() - 4).toString(),
        (this.moment().year() - 3).toString(),
        (this.moment().year() - 2).toString(),
        (this.moment().year() - 1).toString(),
      ];

      const newScore = [];
      this.campusDetails.testscore_campus.forEach((score) => {
        if (score.testscore_category.testscore_variable_label.id === 5) {
          const key = score.year;
          if (newScore[key] === undefined) {
            newScore[key] = [];
          }
          if (score) {
            newScore[key].push(score);
          }
        }
      });
      this.psuScores = newScore;
    },
    chooseYear(grade) {
      this.current_chosen = grade;
    },
    getPsuScore(subject) {
      const yearChosen = parseInt(this.current_chosen, 10);
      const psuScore = this.psuScores[yearChosen]?.find(
        (score) => score.testscore_category.testscore_area_label.id === subject
          && score.testscore_category.testscore_variable_label.id === 5,
      );
      return psuScore ? Math.round(psuScore.mean) : null;
    },
    getPsuLabel(numberList) {
      const labels = [
        'campus-details.panels.math',
        'campus-details.panels.language.title',
        'campus-details.panels.history',
        'campus-details.panels.science',
      ];
      return numberList >= 1 && numberList <= labels.length ? labels[numberList - 1] : '';
    },
    hasPsuScore(year) {
      const yearChosen = parseInt(year, 10);
      return !!this.psuScores[yearChosen];
    },
    yearButtonClasses(year, index) {
      return {
        'panel_button__color__salmon mt-3': year === (moment().year() - 4).toString(),
        'panel_button__color__green mt-3': year === (moment().year() - 3).toString(),
        'panel_button__color__orange mt-3': year === (moment().year() - 2).toString(),
        'panel_button__color__grey mt-3': year === (moment().year() - 1).toString()
          && Object.keys(this.psuScores).length === 4
          && this.$vuetify.breakpoint.width > 432,
        'panel_button__color__grey mt-0': year === (moment().year() - 1).toString()
          && Object.keys(this.psuScores).length === 4
          && this.$vuetify.breakpoint.width < 432,
        panel_button__color__deactivated: this.current_chosen !== year,
        'mr-0': Object.keys(this.psuScores).length === 1,
        'mt-2': this.$vuetify.breakpoint.width < 432
          && Object.keys(this.psuScores).length === 4
          && index === 4,
      };
    },
  },
};
</script>
