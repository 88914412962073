import {
  TETHER_FILTER_KEYS as FILTER_KEYS,
  TETHER_FILTER_CONTEXTS as FILTER_CONTEXTS,
} from '@/constants/filters';
import {
  blankFilterContext,
  schoolMatchesFilters,
  getDistanceCategoryBounds,
} from '@/utils/filters';

/**
  * Helper function to check if there are different schools in a year
  so we can retrieve the vacancies only with vuex and not to call the API again.
  * @param {Object} year - The year to check
  * @param {Object} schools - The schools to check
  * @param {Object} schoolsVacancies - The schoolsVacancies to check
  * @returns {Boolean} - True if there are different schools in the year, false otherwise
  * @example
*/
const differentSchoolsInYear = ({ year, schools, schoolsVacancies }) => {
  const campusesCodes = schools.map((school) => school.campus_code);
  const vacancies = schoolsVacancies[year];
  const vacanciesCampuses = Object.keys(vacancies);
  const differentCampuses = vacanciesCampuses.filter((campus) => !campusesCodes.includes(campus));
  return differentCampuses.length > 0;
};
/**
 * Helper function to initialize the values of the filters object with the default value for each context
 *
 * @param {Function} defaultConstructor - A function that returns the default value for the context key
 * @returns {Object} - An object with the same keys as FILTER_CONTEXTS, but with the default value for each context
 * @example
 * initializeValues(() => new Set()); // { explorer: new Set(), ... }
 * initializeValues(() => []); // { explorer: [], ... }
 */
const initializeValues = (defaultConstructor) => (
  Object.values(FILTER_CONTEXTS).reduce((acc, context) => { acc[context] = defaultConstructor(); return acc; }, {})
);

const getDefaultState = () => ({
  context: FILTER_CONTEXTS.EXPLORER,
  filters: initializeValues(blankFilterContext),
  filteredSchools: initializeValues(() => []),
});

const state = {
  ...getDefaultState(),
};

const getters = {
  activeFiltersCount: ({ filters, context }) => {
    const contextFilters = filters[context];
    const {
      [FILTER_KEYS.DISTANCE_RAW]: distanceRaw,
      ...setFilters
    } = contextFilters;
    return (
      Object.values(setFilters).filter((filter) => filter?.size > 0).length
      + (distanceRaw ? 1 : 0)
    );
  },
  publicActiveFiltersCount: ({ filters, context }) => {
    // Some filters are not public, so we don't want to show them in the count
    const contextFilters = filters[context];
    const privateFilters = new Set([
      FILTER_KEYS.PROCESS_ID,
      FILTER_KEYS.DISTANCE_RAW,
      FILTER_KEYS.INSTITUTION,
    ]);
    return Object.values(contextFilters).reduce(
      (acc, filter) => {
        if (!privateFilters.has(filter) && filter instanceof Set) {
          return acc + (filter.size > 0 ? 1 : 0);
        }
        return acc;
      },
      0,
    );
  },
  anyVacanciesFilterActive: ({ filters }) => Object.values(filters)
    .some((cf) => Boolean(cf[FILTER_KEYS.HAS_VACANCIES].size)),
  multimediaFilter: ({ filters, context }) => filters[context][FILTER_KEYS.MULTIMEDIA],
  agreementFilter: ({ filters, context }) => filters[context][FILTER_KEYS.AGREEMENTS],
  modalityFilter: ({ filters, context }) => filters[context][FILTER_KEYS.MODALITY],
  paymentFilter: ({ filters, context }) => filters[context][FILTER_KEYS.PAYMENT],
  performanceFilter: ({ filters, context }) => filters[context][FILTER_KEYS.PERFORMANCE],
  distanceFilter: ({ filters, context }) => filters[context][FILTER_KEYS.DISTANCE],
  rawDistanceFilter: ({ filters, context }) => filters[context][FILTER_KEYS.DISTANCE_RAW],
  vacanciesFilter: ({ filters, context }) => filters[context][FILTER_KEYS.HAS_VACANCIES],
  dependencyFilter: ({ filters, context }) => filters[context][FILTER_KEYS.DEPENDENCIES],
  infrastructureFilter: ({ filters, context }) => filters[context][FILTER_KEYS.INFRASTRUCTURE],
  sportFilter: ({ filters, context }) => filters[context][FILTER_KEYS.SPORTS],
  extracurricularFilter: ({ filters, context }) => filters[context][FILTER_KEYS.EXTRACURRICULARS],
  languageFilter: ({ filters, context }) => filters[context][FILTER_KEYS.LANGUAGES],
  specialtyFilter: ({ filters, context }) => filters[context][FILTER_KEYS.SPECIALTIES],
  partnershipFilter: ({ filters, context }) => filters[context][FILTER_KEYS.PARTNERSHIPS],
  navbarVacanciesFilter: ({ filters }) => filters[FILTER_CONTEXTS.NAVBAR][FILTER_KEYS.HAS_VACANCIES],
  filters: ({ filters, context }) => filters[context],
  filteredExplorerSchools: ({ filteredSchools }) => filteredSchools[FILTER_CONTEXTS.EXPLORER],
  filteredFavoritesSchools: ({ filteredSchools }) => filteredSchools[FILTER_CONTEXTS.FAVORITES],
  filteredAdmissionSystem: ({ filters, context }) => filters[context][FILTER_KEYS.ADMISSION],
};

const mutations = {
  resetStore(state) {
    Object.assign(state, getDefaultState());
  },
  setContext(state, { context }) {
    state.context = context;
  },
  resetContextFilters(state) {
    const { context } = state;
    state.filters[context] = blankFilterContext();
  },
  setFilter(state, { key, value, context }) {
    // Set hasn't reactivity in Vue, so we need to create a new Set to trigger the reactivity
    const newValue = new Set([...value]);
    state.filters[context][key] = newValue;
  },
  setFilteredSchools(state, { schools }) {
    const { context } = state;
    state.filteredSchools[context] = schools;
  },
  setAllFilters(state, { filters }) {
    const { context } = state;
    state.filters[context] = filters;
  },
};

const actions = {
  resetStore({ commit }) {
    commit('resetStore');
  },
  resetContext({ commit, dispatch, getters }) {
    if (getters.distanceFilter.size > 0 || getters.rawDistanceFilter) {
      dispatch('explorer/configureDistanceCircle', { }, { root: true });
    }
    commit('resetContextFilters');
    commit('setFilteredSchools', { schools: [] });
  },
  setContext({ state, commit }, { context = FILTER_CONTEXTS.EXPLORER }) {
    if (context && Object.values(FILTER_CONTEXTS).includes(context) && context !== state.context) {
      commit('setContext', { context });
    }
  },
  handleDistanceFilterChange({ dispatch, getters }) {
    const { distanceFilter, rawDistanceFilter } = getters;

    const distance = (
      rawDistanceFilter
      || (distanceFilter.size && getDistanceCategoryBounds(Math.max(...distanceFilter)).upperBound)
    );

    if (distance && distance !== Infinity) {
      // Valid distance - update the radius
      dispatch('explorer/configureDistanceCircle', { radius: distance * 1000 }, { root: true });
    } else if (distanceFilter.size) {
      // There are distance filters, but the distance is invalid, hide the circle and reset the distance
      // i.e. The distance is 0 or Infinity
      dispatch('explorer/configureDistanceCircle', { show: false }, { root: true });
    }
  },
  commitFilters({ commit, dispatch }, { filters, updateSchools = true }) {
    const allFilters = {
      ...blankFilterContext(),
      ...filters,
    };
    commit('setAllFilters', { filters: allFilters });
    if (updateSchools) {
      dispatch('updateSchools');
    }
    dispatch('handleDistanceFilterChange');
  },
  setFilter({ commit, state }, { key, value, context }) {
    const { context: currentContext } = state;
    commit('setFilter', { key, value, context: context || currentContext });
  },
  setFiltersInFavorites({ commit }, { filters }) {
    commit('setFiltersInFavorites', { filters });
  },
  async updateSchools({
    state, commit, dispatch, getters, rootGetters,
  }, { context = null, inFilter = false } = {}) {
    const currentContext = context || state.context;
    await dispatch('setContext', { context: currentContext });

    const isGuest = rootGetters['authentication/isGuest'];

    const contextSchoolsMapping = {
      [FILTER_CONTEXTS.FAVORITES]: () => (
        isGuest ? rootGetters['favorites/favoriteGuestList'] : rootGetters['favorites/listFavoriteSchools']
      ),
      [FILTER_CONTEXTS.EXPLORER]: () => rootGetters['institutions/schools'],
    };
    const { filters } = getters;
    const { grades } = rootGetters['authentication/activeGrades'];
    const grade = grades?.length ? grades : null;
    const schools = contextSchoolsMapping[currentContext]() || [];
    if (inFilter) {
      const years = filters[FILTER_KEYS.HAS_VACANCIES];
      const schoolsVacancies = rootGetters['institutions/schoolsVacancies'];
      const selectedYears = Array.from(years)
        .filter((year) => differentSchoolsInYear({ year, schools, schoolsVacancies }));
      const campusesCodes = schools.map((school) => school.campus_code);
      const promises = selectedYears.map((year) => dispatch('institutions/retrieveVacancies', { year, grade, campuses: campusesCodes }, { root: true }));

      await Promise.all(promises);
    }

    const filteredSchools = getters.activeFiltersCount > 0
      ? schools.filter((school) => schoolMatchesFilters(school, filters, rootGetters)).map(({ uuid }) => uuid)
      : [];
    commit('setFilteredSchools', { schools: filteredSchools });
  },
};


export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
