<template>
  <section class="help-information">
    <HeaderModal close-no-margin :content-sections="['close']" @close="$emit('close')" />
    <div class="help-information--content">
      <TextAtom
        :value="$t('digital_enrollment.help_information.title')"
        :color="open ? 'secondary-light' : 'primary-dark'"
        font="poppins"
        weight="500"
        size="title"
      />
      <TextAtom
        class="mt-4"
        :value="$t('digital_enrollment.help_information.subtitle')"
        :color="open ? 'secondary-light' : 'primary-dark'"
        font="inter"
        weight="400"
        size="subtitle"
      />
      <TextAtom
        class="mt-5"
        :value="$t('digital_enrollment.help_information.doubt_text')"
        :color="open ? 'secondary-light' : 'primary-dark'"
        font="inter"
        weight="400"
        size="subtitle"
      />
      <CallToActions
        class="mt-6"
        :option-text1="'digital_enrollment.help_information.mail'"
        :option-text2="'digital_enrollment.help_information.number'"
        light
        :svg-icon1="'mail-purple.svg'"
        :svg-icon2="'phone-purple.svg'"
        no-margin
        :types="['option']"
        :spaced="false"
        @nextStep="nextStep"
      />
    </div>
  </section>
</template>

<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import HeaderModal from '@/components/molecules/modals/Header.vue';

export default {
  name: 'HelpInformation',
  components: {
    HeaderModal,
    TextAtom,
    CallToActions,
  },
  methods: {
    nextStep(clickedActionIndex) {
      if (clickedActionIndex === 1) {
        const email = this.$t('digital_enrollment.help_information.mail');
        const mailtoUrl = `mailto:${email}`;
        window.open(mailtoUrl, '_blank');
      } else {
        const phoneNumber = this.$t('digital_enrollment.help_information.number');
        const telUrl = `tel:${phoneNumber}`;
        window.open(telUrl, '_blank');
      }
    },
  },
};
</script>
