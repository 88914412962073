<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        :actions-tag="'click_application-register-logged-welcome-back-button'"
        small-logo
        no-welcome-text-logo
        spaced-logo
        @back="backMethod()"
      />
      <ModalContent
        style="margin-top: 36px"
        :content-sections="['title', 'infoBox']"
        :title-text="'applications_register.application_logged_title'"
        :title-arg="Object.keys(currentStudent).length > 0 ? currentStudent.first_name : ''"
        :info-box-text="setStudentName()"
        :info-box-sub-text="getStudentAge()"
        :info-box-icon="Object.keys(currentStudent).length > 0 ? 'face.svg' : null"
        :info-box-tag="'click_application-register-logged-welcome-student-card'"
        info-box-align-left
      />
    </div>
    <CallToActions
      :option-text2="'user_register.final_student.button1'"
      :option-text-active-tag="'click_application-register-logged-welcome_add-student-button'"
      :option-text1="'headmaster_register.welcome.btn'"
      :option-text-tag="'click_application-register-logged-welcome_start-button'"
      :types="['option']"
      :spaced="false"
      @nextStep="nextStep"
    />
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AppLoggedWelcome',
  components: { ModalHeader, ModalContent, CallToActions },
  props: {
  },
  computed: {
    ...mapGetters({
      currentStudent: 'authentication/currentStudent',
      campusDetails: 'institutions/campusDetails',
      studentList: 'authentication/students',
    }),
  },
  mounted() {
    this.setTenantId({ tenantId: this.campusDetails.campus_code });
    if (this.studentList.length > 1) {
      return this.$emit('toStudentSelection');
    }
    if (!this.studentList || this.studentList.length === 0) {
      return this.$emit('addStudent');
    }
    if (!this.currentStudent.first_name || !this.currentStudent.first_lastname) {
      this.$emit('toStudentId');
    }
    if (this.studentList.length === 1) {
      this.setCurrentStudent({ student: this.studentList[0] });
    }
    return null;
  },
  methods: {
    ...mapActions({
      identificationLgStep1: 'userRegister/identificationLgStep1',
      setStudents: 'newStudentRegister/setStudents',
      getGradeRecommendation: 'institutions/getGradeRecommendation',
      retrieveDigitalEnrollmentTenantOptions: 'digitalEnrollment/retrieveDigitalEnrollmentTenantOptions',
      setTenantId: 'digitalEnrollment/setTenantId',
      setCurrentStudent: 'authentication/setCurrentStudent',
    }),
    nextStep(clickedActionIndex) {
      if (clickedActionIndex === 1) {
        return this.$emit('nextStep');
      }
      return this.$emit('addStudent');
    },
    setStudentName() {
      if (Object.keys(this.currentStudent).length > 0) {
        let studentName = this.currentStudent.first_name;
        if (this.currentStudent.first_lastname) {
          studentName = `${studentName} ${this.currentStudent.first_lastname}`;
        }
        return studentName;
      }
      return '';
    },
    getStudentAge() {
      const actualYear = new Date().getFullYear();
      const studentYears = actualYear - this.currentStudent.birth_year;
      const studentMonth = this.currentStudent.birth_month;
      const yearsOld = `${this.$tc('user_register.final_student.years', studentYears)} ${this.$tc('user_register.final_student.months', studentMonth)}`;
      return yearsOld;
    },
    backMethod() {
      this.$router.push({ name: 'ExplorerSchool', params: { code: this.campusDetails.campus_code } });
    },
  },
};
</script>
