<template>
  <section>
    <div v-if="!loaded" class="d-flex justify-center align-center mb-10">
      <LoadingSpinner
        show
        :size="25"
      />
    </div>
    <div
      v-else
      class="campus-detail__panels__vacancies"
      :class="!hasVacancies ? 'panel_container_row--dashed' : ''"
    >
      <TextAtom
        :value="description"
        class="mt-4 text-left d-flex pl-4 pb-3"
        tag="label"
        font="inter"
        color="primary-dark"
        weight="400"
        size="subtitle"
      />
      <v-tooltip bottom content-class="custom-tooltip">
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            class="panels__tooltip panels__tooltip--right"
            icon
            dark
            small
            color="#1E2D4C"
            style="margin-top: -4px !important"
            v-on="on"
          >
            <v-icon dark>
              mdi-information
            </v-icon>
          </v-btn>
        </template>
        <span v-t="'campus-details.tooltip.vacancies'" />
      </v-tooltip>
      <div v-if="hasVacancies" style="width: 100%" class="programs-card">
        <Tabs
          v-model="tab"
          :current-year="currentYear"
          :second-year="secondYear"
          :third-year="thirdYear"
          :disabled="tabDisabled"
        />
        <div v-for="(value, key, i) in vacancies" :key="i" class="text-left">
          <span
            v-if="hasProgramsOfTheYear(value)"
            class="
            campus-detail__panels__title
            text-left
            mb-2
            font-weight-medium
            text-style
          "
            style="margin-left: 10px"
          >
            <b>{{ key }}</b>
          </span>
          <div
            class="
            panel_container_row
            panel_container_row_wrap
            panel_container_row_start
          "
          >
            <div
              v-for="(vacancy, j) in vacancies[key]"
              :key="j"
            >
              <div
                v-if="vacancy.year === getYear()"
                :class="getDotClass(vacancy.state)"
                class="
                panel_container_row_item position-grade
              "
              >
                <div :class="getIcon(vacancy.state)" />
                <!-- <div :class="getDotClass(vacancy.state)"></div> -->
                <span>{{ vacancy.shortened }}</span>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div class="info-vacancy-container">
          <div class="flex-container">
            <div class="div-container">
              <div class="vacanciesIcon" style="float: left" />
              <span style="color: #2b5bff" class="ml-2">
                {{ $t("campus-details.vacancies.yes-vacancies") }}
              </span>
            </div>
            <br />
            <div class="div-container left-inside">
              <div class="noVacanciesIcon" style="float: right" />
              <span style="color: #2b5bff" class="ml-2">
                {{ $t("campus-details.vacancies.no-vacancies") }}
              </span>
            </div>
          </div>
          <br />
          <div class="div-container">
            <div class="noInfoVacanciesIcon" style="float: left" />
            <span style="color: #2b5bff" class="ml-2">
              {{ $t("campus-details.vacancies.no-info-vacancies") }}</span>
          </div>
        </div>
      </div>
      <div v-else class="panel_container_row">
        Aún no tenemos información sobre las vacantes disponibles en este
        establecimiento
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import Tabs from '@/components/molecules/tabs/Tabs.vue';
import LoadingSpinner from '@/components/atoms/loaders/LoaderCircular.vue';

export default {
  name: 'VacanciesPanel',
  components: {
    Tabs,
    TextAtom,
    LoadingSpinner,
  },
  props: {
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isPublic: false,
      loaded: false,
      // vacancies: {},
      tab: 0,
      currentYear: new Date().getFullYear(),
      secondYear: new Date().getFullYear() + 1,
      thirdYear: new Date().getFullYear() + 2,
      gradesVacancies: {},
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'institutions/campusDetails',
      stages: 'options/stages',
    }),
    tabDisabled() {
      return [
        !this.hasProgramsByYear(this.currentYear),
        !this.hasProgramsByYear(this.secondYear),
        !this.hasProgramsByYear(this.thirdYear),
      ];
    },
    vacancies() {
      // TODO: This is completely unnecessary. We're actually already making a reduce that could be tweaked to work
      // with the data as it is. Also, in combination to the updated gradetrack educational levels endpoint,
      // we could just fetch the stage/grades insead of mapping them here
      const newStages = [];
      this.stages.forEach((stage) => {
        newStages.push({
          id: stage.id,
          stage_name: this.$i18n.locale === 'en' ? stage.stage_name_en : stage.stage_name,
          grades: this.getGradesByPrograms(stage.id),
        });
      });
      const vacanciesArray = {};
      newStages?.forEach((stage) => {
        vacanciesArray[stage.stage_name] = [];
        stage.grades.forEach((grade) => {
          const gradeInfo = {
            stage_label_name: stage.stage_name,
            grade_id: grade.id,
            shortened: grade.gradeName,
            state: this.gradesVacancies[grade.gradeName]?.[grade.year],
            vacancies: grade.regularVacancies,
            order: grade.order,
            year: grade.year,
            programId: grade.programId,
          };
          vacanciesArray[stage.stage_name].push(gradeInfo);
        });
      });
      this.campusDetail?.vacancies.forEach((vacancy) => {
        vacanciesArray[vacancy.stage_label_name].sort((a, b) => a.order - b.order);
      });
      // this.vacancies = vacanciesArray;
      Object.keys(vacanciesArray).forEach((stage) => {
        if (vacanciesArray[stage].length === 0) {
          delete vacanciesArray[stage];
        }
      });
      // this.loaded = true;

      return vacanciesArray;
    },
  },
  async mounted() {
    this.isPublic = true;
    this.loaded = false;
    await this.retrieveVacanciesByCampus({ campusCode: this.campusDetail.campus_code }).then((response) => {
      this.gradesVacancies = response;
      this.loaded = true;
    });
  },
  methods: {
    ...mapActions({
      retrieveVacanciesByCampus: 'institutions/retrieveVacanciesByCampus',
    }),
    hasProgramsOfTheYear(value) {
      return value.some((grade) => grade.year === this.getYear());
    },
    getYear() {
      return new Date().getFullYear() + this.tab;
    },
    hasProgramsByYear(year) {
      const flattenedVacancies = Object.values(this.vacancies).flat();
      return flattenedVacancies.some((grade) => grade.year === year);
    },
    getGradesByPrograms(stageId) {
      const grades = [];
      this.campusDetail?.program_campus.forEach((program) => {
        const { id } = program.gradetrack.grade_label;
        const { year } = program;
        const isGradeIdPresentWithSameYear = grades.some(
          (gradeObj) => gradeObj.id === id && gradeObj.year === year,
        );

        if (program.gradetrack.stage_label.id === stageId && !isGradeIdPresentWithSameYear) {
          grades.push(
            {
              id,
              year: program.year,
              grade: program.gradetrack.grade_label,
              order: program.gradetrack.grade_label.order,
              gradeName: this.$i18n.locale === 'en' ? program.gradetrack.grade_label.grade_name_en : program.gradetrack.grade_label.grade_name,
              programId: program.id,
              regularVacancies: program.regular_vacancies,
            },
          );
        }
      });
      return grades;
    },

    hasVacancies() {
      return Object.keys(this.vacancies).length > 0;
    },
    getDotClass(state) {
      if (state === 0) {
        // No Vacancies
        return 'panel_container_row_item__no_vacancies';
      }
      if (state === -1) {
        return 'panel_container_row_item__no_info_vacancies';
      }
      if (state === 1) {
        return 'panel_container_row_item__vacancies'; // Vacancies
      }
      return 'panel_container_row_item__no_info_vacancies';
    },
    getIcon(state) {
      if (state === -1 || state === undefined) {
        return 'no_info_vacancy_icon';
      }
      return null;
    },
  },
};
</script>
