<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        :actions-tag="registerType === 1
          ? 'click_reg_quick-register-student-birthday_back-button'
          : 'click_reg_detailed-register-student-birthday_back-button'"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <RowStepper
        :mixpanel-tag="registerType === 1
          ? 'click_reg_quick-register-student-birthday_row-stepper'
          : 'click_reg_detailed-register-student-birthday_row-stepper'"
        :step="inStudentFlow ? 2 : 1"
      />
      <ModalContent
        :class="inWaitingList && !mobile ? 'deg-waiting-list--mobile' : ''"
        :content-sections="['smallTitle', 'subtitle', 'numberSelect']"
        :subtitle-text="'user_register.date_of_birth.subtitle'"
        :subtitle-arg="Object.keys(currentStudent).length > 0 ? currentStudent.first_name : ''"
        :small-title-text="
          inStudentFlow
            ? 'user_register.identification.student_title'
            : 'user_register.identification.title'
        "
        small-title-left
        :number-slot-content="setNumberSlotContent()"
        :number-info-error="noInfoError"
        @setValue="setValue"
      />
    </div>
    <CallToActions
      :types="['main']"
      :active-value="isActive"
      :main-button-text="'user_register.continue'"
      :main-button-tag="registerType === 1
        ? 'click_reg_quick-register-student-birthday_continue-button'
        : 'click_reg_detailed-register-student-birthday_continue-button'"
      small
      :spaced="false"
      :loader="dateLoader"
      @continue="nextStep()"
    />
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DateOfBirth',
  components: {
    ModalHeader,
    RowStepper,
    ModalContent,
    CallToActions,
  },
  props: {
    inStudentFlow: {
      type: Boolean,
      default: false,
    },
    preFill: {
      type: Boolean,
      default: false,
    },
    preFillData: {
      type: Object,
      default: () => ({}),
    },
    // 1 Quick - 2 Detailed
    registerType: {
      type: Number,
      default: 2,
    },
    inWaitingList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dayOfBirth: null,
      monthOfBirth: null,
      yearOfBirth: null,
      noInfoError: false,
      dateLoader: false,
    };
  },
  computed: {
    ...mapGetters({
      legalGuardian: 'authentication/legalGuardian',
      currentStudent: 'authentication/currentStudent',
      inDigitalEnrollment: 'digitalEnrollment/inDigitalEnrollment',
      digitalEnrollmentTenant: 'digitalEnrollment/tenantId',
    }),
    isActive() {
      if (this.monthOfBirth && this.yearOfBirth && this.registerType === 1) {
        return true;
      }
      if (this.monthOfBirth && this.yearOfBirth && this.dayOfBirth
        && (this.registerType === 2 || this.registerType === null)) {
        return true;
      }
      return false;
    },
    setYearRange() {
      const rangeNumber = new Date().getFullYear() - 1899;
      return rangeNumber;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    preFillData: {
      handler() {
        if (this.preFill) {
          this.doPreFill();
        }
      },
      deep: true,
    },
    preFill: {
      handler(newVal) {
        if (!newVal) {
          this.dayOfBirth = null;
          this.monthOfBirth = null;
          this.yearOfBirth = null;
        }
      },
    },
  },
  created() {
    if (this.preFill && Object.keys(this.preFillData).length > 0) {
      this.doPreFill();
    }
  },
  methods: {
    ...mapActions({
      identificationLgStep1: 'userRegister/identificationLgStep1',
      setStudents: 'newStudentRegister/setStudents',
      getGradeRecommendation: 'institutions/getGradeRecommendation',
    }),
    nextStep() {
      this.dateLoader = true;
      if (!this.isActive) {
        this.noInfoError = true;
        return null;
      }
      const birthDate = this.registerType === 2 ? `${this.yearOfBirth.toString()}-${this.monthOfBirth.toString()}-${this.dayOfBirth.toString()}` : null;
      const birthDay = this.dayOfBirth ? this.dayOfBirth.toString() : null;
      const birthMonth = this.monthOfBirth.toString() ?? null;
      const birthYear = this.yearOfBirth.toString() ?? null;
      if (this.inStudentFlow) {
        const personalInfo = {
          uuid: this.currentStudent.uuid,
          birthDate,
          birthDay,
          birthMonth,
          birthYear,
        };
        if (this.registerType === 2) {
          const campusCode = this.inDigitalEnrollment ? this.digitalEnrollmentTenant : undefined;
          this.getGradeRecommendation({ birthDate, campusCode }).then(() => {
            this.setStudents({ studentsForm: personalInfo, current: true }).then(() => {
              this.$emit('nextStep');
            });
          });
        } else {
          this.setStudents({ studentsForm: personalInfo, current: true }).then(() => {
            this.$emit('nextStep');
          });
        }
      } else {
        const personalInfo = {
          uuid: this.legalGuardian.uuid,
          birthDate,
        };
        this.identificationLgStep1({ personalInfo }).then(() => {
          this.$emit('nextStep');
        });
      }
      return null;
    },
    doPreFill() {
      const datePropertyName = this.inStudentFlow ? 'birth_month' : 'birthDate';
      if (this.preFillData && this.preFillData[datePropertyName]) {
        this.dayOfBirth = this.preFillData.birth_day ? this.preFillData.birth_day.toString() : null;
        this.monthOfBirth = this.preFillData.birth_month.toString() ?? null;
        this.yearOfBirth = this.preFillData.birth_year.toString() ?? null;
      }
      if (this.dayOfBirth < 10) {
        this.dayOfBirth = `0${this.dayOfBirth}`;
      }
      if (this.monthOfBirth < 10) {
        this.monthOfBirth = `0${this.monthOfBirth}`;
      }
    },
    setNumberSlotContent() {
      const numberSlotContent = [
        {
          placeholder: this.$t('user_register.birthday.day_placeholder'),
          totalNumber: 31,
          startNumber: 1,
          initialValue: this.dayOfBirth,
          tag: this.registerType === 1
            ? 'click_reg_quick-register-student-birthday_day-dropdown'
            : 'click_reg_detailed-register-student-birthday_day-dropdown',
          selectorTag: this.registerType === 1
            ? 'click_reg_quick-register-student-birthday_day-dropdown-selector'
            : 'click_reg_detailed-register-student-birthday_day-dropdown-selector',
          show: this.registerType === 2 || this.registerType === null,
        },
        {
          placeholder: this.$t('user_register.birthday.month_placeholder'),
          totalNumber: 12,
          startNumber: 1,
          initialValue: this.monthOfBirth,
          tag: this.registerType === 1
            ? 'click_reg_quick-register-student-birthday_month-dropdown'
            : 'click_reg_detailed--register-student-birthday_month-dropdown',
          selectorTag: this.registerType === 1
            ? 'click_reg_quick-register-student-birthday_month-dropdown-selector'
            : 'click_reg_detailed-register-student-birthday_month-dropdown-selector',
          show: true,
        },
        {
          placeholder: this.$t('user_register.birthday.year_placeholder'),
          totalNumber: this.setYearRange,
          startNumber: 1900,
          initialValue: this.yearOfBirth,
          tag: this.registerType === 1
            ? 'click_reg_quick-register-student-birthday_year-dropdown'
            : 'click_reg_detailed-register-student-birthday_year-dropdown',
          selectorTag: this.registerType === 1
            ? 'click_reg_quick-register-student-birthday_year-dropdown-selector'
            : 'click_reg_detailed-register-student-birthday_year-dropdown-selector',
          show: true,
        },
      ];
      return numberSlotContent;
    },
    setValue(index, value) {
      if (index === 0 && this.registerType === 1) {
        this.monthOfBirth = value;
      }
      if (index === 0 && (this.registerType === 2 || this.registerType === null)) {
        this.dayOfBirth = value;
      }
      if (index === 1) {
        this.monthOfBirth = value;
      }
      if (index === 2) {
        this.yearOfBirth = value;
      }
      if (this.dayOfBirth && this.monthOfBirth && this.yearOfBirth) {
        this.noInfoError = false;
      }
      return null;
    },
  },
};
</script>
