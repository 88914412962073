<template>
  <div class="campus-detail__panels__container campus-detail__panels__container__white fill-width">
    <div class="panel_container_column panel_container_row--scroll fill-width">
      <div class="panel_container_row panel_container_row--space-between fill-width">
        <div class="panel_container_row--left widget-percent--text--grey">
          50% {{ $t('campus-details.panels.inferior_district') }}
        </div>
        <div class="panel_container_row--right widget-percent--text--blue">
          50% {{ $t('campus-details.panels.superior_district') }}
        </div>
      </div>
      <div
        class="panel_container_row panel_container_row panel_container_row--no-margin fill-width"
      >
        <div class="widget-percent-bar1"></div>
        <div class="widget-percent-bar2"></div>
      </div>
      <div
        class="panel_container_row panel_container_row--no-margin fill-width widget-percent--white"
      >
        <div v-for="index in 5" :key="index">
          <img
            :src="require('@/assets/iconsChile/campusDetail/face_icon_deactivated.svg')"
            class="widget-percent--icon"
          />
        </div>
        <div v-for="index in 5" :key="index + 5">
          <img :src="require('@/assets/iconsChile/campusDetail/face_icon_blue.svg')" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'WidgetPercent',
};
</script>
