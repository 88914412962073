<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo', 'svgIcon']"
        :actions-tag="registerType === 1
          ? 'click_reg_quick-register-legal-guardian-want-add-student_back-button'
          : 'click_reg_detailed-register-legal-guardian-want-add-student_back-button'"
        small-logo
        inline-logo
        :svg-icon="'face.svg'"
        @back="$emit('backStep')"
      />
    </div>
    <ModalContent
      :content-sections="['subtitle']"
      :subtitle-text="'user_register.legal_Step_Modal.subtitle'"
      subtitle-center
    />
    <CallToActions
      :option-text1="'user_register.legal_Step_Modal.button1'"
      :option-text-active-tag="registerType === 1
        ? 'click_reg_quick-register-legal-guardian-want-add-student_add-student-button'
        : 'click_reg_detailed-register-legal-guardian-want-add-student_add-student-button'"
      :option-text2="'user_register.legal_Step_Modal.button2'"
      :option-text-tag="registerType === 1
        ? 'click_reg_quick-register-legal-guardian-want-add-student_go-to-explore-button'
        : 'click_reg_detailed-register-legal-guardian-want-add-student_go-to-explore-button'"
      :types="['option']"
      :spaced="false"
      @nextStep="nextStep"
    />
  </div>
</template>

<script>
import ModalHeader from '@/components/molecules/modals/Header.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';

export default {
  name: 'LegalGuardianStepModal',
  components: {
    ModalHeader,
    ModalContent,
    CallToActions,
  },
  props: {
    // 1 Quick - 2 Detailed
    registerType: {
      type: Number,
      default: null,
    },
  },
  methods: {
    nextStep(to) {
      if (to === 1) {
        this.$emit('inStudentFlow');
        return this.$emit('nextStep');
      }
      return this.$router.push('/map');
    },
  },
};
</script>
