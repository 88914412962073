<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        :actions-tag="registerType === 1
          ? 'click_reg_quick-register-student-created_back-button'
          : 'click_reg_detailed-register-student-created_back-button'"
        inline-logo
        no-welcome-text-logo
        @back="$emit('backStep')"
      />
      <ModalContent
        style="margin-top: 36px"
        :content-sections="['title', 'infoBox']"
        :title-text="'user_register.final_student.title'"
        :title-arg="Object.keys(currentStudent).length > 0 ? currentStudent.first_name : ''"
        :info-box-text="
          Object.keys(currentStudent).length > 0
            ? currentStudent.first_name + ' ' + (currentStudent.first_lastname ? currentStudent.first_lastname : '')
            : ''
        "
        info-padding
        info-box-fill-width
        info-box-align-left
        :info-box-icon="Object.keys(currentStudent).length > 0 ? 'face.svg' : null"
        :info-box-tag="registerType === 1
          ? 'click_reg_quick-register-student-created_student-card'
          : 'click_reg_detailed-register-student-created_student-card'"
        :info-box-sub-text="yearsOld"
      />
    </div>
    <CallToActions
      :option-text1="setOptionText1()"
      :option-text-active-tag="registerType === 1
        ? 'click_reg_quick-register-student-created_add-student-button'
        : 'click_reg_detailed-register-student-created_add-student-button'"
      :option-text2="loginModal === 'applicationFlow'
        ? 'user_register.final_student.button1' : 'user_register.final_student.button2'"
      :option-text-tag="registerType === 1
        ? 'click_reg_quick-register-student-created_start-button'
        : 'click_reg_detailed-register-student-created_start-button'"
      :types="['option']"
      :spaced="false"
      :number-of-options="hideNewStudentButton ? 1 : 2"
      @nextStep="nextStep"
    />
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'StudentsFinal',
  components: { ModalHeader, ModalContent, CallToActions },
  props: {
    // 1 Quick - 2 Detailed
    registerType: {
      type: Number,
      default: null,
    },
    hideNewStudentButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      yearsOld: '',
      separator: '',
    };
  },
  computed: {
    ...mapGetters({
      currentStudent: 'authentication/currentStudent',
      inDigitalEnrollment: 'digitalEnrollment/inDigitalEnrollment',
      loginModal: 'authentication/loginModal',
    }),
  },
  watch: {
    'currentStudent.birth_month': {
      handler() {
        this.watcher = true;
        if (this.registerType === 1) {
          this.yearsCount();
        }
      },
      immediate: false,
    },
  },
  mounted() {
    if (this.registerType === 2) {
      this.yearsCount();
    }
  },
  methods: {
    ...mapActions({
      setCurrentStudent: 'authentication/setCurrentStudent',
      getStudents: 'authentication/getStudents',
    }),
    yearsCount() {
      if (Object.keys(this.currentStudent).length > 0) {
        const birthYear = this.currentStudent.birth_year;
        const birthMonth = this.currentStudent.birth_month;
        const today = new Date();
        const yearAge = today.getFullYear();
        const monthAge = today.getMonth() + 1;

        const a = moment([yearAge, monthAge]);
        const b = moment([birthYear, birthMonth]);

        const years = a.diff(b, 'year');
        b.add(years, 'years');
        const months = a.diff(b, 'months');
        b.add(months, 'months');
        if (years > 0 && months > 0) {
          this.separator = 'y';
        }

        this.yearsOld = `${this.$tc('user_register.final_student.years', years)} ${this.separator} ${this.$tc('user_register.final_student.months', months)}`;
        this.separator = '';
      }
    },
    nextStep(clickedActionIndex) {
      // 1 -> add another student
      // 2 -> continue to map or DGE
      if (!this.inDigitalEnrollment) {
        if (clickedActionIndex === 2) {
          this.getStudents({ updateStudent: false }).then(() => {
            this.$emit('toMap');
            this.setCurrentStudent({ student: {} });
          });
        } else {
          this.$emit('nextStep');
        }
      } else {
        this.$emit('nextStep');
      }
    },
    setOptionText1() {
      if (this.inDigitalEnrollment && this.loginModal !== 'applicationFlow') {
        return '';
      }
      if (this.loginModal === 'applicationFlow') {
        return 'user_register.final_student.continue';
      }
      return 'user_register.final_student.button1';
    },
  },
};
</script>
