<template>
  <div
    class="register-main--multiStyle"
    :class="{ 'register-main--multiStyle--center': center.length > 0 }"
    :style="small.length > 0
      ? 'margin-bottom: 12px' : 'margin-bottom: 20px'"
  >
    <div v-for="textIndex in textArrayLength" :key="textIndex" style="height: 100%">
      <p
        v-t="setText(textIndex)"
        :style="spaced.includes(textIndex) ? 'margin: 0 4px;' : ''"
        class="register-main--multiStyle--text"
        :class="{
          'register-main--multiStyle--text--tiny': small.includes(textIndex),
          'register-main--multiStyle--text--small': small.includes(textIndex),
          'register-main--multiStyle--text--bold': bold.includes(textIndex),
          'register-main--multiStyle--text--bolder': bolder.includes(textIndex),
          'register-main--multiStyle--text--link': link.includes(textIndex),
          'register-main--multiStyle--text--underline': underline.includes(textIndex),
          'register-main--multiStyle--text--secondary': secondary.includes(textIndex),
          'register-main--multiStyle--text--white': white.includes(textIndex),
          'register-main--multiStyle--text--disabled': disabled,
          'register-main--multiStyle--text--center': center.includes(textIndex),
        }"
        @keydown="textClick(textIndex)"
        @click="textClick(textIndex)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultiStyleText',
  props: {
    text: {
      type: String,
      default: null,
    },
    args: {
      type: Object,
      default: null,
    },
    text2: {
      type: String,
      default: null,
    },
    text3: {
      type: String,
      default: null,
    },
    text4: {
      type: String,
      default: null,
    },
    text5: {
      type: String,
      default: null,
    },
    args2: {
      type: Object,
      default: null,
    },
    args3: {
      type: Object,
      default: null,
    },
    args4: {
      type: Object,
      default: null,
    },
    args5: {
      type: Object,
      default: null,
    },
    bold: {
      type: Array,
      default() {
        return [];
      },
    },
    bolder: {
      type: Array,
      default() {
        return [];
      },
    },
    link: {
      type: Array,
      default() {
        return [];
      },
    },
    underline: {
      type: Array,
      default() {
        return [];
      },
    },
    secondary: {
      type: Array,
      default() {
        return [];
      },
    },
    white: {
      type: Array,
      default() {
        return [];
      },
    },
    spaced: {
      type: Array,
      default() {
        return [];
      },
    },
    small: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      textArrayLength: [],
    };
  },
  mounted() {
    const textArray = [this.text, this.text2, this.text3, this.text4, this.text5].filter((element) => element !== null);
    this.textArrayLength = textArray.length;
  },
  methods: {
    setText(index) {
      switch (index) {
        case 1:
          if (this.args) {
            return {
              path: this.text,
              args: this.args,
            };
          }
          return this.text;
        case 2:
          if (this.args2) {
            return {
              path: this.text2,
              args: this.args2,
            };
          }
          return this.text2;
        case 3:
          if (this.args3) {
            return {
              path: this.text3,
              args: this.args3,
            };
          }
          return this.text3;
        case 4:
          if (this.args4) {
            return {
              path: this.text4,
              args: this.args4,
            };
          }
          return this.text4;
        case 5:
          if (this.args5) {
            return {
              path: this.text5,
              args: this.args5,
            };
          }
          return this.text5;
        default:
          break;
      }
      return null;
    },
    textClick(textIndex) {
      if (this.link.includes(textIndex)) {
        this.$emit('linkClick');
      }
      if (this.underline.includes(textIndex)) {
        this.$emit('underlineClick');
      }
      return null;
    },
  },
};
</script>
