import { render, staticRenderFns } from "./CampusDetailCallToActionDGE.vue?vue&type=template&id=c54d5fac&scoped=true"
import script from "./CampusDetailCallToActionDGE.vue?vue&type=script&lang=js"
export * from "./CampusDetailCallToActionDGE.vue?vue&type=script&lang=js"
import style0 from "./CampusDetailCallToActionDGE.vue?vue&type=style&index=0&id=c54d5fac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c54d5fac",
  null
  
)

export default component.exports