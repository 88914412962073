<template>
  <div
    class="register-main--check-div"
    style=" display: flex; flex-wrap: wrap;"
  >
    <div v-for="textIndex in textArrayLength" :key="textIndex">
      <p
        v-t="setCheckboxText(textIndex)"
        :style="textIndex === 2 ? 'margin: 0 4px;' : ''"
        class="register-main--check-div--text"
        :class="{
          'register-main--check-div--text--disabled': disabledValue,
          'register-main--check-div--text--bold': bold.includes(textIndex),
          'register-main--check-div--text--link': link.includes(textIndex),
          'register-main--check-div--text--underline': underline.includes(textIndex),
        }"
        @keydown="textClick(textIndex)"
        @click="textClick(textIndex)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckboxTitle',
  props: {
    text: {
      type: String,
      default: null,
    },
    args: {
      type: Object,
      default: null,
    },
    text2: {
      type: String,
      default: null,
    },
    text3: {
      type: String,
      default: null,
    },
    disabledValue: {
      type: [String, Boolean],
      default: null,
    },
    bold: {
      type: Array,
      default() {
        return [];
      },
    },
    link: {
      type: Array,
      default() {
        return [];
      },
    },
    underline: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      textArrayLength: [],
    };
  },
  mounted() {
    const textArray = [this.text, this.text2, this.text3].filter((element) => element !== null);
    this.textArrayLength = textArray.length;
  },
  methods: {
    setCheckboxText(index) {
      switch (index) {
        case 1:
          if (this.args) {
            return {
              path: this.text,
              args: this.args,
            };
          }
          return this.text;
        case 2:
          if (this.args) {
            return {
              path: this.text2,
              args: this.args,
            };
          }
          return this.text2;
        case 3:
          if (this.args) {
            return {
              path: this.text3,
              args: this.args,
            };
          }
          return this.text3;
        default:
          break;
      }
      return null;
    },
    textClick(textIndex) {
      if (this.link.includes(textIndex)) {
        this.$emit('linkClick');
      }
      if (this.underline.includes(textIndex)) {
        this.$emit('underlineClick');
      }
      return null;
    },
  },
};
</script>
