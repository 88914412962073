<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <RowStepper :step="3" />
      <ModalContent
        :content-sections="['smallTitle', 'subtitle', 'selectionButton']"
        :small-title-text="'user_register.additional_info.title'"
        small-title-left
        :subtitle-arg="Object.keys(currentStudent).length > 0 ? currentStudent.first_name : ''"
        :subtitle-text="'user_register.additional_info.year_subtitle'"
        :selection-button-info="selectionButtonInfo"
        selection-button-column
        @setSelectionButtonValue="setButtonsValue"
      />
    </div>
    <CallToActions
      :types="['main']"
      :active-value="isActive"
      :main-button-text="'user_register.continue'"
      small
      :spaced="false"
      @continue="nextStep()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';

export default {
  name: 'AdditionalInfoYear',
  components: {
    ModalHeader,
    RowStepper,
    ModalContent,
    CallToActions,
  },
  data() {
    return {
      activeIndex: null,
      selectionButtonInfo: [
        { label: 'user_register.additional_info.actual_year', isActive: false },
        { label: 'user_register.additional_info.next_year', isActive: false },
        { label: '', isActive: false },
      ],
      years: [],
    };
  },
  computed: {
    ...mapGetters({
      students: 'authentication/students',
      currentStudent: 'authentication/currentStudent',
    }),
    isActive() {
      if (this.activeIndex !== null) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    const currentYear = new Date().getFullYear();
    // Push the current year, next year, and year after
    this.years.push(currentYear, currentYear + 1, currentYear + 2);
    this.selectionButtonInfo[2].label = String(currentYear + 2);
  },
  methods: {
    ...mapActions({
      setYearToApply: 'digitalEnrollment/setYearToApply',
    }),
    nextStep() {
      if (!this.isActive) {
        this.noInfoError = true;
        return null;
      }
      this.$emit('nextStep');
      return null;
    },
    setButtonsValue(i) {
      this.activeIndex = i;
      this.selectionButtonInfo[0].isActive = i === 0;
      this.selectionButtonInfo[1].isActive = i === 1;
      this.selectionButtonInfo[2].isActive = i === 2;

      this.setYearToApply({ yearToApply: this.years[i] });

      return this.activeIndex;
    },
  },
};
</script>
