<template>
  <v-expansion-panel-header :class="titleClass">
    <template #actions>
      <v-img
        v-if="showImage"
        :src="require(`@/assets/icons/${imageSrc}`)"
        alt="Student Icon"
      />
      <v-icon v-else :color="iconColor">
        $expand
      </v-icon>
    </template>
    {{ title }}
  </v-expansion-panel-header>
</template>

<script>
export default {
  name: 'ExpansionPanelHeader',
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    showImage: {
      type: Boolean,
      default: false,
    },
    imageSrc: {
      type: String,
      default: '',
    },
    titleClass: {
      type: String,
      default: '',
    },
  },
};
</script>
