<template>
  <div
    v-if="source"
    class="url-image"
    :style="small ? 'width: auto' : ''"
  >
    <img
      :class="{
        'url-image__image--unset-width': width,
        'url-image__image--rounded': rounded,
        'url-image__image--rounded-wide': roundedWide,
        'url-image__image--medium': medium,
        'url-image__image--small': small,
        'url-image__image--with-background': withBackground,
      }"
      :width="small ? 'auto' : width"
      :src="source"
      :alt="altText"
      @click="$emit('click')"
      @keydown.enter="$emit('click')"
    />
  </div>
</template>

<script>
export default {
  name: 'ImageAtom',
  props: {
    source: {
      type: String,
      default: null,
    },
    altText: {
      type: String,
      default: 'Contenido de Imagen',
    },
    rounded: {
      type: Boolean,
      default: null,
    },
    roundedWide: {
      type: Boolean,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    withBackground: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '',
    },
  },
};
</script>
