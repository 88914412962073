var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-main register-main--spaced"},[_c('div',{staticClass:"fill-width"},[_c('ModalHeader',{attrs:{"content-sections":['actions', 'logo'],"actions-tag":_vm.registerType === 1
        ? 'click_reg_quick-register-student-created_back-button'
        : 'click_reg_detailed-register-student-created_back-button',"inline-logo":"","no-welcome-text-logo":""},on:{"back":function($event){return _vm.$emit('backStep')}}}),_c('ModalContent',{staticStyle:{"margin-top":"36px"},attrs:{"content-sections":['title', 'infoBox'],"title-text":'user_register.final_student.title',"title-arg":Object.keys(_vm.currentStudent).length > 0 ? _vm.currentStudent.first_name : '',"info-box-text":Object.keys(_vm.currentStudent).length > 0
          ? _vm.currentStudent.first_name + ' ' + (_vm.currentStudent.first_lastname ? _vm.currentStudent.first_lastname : '')
          : '',"info-padding":"","info-box-fill-width":"","info-box-align-left":"","info-box-icon":Object.keys(_vm.currentStudent).length > 0 ? 'face.svg' : null,"info-box-tag":_vm.registerType === 1
        ? 'click_reg_quick-register-student-created_student-card'
        : 'click_reg_detailed-register-student-created_student-card',"info-box-sub-text":_vm.yearsOld}})],1),_c('CallToActions',{attrs:{"option-text1":_vm.setOptionText1(),"option-text-active-tag":_vm.registerType === 1
      ? 'click_reg_quick-register-student-created_add-student-button'
      : 'click_reg_detailed-register-student-created_add-student-button',"option-text2":_vm.loginModal === 'applicationFlow'
      ? 'user_register.final_student.button1' : 'user_register.final_student.button2',"option-text-tag":_vm.registerType === 1
      ? 'click_reg_quick-register-student-created_start-button'
      : 'click_reg_detailed-register-student-created_start-button',"types":['option'],"spaced":false,"number-of-options":_vm.hideNewStudentButton ? 1 : 2},on:{"nextStep":_vm.nextStep}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }