<template>
  <div itemscope itemtype="https://schema.org/EducationalOrganization">
    <div v-if="loadingProfile" class="campus-detail">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader
          v-bind="attrs"
          type="table-heading, image, list-item-three-line,
                list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line,
                list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"
        />
      </v-sheet>
    </div>
    <section v-else class="campus-detail">
      <!-- Close button -->
      <div class="campus-detail__close">
        <button type="button">
          <v-icon color="#4F4F4F" size="20" @click="goBack">
            mdi-close
          </v-icon>
        </button>
      </div>

      <!-- Information school -->
      <CampusDetailHeader
        class="px-2 campus-detail__margin"
        :name-school="campusDetail.campus_name"
        :campus="campusDetail"
        :commune="campusDetail.commune"
        :type-school="getSector"
        :grades-school="getGrades"
        :views="numberOfVisitsToTheDigitalProfile"
        :is-mobile="mobile"
        :programs="campusDetail.program_campus"
        :is-campus-verified="isCampusVerified"
        :is-campus-tether-pack="isCampusTetherPack"
        :is-campus-freemium="isCampusFreemium"
        @close-modal="isCloseCampusDetail"
      />
      <!-- Pictures of the school -->
      <CampusDetailPhotos
        v-if="hasImages"
        :style="{ height: mobile ? '230px' : '450px' }"
        :images="campusDetail.campus_images_gallery"
      />

      <div v-else class="campus-detail__no-picture" :style="{ height: mobile ? '230px' : '450px' }">
        <img src="@/assets/icons/no-school.svg" alt="school" />
        <p v-t="'campus-details.no-picture'" />
      </div>

      <!-- Favorite button -->
      <div class="campus-detail__favorite">
        <div>
          <FavoriteButton
            :campus="campusDetail"
            :is-large-button="true"
            :button-in-campus-detail="true"
            @close-campus-detail="isCloseCampusDetail"
          />
        </div>
      </div>
      <CampusDetailPostulationButtons
        @close-campus-detail="isCloseCampusDetail"
      />
      <!-- Navbar -->
      <CampusDetailNavbar
        :has-testimonial="getTestimonials?.length > 0"
        :has-virtual-tour="getVirtualTour?.length > 0"
        :has-drone-video="getVideoDrone?.link?.length > 0"
        :has-infrastructure="campusDetail.infraestructure_campus?.length > 0"
        :has-sports="campusDetail.sport_campus?.length > 0"
        :has-extracurriculars="campusDetail.extraactivity_campus?.length > 0"
      />
      <!-- Summary -->
      <div id="summary" class="campus-detail__summary campus-detail__module mt-2">
        <CampusDetailAbout
          :about="getInstitutionText()"
          :agreements="campusDetail.institutionagreement_campus"
          :contacts="campusDetail.institutioncontact_campus"
          :is-mobile="mobile"
          :trainings="campusDetail.program_campus"
          :show-placard-generate-by-ai="profileSummaryIsGenerateByAi()"
          :partnerships="getPartnershipById('summary')"
        />
        <div>
          <hr v-if="showDivisor" class="campus-detail__about__divider-full" />
        </div>
      </div>

      <PridePoints :pride-points="campusDetail.pridepoint_campus" />

      <!-- Instagram Profile -->
      <CampusDetailInstagram
        v-if="igToken && getMultimediaInstagram.length > 6"
        :instagram="getMultimediaInstagram"
        :user="getUserInstagram"
        :is-mobile="mobile"
      />
      <!-- Educational Focus -->
      <CampusDetailEducationalFocus
        id="educational-focus"
        :educational-focus-video="getEducationalFocusVideo"
        :pdf="campusDetail.pei_pdf"
        :partnerships="getPartnershipById('educational-focus')"
      />

      <!-- In their own words -->
      <CampusDetailInTheirOwnWords
        id="in-their-own-words"
        :testimonials="getTestimonials"
        :is-mobile="mobile"
      />

      <!-- 360 virtual tour -->
      <CampusDetailVirtualTour id="360-virtual" :virtual-tour="getVirtualTour" :is-mobile="mobile" />

      <!-- Drone footage -->
      <CampusDetailDrone
        id="drone-footage"
        :key="keyVideoDron"
        :video-drone="getVideoDrone"
        :is-mobile="mobile"
      />

      <!-- Travel Time -->
      <TravelTime
        id="travel-time"
        :institution="getInfoTravelTime"
        :partnerships="getPartnershipById('travel-time')"
        @close-modal="goBack"
      />

      <!-- Panels -->
      <CampusDetailPanels
        :canonical-link="canonicalLink"
        :scroll-to-admissions="scrollToAdmissions"
        @goBack="goBack"
        @close-campus-detail="isCloseCampusDetail"
      />

      <!-- List partnerships -->
      <div v-if="getPartnerships.length > 0 " class="list-partnerships">
        <p v-t="'partnerships.title'" class="campus-detail__panels__title" />
        <div class="w-full d-flex flex-row">
          <Partnership
            v-for="partnership in getPartnerships"
            :key="partnership.id"
            :partnership="partnership"
            display="only-logo"
            class="mr-1"
          />
        </div>
      </div>

      <!-- Contact form -->
      <div class="w-full d-flex mt-2 px-2 pb-7">
        <ContactPanel id="contact-campus" />
      </div>

      <!-- call to action DGE -->
      <CampusDetailCallToActionDGE
        v-if="isPrime"
        @close-campus-detail="isCloseCampusDetail"
      />
    </section>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ContactPanel from '@/components/explorer/contact/ContactPanel.vue';
import FavoriteButton from '@/components/explorer/favorite/FavoriteButton.vue';
import CampusDetailAbout from '@/components/explorer/general/campus_detail/CampusDetailAbout.vue';
import CampusDetailPostulationButtons from '@/components/explorer/general/campus_detail/CampusDetailPostulationButtons.vue';
import CampusDetailCallToActionDGE from '@/components/explorer/general/campus_detail/CampusDetailCallToActionDGE.vue';
import CampusDetailDrone from '@/components/explorer/general/campus_detail/CampusDetailDrone.vue';
import CampusDetailEducationalFocus from '@/components/explorer/general/campus_detail/CampusDetailEducationalFocus.vue';
import CampusDetailHeader from '@/components/explorer/general/campus_detail/CampusDetailHeader.vue';
import CampusDetailInTheirOwnWords from '@/components/explorer/general/campus_detail/CampusDetailInTheirOwnWords.vue';
import CampusDetailInstagram from '@/components/explorer/general/campus_detail/CampusDetailInstagram.vue';
import CampusDetailNavbar from '@/components/explorer/general/campus_detail/CampusDetailNavbar.vue';
import CampusDetailPanels from '@/components/explorer/general/campus_detail/CampusDetailPanels.vue';
import CampusDetailPhotos from '@/components/explorer/general/campus_detail/CampusDetailPhotos.vue';
import CampusDetailVirtualTour from '@/components/explorer/general/campus_detail/CampusDetailVirtualTour.vue';
import TravelTime from '@/components/explorer/general/campus_detail/TravelTime.vue';
import Partnership from '@/components/organisms/campus/Partnership.vue';
import utils from '@/utils/';
import trackMixPanel from '@/utils/mixpanel';
import { CAMPUS_SUBSCRIPTION_STATUS } from '@/constants/category';
import PridePoints from './PridePoints.vue';

export default {
  name: 'CampusDetail',
  components: {
    CampusDetailHeader,
    CampusDetailNavbar,
    CampusDetailAbout,
    CampusDetailEducationalFocus,
    PridePoints,
    CampusDetailInTheirOwnWords,
    CampusDetailVirtualTour,
    CampusDetailDrone,
    CampusDetailPhotos,
    FavoriteButton,
    ContactPanel,
    CampusDetailPanels,
    TravelTime,
    CampusDetailInstagram,
    CampusDetailPostulationButtons,
    Partnership,
    CampusDetailCallToActionDGE,
  },
  metaInfo() {
    return {
      title: this.getTitle(),
      link: [
        ...utils.generateMetaArray([
          {
            vmid: 'canonical',
            rel: 'canonical',
            href: this.getUrlCanonical,
          },
          {
            vmid: 'start',
            rel: 'start',
            href: `${utils.getBaseLink(process.env.VUE_APP_ENVIRONMENT)}/map`,
          },
        ]),
      ],
      meta: [
        ...utils.generateMetaArray([
          // Schema.org School tags
          { name: 'name', content: `Explorador.com: ${this.campusDetail?.campus_name}` },
          { name: 'alternateName', content: this.campusDetail?.institution_name },
          { name: 'identifier', content: this.campusDetail?.campus_code },
          {
            name: 'url',
            content: utils.getCanonicalLink(this.$route.path),
          },
          { name: 'category', content: this.campusDetail?.sector_label?.sector_name },
          { name: 'brand', content: this.getCampusBrand() },
          { name: 'image', content: this.getCampusImageLink() },
          { name: 'logo', content: this.getCampusLogo() },
          { name: 'description', content: this.getInstitutionText(), vmid: 'description' },
          { property: 'hasMap', content: true },
          {
            property: 'latitude',
            content: this.campusDetail?.campus_location?.length > 0
              ? this.campusDetail?.campus_location[0]?.latitud : '',
          },
          {
            property: 'longitude',
            content: this.campusDetail?.campus_location?.length > 0
              ? this.campusDetail?.campus_location[0]?.longitud : '',
          },
          {
            property: 'address',
            content: this.campusDetail?.campus_location?.length > 0
              ? this.campusDetail?.campus_location[0]?.address_street : '',
          },
          {
            property: 'areaServed',
            content: this.campusDetail?.campus_location?.length > 0
              ? this.campusDetail?.campus_location[0]?.plocation.name : '',
          },
          // Mobile Tags
          {
            name: 'apple-mobile-web-app-title',
            content: this.getTitle(),
            vmid: 'apple-mobile-web-app-title',
          },
          // OG Tags
          { property: 'og:title', content: this.getTitle(), vmid: 'og:title' },
          { property: 'og:type', content: 'Institución Educacional', vmid: 'og:type' },
          {
            property: 'og:description',
            content: this.getInstitutionText(),
            vmid: 'og:description',
          },
          {
            property: 'og:url',
            content: utils.getCanonicalLink(this.$route.path),
            vmid: 'og:url',
          },
          { property: 'og:image', content: this.getCampusLogo(), vmid: 'og:image' },
        ]),
      ],
      script: [{
        type: 'application/ld+json',
        json: {
          '@context': 'http://schema.org',
          '@type': 'EducationalOrganization',
          '@id': this.campusDetail?.campus_code,
          name: this.campusDetail?.campus_name,
          alternateName: this.campusDetail?.institution_name,
          description: 'Description of this school',
          url: utils.getCanonicalLink(this.$route.path),
          logo: this.getCampusLogo(),
          image: this.getCampusImageLink(),
          address: this.campusDetail?.campus_location?.length > 0
            ? this.campusDetail?.campus_location[0]?.address_street : '',
          areaServed: this.campusDetail?.campus_location?.length > 0
            ? this.campusDetail?.campus_location[0]?.plocation.name : '',
          latitude: this.campusDetail?.campus_location?.length > 0
            ? this.campusDetail?.campus_location[0]?.latitud : '',
          longitude: this.campusDetail?.campus_location?.length > 0
            ? this.campusDetail?.campus_location[0]?.longitud : '',
          contactPoint: {
            '@type': 'ContactPoint',
            telephone: this.campusDetail?.institutioncontact_campus?.[0]?.phone,
            name: this.campusDetail?.institutioncontact_campus?.[0]?.name,
            email: this.campusDetail?.institutioncontact_campus?.[0]?.email,
            contactType: this.campusDetail?.institutioncontact_campus?.[0]?.contact_label?.contact_name,
          },
        },
      }],
    };
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  props: {
    outsideClose: {
      type: [Boolean, Function],
      default: null,
    },
    scrollToAdmissions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      closePanels: false,
      keyVideoDron: 0,
      modalClosed: false,
      attrs: {
        class: 'mb-6',
        boilerplate: false,
        elevation: 2,
      },
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'institutions/campusDetails',
      loadingProfile: 'institutions/loadingProfile',
      getMultimediaInstagram: 'institutions/getMultimediaInstagram',
      getUserInstagram: 'institutions/getUserInstagram',
    }),
    getUrlCanonical() {
      return `${process.env.VUE_APP_SITE_URL}/school/${this.$route.params.code}`;
    },
    isCampusTetherPack() {
      return this.campusDetail?.config_campus[0]?.account_type === CAMPUS_SUBSCRIPTION_STATUS.PREMIUM;
    },
    isCampusVerified() {
      return this.campusDetail?.verified ?? false;
    },
    isCampusFreemium() {
      return this.campusDetail?.config_campus[0]?.account_type === CAMPUS_SUBSCRIPTION_STATUS.FREEMIUM;
    },
    numberOfVisitsToTheDigitalProfile() {
      const listOfEvents = this.campusDetail?.num_general_views ?? [];
      const totalViews = listOfEvents?.agrupado?.filter(({ event }) => event === 'click_campus_card' || event === 'open_digital_profile_from_url')
        ?.reduce((acc, { total }) => acc + total, 0) ?? 0;

      return totalViews;
    },
    showDivisor() {
      return this.campusDetail.pridepoint_campus.length > 0;
    },
    getPartnerships() {
      return this.campusDetail?.partership_campus?.map((partnership) => partnership.partnerships_label) ?? [];
    },
    hasImages() {
      return this.campusDetail.campus_images_gallery?.length > 0;
    },
    getInfoTravelTime() {
      const institution = {};
      institution.name = this.campusDetail.campus_name;
      if (this.campusDetail.campus_location.length > 0) {
        institution.location = {
          lat: this.campusDetail?.campus_location[0]?.latitud,
          lng: this.campusDetail?.campus_location[0]?.longitud,
        };
        institution.address = this.campusDetail?.campus_location[0]?.address_street;
        return institution;
      }
      return null;
    },
    getSector() {
      return this.campusDetail?.sector_label?.sector_name ?? '';
    },
    getGrades() {
      const indexMinGrade = this.campusDetail?.grade_min_label?.grade_name;
      const indexMaxGrade = this.campusDetail?.grade_max_label?.grade_name;
      if (indexMinGrade === indexMaxGrade) {
        return indexMinGrade;
      }
      if (indexMinGrade && indexMaxGrade) {
        return `${indexMinGrade} ${this.$t('campus-details.to')} ${indexMaxGrade}`;
      }
      if (indexMaxGrade) {
        return indexMaxGrade;
      }
      return '';
    },
    getEducationalFocusVideo() {
      let { audiovisual_campus: listAudiovisual } = this.campusDetail;
      listAudiovisual = listAudiovisual
        .filter((item) => item.audiovisual_label.id === 3 || item.audiovisual_label.id === 4)
        .sort((a, b) => b.audiovisual_label.id - a.audiovisual_label.id)
        .map((item) => item.audiovisual_link)
        .filter((link) => link !== '');

      return listAudiovisual ?? [];
    },
    getTestimonials() {
      let { audiovisual_campus: listAudiovisual } = this.campusDetail;

      listAudiovisual = listAudiovisual ?? [];

      const listTestimonials = listAudiovisual
        .filter((item) => item.audiovisual_label.id > 5)
        .map((item) => ({
          link: item.audiovisual_link,
          name: item?.name ?? '',
          order: item.audiovisual_order,
          audiovisual_label: item.audiovisual_label.audiovisual_name,
        }))
        .sort((a, b) => a.order - b.order);

      return listTestimonials;
    },
    getVideoDrone() {
      const videoDrone = this.campusDetail.audiovisual_campus.find(
        (item) => item.audiovisual_label.id === 1,
      );

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.keyVideoDron += 1;
      return videoDrone ? { link: videoDrone.audiovisual_link, id: videoDrone.id } : null;
    },
    getVirtualTour() {
      const virtualTour = this.campusDetail.audiovisual_campus.find(
        (item) => item.audiovisual_label.id === 2,
      );

      return virtualTour?.audiovisual_link ?? null;
    },
    mobile() {
      return this.$vuetify.breakpoint.width < 670;
    },
    canonicalLink() {
      const canonicalLink = utils.getCanonicalLink(this.$route.path);
      return canonicalLink;
    },
    igToken() {
      const BUSINESS_LABEL = 'Instagram Business';
      const tokenObject = this.campusDetail?.token_campus.find((tkn) => tkn.token_label.name === BUSINESS_LABEL)
        ?? this.campusDetail?.token_campus.find((tkn) => tkn.token_label.name === 'Instagram');
      const token = tokenObject?.token_value;

      if (token) {
        const isBusinessAccount = tokenObject?.token_label.name === BUSINESS_LABEL;
        const businessAccountId = isBusinessAccount ? tokenObject?.account_id : null;
        this.retrieveMultimediaInstagram({ token, isBusinessAccount, businessAccountId });
        this.retrieveUserInstagram({ token, isBusinessAccount, businessAccountId });
      }

      return token;
    },
    isPrime() {
      return this.campusDetail.config_campus?.[0]?.campus_tether_pack;
    },
  },
  watch: {
    outsideClose() {
      if (this.outsideClose === true) {
        this.closePanels = true;
        this.modalClosed = true;
      }
    },
  },
  created() {
    window.addEventListener('beforeunload', () => {
      this.goBack();
    });
    document.body.addEventListener('keydown', (event) => {
      if (event.code === 'Escape' || event.keyCode === 27) {
        utils.resetUrlFromDigitalProfile();
      }
    });
  },
  methods: {
    ...mapActions({
      retrieveMultimediaInstagram: 'institutions/retrieveMultimediaInstagram',
      retrieveUserInstagram: 'institutions/retrieveUserInstagram',
      setIsShowDigitalProfile: 'feedback/setIsShowDigitalProfile',
      hiddenCampusDetail: 'institutions/hiddenCampusDetail',
    }),
    getPartnershipById(id) {
      return this.campusDetail?.partership_campus
        .filter((partnership) => partnership.partnerships_label.section_label.section_code === id)
        .map((partnership) => partnership.partnerships_label);
    },
    isCloseCampusDetail() {
      this.goBack();
    },
    goBack() {
      utils.resetUrlFromDigitalProfile();
      trackMixPanel('close_school_profile', {
        school_id: this.campusDetail.uuid,
        campus_code: this.campusDetail.campus_code,
        institution_code: this.campusDetail.institution_code,
        name: this.campusDetail.campus_name,
      });
      this.closePanels = true;
      this.modalClosed = true;
      this.$emit('close');
      if (this.$route.path === '/dashboard') {
        this.hiddenCampusDetail();
        this.setIsShowDigitalProfile(false);
      }
    },
    profileSummaryIsGenerateByAi() {
      return this.campusDetail?.institutiontext_campus[0]?.profile_summary_aigen ?? false;
    },
    getInstitutionText() {
      if (this.campusDetail.institutiontext_campus?.length > 0) {
        if (this.$i18n.locale === 'en') {
          return this.campusDetail?.institutiontext_campus[0]?.profile_summary_en;
        }
        return this.campusDetail?.institutiontext_campus[0]?.profile_summary;
      }
      return '';
    },
    getCampusBrand() {
      if (this.campusDetail) {
        return `Explorador | ${this.campusDetail.campus_name}`;
      }
      return '';
    },
    getCampusImageLink() {
      if (this.campusDetail) {
        if (this.campusDetail.campus_images_gallery?.length > 0) {
          return this.campusDetail?.campus_images_gallery[0].image_link;
        }
        return '';
      }
      return '';
    },
    getCampusLogo() {
      if (this.campusDetail) {
        if (this.campusDetail.campus_images_thumbnail?.length > 0) {
          return this.campusDetail?.campus_images_thumbnail[0].image_link;
        }
        return '';
      }
      return '';
    },
    getTitle() {
      return `${this.$tc('dashboard.breadcrumbs.app_name')} | ${this.campusDetail?.campus_name?.substring(0, 160)}`;
    },
  },
};
</script>
