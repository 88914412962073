<template>
  <section :key="componentKey" class="container-admission pb-7" :class="{ 'p-3': !isMobile, 'ml-1': isMobile }">
    <div
      v-for="({
        name, start_date, end_date, description, steps, grade_range, image_link, ...props
      }, index) in filterAdmissionProcesses(activeAdmissionProcesses)"
      :id="name"
      :key="index"
      class="process-card p-4 md:p-8"
    >
      <div style="display:block">
        <!-- Process image and basic info -->
        <div class="d-flex mb-6">
          <img
            v-if="image_link"
            :src="image_link"
            :alt="name"
            class="process-logo rounded-lg mr-4"
          />
          <div class="flex flex-col text-left">
            <TextAtom
              :value="name"
              :color="'primary-dark'"
              size="title"
              font="inter"
              weight="700"
              class="pr-3"
            />
            <TextAtom
              :value="getGradeRangeText(grade_range)"
              :color="'primary-dark'"
              font="inter"
              weight="400"
              class="mt-1"
            />
          </div>
        </div>
        <TextAtom
          v-if="start_date && end_date"
          :value="getDateRange(start_date, end_date)"
          font="inter"
          weight="400"
          class="text-left"
          style="color: #6E7191"
        />
        <TextAtom
          v-if="description"
          :value="description"
          font="inter"
          weight="400"
          class="text-left mt-2"
          style="color: #A0A3BD"
        />
        <hr style="color:#EFF0F6; margin-top: 1.5rem;">
      </div>

      <div v-if="steps.length > 0">
        <div
          v-for="({ order, details }, stepIndex) in steps"
          :key="stepIndex"
          class="mt-6"
        >
          <div class="text-left">
            <TextAtom
              :value="`${order}. ${details.name}`"
              :color="'primary-dark'"
              size="subtitle"
              font="inter"
              weight="700"
              class="pr-3"
            />
            <TextAtom
              :value="getDateRange(details.start_date, details.end_date)"
              font="inter"
              weight="400"
              class="mt-2"
              style="color: #6E7191"
            />
            <TextAtom
              :value="details.description"
              font="inter"
              weight="400"
              class="mt-2"
              style="color: #A0A3BD"
            />
          </div>
          <!-- These are mutually exclusive -->
          <div>
            <button
              v-if="isPostulationToCome(details.start_date)"
              type="button"
              class="postulate-btn-inactive mt-3"
              :disabled="details.preapplication_link === '' || details.preapplication_link === null"
              @click="redirect(
                details.preapplication_link,
                'preapplication_step_button_click',
                {
                  processId: props.id,
                  processName: name,
                  stepId: details.id,
                  stepName: details.name,
                },
              )"
            >
              {{ details[`preapplication_button_text_${language}`] || $t('campus-details.admission.apply') }}
            </button>
            <button
              v-if="isPostulationOpen(details.start_date, details.end_date)"
              type="button"
              class="postulate-btn-active mt-3"
              :disabled="details.application_link === '' || details.application_link === null"
              @click="redirect(
                details.application_link,
                'application_step_button_click',
                {
                  processId: props.id,
                  processName: name,
                  stepId: details.id,
                  stepName: details.name,
                },
              )"
            >
              {{ details[`application_button_text_${language}`] || $t('campus-details.admission.apply') }}
            </button>
            <button
              v-if="isPostulationExpired(details.end_date)"
              type="button"
              class="postulate-btn-inactive mt-3"
              :disabled="details.postapplication_link === '' || details.postapplication_link === null"
              @click="redirect(
                details.postapplication_link,
                'postapplication_step_button_click',
                {
                  processId: props.id,
                  processName: name,
                  stepId: details.id,
                  stepName: details.name,
                },
              )"
            >
              {{ details[`postapplication_button_text_${language}`] || $t('campus-details.admission.apply') }}
            </button>
          </div>
        </div>
      </div>

      <!-- Process call to action and dates -->
      <div
        v-if="steps.length === 0"
        class="mt-6"
      >
        <!-- These are mutually exclusive -->
        <div>
          <button
            v-if="isPostulationToCome(start_date)"
            type="button"
            class="postulate-btn-inactive"
            :disabled="props.preapplication_link === '' || props.preapplication_link === null"
            @click="redirect(
              props.preapplication_link,
              'preapplication_process_button_click',
              {
                processId: props.id,
                processName: name,
              },
            )"
          >
            {{ props[`preapplication_button_text_${language}`] || $t('campus-details.admission.apply') }}
          </button>
          <button
            v-if="isPostulationOpen(start_date, end_date)"
            type="button"
            class="postulate-btn-active"
            :disabled="props.application_link === '' || props.application_link === null"
            @click="redirect(
              props.application_link,
              'application_process_button_click',
              {
                processId: props.id,
                processName: name,
              },
            )"
          >
            {{ props[`application_button_text_${language}`] || $t('campus-details.admission.apply') }}
          </button>
          <button
            v-if="isPostulationExpired(end_date)"
            type="button"
            class="postulate-btn-inactive"
            :disabled="props.postapplication_link === '' || props.postapplication_link === null"
            @click="redirect(
              props.postapplication_link,
              'postapplication_process_button_click',
              {
                processId: props.id,
                processName: name,
              },
            )"
          >
            {{ props[`postapplication_button_text_${language}`] || $t('campus-details.admission.apply') }}
          </button>
        </div>
        <TextAtom
          :value="getRemainingTime(start_date, end_date)"
          :color="'primary-medium'"
          font="inter"
          weight="700"
          class="mt-3 mb-3"
        />
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'AdmissionPanel',
  components: {
    TextAtom,
  },
  props: {
    allAdmissionProcesses: {
      type: Array,
      default: () => [],
    },

  },
  data: () => ({
    componentKey: 0,
    activeAdmissionProcesses: [],
  }),
  metaInfo: {
  },
  computed: {
    ...mapGetters({
      language: 'authentication/language',
      campusDetail: 'institutions/campusDetails',
    }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false;
    },
  },
  mounted() {
    this.allAdmissionProcesses.forEach((process) => {
      process.steps.sort((a, b) => a.order - b.order);
    });
    // Hiding processes that already expired
    this.activeAdmissionProcesses = this.allAdmissionProcesses.filter((process) => {
      const { end_date: end } = process;
      return !this.isPostulationExpired(end);
    });
  },
  methods: {
    getGradeRangeText(gradeRange) {
      const { lower_grade: min, upper_grade: max } = gradeRange;
      if (min === max) return min;
      return `${min} ${this.$t('campus-details.to')} ${max}`;
    },
    getDateRange(start, end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const startMonth = this.$t(`campus-details.admission.months-map.${startDate.getMonth() + 1}`);
      const endMonth = this.$t(`campus-details.admission.months-map.${endDate.getMonth() + 1}`);
      return `${startDate.getDate()} ${this.$t('campus-details.of')} ${startMonth} ${this.$t('campus-details.from')}
        ${startDate.getFullYear()} ${this.$t('campus-details.to-date')} ${endDate.getDate()}
        ${this.$t('campus-details.of')} ${endMonth} ${this.$t('campus-details.from')} ${endDate.getFullYear()}`;
    },
    isPostulationOpen(start, end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const today = new Date();
      const milisecondsToBegin = (startDate.getTime() - today.getTime());
      const milisecondsToEnd = (endDate.getTime() - today.getTime());
      if (milisecondsToBegin > 0 && milisecondsToBegin < 600000) {
        setTimeout(() => {
          this.componentKey += 1;
        }, milisecondsToBegin);
      }
      if (milisecondsToEnd > 0 && milisecondsToEnd < 600000) {
        setTimeout(() => {
          this.componentKey += 1;
        }, milisecondsToEnd);
      }
      if (today < startDate || today > endDate) return false;
      return true;
    },
    getRemainingTime(start, end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const today = new Date();
      if (today < startDate) {
        const startDiff = startDate - today;
        if (startDiff < 1000 * 60 * 60 * 24 * 30 * 2) {
          if (startDiff < 1000 * 60 * 60 * 24) {
            if (startDiff < 1000 * 60 * 60) {
              return this.$t('campus-details.admission.starts-next-hour');
            }
            const startHours = Math.floor(startDiff / (1000 * 60 * 60));
            return this.$t('campus-details.admission.hours-to-start', { hours: startHours });
          }
          const startDays = Math.floor(startDiff / (1000 * 60 * 60 * 24));
          return this.$t('campus-details.admission.days-to-start', { days: startDays });
        }
        const startMonths = Math.floor(startDiff / (1000 * 60 * 60 * 24 * 30));
        return this.$t('campus-details.admission.months-to-start', { months: startMonths });
      }
      if (today > endDate) {
        return this.$t('campus-details.admission.postulation-closed');
      }
      const endDiff = endDate - today;
      if (endDiff < 1000 * 60 * 60 * 24 * 30 * 2) {
        if (endDiff < 1000 * 60 * 60 * 24) {
          if (endDiff < 1000 * 60 * 60) {
            return this.$t('campus-details.admission.ends-next-hour');
          }
          const endHours = Math.floor(endDiff / (1000 * 60 * 60));
          return this.$t('campus-details.admission.hours-to-end', { hours: endHours });
        }
        const endDays = Math.floor(endDiff / (1000 * 60 * 60 * 24));
        return this.$t('campus-details.admission.days-to-end', { days: endDays });
      }
      const endMonths = Math.floor(endDiff / (1000 * 60 * 60 * 24 * 30));
      return this.$t('campus-details.admission.months-to-end', { months: endMonths });
    },
    redirect(link, mixpanelTag, metadata) {
      trackMixPanel(mixpanelTag, metadata);
      let redirectLink = link;
      if (link.includes('{campus_code}')) {
        const campusCode = this.campusDetail.campus_code;
        redirectLink = link.replace('{campus_code}', campusCode);
      }
      if (link.includes('{campus_uuid}')) {
        const { uuid } = this.campusDetail;
        redirectLink = link.replace('{campus_uuid}', uuid);
      }
      window.open(redirectLink, '_blank');
    },
    isPostulationToCome(start) {
      if (new Date() < new Date(start)) {
        return true;
      }
      return false;
    },
    isPostulationExpired(end) {
      if (new Date() > new Date(end)) {
        return true;
      }
      return false;
    },
    filterAdmissionProcesses(processes) {
      if (this.$route.query.process_id) {
        const filteredProcesses = Array.isArray(this.$route.query.process_id)
          ? processes.filter((process) => this.$route.query.process_id.includes(String(process.id)))
          : processes.filter((process) => this.$route.query.process_id === String(process.id));
        return filteredProcesses;
      }
      return processes;
    },
  },
};
</script>
<style scoped>
.process-card {
  width: 100%;
  border-radius: 1rem !important;
  box-shadow: 0px 8px 16px 0px rgba(12, 20, 97, 0.05), 0px -4px 8px 0px rgba(12, 20, 97, 0.02);
  margin: 1rem 0;
}
.postulate-btn-active {
  background-color: #5627FF;
  color: white;
  border-radius: 0.7rem;
  border: none;
  padding: 0.4rem 2rem;
  min-width: 180px;
  font-weight: 500;
}
.postulate-btn-inactive {
  background-color: #EFF0F6;
  color: #0C1461;
  border-radius: 0.7rem;
  border: none;
  padding: 0.4rem 2rem;
  min-width: 180px;
  font-weight: 500;
}
.postulate-btn-active:disabled,
.postulate-btn-inactive:disabled {
  background-color: #E0E7FF;
  color: #B2C2FE;
  cursor: default;
}
.process-logo {
  width: 18%;
  object-fit: contain;
  min-width: 70px;
}
</style>
