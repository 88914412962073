import { backendAPI, userApi, userSearchApi } from '@/api';

const registerUrl = 'registration';
const guestRegister = '/token/guest/save_user/';
const tokenUrl = 'token';
const authUrl = 'authentication';
export default {
  /**
   * @response {
   *  name: String,
   *  token: String,
   *  username: String,
   *  register_complete: Boolean
   *  ...
   * }
   */
  loginWithUUID({ uuid }) {
    return userApi.get(`${tokenUrl}/fast_login/${uuid}/`);
  },
  /**
   * @response {
   *  name: String,
   *  token: String,
   *  username: String,
   *  register_complete: Boolean
   *  ...
   * }
   */
  login({
    email, password, username, captcha,
  }) {
    return userApi.post(`${authUrl}/login/`, {
      email,
      password,
      username,
      captcha,
    });
  },
  /**
   * Call to API to logout current user
   * @returns {Promise<AxiosResponse<any>>}
   */
  logout() {
    return userApi.post(`${authUrl}/logout/`);
  },
  /**
   * Retrieve the student list of the logged legal guardian
   * @returns {Promise<AxiosResponse<any>>}
   */
  retrieveStudents() {
    return backendAPI.get(`${registerUrl}/applicant/`);
  },
  /**
   * Register a new user
   * @param username
   * @param email
   * @param password1
   * @param password2
   * @returns {Promise<AxiosResponse<any>>}
   */
  register({
    email, phone, password1, password2, token,
  }) {
    if (phone) {
      return userApi.post(`${guestRegister}`, {
        username: phone,
        phone,
        password1,
        password2,
        token,
      });
    }
    return userApi.post(`${guestRegister}`, {
      email,
      password1,
      password2,
      token,
    });
  },
  /**
   * Checks if the code is valid
   * @param email
   * @param username
   * @param code
   * @param authMethod
   * @returns {Promise<AxiosResponse<any>>}
   */
  verifyCode({
    email, phone, code, authMethod,
  }) {
    if (authMethod === 'email') {
      return userApi.post(`${registerUrl}/verify_code/`, {
        code,
        email,
        username: email,
      });
    }
    return userApi.post(`${registerUrl}/verify_code/`, {
      code,
      email: null,
      username: phone,
    });
  },

  resendVerificationCode({ email, phone, authMethod }) {
    if (authMethod === 'email') {
      return userApi.post(`${registerUrl}/resend_verification_code/`, {
        email,
      });
    }
    return userApi.post(`${registerUrl}/resend_verification_code/`, {
      username: phone,
    });
  },
  /**
   * Send email for password's reset
   * @param email
   * @returns {Promise<AxiosResponse<any>>}
   */
  resetPasswordToken({ email, phone, authMethod }) {
    if (authMethod === 'email') {
      return userApi.post(`${authUrl}/password/reset/`, { email, username: null });
    }
    return userApi.post(`${authUrl}/password/reset/`, { email: null, username: phone });
  },
  /**
   * Confirms token and reset password
   * @param token
   * @param password
   * @returns {Promise<AxiosResponse<any>>}
   */
  resetPassword({ token, newPassword }) {
    return userApi.post(`${authUrl}/password/reset/confirm/`, {
      token,
      new_password: newPassword,
    });
  },
  /**
   * Delete student in database
   * @param uuid
   * @returns {Promise<AxiosResponse<any>>}
   */
  deleteStudent({ uuid }) {
    return backendAPI.delete(`${registerUrl}/applicant/${uuid}/`);
  },
  createLegalGuardian() {
    return backendAPI.post(`${registerUrl}/legal_guardian/step_0/`);
  },
  updateGuest(data, uuid) {
    return backendAPI.put(`${registerUrl}/guest/${uuid}/`, data);
  },
  changePassword({ password1, password2 }) {
    return backendAPI.post(`${registerUrl}/dj-rest-auth/password/change/`, {
      new_password1: password1,
      new_password2: password2,
    });
  },
  tokenResetPassword({ password1, password2 }) {
    return userApi.post(`${authUrl}/password/change/`, {
      new_password1: password1,
      new_password2: password2,
    });
  },
  validateDate(year, month, day) {
    const params = { year, month, day };
    return backendAPI.get(`${registerUrl}/validate_date`, { params });
  },
  refreshToken({ token }) {
    return userApi.post('/token/refresh/', { token });
  },
  fakeToken() {
    return userApi.get('/token/guest/generate/');
  },
  googleAuth({ authResponse, guestToken }) {
    return userApi.post('/socials/google/', {
      id_token: authResponse,
      access_token: authResponse,
      guest_token: guestToken,
    });
  },
  linkAccounts({ gauth }) {
    return userApi.post('/socials/google/connect/', {
      access_token: gauth.access_token,
      id_token: gauth.id_token,
    });
  },
  getUserMainInfo({ uuid }) {
    return userSearchApi.get(`/users/${uuid}/`);
  },
};
