<!-- TODO: Use this component in other parts -->
<template>
  <div
    v-click-outside="collapseOutOfFocus ? () => { panels = [] } : () => {}"
    class="grade"
  >
    <SmallTitle
      v-if="header"
      text="shared.grade.title"
      center-left
    />
    <v-expansion-panels
      v-model="panels"
      class="grade--panels"
      flat
      light
    >
      <v-expansion-panel
        v-for="stage in availableEducationalLevels.stages"
        :key="stage.id"
        :style="selectionMode === 'button' && 'margin-top: 16px; background-color: #ffffff'"
      >
        <ExpansionPanelHeader
          :title="getLocaleProperty(stage, 'stage_name')"
          :icon-color="'#1a0c4c'"
          style="border-radius: 16px"
        />
        <v-expansion-panel-content
          :class="{
            'd-flex flex-wrap': selectionMode === 'button',
          }"
        >
          <div
            v-for="(grade) in stage.grades"
            :key="grade.id"
            class="register-main--check-div"
            style="cursor: pointer"
          >
            <div v-if="selectionMode == 'button'" style="margin-right: 16px">
              <SelectionButton
                :text="getLocaleProperty(grade, 'grade_name')"
                :is-active="isSelected(grade)"
                dark
                multi-selection-icons
                @setValue="toggleOptions(grade, stage)"
              />
            </div>
            <div v-else class="d-flex align-center justify-start mt-2 !first:mt-0">
              <CheckboxInput
                style="margin-right: 8px"
                :value="isSelected(grade)"
                :index="grade.id"
                :radio="single"
                @setValue="toggleOptions(grade, stage)"
              />
              <CheckboxTitle :disabled-value="false" :text="parseGradeName(grade, stage)" />
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <section v-if="summaryChips && value.length > 0" class="w-full">
      <Subtitle
        light
        no-margin
        subtitle="shared.grade.selected_grade"
        class="my-2"
      />
      <div
        class="flex flex-wrap"
      >
        <GradeChip
          v-for="({ grade, stage }, index) in value"
          :key="index"
          :stage-name="getLocaleProperty(stage, 'stage_name')"
          :grade-name="parseGradeName(grade, stage)"
          @deleteOption="toggleOptions(grade, stage)"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SelectionButton from '@/components/atoms/buttons/SelectionButton.vue';
import GradeChip from '@/components/atoms/chips/GradeChip.vue';
import CheckboxInput from '@/components/atoms/inputs/CheckboxInput.vue';
import Subtitle from '@/components/atoms/subtitles/Subtitle.vue';
import CheckboxTitle from '@/components/atoms/titles/CheckboxTitle.vue';
import ExpansionPanelHeader from '@/components/atoms/titles/ExpansionPanelHeader.vue';
import SmallTitle from '@/components/atoms/titles/SmallTitle.vue';
import { getLocaleProperty } from '@/utils/locale';

export default {
  name: 'SelectEducationalLevels',
  components: {
    ExpansionPanelHeader,
    CheckboxInput,
    CheckboxTitle,
    Subtitle,
    GradeChip,
    SelectionButton,
    SmallTitle,
  },
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    educationalLevels: {
      type: Object,
      default: undefined,
    },
    selectionMode: {
      type: String,
      default: 'checkbox',
    },
    single: {
      type: Boolean,
      default: false,
    },
    header: {
      type: Boolean,
      default: false,
    },
    prefillWithActiveGrades: {
      type: Boolean,
      default: true,
    },
    summaryChips: {
      type: Boolean,
      default: false,
    },
    collapseOutOfFocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      panels: [],
    };
  },
  computed: {
    ...mapGetters({
      stageOptions: 'options/stages',
      gradeOptions: 'options/grades',
      activeGrades: 'authentication/activeGrades',
      inDigitalEnrollment: 'digitalEnrollment/inDigitalEnrollment',
      applicationCampusGrades: 'digitalEnrollment/tenantGrades',
      applicationCampusStages: 'digitalEnrollment/tenantStages',
    }),
    availableEducationalLevels() {
      return (
        // If educationalLevels is provided, use it
        this.educationalLevels
        // Otherwise, if inDigitalEnrollment, use the applicationCampusStages and applicationCampusGrades
        || (this.inDigitalEnrollment && {
          stages: this.applicationCampusStages,
          grades: this.applicationCampusGrades,
        })
        // Otherwise, use the default stages and grades
        || ({
          stages: this.stageOptions,
          grades: this.gradeOptions,
        })
      );
    },
    selectedGradesIdSet() {
      return new Set(this.value.map((option) => option.grade.id));
    },
  },
  mounted() {
    if (this.prefillWithActiveGrades) {
      this.prefill();
    }
  },
  methods: {
    /**
     * Get the educational level of a grade by its id.
     * This has a heavy assumption, what the first stage in a grade is the correct one.
     * @param {*} gradeId
     */
    getEducationalLevelOption(gradeId) {
      const { grades, stages } = this.availableEducationalLevels;
      const grade = grades.find(({ id }) => id === gradeId);
      const possibleStages = stages.filter((stage) => stage.grades.includes(gradeId));
      const stage = possibleStages[0];
      // TODO: We looked for possible stages, because activeGrades contains only the grade ids, but we need a version
      // with also the active stages. This is a temporary solution, and we should find a better way to handle this.
      return { grade, stage };
    },
    prefill() {
      // ActiveGrades contains only the grade ids, so we need to map them to the actual grade objects
      const newValue = this.activeGrades.grades.map((gradeId) => this.getEducationalLevelOption(gradeId));
      this.$emit('input', newValue);
    },
    parseGradeName(grade, stage) {
      // This is a hardcoded hack to get the correct name of grades that have multiple names
      // The special conditions are:
      //  - (Chile) Special Stage && GradeID is in the childcare range (1-6)
      //  - (Chile & Col) Adult stage
      // For those cases, the last element in the string, split by /, otherwise the first element is used
      const splitName = getLocaleProperty(grade, 'grade_name').split('/');
      // const isSpecialAndElementary = stage.id === 4 && grade.id <= 6; // Chile-specific condition
      const isAdult = stage.id === 5; // This condition applies to Colombia and Chile
      const splitIndex = isAdult ? splitName.length - 1 : 0;
      return splitName[splitIndex].trim();
    },
    isSelected(grade) {
      return this.selectedGradesIdSet.has(grade.id);
    },
    toggleOptions(grade, stage) {
      let newValue = [];
      if (this.isSelected(grade)) {
        const optionIndex = this.value.findIndex((option) => option.grade.id === grade.id);
        newValue = this.value.slice();
        newValue.splice(optionIndex, 1);
      } else if (this.single) {
        newValue = [{ grade, stage }];
      } else {
        newValue = [...this.value, { grade, stage }];
      }

      this.$emit('input', newValue);
    },
    getLocaleProperty,
  },
};
</script>
