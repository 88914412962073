<template>
  <div class="register-main register-main--spaced">
    <div>
      <ModalHeader :content-sections="['close']" @close="$emit('close')" />
      <ModalContent
        :content-sections="['subtitle', 'icon']"
        subtitle-center
        :subtitle-text="setDataWarningTitle()"
        :icon-color="'#00101A'"
        :icon-name="'mdi-alert-outline'"
        :icon-type="'warning'"
      />
      <ModalContent
        :content-sections="['subtitle']"
        :subtitle-text="setDataWarningContent()"
        subtitle-light
      />
    </div>
    <CallToActions
      :option-text1="'modals.warning_data_modal.btn1'"
      :option-text2="'modals.warning_data_modal.btn2'"
      :types="['option']"
      :spaced="false"
      option-btn-no-margin
      @nextStep="nextStep"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';

export default {
  name: 'DataWarningModal',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
  },
  props: {
    lgDataWarning: {
      type: Boolean,
      default: false,
    },
    noPriority: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      modalInfoFlow: 'userRegister/modalInfoFlow',
      legalGuardian: 'authentication/legalGuardian',
      legalGuardianAddress: 'authentication/legalGuardianAddress',
    }),
    setModalUuid() {
      if (this.modalInfoFlow && this.modalInfoFlow.length > 0) {
        const infoModal = this.modalInfoFlow.find((modal) => {
          if (modal.modal_code.indexOf('w') > -1) {
            return modal;
          }
          return null;
        });
        if (infoModal) {
          return infoModal.uuid;
        }
      }
      return null;
    },
    lgCompletedData() {
      if (this.legalGuardian && this.legalGuardianAddress && this.legalGuardianAddress.length > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      setProfileStep: 'userRegister/setProfileStep',
      setModalFlowInfo: 'userRegister/setModalFlowInfo',
    }),
    setDataWarningTitle() {
      if (this.noPriority) {
        return 'modals.warning_data_modal.no-priority-title';
      }
      return 'modals.warning_data_modal.title';
    },
    setDataWarningContent() {
      if (this.lgDataWarning) {
        return 'modals.warning_data_modal.lg-content';
      }
      if (this.noPriority) {
        return 'modals.warning_data_modal.no-priority-content';
      }
      return 'modals.warning_data_modal.content';
    },
    nextStep(i) {
      let missingDataFor = 'student';
      let profileStepModal = 1;
      let modalCode = '6M.w';
      if (!this.lgCompletedData) {
        modalCode = '4M.w';
        missingDataFor = 'lg';
        profileStepModal = 0;
      }
      if (this.noPriority) {
        modalCode = '5M.w';
      }
      const modalInfo = {
        user: '',
        modal_code: modalCode,
        modal_response: false,
        action_completed: false,
      };
      const modalUuid = this.setModalUuid;
      if (i === 1) {
        modalInfo.modal_response = true;
        this.setModalFlowInfo({ modalInfo, modalUuid });
        this.setProfileStep({ step: { for: missingDataFor, step: profileStepModal } });
        return this.$router.push('/dashboard');
      }
      this.setModalFlowInfo({ modalInfo, modalUuid });
      return this.$emit('close');
    },
  },
};
</script>
