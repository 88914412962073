<template>
  <div class="register-main register-main--spaced">
    <div>
      <ModalHeader :content-sections="['close']" @close="$emit('close')" />
      <ModalContent
        :content-sections="['subtitle', 'icon']"
        subtitle-center
        :subtitle-text="'modals.register_info_modal.title'"
        :icon-color="'#00101A'"
        :icon-name="'mdi-information-outline'"
        :icon-type="'info'"
      />
      <ModalContent
        :content-sections="['subtitle']"
        :subtitle-text="'modals.register_info_modal.content-text'"
        subtitle-light
      />
    </div>
    <CallToActions
      :option-text1="'modals.register_info_modal.btn1'"
      :option-text2="'modals.register_info_modal.btn2'"
      :types="['option']"
      :spaced="false"
      option-btn-no-margin
      @nextStep="nextStep"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';

export default {
  name: 'RegisterInfoModal',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
  },
  computed: {
    ...mapGetters({
      modalInfoFlow: 'userRegister/modalInfoFlow',
      legalGuardianAddress: 'authentication/legalGuardianAddress',
      legalGuardian: 'authentication/legalGuardian',
    }),
    setModalUuid() {
      if (this.modalInfoFlow && this.modalInfoFlow.length > 0) {
        const infoModal = this.modalInfoFlow.find((modal) => {
          if (modal.modal_code.indexOf('i') > -1) {
            return modal;
          }
          return null;
        });
        if (infoModal) {
          return infoModal.uuid;
        }
      }
      return null;
    },
    lgCompletedData() {
      if (this.legalGuardian && this.legalGuardianAddress && this.legalGuardianAddress.length > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      setProfileStep: 'userRegister/setProfileStep',
      setModalFlowInfo: 'userRegister/setModalFlowInfo',
    }),
    nextStep(i) {
      const modalInfo = {
        user: '',
        modal_code: '1M.i1',
        modal_response: false,
        action_completed: false,
      };
      const modalUuid = this.setModalUuid;
      if (i === 1) {
        let profileToEdit = 'lg';
        if (this.lgCompletedData) {
          profileToEdit = 'student';
        }
        modalInfo.modal_response = true;
        modalInfo.action_completed = true;
        this.setProfileStep({ step: { for: profileToEdit, step: 0 } }).then(() => {
          this.setModalFlowInfo({ modalInfo, modalUuid }).then(() => this.$router.push('/dashboard'));
        });
      }
      this.setModalFlowInfo({ modalInfo, modalUuid });
      return this.$emit('close');
    },
  },
};
</script>
