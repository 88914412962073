<template>
  <div id="navbar" class="campus-detail__navbar">
    <v-tabs
      v-model="tabActive"
      fixed-tabs
      background-color="white"
      color="#5627FF"
      light
      show-arrows
    >
      <v-tab
        v-for="({ name, element }, index) in navbar"
        :key="index"
        :href="`#${element}`"
        class="campus-detail__navbar--tab"
        @click="scrollTo(element)"
      >
        {{ name }}
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'CampusDetailNavbar',
  props: {
    hasTestimonial: {
      type: Boolean,
      default: false,
    },
    hasVirtualTour: {
      type: Boolean,
      default: false,
    },
    hasDroneFlight: {
      type: Boolean,
      default: false,
    },
    hasInfrastructure: {
      type: Boolean,
      default: false,
    },
    hasSports: {
      type: Boolean,
      default: false,
    },
    hasExtracurriculars: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      navbar: [],
      tabActive: 'summary',
    };
  },
  computed: {
    ...mapGetters({
      currentStudent: 'authentication/currentStudent',
      campusDetail: 'institutions/campusDetails',
    }),
  },
  created() {
    this.setNavbar();
  },
  mounted() {
    window.addEventListener('wheel', this.handleScroll);
    window.addEventListener('touchmove', this.handleScroll);
    window.addEventListener('touchstart', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('wheel', this.handleScroll);
    window.removeEventListener('touchmove', this.handleScroll);
    window.removeEventListener('touchstart', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.navbar.forEach(({ element }) => {
        const target = document.getElementById(element);
        if (target !== null) {
          const topTarget = target.getBoundingClientRect().top;
          if (topTarget < 350 && topTarget < 370) {
            this.tabActive = element;
          }
        }
      });
    },
    setNavbar() {
      this.navbar = [
        {
          name: this.$t('campus-details.navbar.summary'),
          element: 'summary',
          enable: true,
        },
        {
          name: this.$t('campus-details.navbar.educational_focus'),
          element: 'educational-focus',
          enable: true,
        },
        {
          name: this.$t('campus-details.navbar.in_their_own_words'),
          element: 'in-their-own-words',
          enable: this.hasTestimonial,
        },
        {
          name: this.$t('campus-details.navbar.360_virtual'),
          element: '360-virtual',
          enable: this.hasVirtualTour,
        },
        {
          name: this.$t('campus-details.navbar.drone_footage'),
          element: 'drone-footage',
          enable: this.hasDroneFlight,
        },
        {
          name: this.$t('campus-details.navbar.travel_time'),
          element: 'travel-time',
          enable: true,
        },
        {
          name: this.$t('campus-details.navbar.application_and_enrollment'),
          element: 'admission',
          enable: true,
        },
        {
          name: this.$t('campus-details.navbar.teachers'),
          element: 'team',
          enable: true,
        },
        {
          name: this.$t('campus-details.navbar.students'),
          element: 'students',
          enable: true,
        },
        {
          name: this.$t('campus-details.navbar.infrastructure'),
          element: 'infrastructure',
          enable: this.hasInfrastructure,
        },
        {
          name: this.$t('campus-details.navbar.sports'),
          element: 'sports',
          enable: this.hasSports,
        },
        {
          name: this.$t('campus-details.navbar.extracurriculars'),
          element: 'extracurriculars',
          enable: this.hasExtracurriculars,
        },
        {
          name: this.$t('campus-details.navbar.price'),
          element: 'price',
          enable: true,
        },
        {
          name: this.$t('campus-details.navbar.programs'),
          element: 'programs',
          enable: true,
        },
        {
          name: this.$t('campus-details.navbar.performance'),
          element: 'performance',
          enable: true,
        },
        {
          name: this.$t('campus-details.navbar.vacancies'),
          element: 'vacancy',
          enable: true,
        },
        // {
        //   name: this.$t('campus-details.navbar.language'),
        //   element: 'panels',
        //   enable: false,
        // },
      ];
      this.navbar = this.navbar.filter((tab) => tab.enable);
    },
    scrollTo(element) {
      if (element === 'in-their-own-words') {
        trackMixPanel('click_school_testimonials', {
          school_id: this.campusDetail.uuid,
          campus_code: this.campusDetail.campus_code,
          institution_code: this.campusDetail.institution_code,
        });
      }
      document.getElementById(element).scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>
