const LABELS = Object.freeze({
  PROGRAM_GENDERS: 'PROGRAM_GENDERS',
  GENDERS: 'GENDERS',
  PRIDE_POINTS: 'PRIDE_POINTS',
  SPECIALTIES: 'SPECIALTIES',
  NATIONALITIES: 'NATIONALITIES',
  REGIONS: 'REGIONS',
  MUNICIPALITIES: 'MUNICIPALITIES',
  VIAS: 'VIAS',
  INTITUTIONS: 'INTITUTIONS',
  MONTHLY_PAYMENTS: 'MONTHLY_PAYMENTS',
  PAYMENT_BANDS: 'PAYMENT_BANDS',
  DOCUMENT_TYPES: 'DOCUMENT_TYPES',
  RELATIONSHIPS_TYPES: 'RELATIONSHIPS_TYPES',
  DISABILITIES: 'DISABILITIES',
  ARMED_CONFLICTS: 'ARMED_CONFLICTS',
  AGREEMENTS: 'AGREEMENTS',
  SECTOR_TYPES: 'SECTOR_TYPES',
  NATIVE_LANGUAGES: 'NATIVE_LANGUAGES',
  SPORTS: 'SPORTS',
  STAGES: 'STAGES',
  LENGUAGES: 'LENGUAGES',
  LANGUAGES_LEVELS: 'LANGUAGES_LEVELS',
  INFRASTRUCTURE: 'INFRASTRUCTURE',
  SAFETY: 'SAFETY',
  MEMBERS: 'MEMBERS',
  EXTRACURRICULAR: 'EXTRACURRICULAR',
  SUPPORT: 'SUPPORT',
  LOCATION_TYPES: 'LOCATION_TYPES',
  SISBEN_GROUPS: 'SISBEN_GROUPS',
  AUDIOVISUAL: 'AUDIOVISUAL',
  PARTNERSHIPS: 'PARTNERSHIPS',
  CONTANCT: 'CONTANCT',
  SHIFTS: 'SHIFTS',
  MODALITIES: 'MODALITIES',
});

const LABELS_ENDPOINT_ROUTE = Object.freeze({
  INTITUTIONS: 'institutions',
  REGISTRATION: 'registration',
  PLACES: 'places',
});

const LABELS_CONFIGURATION = Object.freeze({
  [LABELS.PROGRAM_GENDERS]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.INTITUTIONS}/genders`,
  },
  [LABELS.GENDERS]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.REGISTRATION}/genders`,
  },
  [LABELS.PRIDE_POINTS]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.INTITUTIONS}/pridepointlabels`,
  },
  [LABELS.SPECIALTIES]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.INTITUTIONS}/specialitieslabel`,
  },
  [LABELS.NATIONALITIES]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.REGISTRATION}/nationality`,
  },
  [LABELS.REGIONS]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.PLACES}/departments`,
  },
  [LABELS.MUNICIPALITIES]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.PLACES}/municipalities`,
  },
  [LABELS.VIAS]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.REGISTRATION}/vias`,
  },
  [LABELS.INTITUTIONS]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.INTITUTIONS}/qualitymeasurecateglevellabel`,
  },
  [LABELS.MONTHLY_PAYMENTS]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.INTITUTIONS}/paymentcategorylabel`,
  },
  [LABELS.PAYMENT_BANDS]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.INTITUTIONS}/v2/paymentbandslabelbytype`,
  },
  [LABELS.DOCUMENT_TYPES]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.REGISTRATION}/nationalidentification`,
  },
  [LABELS.RELATIONSHIPS_TYPES]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.REGISTRATION}/relationship_types`,
  },
  [LABELS.DISABILITIES]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.REGISTRATION}/disabilities`,
  },
  [LABELS.ARMED_CONFLICTS]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.REGISTRATION}/armedConflict_types`,
  },
  [LABELS.AGREEMENTS]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.AGREEMENTS}/agreementslabels`,
  },
  [LABELS.SECTOR_TYPES]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.INTITUTIONS}/sectorlabel`,
  },
  [LABELS.NATIVE_LANGUAGES]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.REGISTRATION}/nativelanguagelist`,
  },
  [LABELS.SPORTS]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.SPORTS}/sportlabels`,
  },
  [LABELS.STAGES]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.INTITUTIONS}/stageslabel`,
  },
  [LABELS.LANGUAGES_LEVELS]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.INTITUTIONS}/languajelevellabels`,
  },
  [LABELS.INFRASTRUCTURE]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.INTITUTIONS}/infraestructurelabels`,
  },
  [LABELS.SAFETY]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.INTITUTIONS}/safety`,
  },
  [LABELS.MEMBERS]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.INTITUTIONS}/memberlabels`,
  },
  [LABELS.EXTRACURRICULAR]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.EXTRACURRICULAR}/extraactivitylabels`,
  },
  [LABELS.SUPPORT]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.SUPPORT}/supportlabels`,
  },
  [LABELS.LOCATION_TYPES]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.REGISTRATION}/address_labels`,
  },
  [LABELS.SISBEN_GROUPS]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.REGISTRATION}/sisben_category`,
  },
  [LABELS.AUDIOVISUAL]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.INTITUTIONS}/audiovisuallabels`,
  },
  [LABELS.PARTNERSHIPS]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.INTITUTIONS}/partnershiplabels`,
  },
  [LABELS.LENGUAGES]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.INTITUTIONS}/languajelabels`,
  },
  [LABELS.CONTANCT]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.INTITUTIONS}/contactlabels`,
  },
  [LABELS.SHIFTS]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.INTITUTIONS}/shiftslabel`,
  },
  [LABELS.MODALITIES]: {
    PATH: `${LABELS_ENDPOINT_ROUTE.INTITUTIONS}/modalitieslabel`,
  },
});

export { LABELS, LABELS_CONFIGURATION, LABELS_ENDPOINT_ROUTE };
