<template>
  <section>
    <div
      class="py-4 px-5"
      style="background-color: #F7F7FC; border-radius: 10px"
    >
      <TextAtom
        :value="programsSummary.min_grade_label + ' - ' + programsSummary.max_grade_label"
        tag="p"
        font="inter"
        color="primary-dark"
        weight="700"
        size="normal"
      />
      <TextAtom
        v-if="programsSummary.allGenders.length > 0"
        :value="programsSummary.allGenders.join(' · ')"
        tag="p"
        font="inter"
        color="neutral-700"
        weight="500"
        size="normal"
        class="mt-2"
      />
      <TextAtom
        v-if="programsSummary.allModalities.length > 0"
        :value="programsSummary.allModalities.join(' · ')"
        tag="p"
        font="inter"
        color="neutral-700"
        weight="500"
        size="normal"
        class="mt-1"
      />
    </div>

    <v-expansion-panels>
      <v-expansion-panel
        v-for="(stage, stageName) in formatPrograms()"
        :key="stageName"
        accordion
      >
        <v-expansion-panel-header class="programs-panel-header" hide-details expand>
          <template #default="{ open: stageOpen }">
            <TextAtom
              :value="stageName"
              tag="p"
              font="inter"
              :color="stageOpen ? 'primary-medium' : 'primary-dark'"
              weight="700"
              size="normal"
            />
          </template>
          <template #actions>
            <v-icon color="#F7F7FC">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="programs-panel-content">
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(gradePrograms, gradeName) in stage"
              :key="gradeName"
              class="programs-panel-header--grade"
            >
              <v-expansion-panel-header class="programs-panel-header">
                <TextAtom
                  :value="gradeName"
                  tag="p"
                  font="inter"
                  color="primary-dark"
                  weight="700"
                  size="normal"
                />
              </v-expansion-panel-header>
              <v-expansion-panel-content class="programs-panel-content--grade">
                <div
                  v-for="(grade, gradeIndex) in gradePrograms"
                  :key="gradeIndex"
                  class="custom mb-3"
                >
                  <div class="d-flex gap-x-2">
                    <TextAtom
                      :value="grade.shift_label?.shift_name || ''"
                      tag="p"
                      font="inter"
                      color="primary-dark"
                      weight="500"
                      size="normal"
                    />
                    <TextAtom
                      v-if="grade.gender_label?.gender_name"
                      :value="'·'"
                      tag="p"
                      font="inter"
                      color="primary-dark"
                      weight="700"
                      size="normal"
                    />
                    <TextAtom
                      v-if="grade.gender_label?.gender_name"
                      :value="grade.gender_label?.gender_name"
                      tag="p"
                      font="inter"
                      color="primary-dark"
                      weight="500"
                      size="normal"
                    />
                  </div>
                  <div class="d-flex gap-x-2">
                    <TextAtom
                      v-if="grade.gradetrack?.specialty_label?.id !== 1"
                      :value="grade.gradetrack?.specialty_label?.specialty_name"
                      tag="p"
                      font="inter"
                      color="neutral-600"
                      weight="400"
                      size="normal"
                    />
                    <TextAtom
                      v-if="grade.gradetrack?.specialty_label?.id !== 1 && grade.gradetrack?.modality_label?.id !== 1"
                      :value="'·'"
                      tag="p"
                      font="inter"
                      color="neutral-600"
                      weight="700"
                      size="normal"
                    />
                    <TextAtom
                      v-if="grade.gradetrack?.modality_label?.id !== 1"
                      :value="grade.gradetrack?.modality_label?.modality_name"
                      tag="p"
                      font="inter"
                      color="neutral-600"
                      weight="400"
                      size="normal"
                    />
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import CONFIG from '@/config';

export default {
  name: 'ProgramsPanel',
  components: {
    TextAtom,
  },
  data() {
    return {
      programs: [],
      programsSummary: {
        max_grade: 0,
        max_grade_label: '',
        min_grade: 1000,
        min_grade_label: '',
        allGenders: [],
        allModalities: [],
      },
      modalityMapper: {
        1: 'Humanista Científico',
        12: 'Artístico',
        32: 'Técnico Profesional',
        33: 'Técnico Profesional',
        34: 'Técnico Profesional',
        35: 'Técnico Profesional',
        36: 'Técnico Profesional',
      },
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'institutions/campusDetails',
    }),
  },
  watch: {
    programs: {
      handler() {
        for (let i = 0; i < this.programs.length; i += 1) {
          if (this.programs[i].gradetrack.grade_label.id > this.programsSummary.max_grade) {
            this.programsSummary.max_grade = this.programs[i].gradetrack.grade_label.id;
            this.programsSummary.max_grade_label = this.programs[i].gradetrack.grade_label.grade_name;
          }
          if (this.programs[i].gradetrack.grade_label.id < this.programsSummary.min_grade) {
            this.programsSummary.min_grade = this.programs[i].gradetrack.grade_label.id;
            this.programsSummary.min_grade_label = this.programs[i].gradetrack.grade_label.grade_name;
          }
          if (!this.programsSummary.allGenders.includes(this.programs[i].gender_label.gender_name)) {
            this.programsSummary.allGenders.push(this.programs[i].gender_label.gender_name);
          }
          // This data is going to change, hardcoded for chile for now
          if (CONFIG.tenant === 'chile'
                && [1, 12, 32, 33, 34, 35, 36].includes(this.programs[i].gradetrack.modality_label.id)) {
            if (!this.programsSummary.allModalities.includes(
              this.modalityMapper[this.programs[i].gradetrack.modality_label.id],
            )) {
              this.programsSummary.allModalities.push(
                this.modalityMapper[this.programs[i].gradetrack.modality_label.id],
              );
            }
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    formatPrograms() {
      let year = new Date().getFullYear();
      const tempFormattedPrograms = {};
      let filteredPrograms = [];
      while (filteredPrograms.length === 0 && year >= 2018) {
        filteredPrograms = this.campusDetail.program_campus.filter(
          // eslint-disable-next-line no-loop-func
          (program) => program.year === year,
        );
        year -= 1;
      }

      this.programs = [...filteredPrograms];

      filteredPrograms?.forEach((program) => {
        const stage = program.gradetrack.stage_label.stage_name;
        const grade = program.gradetrack.grade_label.grade_name;

        if (!tempFormattedPrograms[stage]) {
          tempFormattedPrograms[stage] = {};
        }
        if (!tempFormattedPrograms[stage][grade]) {
          tempFormattedPrograms[stage][grade] = [];
        }

        tempFormattedPrograms[stage][grade].push(program);
      });

      return tempFormattedPrograms;
    },
  },
};
</script>
<style scoped>

.programs-panel-header {
  background-color: #F7F7FC;
  border-radius: 10px;
  margin: 0;
}
.programs-panel-header--grade {
  background-color: #F7F7FC;
  border-radius: 10px;
  margin: 0;
  margin-top: 0 !important;
  padding: 5px 5px;
  :hover {
    background-color: #f3f3fe;
  }
}
.theme--light.v-expansion-panels .v-expansion-panel {
    box-shadow: none !important;
    border: none !important;
    background-color: transparent !important;
    padding: 0 !important;
    margin-top: 10px;
  }

  .v-expansion-panel::before {
    box-shadow:  none !important;
    border: none !important;
    background-color: transparent !important;
    padding: 0 !important;
  }

.programs-panel-content{
  background-color: #F7F7FC;
  padding: 5px;
  border-radius: 10px;
}
.programs-panel-content--grade{
  background-color: #F7F7FC;
  padding: 8px 24px;
  border-radius: 10px;

}
.theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
  border-color: transparent !important;
}

</style>
