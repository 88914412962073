<template>
  <div
    style="cursor: pointer"
    class="register-main--check-div"
  >
    <input id="checkboxInput" :disabled="!disabledValue" type="checkbox" />
    <label
      class="register-main--check-div--label"
      :style="radio ? 'border-radius: 50%;' : 'border-radius: 6px'"
      :class="{ 'register-main--check-div--label--active': CheckboxValue }"
      style="z-index: 10"
      for="checkboxInput"
      @click="disabledValue ? null : checkboxClick(index)"
      @keydown="/* TODO */"
    >
      <img
        v-if="!radio && CheckboxValue"
        src="@/assets/svg/selectedCheckbox.svg"
        alt="selectedCheckbox"
        style="z-index: 5000"
      />
      <img
        v-if="radio && CheckboxValue"
        src="@/assets/svg/singleSelectedCheckbox.svg"
        alt="selectedCheckbox"
      />
    </label>
  </div>
</template>

<script>
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'CheckboxInput',
  props: {
    value: {
      type: Boolean,
      default() {
        return null;
      },
    },
    disabledValue: {
      type: [String, Boolean],
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
    radio: {
      type: Boolean,
      default: false,
    },
    mixpanelTag: {
      type: String,
      default: null,
    },
    mixpanelPayload: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      CheckboxValue: '',
      selected: false,
    };
  },
  watch: {
    value: {
      handler() {
        this.CheckboxValue = this.value;
      },
    },
  },
  created() {
    this.CheckboxValue = this.value ?? this.selected;
  },
  methods: {
    checkboxClick(index) {
      if (this.mixpanelTag) {
        if (!this.mixpanelPayload) {
          trackMixPanel(this.mixpanelTag);
        } else {
          trackMixPanel(this.mixpanelTag, this.mixpanelPayload);
        }
      }
      this.$emit('input', !this.CheckboxValue);
      this.$emit('setValue', index);
    },
  },
};
</script>
