<template>
  <div class="register-main register-main--safe-padding">
    <FavWarningModal
      v-if="inStep(1)"
      @close="closeModal"
      @closeAllDialogs="$emit('closeAllDialogs')"
    />
    <InitInfoModal v-if="inStep(2)" @toRegisterInfo="toStep(3)" @close="closeModal" />
    <RegisterInfoModal v-if="inStep(3)" @close="closeModal" />
    <NoInfoWarningModal
      v-if="inStep(4)"
      :has-location="noInfoWarnWithLocation"
      @close="closeModal"
    />
    <ApplicationInfoModal
      v-if="inStep(5)"
      :in-nav-buttons="inNavButtons"
      @toApplication="$emit('toApplication')"
      @close="closeModal"
    />
    <DataWarningModal
      v-if="inStep(6)"
      :lg-data-warning="lgDataWarning"
      :no-priority="noPriorityWarn"
      @close="closeModal"
    />
    <DefaultWarningModal
      v-if="inStep(7)"
      :text-info="defaultWarningInfo"
      @toRegisterInfo="toStep(3)"
      @close="closeModal"
    />
    <NoStudentWarningModal
      v-if="inStep(8)"
      @close="closeModal"
    />
  </div>
</template>

<script>
import ApplicationInfoModal from '@/components/organisms/system/ApplicationInfoModal.vue';
import DataWarningModal from '@/components/organisms/system/DataWarningModal.vue';
import DefaultWarningModal from '@/components/organisms/system/DefaultWarningModal.vue';
import FavWarningModal from '@/components/organisms/system/FavWarningModal.vue';
import InitInfoModal from '@/components/organisms/system/InitInfoModal.vue';
import NoInfoWarningModal from '@/components/organisms/system/NoInfoWarningModal.vue';
import NoStudentWarningModal from '@/components/organisms/system/NoStudentWarningModal.vue';
import RegisterInfoModal from '@/components/organisms/system/RegisterInfoModal.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SystemModals',
  components: {
    FavWarningModal,
    InitInfoModal,
    RegisterInfoModal,
    NoInfoWarningModal,
    ApplicationInfoModal,
    DataWarningModal,
    DefaultWarningModal,
    NoStudentWarningModal,
  },
  props: {
    modalIndex: {
      type: Number,
      default: 0,
    },
    noInfoWarnWithLocation: {
      type: Boolean,
      default: false,
    },
    inNavButtons: {
      type: Boolean,
      default: false,
    },
    noPriorityWarn: {
      type: Boolean,
      default: false,
    },
    lgDataWarning: {
      type: Boolean,
      default: false,
    },
    // Set content text and 2 option buttons
    defaultWarningInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      stepStack: [],
    };
  },
  computed: {
    ...mapGetters({}),
    currentStep() {
      return this.stepStack[this.stepStack.length - 1];
    },
  },
  watch: {
    modalIndex: {
      handler(newVal) {
        this.stepStack.push(newVal);
      },
    },
  },
  mounted() {
    this.stepStack.push(this.modalIndex);
  },
  methods: {
    ...mapActions({}),
    closeModal() {
      this.index = 0;
      this.$emit('closeDialog');
    },
    toStep(step) {
      // Jump to a specific step.
      this.stepStack.push(step);
    },
    inStep(...steps) {
      return steps.includes(this.currentStep);
    },
  },
};
</script>
