<template>
  <div class="register-main fill-width" :class="{ 'search-location--register': absolute }">
    <div class="d-flex justify-end fill-width" :style="!noMargin ? 'margin-bottom: 16px' : ''">
      <img
        :class="absolute ? 'search-location--back' : 'register-main--back'"
        src="@/assets/icons/close.svg"
        alt="close"
        style="cursor: pointer"
        @click="$emit('close')"
        @keydown="/* TODO: */"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CloseAction',
  components: {},
  props: {
    absolute: {
      type: Boolean,
      default: false,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
