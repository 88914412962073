<template>
  <RedirectionDialog
    v-if="isActive"
    :title="`dialog.redirect.title`"
    :subtitle="{
      path: 'dialog.redirect.subtitle',
      args: { site: providerName, action: $t('payments.dialog.redirect.action') },
    }"
    :to-link="toLink"
    :site="providerName"
    :wait-time="waitTime"
    in-new-window
    value
    @cancel="deactivateModal"
    @redirected="onRedirectCallback"
  />
</template>

<script>
import RedirectionDialog from '@/components/molecules/dialogs/RedirectionDialog.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PaymentRedirectionModal',
  components: {
    RedirectionDialog,
  },
  computed: {
    ...mapGetters({
      activeModal: 'utils/activeModal',
      activeModalData: 'utils/activeModalData',
      activeModalCallbacks: 'utils/activeModalCallbacks',
    }),
    isActive() {
      return this.activeModal === 'PaymentRedirection';
    },
    toLink() {
      return this.activeModalData?.toLink || null;
    },
    waitTime() {
      return this.activeModalData?.waitTime || 5;
    },
    providerName() {
      return this.activeModalData?.providerName || null;
    },
    onRedirectCallback() {
      return this.activeModalCallbacks?.onRedirect || (() => {});
    },
  },
  methods: {
    ...mapActions({
      deactivateModal: 'utils/deactivateModal',
    }),
  },
};
</script>
