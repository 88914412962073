<template>
  <section>
    <div class="option__radio--button">
      <input
        :disabled="!thereAreStatsCategory.global"
        class="mr-1"
        type="radio"
        id="radioGlobal"
        v-model="showStat"
        value="global"
      />
      <label
        class="mr-1 option__radio--button-full"
        for="radioGlobal"
        v-t="'campus-details.test.global'"
      />
    </div>

    <div class="option__radio--button pt-0 fix-margin-top">
      <input
        :disabled="!thereAreStatsCategory.math"
        class="mr-1"
        type="radio"
        id="radioMath"
        v-model="showStat"
        value="math"
      />

      <label
        class="mr-1 option__radio--button-40"
        for="radioMath"
        v-t="'campus-details.test.math'"
      />
      <input
        :disabled="!thereAreStatsCategory.naturalSciences"
        class="mr-1"
        type="radio"
        id="radioNatural"
        v-model="showStat"
        value="naturalSciences"
      />
      <label
        class="mr-1 option__radio--button-60"
        for="radioNatural"
        v-t="'campus-details.test.naturalSciences'"
      />
    </div>

    <div class="option__radio--button pt-0 fix-margin-top">
      <input
        :disabled="!thereAreStatsCategory.socialSciences"
        class="mr-1"
        type="radio"
        id="radioSocial"
        v-model="showStat"
        value="socialSciences"
      />
      <label
        class="mr-1 option__radio--button-full"
        for="radioSocial"
        v-t="'campus-details.test.socialSciences'"
      />
    </div>

    <div class="option__radio--button pt-0 fix-margin-top">
      <input
        :disabled="!thereAreStatsCategory.read"
        class="mr-1"
        type="radio"
        id="radioRead"
        v-model="showStat"
        value="read"
      />

      <label class="mr-1" for="radioRead" v-t="'campus-details.test.reading'" />
      <input
        :disabled="!thereAreStatsCategory.english"
        class="mr-1"
        type="radio"
        id="radioEnglish"
        v-model="showStat"
        value="english"
      />
      <label class="mr-1" for="radioEnglish" v-t="'campus-details.test.english'" />
    </div>

    <div class="d-flex flex-column mx-5 mt-2">
      <h3 class="performance-global__institution" v-t="'campus-details.test.institutionAverage'" />

      <p v-if="noInfoStats" class="ml-2 mt-2">
        <span class="performance-global__total" v-t="'campus-details.school_info'" />
      </p>
      <p class="ml-2 mt-2" v-for="({ mean, year }, index) in stat[showStat].results" :key="index">
        <span class="performance-global__year">Año: {{ year }} :</span> &nbsp;
        <span class="performance-global__total">{{ mean.toFixed(1) }}</span>
      </p>
    </div>

    <div v-if="false" class="d-flex flex-column mx-5 mt-4 mb-5">
      <h3
        class="performance-global__institution performance-global__institutions"
        v-t="'campus-details.test.InstitutionsAverage'"
      />
      <p class="ml-2 mt-2">
        <span class="performance-global__year">Año {{ stat[showStat].avg_all_campus.year }}:</span>
        &nbsp;
        <!-- // ! change for variable -->
        <span class="performance-global__total">{{
          stat[showStat].avg_all_campus.value.toFixed(1)
        }}</span>
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PerformanceGlobal',
  data() {
    return {
      showStat: 'global',
      stat: {
        global: {},
        math: {},
        naturalSciences: {},
        socialSciences: {},
        read: {},
        english: {},
      },
    };
  },
  props: {
    stats: {
      type: Array,
    },
  },
  mounted() {
    this.stats.forEach((stat) => {
      this.formatStats(stat);
    });
  },
  methods: {
    formatStats(stat) {
      switch (stat.area_name) {
        case 'Ciencias Naturales':
          this.stat.naturalSciences = { ...stat };
          break;

        case 'Inglés':
          this.stat.english = { ...stat };
          break;

        case 'Lectura Crítica':
          this.stat.read = { ...stat };
          break;

        case 'Matemáticas':
          this.stat.math = { ...stat };
          break;

        case 'Puntaje global':
          this.stat.global = { ...stat };
          break;

        case 'Ciencias sociales y ciudadanas':
          this.stat.socialSciences = { ...stat };
          break;

        default:
          break;
      }
    },
  },
  computed: {
    noInfoStats() {
      if (
        !this.thereAreStatsCategory.math &&
        !this.thereAreStatsCategory.naturalSciences &&
        !this.thereAreStatsCategory.english &&
        !this.thereAreStatsCategory.read &&
        !this.thereAreStatsCategory.global &&
        !this.thereAreStatsCategory.socialSciences
      ) {
        return true;
      }
      return false;
    },
    thereAreStatsCategory() {
      const categoryEnable = {
        global: false,
        math: false,
        naturalSciences: false,
        socialSciences: false,
        read: false,
        english: false,
      };
      this.stats.forEach((stat) => {
        if (stat.area_name === 'Ciencias Naturales') {
          categoryEnable.naturalSciences =
            stat.results.length === 0 ? categoryEnable.naturalSciences : true;
        } else if (stat.area_name === 'Inglés') {
          categoryEnable.english = stat.results.length === 0 ? categoryEnable.english : true;
        } else if (stat.area_name === 'Lectura Crítica') {
          categoryEnable.read = stat.results.length === 0 ? categoryEnable.read : true;
        } else if (stat.area_name === 'Matemáticas') {
          categoryEnable.math = stat.results.length === 0 ? categoryEnable.math : true;
        } else if (stat.area_name === 'Puntaje global') {
          categoryEnable.global = stat.results.length === 0 ? categoryEnable.global : true;
        } else if (stat.area_name === 'Ciencias sociales y ciudadanas') {
          categoryEnable.socialSciences =
            stat.results.length === 0 ? categoryEnable.socialSciences : true;
        }
      });

      return categoryEnable;
    },
  },
};
</script>

<style></style>
