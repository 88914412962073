<template>
  <SelectionDialog
    v-if="activeModal === 'SelectFacebookPage'"
    title="modals.select_facebook_modal.title"
    subtitle="modals.select_facebook_modal.description"
    :options="getOptions()"
    confirm-button-text="modals.select_facebook_modal.confirm"
    cancel-button-text="modals.select_facebook_modal.cancel"
    value
    @confirm="confirm($event)"
    @cancel="cancel"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SelectionDialog from '@/components/molecules/dialogs/SelectionDialog.vue';

export default {
  name: 'SelectFacebookPageModal',
  components: {
    SelectionDialog,
  },
  computed: {
    ...mapGetters({
      activeModal: 'utils/activeModal',
      activeModalData: 'utils/activeModalData',
      activeModalCallbacks: 'utils/activeModalCallbacks',
      campusDetail: 'roles/currentHeadMasterMainSchool',
    }),
  },
  methods: {
    ...mapActions({
      deactivateModal: 'utils/deactivateModal',
      snackbarError: 'utils/error',
    }),
    confirm(selected) {
      const selectedAccount = this.activeModalData.accounts[selected];
      if (this.activeModalCallbacks.onConfirm) this.activeModalCallbacks.onConfirm(selectedAccount);
      this.deactivateModal();
    },
    cancel() {
      if (this.activeModalCallbacks.onCancel) this.activeModalCallbacks.onCancel();
      this.deactivateModal();
    },
    getOptions() {
      const options = [];
      this.activeModalData.accounts.forEach((account) => {
        options.push({
          title: account.name,
        });
      });
      return options;
    },
  },

};
</script>
