// ************************************************************************************************
// ***********************         CONSTANTS for filters        ***********************************
// ************************************************************************************************
import { CAMPUS_PROPERTIES } from './category';

const TETHER_FILTER_CONTEXTS = Object.freeze({
  EXPLORER: 'explorer',
  FAVORITES: 'favorites',
  NAVBAR: 'navbar',
});

const {
  HAS_VACANCIES,
  AGREEMENTS,
  MULTIMEDIA,
  INFRASTRUCTURE,
  SPORTS,
  EXTRACURRICULARS,
  LANGUAGES,
  SPECIALTIES,
  DEPENDENCIES,
  PERFORMANCE,
  PAYMENT,
  PARTNERSHIPS,
  DISTANCE,
  NETWORK_ID,
  PROCESS_ID,
  INSTITUTION,
  MODALITY,
} = CAMPUS_PROPERTIES;

const TETHER_FILTER_KEYS = Object.freeze({
  HAS_VACANCIES,
  AGREEMENTS,
  MULTIMEDIA,
  INFRASTRUCTURE,
  SPORTS,
  EXTRACURRICULARS,
  LANGUAGES,
  SPECIALTIES,
  DEPENDENCIES,
  PERFORMANCE,
  PAYMENT,
  // Same ids as agreement, but if set the filter will match a campus if it fits PAYMENT OR PAYMENT_AGREEMENT
  PAYMENT_AGREEMENT: 'PAYMENT_AGREEMENT',
  PARTNERSHIPS,
  NETWORK_ID,
  DISTANCE, // Uses categories
  DISTANCE_RAW: 'DISTANCE_RAW', // Uses raw, distance in km
  GRADE: 'GRADE',
  PROCESS_ID,
  ADMISSION: 'ADMISSION',
  INSTITUTION,
  MODALITY,
  PLACE_ID: 'PLACE_ID',
});

export {
  TETHER_FILTER_CONTEXTS,
  TETHER_FILTER_KEYS,
};
