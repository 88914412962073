<template>
  <svg width="56" height="41" viewBox="0 0 56 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M1.67172 32.6021L7.21575 29.7866L1.26147 27.995L1.15417 26.79L8.20027 26.1626L8.28301 27.0917L5.5391 27.3361L2.56966 27.5127L8.48124 29.3178L8.54458 30.0292L3.05906 32.8444L5.99804 32.49L8.74195 32.2456L8.82469 33.1748L1.77859 33.8022L1.67172 32.6021Z"
        fill="#454444"
      />
      <path
        d="M9.71264 21.3439C9.59824 21.3623 9.40948 21.3472 9.14636 21.2986C9.43544 21.8272 9.49322 22.3681 9.3197 22.9213C9.1646 23.4158 8.89805 23.7786 8.52006 24.0096C8.13995 24.2366 7.7351 24.2828 7.30552 24.148C6.78323 23.9842 6.44041 23.6594 6.27708 23.1737C6.11162 22.6839 6.14183 22.079 6.36769 21.3589L6.62943 20.5244L6.23539 20.4008C5.93561 20.3068 5.66953 20.3218 5.43715 20.4458C5.20168 20.5688 5.02917 20.8049 4.91963 21.1542C4.82366 21.4601 4.82046 21.7409 4.91004 21.9964C4.99962 22.2519 5.15412 22.4141 5.37354 22.4829L5.10308 23.3452C4.85275 23.2667 4.63932 23.103 4.46279 22.8541C4.28414 22.6012 4.17432 22.302 4.13334 21.9564C4.09332 21.6078 4.13051 21.2512 4.2449 20.8865C4.42617 20.3086 4.71344 19.9014 5.1067 19.6649C5.49687 19.4275 5.94732 19.3821 6.45804 19.5287L8.76664 20.2528C9.22712 20.3973 9.61176 20.4534 9.92056 20.4213L9.99473 20.4445L9.71264 21.3439ZM8.6126 22.562C8.69694 22.2932 8.70738 22.0164 8.64392 21.7317C8.58046 21.447 8.45443 21.2157 8.26585 21.0377L7.23671 20.7149L7.02587 21.3871C6.69628 22.4379 6.83899 23.0597 7.454 23.2526C7.72287 23.337 7.96114 23.3132 8.16879 23.1815C8.37645 23.0498 8.52439 22.8433 8.6126 22.562Z"
        fill="#454444"
      />
      <path
        d="M8.19364 17.7349C7.51679 17.273 7.08586 16.7495 6.90085 16.1645C6.71317 15.5776 6.79551 15.026 7.14788 14.5097C7.50938 13.9799 7.97873 13.6944 8.55593 13.6531L8.09902 13.2883L8.56185 12.6101L12.7995 15.5021C13.3613 15.8855 13.69 16.3549 13.7855 16.9102C13.8828 17.4629 13.7398 18.0201 13.3564 18.5819C13.1427 18.8949 12.8668 19.1556 12.5286 19.364C12.1903 19.5723 11.851 19.676 11.5105 19.675L11.328 18.9857C11.9385 18.9358 12.4008 18.6807 12.7148 18.2206C12.9613 17.8594 13.0513 17.5091 12.9849 17.1697C12.9204 16.8276 12.7035 16.5306 12.3343 16.2786L11.9611 16.024C12.1009 16.5546 11.9955 17.0768 11.6449 17.5904C11.298 18.0987 10.8122 18.3711 10.1875 18.4074C9.56453 18.4411 8.89992 18.2169 8.19364 17.7349ZM8.7873 17.046C9.27688 17.3801 9.73059 17.5427 10.1484 17.5338C10.5636 17.523 10.8944 17.337 11.1409 16.9759C11.4604 16.5077 11.4823 16.0188 11.2066 15.5091L9.22425 14.1562C8.65439 14.1006 8.21427 14.3002 7.90389 14.755C7.65741 15.1162 7.60525 15.4943 7.7474 15.8893C7.88955 16.2843 8.23618 16.6699 8.7873 17.046Z"
        fill="#454444"
      />
      <path
        d="M11.6249 9.30927L12.065 9.80299C12.0571 9.15762 12.3041 8.6303 12.8062 8.22103C13.6673 7.51915 14.4976 7.65091 15.297 8.61631L17.4917 11.3088L16.7951 11.8766L14.5973 9.1804C14.3554 8.88872 14.1106 8.72682 13.863 8.69469C13.6178 8.66051 13.3559 8.75699 13.0773 8.98413C12.8513 9.1683 12.7021 9.39021 12.6296 9.64986C12.5571 9.90951 12.553 10.1761 12.6172 10.4497L14.9838 13.353L14.2871 13.9209L10.966 9.84643L11.6249 9.30927Z"
        fill="#454444"
      />
      <path
        d="M21.4703 9.45029C20.8134 9.72619 20.1885 9.73566 19.5958 9.4787C19.0019 9.21877 18.5532 8.72746 18.2497 8.00479L18.1857 7.85249C17.9838 7.37171 17.8949 6.90494 17.9191 6.45217C17.945 5.99516 18.0776 5.59168 18.3171 5.2417C18.5582 4.88749 18.8715 4.6295 19.2567 4.46772C19.8868 4.2031 20.4637 4.20498 20.9874 4.47334C21.5111 4.74169 21.9353 5.26259 22.2602 6.03603L22.405 6.38094L19.1216 7.75981C19.3342 8.23259 19.6355 8.56099 20.0254 8.74501C20.4171 8.9248 20.819 8.92816 21.2311 8.75509C21.5237 8.63219 21.7465 8.46838 21.8994 8.26365C22.0523 8.05892 22.1635 7.82603 22.233 7.56498L22.9048 7.7466C22.7607 8.54128 22.2826 9.10917 21.4703 9.45029ZM19.5426 5.14858C19.2081 5.28904 18.9789 5.52936 18.8547 5.86954C18.7294 6.20674 18.732 6.60611 18.8626 7.06766L21.2904 6.04808L21.2641 5.98537C21.0633 5.57435 20.8132 5.2965 20.5135 5.15184C20.2127 5.00419 19.889 5.00311 19.5426 5.14858Z"
        fill="#454444"
      />
      <path
        d="M25.3303 1.71106L25.4625 2.97706L26.4385 2.87519L26.5107 3.56618L25.5346 3.66804L25.873 6.91036C25.8948 7.11975 25.9548 7.27306 26.0528 7.37028C26.1506 7.46429 26.3041 7.50037 26.5135 7.47852C26.6166 7.46776 26.7563 7.43364 26.9327 7.37615L27.0083 8.10096C26.7799 8.18993 26.5546 8.24602 26.3323 8.26921C25.9328 8.3109 25.619 8.22153 25.3909 8.00111C25.1627 7.78069 25.0254 7.4482 24.979 7.00365L24.6406 3.76134L23.6887 3.86068L23.6166 3.1697L24.5685 3.07035L24.4364 1.80435L25.3303 1.71106Z"
        fill="#454444"
      />
      <path
        d="M34.5264 5.73551C33.8096 5.31233 33.3158 4.89339 33.0452 4.47868C32.7784 4.06164 32.7068 3.61483 32.8302 3.13823C32.9698 2.59892 33.2999 2.2093 33.8204 1.96936C34.3449 1.7271 34.952 1.69529 35.6418 1.87392C36.1122 1.99572 36.5072 2.19505 36.827 2.47191C37.1499 2.74958 37.3702 3.07431 37.4881 3.4461C37.6091 3.8187 37.6204 4.1947 37.5222 4.5741L36.6145 4.33903C36.7216 3.92514 36.674 3.56651 36.4715 3.26314C36.2698 2.95664 35.9291 2.74127 35.4494 2.61704C35.0042 2.50174 34.6305 2.51038 34.3285 2.64296C34.0305 2.77321 33.8364 3.01236 33.7463 3.3604C33.674 3.63946 33.7303 3.90664 33.9151 4.16194C34.1039 4.41492 34.4613 4.69485 34.9874 5.00173C35.5167 5.30942 35.9161 5.60188 36.1855 5.8791C36.459 6.154 36.6392 6.4399 36.7263 6.7368C36.8165 7.03451 36.8165 7.35739 36.7264 7.70543C36.5826 8.26041 36.251 8.64963 35.7314 8.87308C35.2126 9.09339 34.5911 9.10976 33.8668 8.9222C33.3965 8.8004 32.9806 8.59736 32.6193 8.31308C32.2588 8.02566 32.0078 7.69635 31.8664 7.32515C31.7281 6.95476 31.7105 6.57046 31.8136 6.17225L32.7214 6.40732C32.6142 6.82121 32.6814 7.18825 32.923 7.50845C33.1686 7.82632 33.5469 8.05143 34.058 8.18378C34.5346 8.3072 34.9251 8.3046 35.2294 8.17597C35.5337 8.04733 35.7293 7.81527 35.8162 7.47977C35.9031 7.14427 35.8525 6.85514 35.6644 6.61238C35.4772 6.36649 35.0978 6.0742 34.5264 5.73551Z"
        fill="#454444"
      />
      <path
        d="M39.2678 10.5917C39.5392 10.7625 39.828 10.8295 40.1344 10.7927C40.4408 10.7559 40.6825 10.6211 40.8598 10.3882L41.5793 10.8411C41.4051 11.0874 41.1647 11.2748 40.8581 11.4032C40.5514 11.5317 40.2193 11.579 39.8616 11.5453C39.5067 11.5132 39.1785 11.4023 38.877 11.2125C38.2713 10.8312 37.9156 10.3261 37.8102 9.69715C37.7092 9.06718 37.8796 8.40134 38.3212 7.69963L38.4015 7.57218C38.6741 7.13909 38.996 6.80401 39.3672 6.56693C39.7384 6.32985 40.1306 6.21128 40.544 6.21123C40.9601 6.2129 41.371 6.34141 41.7767 6.59676C42.2755 6.91077 42.5954 7.32069 42.7363 7.8265C42.8799 8.33404 42.8125 8.83319 42.5343 9.32393L41.8147 8.87103C41.974 8.56942 42.0127 8.26467 41.9309 7.9568C41.8536 7.64791 41.6711 7.40288 41.3833 7.22172C40.9968 6.97844 40.6087 6.92931 40.2189 7.07432C39.8336 7.21832 39.4753 7.55346 39.144 8.07974L39.0534 8.22364C38.7308 8.73622 38.5879 9.19736 38.6247 9.60706C38.6615 10.0168 38.8758 10.345 39.2678 10.5917Z"
        fill="#454444"
      />
      <path
        d="M45.8139 10.8202C46.4444 10.8027 46.968 11.0357 47.3845 11.519C48.1097 12.3605 48.0007 13.1941 47.0576 14.0197L44.4263 16.2873L43.8396 15.6065L46.4746 13.3357C46.7595 13.0859 46.9146 12.8367 46.94 12.5883C46.9674 12.3423 46.8638 12.0832 46.6291 11.8108C46.4388 11.59 46.2129 11.4469 45.9514 11.3816C45.6898 11.3162 45.4232 11.3194 45.1515 11.3911L42.3141 13.8363L41.7273 13.1555L47.3801 8.28407L47.9668 8.9649L45.8139 10.8202Z"
        fill="#454444"
      />
      <path
        d="M48.0823 17.004C48.5613 16.8149 49.0289 16.7382 49.4852 16.7739C49.9427 16.8127 50.3414 16.9582 50.6813 17.2105C51.0224 17.4658 51.2779 17.8089 51.448 18.2397C51.7108 18.9054 51.6927 19.5342 51.3935 20.1258C51.0956 20.7205 50.564 21.1689 49.7988 21.471L49.7401 21.4941C49.2641 21.6821 48.8001 21.7591 48.3479 21.7251C47.9 21.6931 47.5034 21.5484 47.1582 21.2913C46.8141 21.0371 46.5552 20.6902 46.3816 20.2503C46.1199 19.5876 46.1381 18.9589 46.4361 18.3642C46.7352 17.7726 47.2644 17.3269 48.0235 17.0272L48.0823 17.004ZM48.3147 17.8837C47.7724 18.0978 47.3865 18.3946 47.1568 18.7743C46.9284 19.157 46.8974 19.5592 47.0639 19.981C47.2316 20.4057 47.5305 20.6777 47.9606 20.7969C48.3937 20.9149 48.91 20.8556 49.5094 20.6189C50.0457 20.4072 50.4289 20.1079 50.6592 19.7211C50.8937 19.336 50.9277 18.9326 50.7612 18.5109C50.5982 18.0981 50.3026 17.8301 49.8744 17.7067C49.4461 17.5833 48.9262 17.6423 48.3147 17.8837Z"
        fill="#454444"
      />
      <path
        d="M50.5382 24.0812C51.0527 24.0604 51.5196 24.1421 51.9387 24.3262C52.3579 24.5135 52.6864 24.7822 52.9242 25.1325C53.1621 25.4859 53.2904 25.894 53.3091 26.3568C53.3379 27.0721 53.1137 27.6597 52.6363 28.1198C52.159 28.5832 51.5094 28.8314 50.6873 28.8646L50.6242 28.8671C50.1129 28.8877 49.6494 28.8076 49.2336 28.6266C48.8213 28.4487 48.4944 28.1815 48.2532 27.825C48.012 27.4716 47.8819 27.0587 47.8628 26.5862C47.8341 25.8742 48.0584 25.2866 48.5357 24.8232C49.0131 24.3631 49.6595 24.1166 50.4751 24.0837L50.5382 24.0812ZM50.4678 24.9884C49.8853 25.0119 49.423 25.1651 49.0811 25.4479C48.7393 25.734 48.5776 26.1036 48.5958 26.5567C48.6143 27.013 48.8069 27.3683 49.1737 27.6225C49.5438 27.8766 50.0508 27.9907 50.6948 27.9647C51.2709 27.9415 51.7313 27.7852 52.0762 27.4957C52.4245 27.2094 52.5895 26.8397 52.5712 26.3866C52.5533 25.9432 52.3625 25.5927 51.9988 25.3351C51.6351 25.0775 51.1248 24.9619 50.4678 24.9884Z"
        fill="#454444"
      />
      <path
        d="M47.6194 31.8571L47.8023 30.9771L55.1087 32.4965L54.9257 33.3765L47.6194 31.8571Z"
        fill="#454444"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.0952 21.2L17.3352 26.98V40.5H38.8852C38.8952 36.16 38.9352 31.16 38.8852 26.98L28.0952 21.2ZM28.1652 37.93C31.5352 37.92 34.3652 35.13 34.3652 31.81C34.3652 28.55 31.4252 25.64 28.1552 25.68C24.6452 25.73 21.9652 28.54 22.0152 32.12C22.0652 35.27 24.8852 37.94 28.1652 37.93Z"
        fill="#424241"
      />
      <path
        d="M28.4053 17.59L41.2053 24.3"
        stroke="#424241"
        stroke-width="2.777"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M15.3052 24.3C16.5052 23.51 26.8752 18.19 28.1752 17.5"
        stroke="#424241"
        stroke-width="2.777"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M28.1753 13.84V17.64"
        stroke="#424241"
        stroke-width="2.777"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M29.4252 29.63L26.8652 30.94C25.7952 31.48 25.3852 32.15 25.7652 32.89C26.1452 33.64 26.8752 33.73 27.9952 33.17L30.6352 31.83L31.5952 33.72L29.0752 35C26.9052 36.1 25.2552 35.57 24.2552 33.6C23.2852 31.68 23.8152 30.12 26.0752 28.97L28.4852 27.74L29.4252 29.63Z"
        fill="#424241"
      />
      <path
        d="M30.5753 26.8826L29.5466 27.4186L30.3738 29.006L31.4025 28.47L30.5753 26.8826Z"
        fill="#424241"
      />
      <path
        d="M32.4354 30.3826L31.4067 30.9186L32.2339 32.506L33.2626 31.9699L32.4354 30.3826Z"
        fill="#424241"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="55.03" height="40.03" fill="white" transform="translate(0.594971 0.470001)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'MagnetSchool',
};
</script>
