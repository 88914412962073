<template>
  <div>
    <!-- Import here all the Modal Groups -->
    <PaymentModals />
    <FeedbackModals />
    <DashboardModals />
    <SystemModals />
  </div>
</template>

<script>
import PaymentModals from '@/components/organisms/payments/PaymentModals.vue';
// TODO: Homogenize where we keep modals in every context. For payments is in organisms, feedback in views, etc.
import FeedbackModals from '@/views/feedback/FeedbackModals.vue';
import DashboardModals from '@/components/organisms/dashboard/DashboardModals.vue';
import SystemModals from '@/components/organisms/system/SystemModals.vue';

export default {
  name: 'ApplicationModals',
  components: {
    PaymentModals,
    FeedbackModals,
    DashboardModals,
    SystemModals,
  },
};
</script>
