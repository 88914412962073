<template>
  <section class="campus-detail__focus campus-detail__module mt-2">
    <div class="w-full d-flex flex-row align-center">
      <div
        class="campus-detail__focus__information"
        :class="{ 'campus-detail__focus__information--mobile': isMobile }"
      >
        <h1> {{ $t('campus-details.education_focus.title') }}</h1>

        <button v-show="pdf" type="button" @click="downloadPdf">
          <span v-t="'campus-details.education_focus.summary'" />
          <img class="ml-2" src="@/assets/icons/direct-download.svg" alt="Descargar Proyecto Educativo" />
        </button>
      </div>
      <div
        class="campus-detail__focus__multimedia"
        :class="{ 'campus-detail__focus__multimedia--mobile': isMobile }"
      >
        <template v-if="educationalFocusVideo.length">
          <div
            class="campus-detail__focus__multimedia--video"
            :class="{
              'campus-detail__focus__multimedia--video--mobile': isMobile,
            }"
          >
            <v-carousel height="auto" show-arrows-on-hover hide-delimiters>
              <v-carousel-item
                v-for="(url, index) in educationalFocusVideo"
                :key="index"
              >
                <CampusDetailVideo :media="true" :video="url" />
              </v-carousel-item>
            </v-carousel>
          </div>
        </template>
        <template v-else>
          <div
            class="campus-detail__focus__multimedia--no-video"
            :class="{
              'campus-detail__focus__multimedia--no-video--mobile': isMobile,
            }"
          >
            <img src="@/assets/iconsNewHaven/campusDetail/exclamation-mark.svg" alt="Icono exclamación" />
            <span v-t="'campus-details.education_focus.no-video'" />
          </div>
        </template>
      </div>
    </div>
    <div
      v-if="!educationalFocusVideo.length"
      class="campus-detail__focus__message"
      :class="{ 'campus-detail__focus__message--mobile': isMobile }"
    >
      <span>
        {{ $t('campus-details.education_focus.message-one') }}
        <button
          v-t="'campus-details.education_focus.contact'"
          type="button"
          class="mx-1 font-semibold"
          @click="goToContactUs"
        />
      </span>
    </div>
    <MainActivities
      v-if="partnerships.length > 0"
      class="mt-4"
      :partnerships="partnerships"
    />
  </section>
</template>

<script>
import CampusDetailVideo from '@/components/explorer/general/campus_detail/CampusDetailVideo.vue';
import MainActivities from '@/components/explorer/general/campus_detail/panels/MainActivities.vue';
import utils from '@/utils/';
import trackMixPanel from '@/utils/mixpanel';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CampusDetailEducationalFocus',
  components: {
    CampusDetailVideo,
    MainActivities,
  },
  metaInfo() {
    return {
      meta: [
        ...utils.generateMetaArray([
          { name: 'og:video', content: this.educationalFocusVideo[0], vmid: 'og:video' },
        ]),
      ],
    };
  },
  props: {
    educationalFocusVideo: {
      type: Array,
      default() {
        return [];
      },
    },
    pdf: {
      type: String,
      default: null,
    },
    partnerships: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      campusDetail: 'institutions/campusDetails',
      currentStudent: 'authentication/currentStudent',
    }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapActions({
      setGoToFormContactFrom: 'messaging/setGoToFormContactFrom',
    }),
    downloadPdf() {
      trackMixPanel('click_school_project_download', {
        school_id: this.campusDetail.uuid,
        campus_code: this.campusDetail.campus_code,
        institution_code: this.campusDetail.institution_code,
      });
      window.open(this.pdf);
    },
    goToContactUs() {
      document.getElementById('contact-us').scrollIntoView({ behavior: 'smooth' });
      this.setGoToFormContactFrom({ from: 'educational-proyect' });
    },
  },
};
</script>
