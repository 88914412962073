<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <RowStepper :step="inStudentFlow ? 2 : 1" />
      <div :class="inWaitingList && !mobile ? 'deg-waiting-list--mobile' : ''">
        <!-- Modal Title -->
        <ModalContent
          :content-sections="['smallTitle']"
          :small-title-text="
            inStudentFlow
              ? 'user_register.identification.student_title'
              : 'user_register.identification.title'
          "
          small-title-left
        />
        <!-- Select Nationality -->
        <ModalContent
          :content-sections="['subtitle', 'textSelect']"
          subtitle-text="user_register.basic_info.nationality_placeholder"
          text-select-placeholders="user_register.basic_info.nationality_placeholder"
          :text-select-items="parsedNationalities"
          :text-select-initial-value="nationality"
          :text-select-no-info-error="showNationalityError"
          text-select-return-object
          @setTextSelectValue="setNationality"
        />
        <!-- Upload documents required by nationality -->
        <ModalContent
          :content-sections="
            noId
              ? ['subtitle', 'textSelect']
              : ['subtitle', 'textSelect', 'registerInput', 'fileInput']
          "
          subtitle-text="user_register.basic_info.document_subtitle"
          text-select-placeholders="user_register.basic_info.document_type_placeholder"
          text-select-item-text="identification_type_name"
          :text-select-items="selectedNationalityDocuments"
          :text-select-initial-value="docType"
          :text-select-disabled="nationality === null"
          :text-select-no-info-error="showDocumentTypeError"
          :input-section="documentNumberSection"
          :file-input-section="documentFilesSections"
          text-select-return-object
          @setTextSelectValue="setDocumentType"
          @setValue="setDocumentNumber"
          @setFileValue="setFileValue"
        />
      </div>
    </div>
    <CallToActions
      :types="['main']"
      :active-value="isActive"
      :main-button-text="'user_register.continue'"
      small
      :spaced="false"
      @continue="nextStep()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import { parseName, snakeToCamel, extractAfterLastSlash } from '@/utils/strings';
import { LABELS } from '@/constants/explorer/labels';

export default {
  name: 'ModalNationality',
  components: {
    ModalHeader,
    RowStepper,
    ModalContent,
    CallToActions,
  },
  props: {
    inStudentFlow: {
      type: Boolean,
      default: false,
    },
    preFill: {
      type: Boolean,
      default: false,
    },
    preFillData: {
      type: Object,
      default: () => ({}),
    },
    inWaitingList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Inputs expected from this modal
      nationality: null,
      docType: null,
      docNumber: null,
      docImageFront: null,
      docImageBack: null,
      tenantNationality: {},
      // Auxiliary variables
      missingInfo: false,
      noId: false,
      // Input sections
      documentFilesSections: [
        {
          value: null,
          noInfoError: false,
          placeholder: 'user_register.basic_info.document_file_front',
          fileTypes: 'image/*, application/pdf',
          file: this.preFillData ? extractAfterLastSlash(this.preFillData.identification_link_1) : '',
        },
        {
          value: null,
          noInfoError: false,
          placeholder: 'user_register.basic_info.document_file_back',
          fileTypes: 'image/*, application/pdf',
          file: this.preFillData ? extractAfterLastSlash(this.preFillData.identification_link_2) : '',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      documentTypes: 'options/documentTypes',
      legalGuardianUUID: 'authentication/legalGuardianUUID',
      currentStudent: 'authentication/currentStudent',
      labelsByKey: 'explorer/labelsByKey',
    }),
    nationalities() {
      return this.labelsByKey(LABELS.NATIONALITIES);
    },
    parsedNationalities() {
      const sortedNationalities = this.nationalities
        .filter((nationality) => nationality.nationality_name !== TENANT_CONFIGURATION.NAME.toUpperCase()); // Exclude the tenant nationality

      if (this.tenantNationality) {
        sortedNationalities.unshift(this.tenantNationality);
      }
      return sortedNationalities.map((nationality) => ({
        ...nationality,
        text: parseName(nationality.nationality_name),
      }));
    },
    isActive() {
      return !!(this.nationality && this.docType && this.docNumber) || this.noId;
    },
    showNationalityError() {
      return this.missingInfo && !this.nationality;
    },
    showDocumentTypeError() {
      return this.missingInfo && !this.docType;
    },
    selectedNationalityDocuments() {
      if (this.nationality) {
        return this.documentTypes[this.nationality.id];
      }
      return [];
    },
    documentNumberSection() {
      return [
        {
          value: this.docNumber,
          noInfoError: this.missingInfo && !this.docNumber,
          placeholder: 'user_register.basic_info.document_number_placeholder',
          show: true,
        },
      ];
    },
    uploadedFiles() {
      const dummyImage = {
        data_b64: null,
        filename: null,
        image_number: null,
      };
      const images = [this.docImageFront, this.docImageBack].filter((image) => image !== null);
      if (this.inStudentFlow && images.length === 0) {
        // If the user is in the student flow and has not uploaded any image, we send a dummy image
        return [dummyImage];
      }
      return images;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    preFill: {
      handler(newVal) {
        if (!newVal) {
          this.nationality = null;
          this.nationality = null;
          this.docType = null;
          this.docNumber = null;
          this.docImageFront = null;
          this.docImageBack = null;
        }
      },
    },
    preFillData: {
      handler() {
        if (this.preFill) {
          this.doPreFill();
        }
      },
      deep: true,
    },
  },
  created() {
    this.tenantNationality = this.nationalities.find(
      (nationality) => nationality.nationality_name === TENANT_CONFIGURATION.NAME.toUpperCase(),
    );
    this.tenantNationality.text = parseName(this.tenantNationality.nationality_name);

    if (this.preFill) {
      this.doPreFill();
    }
  },
  methods: {
    ...mapActions({
      setStudentId: 'newStudentRegister/setStudentId',
      setIdImage: 'userRegister/setIdImage',
    }),
    doPreFill() {
      if (this.preFillData && Object.keys(this.preFillData).length > 0) {
        const baseAccessors = ['nationality', 'identification_number', 'identification_type'];
        const [nationalityProp, docNumberProp, docTypeProp] = this.inStudentFlow
          ? baseAccessors
          : baseAccessors.map(snakeToCamel);
        const nationalityObject = this.preFillData[nationalityProp];
        if (nationalityObject) {
          this.nationality = { ...nationalityObject, text: parseName(nationalityObject.nationality_name) }
            ?? this.tenantNationality;
        }
        if (this.nationality === null && this.inWaitingList) {
          this.nationality = this.tenantNationality;
        }
        this.docNumber = this.preFillData[docNumberProp] ?? null;
        if (this.preFillData[docTypeProp]) {
          this.docType = this.preFillData[docTypeProp];
          this.noId = this.docType.identification_type_name === 'Sin documento';
        }
      }

      // TODO: Figure out how we'll handle autofill for images.
      // First idea: show the images to the user with a button to replace them.
      // could be a simple layout:
      // [image] [button]
      // [image] [button]
      // or
      // [image]   [image]
      // [button]  [button]
    },
    uploadStudentId() {
      const idInfo = this.uploadedFiles.map((file) => ({
        ...file,
        uuid: this.currentStudent.uuid,
        nationality: this.nationality.id,
        identification_number: this.noId ? null : this.docNumber,
        identification_type: this.docType.id,
      }));
      this.setStudentId({ idInfo });
    },
    uploadLegalGuardianId() {
      const idInfo = {
        uuid: this.legalGuardianUUID,
        nationality: this.nationality,
        identification_number: this.noId ? null : this.docNumber,
        identification_type: this.docType,
        images: this.uploadedFiles,
      };
      this.setIdImage({ idInfo });
    },
    nextStep() {
      this.missingInfo = false;

      if (
        // No id, we require at least the nationality
        (this.noId && !this.nationality)
        // Selected ID type, require everything
        || (!this.noId && !(this.nationality && this.docType && this.docNumber))
      ) {
        // Toggle errors and stay in this modal
        this.missingInfo = true;
      } else {
        // Post images and go to next step
        if (this.inStudentFlow) {
          this.uploadStudentId();
        } else {
          this.uploadLegalGuardianId();
        }
        this.$emit('nextStep');
      }
    },
    setNationality(_index, value) {
      this.nationality = value;
    },
    setDocumentType(_index, value) {
      this.noId = value.identification_type_name === 'Sin documento';
      this.docType = value;
    },
    setDocumentNumber(value) {
      this.docNumber = value;
    },
    setFileValue(index, fileData) {
      if (index === 0) {
        this.docImageFront = fileData;
      }
      if (index === 1) {
        this.docImageBack = fileData;
      }
    },
  },
};
</script>
