<template>
  <section class="w-full d-flex flex-column">
    <TextAtom
      :value="description"
      class="mt-4 text-left d-flex pl-4 pb-3"
      tag="label"
      font="inter"
      color="primary-dark"
      weight="400"
      size="subtitle"
    />
    <!-- Information team -->
    <div
      v-for="({ name, ocupation }, index) in contact"
      :key="index"
      class="mt-3 campus-detail__leadership"
    >
      <p>{{ ocupation }}</p>
      <p class="font-weight-bold">
        {{ name }}
      </p>
    </div>

    <div class="campus-detail__leadership--contact mt-5">
      <div class="w-full text-left">
        <template v-if="showMessageContact">
          <span v-t="'campus-details.leadership_contact.text'" />
          <button v-t="'campus-details.leadership_contact.link'" type="button" @click="goToContactUs" />
          <span v-t="'campus-details.leadership_contact.text_two'" />
        </template>
        <span v-else v-t="'campus-details.leadership_add_information'" />
      </div>

      <div class="d-flex align-start justify-start">
        <v-btn
          class="ml-10"
          depressed
          icon
          color="white"
          type="button"
          @click="showMessageContact = !showMessageContact"
        >
          <v-icon>
            {{ !showMessageContact ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
          </v-icon>
        </v-btn>
      </div>
    </div>
  </section>
</template>

<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import { mapActions } from 'vuex';

export default {
  name: 'LeadershipPanel',
  components: {
    TextAtom,
  },
  props: {
    contact: {
      type: Array,
      default: () => [],
    },
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showMessageContact: false,
    };
  },
  methods: {
    ...mapActions({
      setGoToFormContactFrom: 'messaging/setGoToFormContactFrom',
    }),
    goToContactUs() {
      document.getElementById('contact-us').scrollIntoView({ behavior: 'smooth' });
      this.setGoToFormContactFrom({ from: 'leadership' });
    },
  },
};
</script>
