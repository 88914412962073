<template>
  <ConfirmationDialog
    v-if="isActive"
    :title="titleKey"
    :loading="loading"
    :subtitle="
      hasSuccessfulAttempt
        ? null : (
          {
            path: subtitleKey,
            args: {
              description: payment.name,
            },
          })"
    :warning="warningKey"
    :info-to-confirm="
      (hasSuccessfulAttempt || paymentFlags.payAtInstitution)
        ? null
        : amountDisplayName(amountShown, payment.currency)"
    :hide-cancel-button="!hasSuccessfulAttempt && !paymentFlags.payAtInstitution"
    :hide-confirm-button="hasSuccessfulAttempt || paymentFlags.payAtInstitution"
    :confirm-button-text="!hasSuccessfulAttempt ? 'payments.confirm.yes' : null"
    :cancel-button-text="paymentFlags.payAtInstitution ? 'payments.confirm.return' : null"
    value
    @confirm="confirm"
    @cancel="cancel"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ConfirmationDialog from '@/components/molecules/dialogs/ConfirmationDialog.vue';
import { amountDisplayName } from '@/utils/currency';

export default {
  name: 'PaymentConfirmationModal',
  components: {
    ConfirmationDialog,
  },
  computed: {
    ...mapGetters({
      activeModal: 'utils/activeModal',
      activeModalData: 'utils/activeModalData',
      activeModalCallbacks: 'utils/activeModalCallbacks',
      currentPayment: 'payments/currentPayment',
    }),
    isActive() {
      return this.activeModal === 'PaymentConfirmation';
    },
    loading() {
      return this.isActive && this.currentPayment?.id !== this.activeModalData.paymentId;
    },
    payment() {
      return this.currentPayment || {};
    },
    paymentFlags() {
      const amountBreakdown = this.payment.amount_breakdown || {};
      const {
        total,
        due,
        next_installment: nextInstallment,
      } = amountBreakdown;
      return {
        payAtInstitution: total === null,
        isInitial: total === due && total !== nextInstallment,
        isDue: total !== due && due === nextInstallment,
        isTotal: total === nextInstallment && total === due,
      };
    },
    amountShown() {
      return this.payment.amount_breakdown?.next_installment || 0;
    },
    hasSuccessfulAttempt() {
      return this.activeModalData?.hasSuccessfulAttempt || false;
    },
    titleKey() {
      if (this.paymentFlags.payAtInstitution) {
        return 'payments.confirm.title.institution';
      }
      return 'payments.confirm.title.default';
    },
    subtitleKey() {
      if (this.paymentFlags.payAtInstitution) {
        return 'payments.confirm.subtitle.institution';
      }
      if (this.paymentFlags.isInitial) {
        return 'payments.confirm.subtitle.partial';
      }
      if (this.paymentFlags.isDue) {
        return 'payments.confirm.subtitle.due';
      }
      return 'payments.confirm.subtitle.total';
    },
    warningKey() {
      if (this.hasSuccessfulAttempt) {
        return 'payments.confirm.warning.pending_payment';
      }
      if (this.paymentFlags.isInitial) {
        return this.$t('payments.confirm.warning.initial_payment', {
          amount: amountDisplayName(this.payment.amount, this.payment.currency),
        });
      }
      return null;
    },
  },
  watch: {
    activeModalData: {
      handler() {
        if (this.loading) {
          this.getPayment({ id: this.activeModalData?.paymentId });
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getPayment({ id: this.activeModalData?.paymentId });
  },
  methods: {
    ...mapActions({
      deactivateModal: 'utils/deactivateModal',
      getPayment: 'payments/getPayment',
    }),
    confirm() {
      if (this.activeModalCallbacks.onConfirm) this.activeModalCallbacks.onConfirm();
      this.deactivateModal();
    },
    cancel() {
      if (this.activeModalCallbacks.onCancel) this.activeModalCallbacks.onCancel();
      this.deactivateModal();
    },
    amountDisplayName,
  },
};
</script>
