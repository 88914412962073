// Init rect steps https://dev.to/sahilkashyap64/google-identity-servicegoogle-auth-2022-in-react-node-27lh
// FOR VUE 3 VERSION USE https://github.com/yobaji/vue3-google-login/

// Scopes https://developers.google.com/identity/protocols/oauth2/scopes?hl=es-419
import CONFIG from '@/config';

export const SignUpUser = async () => {
  /* FOR ACCESS TOKEN AND AUTHORIZATION METHODS
  const SCOPES = [
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/userinfo.email',
  ].join(' ');
  const client = window.google.accounts.oauth2.initTokenClient({
    client_id: CONFIG.googleClientId,
    scope: SCOPES,
    callback: '', // defined at request time
  });
  const tokenResponse = await new Promise((resolve, reject) => {
    try {
      // Settle this promise in the response callback for requestAccessToken()
      client.callback = (resp) => {
        if (resp.error !== undefined) {
          reject(resp);
        }
        // console.log("client resp",resp);
        resolve(resp);
      };
      // console.log("client",client);
      client.requestAccessToken({ prompt: 'consent' });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  });
  */

  const client = window.google.accounts.id.initialize({
    client_id: CONFIG.googleClientId,
    callback: '',
  });
  window.google.accounts.id.prompt();
  const tokenResponse = await new Promise((resolve, reject) => {
    try {
      // await callback for return response
      client.callback = (resp) => {
        if (resp.error !== undefined) {
          reject(resp);
        }
        resolve(resp);
      };
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  });
  return tokenResponse;
};
