<template>
  <div
    class="waiting-chip clickable"
    :class="open ? 'waiting-chip--vacancies' : 'waiting-chip--waiting'"
    @click="$emit('next')"
    @keydown="$emit('next')"
  >
    <TextAtom
      :value="title"
      :color="open ? 'secondary-light' : 'primary-dark'"
      font="inter"
      weight="500"
    />
    <ImageAtom
      class="clickable waiting-chip--arrow"
      :source="open ? require('@/assets/icons/next-white.svg') : require('@/assets/icons/next-blue.svg')"
      :alt-text="'next'"
    />
  </div>
</template>

<script>
import ImageAtom from '@/components/atoms/images/Image.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';

export default {
  name: 'WaitingChip',
  components: {
    ImageAtom,
    TextAtom,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: '',
    };
  },
  mounted() {
    this.title = this.open
      ? this.$t('digital_enrollment.waiting-list.vacancies.title')
      : this.$t('digital_enrollment.waiting-list.vacancies.waiting');
  },
};
</script>
