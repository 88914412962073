<template>
  <div class="deg-waiting-list--container">
    <ImageAtom
      class="deg-waiting-list--line clickable"
      :source="require('@/assets/icons/back-blue.svg')"
      :alt-text="'back'"
      @click="$emit('back')"
      @keydown="$emit('back')"
    />
    <MainTitle
      class="mt-8 deg-waiting-list--title"
      :text="'digital_enrollment.waiting-list.list.title'"
      left-align
      secondary
    />
    <ImageAtom
      class="deg-waiting-list--line"
      :source="require('@/assets/waitingList/dge-line.svg')"
      :alt-text="'dge-line'"
    />
    <SmallTitle
      class="deg-waiting-list--campus-name mb-10"
      :text="campusDetail.campus_name"
      center-left
    />
    <div :class="!mobile ? 'deg-waiting-list--list' : ''">
      <div
        v-if="showCurrentStudent"
        class="deg-waiting-list--confirm__purple mb-14 ml-5 mr-5"
      >
        <TextAtom
          :value="studentName"
          font="inter"
          class="deg-waiting-list--confirm__name"
        />
        <p
          v-t="{
            path: 'digital_enrollment.waiting-list.confirmation.position',
            args: { pos: position },
          }"
          class="deg-waiting-list--confirm__position"
        />
        <TextAtom
          :value="$t('digital_enrollment.waiting-list.confirmation.in-list')"
          size="subtitle"
        />
      </div>
      <div v-if="loading">
        <v-skeleton-loader
          max-width="auto"
          max-height="220"
          type="heading"
          class="mb-5"
        />
      </div>
      <div
        v-else
        :class="!mobile ? 'deg-waiting-list--list__name' : ''"
      >
        <TextAtom
          :value="shiftVacancy.grade"
          class="deg-waiting-list--grade-title"
        />
        <TextAtom
          :value="shiftVacancy.gradeName + ' - ' + shiftVacancy.name"
          weight="500"
          size="subtitle"
          class="deg-waiting-list--stage mb-8"
        />
      </div>
      <div v-if="loading">
        <v-skeleton-loader
          max-width="auto"
          max-height="220"
          type="list-item-avatar-two-line"
          class="mb-5"
        />
        <v-skeleton-loader
          max-width="auto"
          max-height="220"
          type="list-item-avatar-two-line"
        />
      </div>
      <div v-else-if="students.length > 0">
        <div v-for="(student, index) in students" :key="index">
          <div
            class="deg-waiting-list--student flex"
            :class="student.ranking === position ? 'deg-waiting-list--student__pos' : ''"
          >
            <TextAtom
              :value="student.ranking + '.'"
              class="deg-waiting-list--student__ranking"
              :class="student.ranking === position ? 'deg-waiting-list--student__white' : ''"
              size="big"
              font="inter"
              weight="500"
            />
            <div class="deg-waiting-list--student__info">
              <TextAtom
                v-if="waitingListType === 'PUBLIC'"
                class="deg-waiting-list--student__name"
                :class="student.ranking === position ? 'deg-waiting-list--student__white' : ''"
                :value="student.name"
                font="inter"
                color="primary-dark"
                weight="500"
              />
              <TextAtom
                v-if="waitingListType !== 'PUBLIC'"
                class="deg-waiting-list--student__name"
                :class="student.ranking === position ? 'deg-waiting-list--student__white' : ''"
                :value="'-'"
                font="inter"
                color="primary-dark"
                weight="500"
              />
              <TextAtom
                :value="student.date"
                class="deg-waiting-list--student__date mt-1"
                :class="student.ranking === position ? 'deg-waiting-list--student__white' : ''"
                font="inter"
                color="neutral-800"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showCurrentStudent"
      class="deg-waiting-list--footer__information mt-4 clickable"
      @click="copyLink()"
      @keydown="copyLink()"
    >
      <TextAtom
        :value="$t('digital_enrollment.waiting-list.list.copy-link')"
        class="deg-waiting-list--footer__information__text"
        weight="500"
      />
      <MdiIcon
        :icon="copyIcon"
        style="margin-left: 12px;"
        color="#5627FF"
        class="mb-5"
      />
    </div>
    <div class="deg-waiting-list--footer">
      <CallToActions
        class="mb-3"
        :class="!mobile ? 'deg-waiting-list--list__button' : 'deg-waiting-list--footer__button'"
        :option-text1="showCurrentStudent
          ? 'digital_enrollment.waiting-list.list.button-profile' : 'digital_enrollment.waiting-list.list.button'"
        :types="['option']"
        :spaced="false"
        @nextStep="showCurrentStudent ? $emit('close') : $emit('next', programId)"
      />
      <TextAtom
        v-if="!showCurrentStudent"
        :value="$t('digital_enrollment.waiting-list.list.information')"
        class="deg-waiting-list--footer__information mb-5"
        font="inter"
      />
    </div>
  </div>
</template>

<script>
import MdiIcon from '@/components/atoms/icons/MdiIcon.vue';
import ImageAtom from '@/components/atoms/images/Image.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import MainTitle from '@/components/atoms/titles/MainTitle.vue';
import SmallTitle from '@/components/atoms/titles/SmallTitle.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import CONFIG from '@/config';
import publicRecordsService from '@/services/publicRecords.service';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'WaitingList',
  components: {
    ImageAtom,
    MainTitle,
    SmallTitle,
    TextAtom,
    CallToActions,
    MdiIcon,
  },
  props: {
    programId: {
      type: [String, Number],
      required: true,
    },
    showCurrentStudent: {
      type: Boolean,
      default: false,
    },
    position: {
      type: Number,
      default: 0,
    },
    shiftVacancy: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      students: [],
      studentName: '',
      loading: false,
      copyIcon: 'mdi-content-copy',
      shift: {},
      isInitialWaitingListCalled: false,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'institutions/campusDetails',
      currentStudent: 'authentication/currentStudent',
    }),
    waitingListType() {
      return this.campusDetail?.config_campus[0].waiting_list_type;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    programId: {
      immediate: true,
      handler() {
        this.handleRouteChange();
      },
    },
    'campusDetail.campus_code': {
      immediate: true,
      handler() {
        this.handleRouteChange();
      },
    },
  },
  mounted() {
    this.studentName = this.currentStudent.first_name;
    this.loading = true;
  },
  methods: {
    ...mapActions({
      setShiftWaitingList: 'institutions/setShiftWaitingList',
    }),
    handleRouteChange() {
      if (this.programId && this.campusDetail?.campus_code && !this.isInitialWaitingListCalled) {
        this.isInitialWaitingListCalled = true;
        this.getInitialWaitingList();
      }
    },
    getInitialWaitingList() {
      publicRecordsService.getWaitingList({
        tenant: CONFIG.tenant,
        campusCode: this.campusDetail.campus_code,
        programId: Number(this.programId),
      }).then((response) => {
        const responseData = response.data;
        if (responseData.length > 0) {
          responseData.forEach((student, index) => {
            this.students.push({
              ranking: index + 1,
              name: student.applicant.name,
              date: this.getDateString(student.createdAt),
            });
          });
          const responseFirst = responseData[0];
          this.shift = {
            name: responseFirst.course.shiftName,
            grade: responseFirst.course.levelTrackName,
            gradeName: responseFirst.course.levelTrackName,
            id: responseFirst.course.shiftId,
            gradeLabelId: responseFirst.course.levelTrackId,
          };
          this.setShiftWaitingList({ shift: this.shift });
          this.loading = false;
        } else if (JSON.stringify(this.shiftVacancy) !== '{}' && responseData.length === 0) {
          this.setShiftWaitingList({ shift: this.shiftVacancy });
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    copyLink() {
      navigator.clipboard.writeText(window.location.href);
    },
    // FIXME: This method should be a pipe or in utils or in another file to be reusable
    getDateString(date) {
      const dateObject = new Date(date);
      const isSpanish = this.$i18n.locale === 'es';
      const daysOfWeek = isSpanish ? ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'] : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const dayOfWeek = daysOfWeek[dateObject.getDay()];
      const day = dateObject.getDate();
      const months = isSpanish ? ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'] : ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      const month = months[dateObject.getMonth()];
      const hours = dateObject.getHours();
      const minutes = dateObject.getMinutes();
      const hourString = hours < 10 ? `0${hours}` : `${hours}`;
      const minuteString = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const timeString = isSpanish ? `${hourString}.${minuteString}hrs` : `${hourString}.${minuteString}hrs`;
      return `${dayOfWeek} ${day} de ${month} ${timeString}`;
    },
  },
};
</script>
