<template>
  <div class="campus-detail__photos">
    <v-carousel hide-delimiters>
      <v-carousel-item
        v-for="({ image_link, image_description, image_name }, index) in filterPhotos"
        :key="index"
        height="100px"
      >
        <div
          class="fill-height"
          style="max-width: 800px"
          @click="trackImageSliderClick()"
          @keydown="trackImageSliderClick()"
        >
          <ImageAtom
            class="fill-height"
            :src="image_link"
            :alt="altImage(image_description || image_name)"
            height="100%"
            width="100%"
            is-responsive
            object-fit="contain"
          />
        </div>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import trackMixPanel from '@/utils/mixpanel';
import ImageAtom from '@/components/atoms/images/ImageAtom.vue';

export default {
  name: 'CampusDetailPhotos',
  components: {
    ImageAtom,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  metaInfo() {
    return {
      meta: [],
    };
  },
  computed: {
    ...mapGetters({
      campusDetails: 'institutions/campusDetails',
    }),
    filterPhotos() {
      let images = [...this.images];
      images = images.filter(
        (image) => !(this.images.length > 1 && image.image_name === 'Thumbnail' && image.image_name === 'main'),
      );
      return images;
    },
    altImageDefault() {
      return this.campusDetails?.campus_name;
    },
  },
  methods: {
    altImage(description) {
      const separator = description ? ' - ' : '';
      return `${this.altImageDefault} ${separator} ${description || ''}`;
    },
    trackImageSliderClick() {
      trackMixPanel('sp_school_photo', {
        school_id: this.campusDetails.uuid,
        campus_code: this.campusDetails.campus_code,
        institution_code: this.campusDetails.institution_code,
        name: this.campusDetails.campus_name,
      });
    },
  },
};
</script>
