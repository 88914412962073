var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-main register-main--info-box-wrap",class:{
    'register-main--info-box-wrap--border': _vm.border,
    'register-main--info-box-wrap--fillWidth': _vm.fillWidth,
    'ma-0': _vm.noMargin,
  },on:{"click":function($event){return _vm.infoBoxClick()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.infoBoxClick()}}},[_c('section',{staticClass:"register-main--info-box-wrap--info-box",class:{
      'register-main--info-box-wrap--info-box--left': _vm.alignLeft,
      'register-main--info-box-wrap--info-box--with-image': _vm.imageSource,
      'register-main--info-box-wrap--info-box--gray-background': _vm.grayBackground,
      'ma-0': _vm.noMargin,
      'p-5': _vm.infoPadding,
      'p-3': _vm.infoPaddingSmall,
    }},[_c('section',{staticClass:"d-flex"},[(_vm.icon)?_c('img',{staticStyle:{"margin-right":"12px"},attrs:{"width":_vm.smallIcon ? '14' : '28',"src":require('@/assets/icons/' + _vm.icon),"alt":"infoBox"}}):_vm._e(),_c('div',[_c('p',{directives:[{name:"t",rawName:"v-t",value:(_vm.text),expression:"text"}],staticClass:"register-main--info-box-wrap--info-box--box-text",class:{
            'register-main--info-box-wrap--info-box--box-text--center-header': _vm.centerHeader,
            'register-main--info-box-wrap--info-box--box-text--small': _vm.smallIcon,
            'register-main--info-box-wrap--info-box--box-text--thin': _vm.thinLine,
          }}),_c('p',{directives:[{name:"t",rawName:"v-t",value:(_vm.subText),expression:"subText"}],staticClass:"register-main--info-box-wrap--info-box--box-text__subtitle",class:{
            'register-main--info-box-wrap--info-box--box-text--center-header': _vm.centerHeader,
            'register-main--info-box-wrap--info-box--box-text--small': _vm.smallIcon,
            'register-main--info-box-wrap--info-box--box-text--thin': _vm.thinLine,
          }})]),(_vm.imageSource)?_c('img',{staticStyle:{"width":"60%","margin":"0 auto"},attrs:{"src":_vm.imageSource,"alt":"Institution Logo"}}):_vm._e()]),(_vm.infoArray.length > 0)?_c('section',{staticStyle:{"margin":"10px 0 0 12px"}},_vm._l((_vm.infoArray),function(info,index){return _c('div',{key:index,staticClass:"d-flex",staticStyle:{"margin-top":"16px"}},[(info.content)?_c('p',{directives:[{name:"t",rawName:"v-t",value:(info.title),expression:"info.title"}],staticClass:"register-main--info-box-wrap--info-box--array-box-text"}):_vm._e(),(info.content)?_c('p',{directives:[{name:"t",rawName:"v-t",value:(info.content),expression:"info.content"}],staticClass:"register-main--info-box-wrap--info-box--array-box-text register-main--info-box-wrap--info-box--array-box-text--content"}):_vm._e()])}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }