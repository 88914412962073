<template>
  <section class="d-flex justify-center align-center fill-height">
    <div
      class="headmaster_register_container fill-width"
    >
      <PasswordRecovery v-if="inStep(-1)" @toRegister="toStep(2)" @backStep="backStep" />
      <!-- TODO: Link Accounts -->
      <LoginModal
        v-if="inStep(0)"
        @backStep="backStep"
        @toRecovery="toStep(-1)"
        @toRegister="toStep(2)"
        @toVerification="toStep(3)"
        @skipVerification="toStep(5)"
        @toAccountLinking="linkAccountMessage"
      />
      <ApplicationWelcome
        v-if="inStep(1)"
        :partner-id="partnerId"
        @nextStep="nextStep"
        @show-login-register="toStep(0)"
      />
      <RegisterData
        v-if="inStep(2)"
        @nextStep="nextStep"
        @backStep="backStep"
        @setLocationModal="toStep(3)"
        @toResetPass="toStep(-1)"
        @skipVerification="toStep(5)"
        @toAccountLinking="linkAccountMessage"
      />
      <RegisterCode v-if="inStep(3)" @nextStep="nextStep" @backStep="backStep" />
      <StepsModal
        v-if="inStep(4)"
        :in-student-flow="inStudentFlow"
        :register-type="registerType"
        @nextStep="nextStep"
        @backStep="backStep"
      />
      <Identification
        v-if="inStep(5)"
        :pre-fill="inDataValidationFlow"
        :pre-fill-data="currentUserToEdit"
        :in-student-flow="inStudentFlow"
        :is-new-student="inStudentFlow && Object.keys(currentStudent).length > 0"
        in-detailed-register
        @nextStep="identificationNextStep()"
        @backStep="backStep"
      />
      <LegalGuardianStepModal
        v-if="inStep(6)"
        :register-type="registerType"
        @nextStep="toStep(5)"
        @backStep="backStep"
        @inStudentFlow="inStudentFlow = true"
      />
      <RelationshipModal
        v-if="inStep(7)"
        :pre-fill="inDataValidationFlow"
        :pre-fill-data="currentUserToEdit"
        :register-type="registerType"
        @backStep="backStep"
        @nextStep="yearToApply ? yearFollowUp() : relationshipFollowUp()"
      />
      <ApplicationLoggedWelcome
        v-if="inStep(10)"
        @nextStep="loggedWelcomeNexStep()"
        @backStep="backStep"
        @addStudent="addStudent()"
        @toStudentId="toStudentId()"
        @toStudentSelection="toStep(16)"
      />
      <GradeSuggestion
        v-if="inStep(11)"
        @nextStep="nextStep"
        @backStep="backStep"
        @toGradeSelection="toStep(14)"
      />
      <AdditionalInfoYear v-if="inStep(12)" @nextStep="yearFollowUp()" @backStep="backStep" />
      <ShiftPriority
        v-if="inStep(13)"
        use-defaults
        @nextStep="(args) => toStep(registeredUser ? 15 : 10, args)"
        @backStep="backStep"
      />
      <SelectGrades
        v-if="inStep(14)"
        :in-student-flow="inStudentFlow"
        user-register
        @nextStep="toStep(13)"
        @backStep="backStep"
      />
      <StudentsFinal
        v-if="inStep(15)"
        :register-type="2"
        hide-new-student-button
        @nextStep="toDge()"
        @backStep="backStep"
      />
      <StudentSelection
        v-if="inStep(16)"
        @nextStep="studentSelectionFollowUp()"
        @toNewStudentRegister="studentSelectionFollowUp(true)"
        @backStep="backStep"
      />
      <SvgLogo
        v-if="currentStep > 4 && currentStep < 15"
        class="clickable"
        :logo="'help-icon.svg'"
        left
        no-margin
        alt="help icon"
        @logoClick="helpModal = true"
      />
      <v-dialog v-model="helpModal" max-width="350">
        <HelpInformation :class="mobile ? '' : 'help-information--dge' " @close="helpModal = false" />
      </v-dialog>
    </div>
  </section>
</template>

<script>
import AdditionalInfoYear from '@/components/organisms/register/AdditionalInfoYear.vue';
import ApplicationLoggedWelcome from '@/components/organisms/register/ApplicationLoggedWelcome.vue';
import ApplicationWelcome from '@/components/organisms/register/ApplicationWelcome.vue';
import GradeSuggestion from '@/components/organisms/register/GradeSuggestion.vue';
import HelpInformation from '@/components/organisms/digital_enrollment/HelpInformation.vue';
import Identification from '@/components/organisms/register/Identification.vue';
import LegalGuardianStepModal from '@/components/organisms/register/LegalGuardianStepModal.vue';
import RegisterCode from '@/components/organisms/register/RegisterCode.vue';
import RegisterData from '@/components/organisms/register/RegisterData.vue';
import RelationshipModal from '@/components/organisms/register/RelationshipModal.vue';
import SelectGrades from '@/components/shared/SelectGrades.vue';
import ShiftPriority from '@/components/organisms/register/ShiftPriority.vue';
import StepsModal from '@/components/organisms/register/StepsModal.vue';
import StudentSelection from '@/components/organisms/register/StudentSelection.vue';
import StudentsFinal from '@/components/organisms/register/StudentsFinal.vue';
import SvgLogo from '@/components/atoms/logos/SvgLogo.vue';
import { redirectToDGE } from '@/utils/redirects';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ApplicationDgeRegister',
  components: {
    AdditionalInfoYear,
    ApplicationLoggedWelcome,
    ApplicationWelcome,
    RegisterData,
    RegisterCode,
    StepsModal,
    Identification,
    LegalGuardianStepModal,
    RelationshipModal,
    StudentsFinal,
    GradeSuggestion,
    SelectGrades,
    ShiftPriority,
    StudentSelection,
    SvgLogo,
    HelpInformation,
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
    partnerId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      stepStack: [],
      registerType: 1,
      inDataValidationFlow: false,
      inStudentFlow: null,
      registeredUser: false,
      helpModal: false,
    };
  },
  computed: {
    ...mapGetters({
      studentList: 'authentication/students',
      currentStudent: 'authentication/currentStudent',
      legalGuardian: 'authentication/legalGuardian',
      campusDetails: 'institutions/campusDetails',
      isGuest: 'authentication/isGuest',
      authDone: 'authentication/authDone',
      yearToApply: 'digitalEnrollment/yearToApply',
    }),
    currentStep() {
      return this.stepStack[this.stepStack.length - 1];
    },
    currentUserToEdit() {
      return this.inStudentFlow ? this.currentStudent : this.legalGuardian;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    step: {
      handler(newVal) {
        this.restartProcess(newVal);
      },
    },
    authDone: {
      handler(newVal) {
        if (newVal && this.inStep(2)) {
          this.preCheckNextStep();
        }
      },
    },
  },
  mounted() {
    if (this.isGuest) {
      this.restartProcess();
    } else {
      this.registeredUser = true;
      if (this.legalGuardian?.firstLastname) {
        this.restartProcess(10);
      } else {
        this.inDataValidationFlow = true;
        this.restartProcess(5);
      }
    }
  },
  methods: {
    ...mapActions({
      retrieveDigitalEnrollmentTenantOptions: 'digitalEnrollment/retrieveDigitalEnrollmentTenantOptions',
      setCurrentStudent: 'authentication/setCurrentStudent',
      setLoginModal: 'authentication/setLoginModal',
      getGradeRecommendation: 'institutions/getGradeRecommendation',
      notifyError: 'utils/error',
    }),
    nextStep() {
      const nextStep = this.currentStep + 1;
      this.stepStack.push(nextStep);
    },
    backStep() {
      this.stepStack.pop();
    },
    inStep(...steps) {
      return steps.includes(this.currentStep);
    },
    restartProcess(customStep) {
      const newStep = customStep !== undefined ? customStep : this.step;
      this.stepStack = [newStep];
    },
    relationshipFollowUp() {
      this.retrieveDigitalEnrollmentTenantOptions({ tenantId: this.campusDetails.campus_code });
      return this.toStep(13);
    },
    toDge() {
      this.setLoginModal({ modal: null });
      redirectToDGE({
        path: '/new-admission',
        query: {
          tenantId: this.campusDetails.campus_code,
          applicantId: this.currentStudent.uuid,
        },
        newTab: false,
      });
    },
    identificationNextStep() {
      if (this.inStudentFlow) {
        return this.toStep(7);
      }
      return this.toStep(10);
    },
    yearFollowUp() {
      this.retrieveDigitalEnrollmentTenantOptions({ tenantId: this.campusDetails.campus_code });
      return this.toStep(14);
    },
    loggedWelcomeNexStep() {
      if (this.registeredUser) {
        return this.toStep(13);
      }
      return this.toDge();
    },
    toStep(step, { skip = false } = {}) {
      if (skip) {
        this.stepStack.pop();
      }
      this.stepStack.push(step);
    },
    addStudent() {
      this.inStudentFlow = true;
      return this.toStep(5);
    },
    toStudentId() {
      this.inStudentFlow = true;
      this.inDataValidationFlow = true;
      this.setCurrentStudent({ student: this.studentList[0] })
        .then(() => this.toStep(5));
    },
    studentSelectionFollowUp(newStudent) {
      this.retrieveDigitalEnrollmentTenantOptions({ tenantId: this.campusDetails.campus_code });
      if (newStudent) {
        this.inStudentFlow = true;
        this.toStep(5);
      } else if (this.currentStudent.first_lastname === null) {
        this.inStudentFlow = true;
        this.toStep(5);
      } else {
        return this.toStep(13);
      }
      this.inDataValidationFlow = true;
      return this.toStep(5);
    },

    preCheckNextStep() {
      this.registeredUser = true;
      if (this.studentList.length > 0) {
        this.inStudentFlow = true;
        this.setCurrentStudent({ student: this.studentList[0] }).then(() => {
          const monthOfBirth = this.studentList[0].birth_month;
          const yearOfBirth = this.studentList[0].birth_year;
          if (yearOfBirth && monthOfBirth) {
            const birthDate = `${yearOfBirth.toString()}-${monthOfBirth.toString()}`;
            this.getGradeRecommendation({ birthDate });
            return this.toStep(10);
          }
          return this.toStep(8);
        });
      }
      if (this.legalGuardian.firstName) {
        return this.toStep(6);
      }
      return this.toStep(5);
    },
    linkAccountMessage() {
      // TODO: Eventually implement account linking here

      this.notifyError('errors.login.precheck');
    },
  },
};
</script>
