<template>
  <div>
    <SelectFacebookPageModal />
  </div>
</template>

<script>
import SelectFacebookPageModal from '@/components/dashboard/modals/SelectFacebookPageModal.vue';

export default {
  name: 'SelectFacebookPageModals',
  components: {
    SelectFacebookPageModal,
  },
};

</script>
