<template>
  <div class="register-main">
    <p
      v-t="setText"
      class="register-main--small-title"
      :class="{
        'register-main--small-title--left': centerLeft,
        'register-main--small-title--primary': primary,
        'ma-0': noMargin,
      }"
      :style="'margin-top: 16px'"
    />
  </div>
</template>

<script>
export default {
  name: 'SmallTitle',
  components: {},
  props: {
    centerLeft: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    args: {
      type: [String, Object],
      default: '',
    },
    primary: {
      type: Boolean,
      default: false,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    setText() {
      if (this.args) {
        const args = typeof this.args === 'object' ? this.args : { args: this.args };
        const textObj = {
          path: this.text,
          args,
        };
        return textObj;
      }
      return this.text;
    },
  },
};
</script>
