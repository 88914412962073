import moment from 'moment';
import router from '@/router';
import paymentsService from '@/services/payments.service';
import { uniqueArrayObjects } from '@/utils/arrays';
import { camelToSnake, snakeToCamel } from '@/utils/objects';

const getDefaultState = () => ({
  // list of available payment methods
  payments: [],
  // States reflecting options available
  providers: [],
  methods: [],
  // Customer Initialization states
  initializingCustomer: false,
  customerInitialization: null,
  // The following states reflect states of the payment
  currentPayment: null,
  currentProvider: null,
  currentMethod: null,
  activePaymentFlow: null,
  // Linked bank account
  bankAccount: null,
  // Loading states
  loading: false,
  // Route to redirect to after payment is completed
  redirectRoute: null,
});

const state = {
  ...getDefaultState(),
};

const mutations = {
  resetStore(state) {
    Object.assign(state, getDefaultState());
  },
  setPaymentsList(state, { payments }) {
    state.payments = payments;
  },
  setProviders(state, { providers }) {
    state.providers = providers;
  },
  setPaymentMethods(state, { paymentMethods }) {
    state.methods = paymentMethods;
  },
  setCurrentPayment(state, { payment }) {
    state.currentPayment = payment;
  },
  setCurrentMethod(state, { method }) {
    state.currentMethod = method;
  },
  setCurrentProvider(state, { provider }) {
    state.currentProvider = provider;
  },
  setActivePaymentFlow(state, { flow }) {
    state.activePaymentFlow = flow;
  },
  setBankAccount(state, { bankAccount }) {
    state.bankAccount = bankAccount;
  },
  setLoading(state, { loading }) {
    state.loading = loading;
  },
  setRedirectRoute(state, { route }) {
    state.redirectRoute = route;
  },
  setCustomerInitialization(state, { customerInitialization }) {
    state.customerInitialization = customerInitialization;
  },
  setInitializingCustomer(state, { initializingCustomer }) {
    state.initializingCustomer = initializingCustomer;
  },
};

const getters = {
  providers: ({ providers }) => providers,
  payments: ({ payments }) => payments,
  methods: ({ methods }) => methods,
  currentPayment: ({ currentPayment }) => currentPayment,
  currentMethod: ({ currentMethod }) => currentMethod,
  currentProvider: ({ currentProvider }) => currentProvider,
  bankAccount: ({ bankAccount }) => bankAccount,
  activePaymentFlow: ({ activePaymentFlow }) => activePaymentFlow,
  loading: ({ loading }) => loading,
  redirectRoute: ({ redirectRoute }) => redirectRoute,
  initializingCustomer: ({ initializingCustomer }) => initializingCustomer,
  customerInitializationResult: ({ customerInitialization }) => customerInitialization,
  successfulCheckout: ({ currentProvider, initializingCustomer, customerInitialization }) => {
    if (!currentProvider) {
      return false;
    }
    const { requires_customer_init: forceCustomerInitialization } = currentProvider || {};
    if (forceCustomerInitialization) {
      if (initializingCustomer || !customerInitialization) {
        return false;
      }
      const { initialized, valid, validate } = customerInitialization || {};
      return initialized && valid && !validate;
    }
    return true;
  },
};

const actions = {
  async resetStore({ commit }) {
    commit('resetStore');
  },

  setLoading({ commit }, { loading }) {
    commit('setLoading', { loading });
  },

  async resetPaymentFlow({ commit }, { keepPayment = false } = {}) {
    if (!keepPayment) {
      commit('setCurrentPayment', { payment: null });
    }
    commit('setCurrentMethod', { method: null });
    commit('setCurrentProvider', { provider: null });
    commit('setActivePaymentFlow', { flow: null });
    commit('setCustomerInitialization', { customerInitialization: null });
  },

  async getPayments({ commit }) {
    commit('setLoading', { loading: true });
    // Fetches payments of the current user (through token header)
    const { data } = await paymentsService.getPayments();
    commit('setPaymentsList', { payments: data });
    commit('setLoading', { loading: false });
    return data;
  },

  async getPayment({ state, commit }, { id, useCached = true }) {
    commit('setLoading', { loading: true });
    if (id === state.currentPayment?.id && useCached) {
      commit('setLoading', { loading: false });
      return state.currentPayment;
    }
    if (id) {
      const { data: payment } = await paymentsService.getPayment({ id });
      commit('setCurrentPayment', { payment });
      commit('setLoading', { loading: false });
      return payment;
    }
    commit('setCurrentPayment', { payment: null });
    commit('setLoading', { loading: false });
    return null;
  },

  async getProvider({ commit }, { providerCode }) {
    paymentsService.getProvider({ providerCode }).then(
      ({ data: provider }) => {
        commit('setCurrentProvider', { provider });
      },
    );
  },

  async initializeCustomer({ commit, state }, { updateBefore = false, updateData = null, precheck = false } = {}) {
    commit('setInitializingCustomer', { initializingCustomer: true });
    if (updateBefore && updateData) {
      const snakeData = camelToSnake(updateData);
      await paymentsService.updateCustomer({ customerData: snakeData });
    }
    const providerCode = state.currentProvider.provider_code;
    const { data: customerInitialization } = await paymentsService.initializeCustomer({ providerCode, precheck });
    commit('setCustomerInitialization', {
      customerInitialization: {
        ...customerInitialization,
        customer: snakeToCamel(customerInitialization.customer),
      },
    });
    commit('setInitializingCustomer', { initializingCustomer: false });
    return customerInitialization;
  },

  async getAvailablePaymentMethods({ commit }, { entity }) {
    commit('setLoading', { loading: true });
    commit('setCurrentMethod', { method: null });
    commit('setCurrentProvider', { provider: null });
    // Only get the ones that are available for the payments entity
    const { data: paymentMethods } = await paymentsService.getAvailablePaymentMethods({ entity });
    const providers = uniqueArrayObjects(paymentMethods.flatMap((method) => method.providers));
    commit('setPaymentMethods', { paymentMethods });
    commit('setProviders', { providers });
    commit('setLoading', { loading: false });
    return paymentMethods;
  },

  async setRedirectRoute({ commit }, { route }) {
    // We only override the redirect route if it's not a payment route or null
    const re = /Payments?/g;
    if (route === null) {
      commit('setRedirectRoute', { route });
    } else if (route.name && !route.name.match(re)) {
      // the matched property creates a circular reference, so we remove it (it's not needed anyway)
      const { matched, ...nonCircularFields } = route; // eslint-disable-line no-unused-vars
      commit('setRedirectRoute', { route: nonCircularFields });
    }
  },

  async setCurrentPayment({ commit }, { payment }) {
    commit('setCurrentPayment', { payment });
  },

  async setCurrentMethod({ commit, dispatch }, { method }) {
    await commit('setCurrentProvider', { provider: null });
    if (method && method.providers.length === 1) {
      const [provider] = method.providers;
      dispatch('setCurrentProvider', { provider });
    } else {
      dispatch('setCurrentProvider', { provider: null });
    }
    await commit('setCurrentMethod', { method });
  },
  async setCurrentProvider({ commit, dispatch }, { provider }) {
    commit('setCurrentProvider', { provider });
    if (provider) {
      dispatch('initializeCustomer', { precheck: true });
    }
  },
  async setBankAccount({ commit }, { bankAccount }) {
    commit('setBankAccount', { bankAccount });
  },

  async getBankAccount({ commit }, { entityCode }) {
    paymentsService.getBankAccount({ entityCode }).then(
      ({ data: bankAccount }) => {
        commit('setBankAccount', { bankAccount });
      },
    ).catch(() => {
      commit('setBankAccount', { bankAccount: null });
    });
  },

  async initializePaymentFlow({ dispatch, commit, state }, { payload = {} } = {}) {
    commit('setLoading', { loading: true });
    const {
      currentMethod: method,
      currentProvider: provider,
      currentPayment: payment,
      redirectRoute,
    } = state;

    const buildCallbackURL = (status) => {
      const isLocalhost = window.location.hostname === 'localhost';
      const host = isLocalhost ? 'https://chile-staging.chile.explorador.com/' : window.location.origin;
      if (redirectRoute && redirectRoute.name?.match(/DigitalEnrollmentApplication?/g)) {
        const route = router.resolve({ name: redirectRoute.name, params: { id: redirectRoute.params.id, status } });
        return new URL(route.href, host).toString();
      }
      // FIXME: Belvo API doesn't support localhost as a callback URL
      const route = router.resolve({ name: 'Payment', params: { id: payment.id, status } });
      return new URL(route.href, host).toString();
    };

    const callbacks = {
      success: buildCallbackURL('payment-success'),
      cancel: buildCallbackURL('payment-error'),
      error: buildCallbackURL('payment-error'),
    };

    const fullPayload = {
      method,
      provider,
      payment,
      callbacks,
      ...payload,
    };

    return paymentsService.initializePaymentFlow(fullPayload).then((response) => {
      if (response.status === 201) {
        commit('setActivePaymentFlow', { flow: response.data });
      } else {
        dispatch('utils/error', { msg: response.code }, { root: true });
        commit('setActivePaymentFlow', { flow: null });
      }
      commit('setLoading', { loading: false });
      return state.activePaymentFlow;
    }).catch((error) => {
      dispatch('utils/error', { msg: error }, { root: true });
      commit('setActivePaymentFlow', { flow: null });
      commit('setLoading', { loading: false });
      return state.activePaymentFlow;
    }).finally(() => {
      commit('setLoading', { loading: false });
    });
  },

  async initializeLinkIntent({ dispatch, commit }, {
    provider = {}, entityCode = null, entityName = null, accountType = null, contryCode = 'chile',
  } = {}) {
    commit('setLoading', { loading: true });
    const fullPayload = {
      provider: provider.provider_code,
      entity_code: entityCode,
      entity_name: entityName,
      holder_type: accountType,
      country: contryCode,
    };

    return paymentsService.initializeLinkIntent(fullPayload).then((response) => {
      if (response.status !== 201) {
        dispatch('utils/error', { msg: response.code }, { root: true });
      }
      commit('setLoading', { loading: false });
      return response.data;
    }).catch((error) => {
      dispatch('utils/error', { msg: error }, { root: true });
      commit('setLoading', { loading: false });
      return null;
    }).finally(() => {
      commit('setLoading', { loading: false });
    });
  },

  async exchangeToken({ dispatch, commit }, { data = {}, entityCode = null, bankAccProvId = null } = {}) {
    commit('setLoading', { loading: true });
    const payload = {
      bank_account_provider_id: bankAccProvId,
      entity_code: entityCode,
      exchange_token: data.exchangeToken,
      provider: 'cl_fintoc',
    };
    return paymentsService.exchangeToken({ payload }).then((response) => {
      if (response.status !== 200) {
        dispatch('utils/error', { msg: response.code }, { root: true });
        commit('setLoading', { loading: false });
      }
      commit('setLoading', { loading: false });
      return response.data;
    }).catch((error) => {
      dispatch('utils/error', { msg: error }, { root: true });
      commit('setLoading', { loading: false });
      return null;
    });
  },

  async finishLinkAccount({ dispatch, commit }, { bankAccount = {}, entityCode = null, bankAccProvId = null } = {}) {
    commit('setLoading', { loading: true });
    const payload = {
      bank_account_provider_id: bankAccProvId,
      entity_code: entityCode,
      bank_account: bankAccount,
      provider: 'cl_fintoc',
    };
    return paymentsService.finishLinkAccount({ payload }).then((response) => {
      if (response.status !== 200) {
        dispatch('utils/error', { msg: response.code }, { root: true });
        commit('setLoading', { loading: false });
      }
      commit('setLoading', { loading: false });
      return response.data;
    }).catch((error) => {
      dispatch('utils/error', { msg: error }, { root: true });
      commit('setLoading', { loading: false });
      return null;
    });
  },

  async submitPaymentFlow({ commit, state }, { flowSubmissionData }) {
    commit('setLoading', { loading: true });
    const { currentPayment: payment, activePaymentFlow: { tether_payment_intent_id: flowId } } = state;
    return paymentsService.submitPaymentFlow({ payment, flowId, flowData: flowSubmissionData }).finally(() => {
      commit('setLoading', { loading: false });
    });
  },
  /** Get the payment status from the payments service
   *
   * @param {*} param0 - vuex context
   * @param {*} param1 - Object containing the paymentId
   * @param {*} param1.paymentId - The payment id
   * @returns {Promise} - The payment status object (containing the status and date)
   */
  async getPaymentStatus({ dispatch }, { paymentId }) {
    return paymentsService.getPaymentStatus({ paymentId })
      .then((response) => {
        const { data } = response;
        if (data) {
          return data;
        }
        dispatch('utils/error', 'Error getting payment status', { root: true });
        return null;
      });
  },

  async pollPaymentStatus({ dispatch }, {
    paymentId,
    maxAttempts = null,
    intervalTime = 1000,
    initialTime = null,
    expectedStatus = null,
    initialStatus = null,
    iterationCallback = () => {},
    successCallback = () => {},
    errorCallback = () => {},
  }) {
    const successCriteria = expectedStatus
      ? (status) => status === expectedStatus
      : (status) => status !== initialStatus;
    let remainingAttempts = maxAttempts ?? Infinity;
    let poll = null;
    const now = initialTime ?? moment();
    function pollCallback() {
      dispatch('getPaymentStatus', { paymentId })
        .then(({ status, updatedAt }) => {
          // FIXME: The time returned by the service does not have the timezone, so there's actually no way to know
          // if the time is in UTC or in the local timezone. For now, we assume that the time is in UTC.
          const time = moment(updatedAt).utc();
          iterationCallback(status, time, initialStatus);
          // We have moved to another step, so we stop polling the payment status and we update the application list
          if (time > now && (status && successCriteria(status))) {
            clearInterval(poll);
            // Update the application list
            successCallback(status, time, initialStatus);
          } else if (remainingAttempts <= 0) {
            clearInterval(poll);
            if (errorCallback) {
              errorCallback(status, time, initialStatus);
            }
          }
          remainingAttempts -= 1;
        });
    }
    poll = setInterval(pollCallback, intervalTime);
    return poll;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
