var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-main register-main--spaced"},[_c('div',{staticClass:"fill-width"},[_c('ModalHeader',{attrs:{"content-sections":['actions', 'logo'],"actions-tag":_vm.registerType === 1
        ? 'click_reg_quick-register-name-location_back-button'
        : 'click_reg_detailed-register-name-location_back-button',"inline-logo":"","small-logo":""},on:{"back":function($event){return _vm.back()}}}),(!_vm.isInMap)?_c('RowStepper',{attrs:{"mixpanel-tag":_vm.registerType === 1
        ? 'click_reg_quick-register-name-location_row-stepper'
        : 'click_reg_detailed-register-name-location_row-stepper',"step":3}}):_vm._e(),_c('ModalContent',{attrs:{"content-sections":['smallTitle', 'subtitle', 'infoBox', 'textSelect'],"small-title-text":'user_register.register_location.title',"small-title-left":"","subtitle-text":_vm.inStudentFlow
          ? 'user_register.location_type.arg_subtitle'
          : 'user_register.location_type.subtitle',"subtitle-light":"","subtitle-arg":Object.keys(_vm.currentStudent).length > 0 && _vm.inStudentFlow ? _vm.currentStudent.first_name : '',"text-select-items":_vm.locationTypesFiltered,"text-select-item-text":"address_name","text-select-initial-value":_vm.locationType,"text-select-no-info-error":_vm.missingInfo && !_vm.isActive,"text-select-return-object":"","text-select-placeholders":'user_register.location_type.placeholder',"info-box-text":_vm.setAddress(),"info-box-tag":_vm.registerType === 1
        ? 'click_reg_quick-register-name-location_address-card'
        : 'click_reg_detailed-register-name-location_address-card',"info-box-icon":'location-pin.svg',"text-select-tag":_vm.registerType === 1
        ? 'click_reg_quick-register-name-location_name-dropdown-option-'
        : 'click_reg_detailed-register-name-location_name-dropdown-option-',"click-text-select-tag":_vm.registerType === 1
        ? 'click_reg_quick-register-name-location_name-dropdown-selector'
        : 'click_reg_detailed-register-name-location_name-dropdown-selector',"info-box-border":"","info-box-fill-width":""},on:{"setTextSelectValue":_vm.setValue}})],1),_c('CallToActions',{attrs:{"types":['main'],"active-value":_vm.isActive,"main-button-text":'user_register.continue',"main-button-tag":_vm.registerType === 1
      ? 'click_reg_quick-register-name-location_continue-button'
      : 'click_reg_detailed-register-name-location_continue-button',"small":"","spaced":false},on:{"continue":function($event){return _vm.nextStep()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }