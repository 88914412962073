<template>
  <div class="register-main">
    <p
      v-t="'views.login.or'"
      class="register-main--google--container--text"
      style="margin-top: 8px; margin-bottom: 8px !important"
    />
  </div>
</template>

<script>
export default {
  name: 'ModalOr',
  components: {},
};
</script>
