import { backendAPI, userApi } from '@/api';
import querystring from 'querystring';

const appUrl = '/registration/legal_guardian';
const appUrlGuest = '/registration/address/research';
const applicantUrl = '/registration/applicant/';
const authUrl = 'authentication';
const registerFlat = '/registration';

export default {
  getTermsAndConditions(termsCodes, campusCodes) {
    return backendAPI.get(
      `${registerFlat}/terms_and_conditions/`,
      {
        params: {
          code: termsCodes,
          campus_code: campusCodes,
        },
        paramsSerializer: (params) => querystring.stringify(params),
      },
    );
  },
  submitTermsAndCondition(termsCode, { accepted, campusCode }) {
    return backendAPI.post(`${registerFlat}/terms_and_conditions/${termsCode}/submit`, {
      accepted,
      campus_code: campusCode,
    });
  },
  checkTermsAndConditions(termsCode, campusCode = undefined) {
    return backendAPI.get(`${registerFlat}/terms_and_conditions/${termsCode}`, {
      params: {
        campus_code: campusCode,
      },
    });
  },
  setUserInfo({ username }) {
    return userApi.patch(`${authUrl}/user/`, {
      username,
    });
  },
  identificationStep1({ uuid, nationality, idType }) {
    return backendAPI.patch(`${appUrl}/${uuid}/step_1/`, {
      nationality,
      identification_type: idType,
    });
  },
  identificationStep2({ uuid, idNumber }) {
    return backendAPI.patch(`${appUrl}/${uuid}/step_2_1/`, {
      identification_number: idNumber,
    });
  },
  identificationStep3({ idInfo }) {
    return backendAPI.patch(`${appUrl}/c_step_personal_data_2/`, idInfo);
  },
  identificationStep4({ uuid, personalInfo }) {
    return backendAPI.patch(`${appUrl}/${uuid}/step_3/`, {
      first_name: personalInfo.firstName,
      other_name: personalInfo.secondName,
      first_lastname: personalInfo.surname,
      other_lastname: personalInfo.secondSurname,
      gender: personalInfo.gender,
      birth_date: personalInfo.birthDate,
      education: null,
      marital_status: null,
      ethnicity: null,
    });
  },
  identificationStep5({ uuid, fullAddressPost }) {
    return backendAPI.patch(`${appUrl}/${uuid}/step_4/`, {
      department: fullAddressPost.department,
      municipality: fullAddressPost.municipality,
      main_address: fullAddressPost.main_address,
      address_details: fullAddressPost.address_details,
      vp_tipo_via: fullAddressPost.vp_tipo_via,
      area_label: fullAddressPost.area_label,
      vp_number: fullAddressPost.vp_number,
      vp_letra: fullAddressPost.vp_letra,
      vp_prefijo: fullAddressPost.vp_prefijo,
      vp_cuadrante: fullAddressPost.vp_cuadrante,
      vg_numero_via: fullAddressPost.vg_numero_via,
      vg_letra: fullAddressPost.vg_letra,
      vg_placa: fullAddressPost.vg_placa,
      vg_prefijo: fullAddressPost.vg_prefijo,
      vg_cuadrante: fullAddressPost.vg_cuadrante,
      address_lat: fullAddressPost.address_lat,
      address_lon: fullAddressPost.address_lon,
    });
  },
  identificationStep6({
    uuid, contactConfig, phoneNumber, email,
  }) {
    return backendAPI.patch(`${appUrl}/${uuid}/step_5/`, {
      phone: phoneNumber,
      email,
      contact_preference_call: contactConfig.calls,
      contact_preference_whatsapp: contactConfig.whatsapp,
      contact_preference_sms: contactConfig.sms,
      contact_preference_email: true,
    });
  },
  identificationStep7({ uuid, notifications }) {
    return backendAPI.patch(`${appUrl}/${uuid}/step_6/`, {
      notify_important_dates: notifications.dates,
      notify_interest_schools: notifications.schools,
    });
  },
  fastRegisterStep4({ location, lgUuid }) {
    return backendAPI.patch(`${appUrl}/${lgUuid}/step_4/`, {
      department: location.department,
      municipality: location.municipality,
      main_address: true,
      address_details: location.address_details,
      vp_tipo_via: location.vp_tipo_via,
      vp_number: location.vp_number,
      vg_numero_via: location.vg_numero_via,
      vg_placa: location.vg_numero_via,
      address_lat: location.address_lat,
      address_lon: location.address_lon,
    });
  },
  registerAddressGuest({ location }) {
    return backendAPI.post(`${appUrlGuest}/`, location);
  },
  fastRegisterStep2({ location }) {
    return backendAPI.patch(`${appUrl}/f_step_2/`, location);
  },
  fastRegisterStep3({ gradesData }) {
    return backendAPI.patch(`${appUrl}/f_step_3/`, {
      interest_grades: gradesData.grades,
      interest_stages: gradesData.stages,
    });
  },
  identificationLgStep1({ personalInfo }) {
    return backendAPI.patch(`${appUrl}/c_step_personal_data_1/`, {
      uuid: personalInfo.uuid,
      first_name: personalInfo.firstName,
      other_name: personalInfo.secondName,
      last_name: personalInfo.firstLastName,
      other_lastname: personalInfo.secondLastName,
      birth_date: personalInfo.birthDate,
    });
  },
  locationLgStep2(location) {
    return backendAPI.patch(`${appUrl}/c_step_2/`, {
      location,
    });
  },
  contactLgStep3(location) {
    return backendAPI.patch(`${appUrl}/c_step_2/`, {
      location,
    });
  },
  retrieveRegistrationData(uuid, key) {
    return backendAPI.request({
      url: `${appUrl}/${uuid}/`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${key}`,
      },
    });
  },
  retrieveRegistrationApplicants() {
    return backendAPI.get(`${applicantUrl}`);
  },
  contactPreferences({ contactPreferences }) {
    const contactPreferencePayload = {
      contact_preference_call: contactPreferences?.contactPreferenceCall,
      contact_preference_whatsapp: contactPreferences?.contactPreferenceWhatsApp,
      contact_preference_sms: contactPreferences?.contactPreferenceSms,
      contact_preference_email: contactPreferences?.contactPreferenceEmail,
      email: contactPreferences.email,
      phone: contactPreferences.phone,
    };
    return backendAPI.patch(`${appUrl}/step_pref_contact/`, contactPreferencePayload);
  },
  setModalFlow({ modalInfo, modalUuid }) {
    return backendAPI.patch(`${registerFlat}/modal_flow/${modalUuid}/`, modalInfo);
  },
  setNewModalFlow({ modalInfo }) {
    return backendAPI.post(`${registerFlat}/modal_flow/`, modalInfo);
  },
  getModalFlowInfo() {
    return backendAPI.get(`${registerFlat}/modal_flow/`);
  },
};
