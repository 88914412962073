<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        :actions-tag="registerType === 1
          ? 'click_reg_quick-register-other-success_back-button'
          : 'click_reg_detailed-register-other-success_back-button'"
        small-logo
        no-welcome-text-logo
        spaced-logo
        @back="$emit('backStep')"
      />
      <ModalContent
        style="margin-top: 40px"
        :content-sections="['smallTitle']"
        :small-title-text="'user_register.final_roles.title'"
        small-title-primary-color
      />
    </div>
    <CallToActions
      :types="['main']"
      active-value
      :main-button-text="'user_register.final_roles.btn'"
      :main-button-tag="registerType === 1
        ? 'click_reg_quick-register-other-success_start-button'
        : 'click_reg_detailed-register-other-success_start-button'"
      :spaced="false"
      @continue="$router.push('/map')"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';

export default {
  name: 'RolesFinal',
  components: { ModalHeader, ModalContent, CallToActions },
  props: {
    // 1 Quick - 2 Detailed
    registerType: {
      type: Number,
      default: null,
    },
  },
  methods: {
    ...mapActions({
      setCurrentStep: 'userRegister/setCurrentStep',
    }),
  },
};
</script>
