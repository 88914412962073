var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:{
    'register-main--call-info-button': _vm.origin === 'call',
    'register-main--info-button': !_vm.origin,
    'register-main--info-button--active': _vm.isActive,
  },staticStyle:{"margin-top":"12px"},on:{"click":function($event){return _vm.infoButtonClick()},"keydown":function($event){/* TODO */}}},[(!_vm.withoutTitle)?_c('div',{staticClass:"d-flex justify-space-between"},[_c('h3',{directives:[{name:"t",rawName:"v-t",value:(_vm.title),expression:"title"}],staticClass:"register-main--info-button--title"}),_c('p',{directives:[{name:"t",rawName:"v-t",value:(_vm.time),expression:"time"}],staticClass:"register-main--info-button--time"})]):_vm._e(),_vm._l((_vm.sectionContent),function(section,index){return _c('div',{key:index,staticClass:"d-flex align-center",class:{ 'justify-center': _vm.center },style:(_vm.center || _vm.withoutTitle ? '' : 'margin-top: 12px')},[(!_vm.withoutImg)?_c('img',{staticStyle:{"margin-right":"12px"},attrs:{"src":require('@/assets/icons/' + section.img),"alt":"bookmark"}}):_vm._e(),_c('p',{directives:[{name:"t",rawName:"v-t",value:(section.text),expression:"section.text"}],staticClass:"register-main--info-button--list",class:{
        'register-main--info-button--list--center': _vm.center,
        'register-main--info-button--list--purple-center': _vm.purple,
      }})])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }