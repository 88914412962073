<template>
  <div class="register-main fill-width justify-space-between" style="height: 100%">
    <section
      id="calendar"
      class="calendar-div"
      :class="{ 'calendar-div--small': dateSet }"
      @click="calendarClick()"
      @keydown.enter="calendarClick()"
    />
  </div>
</template>

<script>
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'CalendarAtom',
  props: {
    mixpanelTag: {
      type: String,
      default: null,
    },
    calendarUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dateSet: false,
    };
  },
  mounted() {
    // eslint-disable-next-line no-undef
    Calendly.initInlineWidget({
      // eslint-disable-next-line max-len
      url: this.calendarUrl,
      parentElement: document.getElementById('calendar'),
      prefill: {},
      utm: {},
    });
    window.addEventListener('message', (e) => {
      this.isCalendlyEvent(e);
    });
  },
  methods: {
    isCalendlyEvent(e) {
      if (e.data.event === 'calendly.event_scheduled') {
        this.$emit('data-set');
        this.dateSet = true;
      }
    },
    calendarClick() {
      if (this.mixpanelTag) {
        return trackMixPanel(this.mixpanelTag);
      }
      return null;
    },
  },
};
</script>
