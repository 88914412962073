<template>
  <v-expansion-panels v-model="panelsContact" class="contact_panel__container pb-3">
    <v-expansion-panel>
      <v-expansion-panel-header
        class="p-0 d-flex flex-column justify-start align-start"
        disable-icon-rotate
        expand-icon=""
      >
        <template #default="{ open }">
          <p
            v-if="buttonsContact[0].isDisabled && !open"
            v-t="'contact_panel.contact.school-error'"
            class="contact_panel__container__buttons--error pl-8"
          />

          <!-- Buttons contact -->
          <div v-if="!open" class="contact_panel__container__buttons">
            <button
              v-for="{ name, isDisabled, icon } in buttonsContact"
              :key="name"
              type="button"
              :disabled="isDisabled"
              :class="{
                'contact_panel__container__buttons--plataform':
                  name === $t('contact_panel.contact.plataform'),
                'contact_panel__container__buttons--school':
                  name === $t('contact_panel.contact.school'),
              }"
              @click="selectContact(name)"
            >
              <img width="25" :src="require(`@/assets/icons/${icon}`)" alt="message" />
              <span class="ml-2"> {{ name }} </span>
            </button>
          </div>

          <!-- Title contact -->
          <div v-else class="contact_panel__container__header--white px-12 w-full">
            <!-- Tittle -->
            <div class="d-flex flex-row">
              <p
                v-t="
                  isSelectedContactPlataform
                    ? 'contact_panel.contact.plataform'
                    : 'contact_panel.contact.school'
                "
                class="contact_panel__container__header--white--title"
              />
              <!-- Close button -->
              <button class="contact_panel__container__close" type="button">
                <v-icon color="#F90E1F" size="24">
                  mdi-close-circle
                </v-icon>
              </button>
            </div>

            <!-- Name institution -->
            <p v-if="!isSelectedContactPlataform" class="contact_panel__container__text">
              {{ campusDetail.campus_name }}
            </p>

            <hr />
          </div>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pb-5">
        <Contact
          :contacts="campusDetail.institutioncontact_campus"
          :is-support-form="isSelectedContactPlataform"
          @resetForm="resetFormContact"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapGetters } from 'vuex';
import Contact from '@/components/explorer/contact/Contact.vue';

export default {
  name: 'ContactPanel',
  components: {
    Contact,
  },
  data() {
    return {
      isSelectedContactPlataform: false,
      panelsContact: [0],
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'institutions/campusDetails',
      goToFormContactFrom: 'messaging/goToFormContactFrom',
    }),
    buttonsContact() {
      const buttons = [
        {
          name: this.$t('contact_panel.contact.school'),
          isDisabled: !this.hasContactsSchool(),
          icon: 'contact-school.svg',
        },
        {
          name: this.$t('contact_panel.contact.plataform'),
          isDisabled: false,
          icon: 'contact-platform.svg',
        },
      ];

      return buttons;
    },
  },
  watch: {
    panelsContact(value) {
      if (value === 0) {
        if (!this.isSelectedContactPlataform && this.buttonsContact[0].isDisabled) {
          this.panelsContact = [undefined];
        }
      } else {
        this.isSelectedContactPlataform = false;
      }
    },
    goToFormContactFrom({ from }) {
      this.isSelectedContactPlataform = from !== 'contact-institution';
      this.panelsContact = 0;
    },
  },
  methods: {
    resetFormContact() {
      this.panelsContact = [undefined];
    },
    selectContact(contact) {
      this.isSelectedContactPlataform = contact === this.$t('contact_panel.contact.plataform');
    },
    hasContactsSchool() {
      const list = [];
      // eslint-disable-next-line
      for (const { email, contact_label } of this.campusDetail.institutioncontact_campus) {
        // eslint-disable-next-line
        const name = contact_label.contact_name;
        // eslint-disable-next-line camelcase
        const { id } = contact_label;
        const hasEmailContact = id < 5 && email;

        if (hasEmailContact) {
          list.push({ name, email });
        }
      }
      return list.length > 0;
    },
  },
};
</script>
<style scoped>
hr {
  margin-top: 0.5rem;
  width: 100%;
  border: 0px;
  border-bottom: 1px solid #deebff;
}

.theme--light.v-expansion-panels .v-expansion-panel {
    background-color: #FAFAFA !important;
    box-shadow: none !important;
  }

  .v-expansion-panel::before {
    box-shadow:  none !important;
  }
</style>
