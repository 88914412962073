<template>
  <div class="register-main fill-width flex-row align-center mb-2" style="padding-left: 14px">
    <img src="@/assets/icons/validation-warning.svg" alt="validation" />
    <p
      v-t="text"
      class="register-main--error"
      :class="{ 'register-main--error--center': center }"
    />
  </div>
</template>

<script>
export default {
  name: 'NoInfoErrorText',
  components: {},
  props: {
    text: {
      type: String,
      default: '',
    },
    center: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
