import services from '@/services';

const getDefaultState = () => ({
  currentRole: {
    roleInfo: null,
    rolePermissions: null,
  },
  headMaster: {
    roleInfo: null,
    rolePermissions: null,
    schools: null,
    mainSchool: null,
    firstLogin: null,
  },
  legalGuardian: {
    roleInfo: null,
    rolePermissions: null,
  },
});

const state = {
  ...getDefaultState(),
};

const getters = {
  currentRole: ({ currentRole }) => currentRole,
  isLegalGuardian: ({ currentRole }) => {
    if (currentRole.roleInfo) {
      return currentRole.roleInfo.id === 2;
    }
    return false;
  },
  isHeadMaster: ({ currentRole }) => {
    if (currentRole.roleInfo) {
      return currentRole.roleInfo.id === 3;
    }
    return false;
  },
  isAmbassador: ({ currentRole }) => {
    if (currentRole.roleInfo) {
      return currentRole.roleInfo.id === 4;
    }
    return false;
  },
  currentHeadMasterMainSchool: ({ headMaster }) => headMaster.mainSchool,
  currentHeadMasterSchools: ({ headMaster }) => headMaster.schools,
  headMasterRole: ({ headMaster }) => headMaster.roleInfo,
  legalGuardianRole: ({ legalGuardian }) => legalGuardian.roleInfo,
  permissions: ({ currentRole }) => currentRole.rolePermissions,
  headMasterFirstLogin: ({ headMaster }) => !!(headMaster.roleInfo && headMaster.firstLogin),
  headMasterPermissions: ({ headMaster }) => headMaster.rolePermissions,
  ambassadorPermissions: ({ ambassador }) => ambassador.rolePermissions,
  legalGuardianPermissions: ({ legalGuardian }) => legalGuardian.rolePermissions,
  rolePermissions: ({ legalGuardian, headMaster }) => [
    { legalGuardian: legalGuardian.rolePermissions },
    { headMaster: headMaster.rolePermissions },
  ],
};

const mutations = {
  resetStore(state) {
    Object.assign(state, getDefaultState());
  },
  setHeadMasterSchools(state, { schools }) {
    state.headMaster.schools = schools;
  },
  setHeadMasterMainSchool(state, { mainSchool }) {
    state.headMaster.mainSchool = mainSchool;
  },
  //  ** Start Partial Main School Patches/Updates
  setHeadMasterMainSchoolAudiovisuals(state, { audiovisuals }) {
    state.headMaster.mainSchool.audiovisual_campus = audiovisuals;
  },
  setSafetyMainSchool(state, { safetyMeasures }) {
    state.headMaster.mainSchool.safety_campus = safetyMeasures;
  },
  setInfrastructureMainSchool(state, { infrastructures }) {
    state.headMaster.mainSchool.infraestructure_campus = infrastructures;
  },
  setHeadmasterMainSchoolLocation(state, { location }) {
    state.headMaster.mainSchool.campus_location = location;
  },
  setMainSchoolPridepoints(state, { pridepoints }) {
    state.headMaster.mainSchool.pridepoint_campus = pridepoints;
  },
  setGlobalContactsPatch(state, { globalContacts }) {
    state.headMaster.mainSchool.institutioncontact_campus = globalContacts;
  },
  setProgramsPatch(state, { programs }) {
    state.headMaster.mainSchool.program_campus = programs;
  },
  setSectionTextPatch(state, { sectionText }) {
    state.headMaster.mainSchool.institutiontext_campus[0] = sectionText;
  },
  setConfigMainSchool(state, { configData }) {
    state.headMaster.mainSchool.config_campus[0] = configData;
  },
  setHeadmasterMainSchoolSports(state, { sports }) {
    state.headMaster.mainSchool.sport_campus = sports;
    // ** End Partial Main School Patches/Updates
  },
  setStaffMainSchool(state, { staff }) {
    state.headMaster.mainSchool.institutionmember_campus = staff.institutionmember_campus;
    state.headMaster.mainSchool.support_campus = staff.support_campus;
  },
  setStudentsMainSchool(state, { staff }) {
    if (staff.institutionmember_campus) {
      state.headMaster.mainSchool.institutionmember_campus = staff.institutionmember_campus;
    }
  },
  setImagesMainSchool(state, { images }) {
    state.headMaster.mainSchool.campus_images = images;
    const thumbnail = images.filter((image) => image.image_name.toLowerCase() === 'thumbnail');
    state.headMaster.mainSchool.campus_images_thumbnail = thumbnail;
    const imagesWithoutThumbnail = images.filter((image) => image.image_name.toLowerCase() !== 'thumbnail');
    state.headMaster.mainSchool.campus_images_gallery = imagesWithoutThumbnail;
  },
  setEduProjectMainSchool(state, { eduProjectData }) {
    const hasPiePdf = Object.keys(eduProjectData).find((key) => key === 'pei_pdf');
    const hasEduProjectLink = Object.keys(eduProjectData).find((key) => key === 'educational_project_video');
    if (hasPiePdf) {
      if (eduProjectData.pei_pdf || eduProjectData.pei_pdf === '') {
        state.headMaster.mainSchool.pei_pdf = eduProjectData.pei_pdf;
      }
    }
    if (hasEduProjectLink) {
      // eslint-disable-next-line camelcase
      const { educational_project_video } = eduProjectData;
      const { mainSchool } = state.headMaster;
      // eslint-disable-next-line camelcase
      if (educational_project_video !== undefined) {
        const eduProjectIndex = mainSchool.audiovisual_campus.findIndex(
          (audiovisual) => audiovisual.audiovisual_label.id === 3,
        );

        if (eduProjectIndex !== -1) {
          // eslint-disable-next-line camelcase
          if (educational_project_video !== null) {
            // eslint-disable-next-line camelcase
            mainSchool.audiovisual_campus[eduProjectIndex] = educational_project_video;
          } else {
            mainSchool.audiovisual_campus.splice(eduProjectIndex, 1);
          }
          // eslint-disable-next-line camelcase
        } else if (educational_project_video !== null) {
          mainSchool.audiovisual_campus.push(educational_project_video);
        }
      }
    }
  },
  setMainSchoolAdmissionSystem(state, { admissionSystem }) {
    state.headMaster.mainSchool.campus_admission_system = admissionSystem;
  },
  setMainSchoolAdmissionSystemRequiredDocuments(state, { requiredDocuments }) {
    state.headMaster.mainSchool.required_documents = requiredDocuments;
  },
  setMainSchoolAdmissionSystemRequiredTests(state, { requiredTests }) {
    state.headMaster.mainSchool.required_tests = requiredTests;
  },
  setExtracurricularSchool(state, { extracurricularData }) {
    state.headMaster.mainSchool.extraactivity_campus = extracurricularData;
  },
  setFaqsMainSchool(state, { faqsData }) {
    state.headMaster.mainSchool.frequently_asked_questions = faqsData;
  },
  setAchievementsMainSchool(state, { achievementsData }) {
    state.headMaster.mainSchool.achievement_campus = achievementsData;
  },
  // ** End Partial Main School Patches/Updates
  setLegalGuardianRoleInfo(state, { info }) {
    state.legalGuardian.roleInfo = info;
  },
  setLegalGuardianRolePermissions(state, { info }) {
    state.legalGuardian.rolePermissions = info;
  },
  setHeadMasterRoleInfo(state, { info }) {
    state.headMaster.roleInfo = info;
  },
  setHeadMasterRolePermissions(state, { info }) {
    state.headMaster.rolePermissions = info;
  },
  setCurrentRole(state, { info }) {
    state.currentRole.rolePermissions = info.rolePermissions;
    state.currentRole.roleInfo = info.roleInfo;
  },
  setHeadMasterFirstLogin(state, { bool }) {
    state.headMaster.firstLogin = bool;
  },
  setVacanciesPatch(state, { vacanciesData }) {
    const { mainSchool } = state.headMaster;
    vacanciesData.forEach((vacancy) => {
      const vacancyIndex = mainSchool.vacancies.findIndex(
        (vacancyInfo) => vacancyInfo.program_id === vacancy.id,
      );
      const programVancancyIndex = mainSchool.program_campus.findIndex(
        (vacancyInfo) => vacancyInfo.id === vacancy.id,
      );
      mainSchool.vacancies[vacancyIndex].regular_vacancies = vacancy.regular_vacancies;
      mainSchool.vacancies[vacancyIndex].boolean_vacancies = vacancy.boolean_vacancies;
      mainSchool.program_campus[programVancancyIndex].regular_vacancies = vacancy.regular_vacancies;
      mainSchool.program_campus[programVancancyIndex].boolean_vacancies = vacancy.boolean_vacancies;
    });
  },
  setGeneralInformationPatch(state, { generalInfoData }) {
    const { mainSchool } = state.headMaster;
    mainSchool.campus_name = generalInfoData.campus_name ?? mainSchool.campus_name;
    mainSchool.campus_short_name = generalInfoData.campus_short_name ?? mainSchool.campus_short_name;
    mainSchool.institution_name = generalInfoData.institution_name ?? mainSchool.institution_name;
    mainSchool.institution_short_name = generalInfoData.institution_short_name ?? mainSchool.institution_short_name;
    mainSchool.grade_min = generalInfoData.grade_min ?? mainSchool.grade_min;
    mainSchool.grade_max = generalInfoData.grade_max ?? mainSchool.grade_max;
  },
  setLanguagesPatch(state, { languagesData }) {
    const { mainSchool } = state.headMaster;
    mainSchool.language_campus = languagesData;
  },
  setAboutEstablishmentPatch(state, { aboutSchoolData }) {
    const { mainSchool } = state.headMaster;
    mainSchool.institutionagreement_campus = aboutSchoolData.institutionagreement_campus
      ?? mainSchool.institutionagreement_campus;
    mainSchool.sector_label = aboutSchoolData.sector_label ?? mainSchool.sector_label;
    mainSchool.institutiontext_campus = aboutSchoolData.institutiontext_campus ?? mainSchool.institutiontext_campus;
  },
  setPaymentPatch(state, { paymentData }) {
    const { mainSchool } = state.headMaster;
    const paymentIndex = mainSchool.payment_campus.findIndex(
      (paymentInfo) => paymentInfo.id === paymentData.id,
    );
    if (paymentIndex !== -1) {
      mainSchool.payment_campus[paymentIndex].num_scholarships = paymentData.num_scholarships;
      mainSchool.payment_campus[paymentIndex].payment_category_label = paymentData.payment_category_label;
      mainSchool.payment_campus[paymentIndex].tuition_category_label = paymentData.tuition_category_label;
    } else {
      mainSchool.payment_campus.push(paymentData);
    }
  },
  setPaymentV2Patch(state, { paymentV2Data }) {
    const { mainSchool } = state.headMaster;
    mainSchool.institution_payment_campus = paymentV2Data;
  },
  setContactsMainSchool(state, { contactsData }) {
    contactsData.forEach((contact) => {
      const contactsIndex = state.headMaster.mainSchool.institutioncontact_campus.findIndex(
        (social) => social.contact_label.id === contact.contact_label.id,
      );
      const { mainSchool } = state.headMaster;
      if (contactsIndex !== -1) {
        mainSchool.institutioncontact_campus[contactsIndex] = contact;
      } else {
        mainSchool.institutioncontact_campus.push(contact);
      }
    });
  },
  setSocialTokenMainSchool(state, { tokensData }) {
    state.headMaster.mainSchool.token_campus = tokensData;
  },
};
const actions = {
  resetStores({ commit }) {
    commit('resetStore');
  },
  // eslint-disable-next-line no-empty-pattern
  async getAnalyticsSectionGraphs({ }, { roleId, sectionId }) {
    return services.analyticsService
      .getSectionGraphs({ roleId, sectionId })
      .then((response) => response)
      .catch((err) => err);
  },
  // eslint-disable-next-line no-empty-pattern
  async getAnalyticsSections({ }, { roleId }) {
    return services.analyticsService
      .getSections({ roleId })
      .then((response) => response)
      .catch((err) => err);
  },
  async getRoleGraphs({ graphName, campusUuid }) {
    // DEPRECATED
    return services.analyticsService
      .getGraph({ graphName, campusUuid })
      .then((response) => response)
      .catch((err) => err);
  },
  // eslint-disable-next-line no-empty-pattern
  async getAnalyticsGraph({ }, { campusCode, graphId }) {
    return services.analyticsService
      .getCampusGraph({ campusCode, graphId })
      .then((response) => response)
      .catch((err) => err);
  },
  setMainSchool({ commit }, { school }) {
    commit('setMainSchool', { school });
  },
  setLegalGuardianRoleInfo({ commit }, { info }) {
    commit('setLegalGuardianRoleInfo', { info });
  },
  setLegalGuardianRolePermissions({ commit }, { info }) {
    commit('setLegalGuardianRolePermissions', { info });
  },
  setHeadMasterRoleInfo({ commit }, { info }) {
    const schools = [];
    if (info !== null) {
      if (info.roleOptions) {
        const getSchoolsPromise = new Promise((resolve) => {
          info.roleOptions.campus_allowed.forEach((campus, index, array) => {
            if (campus !== '*') {
              const campusUuid = campus;
              services.elasticSearchService
                .elasticCampusAttributeSearch({
                  campus: campusUuid,
                  fieldsRequired: ['campus_name', 'uuid'],
                })
                .then((response) => {
                  const campusDetail = response.data.results[0];
                  if (index === 0) {
                    services.institutionsService
                      .retrieveSchoolDetail({
                        searchCode: campusUuid,
                        studentUUID: null,
                        grade: null,
                      })
                      .then((campusDetail) => {
                        commit('setHeadMasterMainSchool', { mainSchool: campusDetail.data });
                      });
                  }
                  schools.push(campusDetail);
                })
                .catch((error) => {
                  throw error;
                })
                .finally(() => {
                  if (index === array.length - 1) resolve(campus);
                });
            }
          });
        });
        getSchoolsPromise.then(() => {
          commit('setHeadMasterSchools', { schools });
        });
      }
    }
    commit('setHeadMasterRoleInfo', { info });
  },
  setHeadMasterRolePermissions({ commit }, { info }) {
    commit('setHeadMasterRolePermissions', { info });
  },
  setCurrentRole({ commit }, { info }) {
    commit('setCurrentRole', { info });
  },
  setHeadMasterFirstLogin({ commit }, { bool }) {
    commit('setHeadMasterFirstLogin', { bool });
  },
  setHeadMasterMainSchool({ commit }, { mainSchool }) {
    commit('setHeadMasterMainSchool', { mainSchool });
  },
  updateHeadMasterSchool({ commit, state }, { school }) {
    const { schools, mainSchool } = state.headMaster;
    const index = schools.findIndex((s) => s.uuid === school.uuid);
    if (index !== -1) {
      schools[index] = school;
      if (school.uuid === mainSchool.uuid) {
        commit('setHeadMasterMainSchool', { mainSchool: school });
      }
    }
  },
  setHeadMasterMainSchoolAudiovisuals({ commit }, { audiovisuals }) {
    commit('setHeadMasterMainSchoolAudiovisuals', { audiovisuals });
  },
  setSafetyMainSchool({ commit }, { safetyMeasures }) {
    commit('setSafetyMainSchool', { safetyMeasures });
  },
  setInfrastructureMainSchool({ commit }, { infrastructures }) {
    commit('setInfrastructureMainSchool', { infrastructures });
  },
  setHeadmasterMainSchoolLocation({ commit }, { location }) {
    commit('setHeadmasterMainSchoolLocation', { location });
  },
  setHeadmasterMainSchoolSports({ commit }, { sports }) {
    commit('setHeadmasterMainSchoolSports', { sports });
  },
  setMainSchoolPridepoints({ commit }, { pridepoints }) {
    commit('setMainSchoolPridepoints', { pridepoints });
  },
  setStaffMainSchool({ commit }, { staff }) {
    commit('setStaffMainSchool', { staff });
  },
  setStudentsMainSchool({ commit }, { staff }) {
    commit('setStudentsMainSchool', { staff });
  },
  setImagesMainSchool({ commit }, { images }) {
    commit('setImagesMainSchool', { images });
  },
  setEduProjectMainSchool({ commit }, { eduProjectData }) {
    commit('setEduProjectMainSchool', { eduProjectData });
  },
  setMainSchoolAdmissionSystem({ commit }, { admissionSystem }) {
    commit('setMainSchoolAdmissionSystem', { admissionSystem });
  },
  setMainSchoolAdmissionSystemRequiredDocuments({ commit }, { requiredDocuments }) {
    commit('setMainSchoolAdmissionSystemRequiredDocuments', { requiredDocuments });
  },
  setMainSchoolAdmissionSystemRequiredTests({ commit }, { requiredTests }) {
    commit('setMainSchoolAdmissionSystemRequiredTests', { requiredTests });
  },
  setExtracurricularSchool({ commit }, { extracurricularData }) {
    commit('setExtracurricularSchool', { extracurricularData });
  },
  setContactsMainSchool({ commit }, { contactsData }) {
    commit('setContactsMainSchool', { contactsData });
  },
  setSocialTokenMainSchool({ commit }, { tokensData }) {
    commit('setSocialTokenMainSchool', { tokensData });
  },
  setFaqsMainSchool({ commit }, { faqsData }) {
    commit('setFaqsMainSchool', { faqsData });
  },
  setAchievementsMainSchool({ commit }, { achievementsData }) {
    commit('setAchievementsMainSchool', { achievementsData });
  },
  setVacanciesPatch({ commit }, { vacanciesData }) {
    commit('setVacanciesPatch', { vacanciesData });
  },
  setGeneralInformationPatch({ commit }, { generalInfoData }) {
    commit('setGeneralInformationPatch', { generalInfoData });
  },
  setLanguagesPatch({ commit }, { languagesData }) {
    commit('setLanguagesPatch', { languagesData });
  },
  setAboutEstablishmentPatch({ commit }, { aboutSchoolData }) {
    commit('setAboutEstablishmentPatch', { aboutSchoolData });
  },
  setPaymentPatch({ commit }, { paymentData }) {
    commit('setPaymentPatch', { paymentData });
  },
  setPaymentV2Patch({ commit }, { paymentV2Data }) {
    commit('setPaymentV2Patch', { paymentV2Data });
  },
  setConfigMainSchool({ commit }, { configData }) {
    commit('setConfigMainSchool', { configData });
  },
  setGlobalContactsPatch({ commit }, { globalContacts }) {
    commit('setGlobalContactsPatch', { globalContacts });
  },
  setProgramsPatch({ commit }, { programs }) {
    commit('setProgramsPatch', { programs });
  },
  setSectionTextPatch({ commit }, { sectionText }) {
    commit('setSectionTextPatch', { sectionText });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
