<template>
  <div class="register-main p-4 not-found-address--background" :class="inRegister ? 'not-found-address' : ''">
    <div>
      <HeaderModal :content-sections="['close']" @close="$emit('close')" />
      <ContentModal
        class="mt-0"
        :content-sections="['icon']"
        :icon-color="'#5627FF'"
        :icon-name="'mdi-information'"
      />
      <MainTitle
        :text="'user_register.register_location.title_not_found'"
        secondary
        small
      />
      <TextAtom
        :value="$t('user_register.register_location.subtitle_not_found')"
        color="neutral-850"
        font="inter"
        size="normal"
        weight="400"
      />
    </div>
    <CallToActions
      :option-text1="'user_register.continue'"
      :types="['option']"
      legacy-primary
      spaced
      @nextStep="nextStep"
    />
  </div>
</template>

<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import MainTitle from '@/components/atoms/titles/MainTitle.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ContentModal from '@/components/molecules/modals/Content.vue';
import HeaderModal from '@/components/molecules/modals/Header.vue';

export default {
  name: 'FavWarningModal',
  components: {
    HeaderModal,
    CallToActions,
    ContentModal,
    MainTitle,
    TextAtom,
  },
  props: {
    inRegister: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    nextStep() {
      this.$emit('nextStep');
    },
  },
};
</script>
