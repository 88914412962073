<template>
  <div class="register-main register-main--spaced">
    <div>
      <HeaderModal :content-sections="['close']" @close="$emit('close')" />
      <MainTitle
        :text="'digital_enrollment.waiting-list.apply.title'"
        secondary
      />
      <ContentModal
        :content-sections="['subtitle']"
        subtitle-center
        :subtitle-arg="campusDetail.campus_name"
        :subtitle-text="'digital_enrollment.waiting-list.apply.subtitle'"
        subtitle-light
      />
    </div>
    <CallToActions
      :option-text1="'digital_enrollment.waiting-list.apply.button'"
      :types="['option']"
      :spaced="false"
      option-btn-no-margin
      @nextStep="nextStep"
    />
  </div>
</template>

<script>
import MainTitle from '@/components/atoms/titles/MainTitle.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ContentModal from '@/components/molecules/modals/Content.vue';
import HeaderModal from '@/components/molecules/modals/Header.vue';
import CONFIG from '@/config';
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ApplicationModal',
  components: {
    HeaderModal,
    CallToActions,
    ContentModal,
    MainTitle,
  },
  props: {
    programId: {
      type: Number,
      required: false,
      default: 0,
    },
    hasVacancies: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      campusDetail: 'institutions/campusDetails',
      isGuest: 'authentication/isGuest',
      currentStudent: 'authentication/currentStudent',
      campusDetails: 'institutions/campusDetails',
    }),
  },
  methods: {
    ...mapActions({
      setLoginModal: 'authentication/setLoginModal',
      setShowCard: 'explorer/setShowCard',
      setCurrentStudent: 'authentication/setCurrentStudent',
    }),
    nextStep() {
      if (TENANT_CONFIGURATION.PUBLIC_ENROLLMENT.ENABLED
        && this.campusDetail?.campus_processes.some((process) => process.process_code
        === TENANT_CONFIGURATION.PUBLIC_ENROLLMENT.DEFAULT_PROCESS_CODE)) {
        const env = CONFIG.environment === 'staging' ? 'staging.' : '';
        if (this.hasVacancies) {
          window.open(`https://${env}applications.tether.education/apply/${TENANT_CONFIGURATION.PUBLIC_ENROLLMENT.DEFAULT_PROCESS}/${this.campusDetail?.uuid}?programs=${this.programId}`, '_blank').focus();
        } else {
          window.open(`https://${env}applications.tether.education/queue/${TENANT_CONFIGURATION.PUBLIC_ENROLLMENT.DEFAULT_PROCESS}/${this.campusDetail?.campus_code}/${this.programId}`, '_blank').focus();
        }
      } else {
        this.$emit('closeCampus');
        if (this.isGuest) {
          // FIXME: Had to send the guests to the full login flow because the DGE login flow is not working properly
          const environmentURLMappings = {
            develop: 'staging.',
            staging: 'staging.',
            production: '',
          };
          const env = environmentURLMappings[CONFIG.environment] || '';
          const urlParams = {
            origin: 'digitalenrollment',
            user_type: 'legalguardian',
            redirect_url: `https://${env}${CONFIG.tenant}.digitalenrollment.explorador.com/digital-enrollment/new-admission`,
            tenantId: this.campusDetail.campus_code,
          };
          this.$emit('close-campus-detail');
          this.$router.push({ path: '/login', query: urlParams });
        } else {
          if (!this.isGuest && this.currentStudent && Object.keys(this.currentStudent).length === 0) {
            this.setCurrentStudent({ student: this.currentStudent });
          }
          this.setLoginModal({ modal: 'applicationFlow' });
          this.$emit('close-campus-detail');
          this.setShowCard({ status: false });
          this.$router.push('/login');
        }
      }
    },
  },
};
</script>
