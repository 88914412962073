<template>
  <div>
    <TextAtom
      :value="description"
      class="mt-4 text-left d-flex pl-4 pb-3"
      tag="label"
      font="inter"
      color="primary-dark"
      weight="400"
      size="subtitle"
    />
    <section class="campus-detail__panels__container">
      <div class="panel_container_row fill-width">
        <img
          :src="require('@/assets/iconsChile/campusDetail/sports_tennis.svg')"
          class="campus-detail__panels__image mr-3"
          alt="Campus Sports"
        />

        <div class="campus-detail__panels__title mb-2 font-weight-bold panel_container_row">
          <div v-if="allSports.length == 1" class="ml-2">
            {{ $t('campus-details.panels.onesport', { n: allSports.length }) }}
          </div>

          <div v-else class="ml-2">
            {{ $t('campus-details.panels.sports', { n: allSports.length }) }}
          </div>
        </div>
      </div>

      <hr />
      <section>
        <div
          v-for="(sport, index) in allSports"
          :key="index"
          itemprop="additionalProperty"
          itemscope
          itemtype="https://schema.org/PropertyValue"
        >
          <span
            class="campus-detail__panels__title text-left mb-2 font-weight-medium bullet-point d-block"
            itemprop="name"
          >
            {{ getLabel(sport) }}
            <meta itemprop="value" content="True" />
            <meta
              v-if="getLabel(sport)"
              itemprop="description"
              :content="getLabel(sport)"
            >
            <meta
              v-if="sport.campus_code !== ''"
              itemprop="identifier"
              :content="sport.campus_code"
            >
            <meta itemprop="url" :href="canonicalLink">
          </span>
        </div>
      </section>
      <MessageInPanel from="sports-panel" />
    </section>
  </div>
</template>
<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import MessageInPanel from './MessageInPanel.vue';

export default {
  name: 'SportsPanel',
  components: {
    MessageInPanel,
    TextAtom,
  },
  props: {
    sports: {
      type: Array,
      required: true,
    },
    canonicalLink: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
  },
  computed: {
    allSports() {
      return this.sports.flatMap((sport) => {
        if (sport.sport_label_id === 99 && sport.descrip) {
          return sport.descrip.split(',').map((sportName) => ({ ...sport, descrip: sportName.trim() }));
        }
        return sport;
      });
    },
  },
  methods: {
    getLabel(sport) {
      if (sport.sport_label_id === 99) {
        return sport.descrip || sport.sport_label;
      }
      return sport.sport_label;
    },
  },
};
</script>
