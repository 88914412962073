<template>
  <ConfirmationDialog
    v-if="activeModal === 'LinkBankConfirmation'"
    :title="`payments.link_bank.dialog.${status}.title`"
    :subtitle="`payments.link_bank.dialog.${status}.subtitle`"
    :hide-cancel-button="true"
    :confirm-button-text="`payments.link_bank.dialog.continue`"
    value
    @confirm="confirm"
  />
</template>

<script>
import ConfirmationDialog from '@/components/molecules/dialogs/ConfirmationDialog.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'LinkBankConfirmationModal',
  components: {
    ConfirmationDialog,
  },
  computed: {
    ...mapGetters({
      activeModal: 'utils/activeModal',
      activeModalData: 'utils/activeModalData',
      activeModalCallbacks: 'utils/activeModalCallbacks',
    }),
    status() {
      return this.activeModalData?.status || null;
    },
  },
  methods: {
    ...mapActions({
      deactivateModal: 'utils/deactivateModal',
    }),
    confirm() {
      if (this.activeModalCallbacks.onConfirm) this.activeModalCallbacks.onConfirm();
      this.deactivateModal();
    },
  },

};
</script>
