<template>
  <div class="register-main">
    <button
      type="button"
      class="register-main--selection-button"
      :class="{
        'register-main--selection-button--active': isActive,
        'register-main--selection-button--dark': dark,
        'register-main--selection-button--filter': isFilter,
      }"
      @click="clickDisabled ? '' : $emit('setValue')"
    >
      {{ $t(text) }}
      <section v-if="multiSelectionIcons" style="margin-left: 16px">
        <img
          v-if="isActive || isFilter"
          src="@/assets/svg/close-white.svg"
          alt="Close"
          style="width: 24px !important; max-width: none"
        >
        <img
          v-if="!isActive && !isFilter"
          src="@/assets/svg/add-primary-dark.svg"
          alt="Add"
          style="width: 24px"
        >
      </section>
    </button>
  </div>
</template>

<script>
export default {
  name: 'SelectionButtons',
  components: {},
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    dark: {
      type: Boolean,
      default: false,
    },
    multiSelectionIcons: {
      type: Boolean,
      default: false,
    },
    clickDisabled: {
      type: Boolean,
      default: false,
    },
    isFilter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
