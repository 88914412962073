<template>
  <div>
    <LandingPluginModal />
  </div>
</template>

<script>
import LandingPluginModal from '@/components/organisms/modals/LandingPluginModal.vue';

export default {
  name: 'SystemModals',
  components: {
    LandingPluginModal,
  },
};
</script>
