<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <RowStepper :step="3" />
      <ModalContent
        :content-sections="['smallTitle', 'subtitle', 'instructions', 'infoBox', 'link']"
        :small-title-text="'user_register.additional_info.title'"
        small-title-left
        :subtitle-text="'user_register.additional_info.interest_level'"
        :subtitle-arg="Object.keys(currentStudent).length > 0 ? currentStudent.first_name : ''"
        :instruction-text="'user_register.additional_info.gradeInstructions'"
        :info-box-text="infoBoxText"
        :link-text="'user_register.additional_info.grade_link'"
        info-box-border
        info-box-fill-width
        @toLink="$emit('toGradeSelection', { skip: false })"
      />
    </div>
    <CallToActions
      :types="['main']"
      active-value
      :main-button-text="'user_register.continue'"
      small
      :spaced="false"
      @continue="nextStep()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import { TENANT_CONFIGURATION } from '@/constants/tenant';

export default {
  name: 'GradeSuggestion',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
    RowStepper,
  },
  data() {
    return {
      infoBoxText: null,
    };
  },
  computed: {
    ...mapGetters({
      currentStudent: 'authentication/currentStudent',
      gradeRecommendation: 'institutions/gradeRecommendation',
      inDigitalEnrollment: 'digitalEnrollment/inDigitalEnrollment',
      gradeOptions: 'options/grades',
    }),
  },
  created() {
    if (this.gradeRecommendation) {
      this.infoBoxText = this.gradeRecommendation.grade_name;
    } else {
      this.$emit('toGradeSelection', { skip: true });
    }
  },
  methods: {
    ...mapActions({
      setStudentGrades: 'newStudentRegister/setStudentGrades',
      setApplicationGrade: 'digitalEnrollment/setApplicationGrade',
    }),
    nextStep() {
      let levelOptions = {
        uuid: this.currentStudent.uuid,
        grades: TENANT_CONFIGURATION.DEFAULTS.GRADES,
        // TODO: Esto es redundante en los datos, porque se podría computar en el back a partir de los grades
        // que se envían. Pero por ahora lo dejamos así para no tener que cambiar el back. (revisar c_step_2 en el back)
        stages: [],
      };
      if (this.gradeRecommendation) {
        // TODO: See how this behaves in different tenants, because in some more than one stage might show
        const stages = this.gradeOptions.find(
          ({ id }) => id === this.gradeRecommendation.id,
        ).stages.splice(0, 1);

        levelOptions = {
          uuid: this.currentStudent.uuid,
          grades: [this.gradeRecommendation.id],
          stages,
        };
      }
      this.setStudentGrades({
        gradeOptions: levelOptions,
        updateStudent: this.currentStudent.uuid,
      });
      if (this.inDigitalEnrollment) {
        this.setApplicationGrade({ grade: this.gradeRecommendation }).then(() => {
          this.$emit('nextStep');
        });
      } else {
        this.$emit('nextStep');
      }
      return null;
    },
  },
};
</script>
