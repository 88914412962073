import services from '@/services';

const getDefaultState = () => ({
  cannotSetTheFavorite: false,
  listFavoriteSchools: [],
  favoriteGuestList: [],
  loadingSchoolsDetails: true,
});

const state = getDefaultState();

const getters = {
  listFavoriteSchools: ({ listFavoriteSchools }) => listFavoriteSchools,
  loadingSchoolsDetails: ({ loadingSchoolsDetails }) => loadingSchoolsDetails,
  favoriteGuestList: ({ favoriteGuestList }) => favoriteGuestList,
  cannotSetTheFavorite: ({ cannotSetTheFavorite }) => cannotSetTheFavorite,
  getListApplications(state) {
    const listSchools = [];
    const listApplicationsFilter = [...state.listFavoriteSchools].filter(
      (school) => school.campus_admission_system[0].admission_system.id === 1,
    ) ?? [];

    listApplicationsFilter.forEach((school) => {
      const totalPrograms = school?.programs.filter(
        (program) => program?.gradetrack?.grade_label?.id === 3,
      );

      // eslint-disable-next-line no-unused-expressions, no-use-before-define
      totalPrograms.length > 0 && addSchool(school, +totalPrograms.length, totalPrograms);
    });

    function addSchool(school, total, totalPrograms) {
      if (total > 0) {
        const newSchool = {
          ...school,
          programs: totalPrograms[total - 1],
        };

        listSchools.push(newSchool);
        addSchool(school, total - 1, totalPrograms);
      }
    }
    const setListSchools = new Set(listSchools.map(JSON.stringify));
    const listSchoolsFilter = Array.from(setListSchools).map(JSON.parse);

    const finalList = listSchoolsFilter.filter(
      (school) => school.programs.admission_system_label === '1',
    );

    return finalList;
  },
};

const mutations = {
  setCannotSetTheFavorite(state, value) {
    state.cannotSetTheFavorite = value;
  },
  setListFavoriteSchools(state, data) {
    state.listFavoriteSchools = data;
  },
  resetFavorites(state) {
    state.listFavoriteSchools = [];
    state.cannotSetTheFavorite = false;
    state.loadingSchoolsDetails = true;
  },
  setLoadingSchoolsDetails(state, value) {
    state.loadingSchoolsDetails = value;
  },
  setGuestFavorite(state, favoriteCampus) {
    state.favoriteGuestList.push(favoriteCampus);
  },
  deleteGuestFavorite(state, favoriteCampusUuid) {
    const newFavList = state.favoriteGuestList.filter((campus) => campus.uuid !== favoriteCampusUuid);
    state.favoriteGuestList = newFavList;
  },
};

const actions = {
  setLoadingSchoolsDetails({ commit }, value) {
    commit('setLoadingSchoolsDetails', value);
  },
  resetFavorites({ commit }) {
    commit('resetFavorites');
  },
  setCannotSetTheFavorite({ commit }, cannotSetTheFavorite) {
    commit('setCannotSetTheFavorite', cannotSetTheFavorite);
  },
  async retrieveListFavorites(
    { dispatch, commit, rootState },
    { isInLogin = false, getDetails = false } = {},
  ) {
    const { data: listFavorites } = await services.favorites.retrieveListFavoriteSchools();

    if (isInLogin) {
      const { schools } = rootState.institutions;
      commit('setListFavoriteSchools', listFavorites);
      dispatch('institutions/updateFavoritesMarkers', { schools }, { root: true });
    } else {
      await dispatch('retrieveListFavoritesDetails', { listFavorites, getDetails });
    }
  },

  async retrieveListFavoritesDetails(
    {
      commit, dispatch, rootState, state,
    },
    { listFavorites, getDetails = false },
  ) {
    let listFavoritesDetails = [];
    const { schools } = rootState.institutions;
    const listFavoritesWithData = state.listFavoriteSchools;
    commit('setLoadingSchoolsDetails', true);

    // eslint-disable-next-line no-use-before-define
    await getSchoolDetail(listFavorites[0], 0);

    async function recursiveOptionsOfFavoriteSchools(counter) {
      if (counter !== listFavorites.length) {
        // eslint-disable-next-line no-use-before-define
        await getSchoolDetail(listFavorites[counter], counter);
      } else {
        commit('setListFavoriteSchools', listFavoritesDetails);
        commit('setLoadingSchoolsDetails', false);
        dispatch('institutions/updateFavoritesMarkers', { schools }, { root: true });
      }
    }

    async function getSchoolDetail(school, counter) {
      const hasSchoolsDetail = school !== undefined
        ? listFavoritesWithData.find(
          ({ campus_code: campusCode }) => campusCode === school.campus_code,
        )
        : 'no schools';
      const newCounter = counter + 1;
      if (hasSchoolsDetail === 'no schools') {
        listFavoritesDetails = [];
        recursiveOptionsOfFavoriteSchools(0);
      } else if (hasSchoolsDetail !== undefined && !getDetails) {
        listFavoritesDetails.push({ ...hasSchoolsDetail });
        recursiveOptionsOfFavoriteSchools(newCounter);
      } else {
        await services.elasticSearchService
          .getCampusFromKey({ key: school.campus_code })
          .then(({ data }) => {
            listFavoritesDetails.push({ ...data?.result, idFavorite: school.id } || {});
            recursiveOptionsOfFavoriteSchools(newCounter);
          })
          .catch((error) => {
            dispatch('utils/error', error, { root: true });
          });
      }
    }
  },
  async newFavorite({ dispatch }, data) {
    await services.favorites
      .addFavorite(data)
      .then(({ status }) => {
        if (status === 201) {
          dispatch('retrieveListFavorites', { isLogin: false, getDetails: false });
        }
      })
      .catch((error) => {
        dispatch('utils/error', error, { root: true });
      });
  },

  async removeFavorite({ dispatch }, idFavorite) {
    await services.favorites
      .removeFavorite(idFavorite)
      .then(() => {
        dispatch('retrieveListFavorites', { isLogin: false, getDetails: false });
      })
      .catch((error) => {
        dispatch('utils/error', error, { root: true });
      });
  },
  /**
    * Set Guest Favorite list
    * @param {Object} favoriteCampus --- Campus details for favorites list
  */
  setGuestFavorite({ commit }, { favoriteCampus }) {
    commit('setGuestFavorite', favoriteCampus);
  },
  /**
    * Remove Guest Favorite from guest favorite list
    * @param {Object} favoriteCampusUuid --- Campus details id to remove favorites from list
  */
  deleteGuestFavorite({ commit }, { favoriteCampusUuid }) {
    commit('deleteGuestFavorite', favoriteCampusUuid);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
