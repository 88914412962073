import { backendAPI, userApi } from '@/api';

const prechecksURL = 'prechecks/check_user';
const prechecksRoles = 'registration/user_roles';
const prechecksLegalGuardian = 'registration/prechecks/check_user';

export default {
  preCheckUser({ preCheck }) {
    return userApi.post(`${prechecksURL}/`, {
      username: preCheck.username,
      password: preCheck.password,
    });
  },
  preCheckRoles() {
    return backendAPI.get(`${prechecksRoles}/`);
  },
  preCheckLegalGuardian() {
    return backendAPI.post(`${prechecksLegalGuardian}/`);
  },
};
