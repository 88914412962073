<template>
  <div>
    <WaitingList
      v-if="inStep(1)"
      class="waiting-list-flow--page"
      :program-id="programId"
      :campus-name="campusDetail.campus_name"
      :show-current-student="showCurrentStudent"
      :position="position"
      :shift-vacancy="shiftWaitingList"
      @back="backWaitingList"
      @next="nextStepList"
      @close="showCampus"
      @updateShift="updateShift"
      @updateGradeLabelId="updateGradeLabelId"
    />
    <WaitingListSchool
      v-if="inStep(2) || inStep(3)"
      class="waiting-list-flow--page"
      :shift="shiftWaitingList"
      @back="backListSchool"
      @next="nextStepLogIn"
      @close="showCampus"
    />
    <v-dialog
      v-if="inStep(3)"
      v-model="showLogInModal"
      transition="scroll-x-reverse-transition"
      content-class="waiting-list-flow--dialog"
      overlay-color="black"
    >
      <LogInModal
        @close="backStep() && (showLogInModal = false) && (stepRegister = 1)"
        @next="nextStepRegister"
      />
    </v-dialog>
    <WaitingListRegister
      v-if="inStep(4)"
      class="waiting-list-flow--page"
      :step="stepRegister"
      @back="toStep(2)"
      @next="nextStepSummary"
      @close="showCampus"
    />
    <WaitingListSummary
      v-if="inStep(5) || inStep(6)"
      class="waiting-list-flow--page"
      :shift="shiftWaitingList"
      :grade-label-id="gradeLabelId"
      @back="toStep(4)"
      @next="nextStepConfirm"
      @close="showCampus"
    />
    <v-dialog
      v-if="inStep(6)"
      v-model="showConfirmModal"
      transition="scroll-x-reverse-transition"
      content-class="waiting-list-flow--dialog--summary"
      overlay-color="black"
    >
      <WaitingListConfirm
        :shift="shiftWaitingList"
        :in-waiting-list="inWaitingList"
        :program-id="programId"
        :waiting-list-type="waitingListType"
        @next="nextStepWaitingList"
        @close="backStep() && (showConfirmModal = false)"
        @showCampus="showCampus"
      />
    </v-dialog>
  </div>
</template>

<script>
import WaitingList from '@/components/organisms/digital_enrollment/WaitingList.vue';
import { mapActions, mapGetters } from 'vuex';
import LogInModal from '../organisms/digital_enrollment/LogInModal.vue';
import WaitingListConfirm from '../organisms/digital_enrollment/WaitingListConfirm.vue';
import WaitingListSchool from '../organisms/digital_enrollment/WaitingListSchool.vue';
import WaitingListSummary from '../organisms/digital_enrollment/WaitingListSummary.vue';
import WaitingListRegister from './WaitingListRegister.vue';

export default {
  name: 'WaitingListFlow',
  components: {
    WaitingList,
    WaitingListSchool,
    LogInModal,
    WaitingListSummary,
    WaitingListConfirm,
    WaitingListRegister,
  },
  props: {
    programId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      stepStack: [],
      showLogInModal: false,
      showConfirmModal: false,
      gradeLabelId: 0,
      shift: {},
      position: 0,
      stepRegister: 2,
      inWaitingList: false,
      showCurrentStudent: false,
      fromProfile: false,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'institutions/campusDetails',
      isGuest: 'authentication/isGuest',
      shiftWaitingList: 'institutions/shiftWaitingList',
    }),
    currentStep() {
      return this.stepStack[this.stepStack.length - 1];
    },
    waitingListType() {
      return this.campusDetail?.config_campus[0].waiting_list_type;
    },
  },
  watch: {
    'campusDetail.config_campus': {
      handler() {
        this.setInitialWaitingListStep();
      },
      deep: true,
    },
  },
  mounted() {
    this.fromProfile = this.$route.query.fromProfile;
    this.showCurrentStudent = false;
    this.position = 0;
    this.setInitialWaitingListStep();
  },
  methods: {
    ...mapActions({
      setShowCampusDetail: 'institutions/setShowCampusDetail',
      setShowWaitingList: 'institutions/setShowWaitingList',
    }),
    inStep(...steps) {
      return steps.includes(this.currentStep);
    },
    nextStepList() {
      if (this.position === 0) {
        this.toStep(2);
      } else {
        this.$emit('closeWaiting');
      }
    },
    setInitialWaitingListStep() {
      if (this.waitingListType !== 'PUBLIC' && this.fromProfile) {
        this.stepStack.push(2);
      } else {
        this.stepStack.push(1);
      }
    },
    backListSchool() {
      if (this.waitingListType !== 'PUBLIC' && this.fromProfile) {
        this.showCampus();
      } else {
        this.toStep(1);
      }
    },
    nextStepLogIn() {
      this.stepRegister = 4;
      if (this.isGuest) {
        this.showLogInModal = true;
        this.toStep(3);
      } else {
        this.toStep(4);
      }
    },
    nextStepRegister(i) {
      this.stepRegister = i;
      this.toStep(4);
    },
    nextStepSummary(i) {
      this.stepRegister = i;
      this.toStep(5);
    },
    nextStepConfirm(isInList) {
      this.showConfirmModal = true;
      this.inWaitingList = isInList;
      this.toStep(6);
    },
    nextStepWaitingList(position) {
      this.position = position;
      this.showCurrentStudent = true;
      this.toStep(1);
    },
    backStep() {
      this.stepStack.pop();
    },
    updateShift(shift) {
      this.shift = shift;
    },
    updateGradeLabelId(gradeLabelId) {
      this.gradeLabelId = gradeLabelId;
    },
    toStep(step, { skipCurrent = false } = {}) {
      // Jump to a specific step. Do not use to reset the process, use restartProcess instead.
      // If skipCurrent is true, the current step will be skipped in the historial
      if (skipCurrent) {
        this.stepStack = [...this.stepStack.slice(0, -1), step];
      } else {
        this.stepStack.push(step);
      }
    },
    backWaitingList() {
      if (this.showCurrentStudent) {
        this.showCurrentStudent = false;
        this.toStep(5);
      } else {
        this.showCampus();
      }
    },
    showCampus() {
      this.showCurrentStudent = false;
      this.$router.push({
        name: 'ExplorerSchool',
        params: {
          code: this.campusDetail.campus_code,
        },
      });
      this.setShowCampusDetail({ uuid: this.campusDetail.uuid });
      this.stepStack = [1];
      this.position = 0;
    },
  },
};
</script>
