<template>
  <div
    class="search-location"
    :style="{ '--height': height, '--height-mobile': heightMobile }"
    :class="{
      'search-location--mobile': isMobile && isInMap,
      'search-location--register': !isInMap && !isMobile,
      'search-location--mobile-register': !isInMap && isMobile,
    }"
  >
    <Actions
      v-if="!isInMap"
      :mixpanel-tag="'click_reg_register-map-adjust-if-necessary_back-button'"
      absolute
      @back="back()"
    />
    <GmapMap
      ref="locationMapRef"
      style="height: 100%"
      :style="!isInMap ? 'position: absolute' : ''"
      class="search-location--map"
      :center="mapCenter"
      :options="{
        zoom: inWelcome ? 13.5 : 16,
        disableDefaultUI: true,
        clickableIcons: false,
        draggableIcons: false,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        scaleControl: false,
        rotateControl: false,
        styles: mapStyle,
      }"
    >
      <GmapCircle :center="circleCenter" :radius="radius" :options="options" />
      <GmapMarker
        :position="markerCenter"
        clickable
        :draggable="isDraggable"
        :icon="{
          url: require('@/assets/svg/customMarker.svg'),
          scaledSize: { width: 40, height: 40 },
        }"
        @click="markerClickTrack()"
        @dragend="setPlace($event, true)"
      />
    </GmapMap>
    <MapTitle
      v-if="!isInMap"
      :text="'shared.location.title'"
      :mixpanel-tag="'click_reg_register-map-adjust-if-necessary_helper-text'"
    />
    <MapTitle
      v-if="inWelcome"
      :color="researchLocaleKeyRoot.includes('research-cl') ? '#62C7D3' : '#5627FF'"
      :text="`shared.location.title2.${researchLocaleKeyRoot}`"
      :mixpanel-tag="'click_reg_register-map-adjust-if-necessary_helper-text'"
    />
    <div
      v-if="inWelcome"
      class="absolute right-10 top-10 flex flex-col space-y-2"
      style="z-index: 51;"
    >
      <v-btn
        depressed
        type="button"
        @click="goToDeviceLocation()"
      >
        <img src="@/assets/icons/my_location.svg" width="27px" alt="location" />
      </v-btn>
      <!-- <v-btn
        v-if="pinMoved"
        depressed
        type="button"
        @click="resetLocation()"
      >
        <img src="@/assets/icons/undo.svg" width="27px" alt="undo" />
      </v-btn> -->
    </div>
    <CenterMap v-if="!isInMap" @setCenter="setCenter()" />
    <CallToActions
      v-if="!isInMap"
      :types="['main']"
      active-value
      :main-button-text="'user_register.continue'"
      :main-button-tag="'click_reg_register-map-adjust-if-necessary_center-location'"
      small
      is-main-absolute
      @continue="nextStep()"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import mapStyle from '@/assets/mapStyle.json';
import CenterMap from '@/components/atoms/buttons/CenterMap.vue';
import Actions from '@/components/atoms/headers/Actions.vue';
import MapTitle from '@/components/atoms/titles/MapTitle.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'SearchLocation',
  components: {
    Actions,
    MapTitle,
    CenterMap,
    CallToActions,
  },
  props: {
    isInMap: {
      type: Boolean,
      default: false,
    },
    commune: {
      type: String,
      default: '',
    },
    userLocation: {
      type: Object,
      default: null,
    },
    locationFor: {
      type: String,
      default: '',
    },
    inStudentFlow: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '428px',
    },
    heightMobile: {
      type: String,
      default: '24rem',
    },
    inWelcome: {
      type: Boolean,
      default: false,
    },
    isDraggable: {
      type: Boolean,
      default: true,
    },
    researchLocaleKeyRoot: {
      type: String,
      default: null,
    },
  },
  data() {
    const { coordinates: defaultCoordinates } = TENANT_CONFIGURATION.DEFAULTS.LOCATION;
    return {
      mapStyle,
      defaultCoordinates,
      markerCenter: defaultCoordinates,
      mapCenter: defaultCoordinates,
      circleCenter: defaultCoordinates,
      selectedLocation: null,
      clickMarkerMixpanelTag: null,
      dargMarkerMixpanelTag: null,
      radius: 300,
      options: {
        strokeColor: '#E0E0E0',
        strokeOpacity: 1,
        strokeWeight: 1,
        fillColor: '#FFFFFF',
        fillOpacity: 0.35,
      },
      finalLat: null,
      finalLng: null,
      locationT: '',
      initialLat: null,
      initialLng: null,
    };
  },
  computed: {
    ...mapGetters({
      municipalities: 'options/municipalities',
      isGuest: 'authentication/isGuest',
      currentStudent: 'authentication/currentStudent',
      legalGuardianUUID: 'authentication/legalGuardianUUID',
      location: 'userRegister/location',
      locationType: 'userRegister/locationType',
      place: 'userRegister/place',
      initialCoordinates: 'userRegister/initialCoordinates',
      pinMoved: 'userRegister/pinMoved',
    }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    this.clickMarkerMixpanelTag = 'click-map-adjust-if-necessary_location-marker';
    this.dargMarkerMixpanelTag = 'drag-map-adjust-if-necessary_location-marker';
    if (!this.isInMap) {
      this.clickMarkerMixpanelTag = 'click_reg_register-map-adjust-if-necessary_location-marker';
      this.dargMarkerMixpanelTag = 'drag_reg_register-map-adjust-if-necessary_location-marker';
    }
    this.resetLocation();
  },
  methods: {
    ...mapActions({
      setFastRegisterLocation: 'userRegister/setFastRegisterLocation',
      setStudentLocation: 'newStudentRegister/setStudentLocation',
      sendGeocodingHistory: 'geoTools/sendGeocodingHistory',
      setFinalLocationAddress: 'userRegister/setFinalLocationAddress',
      setPinMoved: 'userRegister/setPinMoved',
    }),
    nextStep() {
      if (!this.pinMoved && this.location?.final_address?.formatted_address === null) {
        this.finalLat = this.place.lat;
        this.finalLng = this.place.lng;
      } else if (this.location.final_address?.lat !== '') {
        this.finalLat = this.location?.final_address.lat;
        this.finalLng = this.location?.final_address.lng;
      } else {
        this.finalLat = this.initialCoordinates.lat;
        this.finalLng = this.initialCoordinates.lng;
      }
      let location = {};
      let addressDetails;
      if (!this.isGuest && this.locationFor !== 'student' && !this.inStudentFlow) {
        if (this.location?.final_address?.formatted_address !== null) {
          addressDetails = this.location.final_address.formatted_address;
        } else if (this.location?.address_searched) {
          addressDetails = this.location.address_searched;
        } else {
          addressDetails = '';
        }
        location = {
          uuid: this.legalGuardianUUID,
          address_details: addressDetails,
          address_name: null,
          address_number: this.location?.final_address?.street_number !== null
            ? this.location?.final_address?.street_number
            : null,
          address_street: this.location?.final_address?.street_name !== null
            ? this.location?.final_address?.street_name : null,
          address_lat: this.finalLat,
          address_lon: this.finalLng,
          location: this.place.display1,
        };
        this.sendGeoHistory();
        this.setFastRegisterLocation({ location });
      } else {
        addressDetails = this.location?.final_address?.formatted_address !== null
          ? this.location?.final_address?.formatted_address
          : '';
        location = {
          uuid: this.currentStudent.uuid,
          address_name: null,
          address_details: addressDetails,
          address_number: addressDetails,
          address_street: addressDetails,
          address_lat: this.finalLat,
          address_lon: this.finalLng,
          location: this.commune,
        };
        this.sendGeoHistory();
        this.setStudentLocation({ location, updateStudent: true });
      }
      return this.$emit('nextStep');
    },
    back() {
      this.setPinMoved(false);
      return this.$emit('backStep');
    },
    setLocation(coordinates, address, commune) {
      let stringAddress = 'Selecciona tu ubicación';
      if (address) {
        stringAddress = address;
      }
      const location = {
        coordinates,
        address_details: stringAddress,
        zipcode: '0000',
        address_name: null,
        municipalityName: commune,
      };
      this.selectedLocation = location;
    },
    sendGeoHistory() {
      if (!this.pinMoved && this.location?.final_address?.formatted_address === null) {
        this.finalLat = this.place.lat;
        this.finalLng = this.place.lng;
      } else if (this.location.final_address?.lat !== '') {
        this.finalLat = this.location?.final_address.lat;
        this.finalLng = this.location?.final_address.lng;
      } else {
        this.finalLat = this.initialCoordinates.lat;
        this.finalLng = this.initialCoordinates.lng;
      }
      if (this.locationType === 1) {
        this.locationT = 'use_my_location';
      } else if (this.locationType === 2) {
        this.locationT = 'write_address';
      } else if (this.locationType === 3) {
        this.locationT = 'locate_in_map';
      }
      this.initialLat = this.initialCoordinates.lat;
      this.initialLng = this.initialCoordinates.lng;

      const geoInfo = {
        user: this.legalGuardianUUID || null,
        geotools_code_national: this.location?.code_national,
        final_lat: this.finalLat,
        final_lng: this.finalLng,
        used_text_first: this.locationType === 2,
        method: this.locationT,
        geotools_lat: this.initialLat,
        geotools_lng: this.initialLng,
        geotools_geocodification_type: this.location?.final_address?.geocodification_type !== '' ? this.location?.final_address?.geocodification_type : null,
        geotools_address: this.location?.final_address?.formatted_address !== '' ? this.location?.final_address?.formatted_address : '',
        user_text_input: this.location?.address_searched ?? null,
      };
      this.sendGeocodingHistory({ geoInfo });
    },
    goToDeviceLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const userLocation = {
            coordinates: { lat: position.coords.latitude, lng: position.coords.longitude },
            addressDetails: null,
            municipalityName: null,
            address_name: null,
          };
          this.setFinalLocationAddress({
            address: {
              formatted_address: null,
              geocodification_type: null,
              lat: position.coords.latitude,
              lng: position.coords.longitude,
              source: null,
              upper_location: null,
              address_number: null,
              address_street: null,
            },
          });
          this.$emit('setUserLocation', userLocation);
          this.mapCenter = { lat: position.coords.latitude, lng: position.coords.longitude };
          this.circleCenter = { lat: position.coords.latitude, lng: position.coords.longitude };
          this.markerCenter = { lat: position.coords.latitude, lng: position.coords.longitude };
        });
      }
    },
    resetLocation() {
      if (this.location) {
        const addressDetails = this.location.formatted_address;
        let coordinates;
        if (this.location.final_address.lat !== null && this.location.final_address.lng !== null) {
          coordinates = {
            lat: this.location.final_address.lat,
            lng: this.location.final_address.lng,
          };
        } else {
          coordinates = {
            lat: this.place.lat,
            lng: this.place.lng,
          };
        }
        const municipalityName = this.place.display1;
        this.markerCenter = coordinates;
        this.circleCenter = coordinates;
        this.mapCenter = coordinates;
        document.activeElement.blur();
        this.setLocation(coordinates, addressDetails, municipalityName);
      } else if (this.commune) {
        const selectedCommune = this.municipalities.find(
          (municipality) => municipality.name === this.commune,
        );
        this.mapCenter = {
          lat: selectedCommune.default_coordinate.lat,
          lng: selectedCommune.default_coordinate.lon,
        };
        this.circleCenter = {
          lat: selectedCommune.default_coordinate.lat,
          lng: selectedCommune.default_coordinate.lon,
        };
        this.markerCenter = {
          lat: selectedCommune.default_coordinate.lat,
          lng: selectedCommune.default_coordinate.lon,
        };
        document.activeElement.blur();
        this.setLocation(
          {
            lat: selectedCommune.default_coordinate.lat,
            lng: selectedCommune.default_coordinate.lon,
          },
          'Selecciona tu ubicación',
          this.commune,
        );
      } else {
        this.circleCenter = this.defaultCoordinates;
        this.mapCenter = this.defaultCoordinates;
        this.markerCenter = this.defaultCoordinates;
      }
    },
    setCenter() {
      trackMixPanel('click_reg_register-map-adjust-if-necessary_center-location');
      this.$refs.locationMapRef.$mapPromise.then((map) => {
        if (this.userLocation) {
          if (Object.keys(this.userLocation).length > 0) {
            map.setCenter({ lat: this.userLocation.lat, lng: this.userLocation.lng });
          }
        }
        if (this.commune) {
          const selectedCommune = this.municipalities.find(
            (municipality) => municipality.name === this.commune,
          );
          map.setCenter({
            lat: selectedCommune.default_coordinate.lat,
            lng: selectedCommune.default_coordinate.lon,
          });
        }
      });
    },
    markerClickTrack() {
      trackMixPanel(this.clickMarkerMixpanelTag);
    },
    /* eslint-disable */
    async setPlace(place, isSetInDrag) {
      this.setPinMoved({ pinMoved: true });
      this.circleCenter = place.latLng.toJSON();
      trackMixPanel(this.dargMarkerMixpanelTag);
      // let location;
      // let country;
      if (isSetInDrag) {
        const value = {
          formatted_address: this.location.final_address.formatted_address,
          geocodification_type: this.location.final_address.geocodification_type,
          lat: place.latLng.lat(),
          lng: place.latLng.lng(),
          source: this.location.final_address.source,
          upper_location: this.location.final_address.upper_location,
          address_number: this.location?.final_address?.street_number !== null
            ? this.location?.final_address?.street_number
            : null,
          address_street: this.location?.final_address?.street_name !== null
            ? this.location?.final_address?.street_name : null,
        };
        const userLocation = {
          coordinates: { lat: place.latLng.lat(), lng: place.latLng.lng() },
          addressDetails: null,
          municipalityName: this.place.display1,
          address_name: null,
        };

        this.setFinalLocationAddress({ address: value });
        this.$emit('setUserLocation', userLocation);
        // Update the map center with the new coordinates
        this.mapCenter = place.latLng.toJSON();
      }
    },
    /* eslint-disable */
  },
};
</script>
