<template>
  <div class="register-main register-main--spaced">
    <div>
      <HeaderModal :content-sections="['close']" @close="$emit('close')" />
      <ContentModal
        :content-sections="['icon']"
        :icon-color="'#5627FF'"
        :icon-name="'mdi-alert-outline'"
        :icon-type="'warning'"
      />
      <ContentModal
        :content-sections="['subtitle']"
        subtitle-center
        :subtitle-text="textInfo.content"
        subtitle-light
      />
    </div>
    <CallToActions
      :option-text1="textInfo.btn1"
      :option-text2="textInfo.btn2"
      :types="['option']"
      :spaced="false"
      option-btn-no-margin
      @nextStep="nextStep"
    />
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ContentModal from '@/components/molecules/modals/Content.vue';
import HeaderModal from '@/components/molecules/modals/Header.vue';
import { mapActions } from 'vuex';

export default {
  name: 'ListWarningModal',
  components: {
    HeaderModal,
    CallToActions,
    ContentModal,
  },
  props: {
    textInfo: {
      type: Object,
      default: null,
    },
  },
  methods: {
    ...mapActions({
      setForcedStep: 'userRegister/setForcedStep',
    }),
    nextStep(i) {
      if (i === 1) {
        this.setForcedStep({ step: 0 }).then(() => {
          this.$router.push('/login');
        });
        return;
      }
      this.setForcedStep({ step: 2 }).then(() => {
        this.$router.push('/login');
      });
    },
  },
};
</script>
