import services from '@/services';

const getDefaultState = () => ({
  uuid: '',
  // Flow chart
  currentStep: 1,
  currentSubSteps: [1, 1, 1], // identificacion, location, priority
  totalSubSteps: [9, 2, 7], // identificacion, location, priority
  totalSubStepsComplete: [0, 0, 0], // identification, location, priority
  // Identification
  nickname: '',
  grade: null,
  day: null,
  month: null,
  year: null,
  showIdNumberModal: null,
  idNumberResponse: null,
  userRelationship: {},
  otherRelationship: '',
  userDocumentMother: {},
  userDocumentFather: {},
  userNDocumentMother: '',
  userNDocumentFather: '',
  firstNameFather: '',
  secondNameFather: '',
  fatherLastNameFather: '',
  motherLastNameFather: '',
  firstNameMother: '',
  secondNameMother: '',
  fatherLastNameMother: '',
  motherLastNameMother: '',
  liveWithLegalguardian: null,
  document: {},
  nDocument: '',
  file: [],
  firstName: '',
  secondName: '',
  fatherLastName: '',
  motherLastName: '',
  gender: null,
  birthplace: {},
  // location
  sameAddress: null,
  address: {},
  // Priority
  publicInstitutionPriority: null,
  siblings: [],
  cdiOrIcbfPriority: null,
  victimOfArmedConflictPriority: null,
  victimOfArmedConflictCategory: null,
  disabilityPriority: null,
  disabilityCategory: null,
  disabilityFile: [],
  ethnicity: null,
  loading: false,
  idInfo: null,
  hasIcbf: null,
  brothersInfo: null,
});

const state = {
  ...getDefaultState(),
};

const getters = {
  uuid: ({ uuid }) => uuid,
  showIdNumberModal: ({ showIdNumberModal }) => showIdNumberModal,
  idNumberResponse: ({ idNumberResponse }) => idNumberResponse,
  // Flow chart
  currentStep: ({ currentStep }) => currentStep,
  currentSubStep: ({ currentStep, currentSubSteps }) => currentSubSteps[currentStep - 1],
  currentSubSteps: ({ currentSubSteps }) => currentSubSteps,
  totalSubSteps: ({ totalSubSteps }) => totalSubSteps,
  totalSubStepsComplete: ({ totalSubStepsComplete }) => totalSubStepsComplete,
  // identification
  nickname: ({ nickname }) => nickname,
  grade: ({ grade }) => grade,
  day: ({ day }) => day,
  month: ({ month }) => month,
  year: ({ year }) => year,
  otherRelationship: ({ otherRelationship }) => otherRelationship,
  userRelationship: ({ userRelationship }) => userRelationship,
  userDocumentMother: ({ userDocumentMother }) => userDocumentMother,
  userDocumentFather: ({ userDocumentFather }) => userDocumentFather,
  userNDocumentMother: ({ userNDocumentMother }) => userNDocumentMother,
  userNDocumentFather: ({ userNDocumentFather }) => userNDocumentFather,
  firstNameFather: ({ firstNameFather }) => firstNameFather,
  secondNameFather: ({ secondNameFather }) => secondNameFather,
  fatherLastNameFather: ({ fatherLastNameFather }) => fatherLastNameFather,
  motherLastNameFather: ({ motherLastNameFather }) => motherLastNameFather,
  firstNameMother: ({ firstNameMother }) => firstNameMother,
  secondNameMother: ({ secondNameMother }) => secondNameMother,
  fatherLastNameMother: ({ fatherLastNameMother }) => fatherLastNameMother,
  motherLastNameMother: ({ motherLastNameMother }) => motherLastNameMother,
  liveWithLegalguardian: ({ liveWithLegalguardian }) => liveWithLegalguardian,
  document: ({ document }) => document,
  nDocument: ({ nDocument }) => nDocument,
  file: ({ file }) => file,
  firstName: ({ firstName }) => firstName,
  secondName: ({ secondName }) => secondName,
  fatherLastName: ({ fatherLastName }) => fatherLastName,
  motherLastName: ({ motherLastName }) => motherLastName,
  gender: ({ gender }) => gender,
  birthplace: ({ birthplace }) => birthplace,
  // location
  sameAddress: ({ sameAddress }) => sameAddress,
  address: ({ address }) => address,
  // priority
  publicInstitutionPriority: ({ publicInstitutionPriority }) => publicInstitutionPriority,
  siblings: ({ siblings }) => siblings,
  cdiOrIcbfPriority: ({ cdiOrIcbfPriority }) => cdiOrIcbfPriority,
  victimOfArmedConflictPriority: ({ victimOfArmedConflictPriority }) => victimOfArmedConflictPriority,
  victimOfArmedConflictCategory: ({ victimOfArmedConflictCategory }) => victimOfArmedConflictCategory,
  disabilityPriority: ({ disabilityPriority }) => disabilityPriority,
  disabilityCategory: ({ disabilityCategory }) => disabilityCategory,
  disabilityFile: ({ disabilityFile }) => disabilityFile,
  ethnicity: ({ ethnicity }) => ethnicity,
  loading: ({ loading }) => loading,
  hasIcbf: ({ hasIcbf }) => hasIcbf,
};

const mutations = {
  resetStore(state) {
    Object.assign(state, getDefaultState());
  },
  setUuid(state, uuid) {
    state.uuid = uuid;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setShowIdNumberModal(state, response) {
    state.showIdNumberModal = response;
  },
  setIdNumberResponse(state, data) {
    state.idNumberResponse = data;
  },
  // Flow chart
  setCurrentStep(state, step) {
    state.currentStep = step;
  },
  setCurrentSubSteps(state, value) {
    const { step, subStep } = value;
    state.currentSubSteps[step - 1] = subStep;
  },
  setTotalSubStepsComplete(state, value) {
    const { step, subStep } = value;
    state.totalSubStepsComplete[step - 1] = subStep;
  },
  // identification
  setDocument(state, document) {
    state.document = document;
  },
  setUserRelationship(state, relationship) {
    state.userRelationship = relationship;
  },
  setNickname(state, nickname) {
    state.nickname = nickname;
  },
  setGrade(state, grade) {
    state.grade = grade;
  },
  setDay(state, day) {
    state.day = day;
  },
  setMonth(state, month) {
    state.month = month;
  },
  setYear(state, year) {
    state.year = year;
  },
  setOtherRelationship(state, otherRelationship) {
    state.otherRelationship = otherRelationship;
  },
  setUserNDocumentMother(state, userNDocument) {
    state.userNDocumentMother = userNDocument;
  },
  setUserNDocumentFather(state, userNDocument) {
    state.userNDocumentFather = userNDocument;
  },
  setLiveWithLegalguardian(state, liveWithLegalguardian) {
    state.liveWithLegalguardian = liveWithLegalguardian;
  },
  setUserDocumentMother(state, userDocument) {
    state.userDocumentMother = userDocument;
  },
  setUserDocumentFather(state, userDocument) {
    state.userDocumentFather = userDocument;
  },
  setFirstNameFather(state, value) {
    state.firstNameFather = value;
  },
  setSecondNameFather(state, value) {
    state.secondNameFather = value;
  },
  setFatherLastNameFather(state, value) {
    state.fatherLastNameFather = value;
  },
  setMotherLastNameFather(state, value) {
    state.motherLastNameFather = value;
  },
  setFirstNameMother(state, value) {
    state.firstNameMother = value;
  },
  setSecondNameMother(state, value) {
    state.secondNameMother = value;
  },
  setFatherLastNameMother(state, value) {
    state.fatherLastNameMother = value;
  },
  setMotherLastNameMother(state, value) {
    state.motherLastNameMother = value;
  },
  setNDocument(state, nDocument) {
    state.nDocument = nDocument;
  },
  setFile(state, file) {
    state.file = file;
  },
  setFirstName(state, firstName) {
    state.firstName = firstName;
  },
  setSecondName(state, secondName) {
    state.secondName = secondName;
  },
  setFatherLastName(state, fatherLastName) {
    state.fatherLastName = fatherLastName;
  },
  setMotherLastName(state, motherLastName) {
    state.motherLastName = motherLastName;
  },
  setGender(state, gender) {
    state.gender = gender;
  },
  setBirthplace(state, birthplace) {
    state.birthplace = birthplace;
  },
  // location
  setSameAddress(state, sameAddress) {
    state.sameAddress = sameAddress;
  },
  setAddress(state, address) {
    state.address = address;
  },
  // priority
  setPublicInstitutionPriority(state, info) {
    state.publicInstitutionPriority = info;
  },
  setNewSibling(state, student) {
    state.siblings.push(student);
  },
  removeSibling(state, id) {
    state.siblings.splice(id, 1);
  },
  setCdiOrIcbfPriority(state, info) {
    state.cdiOrIcbfPriority = info;
  },
  setVictimOfArmedConflictPriority(state, info) {
    state.victimOfArmedConflictPriority = info;
  },
  setVictimOfArmedConflictCategory(state, info) {
    state.victimOfArmedConflictCategory = info;
  },
  setDisabilityPriority(state, info) {
    state.disabilityPriority = info;
  },
  setDisabilityCategory(state, info) {
    state.disabilityCategory = info;
  },
  setIdInfo(state, { idInfo }) {
    state.idInfo = idInfo;
  },
  setDisabilityFile(state, info) {
    state.disabilityFile = info;
  },
  setEthnicity(state, info) {
    state.ethnicity = info;
  },
  setHasIcbf(state, bool) {
    state.hasIcbf = bool;
  },
  setBrothersInfo(state, brothersInfo) {
    state.brothersInfo = brothersInfo;
  },
  setStudentData(state, data) {
    state.uuid = data.uuid;
    state.currentStep = data.last_step_complete;
    state.currentSubSteps[data.last_step_complete - 1] = data.last_substep_complete;
    state.totalSubStepsComplete[data.last_step_complete - 1] = data.last_substep_complete - 1;
    if (data.last_step_complete > 1) {
      for (let i = 0; i < data.last_step_complete - 1; i += 1) {
        state.totalSubStepsComplete[i] = state.totalSubSteps[i];
      }
    }
    state.nickname = data.personalized_name;
    state.grade = data.grade;
    state.day = data.birth_date ? parseInt(data.birth_date.split('-')[2], 10) : null;
    state.month = data.birth_date ? parseInt(data.birth_date.split('-')[1], 10) : null;
    state.year = data.birth_date ? parseInt(data.birth_date.split('-')[0], 10) : null;
    state.userRelationship = data.relationship || {};
    state.otherRelationship = data.relationship_other || '';
    state.userDocumentMother = data.mother_identification_type || {};
    state.userDocumentFather = data.father_identification_type || {};
  },
};

const actions = {
  resetStore({ commit }, { callback = undefined }) {
    commit('resetStore');
    if (callback) {
      callback();
    }
  },
  setShowIdNumberModal({ commit }, data) {
    commit('setShowIdNumberModal', data);
  },
  setUuid({ commit }, uuid) {
    commit('setUuid', uuid);
  },
  // Flow chart
  setCurrentStep({ commit }, step) {
    commit('setCurrentStep', step);
  },
  setCurrentSubSteps({ commit }, { step, subStep }) {
    commit('setCurrentSubSteps', { step, subStep });
  },
  setTotalSubStepsComplete({ commit }, { step, subStep }) {
    commit('setTotalSubStepsComplete', { step, subStep });
  },
  // identification
  setDocument({ commit }, document) {
    commit('setDocument', document);
  },
  setUserRelationship({ commit }, relationship) {
    commit('setUserRelationship', relationship);
  },
  setNickname({ commit }, nickname) {
    commit('setNickname', nickname);
  },
  setGrade({ commit }, grade) {
    commit('setGrade', grade);
  },
  setDay({ commit }, day) {
    commit('setDay', day);
  },
  setMonth({ commit }, month) {
    commit('setMonth', month);
  },
  setYear({ commit }, year) {
    commit('setYear', year);
  },
  setOtherRelationship({ commit }, otherRelationship) {
    commit('setOtherRelationship', otherRelationship);
  },
  setUserNDocumentMother({ commit }, userNDocument) {
    commit('setUserNDocumentMother', userNDocument);
  },
  setUserNDocumentFather({ commit }, userNDocument) {
    commit('setUserNDocumentFather', userNDocument);
  },
  setLiveWithLegalguardian({ commit }, liveWithLegalguardian) {
    commit('setLiveWithLegalguardian', liveWithLegalguardian);
  },
  setUserDocumentMother({ commit }, userDocument) {
    commit('setUserDocumentMother', userDocument);
  },
  setUserDocumentFather({ commit }, userDocument) {
    commit('setUserDocumentFather', userDocument);
  },
  setFirstNameFather({ commit }, value) {
    commit('setFirstNameFather', value);
  },
  setSecondNameFather({ commit }, value) {
    commit('setSecondNameFather', value);
  },
  setFatherLastNameFather({ commit }, value) {
    commit('setFatherLastNameFather', value);
  },
  setMotherLastNameFather({ commit }, value) {
    commit('setMotherLastNameFather', value);
  },
  setFirstNameMother({ commit }, value) {
    commit('setFirstNameMother', value);
  },
  setSecondNameMother({ commit }, value) {
    commit('setSecondNameMother', value);
  },
  setFatherLastNameMother({ commit }, value) {
    commit('setFatherLastNameMother', value);
  },
  setMotherLastNameMother({ commit }, value) {
    commit('setMotherLastNameMother', value);
  },
  setNDocument({ commit }, nDocument) {
    commit('setNDocument', nDocument);
  },
  setFile({ commit }, file) {
    commit('setFile', file);
  },
  setFirstName({ commit }, firstName) {
    commit('setFirstName', firstName);
  },
  setSecondName({ commit }, secondName) {
    commit('setSecondName', secondName);
  },
  setFatherLastName({ commit }, fatherLastName) {
    commit('setFatherLastName', fatherLastName);
  },
  setMotherLastName({ commit }, motherLastName) {
    commit('setMotherLastName', motherLastName);
  },
  setGender({ commit }, gender) {
    commit('setGender', gender);
  },
  setBirthplace({ commit }, birthplace) {
    commit('setBirthplace', birthplace);
  },
  setHasIcbf({ commit }, bool) {
    commit('setHasIcbf', bool);
  },
  async setIdentificationStepOne({ commit, getters, dispatch }, data) {
    const { nickname } = data;
    commit('setNickname', nickname);
    const { uuid } = getters;
    const requestData = {
      personalized_name: nickname,
    };
    if (uuid !== '') {
      requestData.uuid = uuid;
    }

    await services.studentRegistrationService
      .patchStep1(requestData)
      .then((response) => {
        dispatch('setUuid', response.data.uuid);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async setIdentificationStepTwo({ commit }, data) {
    const { relationship, other, uuid } = data;
    commit('setUserRelationship', relationship);
    commit('setOtherRelationship', other);
    const requestData = {
      relationship_id: relationship.id,
      relationship_other: other,
      uuid,
    };

    await services.studentRegistrationService.patchStep2(requestData).catch((error) => {
      console.error(error);
    });
  },
  async setIdentificationStepThree({ commit, getters }, data) {
    const {
      nDocumentMother,
      nDocumentFather,
      documentMother,
      documentFather,
      firstNameFather,
      secondNameFather,
      fatherLastNameFather,
      motherLastNameFather,
      firstNameMother,
      secondNameMother,
      fatherLastNameMother,
      motherLastNameMother,
    } = data;
    commit('setUserNDocumentMother', nDocumentMother);
    commit('setUserNDocumentFather', nDocumentFather);
    commit('setUserDocumentMother', documentMother);
    commit('setUserDocumentFather', documentFather);
    commit('setFirstNameFather', firstNameFather);
    commit('setSecondNameFather', secondNameFather);
    commit('setFatherLastNameFather', fatherLastNameFather);
    commit('setMotherLastNameFather', motherLastNameFather);
    commit('setFirstNameMother', firstNameMother);
    commit('setSecondNameMother', secondNameMother);
    commit('setFatherLastNameMother', fatherLastNameMother);
    commit('setMotherLastNameMother', motherLastNameMother);
    const { uuid } = getters;
    const requestData = {
      mother_nationality: documentMother.nationality.id,
      mother_identification_type: documentMother.id,
      mother_identification_number: nDocumentMother,
      mother_firstname: firstNameMother,
      mother_other_name: secondNameMother,
      mother_first_lastname: fatherLastNameMother,
      mother_other_lastname: motherLastNameMother,
      father_nationality: documentFather.nationality.id,
      father_identification_type: documentFather.id,
      father_identification_number: nDocumentFather,
      father_firstname: firstNameFather,
      father_other_name: secondNameFather,
      father_first_lastname: fatherLastNameFather,
      father_other_lastname: motherLastNameFather,
      uuid,
    };

    await services.studentRegistrationService.patchStep3(requestData).catch((error) => {
      console.error(error);
    });
  },
  async setIdentificationStepFour({ commit }, data) {
    const { liveWithLegalguardian } = data;
    commit('setLiveWithLegalguardian', liveWithLegalguardian);
  },
  async setIdentificationStepFive({ commit, getters }, data) {
    const { document } = data;
    commit('setDocument', document);
    const { uuid } = getters;
    const requestData = {
      nationality: document.nationality.id,
      identification_type: document.id,
      uuid,
    };

    await services.studentRegistrationService.patchStep4(requestData).catch((error) => {
      console.error(error);
    });
  },
  async setIdentificationStepSix({ commit, getters }, data) {
    const { nDocument } = data;
    commit('setNDocument', nDocument);
    const { uuid } = getters;
    const requestData = {
      identification_number: nDocument,
      uuid,
    };
    services.studentRegistrationService.patchStep5(requestData).catch((error) => {
      console.error(error);
    });
    // await services.studentRegistrationService.patchStep5Pre(requestData).then((response) => {
    //   if (response.data.is_used === false) {
    //     commit('setShowIdNumberModal', false);
    //     services.studentRegistrationService.patchStep5(requestData)
    //       .catch((error) => {
    //         console.error(error);
    //       });
    //   } else {
    //     commit('setShowIdNumberModal', true);
    //     commit('setIdNumberResponse', response.data);
    //   }
    // })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  },
  setStudentId({ commit, dispatch }, { idInfo }) {
    commit('setIdInfo', { idInfo });
    const getSetIdInfoPromise = new Promise((resolve) => {
      idInfo.forEach((id, index) => {
        services.studentRegistrationService
          .setStudentId({ studentId: id })
          .catch((error) => {
            console.error(error);
            throw error;
          })
          .finally(() => {
            if (index === idInfo.length - 1) resolve();
          });
      });
    });
    getSetIdInfoPromise.then(() => {
      dispatch('authentication/getStudents', { updateStudent: idInfo[0].uuid }, { root: true });
    });
  },

  async setIdentificationStepEight({ commit, getters }, data) {
    const {
      day,
      month,
      year,
      firstName,
      secondName,
      fatherLastName,
      motherLastName,
      gender,
      address,
    } = data;
    commit('setDay', day);
    commit('setMonth', month);
    commit('setYear', year);
    commit('setFirstName', firstName);
    commit('setSecondName', secondName);
    commit('setFatherLastName', fatherLastName);
    commit('setMotherLastName', motherLastName);
    commit('setGender', gender);
    commit('setBirthplace', address);
    const { uuid } = getters;
    const requestData = {
      birth_day: day,
      birth_month: month,
      birth_year: year,
      first_name: firstName,
      other_name: secondName,
      first_lastname: fatherLastName,
      other_lastname: motherLastName,
      gender,
      birth_location: address.muni,
      uuid,
    };

    await services.studentRegistrationService.patchStep7(requestData).catch((error) => {
      console.error(error);
    });
  },
  async setIdentificationStepNine({ commit, getters }, data) {
    const { grade } = data;
    commit('setGrade', grade);
    const { uuid } = getters;
    const requestData = {
      grade,
      uuid,
    };

    await services.studentRegistrationService.patchStep8(requestData).catch((error) => {
      console.error(error);
    });
  },
  // location
  setSameAddress({ commit }, sameAddress) {
    commit('setSameAddress', sameAddress);
  },
  setAddress({ commit }, address) {
    commit('setAddress', address);
  },
  // priority
  setPublicInstitutionPriority({ commit }, priority) {
    commit('setPublicInstitutionPriority', priority);
  },
  setNewSibling({ commit }, student) {
    commit('setNewSibling', student);
  },
  removeSibling({ commit }, id) {
    commit('removeSibling', id);
  },
  setCdiOrIcbfPriority({ commit }, priority) {
    commit('setCdiOrIcbfPriority', priority);
  },
  setVictimOfArmedConflictPriority({ commit }, priority) {
    commit('setVictimOfArmedConflictPriority', priority);
  },
  setVictimOfArmedConflictCategory({ commit }, priority) {
    commit('setVictimOfArmedConflictCategory', priority);
  },
  setDisabilityPriority({ commit }, priority) {
    commit('setDisabilityPriority', priority);
  },
  setDisabilityCategory({ commit }, priority) {
    commit('setDisabilityCategory', priority);
  },
  setDisabilityFile({ commit }, priority) {
    commit('setDisabilityFile', priority);
  },
  setEthnicity({ commit }, priority) {
    commit('setEthnicity', priority);
  },
  async setPriorityStepOne({ commit, getters }, data) {
    const { opt } = data;
    commit('setPublicInstitutionPriority', opt);
    const { uuid } = getters;
    const requestData = {
      has_sibling_priority: opt,
      uuid,
    };

    await services.studentRegistrationService.patchStep10(requestData).catch((error) => {
      console.error(error);
    });
  },
  setPriorityStepTwo({ getters }, { callback = undefined }) {
    const { uuid } = getters;
    const { siblings } = getters;
    const requestData = {
      siblings,
      uuid,
    };
    services.studentRegistrationService
      .patchStep11(requestData)
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async setPriorityStepThree({ commit, getters }, data) {
    const { opt } = data;
    commit('setCdiOrIcbfPriority', opt);
    const { uuid } = getters;
    const requestData = {
      icbf: opt,
      uuid,
    };

    await services.studentRegistrationService.patchStep12(requestData).catch((error) => {
      console.error(error);
    });
  },
  async setPatchArmedConflict({ commit }, options) {
    const { opt, category, uuid } = options;
    commit('setVictimOfArmedConflictPriority', opt);
    commit('setVictimOfArmedConflictCategory', category);
    const requestData = {
      has_armed_conflict: opt,
      armed_conflict_type_id: category,
      uuid,
    };

    await services.studentRegistrationService.patchArmedConflict(requestData).catch((error) => {
      console.error(error);
    });
  },
  async setDisabilities({ commit }, options) {
    const { opt, disability, uuid } = options;
    commit('setDisabilityPriority', opt);
    commit('setDisabilityCategory', disability);
    const requestData = {
      disability_id: disability,
      has_disability: opt,
      uuid,
    };

    await services.studentRegistrationService.patchDisabilities(requestData).catch((error) => {
      console.error(error);
    });
  },
  async setPriorityStepSix({ getters }, data) {
    const { imageB64, imageName, imageNumber } = data;
    const { uuid } = getters;
    const requestData = {
      filename: imageName,
      data_b64: imageB64,
      uuid,
      image_number: imageNumber,
    };
    await services.studentRegistrationService.patchStep15(requestData).catch((error) => {
      console.error(error);
    });
  },
  async setSteps({ getters }, { callback = undefined }) {
    const { uuid } = getters;
    const { currentSubSteps } = getters;
    const { totalSubSteps } = getters;
    const { totalSubStepsComplete } = getters;
    let step = 4;
    let subStep = 10;

    currentSubSteps.forEach((elem, i) => {
      if (elem < totalSubSteps[i]) {
        if (i + 1 < step && elem < subStep) {
          step = i + 1;
          subStep = elem;
        }
      } else if (totalSubStepsComplete[i] < totalSubSteps[i]) {
        if (i + 1 < step && elem < subStep) {
          step = i + 1;
          subStep = elem;
        }
      }
    });
    const requestData = {
      step,
      sub_step: subStep,
    };

    if (uuid !== '') {
      requestData.uuid = uuid;
    }

    return services.studentRegistrationService
      .setSteps(requestData)
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
  setStudentData({ commit }, data) {
    commit('setStudentData', data);
  },
  setNativeLanguage({ dispatch }, { languageArray, studentUuid }) {
    services.studentRegistrationService.setNativeLanguage(languageArray)
      .then(() => {
        dispatch('authentication/getStudents', { updateStudent: studentUuid }, { root: true });
      })
      .catch((error) => {
        console.error(error);
      });
  },
  getStudentData({ dispatch }, { updateStudent }) {
    dispatch('authentication/getStudents', { updateStudent }, { root: true }).then(() => {
      dispatch(
        'institutions/retrieveCampuses',
        { callback: null },
        { root: true },
      );
    });
  },
  async setStudents({ commit, dispatch }, { studentsForm, current }) {
    /**
     * Update the students array with the new data, and update the current student if needed
     *
     * @param {Object} studentsForm: Object with the students data to update
     * @param {Boolean} current: Whether the student should be set as the current student or not
     */
    commit('setLoading', true);
    let gradePayload = {
      uuid: studentsForm.uuid ?? null,
      first_name: studentsForm.firstName,
      other_name: studentsForm.secondName,
      last_name: studentsForm.firstLastName,
      other_lastname: studentsForm.secondLastName,
      interest_grades: studentsForm.gradeInterest,
      interest_stages: studentsForm.stagesInterest,
      birth_day: studentsForm.birthDay,
      birth_month: studentsForm.birthMonth,
      birth_year: studentsForm.birthYear,
      current_school: studentsForm.currentSchool,
    };
    if (studentsForm.birthDate) {
      const birthDate = { birth_date: studentsForm.birthDate };
      gradePayload = { ...gradePayload, ...birthDate };
    }
    if (studentsForm.gender) {
      gradePayload.gender = studentsForm.gender;
    }
    let student;
    await services.studentRegistrationService.setStudents(gradePayload).then((response) => {
      dispatch('getStudentData', { updateStudent: false }).then(() => {
        commit('setLoading', false);
      });
      if (current) {
        dispatch('authentication/setCurrentStudent', { student: response.data }, { root: true });
      }
      student = response.data;
    });
    return student;
  },
  async setStudentLocation({ dispatch }, { location, updateStudent }) {
    services.studentRegistrationService.setStudentLocation({ location }).then(() => {
      if (updateStudent) {
        dispatch('getStudentData', { updateStudent: location.uuid });
      } else {
        dispatch('getStudentData', { updateStudent: false });
      }
    });
  },
  async setStudentGrades({ dispatch }, { gradeOptions, updateStudent }) {
    const gradesPayload = {
      uuid: gradeOptions.uuid,
      interest_grades: gradeOptions.grades,
      interest_stages: gradeOptions.stages,
    };
    let updateCurrentStudent = false;
    if (updateStudent) {
      updateCurrentStudent = updateStudent;
    }
    services.studentRegistrationService
      .setStudentGrades({ grades: gradesPayload })
      .then(() => {
        dispatch('getStudentData', { updateStudent: updateCurrentStudent });
      })
      .finally(() => { });
  },
  async setStudentSisbenfGroup({ dispatch }, { icbfData }) {
    const icbfInfo = {
      uuid: icbfData.uuid,
      has_sisben: icbfData.hasSisben,
      sisben_category: icbfData.category,
      sisben_subcategory: null,
      status: '',
    };
    services.studentRegistrationService
      .setStudentSisbenfGroup({ icbfInfo })
      .then(() => {
        dispatch('getStudentData', { updateStudent: icbfData.uuid });
      })
      .finally(() => { });
  },
  async setIcbfBoolean({ dispatch }, { icbfData }) {
    const icbfInfo = {
      uuid: icbfData.uuid,
      icbf: icbfData.hasIcbf,
    };
    services.studentRegistrationService.patchIcbfBoolean(icbfInfo).then(() => {
      dispatch('getStudentData', { updateStudent: icbfData.uuid });
    });
  },

  getApplicantCheckIcbf({ dispatch, rootGetters }) {
    const currentStudent = rootGetters['authentication/currentStudent'];
    const idNumber = currentStudent.identification_number;
    const applicantInfo = services.studentRegistrationService
      .getApplicantCheckIcbf(idNumber)
      .then((response) => {
        dispatch('setHasIcbf', response.data).then(() => response.data);
      });
    return applicantInfo;
  },
  setBloodSiblingInfo({ commit, dispatch }, { brothersInfo, updateStudents }) {
    // eslint-disable-next-line
    const setBrotherPromise = new Promise((resolve) => {
      brothersInfo.forEach((brother, index) => {
        services.studentRegistrationService.setBloodSiblingInfo(brother).then(() => {
          if (index === brothersInfo.length - 1) {
            resolve(true);
          }
        });
      });
    });
    setBrotherPromise.then(() => {
      if (updateStudents) {
        dispatch('getStudentData', { updateStudent: brothersInfo[0].uuid });
      }
      commit('setBrothersInfo', brothersInfo);
    });
  },
  async setStudentCurrentCampus({ dispatch }, { institutionPayload }) {
    services.studentRegistrationService.setStudentCurrentCampus({ institutionPayload }).then(() => {
      dispatch('getStudentData', { updateStudent: institutionPayload.uuid });
    });
  },
  async setStudentPreviousCampus({ dispatch }, { institutionPayload }) {
    services.studentRegistrationService.setStudentPreviousCampus({ institutionPayload }).then(() => {
      dispatch('getStudentData', { updateStudent: institutionPayload.uuid });
    });
  },
  async setParentCampus({ dispatch }, { institutionPayload }) {
    services.studentRegistrationService.setParentCampus({ institutionPayload }).then(() => {
      dispatch('getStudentData', { updateStudent: institutionPayload.uuid });
    });
  },
  async setSepPriority({ dispatch }, { sepPayload }) {
    services.studentRegistrationService.setSepPriority({ sepPayload }).then(() => {
      dispatch('getStudentData', { updateStudent: sepPayload.uuid });
    });
  },
  async setNeepPriority({ dispatch }, { neepPayload }) {
    services.studentRegistrationService.setNeepPriority({ neepPayload }).then(() => {
      dispatch('getStudentData', { updateStudent: neepPayload.uuid });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
