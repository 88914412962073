<template>
  <div class="register-main register-main--safe-padding">
    <div>
      <ModalHeader
        :content-sections="['actions', 'logo']"
        inline-logo
        @back="backMethod()"
      />
      <ModalContent
        class="application_welcome"
        :content-sections="['smallTitle', 'imageAtom']"
        :small-title-text="'applications_register.application_title'"
        :small-title-arg="campusDetails.campus_name"
        :image-source="hasCampusThumb()"
        small-title-primary-color
      />
    </div>
    <CallToActions
      :link-text="'views.login.to-register'"
      :types="['option']"
      :option-text1="'user_register.welcome.login'"
      :option-text2="'user_register.welcome.register'"
      :option-text-active-tag="'click_reg_headmaster-welcome-button'"
      :option-text-tag="'click_reg_headmaster-welcome-button'"
      active-value
      :spaced="false"
      @continue="$emit('nextStep')"
      @nextStep="nextStep"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';

export default {
  name: 'HeadmasterWelcome',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      campusDetails: 'institutions/campusDetails',
    }),
  },
  mounted() {
    this.setTenantId({ tenantId: this.campusDetails.campus_code });
  },
  methods: {
    ...mapActions({
      setForcedStep: 'userRegister/setForcedStep',
      setTenantId: 'digitalEnrollment/setTenantId',
    }),
    backMethod() {
      this.$router.push({ name: 'ExplorerSchool', params: { code: this.campusDetails.campus_code } });
    },
    nextStep(i) {
      if (i === 1) {
        return this.$emit('show-login-register');
      }
      return this.$emit('nextStep');
    },
    hasCampusThumb() {
      if (this.campusDetails.campus_images_thumbnail) {
        return this.campusDetails.campus_images_thumbnail[0].image_link;
      }
      return null;
    },
  },
};
</script>
