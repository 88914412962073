<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      class="loader-tile"
      max-width="auto"
      max-height="220"
      type="list-item"
    />
    <div v-else class="vacancy-tile" :style="getStyle()">
      <TextAtom
        :value="text"
        :color="'color-white'"
        font="inter"
        weight="500"
        size="small"
      />
    </div>
  </div>
</template>

<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';

export default {
  name: 'VacancyTile',
  components: {
    TextAtom,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: 'primary-medium',
      validator(value) {
        return [
          'primary-medium',
          'secondary-focus',
          'gray-line',
        ].includes(value);
      },
    },
    color: {
      type: String,
      default: 'white',
      validator(value) {
        return [
          'white',
          'primary-dark',
        ].includes(value);
      },
    },
  },
  methods: {
    getStyle() {
      return {
        background: `var(--${this.backgroundColor})`,
        color: `var(--${this.color})`,
      };
    },
  },
};
</script>
