<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        :actions-tag="registerType === 1
          ? 'click_reg_quick-register-index_back-button' : 'click_reg_detailed-register-index_back-button'"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <ModalContent
        :content-sections="['smallTitle', 'columnStepper']"
        :small-title-text="
          inStudentFlow
            ? 'user_register.steps_modal.student_title'
            : 'user_register.steps_modal.title'
        "
      />
      <ColumnStepper
        :mixpanel-tag="registerType === 1
          ? 'click_reg_quick-register-index_column-stepper' : 'click_reg_detailed-register-index_column-stepper'"
        :stepper-info="stepperInfo"
      />
    </div>
    <CallToActions
      :option-text1="'user_register.continue'"
      :option-text2="'user_register.register_finale.explore_btn'"
      :main-button-tag="registerType === 1
        ? 'click_reg_quick-register-index_start-button' : 'click_reg_detailed-register-index_start-button'"
      :main-button-text="'user_register.continue'"
      :types="registerType === 2 && inStudentFlow ? ['option'] : ['main']"
      :loader="false"
      :spaced="false"
      active-value
      @nextStep="nextStep"
      @continue="$emit('nextStep')"
    />
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import ColumnStepper from '@/components/molecules/steppers/ColumnStepper.vue';

export default {
  name: 'StepsModal',
  components: {
    ModalHeader, ColumnStepper, ModalContent, CallToActions,
  },
  props: {
    inStudentFlow: {
      type: Boolean,
      default: false,
    },
    // 1 Quick - 2 Detailed
    registerType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      stepperInfo: [
        {
          text: 'user_register.steps_modal.type_1',
        },
        {
          text: this.inStudentFlow
            ? 'user_register.steps_modal.type_3'
            : 'user_register.steps_modal.type_2',
        },
        {
          text: this.inStudentFlow
            ? 'user_register.steps_modal.type_4'
            : 'user_register.steps_modal.type_3',
        },
      ],
    };
  },
  methods: {
    nextStep(i) {
      // 1 -> continue next step
      // 2 -> continue to map
      if (i === 2) {
        this.$emit('toMap');
      } else {
        this.$emit('nextStep');
      }
    },
  },
};
</script>
