<template>
  <div>
    <TextAtom
      :value="description"
      class="mt-4 text-left d-flex pl-4 pb-3"
      tag="label"
      font="inter"
      color="primary-dark"
      weight="400"
      size="subtitle"
    />
    <section class="campus-detail__panels__container">
      <div
        v-for="(label, index) in extracurriculars"
        :key="index"
        itemprop="additionalProperty"
        itemscope
        itemtype="https://schema.org/PropertyValue"
      >
        <span
          v-if="label.extra_label.id !== 99"
          class="campus-detail__panels__title text-left mb-2 font-weight-medium bullet-point d-block"
          itemprop="name"
        >{{ getLabel(label) }}
          <meta itemprop="value" content="True" />
          <meta
            v-if="extracurriculars[index].descrip !== ''"
            itemprop="description"
            :content="extracurriculars[index].descrip"
          >
          <meta
            v-if="extracurriculars[index].campus_code !== ''"
            itemprop="identifier"
            :content="extracurriculars[index].campus_code"
          >
          <meta itemprop="url" :href="canonicalLink">
        </span>
        <span
          v-if="label.extra_label.id === 99"
          class="campus-detail__panels__title text-left mb-2 font-weight-medium bullet-point d-block"
          itemprop="name"
        >{{ getOtro(label) }}
          <meta itemprop="value" content="True" />
          <meta
            v-if="extracurriculars[index].descrip !== ''"
            itemprop="description"
            :content="extracurriculars[index].descrip"
          >
          <meta
            v-if="extracurriculars[index].campus_code !== ''"
            itemprop="identifier"
            :content="extracurriculars[index].campus_code"
          >
          <meta itemprop="url" :href="canonicalLink">
        </span>
      </div>

      <MessageInPanel from="extracurriculars-panel" />
    </section>
  </div>
</template>
<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import MessageInPanel from './MessageInPanel.vue';

export default {
  name: 'ExtracurricularsPanel',
  components: {
    MessageInPanel,
    TextAtom,
  },
  props: {
    extracurriculars: {
      type: Array,
      required: true,
    },
    canonicalLink: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
  },

  methods: {
    getLabel(label) {
      return label.extra_label.id !== 99 ? label.extra_label.extra_name : '';
    },
    getOtro(label) {
      return label.descrip !== null ? label.descrip : 'Otro';
    },
  },
};
</script>
