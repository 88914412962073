/**
 * Filters an array of objects by a given key, in order to get only unique values.
 * By default, the `id` key is used to determine uniqueness.
 * @param {Array} array - The array to filter
 * @param {String} key  - The key to determine duplicates
 * @returns {Array} - The filtered array
 */
const uniqueArrayObjects = (array, key = 'id') => array.filter((v, i, a) => a.findIndex((t) => t[key] === v[key]) === i);

export { uniqueArrayObjects };
