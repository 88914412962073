<template>
  <div class="deg-waiting-list programs-card">
    <ImageAtom
      class="deg-waiting-list--line clickable"
      :source="require('@/assets/icons/back-blue.svg')"
      :alt-text="'back'"
      @click="$emit('close')"
      @keydown="$emit('close')"
    />
    <MainTitle
      class="mt-8 deg-waiting-list--title"
      :text="'digital_enrollment.waiting-list.vacancies.title'"
      left-align
      secondary
    />
    <ImageAtom
      class="deg-waiting-list--line"
      :source="require('@/assets/waitingList/dge-line.svg')"
      :alt-text="'dge-line'"
    />
    <SmallTitle
      class="deg-waiting-list--campus-name"
      :text="campusDetail.campus_name"
      center-left
    />
    <Tabs
      v-model="tab"
      :current-year="currentYear"
      :second-year="secondYear"
      :third-year="thirdYear"
      :disabled="tabDisabled"
    />
    <div v-if="hasVacancies">
      <div v-for="(vacancy, index) in vacancies" :key="index" class="mb-10">
        <div>
          <TextAtom
            v-if="hasStageOfTheYear(vacancy)"
            :value="vacancy.stageName"
            class="deg-waiting-list--stage"
            weight="500"
            size="subtitle"
          />
          <v-expansion-panels v-model="panel" multiple class="grade--panels" flat light>
            <template v-for="(program, gradeName) in vacancy.vacancies">
              <v-expansion-panel
                v-if="hasProgramsOfTheYear(program)"
                :key="gradeName"
                class="elevation-0 deg-waiting-list--content"
                expand
              >
                <ExpansionPanelHeader
                  :title="program.gradeName"
                  :icon-color="'#1a0c4c'"
                  show-image
                  :image-src="getIconList()"
                  :title-class="'deg-waiting-list--grade-title'"
                />
                <v-expansion-panel-content class="elevation-0 deg-waiting-list--content">
                  <div v-for="shift in program.shifts" :key="shift.id">
                    <div v-if="sameYear(shift)">
                      <p class="deg-waiting-list--shift">
                        {{ shift.gradeName }} - {{ shift.name }}
                      </p>
                      <WaitingChip
                        :open="shift.hasVacancies"
                        @next="$emit('next', shift, shift.level_track, shift.programId, shift.hasVacancies)"
                      />
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
          </v-expansion-panels>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WaitingChip from '@/components/atoms/chips/WaitingChip.vue';
import ImageAtom from '@/components/atoms/images/Image.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import ExpansionPanelHeader from '@/components/atoms/titles/ExpansionPanelHeader.vue';
import MainTitle from '@/components/atoms/titles/MainTitle.vue';
import SmallTitle from '@/components/atoms/titles/SmallTitle.vue';
import Tabs from '@/components/molecules/tabs/Tabs.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'VacanciesList',
  components: {
    MainTitle,
    SmallTitle,
    ImageAtom,
    ExpansionPanelHeader,
    WaitingChip,
    TextAtom,
    Tabs,
  },
  data() {
    return {
      vacancies: [],
      panel: [],
      tab: new Date().getFullYear(),
      currentYear: new Date().getFullYear(),
      secondYear: new Date().getFullYear() + 1,
      thirdYear: new Date().getFullYear() + 2,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'institutions/campusDetails',
      currentStudent: 'authentication/currentStudent',
      isGuest: 'authentication/isGuest',
      stages: 'options/stages',
      grades: 'options/stages',
    }),
    tabDisabled() {
      return [
        !this.hasProgramsByYear(this.currentYear),
        !this.hasProgramsByYear(this.secondYear),
        !this.hasProgramsByYear(this.thirdYear),
      ];
    },
  },
  watch: {
    tab() {
      this.panel = [];
    },
  },
  mounted() {
    this.setActualVacancies();
  },
  methods: {
    // FIXME: This method is duplicated in Vacancies.vue of Dashboard.
    // It should be moved to a common place in module and utils.
    setActualVacancies() {
      this.vacancies = [];
      this.campusDetail?.program_campus?.forEach((program) => {
        const indexGradeInVacancies = this.vacancies.findIndex(
          (vacancy) => vacancy?.grade?.id === program?.gradetrack?.grade_label?.id,
        );
        if (indexGradeInVacancies === -1) {
          this.vacancies.push({
            grade: program?.gradetrack?.grade_label,
            gradeName: this.$i18n.locale === 'en' ? program?.gradetrack?.grade_label?.grade_name_en : program?.gradetrack?.grade_label?.grade_name,
            stageName: this.$i18n.locale === 'en' ? program?.gradetrack?.stage_label?.stage_name_en : program?.gradetrack?.stage_label?.stage_name,
            shifts: [
              {
                id: program?.shift_label?.id,
                name: this.$i18n.locale === 'en' ? program?.shift_label?.shift_name_en : program?.shift_label?.shift_name,
                hasVacancies: program?.show_vacancies === 1 ? true : false ?? false,
                gradeName: this.getLangGradeTrack(program?.gradetrack),
                programId: program?.id,
                grade: this.$i18n.locale === 'en' ? program?.gradetrack?.grade_label?.grade_name_en : program?.gradetrack?.grade_label?.grade_name,
                level_track: program?.gradetrack?.id,
                year: program?.year,
              },
            ],
          });
        } else {
          this.vacancies[indexGradeInVacancies].shifts.push({
            id: program?.shift_label?.id,
            name: this.$i18n.locale === 'en' ? program?.shift_label?.shift_name_en : program?.shift_label?.shift_name,
            hasVacancies: program?.show_vacancies === 1 ? true : false ?? false,
            gradeName: this.getLangGradeTrack(program?.gradetrack),
            programId: program?.id,
            grade: this.$i18n.locale === 'en' ? program?.gradetrack?.grade_label?.grade_name_en : program?.gradetrack?.grade_label?.grade_name,
            level_track: program?.gradetrack?.id,
            year: program?.year,
          });
        }
      });

      this.vacancies.sort((a, b) => a.grade.order - b.grade.order);
      const groupedVacancies = this.vacancies.reduce((accumulator, vacancy) => {
        const { stageName } = vacancy;
        const groupIndex = accumulator.findIndex((group) => group.stageName === stageName);
        if (groupIndex === -1) {
          accumulator.push({ stageName, vacancies: [vacancy] });
        } else {
          accumulator[groupIndex].vacancies.push(vacancy);
        }
        return accumulator;
      }, []);

      this.vacancies = groupedVacancies;
    },
    getLangGradeTrack(gradetrack) {
      if (!gradetrack?.gradetrack_name) {
        return '';
      }
      if (this.$i18n.locale === 'en') {
        return gradetrack.gradetrack_name_en;
      }
      return gradetrack.gradetrack_name;
    },
    hasVacancies() {
      return this.vacancies.length > 0;
    },
    getIconList() {
      return 'chair-blue.svg';
    },
    getYear() {
      return new Date().getFullYear() + this.tab;
    },
    hasProgramsByYear(year) {
      const copyVacancies = [...this.vacancies];
      const programs = copyVacancies.flatMap((grade) => grade.vacancies);
      const shifts = programs.flatMap((program) => program.shifts);
      return shifts.some((shift) => shift.year === year);
    },
    hasProgramsOfTheYear(program) {
      return program.shifts.some((shift) => shift.year === this.getYear());
    },
    hasStageOfTheYear(stage) {
      return stage.vacancies.some((vacancy) => this.hasProgramsOfTheYear(vacancy));
    },
    sameYear(shift) {
      return shift.year === this.getYear();
    },
  },
};
</script>
