var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-main"},[_c('div',{staticClass:"register-main--btn-div flex-column",class:{
      'register-main--btn-div--no-margins': _vm.noMargin,
    },staticStyle:{"margin-top":"12px"}},[(_vm.loader)?_c('div',{staticClass:"register-main--btn-div--btn-main register-main--btn-div--btn-main--square-border register-main--btn-div--btn-main--square-border-active",class:{
        'register-main--btn-div--btn-main--legacy-primary-loader': _vm.legacyPrimary,
      }},[_c('v-progress-circular',{staticClass:"d-flex align-center justify-center",staticStyle:{"margin":"4px"},attrs:{"indeterminate":"","color":"white"}})],1):_vm._e(),(!_vm.loader)?_c('button',{staticClass:"register-main--btn-div--btn-main",class:{
        'register-main--btn-div--btn-main--active': _vm.isActive,
        'register-main--btn-div--btn-main--square-border': _vm.squareBorders,
        'register-main--btn-div--btn-main--legacy-primary': _vm.legacyPrimary,
        'register-main--btn-div--btn-main--legacy-medium': _vm.legacyMedium,
        'register-main--btn-div--btn-main--legacy-medium-active': _vm.legacyMedium && _vm.isActive,
        'register-main--btn-div--btn-main--legacy-primary-active': _vm.legacyPrimary && _vm.isActive,
        'register-main--btn-div--btn-main--primary-medium': _vm.primaryMedium,
        'register-main--btn-div--btn-main--primary-medium--mobile': _vm.primaryMedium && _vm.mobile,
        'register-main--btn-div--btn-main--primary-medium--active': _vm.primaryMedium && _vm.isActive,
        'register-main--btn-div--btn-main--text-small': _vm.textSmall,
        'register-main--btn-div--btn-main--disabled-active': _vm.disabledActive,
        'register-main--btn-div--btn-main--disabled': _vm.disabled,
        'register-main--btn-div--btn-main--borders': !_vm.light,
        'register-main--btn-div--btn-main--light': _vm.light,
        'register-main--btn-div--btn-main--solid-background': _vm.solidBackground,
        'register-main--btn-div--btn-main--solid-background--disabled': _vm.solidBackground && _vm.disabled,
        'register-main--btn-div--btn-main--solid-background--mobile': _vm.solidBackground && _vm.mobile,
      },style:({ '--color': _vm.color }),attrs:{"type":"button"},on:{"click":function($event){_vm.disabled || _vm.disabledActive ? null : _vm.buttonClick()}}},[(_vm.svgIcon)?_c('SvgIcon',{staticClass:"mr-auto ml-5",attrs:{"icon":_vm.svgIcon,"size":'24'}}):_vm._e(),_c('span',{class:_vm.svgIcon ? 'mr-auto d-flex' : ''},[_vm._v(" "+_vm._s(_vm.$t(_vm.text)))])],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }