<template>
  <div class="register-main fill-width justify-space-between" style="height: 100%">
    <ModalHeader
      :content-sections="outOfRegister ? ['close'] : ['actions']"
      :actions-tag="registerType === 1
        ? 'click_reg_quick-register-headmaster-schedule_back-button'
        : 'click_reg_detailed-register-headmaster-schedule_back-button'"
      @back="$emit('backStep')"
      @close="$emit('closeModal')"
    />
    <Calendar
      :mixpanel-tag="setCalendarMixpanelTag()"
      :calendar-url="calendarUrl"
      @data-set="dateSet = true"
    />
    <CallToActions
      v-if="dateSet"
      :types="['main']"
      active-value
      :main-button-text=" outOfRegister ? 'user_register.calendar.button2' : 'user_register.calendar.button1'"
      :main-button-tag="setCallToActionMixpanelTag()"
      @continue="nextStep"
    />
  </div>
</template>

<script>
import ModalHeader from '@/components/molecules/modals/Header.vue';
import Calendar from '@/components/atoms/calendar/Calendar.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';

export default {
  name: 'ModalCalendar',
  components: { ModalHeader, CallToActions, Calendar },
  props: {
    // 1 Quick - 2 Detailed
    registerType: {
      type: Number,
      default: null,
    },
    outOfRegister: {
      type: Boolean,
      default: false,
    },
    calendarUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dateSet: false,
    };
  },
  methods: {
    nextStep() {
      if (this.outOfRegister) {
        return this.$emit('closeModal');
      }
      return this.$router.push('/map');
    },
    setCalendarMixpanelTag() {
      if (!this.outOfRegister) {
        if (this.registerType === 1) {
          return 'click_reg_quick-register-headmaster-schedule_calendly-module';
        }
        return 'click_reg_detailed-register-headmaster-schedule_calendly-module';
      }
      return 'click-headmaster-schedule_calendly-module';
    },
    setCallToActionMixpanelTag() {
      if (!this.outOfRegister) {
        if (this.registerType === 1) {
          return 'click_reg_quick-register-headmaster-schedule_start-button';
        }
        return 'click_reg_detailed-register-headmaster-schedule_start-button';
      }
      return 'click-headmaster-schedule_start-button';
    },
  },
};
</script>
