<template>
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path
        d="M11.9147 4.15935C11.3897 3.88328 10.7468 4.07441 10.4683 4.59471C10.2218 5.04068 10.3397 5.60345 10.7361 5.922C13.2004 7.53599 13.8754 10.8383 12.2468 13.2805C10.6183 15.7228 7.28613 16.3917 4.82185 14.7777C2.35756 13.1637 1.68256 9.86141 3.31113 7.41919C3.70756 6.82456 4.22185 6.31488 4.82185 5.922C5.34685 5.64592 5.53971 4.9982 5.26113 4.48852C4.98256 3.96822 4.32899 3.77709 3.81471 4.05317C3.75042 4.08503 3.69685 4.11688 3.64328 4.15935C0.182563 6.42107 -0.760295 11.0294 1.52185 14.4592C3.80399 17.8889 8.45399 18.8233 11.9147 16.5616C15.3754 14.2999 16.3183 9.69152 14.0361 6.26179C13.479 5.42294 12.7611 4.71151 11.9147 4.15935Z"
        fill="white"
      />
      <path
        d="M7.77846 11.4117C8.36775 11.4117 8.84989 10.9339 8.84989 10.3499V1.85517C8.84989 1.27116 8.36775 0.793335 7.77846 0.793335C7.18917 0.793335 6.70703 1.27116 6.70703 1.85517V10.3499C6.70703 10.9339 7.18917 11.4117 7.77846 11.4117Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="15" height="17" fill="white" transform="translate(0.279297 0.793335)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'logout',
};
</script>
