import services from '@/services';

const getDefaultState = () => ({
  currentStep: null,
  forcedStep: null,
  profileStep: null,
  contactPreferences: null,
  inRegisterFlow: false,
  geocodeInfo: null,
  fullAddressDetails: null,
  modalInfoFlow: null,
  // location
  place: {},
  location: {},
  locationType: null,
  initialCoordinates: {},
  pinMoved: false,
  isAllianceUser: false,
});

const state = {
  ...getDefaultState(),
};

const getters = {
  currentStep: ({ currentStep }) => currentStep,
  contactPreferences: ({ contactPreferences }) => contactPreferences,
  inRegisterFlow: ({ inRegisterFlow }) => inRegisterFlow,
  forcedStep: ({ forcedStep }) => forcedStep,
  profileStep: ({ profileStep }) => profileStep,
  geocodeInfo: ({ geocodeInfo }) => geocodeInfo,
  fullAddressDetails: ({ fullAddressDetails }) => fullAddressDetails,
  modalInfoFlow: ({ modalInfoFlow }) => modalInfoFlow,
  // location
  place: ({ place }) => place,
  location: ({ location }) => location,
  locationType: ({ locationType }) => locationType,
  initialCoordinates: ({ initialCoordinates }) => initialCoordinates,
  pinMoved: ({ pinMoved }) => pinMoved,
  isAllianceUser: ({ isAllianceUser }) => isAllianceUser,
};

const mutations = {
  setInRegisterFlow(state, bool) {
    state.inRegisterFlow = bool;
  },
  setCurrentStep(state, { nextStep }) {
    state.currentStep = nextStep;
  },
  setContactPreferences(state, { contactPreferences }) {
    state.contactPreferences = contactPreferences;
  },
  setForcedStep(state, { step }) {
    state.forcedStep = step;
  },
  setProfileStep(state, { step }) {
    state.profileStep = step;
  },
  setGeocoderInfo(state, { geocodeInfo }) {
    state.geocodeInfo = geocodeInfo;
  },
  setFullAddressDetails(state, { fullAddressDetails }) {
    state.fullAddressDetails = fullAddressDetails;
  },
  setModalInfoFlow(state, { details }) {
    state.modalInfoFlow = details;
  },
  setModalInfoFlowPush(state, { details }) {
    let hasModal = false;
    if (state.modalInfoFlow) {
      hasModal = state.modalInfoFlow.find((modal) => modal.modal_code === details.modal_code);
    }
    if (
      hasModal
      && hasModal.action_completed !== details.action_completed
      && hasModal.modal_code === details.modal_code
    ) {
      state.modalInfoFlow.map((modal) => {
        if (modal.modal_code === details.modal_code) {
          modal.action_completed = details.action_completed;
        }
        return modal;
      });
    }
    if (!hasModal) {
      if (!state.modalInfoFlow) {
        state.modalInfoFlow = [];
      }
      state.modalInfoFlow.push(details);
    }
  },
  // location

  setSearchTerm(state, { searchTerm }) {
    state.place.searchTerm = searchTerm;
  },
  setPlace(state, { place }) {
    state.place = place;
  },
  setLocationCodeNational(state, { codeNational }) {
    state.location.code_national = codeNational;
  },
  setLocationAddress(state, { address }) {
    state.location.address_searched = address;
  },
  setFinalLocationAddress(state, { address }) {
    state.location.final_address = address;
  },
  setLocationType(state, { locationType }) {
    state.locationType = locationType;
  },
  setLocationSource(state, { locationSource }) {
    state.location.final_address.source = locationSource;
  },
  setLocationFormattedAddress(state, { locationAddress }) {
    state.location.final_address.formatted_address = locationAddress;
  },
  setGeoType(state, { geoType }) {
    state.location.final_address.geocodification_type = geoType;
  },
  setInitialCoordinates(state, { coordinates }) {
    state.initialCoordinates = coordinates;
  },
  setPinMoved(state, { pinMoved }) {
    state.pinMoved = pinMoved;
  },
  setLatLong(state, { lat, lng }) {
    state.place.lat = lat;
    state.place.lng = lng;
  },
  setAllianceUserFlag(state, { isAlliance }) {
    state.isAllianceUser = isAlliance;
  },
};

const actions = {
  setFullAddressDetails({ commit }, { fullAddressDetails }) {
    commit('setFullAddressDetails', { fullAddressDetails });
  },
  setInRegisterFlow({ commit }, bool) {
    commit('setInRegisterFlow', bool);
  },
  setCurrentStep({ commit }, { nextStep }) {
    commit('setCurrentStep', { nextStep });
  },
  setForcedStep({ commit }, { step }) {
    commit('setForcedStep', { step });
  },
  setProfileStep({ commit }, { step }) {
    commit('setProfileStep', { step });
  },
  setIdImage({ dispatch }, { idInfo }) {
    // TODO: Move from here
    const identificationStepPayload = {
      ...idInfo,
      nationality: idInfo.nationality.id,
      identification_type: idInfo.identification_type.id,
    };
    services.userRegistrationService
      .identificationStep3({
        idInfo: identificationStepPayload,
      })
      .then((response) => {
        dispatch('authentication/setLgIdInfo', { data: response.data }, { root: true });
      });
  },
  setFastRegisterLocation({ dispatch }, { location }) {
    const locationPayload = {
      ...location,
      address_name: location.address_name?.id || location.address_name,
    };
    services.userRegistrationService.fastRegisterStep2({ location: locationPayload }).then(() => {
      dispatch('authentication/setLegalGuardianLocation', { address: location }, { root: true })
        .then((response) => response);
    });
  },
  // eslint-disable-next-line no-empty-pattern
  setRegisterAddressGuest({}, { location }) {
    try {
      services.userRegistrationService.registerAddressGuest({ location });
    } catch (error) {
      console.error(error);
    }
  },
  setFastRegisterGrade({ rootGetters, dispatch }, { gradesData }) {
    dispatch('authentication/setLegalGuardianInterestGrades', { grades: gradesData.grades }, { root: true });
    services.userRegistrationService.fastRegisterStep3({ gradesData }).then(() => {
      const legalGuardianUUID = rootGetters['authentication/legalGuardianUUID'];
      dispatch('authentication/getUserDetails', { legalGuardianUUID }, { root: true });
      const inRegisterFlow = rootGetters['userRegister/inRegisterFlow'];
      if (inRegisterFlow) {
        dispatch('authentication/getStudents', {}, { root: true }).then(() => {
          const currentStudent = rootGetters['authentication/currentStudent'];
          const exploredCampuses = rootGetters['institutions/exploredCampuses'];
          const exploredSchoolCards = rootGetters['institutions/exploredSchoolCards'];
          if (exploredSchoolCards.length > 0) {
            exploredSchoolCards.forEach((campus) => {
              services.institutionsService
                .exploreSchoolCard({
                  currentStudent,
                  campus,
                })
                .then(() => {
                  dispatch('institutions/retrieveExploredSchoolCard', {}, { root: true }).then(
                    () => {
                      dispatch('institutions/updateExploredSchoolCards', [campus], {
                        root: true,
                      });
                    },
                  );
                });
            });
          }
          if (exploredCampuses.length > 0) {
            exploredCampuses.forEach((campus) => {
              services.institutionsService
                .exploreCampus({
                  currentStudent,
                  campus,
                })
                .then(() => {
                  dispatch('institutions/retrieveExploredCampuses', {}, { root: true }).then(() => {
                    dispatch('institutions/updateExploredMarkers', [campus], { root: true });
                  });
                });
            });
          }
        });
      }
    });
  },
  identificationLgStep1({ dispatch }, { personalInfo }) {
    services.userRegistrationService.identificationLgStep1({ personalInfo }).then((response) => {
      dispatch(
        'authentication/setLgIdentification',
        { identification: response.data },
        { root: true },
      );
      dispatch('authentication/setUsername', { username: personalInfo.firstName }, { root: true });
      dispatch('authentication/updateMainFilters', { info: personalInfo }, { root: true });
    });
  },
  setContactPreferences({ commit }, { contactPreferences, persist = true }) {
    /**
     * @param {object} contactPreferences
     * @param {boolean} persist
     * @returns {Promise<void>}
     * @description Set contact preferences for the user in store and backend.
     * If persist is false, the preferences are saved only in the store and not in the backend.
     */
    if (persist) {
      services.userRegistrationService.contactPreferences({ contactPreferences }).then(() => {
        commit('setContactPreferences', { contactPreferences });
      });
    } else {
      commit('setContactPreferences', { contactPreferences });
    }
  },
  async getAddressGeocoding({ commit, dispatch }, { location }) {
    // FIXME: This doesn't fit here, it should be in a different module along with all geocoding info
    return services.geoToolsService
      .getAddressGeocoding(location)
      .then((response) => {
        commit('setGeocoderInfo', { geocodeInfo: response.data });
      })
      .catch(() => {
        dispatch('utils/error', 'Error while fetching location from address', { root: true });
        commit('setGeocoderInfo', { geocodeInfo: null });
      });
  },
  async getAddressReverseGeocoding({ commit, dispatch }, { reversGeocoding }) {
    // FIXME: This doesn't fit here, it should be in a different module along with all geocoding info
    return services.geoToolsService
      .getAddressReverseGeocoding(reversGeocoding)
      .then((response) => {
        commit('setGeocoderInfo', { geocodeInfo: response.data });
      })
      .catch(() => {
        dispatch('utils/error', 'Error while fetching address from location', { root: true });
        commit('setGeocoderInfo', { geocodeInfo: null });
      });
  },
  async getModalFlowInfo({ commit, rootGetters }) {
    const legalGuardianUUID = rootGetters['authentication/legalGuardianUUID'];
    return services.userRegistrationService
      .getModalFlowInfo({ uuid: legalGuardianUUID })
      .then((response) => {
        commit('setModalInfoFlow', { details: response.data });
      })
      .catch(() => {
        commit('setModalInfoFlow', { details: null });
      });
  },
  async setModalFlowInfo({ commit, rootGetters, getters }, { modalInfo, modalUuid }) {
    const sessionUUID = rootGetters['authentication/sessionUUID'];
    const { modalInfoFlow } = getters;
    modalInfo.user = sessionUUID;
    if (modalInfoFlow && modalInfoFlow.length > 0 && modalUuid) {
      return services.userRegistrationService.setModalFlow({ modalInfo, modalUuid }).then(() => {
        commit('setModalInfoFlowPush', { details: modalInfo });
      });
    }
    return services.userRegistrationService
      .setNewModalFlow({ uuid: sessionUUID, modalInfo })
      .then((response) => {
        commit('setModalInfoFlowPush', { details: response.data });
      });
  },

  // location

  changeCodeNational({ commit }, { codeNational }) {
    commit('setLocationCodeNational', { codeNational });
  },
  changePlace({ commit }, { place }) {
    commit('setPlace', { place });
  },

  setSearchTerm({ commit }, { searchTerm }) {
    commit('setSearchTerm', { searchTerm });
  },

  setLocationAddress({ commit }, { searchTerm }) {
    commit('setLocationAddress', { address: searchTerm });
  },

  setFinalLocationAddress({ commit }, { address }) {
    commit('setFinalLocationAddress', { address });
  },

  setLocationType({ commit }, { locationType }) {
    commit('setLocationType', { locationType });
  },

  changeLocationSource({ commit }, { locationSource }) {
    commit('setLocationSource', { locationSource });
  },
  changeLocationFormattedAddress({ commit }, { locationAddress }) {
    commit('setLocationFormattedAddress', { locationAddress });
  },
  changeGeoType({ commit }, { geoType }) {
    commit('setGeoType', { geoType });
  },
  setInitialCoordinates({ commit }, { coordinates }) {
    commit('setInitialCoordinates', { coordinates });
  },
  setPinMoved({ commit }, { pinMoved }) {
    commit('setPinMoved', { pinMoved });
  },
  changeLatLong({ commit }, { lat, lng }) {
    commit('setLatLong', { lat, lng });
  },
  setAllianceUserFlag({ commit }, { isAlliance }) {
    commit('setAllianceUserFlag', { isAlliance });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
