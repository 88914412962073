import { getDistanceBetweenCoordinates } from '@/utils/geolocation';
import { getVacancies, validateModalities } from '@/utils/schools';
import { getCampusPaymentObject } from '@/utils/categories/payment';
import { TETHER_FILTER_KEYS as FILTER_KEYS } from '@/constants/filters';
import { DISTANCE_LABELS, MODALITY_LABELS } from '@/constants/labels';
import { matchScholarships } from './scholarships';

// ************************************************************************************************
// ***********************         Helper Functions for filters        ****************************
// ************************************************************************************************

/**
 * Returns an object with all filters set to their default values
 * @returns {Object} - an object with all filters set to their default values
 */
export const blankFilterContext = () => ({
  [FILTER_KEYS.AGREEMENTS]: new Set(),
  [FILTER_KEYS.DEPENDENCIES]: new Set(),
  [FILTER_KEYS.DISTANCE]: new Set(),
  [FILTER_KEYS.EXTRACURRICULARS]: new Set(),
  [FILTER_KEYS.HAS_VACANCIES]: new Set(),
  [FILTER_KEYS.INFRASTRUCTURE]: new Set(),
  [FILTER_KEYS.INSTITUTION]: new Set(),
  [FILTER_KEYS.LANGUAGES]: new Set(),
  [FILTER_KEYS.MULTIMEDIA]: new Set(),
  [FILTER_KEYS.PARTNERSHIPS]: new Set(),
  [FILTER_KEYS.PAYMENT]: new Set(),
  [FILTER_KEYS.PAYMENT_AGREEMENT]: new Set(),
  [FILTER_KEYS.PERFORMANCE]: new Set(),
  [FILTER_KEYS.SPECIALTIES]: new Set(),
  [FILTER_KEYS.SPORTS]: new Set(),
  [FILTER_KEYS.DISTANCE_RAW]: undefined,
  [FILTER_KEYS.MODALITY]: new Set(),
});

/**
 * Checks whether a filter is active. That is, whether it has any values.
 * @param {*} filters - the filters object from the store (containing all filters)
 * @param {*} key - the key of the filter to check
 * @returns {Boolean} - whether the filter is active
 */
const filterIsActive = (filters, key) => {
  const filter = filters[key];
  return !!(filter && filter.size);
};

/**
 * Generic function to check whether a school matches a filter. It checks whether the filter is active
 * and if it is, it checks whether the ids from the corresponding school property are included in the
 * filter.
 *
 * @param {*} param0 - Object with the following properties:
 * @param {*} param0.filters - the filters object from the store (containing all filters)
 * @param {Array} param0.ids = the ids to check against the filter
 * @param {Array} param0.filterKey: the key of the filter to check against the ids
 * @returns {Boolean} - whether the school matches the filter
 *
 * @example
 * const schoolSports = [1, 2, 3];
 *
 * const filters = {
 *  sportFilter: new Set([1, 2]),
 * };
 *
 * matchesIdFilter({ filters, ids: schoolSports, filterKey: 'sportFilter' }); // true
 *
 * filters.sportFilter = new Set([4]);
 *
 * matchesIdFilter({ filters, ids: schoolSports, filterKey: 'sportFilter' }); // false
 *
 */
const matchesIdFilter = ({
  filters, ids, filterKey, valueIfInactive = true,
}) => {
  if (!filterIsActive(filters, filterKey)) {
    return valueIfInactive;
  }
  const filter = filters[filterKey];
  return ids.filter((id) => filter.has(id)).length > 0;
};

export const getDistanceCategoryBounds = (distanceCategoryId) => {
  const { UPPER_BOUND } = DISTANCE_LABELS[distanceCategoryId];
  const isInfinity = UPPER_BOUND === Infinity;
  const lowerBound = isInfinity ? DISTANCE_LABELS[distanceCategoryId - 1].UPPER_BOUND : 0;
  return { lowerBound, upperBound: UPPER_BOUND };
};

/**
 * Specific function to check whether a school matches the distance filter. It checks whether the filter is active
 * and if it is, it checks whether the distance between the school and the home location is within the limits
 * of the filter.
 *
 * @param {*} param0 - Object with the following properties:
 * @param {*} param0.filters - the filters object from the store (containing all filters)
 * @param {*} param0.school - the school to check against the filter
 * @param {*} param0.homeLocation - the home location to check against the filter
 * @returns {Boolean} - whether the school is within the distance limits of the filter
 *
 * @example
 * const school = {
 *    location: {
 *      lat: 10.10,
 *      lon: 10.10,
 *    },
 * };
 *
 * const filters = {
 *    distanceFilter: new Set([4]), // distance > 5km
 * };
 *
 * const homeLocation = {
 *    lat: 10.15,
 *    lng: 10.10,
 * };
 *
 * // The distance between the school and the home location is 5.5 km
 *
 * matchesDistanceCategoryFilter({ filters, school, homeLocation }); // true
 *
 * filters.distanceFilter = new Set([3]); // distance <= 5km
 *
 * matchesDistanceCategoryFilter({ filters, school, homeLocation }); // false
 */
const matchesDistanceCategoryFilter = ({ filters, school, homeLocation }) => {
  if (!filterIsActive(filters, FILTER_KEYS.DISTANCE)) {
    return true;
  }
  const filter = filters[FILTER_KEYS.DISTANCE];

  return [...filter].some((id) => {
    const { lowerBound, upperBound } = getDistanceCategoryBounds(id);
    const { lat, lon: lng } = school.location;
    const distance = getDistanceBetweenCoordinates(
      { lat, lng },
      homeLocation,
      'km',
      3,
    );
    return distance >= lowerBound && distance <= upperBound;
  });
};

/**
 * Specific function to check whether a school matches the raw distance filter. It checks whether the filter is active
 * and if it is, it checks whether the distance between the school and the home location is within the limits
 * of the filter.
 *
 * @param {*} param0 - Object with the following properties:
 * @param {*} param0.filters - the filters object from the store (containing all filters)
 * @param {*} param0.school - the school to check against the filter
 * @param {*} param0.homeLocation - the home location to check against the filter
 * @returns {Boolean} - whether the school is within the distance limits of the filter
 *
 * @example
 * const school = {
 *    location: {
 *      lat: 10.10,
 *      lon: 10.10,
 *    },
 * };
 *
 * const filters = {
 *    rawDistanceFilter: 5.5, // distance <= 5.5 km
 * };
 *
 * const homeLocation = {
 *    lat: 10.15,
 *    lng: 10.10,
 * };
 *
 * // The distance between the school and the home location is 5.5 km
 *
 * matchesRawDistanceFilter({ filters, school, homeLocation }); // true
 *
 * filters.rawDistanceFilter = 5; // distance <= 5 km
 *
 * matchesRawDistanceFilter({ filters, school, homeLocation }); // false
 *
 */
const matchesRawDistanceFilter = ({ filters, school, homeLocation }) => {
  if (!filterIsActive(filters, FILTER_KEYS.DISTANCE_RAW)) {
    return true;
  }
  const filter = filters[FILTER_KEYS.DISTANCE_RAW];

  const distance = getDistanceBetweenCoordinates(
    { lat: school.location.lat, lng: school.location.lon },
    homeLocation,
    'km',
    3,
  );

  return distance <= filter;
};

/**
 * Specific function to check whether a school matches the multimedia filter. It checks whether the filter is active
 * and if it is, it checks whether the school has the multimedia options that are included in the filter.
 * It has to match all the options in the filter to be considered a match.
 *
 * @param {*} param0 - Object with the following properties:
 * @param {*} param0.filters - the filters object from the store (containing all filters)
 * @param {*} param0.school - the school to check against the filter
 * @returns {Boolean} - whether the school has the multimedia options that are included in the filter
 *
 * @example
 * const school = {
 *   multimedia_options: {
 *    has_drone_flight: true,
 *    has_tour: true,
 *    has_video: true,
 *    has_animated_landing: true,
 *    has_testimonial_director: false,
 *    ...
 *   },
 * };
 *
 * const filters = {
 * multimediaFilter: new Set([1, 2, 4, 5]),
 * };
 *
 * matchesMultimediaFilter({ filters, school }); // true
 *
 * filters.multimediaFilter = new Set([1, 2, 4, 5, 6]); // No testimonial from the director -> false
 */
const matchesMultimediaFilter = ({ filters, school }) => {
  if (!filterIsActive(filters, FILTER_KEYS.MULTIMEDIA)) {
    return true;
  }
  const filter = filters[FILTER_KEYS.MULTIMEDIA];

  const audiovisualIdPropertyMap = {
    1: 'has_drone_flight',
    2: 'has_tour',
    4: 'has_video',
    5: 'has_animated_landing', // GIF
    6: 'has_testimonial_director',
    8: 'has_testimonial_teacher',
    10: 'has_testimonial_coordinator',
    13: 'has_testimonial_ex_student',
  };

  return [...filter].every((id) => school.multimedia_options[audiovisualIdPropertyMap[id]]);
};

/**
 * Checks if a school matches the vacancies filter applied
 * @param {*} param0 - Object with the following properties:
 * @param {*} param0.filters - the filters object from the store (containing all filters)
 * @param {*} param0.school - the school to check against the filter
 * @returns {boolean} - true if the school matches the filter
 */
const matchesVacanciesFilter = ({
  filters, school, schoolsVacancies,
}) => {
  if (!filterIsActive(filters, FILTER_KEYS.HAS_VACANCIES)) {
    return true;
  }
  const years = filters[FILTER_KEYS.HAS_VACANCIES];

  return getVacancies({
    campusCode: school.campus_code, years, schoolsVacancies,
  });
};
/**
 * Checks if a school matches the modalities filter applied
 * @param {*} param0 - Object with the following properties:
 * @param {*} param0.filters - the filters object from the store (containing all filters)
 * @param {*} param0.school - the school to check against the filter
 * @returns {boolean} - true if the school matches the filter
 */
const matchesModalitiesFilter = ({
  filters, school,
}) => {
  if (!filterIsActive(filters, FILTER_KEYS.MODALITY)) {
    return true;
  }

  if (!school.programs) {
    return false;
  }

  const selectedModalities = filters[FILTER_KEYS.MODALITY];

  const highSchoolPrograms = school.programs.filter((program) => program.grade.id === 17 || program.grade.id === 18);
  if (highSchoolPrograms.length === 0) {
    return false;
  }

  return validateModalities({
    highSchoolPrograms, selectedModalities, modalityLabels: MODALITY_LABELS,
  });
};

/**
 * Specific function to check whether a school matches the payment filter. It checks whether the filter is active
 * and if it is, it checks whether the school has the payment category that is included in the filter.
 * Alternatively, if the payment agreement filter is active, it checks whether the school has at least one of the
 * agreements that are included in the filter.
 * @param {*} filters - the filters object from the store (containing all filters)
 * @param {*} school - the school to check against the filter
 * @param {*} agreementIds - the ids of the agreements of the school
 * @returns {Boolean} - whether the school matches the payment filter
 */
const matchesPaymentFilters = ({
  filters, school, agreementIds, matchesScholarship,
}) => {
  const paymentAgreementFilterIsActive = filterIsActive(filters, FILTER_KEYS.PAYMENT_AGREEMENT);
  const paymentFilterIsActive = filterIsActive(filters, FILTER_KEYS.PAYMENT);
  if (!(paymentFilterIsActive || paymentAgreementFilterIsActive)) {
    return true;
  }

  const paymentCategoryId = getCampusPaymentObject({ campus: school })?.payment_category?.id;
  const paymentIds = paymentCategoryId ? [paymentCategoryId] : [];

  if (matchesScholarship) {
    // Add "Free" payment category to the filter if the school has a scholarship for which the student is eligible
    paymentIds.push(1);
  }

  const matchesPaymentId = matchesIdFilter({
    filters, ids: paymentIds, filterKey: FILTER_KEYS.PAYMENT, valueIfInactive: false,
  });
  const matchesPaymentAgreement = matchesIdFilter(
    {
      filters, ids: agreementIds, filterKey: FILTER_KEYS.PAYMENT_AGREEMENT, valueIfInactive: false,
    },
  );

  // If the payment filter or the payment agreement filter are active, the school must match at least one of them
  return matchesPaymentAgreement || matchesPaymentId;
};

/**
 * Checks if a school matches the filters applied
 * @param {*} school - school to check
 * @param {*} filters - filters to check against
 * @param {*} homeLocation - home location to check against the distance filter
 * @returns {boolean} - true if the school matches the filters
 *
 * @example
 * const homeLocation = {
 *   lat: 10.15,
 *   lng: 10.10,
 * };
 *
 * const school = {
 *  agreement_set: [{ id: 1 }, { id: 2 }],
 *  sector: { id: 1 },
 * };
 *
 * const filters = {
 *  agreementFilter: new Set([1]),
 *  dependencyFilter: new Set([1]),
 * };
 *
 * schoolMatchesFilters(school, filters, homeLocation); // true
 *
 * const filters = {
 *  agreementFilter: new Set([1]),
 *  dependencyFilter: new Set([2]),
 * };
 *
 * schoolMatchesFilters(school, filters, homeLocation); // false
 */
export const schoolMatchesFilters = (school, filters, rootGetters) => {
  const forceScholarshipsEligibility = rootGetters['authentication/forceScholarshipEligibility'];
  const agreementIds = school.agreement_set?.map(({ id }) => id) || [];
  const dependencyIds = [school.sector?.id];
  const extraActivitiesIds = school.extraactivities?.map(({ id }) => id) || [];
  const infrastructuresIds = school.infrastructure?.map(({ id }) => id) || [];
  const languagesIds = school.languages?.map(({ id }) => id) || [];
  const partnershipIds = school.partnerships?.map(({ id }) => id) || [];

  const specialtiesIds = school.programs?.map((program) => program?.specialty) || [];
  const networkIds = [school?.network_id];

  const matchesScholarships = (
    matchScholarships({
      student: rootGetters['authentication/currentStudent'],
      campus: school,
      options: { forceEligibility: forceScholarshipsEligibility },
    }).length > 0
  );

  const performanceIds = school.performance_set?.map(
    ({ qualitycategory_level_label: qualityLabel }) => qualityLabel?.id,
  ).filter(Boolean) || [];
  const sportsIds = school.sports?.map(({ id }) => id) || [];

  const matchesAgreement = matchesIdFilter({ filters, ids: agreementIds, filterKey: FILTER_KEYS.AGREEMENTS });
  const matchesDependency = matchesIdFilter({ filters, ids: dependencyIds, filterKey: FILTER_KEYS.DEPENDENCIES });
  const matchesSpecialties = matchesIdFilter({ filters, ids: specialtiesIds, filterKey: FILTER_KEYS.SPECIALTIES });
  const matchesExtraActivities = matchesIdFilter(
    { filters, ids: extraActivitiesIds, filterKey: FILTER_KEYS.EXTRACURRICULARS },
  );
  const matchesInfrastructures = matchesIdFilter(
    { filters, ids: infrastructuresIds, filterKey: FILTER_KEYS.INFRASTRUCTURE },
  );
  const matchesLanguages = matchesIdFilter({ filters, ids: languagesIds, filterKey: FILTER_KEYS.LANGUAGES });
  const matchesPartnerships = matchesIdFilter({ filters, ids: partnershipIds, filterKey: FILTER_KEYS.PARTNERSHIPS });
  const matchesPayment = matchesPaymentFilters({
    filters, school, agreementIds, matchesScholarships,
  });
  const matchesPerformance = matchesIdFilter({ filters, ids: performanceIds, filterKey: FILTER_KEYS.PERFORMANCE });
  const matchesSports = matchesIdFilter({ filters, ids: sportsIds, filterKey: FILTER_KEYS.SPORTS });
  const matchesNetwork = matchesIdFilter({ filters, ids: networkIds, filterKey: FILTER_KEYS.NETWORK_ID });

  const homeLocation = rootGetters['authentication/homeLocation'];
  const matchesDistanceCategory = matchesDistanceCategoryFilter({ filters, school, homeLocation });
  const matchesDistanceRaw = matchesRawDistanceFilter({ filters, school, homeLocation });

  const matchesMultimedia = matchesMultimediaFilter({ filters, school });

  const schoolsVacancies = rootGetters['institutions/schoolsVacancies'];
  const matchesVacancies = matchesVacanciesFilter(
    {
      filters, school, schoolsVacancies,
    },
  );
  const matchesModalities = matchesModalitiesFilter({ filters, school });

  const matchesInstitution = matchesIdFilter(
    { filters, ids: [school.institution_code], filterKey: FILTER_KEYS.INSTITUTION },
  );

  const matches = (
    matchesAgreement
      && matchesDependency
      && matchesDistanceCategory
      && matchesDistanceRaw
      && matchesExtraActivities
      && matchesInfrastructures
      && matchesInstitution
      && matchesLanguages
      && matchesMultimedia
      && matchesNetwork
      && matchesPartnerships
      && matchesPayment
      && matchesPerformance
      && matchesSpecialties
      && matchesSports
      && matchesVacancies
      && matchesModalities
  );
  return matches;
};
