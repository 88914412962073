<template>
  <div>
    <TextAtom
      :value="description"
      class="mt-4 text-left d-flex pl-4 pb-3"
      tag="label"
      font="inter"
      color="primary-dark"
      weight="400"
      size="subtitle"
    />
    <section class="campus-detail__panels__container">
      <div class="panel_container_row fill-width">
        <img
          :src="require('@/assets/icons/infrastructure.svg')"
          class="campus-detail__panels__image mr-3"
          alt="infraestructura"
        />

        <div class="campus-detail__panels__title mb-2 font-weight-bold panel_container_row">
          {{ getInfraCount() }}

          <div class="ml-2">
            {{ $t('campus-details.panels.infrastructure') }}
          </div>
        </div>
      </div>
      <hr />
      <div
        v-for="(label, index) in infrastructures"
        :key="index"
        itemprop="amenityFeature"
        itemscope
        itemtype="https://schema.org/LocationFeatureSpecification"
      >
        <span
          v-if="label.infra_label.id !== 99"
          class="campus-detail__panels__title text-left mb-2 font-weight-medium bullet-point d-flex"
          itemprop="name"
        >{{ getLabel(label) }}
          <meta itemprop="value" content="True" />
          <meta
            v-if="infrastructures[index].descrip !== ''"
            itemprop="description"
            :content="infrastructures[index].descrip"
          >
          <meta
            v-if="infrastructures[index].campus_code !== ''"
            itemprop="identifier"
            :content="infrastructures[index].campus_code"
          >
          <meta itemprop="url" :href="canonicalLink">
        </span>
        <span
          v-if="label.infra_label.id === 99"
          class="campus-detail__panels__title text-left mb-2 font-weight-medium bullet-point d-flex"
          itemprop="name"
        > {{ getOtro(label) }}
          <meta itemprop="value" content="True" />
          <meta
            v-if="infrastructures[index].descrip !== ''"
            itemprop="description"
            :content="infrastructures[index].descrip"
          >
          <meta
            v-if="infrastructures[index].campus_code !== ''"
            itemprop="identifier"
            :content="infrastructures[index].campus_code"
          >
          <meta itemprop="url" :href="canonicalLink">
        </span>
      </div>

      <MessageInPanel from="infrastructure-panel" />
    </section>
    <br />
    <section class="campus-detail__panels__container">
      <div class="panel_container_row fill-width">
        <img
          :src="require('@/assets/icons/security.svg')"
          class="campus-detail__panels__image mr-3"
          alt="infraestructura"
        />

        <div class="campus-detail__panels__title mb-2 font-weight-bold panel_container_row">
          {{ getMeasureCount() }}

          <div class="ml-2">
            {{ $t('campus-details.panels.safety') }}
          </div>
        </div>
      </div>
      <hr />
      <div
        v-for="(safe, index) in safety"
        :key="safe.id"
        itemprop="amenityFeature"
        itemscope
        itemtype="https://schema.org/LocationFeatureSpecification"
      >
        <span
          v-if="safe.safety_label.id !== 99"
          class="campus-detail__panels__title text-left mb-2 font-weight-medium bullet-point d-flex"
          itemprop="name"
        > {{ getSafetyLabel(safe) }}
          <meta itemprop="value" content="True" />
          <meta
            v-if="safety[index].descrip"
            itemprop="description"
            :content="safety[index].descrip"
          >
          <meta
            v-if="safety[index].campus_code"
            itemprop="identifier"
            :content="safety[index].campus_code"
          >
          <meta itemprop="url" :href="canonicalLink">
        </span>
        <span
          v-if="safe.safety_label.id === 99"
          class="campus-detail__panels__title text-left mb-2 font-weight-medium bullet-point d-flex"
          itemprop="name"
        > {{ getOtro(safe) }}
          <meta itemprop="value" content="True" />
          <meta
            v-if="safety[index].descrip"
            itemprop="description"
            :content="safety[index].descrip"
          >
          <meta
            v-if="safety[index].campus_code"
            itemprop="identifier"
            :content="safety[index].campus_code"
          >
          <meta itemprop="url" :href="canonicalLink">
        </span>
      </div>

      <MessageInPanel from="safety_label-panel" />
    </section>
  </div>
</template>
<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import MessageInPanel from './MessageInPanel.vue';

export default {
  name: 'InfrastructurePanel',
  components: {
    MessageInPanel,
    TextAtom,
  },
  props: {
    infrastructures: {
      type: Array,
      required: true,
    },
    safety: {
      type: Array,
      required: true,
    },
    canonicalLink: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
  },
  methods: {
    getLabel(label) {
      return label.infra_label.id !== 99 ? label.infra_label.infra_name : null;
    },
    getSafetyLabel(label) {
      return label.safety_label.id !== 99 ? label.safety_label.name : null;
    },
    getOtro(label) {
      return label.descrip;
    },
    getInfraCount() {
      return this.infrastructures.length;
    },
    getMeasureCount() {
      return this.safety.length;
    },
  },
};
</script>
