<template>
  <svg xmlns="http://www.w3.org/2000/svg" :viewBox="`0 0 ${2 * radius} ${2 * radius}`" :width="`${size}px`" :height="`${size}px`">
    <!-- Outter circle border -->
    <circle
      :cx="radius"
      :cy="radius"
      :r="radius"
      fill="none"
      stroke-width="2px"
      stroke="white"
    />
    <!-- Inner circle (partially filled) -->
    <circle
      :cx="radius"
      :cy="radius"
      :r="internalRadius"
      stroke="white"
      :stroke-width="radius"
      fill="none"
      :stroke-dasharray="`${dashOffset} ${circumference}`"
      :transform="`rotate(-${90 + probability * 360} ${radius} ${radius})`"
      :opacity="0.6"
    />
  </svg>
</template>

<script>
export default {
  name: 'AdmissionProbabilityIcon',
  data() {
    return {
      internalRadius: 25,
    };
  },
  props: {
    fill: {
      type: String,
      default: '#FFFFFF',
    },
    probability: {
      type: Number,
      default: 0,
      validator: (value) => value >= 0 && value <= 1,
    },
    size: {
      type: Number,
      default: 24,
    },
  },
  computed: {
    radius() {
      return this.internalRadius * 2;
    },
    circumference() {
      return 2 * Math.PI * this.internalRadius;
    },
    dashOffset() {
      return this.circumference * (this.probability);
    },
  },
};

</script>
