var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-column",class:{
    'register-main--small-btn-div': _vm.small && !_vm.absolute,
    'register-main--btn-div': !_vm.small && !_vm.absolute && !_vm.medium,
    'search-location--btn-div': _vm.absolute,
    'register-main--btn-div--tiny': _vm.tiny,
  }},[_c('button',{class:{
      'register-main--btn-div--btn-main': !_vm.small && !_vm.absolute,
      'register-main--small-btn-div--btn-main': _vm.small && !_vm.absolute,
      'register-main--btn-div--btn-main--active':
        ((_vm.activeValue || _vm.isActive) && !_vm.activeLight) && !_vm.small && !_vm.absolute,
      'register-main--small-btn-div--btn-main--active':
        ((_vm.activeValue || _vm.isActive) && !_vm.activeLight) && _vm.small && !_vm.absolute,
      'search-location--btn-div--btn-main': _vm.absolute,
      'register-main--small-btn-div--btn-main--white': _vm.light,
      'register-main--small-btn-div--btn-main--purple': _vm.purple,
      'register-main--medium-btn-div': _vm.medium,
      'register-main--btn-div--btn-main--borders-transparency': _vm.withBorder,
    },attrs:{"type":"button"},on:{"click":function($event){return _vm.mainButtonClick()}}},[(_vm.loader)?_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"48px"}},[(_vm.loader)?_c('v-progress-circular',{staticClass:"d-flex align-center justify-center",style:(_vm.getLoaderSize()),attrs:{"indeterminate":"","color":"white"}}):_vm._e()],1):_vm._e(),(!_vm.loader)?_c('div',{directives:[{name:"t",rawName:"v-t",value:(_vm.text),expression:"text"}],staticClass:"d-flex align-center justify-center",style:(_vm.getSize)}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }