var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{key:_vm.componentKey,staticClass:"container-admission pb-7",class:{ 'p-3': !_vm.isMobile, 'ml-1': _vm.isMobile }},_vm._l((_vm.filterAdmissionProcesses(_vm.activeAdmissionProcesses)),function({
      name, start_date, end_date, description, steps, grade_range, image_link, ...props
    },index){return _c('div',{key:index,staticClass:"process-card p-4 md:p-8",attrs:{"id":name}},[_c('div',{staticStyle:{"display":"block"}},[_c('div',{staticClass:"d-flex mb-6"},[(image_link)?_c('img',{staticClass:"process-logo rounded-lg mr-4",attrs:{"src":image_link,"alt":name}}):_vm._e(),_c('div',{staticClass:"flex flex-col text-left"},[_c('TextAtom',{staticClass:"pr-3",attrs:{"value":name,"color":'primary-dark',"size":"title","font":"inter","weight":"700"}}),_c('TextAtom',{staticClass:"mt-1",attrs:{"value":_vm.getGradeRangeText(grade_range),"color":'primary-dark',"font":"inter","weight":"400"}})],1)]),(start_date && end_date)?_c('TextAtom',{staticClass:"text-left",staticStyle:{"color":"#6E7191"},attrs:{"value":_vm.getDateRange(start_date, end_date),"font":"inter","weight":"400"}}):_vm._e(),(description)?_c('TextAtom',{staticClass:"text-left mt-2",staticStyle:{"color":"#A0A3BD"},attrs:{"value":description,"font":"inter","weight":"400"}}):_vm._e(),_c('hr',{staticStyle:{"color":"#EFF0F6","margin-top":"1.5rem"}})],1),(steps.length > 0)?_c('div',_vm._l((steps),function({ order, details },stepIndex){return _c('div',{key:stepIndex,staticClass:"mt-6"},[_c('div',{staticClass:"text-left"},[_c('TextAtom',{staticClass:"pr-3",attrs:{"value":`${order}. ${details.name}`,"color":'primary-dark',"size":"subtitle","font":"inter","weight":"700"}}),_c('TextAtom',{staticClass:"mt-2",staticStyle:{"color":"#6E7191"},attrs:{"value":_vm.getDateRange(details.start_date, details.end_date),"font":"inter","weight":"400"}}),_c('TextAtom',{staticClass:"mt-2",staticStyle:{"color":"#A0A3BD"},attrs:{"value":details.description,"font":"inter","weight":"400"}})],1),_c('div',[(_vm.isPostulationToCome(details.start_date))?_c('button',{staticClass:"postulate-btn-inactive mt-3",attrs:{"type":"button","disabled":details.preapplication_link === '' || details.preapplication_link === null},on:{"click":function($event){return _vm.redirect(
              details.preapplication_link,
              'preapplication_step_button_click',
              {
                processId: props.id,
                processName: name,
                stepId: details.id,
                stepName: details.name,
              },
            )}}},[_vm._v(" "+_vm._s(details[`preapplication_button_text_${_vm.language}`] || _vm.$t('campus-details.admission.apply'))+" ")]):_vm._e(),(_vm.isPostulationOpen(details.start_date, details.end_date))?_c('button',{staticClass:"postulate-btn-active mt-3",attrs:{"type":"button","disabled":details.application_link === '' || details.application_link === null},on:{"click":function($event){return _vm.redirect(
              details.application_link,
              'application_step_button_click',
              {
                processId: props.id,
                processName: name,
                stepId: details.id,
                stepName: details.name,
              },
            )}}},[_vm._v(" "+_vm._s(details[`application_button_text_${_vm.language}`] || _vm.$t('campus-details.admission.apply'))+" ")]):_vm._e(),(_vm.isPostulationExpired(details.end_date))?_c('button',{staticClass:"postulate-btn-inactive mt-3",attrs:{"type":"button","disabled":details.postapplication_link === '' || details.postapplication_link === null},on:{"click":function($event){return _vm.redirect(
              details.postapplication_link,
              'postapplication_step_button_click',
              {
                processId: props.id,
                processName: name,
                stepId: details.id,
                stepName: details.name,
              },
            )}}},[_vm._v(" "+_vm._s(details[`postapplication_button_text_${_vm.language}`] || _vm.$t('campus-details.admission.apply'))+" ")]):_vm._e()])])}),0):_vm._e(),(steps.length === 0)?_c('div',{staticClass:"mt-6"},[_c('div',[(_vm.isPostulationToCome(start_date))?_c('button',{staticClass:"postulate-btn-inactive",attrs:{"type":"button","disabled":props.preapplication_link === '' || props.preapplication_link === null},on:{"click":function($event){return _vm.redirect(
            props.preapplication_link,
            'preapplication_process_button_click',
            {
              processId: props.id,
              processName: name,
            },
          )}}},[_vm._v(" "+_vm._s(props[`preapplication_button_text_${_vm.language}`] || _vm.$t('campus-details.admission.apply'))+" ")]):_vm._e(),(_vm.isPostulationOpen(start_date, end_date))?_c('button',{staticClass:"postulate-btn-active",attrs:{"type":"button","disabled":props.application_link === '' || props.application_link === null},on:{"click":function($event){return _vm.redirect(
            props.application_link,
            'application_process_button_click',
            {
              processId: props.id,
              processName: name,
            },
          )}}},[_vm._v(" "+_vm._s(props[`application_button_text_${_vm.language}`] || _vm.$t('campus-details.admission.apply'))+" ")]):_vm._e(),(_vm.isPostulationExpired(end_date))?_c('button',{staticClass:"postulate-btn-inactive",attrs:{"type":"button","disabled":props.postapplication_link === '' || props.postapplication_link === null},on:{"click":function($event){return _vm.redirect(
            props.postapplication_link,
            'postapplication_process_button_click',
            {
              processId: props.id,
              processName: name,
            },
          )}}},[_vm._v(" "+_vm._s(props[`postapplication_button_text_${_vm.language}`] || _vm.$t('campus-details.admission.apply'))+" ")]):_vm._e()]),_c('TextAtom',{staticClass:"mt-3 mb-3",attrs:{"value":_vm.getRemainingTime(start_date, end_date),"color":'primary-medium',"font":"inter","weight":"700"}})],1):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }