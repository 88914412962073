<template>
  <div
    class="register-main"
    :class="{ 'register-main--width-auto': rowInstructions }"
  >
    <p
      v-t="text"
      class="register-main--instruction-text"
      :class="{ 'register-main--instruction-text--no-margin': rowInstructions }"
    />
  </div>
</template>

<script>
export default {
  name: 'TitleInstruction',
  components: {},
  props: {
    text: {
      type: String,
      default: '',
    },
    rowInstructions: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
