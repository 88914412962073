var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"campus-detail__panels__container campus-detail__panels__container__3 pl-6 pr-6"},[_c('TextAtom',{staticClass:"text-left",attrs:{"value":_vm.$t('campus-details.current_performance.simce_tooltip'),"color":'primary-dark',"font":"inter","weight":"400"}}),_c('div',{staticClass:"fill-width"},[_c('div',{staticClass:"panel_container_row panel_container_row_wrap performance mt-5 mb-5"},[_vm._l((_vm.grades),function(grade){return [_c('div',{key:grade.index},[_c('button',{directives:[{name:"t",rawName:"v-t",value:(`${grade.grade}`),expression:"`${grade.grade}`"}],staticClass:"panel_button__color panel_button__color__text",class:{
              panel_button__color__activated: _vm.currentGrade === grade.index,
              panel_button__color__deactivated: _vm.currentGrade !== grade.index,
              panel_button__disabled: !_vm.hasSimce(grade.index),
              'mt-3': _vm.$vuetify.breakpoint.width < 416,
            },attrs:{"type":"button","disabled":!_vm.hasSimce(grade.index)},on:{"click":function($event){return _vm.choose_grade(grade.index)}}})])]})],2)]),_c('div',{staticClass:"panels__title panels__title__container text-left mb-2 mt-4"},[_c('TextAtom',{staticClass:"text-left",attrs:{"value":_vm.$t('campus-details.current_performance.average_performance'),"color":'primary-medium',"font":"poppins","weight":"600"}})],1),_c('section',{staticClass:"fill-width mt-3"},[_c('div',{staticClass:"panel_container_row panel_container_row--no-margin gap-2"},_vm._l((['', ..._vm.simceScores
          .filter(score => _vm.getLanguageScore(score) !== '-' || _vm.getMathsScore(score) !== '-')]),function(score,index){return _c('div',{key:index,staticClass:"panels_table_label_top"},[(index === 0)?_c('span',[_vm._v(" ‌ ")]):_c('div',{staticClass:"panels_table_year"},[_c('span',[_vm._v(" "+_vm._s(Object.keys(score)[0])+" ")])]),_vm._l(([_vm.$t('campus-details.panels.math'), _vm.$t('campus-details.panels.language.title')]),function(label,innerIndex){return _c('div',{key:label},[_c('div',{staticClass:"panel_container_row panels_table_label"},[(index === 0)?_c('span',[_vm._v(_vm._s(label))]):(innerIndex === 0)?_c('span',{staticClass:"panels_table_score"},[_vm._v(_vm._s(_vm.getMathsScore(score)))]):(innerIndex === 1)?_c('span',{staticClass:"panels_table_score"},[_vm._v(_vm._s(_vm.getLanguageScore(score)))]):_vm._e()])])})],2)}),0)]),_c('div',{staticClass:"panels__title panels__title__container text-left mb-2 mt-4"},[_c('TextAtom',{staticClass:"text-left",attrs:{"value":_vm.$t('campus-details.current_performance.average_neighborhood'),"color":'primary-medium',"font":"poppins","weight":"600"}})],1),_c('div',{staticClass:"panel_container_row mt-1"},[_c('img',{staticClass:"panel_container_row__abs_img ml-0",attrs:{"src":require('@/assets/icons/face-primary-300.svg'),"alt":"face"}}),_c('span',{staticClass:"panel_container_row__white panel_container_row--text panel_container_row--text__salmon campus-detail__panels__container__item"},[_vm._v(" "+_vm._s(_vm.getPercentile())+" ")]),_c('span',{staticClass:"campus-detail__panels__performance-title--salmon text-left d-flex align-center"},[_vm._v(" "+_vm._s(_vm.$t('campus-details.current_performance.simce_neighbor_1'))+" ")])]),(false)?_c('WidgetPercent'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }