<template>
  <div class="register-main">
    <div class="fill-width" style="position: relative">
      <label @click="clickInput()" @keydown.enter="clickInput()">
        <input
          v-model="mainValue"
          class="register-main--input"
          :class="{ 'register-main--input--iconTextSelect': withIconTextSelect && !passwordInput }"
          :type="setInputType()"
          min="0"
          :placeholder="$t(placeholder)"
          color="#7a7a7a"
          @keypress="handleKeyPress"
          @input="setInputValue(mainValue, passwordInput, index)"
          @blur="$emit('setBlurError')"
        />
      </label>
      <v-icon
        v-if="passwordInput"
        class="register-main--eye"
        @click="showPassword ? (showPassword = false) : (showPassword = true)"
      >
        {{ showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline' }}
      </v-icon>
    </div>
  </div>
</template>

<script>
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'RegisterInput',
  components: {},
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: -1,
    },
    // Number control input password 1 = first input, 2 = password validation Input
    passwordInput: {
      type: Number,
      default: 0,
    },
    inputType: {
      type: String,
      default: 'text',
    },
    mixpanelTag: {
      type: String,
      default: null,
    },
    clickMixpanelTag: {
      type: String,
      default: null,
    },
    withIconTextSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mainValue: null,
      showPassword: false,
    };
  },
  watch: {
    value: {
      handler(newValue) {
        this.mainValue = newValue;
      },
      immediate: true,
    },
  },
  methods: {
    setInputType() {
      if (this.inputType === 'password') {
        return this.showPassword ? 'text' : 'password';
      }
      return this.inputType;
    },
    setInputValue(mainValue, passwordInput, index) {
      if (this.mixpanelTag) {
        trackMixPanel(this.mixpanelTag);
      }
      this.$emit('setValue', mainValue, passwordInput, index);
    },
    clickInput() {
      if (this.clickMixpanelTag) {
        trackMixPanel(this.clickMixpanelTag);
      }
    },
    handleKeyPress(event) {
      if (this.inputType === 'number' && (event.charCode < 48 || event.charCode > 57)) {
        event.preventDefault();
      }
    },
  },
};
</script>
