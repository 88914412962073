<template>
  <v-btn
    depressed
    class="absolute z-10 button-my-location"
    type="button"
    style="bottom: 108px; right: 22px"
    @click="$emit('setCenter')"
  >
    <img src="@/assets/icons/my_location.svg" width="27px" alt="location" />
  </v-btn>
</template>

<script>
export default {
  name: 'CenterMap',
  components: {},
};
</script>
