<template>
  <div
    class="register-main register-main--svg-icon"
    :class="{
      'register-main--svg-icon--with-margin': hasTopMargin,
    }"
    :style="`width: ${size}px;`"
    @click="$emit('click')"
    @keydown.enter="$emit('click')"
  >
    <img
      :alt="altText"
      :class="{
        'register-main--svg-icon--rounded': rounded,
        'register-main--svg-icon--squared': squared,
      }"
      :width="`${size}px`"
      :src="require('@/assets/icons/' + icon)"
    />
  </div>
</template>

<script>
export default {
  name: 'SvgIcon',
  components: {},
  props: {
    icon: {
      type: String,
      default: 'face.svg',
    },
    size: {
      type: String,
      default: '92',
    },
    hasTopMargin: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    squared: {
      type: Boolean,
      default: false,
    },
    altText: {
      type: String,
      default: 'Icono Svg',
    },
  },
};
</script>
