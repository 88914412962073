import i18n from '@/plugins/i18n';

/**
 * Centralization of locale property getter. From the backend, many labels have a
 * locale-specific version. This function will return the locale-specific version if
 * it exists, otherwise it will return the default version.
 *
 * @param {Object} obj - The object to get the property from
 * @param {String} rootKey - The root key of the property
 * @returns {String} The locale-specific property if it exists, otherwise the default
 *
 * @example
 *
 * const AdmissionSystemLabel = {
 *  name: 'Name',
 *  name_en: 'Name in English',
 *  name_es: 'Name in Spanish',
 * };
 *
 * const name = getLocaleProperty(AdmissionSystemLabel, 'name');
 * // name === 'Name in English' if i18n.locale === 'en'
 * // name === 'Name in Spanish' if i18n.locale === 'es'
 * // name === 'Name' if i18n.locale === 'fr'
 *
 */
const getLocaleProperty = (obj, rootKey) => {
  if (!obj) {
    return '';
  }
  return obj[`${rootKey}_${i18n.locale}`] || obj[rootKey];
};

/**
 * Generalization for getting 18n properties that are objects. This is useful for
 * handling default values for properties that are objects.
 *
 * @param {String} i18nPath - The path to the i18n property
 * @param {String} propertyKey - The key of the property to get
 * @param {String} defaultKey - The key of the default property to get
 * @returns {String} The property if it exists, otherwise the default
 *
 * @example
 *
 * const i18nPath = 'admissionSystem.label';
 * const propertyKey = 'HIGH';
 *
 * // i18n file
 * const admissionSystem = {
 *  label: {
 *   HIGH: "foo",
 *   default: "bar",
 *  },
 * };
 *
 * const label = getI18NProperty(i18nPath, propertyKey);
 * // label === 'foo'
 *
 * const label = getI18NProperty(i18nPath, 'MEDIUM');
 * // label === 'bar'
 */
const getI18nPropertyCategoryText = (i18nPath, propertyKey, defaultKey = 'default') => {
  const i18nProperty = i18n.t(i18nPath);
  if (typeof i18nProperty !== 'object') {
    return i18nProperty;
  }
  return i18nProperty[propertyKey] || i18nProperty[defaultKey];
};

export {
  getLocaleProperty, getI18nPropertyCategoryText,
};
