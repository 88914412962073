<template>
  <div
    class="fill-width d-flex justify-center align-center"
    style="margin-top: 16px"
    @click="mixpanelClick()"
    @keydown="/* TODO */"
  >
    <img :src="require('@/assets/svg/' + setStep())" :alt="'step' + setStep('alt')" />
  </div>
</template>

<script>
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'RowStepper',
  components: {},
  props: {
    step: {
      type: Number,
      default: 0,
    },
    mixpanelTag: {
      type: String,
      default: null,
    },
  },
  methods: {
    setStep(alt) {
      switch (this.step) {
        case 1:
          if (alt) {
            return 1;
          }
          return 'step1.svg';
        case 2:
          if (alt) {
            return 2;
          }
          return 'step2.svg';
        case 3:
          if (alt) {
            return 3;
          }
          return 'step3.svg';
        case 4:
          if (alt) {
            return 4;
          }
          return 'step4.svg';
        default:
      }
      return '';
    },
    mixpanelClick() {
      trackMixPanel(this.mixpanelTag);
    },
  },
};
</script>
