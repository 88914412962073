<template>
  <div
    class="register-main register-main--info-box-wrap"
    :class="{
      'register-main--info-box-wrap--border': border,
      'register-main--info-box-wrap--fillWidth': fillWidth,
      'ma-0': noMargin,
    }"
    @click="infoBoxClick()"
    @keydown.enter="infoBoxClick()"
  >
    <section
      class="register-main--info-box-wrap--info-box"
      :class="{
        'register-main--info-box-wrap--info-box--left': alignLeft,
        'register-main--info-box-wrap--info-box--with-image': imageSource,
        'register-main--info-box-wrap--info-box--gray-background': grayBackground,
        'ma-0': noMargin,
        'p-5': infoPadding,
        'p-3': infoPaddingSmall,
      }"
    >
      <section class="d-flex">
        <img
          v-if="icon"
          style="margin-right: 12px;"
          :width="smallIcon ? '14' : '28'"
          :src="require('@/assets/icons/' + icon)"
          alt="infoBox"
        />
        <div>
          <p
            v-t="text"
            class="register-main--info-box-wrap--info-box--box-text"
            :class="{
              'register-main--info-box-wrap--info-box--box-text--center-header': centerHeader,
              'register-main--info-box-wrap--info-box--box-text--small': smallIcon,
              'register-main--info-box-wrap--info-box--box-text--thin': thinLine,
            }"
          />
          <p
            v-t="subText"
            class="register-main--info-box-wrap--info-box--box-text__subtitle"
            :class="{
              'register-main--info-box-wrap--info-box--box-text--center-header': centerHeader,
              'register-main--info-box-wrap--info-box--box-text--small': smallIcon,
              'register-main--info-box-wrap--info-box--box-text--thin': thinLine,
            }"
          />
        </div>
        <img v-if="imageSource" style="width: 60%; margin: 0 auto" :src="imageSource" alt="Institution Logo" />
      </section>
      <section v-if="infoArray.length > 0" style="margin: 10px 0 0 12px;">
        <div v-for="info, index in infoArray" :key="index" class="d-flex" style="margin-top: 16px;">
          <p
            v-if="info.content"
            v-t="info.title"
            class="register-main--info-box-wrap--info-box--array-box-text"
          />
          <p
            v-if="info.content"
            v-t="info.content"
            class="
              register-main--info-box-wrap--info-box--array-box-text
              register-main--info-box-wrap--info-box--array-box-text--content
            "
          />
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'LabelInfoBox',
  components: {},
  props: {
    text: {
      type: String,
      default: '',
    },
    subText: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    imageSource: {
      type: String,
      default: '',
    },
    infoArray: {
      type: Array,
      default: () => [],
    },
    border: {
      type: Boolean,
      default: false,
    },
    alignLeft: {
      type: Boolean,
      default: false,
    },
    fillWidth: {
      type: Boolean,
      default: false,
    },
    centerHeader: {
      type: Boolean,
      default: false,
    },
    mixpanelTag: {
      type: String,
      default: null,
    },
    grayBackground: {
      type: Boolean,
      default: false,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
    smallIcon: {
      type: Boolean,
      default: false,
    },
    infoPadding: {
      type: Boolean,
      default: false,
    },
    thinLine: {
      type: Boolean,
      default: false,
    },
    infoPaddingSmall: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    infoBoxClick() {
      if (this.mixpanelTag) {
        trackMixPanel(this.mixpanelTag);
      }
    },
  },
};
</script>
