<template>
  <section
    class="call-to-action-buttons"
    :style="mobile
      ? 'margin: 2px 0 0 0;' : 'margin: 2px 0 0 0'"
  >
    <section class="d-flex fill-width" style="padding: 0 28px">
      <div v-for=" info, index in setButtonInfo" :key="index" class="fill-width">
        <MainActionButtons
          :class="{
            'pr-1 mb-3': index === 0,
            'pl-1 mb-3': index === 1,
          }"
          :main-action-button-content="['option']"
          :options-buttons-text="info.text"
          :option-mixpanel-tag="info.tag"
          :option-button-square="info.squareButton"
          :option-primary-medium="info.primaryMedium"
          :active-option-button="info.active"
          :option-disabled-active="info.disabled"
          option-button-no-margin
          option-small-text
          @setAction="index === 0 ? scrollTo() : setCalendar()"
        />
      </div>
    </section>
    <v-dialog
      v-model="showCalendar"
      content-class="calendar-dialog"
      max-width="800px"
      min-width="350px"
      overlay-color="black"
      @click:outside="showCalendar = false"
    >
      <iframe
        title="calendly"
        height="100%"
        width="100%"
        frameborder="0"
        :src="calendarUrl()"
        alt="Calendly agendas"
        loading="lazy"
      />
    </v-dialog>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import MainActionButtons from '@/components/molecules/sections/MainActionButtons.vue';
import { urlWithHTTP } from '@/utils/strings';

export default {
  name: 'CampusDetailCallToActionDGE',
  components: {
    MainActionButtons,
  },
  data() {
    return {
      showCalendar: false,
    };
  },
  computed: {
    ...mapGetters({
      campusDetails: 'institutions/campusDetails',
      students: 'authentication/students',
      currentStudent: 'authentication/currentStudent',
      currentRole: 'roles/currentRole',
      isGuest: 'authentication/isGuest',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    setButtonInfo() {
      const buttonsArray = [
        {
          text: 'campus-details.dge_buttons.button_meeting',
          squareButton: true,
          primaryMedium: true,
          tag: 'click_profile_schedule-a-meeting_button1',
          active: true,
          disabled: this.isPrime() === false,
        },
        {
          text: 'campus-details.dge_buttons.button_visit',
          squareButton: true,
          primaryMedium: true,
          tag: 'click_profile_schedule-a-meeting_button2',
          active: false,
          disabled: this.isPrime() === false,
        },
      ];
      return buttonsArray;
    },
  },
  methods: {
    ...mapActions({
      setLoginModal: 'authentication/setLoginModal',
      setShowCard: 'explorer/setShowCard',
      setCurrentStudent: 'authentication/setCurrentStudent',
      setShiftWaitingList: 'institutions/setShiftWaitingList',
      setWaitingListFromProfile: 'institutions/setWaitingListFromProfile',
    }),
    setCalendar() {
      if (!this.showCalendar) {
        this.showCalendar = true;
        return this.showCalendar;
      }
      this.showCalendar = false;
      return this.showCalendar;
    },
    scrollTo() {
      document.getElementById('contact-us').scrollIntoView({ behavior: 'smooth' });
    },
    isPrime() {
      return this.campusDetails.config_campus?.[0]?.campus_tether_pack;
    },
    calendarUrl() {
      let url = this.campusDetails.config_campus?.[0]?.campus_calendly;
      url = urlWithHTTP(url);
      return url;
    },
  },
};
</script>
<style scoped>
.call-to-action-buttons {
  position: sticky;
  bottom: 0;
  z-index: 6000;
  background-color: #313F89;
  color: white;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem
}
</style>
