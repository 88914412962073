import { backendAPI } from '@/api';

const appUrl = '/institutions/favorite-campus-v2';

export default {
  addFavorite(data) {
    return backendAPI.post(`${appUrl}/add_favorite/`, {
      campus_code: data.campusCode,
      favorite_rank: 1,
    });
  },

  retrieveListFavoriteSchools() {
    return backendAPI.get(`${appUrl}/`);
  },

  removeFavorite(idFavorite) {
    return backendAPI.delete(`${appUrl}/${idFavorite}/`);
  },

};
