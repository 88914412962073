const state = {
  loading: false,
  loadingMarkers: false,
};

const mutations = {
  loading(state, value) {
    state.loading = value;
  },
  loadingMarkers(state, value) {
    state.loadingMarkers = value;
  },
};

const getters = {
  loading: (storeState) => storeState.loading,
  loadingMarkers: (storeState) => storeState.loadingMarkers,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
