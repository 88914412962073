<template>
  <section class="w-full h-full d-flex flex-column">
    <!-- Logos -->
    <picture
      class="d-flex justify-space-between w-full"
      :class="isMobile ? 'px-5' : 'px-10'"
    >
      <ImageAtom
        v-for="(logo, index) in feedbackLogos"
        :key="index"
        :src="require(`@/assets/logos/${logo}`)"
        object-fit="contain"
        :width="index === 0 ? 35 : 50"
      />
    </picture>
    <!-- Title -->
    <TextAtom
      class="text-left mt-10"
      :class="isMobile ? 'px-5' : 'px-10'"
      :value="getFeedbackName"
      tag="h1"
      font="poppins"
      color="primary-dark"
      weight="400"
      size="big"
    />
    <!-- Welcome -->
    <TextAtom
      class="text-left mt-5 feedback-text-wrap-balance"
      :class="isMobile ? 'px-5' : 'px-10'"
      :value="welcomeText"
      tag="p"
      font="inter"
      color="neutral-800"
      weight="400"
      size="normal"
    />
    <!-- family application -->
    <template v-if="showFamilyApplication">
      <TextAtom
        class="text-left mt-7"
        :class="isMobile ? 'px-5' : 'px-10'"
        :value="$t('feedback.information_students')"
        tag="p"
        font="inter"
        color="primary-dark"
        weight="600"
        size="normal"
      />
      <v-expansion-panels class="mt-1" multiple>
        <v-expansion-panel class="mt-3 d-flex flex-column justify-center align-center">
          <v-expansion-panel-header disable-icon-rotate expand-icon="" class="">
            <template #default="{ open }">
              <div
                class="feedback-container__family-application py-3"
                :class="isMobile ? 'px-4 mx-5' : 'px-5 mx-10'"
                @click="openFamilySimulation(open)"
                @keydown="openFamilySimulation(open)"
              >
                <!-- student -->
                <div class="d-flex flex-row mb-0">
                  <SvgIconV2 icon="persons" />
                  <TextAtom
                    :value="$t('feedback.family_application.title')"
                    tag="p"
                    font="inter"
                    color="neutral"
                    weight="600"
                    size="normal"
                    :class="isMobile ? 'ml-3' : 'ml-4'"
                  />
                </div>
                <!-- Risk message -->
                <TextAtom
                  :value="$t('feedback.family_application.message')"
                  tag="p"
                  font="inter"
                  color="neutral"
                  weight="500"
                  size="small"
                  class="ml-9 message-family-application"
                />
                <!-- Icon panel -->
                <span
                  v-t="`${open ? 'feedback.close' : 'feedback.open'}`"
                  class="btn-look-family-application btn-look-application"
                  :class="isMobile ? 'mr-4' : 'mr-5'"
                />
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mt-3 px-0">
            <FamilyApplication
              class="py-6"
              :class="isMobile ? 'px-5' : 'px-10'"
              :family-application="feedback.simulateFamily"
            />
            <FamilyApplicationQuestion
              class="py-8"
              :family-application="feedback.simulateFamily"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
    <!-- Students applications -->
    <TextAtom
      class="text-left mt-5"
      :class="isMobile ? 'px-5' : 'px-10'"
      :value="$t('feedback.information_students_applications')"
      tag="p"
      font="inter"
      color="primary-dark"
      weight="400"
      size="normal"
    />
    <v-expansion-panels v-model="panelsStudents" class="mt-1" multiple>
      <v-expansion-panel
        v-for="(student, index) in feedback.students"
        :key="index"
        class="mt-3 d-flex flex-column justify-center align-center"
      >
        <v-expansion-panel-header disable-icon-rotate expand-icon="" class="">
          <template #default="{ open }">
            <div
              class="feedback-container__title py-3"
              :class="isMobile ? 'px-3 mx-5' : 'px-5 mx-10'"
              @click="setMixPanel(student, open)"
              @keydown="setMixPanel(student, open)"
            >
              <!-- student -->
              <div class="d-flex flex-row">
                <SvgIconV2 icon="person" />
                <TextAtom
                  :value="$t('feedback.application_to', { name: student.firstName })"
                  tag="p"
                  font="inter"
                  color="neutral-800"
                  weight="400"
                  size="normal"
                  :class="isMobile ? 'ml-3' : 'ml-4'"
                />
              </div>
              <!-- Risk message -->
              <TextAtom
                v-if="false"
                v-show="showMessageRisk(student.unmatched)"
                :value="$t('feedback.warning_message')"
                tag="p"
                font="inter"
                color="error-check"
                weight="700"
                size="small"
              />
              <!-- Icon panel -->
              <span
                v-t="`${open ? 'feedback.close' : 'feedback.open'}`"
                class="btn-look-application"
                :class="isMobile ? 'mr-2' : 'mr-5'"
              />
            </div>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-3 px-0">
          <FeedbackSchoolsYouApplied
            :applications="student.applications"
            class="py-8"
            :class="isMobile ? 'px-5' : 'px-10'"
            :student-uuid="student.uuid"
          />
          <FeedbackProbabilityAlert
            v-if="student.unmatched > 0.3"
            class="py-10"
            :class="isMobile ? 'px-5' : 'px-10'"
            :unmatched="student.unmatched"
          />
          <FeedbackMessage
            v-if="showRecommendations(student?.recommendations ?? [])"
            class="py-10"
            :campuses="getTotalCampuses(student?.recommendations)"
            :class="isMobile ? 'px-5' : 'px-10'"
            :student="student.firstName"
          />
          <FeedbackMap
            v-if="showRecommendations(student?.recommendations ?? [])"
            :location-student="getStudentLocation(student.location)"
            :applications="student?.applications ?? []"
            :recommendations="student.recommendations.length > 0 ? student.recommendations : []"
          />
          <FeedbackRecommendations
            class="py-10"
            :class="isMobile ? 'px-5' : 'px-10'"
            :recommendations="student.recommendations.length > 0 ? student.recommendations : []"
            :show-recommendations="!showFamilyApplication"
            :student-uuid="student.uuid"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- Close feedback -->
    <FeedbackEnd class="mt-10 px-10" />
    <picture
      class="d-flex justify-space-between w-full pb-10"
      :class="isMobile ? 'px-5' : 'px-10'"
    >
      <ImageAtom
        v-for="(logo, index) in feedbackLogos"
        :key="index"
        :src="require(`@/assets/logos/${logo}`)"
        object-fit="contain"
        :width="index === 0 ? 35 : 50"
      />
    </picture>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import trackMixPanel from '@/utils/mixpanel';
import FeedbackMessage from '@/components/organisms/feedback/FeedbackMessage.vue';
import SvgIconV2 from '@/components/atoms/icons/SvgIconV2.vue';
import ImageAtom from '@/components/atoms/images/ImageAtom.vue';
import FeedbackSchoolsYouApplied from './FeedbackSchoolsYouApplied.vue';
import FeedbackProbabilityAlert from './FeedbackProbabilityAlert.vue';
import FeedbackMap from './FeedbackMap.vue';
import FeedbackRecommendations from './FeedbackRecommendations.vue';
import FeedbackEnd from './FeedbackEnd.vue';
import FamilyApplication from './FamilyApplication.vue';
import FamilyApplicationQuestion from './FamilyApplicationQuestions.vue';

export default {
  name: 'FeedbackContainer',

  components: {
    TextAtom,
    FeedbackSchoolsYouApplied,
    FeedbackProbabilityAlert,
    FeedbackEnd,
    FeedbackMap,
    FeedbackRecommendations,
    SvgIconV2,
    FeedbackMessage,
    FamilyApplication,
    ImageAtom,
    FamilyApplicationQuestion,
  },
  data() {
    return {
      panelsStudents: [],
    };
  },
  computed: {
    ...mapGetters({
      feedback: 'feedback/feedback',
      feedbackId: 'feedback/feedbackId',
    }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    welcomeText() {
      return +this.feedbackId === 5 ? this.$t('feedback.welcome_id_5') : this.$t('feedback.welcome');
    },
    getFeedbackName() {
      return this.$t('feedback.hello', { name: this.feedback.userName });
    },
    showFamilyApplication() {
      return Object.keys(this.feedback?.simulateFamily ?? {}).length > 0;
    },
    feedbackLogos() {
      return [
        'cl/mineduc.svg',
        'cl/sae.svg',
      ];
    },
  },
  mounted() {
    trackMixPanel('fbin_open_feedback');
    this.panelsStudents = [...new Array(this.feedback.students.length).fill(0)];
    this.panelsStudents[0] = 0;
  },
  methods: {
    // showMap(location) {
    //   // eslint-disable-next-line no-prototype-builtins
    //   return location?.location.default_coordinate?.hasOwnProperty('lat');
    // },
    // showMessageRisk(unmatched) {
    //   return unmatched > 0.7;
    // },
    // showProbabilityAlert(unmatched) {
    //   return unmatched > 0.3;
    // },
    openFamilySimulation(open) {
      const label = `fbin_postfam_${open ? 'close' : 'open'}`;
      trackMixPanel(label);
    },
    showRecommendations(recommendations) {
      if (Object.keys(recommendations).length === 0 || recommendations.length === 0) return false;
      return recommendations.filter((recommendation) => recommendation.map_category !== 'application').length > 0 || false;
    },
    getStudentLocation(location) {
      return {
        address_lat: location.address_lat,
        address_lon: location.address_lon,
      };
    },
    getTotalCampuses(recommendations) {
      return {
        around: recommendations?.length ?? 0,
        aroundPaymentAndPerformance: recommendations?.length > 0
          ? recommendations?.filter(
            (recommendation) => recommendation.map_category === 'highlighted',
          ).length : 0,
      };
    },
    setMixPanel(student, isOpen) {
      const nameTag = !isOpen ? 'fbin_postind_open_details' : ' fbin_postind_close_details';
      const dataMixpanel = { student: student.uuid, riskOrUnmatched: student?.unmatched };

      trackMixPanel(nameTag, { ...dataMixpanel });
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-look-application {
  color: white;
  background: var(--secondary-medium-light);
  width: auto;
  border-radius: 9px;
  position: absolute;
  right: 0;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.03px;
  width: 56px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-family-application {
  margin-top: -0.3rem;
}
.btn-look-family-application {
  color: white;
  background: var(--primary-dark);
}
.close-feedback {
  z-index: 2000 !important;
}
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent !important;
  border-radius: 10px !important;
}
.v-expansion-panel::before {
  box-shadow: none !important;
}
.v-expansion-panel-header {
  padding: 0 !important;
}

.theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
  border: none !important;
}

.v-expansion-panel-content {
  width: 100% !important;
  padding: 0 !important;
}

.v-expansion-panel-content__wrap {
  padding: 0px 0px 0px 0px !important;
  display: flex !important;
  width: 100%;
}

.feedback-container__title {
  background: white !important;
  border-radius: 8px !important;
  box-shadow: 0px 0px 15px 0px rgba(177, 177, 177, 0.15);
  position: relative;
  min-height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.feedback-container__family-application {
  background: var(--secondary-medium-light) !important;
  border-radius: 8px !important;
  box-shadow: 0px 0px 15px 0px rgba(177, 177, 177, 0.15);
  position: relative;
  min-height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
