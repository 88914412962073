import {
  CAMPUS_PROPERTIES,
  TETHER_CATEGORY,
} from '@/constants/category';
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import { buildCategoryObject, buildNoInfoCategoryObject } from './shared';

/**
 * Get the category of a performance property based on the performance data and the tenant
 * @param {*} performance - The performance data, as returned by the elastic search API
 * @returns {PropertyCategory} - The category of the performance property
 */
const getPerformanceCategory = (performance, defaultData = {}) => {
  const propertyKey = CAMPUS_PROPERTIES.PERFORMANCE;
  const { CATEGORIES: { PERFORMANCE } } = TENANT_CONFIGURATION;
  const categoryKey = PERFORMANCE[performance?.id];

  if (!categoryKey || categoryKey === TETHER_CATEGORY.NO_DATA) return buildNoInfoCategoryObject(propertyKey);
  return buildCategoryObject(
    propertyKey,
    categoryKey,
    { label: defaultData.PERFORMANCE },
  );
};

/**
 * Get the category of a campus performance property based on the campus data and the tenant
 * @param {*} campusData - The campus data, as returned by the elastic search API
 * @returns {PropertyCategory} - The category of the performance property
 */
const getCampusPerformanceCategory = (campus, typeConfiguration, defaultData) => {
  const propertyKey = CAMPUS_PROPERTIES.PERFORMANCE;
  const { SPECIAL_IDS: { DAYCARE_GRADES } } = TENANT_CONFIGURATION;

  const isDaycare = campus?.programs?.map(({
    grade,
  }) => DAYCARE_GRADES.includes(grade?.id)).every((isDaycareId) => isDaycareId) ?? false;

  if (isDaycare && typeConfiguration !== 'FEEDBACK') {
    return buildCategoryObject(
      propertyKey,
      TETHER_CATEGORY.SPECIAL,
      { icon: 'infant-face' },
    );
  }

  const hasPerformanceData = campus?.performance_set?.length > 0;
  if (!hasPerformanceData) return buildNoInfoCategoryObject(propertyKey);

  const campusQualityObject = campus?.performance_set?.[0]?.qualitycategory_level_label;
  return getPerformanceCategory(campusQualityObject, defaultData);
};

export {
  getCampusPerformanceCategory,
  getPerformanceCategory,
};
