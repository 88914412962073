<template>
  <section class="w-full d-flex flex-column justify-start pb-10">
    <SvgIcon
      icon="ring_blue.svg"
      size="30"
      alt="ring icon"
      class="mb-4"
    />

    <TextAtom
      :value="$t(messageEnd)"
      tag="p"
      font="inter"
      color="neutral-850"
      weight="400"
      size="normal"
      class="text-left"
    />

    <button v-t="'feedback.end.button'" type="button" class="mt-8 show-profile" @click="goToPage()" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'FeedbackEnd',
  components: {
    TextAtom, SvgIcon,
  },
  computed: {
    ...mapGetters({
      feedbackId: 'feedback/feedbackId',
    }),
    messageEnd() {
      return +this.feedbackId === 5 ? 'feedback.end.message_id_5' : 'feedback.end.message';
    },
  },
  methods: {
    goToPage() {
      trackMixPanel('fbin_web_sae');
      window.open('https://www.sistemadeadmisionescolar.cl/', '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
.reveal-animation {
  opacity: 0;
  animation: reveal 0.5s forwards var(--d)
}

.show-profile {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-medium-light);
  color: var(--neutral);
  border-radius: 30px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.28px;
}

@keyframes reveal {
  from {
    transform: translateY(20px);
  }
  to {
    opacity: 1; transform: none;
  }
}
</style>
