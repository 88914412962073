const setCookie = (name, value, expiration, scope = (process.env.VUE_APP_COOKIE_SCOPE || 'domain')) => {
  const keyValue = `${name}=${encodeURIComponent(value)};`;
  const scopeDepths = {
    subdomain: 3,
    domain: 2,
    open: 0,
  };
  const domainDepth = scopeDepths[scope] ?? 2;
  const domain = (
    window.location.hostname === 'localhost' || domainDepth === 0
      ? ''
      : `Domain=.${window.location.hostname.split('.').slice(-domainDepth).join('.')};`
  );
  const expires = expiration ? `Expires=${expiration};` : '';
  const path = 'Path=/;';
  document.cookie = `${keyValue}${expires}${domain}${path} Secure; SameSite=Lax`;
};

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
  return null;
};

const deleteCookie = (name) => {
  document.cookie = `${name}=; Expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/; Secure; SameSite=Lax`;
};

export { setCookie, getCookie, deleteCookie };
