<template>
  <section class="title-verified-account">
    <TextAtom
      tag="h1"
      :value="title"
      color="primary-dark"
      font="poppins"
    />
    <SvgIconV2
      :icon="`verified-account-${verified}`"
    />
    <SvgIconV2
      v-if="prime"
      icon="prime-account"
    />
    <SvgIconV2
      v-if="freemium"
      icon="freemium-account"
    />
  </section>
</template>

<script>
import SvgIconV2 from '@/components/atoms/icons/SvgIconV2.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';

export default {
  name: 'TitleVerifiedAccount',
  components: {
    TextAtom, SvgIconV2,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    prime: {
      type: Boolean,
      default: false,
    },
    verified: {
      type: Boolean,
      default: false,
    },
    freemium: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.title-verified-account {
  width: 100%;
  display: flex;
  align-items: center;
  & h1 {
    text-align: left;
  }

  & svg {
    margin-left: 0.3rem;
  }
}
</style>
