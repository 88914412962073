<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        :actions-tag="getActionsTag()"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <RowStepper v-if="!noStepper" :mixpanel-tag="getStepperTag()" :step="1" />
      <ModalContent
        :class="inWaitingList && !mobile ? 'deg-waiting-list--mobile' : ''"
        :content-sections="setSectionContent"
        :small-title-text="
          inStudentFlow
            ? 'user_register.identification.student_title'
            : 'user_register.identification.title'
        "
        small-title-left
        :input-section="inputContent"
        :subtitle-text="'menu.add-student-instructions'"
        @setValue="setValue"
      />
    </div>
    <CallToActions
      :types="['main']"
      :active-value="isActive"
      :main-button-text="'user_register.continue'"
      :main-button-tag="getMainButtonTag()"
      small
      :spaced="false"
      :loader="loader"
      @continue="nextStep()"
    />
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import { snakeToCamel } from '@/utils/strings';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ModalIdentification',
  components: {
    ModalHeader,
    CallToActions,
    RowStepper,
    ModalContent,
  },
  props: {
    inStudentFlow: {
      type: Boolean,
      default: false,
    },
    isNewStudent: {
      type: Boolean,
      default: false,
    },
    inDetailedRegister: {
      type: Boolean,
      default: false,
    },
    noStepper: {
      type: Boolean,
      default: false,
    },
    isInMap: {
      type: Boolean,
      default: false,
    },
    preFill: {
      type: Boolean,
      default: false,
    },
    preFillData: {
      type: Object,
      default() {
        return {};
      },
    },
    inWaitingList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      firstName: null,
      secondName: null,
      firstLastName: null,
      secondLastName: null,
      noInfoError: false,
      loader: false,
    };
  },
  computed: {
    ...mapGetters({
      legalGuardianUUID: 'authentication/legalGuardianUUID',
      currentStudent: 'authentication/currentStudent',
      modalInfoFlow: 'userRegister/modalInfoFlow',
    }),
    requiredFields() {
      if (this.inDetailedRegister) {
        return {
          firstName: this.firstName,
          firstLastName: this.firstLastName,
        };
      }
      return {
        firstName: this.firstName,
      };
    },
    isActive() {
      return !Object.values(this.requiredFields).some((field) => !field);
    },
    setSectionContent() {
      if (!this.inStudentFlow) {
        return ['smallTitle', 'registerInput'];
      }
      return ['smallTitle', 'registerInput', 'subtitle'];
    },
    inputContent() {
      // Checking for both options for backwards compatibility
      const inputContent = [
        {
          value: this.firstName,
          noInfoError: this.noInfoError ? this.inputInfoValidation(this.firstName) : false,
          placeholder:
            this.inDetailedRegister && !this.inStudentFlow
              ? 'user_register.identification.placeholder_first_name'
              : 'user_register.identification.placeholder_name',
          show: true,
        },
        {
          value: this.secondName,
          noInfoError: false, // never required
          placeholder: 'user_register.identification.placeholder_second_name',
          show: this.inDetailedRegister,
        },
        {
          value: this.firstLastName,
          noInfoError: this.noInfoError
            ? this.inputInfoValidation(this.firstLastName) && this.inDetailedRegister : false,
          placeholder:
            this.inDetailedRegister && !this.inStudentFlow
              ? 'user_register.identification.placeholder_first_surname'
              : 'user_register.identification.placeholder_surname',
          show: this.inDetailedRegister,
        },
        {
          value: this.secondLastName,
          noInfoError: false, // never required
          placeholder: 'user_register.identification.placeholder_second_surname',
          show: this.inDetailedRegister,
        },
      ];
      return inputContent;
    },
    overriddenUserUUID() {
      if (this.preFill) {
        return this.preFillData.uuid ?? null;
      }
      if (this.inStudentFlow) {
        return !this.isNewStudent && this.currentStudent.uuid ? this.currentStudent.uuid : null;
      }
      return this.legalGuardianUUID;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    /* FOR SYSTEM MODAL IF ITS ENABLED
    setSysModal() {
      if (this.modalInfoFlow && this.modalInfoFlow.length > 0) {
        const infoModal = this.modalInfoFlow.find((modal) => {
          if (modal.modal_code.indexOf('i') > -1) {
            return modal;
          }
          return null;
        });
        if (infoModal) {
          return infoModal;
        }
      }
      return null;
    },
    currentRoute() {
      return this.$route.name;
    },
    */
  },
  watch: {
    preFillData: {
      handler() {
        if (this.preFill) {
          this.doPreFill();
        } else {
          this.resetFields();
        }
      },
      deep: true,
    },
  },
  created() {
    if (this.preFill) {
      this.doPreFill();
    }
  },
  methods: {
    ...mapActions({
      identificationLgStep1: 'userRegister/identificationLgStep1',
      setStudents: 'newStudentRegister/setStudents',
      setModalFlowInfo: 'userRegister/setModalFlowInfo',
    }),
    doPreFill() {
      if (this.preFillData && Object.keys(this.preFillData).length > 0) {
        const baseAccessors = ['first_name', 'other_name', 'first_lastname', 'other_lastname'];
        const [firstNameProp, secondNameProp, firstLastNameProp, secondLastNameProp] = this
          .inStudentFlow
          ? baseAccessors
          : baseAccessors.map(snakeToCamel);
        this.firstName = this.preFillData[firstNameProp] ?? null;
        this.secondName = this.preFillData[secondNameProp] ?? null;
        this.firstLastName = this.preFillData[firstLastNameProp] ?? null;
        this.secondLastName = this.preFillData[secondLastNameProp] ?? null;
      }
    },
    resetFields() {
      this.firstName = null;
      this.secondName = null;
      this.firstLastName = null;
      this.secondLastName = null;
    },
    setValue(mainValue, passwordInput, index) {
      if (passwordInput === 0) {
        if (index === 0) {
          this.firstName = mainValue;
        }
        if (index === 1) {
          this.secondName = mainValue;
        }
        if (index === 2) {
          this.firstLastName = mainValue;
        }
        if (index === 3) {
          this.secondLastName = mainValue;
        }
      }
      return null;
    },

    nextStep() {
      this.loader = true;
      this.noInfoError = false;
      if (this.isActive) {
        if (this.inStudentFlow) {
          const studentsForm = {
            uuid: this.overriddenUserUUID,
            firstName: this.firstName,
            secondName: this.secondName,
            firstLastName: this.firstLastName,
            secondLastName: this.secondLastName,
          };
          const current = !this.isInMap;
          this.setStudents({ studentsForm, current }).then((student) => {
            this.$emit('nextStep', student);
          });
          /*
        if (this.setSysModal && this.currentRoute === 'Dashboard') {
          const infoModal = this.setSysModal;
          infoModal.action_completed = true;
          this.setModalFlowInfo({
            modalInfo: infoModal,
            modalUuid: this.setSysModal.uuid,
          });
        }
        */
        } else if (this.inDetailedRegister) {
          const personalInfo = {
            uuid: this.overriddenUserUUID,
            firstName: this.firstName,
            secondName: this.secondName,
            firstLastName: this.firstLastName,
            secondLastName: this.secondLastName,
          };
          this.identificationLgStep1({ personalInfo });
          this.$emit('nextStep');
        } else {
          const personalInfo = {
            uuid: this.overriddenUserUUID,
            firstName: this.firstName,
            firstLastName: this.firstLastName,
          };
          this.identificationLgStep1({ personalInfo }).then(() => {
            this.loader = false;
            this.$emit('nextStep');
          });
        }
      } else {
        this.noInfoError = true;
        this.loader = false;
      }
    },
    inputInfoValidation(value) {
      if (!value) {
        return true;
      }
      return false;
    },
    getActionsTag() {
      if (this.inStudentFlow && !this.inDetailedRegister) {
        return 'click_reg_quick-register-student-identification_back-button';
      }
      if (this.inStudentFlow && this.inDetailedRegister) {
        return 'click_reg_detailed-register-student-identification_back-button';
      }
      if (!this.inStudentFlow && this.inDetailedRegister) {
        return 'click_reg_detailed-register-identification_back-button';
      }
      return 'click_reg_quick-register-identification_back-button';
    },
    getStepperTag() {
      if (this.inStudentFlow && !this.inDetailedRegister) {
        return 'click_reg_quick-register-student-identification_row-stepper';
      }
      if (this.inStudentFlow && this.inDetailedRegister) {
        return 'click_reg_detailed-register-student-identification_row-stepper';
      }
      if (!this.inStudentFlow && this.inDetailedRegister) {
        return 'click_reg_detailed-register-identification_row-stepper';
      }
      return 'click_reg_quick-register-identification_row-stepper';
    },
    getMainButtonTag() {
      if (this.inStudentFlow && !this.inDetailedRegister) {
        return 'click_reg_quick-register-student-identification_continue-button';
      }
      if (this.inStudentFlow && this.inDetailedRegister) {
        return 'click_reg_detailed-register-student-identification_continue-button';
      }
      if (!this.inStudentFlow && this.inDetailedRegister) {
        return 'click_reg_detailed-register-identification_continue-button';
      }
      return 'click_reg_quick-register-identification_continue-button';
    },
  },
};
</script>
