<template>
  <div>
    <p
      v-t="'+'"
      class="register-main--plus"
    />
  </div>
</template>

<script>
export default {
  name: 'MainPlus',
};
</script>
