<template>
  <div
    class="register-main"
    :class="{
      // TODO: CHANGE SECONDARY COLOR rule to fix align
      'align-start': (secondaryColor || alignLeft),
      'register-main--width-auto': widthAuto,
    }"
    @click="trackPrimaryTextClick()"
    @keydown="/* TODO */"
  >
    <p
      v-t="text"
      class="register-main--primary-text"
      :class="{
        'register-main--primary-text--secondary': secondaryColor,
        'register-main--primary-text--tertiary': tertiaryColor,
        'register-main--primary-text--disabled': disabled,
        'register-main--primary-text--bold': bold,
        'register-main--primary-text--roboto': roboto || fontFamily === 'roboto',
        'register-main--primary-text--poppins': poppins || fontFamily === 'poppins',
        'register-main--primary-text--big': big,
        'register-main--primary-text--small': small,
        'register-main--primary-text--selected-tertiary': invertTertiaryColor,
        'register-main--primary-text--neutral500': neutral500,
        'register-main--primary-text--neutral800': neutral800,
        'register-main--primary-text--width-auto': widthAuto,
        'register-main--primary-text--center': center,
      }"
    />
  </div>
</template>

<script>
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'PrimaryText',
  props: {
    text: {
      type: [String, Object],
      default: '',
    },
    secondaryColor: {
      type: Boolean,
      default: false,
    },
    tertiaryColor: {
      type: Boolean,
      default: false,
    },
    neutral500: {
      type: Boolean,
      default: false,
    },
    neutral800: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    big: {
      type: Boolean,
      default: false,
    },
    roboto: {
      type: Boolean,
      default: false,
    },
    poppins: {
      type: Boolean,
      default: false,
    },
    fontFamily: {
      type: String,
      default: 'inter',
    },
    invertTertiaryColor: {
      type: Boolean,
      default: false,
    },
    alignLeft: {
      type: Boolean,
      default: false,
    },
    widthAuto: {
      type: Boolean,
      default: false,
    },
    mixpanelTag: {
      type: String,
      default: null,
    },
    center: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    trackPrimaryTextClick() {
      if (this.mixpanelTag) {
        trackMixPanel(this.mixpanelTag);
      }
      this.$emit('click');
    },
  },
};
</script>
