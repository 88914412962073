import { CAMPUS_PROPERTIES, TETHER_CATEGORY } from '@/constants/category';
import { ADMISSION_RISK_LABELS } from '@/constants/labels';
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import { getLocaleProperty } from '@/utils/locale';
import { buildCategoryObject, buildNoInfoCategoryObject, NO_ICON } from './shared';

/**
 * Get the admission risk label based on the risk data
 * @param {object} riskData - Risk data, as returned by the simulation dispatch
 * @param {number} riskData.risk - The risk value
 * @returns {AdmissionProbabilityLabel} - The category of the admission probability property
 */
const findAdmissionProbabilityLabel = ({ risk }) => (
  Object.values(ADMISSION_RISK_LABELS).find(({ UPPER_BOUND }) => risk < UPPER_BOUND)
);

/**
 * Get the category of a campus admission system property based on the campus data and the tenant
 * @param {*} campusData - The campus data, as returned by the elastic search API
 * @returns {PropertyCategory} - The category of the admission system property
 */
const getCampusAdmissionSystemLabel = (campusData) => {
  const { campus_admission_system: [admissionSystem = {}] = [] } = campusData;
  const admissionSystemLabel = admissionSystem.admission_system;

  const hasRisk = TENANT_CONFIGURATION.SPECIAL_IDS.PUBLIC_ADMISSION_SYSTEMS.includes(admissionSystemLabel?.id);
  return { admissionSystemLabel, hasRisk };
};

/**
 * Get the category of a campus admission property based on the campus data and the tenant
 * @param {*} campusData - The campus data, as returned by the elastic search API
 * @param {*} riskData - The risk data, as returned by a simulation dispatch
 * @returns {PropertyCategory} - The category of the admission property
 */
const getCampusAdmissionProbabilityCategory = (campusData, riskData, typeConfiguration, defaultData) => {
  // Three possible options:
  // 1. The campus has it's own admission system -> We just return that admission system
  // 2. The campus has a public admission system, but there's no risk data -> We return a no info category
  // 3. The campus has a public admission system, and there's risk data -> We return the risk category
  const propertyKey = CAMPUS_PROPERTIES.ADMISSION_RISK;
  // ADMISSION ONLY FOR PUBLIC APPLICATION SYSTEMS
  const { admissionSystemLabel, hasRisk } = getCampusAdmissionSystemLabel(campusData);
  if (typeConfiguration !== 'FEEDBACK'
  && (!admissionSystemLabel
    || !Object.keys(admissionSystemLabel).length)) { return buildNoInfoCategoryObject(propertyKey); }

  // eslint-disable-next-line no-param-reassign
  riskData = (riskData?.risk === undefined && typeConfiguration === 'FEEDBACK')
  || (typeConfiguration === 'FEEDBACK' && riskData?.risk === 0) ? { risk: 0.01 } : riskData;

  if (hasRisk && riskData && riskData?.risk && !campusData.riskLabel) {
    const { TETHER_CATEGORY: tetherCategory } = findAdmissionProbabilityLabel(riskData);
    return buildCategoryObject(propertyKey, tetherCategory, {}, typeConfiguration);
  } if (typeConfiguration === 'FEEDBACK' && campusData.riskLabel) {
    const categories = {
      1: 'LOW',
      2: 'MEDIUM_LOW',
      3: 'MEDIUM',
      4: 'HIGH',
      0: 'LOW',
    };
    // eslint-disable-next-line no-unsafe-optional-chaining
    const label = categories[+campusData?.riskLabel] === 0 ? 'Menos del 5%' : defaultData.ADMISSION_RISK;
    // eslint-disable-next-line no-unsafe-optional-chaining
    return buildCategoryObject('ADMISSION_RISK', categories[+campusData?.riskLabel], { label }, typeConfiguration);
  }
  const label = getLocaleProperty(admissionSystemLabel, 'admission_system_name');
  if (!label) return buildNoInfoCategoryObject(propertyKey);
  return buildCategoryObject(propertyKey, TETHER_CATEGORY.SPECIAL, { label, icon: NO_ICON });
};

export {
  getCampusAdmissionProbabilityCategory,
  getCampusAdmissionSystemLabel,
  findAdmissionProbabilityLabel,
};
