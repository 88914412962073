import App from '@/App.vue';
import CONFIG from '@/config';
import '@/directives';
import i18n from '@/plugins/i18n';
import Intercom from '@/plugins/intercom';
import vuetify from '@/plugins/vuetify';
import router from '@/router';
import store from '@/store';
import '@/styles/main.scss';
import '@/styles/tailwind.css';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import VueMask from 'v-mask';
import Vue from 'vue';
import VueGeolocation from 'vue-browser-geolocation';
import VueCalendly from 'vue-calendly';
import VueGtag from 'vue-gtag';
import Hotjar from 'vue-hotjar';
import VueMathjax from 'vue-mathjax';
import VueMeta from 'vue-meta';
import VueMixpanel from 'vue-mixpanel';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueVimeoPlayer from 'vue-vimeo-player';
import VueYoutube from 'vue-youtube';
import * as VueGoogleMaps from 'vue2-google-maps';
import Vuelidate from 'vuelidate';
import { setCookie } from '@/utils/cookies';

const inProduction = CONFIG.environment === 'production';

Vue.use(VueMathjax);
Vue.use(VueGeolocation);
// set default config cookies
const oneDayUTC = new Date(new Date().setDate(new Date().getDate() + 1));
setCookie('_ga', '1d', oneDayUTC);
setCookie('mp_54a0868c0a0ad44ca6742f3ad6ddcf7f_mixpanel', '1d', oneDayUTC);
setCookie('_ga_3G0ZRBEB0W', '1d', oneDayUTC);
Vue.use(VueMeta);

Vue.use(VueCalendly);

Vue.use(VueVimeoPlayer);

Vue.use(VueGtag, {
  config: { id: CONFIG.googleAnalyticsKey },
  enabled: inProduction,
});

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: CONFIG.googleMapsKey,
    libraries: ['geocoding', 'places', 'geometry'],
    language: process.env.VUE_APP_I18N_LOCALE || 'es-419',
  },
  installComponents: true,
});

Vue.use(Vuelidate);

Vue.use(VueYoutube);

Vue.use(VueReCaptcha, { siteKey: CONFIG.captchaKey });

Vue.use(Hotjar, {
  id: CONFIG.hotjarKey,
  isProduction: inProduction && CONFIG.hotjarOn,
});

Sentry.init({
  Vue,
  enabled: CONFIG.environment !== 'development',
  dsn: CONFIG.sentryDSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: CONFIG.environment,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

Vue.use(VueMixpanel, {
  token: CONFIG.mixpanelToken,
  config: {
    debug: CONFIG.mixpanelDebug,
    ignore_dnt: true,
  },
});
router.setStore(store);

Vue.use(VueMask);
Vue.use(Intercom, {
  appId: CONFIG.intercomAppId,
  // horizontal_padding: 70,
  // vertical_padding: 30,
});

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
