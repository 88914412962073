<template>
  <section class="placard-network" :style="isMobile ? 'flex-direction: column' : 'flex-direction: row'">
    <ImageAtom
      class="network_logo"
      :src="logo"
      alt="network_image"
      :style="{ width: isMobile ? '40%' : '30%' }"
    />
    <TextAtom :value="info" tag="p" font="inter" weight="300" color="neutral-800" size="normal" class="mx-2 " />
  </section>
</template>

<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import ImageAtom from '@/components/atoms/images/ImageAtom.vue';

export default {
  name: 'PlacardNetwork',
  components: {
    TextAtom, ImageAtom,
  },
  props: {
    info: {
      type: String,
      required: true,
    },
    logo: {
      type: String,
      required: true,
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>

<style>
.placard-network {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-radius: 1rem;
  margin: 1rem 0;
  padding: 0.8rem 0.5rem;
}
.network_logo {
  display: block;
  margin:auto;
  margin: 0 1rem;
}
</style>
