<template>
  <div class="register-main register-main--spaced">
    <div>
      <HeaderModal :content-sections="['close']" @close="$emit('close')" />
      <ContentModal
        :content-sections="['icon']"
        :icon-color="'#5627FF'"
        :icon-name="'mdi-alert-outline'"
        :icon-type="'warning'"
      />
      <ContentModal
        :content-sections="['subtitle']"
        subtitle-center
        :subtitle-text="'modals.no_student_modal.subtitle'"
        subtitle-light
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import HeaderModal from '@/components/molecules/modals/Header.vue';
import ContentModal from '@/components/molecules/modals/Content.vue';

export default {
  name: 'NoStudentWarningModal',
  components: {
    HeaderModal,
    ContentModal,
  },
  methods: {
    ...mapActions({
      setForcedStep: 'userRegister/setForcedStep',
    }),
  },
};
</script>
