const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

/**
 *  Convert object keys to snake case (e.g. camelCase to snake_case)
 * @param {*} _object: The object to convert
 * @returns {Object} - The object with snake_case keys
 *
 * @example
 * const obj = { camelCase: 'value' };
 * const snakedObj = camelToSnake(obj);
 * console.log(snakedObj); // { camel_case: 'value' }
 */
const camelToSnake = (_object) => Object.fromEntries(Object.entries(_object).map(([key, value]) => [key.replace(/([A-Z])/g, '_$1').toLowerCase(), (value && typeof value === 'object') ? camelToSnake(value) : value]));
/**
 * Convert object keys to camel case (e.g. snake_case to camelCase)
 * @param {*} _object: The object to convert
 * @returns {Object} - The object with camelCase keys
 *
 * @example
 * const obj = { snake_case: 'value' };
 * const camelObj = snakeToCamel(obj);
 * console.log(camelObj); // { snakeCase: 'value' }
 */
const snakeToCamel = (_object) => Object.fromEntries(Object.entries(_object).map(([key, value]) => [key.replace(/_([a-z])/g, (g) => g[1].toUpperCase()), (value && typeof value === 'object') ? snakeToCamel(value) : value]));

export { deepCopy, camelToSnake, snakeToCamel };
