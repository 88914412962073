<template>
  <section class="placard-generate-by-ai" :style="isMobile ? 'flex-direction: column' : 'flex-direction: row'">
    <SvgLogo
      :logo="'providers/openai.svg'"
      :width-auto="true"
      svg-width="90"
      class="mt-3 mr-3"
      alt="open ai"
      no-margin
      left
    />
    <TextAtom :value="$t('placard.open_ai')" tag="p" font="inter" color="neutral-700" weight="400" size="normal" />
  </section>
</template>

<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import SvgLogo from '@/components/atoms/logos/SvgLogo.vue';

export default {
  name: 'PlacardGeneratedByAi',
  components: {
    TextAtom, SvgLogo,
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>

<style>
.placard-generate-by-ai {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  background: #fafafa;
  padding: 1rem 1.5rem;
  margin: -1.4rem;
  overflow: hidden;
}
</style>
