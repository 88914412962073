<template>
  <div class="register-main">
    <div
      class="register-main--upload-button"
      :class="{ 'register-main--upload-button--active': fileName }"
      @click="chooseFiles()"
      @keydown.enter="chooseFiles()"
    >
      <button v-t="fileName !== '' && fileName !== null ? fileName : placeholder" type="button" />
      <img src="@/assets/icons/upload.svg" alt="upload" />
      <input
        :id="'fileUpload' + index"
        ref="inputFile"
        aria-label="upload"
        type="file"
        :accept="fileTypes"
        name="attachment[]"
        hidden
        @change="onFileChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegisterFileInput',
  components: {},
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    fileTypes: {
      type: String,
      default: '*',
    },
    index: {
      type: Number,
      default: 0,
    },
    file: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fileName: this.file,
    };
  },
  watch: {
    file() {
      this.fileName = this.file;
    },
  },
  methods: {
    onFileChange(event) {
      this.fileName = event.target.files[0].name;
      this.setImages(event);
      return this.fileName;
    },
    chooseFiles() {
      document.getElementById(`fileUpload${this.index}`).click();
      return null;
    },
    setImages(event) {
      const selectedImage = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const index = 1;
        this.image = e.target.result.split(',')[index];
        const imageInfo = {
          data_b64: this.image,
          filename: this.fileName,
          image_number: this.index,
        };
        this.$emit('setValue', imageInfo, this.index);
      };
      reader.readAsDataURL(selectedImage);
    },
  },
};
</script>
