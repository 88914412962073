<template>
  <section>
    <v-expansion-panels
      v-if="
        familyApplication.tiene_juntos || familyApplication.tiene_separados || familyApplication.tiene_no_asignado"
      multiple
    >
      <v-expansion-panel class="d-flex flex-column justify-center align-center">
        <v-expansion-panel-header
          :class="isMobile ? 'question-panel-header__mobile' : 'question-panel-header__desktop'"
        >
          <template #default="{}">
            <div
              @click="setMixpanel('fbin_postfam_masresultados')"
              @keydown="setMixpanel('fbin_postfam_masresultados')"
            >
              <TextAtom
                :value="$t('feedback.family_application.probability.more_results')"
                tag="span"
                font="poppins"
                color="primary-dark"
                weight="500"
                size="normal"
                class="text-left"
              />
            </div>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <section class="w-full d-flex flex-column bg-white mt-4 pb-4" :class="isMobile ? 'px-5' : 'px-10'">
            <section
              v-for="(probability, index) in getListOtherProbabilityFamilyApplication"
              :key="index + probability.description + openDialog"
              class="family-application__probability mt-5 py-5"
            >
              <!-- Dialog -->
              <div v-if="showDialogs[index]" class="family-application__modal">
                <section>
                  <button type="button" @click="showDialog(index, false)">
                    <TextAtom
                      class="text-left mt-7"
                      value="x"
                      tag="span"
                      font="inter"
                      color="primary-dark"
                      weight="400"
                      size="small"
                    />
                  </button>
                  <TextAtom
                    class="text-left"
                    :value="probability.details"
                    tag="span"
                    font="inter"
                    color="primary-dark"
                    weight="400"
                    size="small"
                  />
                </section>
              </div>
              <!-- end dialog -->
              <div class="family-application__probability--header flex-row-reverse mb-2">
                <button
                  v-t="$t('feedback.family_application.show_details')"
                  type="button"
                  @click="showDialog(index, true)"
                />
              </div>
              <div class="d-flex flex-row align-center" style="height: 50px;">
                <SvgIconV2 :icon="probability.avatar" class="type-application__icon" style="width: 140px;" />
                <TextAtom
                  :value="probability.description"
                  tag="span"
                  font="inter"
                  color="neutral-800"
                  weight="400"
                  size="small"
                  class="text-left px-3"
                />
              </div>
              <TextAtom
                :value="$t('feedback.family_application.note')"
                tag="span"
                font="inter"
                color="neutral-700"
                weight="400"
                size="small"
                class="text-left mt-3 ml-2"
              />
              <hr class="my-3" />
              <TextAtom
                :value="$t('feedback.family_application.probability.title')"
                tag="span"
                font="poppins"
                color="primary-dark"
                weight="500"
                size="normal"
                class="text-left"
              />
              <div class="d-flex flex-column justify-space-between mt-2">
                <div class="d-flex flex-row justify-space-between">
                  <div
                    class="students-probability"
                    :class="familyApplication?.postulacion_familiar ? 'probability-selected' : ''"
                  >
                    <TextAtom
                      :value="$t('feedback.family_application.probability.type.family')"
                      tag="span"
                      font="inter"
                      :color="familyApplication?.postulacion_familiar ? 'primary-dark' : 'primary-medium'"
                      weight="400"
                      size="normal"
                      class="text-left"
                    />
                    <TextAtom
                      :value="String(probability.probability.family)"
                      tag="span"
                      font="inter"
                      :color="familyApplication?.postulacion_familiar ? 'primary-dark' : 'primary-medium'"
                      weight="500"
                      size="subtitle"
                      class="text-left"
                    />
                  </div>
                  <div
                    class="students-probability"
                    :class="!familyApplication?.postulacion_familiar ? 'probability-selected' : ''"
                  >
                    <TextAtom
                      :value="$t('feedback.family_application.probability.type.independent')"
                      tag="span"
                      font="inter"
                      :color="!familyApplication?.postulacion_familiar ? 'primary-dark' : 'primary-medium'"
                      weight="400"
                      size="normal"
                      class="text-left"
                    />
                    <TextAtom
                      :value="String(probability.probability.independent)"
                      tag="span"
                      font="inter"
                      :color="!familyApplication?.postulacion_familiar ? 'primary-dark' : 'primary-medium'"
                      weight="500"
                      size="subtitle"
                      class="text-left"
                    />
                  </div>
                </div>
                <div
                  class="mt-2 d-flex students-probability__disclaimer"
                  :class="!familyApplication?.postulacion_familiar ? 'flex-row-reverse' : 'flex-row'"
                >
                  <TextAtom
                    class="text-left -mt-1"
                    :value="$t('feedback.family_application.probability.selected')"
                    tag="p"
                    font="inter"
                    color="neutral-800"
                    weight="300"
                    size="small"
                  />
                </div>
              </div>
            </section>
          </section>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels v-if="familyApplication.tiene_dinamico" multiple>
      <v-expansion-panel class="d-flex flex-column justify-center align-center mt-4">
        <v-expansion-panel-header
          :class="isMobile ? 'question-panel-header__mobile' : 'question-panel-header__desktop'"
        >
          <template #default="{}">
            <div
              @click="setMixpanel('fbin_postfam_resultadosseparados')"
              @keydown="setMixpanel('fbin_postfam_resultadosseparados')"
            >
              <TextAtom
                :value="$t('feedback.family_application.probability.question_different')"
                tag="span"
                font="poppins"
                color="primary-dark"
                weight="500"
                size="normal"
                class="text-left"
              />
            </div>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <section class="w-full d-flex flex-column bg-white mt-4 pb-4" :class="isMobile ? 'px-5' : 'px-10'">
            <TextAtom
              :value="$t('feedback.family_application.probability.question_different_description')"
              tag="p"
              font="inter"
              color="neutral-800"
              weight="400"
              size="default"
              class="text-left my-5"
            />

            <TextAtom
              :value="$t('feedback.family_application.probability.table.title')"
              tag="p"
              font="inter"
              color="neutral-800"
              weight="600"
              size="default"
              class="text-left my-5"
            />

            <div class="table-header">
              <TextAtom
                v-for="(header, index) in getHeaderTable"
                :key="header + index"
                :value="header"
                tag="p"
                font="inter"
                color="primary-medium"
                :weight="index === 0 ? '500' : '400'"
                size="default"
                class="text-left -mt-1"
              />
            </div>

            <section class="table-container">
              <div
                v-for="(table, index) in getTableYears"
                :key="table.year + index"
                :class="index + 1 !== getTableYears.length ? 'border-table' : ''"
              >
                <span>
                  {{ table.year }}
                </span>
                <div>
                  <span class="d-flex flex-row">
                    <span class="table-container__total">{{ table.totalApplication }}</span>
                    {{ table.totalApplicationDescription }}
                  </span>
                  <span class="d-flex flex-row justify-start text-left mt-2">
                    <span class="table-container__total">{{ table.totalAssigned }}</span>
                    {{ table.totalAssignedDescription }}
                  </span>
                </div>
                <div />
              </div>
            </section>
          </section>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </section>
</template>
<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import SvgIconV2 from '@/components/atoms/icons/SvgIconV2.vue';
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'FamilyApplicationQuestions',
  components: {
    TextAtom,
    SvgIconV2,
  },
  props: {
    familyApplication: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialogs: [],
      openDialog: 0,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    getHeaderTable() {
      return [
        this.familyApplication?.nombre_campus_dinamico ?? '',
        this.familyApplication?.grade_label_dinamico ?? '',
      ];
    },
    getTableYears() {
      const years = [];

      if (this.familyApplication?.postularon_2022 !== null) {
        years.push({
          year: '2022',
          totalApplication: this.familyApplication?.postularon_2022 ?? '',
          totalAssigned: this.familyApplication?.quedaron_2022 ?? '',
          totalApplicationDescription: 'Hermanos postularon',
          totalAssignedDescription: this.familyApplication?.postularon_2022 === 0 ? 'Hermanos hubiesen quedado' : 'Hermanos quedaron',
        });
      }

      if (this.familyApplication?.postularon_2021 !== null) {
        years.push({
          year: '2021',
          totalApplication: this.familyApplication?.postularon_2021 ?? '',
          totalAssigned: this.familyApplication?.quedaron_2021 ?? '',
          totalApplicationDescription: 'Hermanos postularon',
          totalAssignedDescription: this.familyApplication?.postularon_2021 === 0 ? 'Hermanos hubiesen quedado' : 'Hermanos quedaron',
        });
      }

      if (this.familyApplication?.postularon_2020 !== null) {
        years.push({
          year: '2020',
          totalApplication: this.familyApplication?.postularon_2020 ?? '',
          totalAssigned: this.familyApplication?.quedaron_2020 ?? '',
          totalApplicationDescription: 'Hermanos postularon',
          totalAssignedDescription: this.familyApplication?.postularon_2020 === 0 ? 'Hermanos hubiesen quedado' : 'Hermanos quedaron',
        });
      }
      return years;
    },
    getListOtherProbabilityFamilyApplication() {
      const probabilities = [];

      if (this.familyApplication?.tiene_juntos) {
        probabilities.push({
          details: this.familyApplication?.juntos_explicacion ?? '',
          description: this.$t('feedback.family_application.probability.same'),
          avatar: 'family-same',
          probability: {
            family: this.familyApplication?.juntos_prob_familiar ?? '',
            independent: this.familyApplication?.juntos_prob_independiente ?? '',
          },
        });
      }

      if (this.familyApplication?.tiene_separados) {
        probabilities.push({
          details: this.familyApplication?.separados_explicacion ?? '',
          description: this.$t('feedback.family_application.probability.different'),
          avatar: 'family-different',
          probability: {
            family: this.familyApplication?.separados_prob_familiar ?? '',
            independent: this.familyApplication?.separados_prob_independiente ?? '',
          },
        });
      }

      if (this.familyApplication?.tiene_no_asignados) {
        probabilities.push({
          details: this.familyApplication?.no_asignados_explicacion ?? '',
          description: this.$t('feedback.family_application.probability.one'),
          avatar: 'family-no-assigned',
          probability: {
            family: this.familyApplication?.no_asignados_prob_familiar ?? '',
            independent: this.familyApplication?.no_asignados_prob_independiente ?? '',
          },
        });
      }
      return probabilities;
    },
  },
  mounted() {
    this.showDialogs = this.getListOtherProbabilityFamilyApplication.map(() => false);
  },
  methods: {
    setMixpanel(event) {
      trackMixPanel(event);
    },
    showDialog(index, status) {
      this.showDialogs[index] = status;
      this.openDialog += 1;
    },
  },
};
</script>
<style scoped>
.feedback-container__family-application {
  background: var(--secondary-medium-light) !important;
  border-radius: 8px !important;
  box-shadow: 0px 0px 15px 0px rgba(177, 177, 177, 0.15);
  position: relative;
  min-height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.table-header {
  border-radius: 8px;
  background: #F5F5F5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
}

.table-container {
  margin-top: 0.5rem;
  border-radius: 8px;
  background: #F5F5F5;
}

.table-container > div {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0.7rem 0.4rem 0.7rem 1rem;
}

.table-container > div > span {
  color: #20D1BF;
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 120% */
  letter-spacing: 0.625px;
  text-transform: uppercase;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 20px;
  padding: 0 0.2rem;
}

.table-container > div > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 1rem;
}

.table-container > div > div > span{
  color: var(--tether-ed-ref-neutral-900, #565656);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.035px;
}

.table-container__total {
  font-weight: 500;
  width: 36px;
  text-align: left;
}

.border-table{
  border-bottom: 0.2px solid #DDD;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent !important;
  border-radius: 10px !important;
}
.v-expansion-panel::before {
  box-shadow: none !important;
}
.v-expansion-panel-header {
  padding: 0  1rem !important;
  background: white;
  border-radius: 8px;
}

.theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
  border: none !important;
}

.v-expansion-panel-content {
  width: 100% !important;
  padding: 0 !important;
}

.v-expansion-panel-content__wrap {
  padding: 0px 0px 0px 0px !important;
  display: flex !important;
  width: 100%;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.question-panel-header__desktop {
  width: calc(100% - 5rem) !important;
}

.question-panel-header__mobile {
  width: calc(100% - 2.5rem) !important;
}
/* deprecated */
a {
  color: #5627FF !important;
  font-family: Inter !important;
  font-size: 14px;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.035px;
  text-decoration-line: underline;
  text-align: left;
  margin-top: 1rem;
}

.type-application {
  color: white;
  background: var(--secondary-medium-light);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.7rem;
}
.student {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: var(--neutral);
  border-radius: 8px;
}

.student__name {
  background: #EEE9FF;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 50px;
}

.student__name > span {
  margin-top: 0.2rem;
  background: white;
  border-radius: 4px;
  width: 30px;
  color: #5627FF;
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}

.student__name--probability {
  border-radius: 8px;
  width: 50px;
  height: 50px
}

.student__name--probability > span {
  font-size: 10px !important;
  width: 100%;
  padding: 0.2rem 0;
}

.student__name--probability > svg {
  width: 15px;
  height: 15px;
}

.student__information {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  padding: 0.3rem 0.7rem;
}

.student_menor {
  background: #D5FDF9;
}

.student_menor > span {
  color: #20D1BF !important;
}

.family-application__modal {
  position: absolute;
  display: flex;
  justify-content: center;
  background: rgba(99, 99, 99, 0.50);
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: -20px;
  margin-left: -1rem;
  border-radius: 8px;
  padding: 0;
  box-sizing: border-box;
}

.family-application__modal > section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 1rem;
  width: 80%;
  margin-top: 1rem;
  max-height: 120px;
  border-radius: 16px 7px 16px 16px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(113, 113, 113, 0.15);
}

.family-application__probability {
  position: relative;
  border-radius: 8px;
  background: #F5F5F5;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
}

.family-application__probability--header {
  display: flex;
  width: 100%;
  justify-content: space-between;;
}

.family-application__probability--header > button {
  color: #5627FF;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 9.6px; /* 80% */
  letter-spacing: 0.03px;
  text-decoration-line: underline;
}
.family-application__probability > hr {
  border: 0px;
  border-bottom: 0.5px solid #b7b4b4;
}

.students-probability {
  width: 48%;
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
}

.probability-selected {
  background: #DDD4FF;
}

</style>
