<template>
  <WaitingListFlow
    :program-id="programId"
    :from-link="fromLink"
  />
</template>
<script>
import WaitingListFlow from '@/components/templates/WaitingListFlow.vue';
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import { mapActions } from 'vuex';

export default {
  name: 'WaitingList',
  components: {
    WaitingListFlow,
  },
  props: {
    fromLink: {
      type: Boolean,
      default: false,
    },
    program: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      programId: '',
    };
  },
  async mounted() {
    this.programId = this.program;
    if (this.fromLink) {
      await this.openWaitingListFromUrl();
    }
  },
  methods: {
    ...mapActions({
      setGuestData: 'authentication/setGuestData',
      retrieveCampusDetails: 'institutions/retrieveCampusDetails',
      setExitsInfoGuest: 'authentication/setExitsInfoGuest',
    }),
    async openWaitingListFromUrl() {
      const campusCode = this.$route.params.campusCode ?? 'no url';
      const programId = this.$route.params.programId ?? 'no url';
      if (campusCode !== 'no url' && programId !== 'no url') {
        this.programId = programId;
        await this.setGuest();
        await this.retrieveCampusDetails({ searchCode: campusCode, fromUrl: true });
      }
    },
    async setGuest() {
      const { LOCATION, GRADES } = TENANT_CONFIGURATION.DEFAULTS;
      await this.setGuestData({
        location: LOCATION,
        grades: GRADES,
      }).then(() => {
        this.setExitsInfoGuest({ exitsInfo: false });
      });
    },
  },
};
</script>
