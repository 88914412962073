<template>
  <section>
    <TextAtom
      :value="description"
      class="mt-4 text-left d-flex pl-4 pb-3"
      tag="label"
      font="inter"
      color="primary-dark"
      weight="400"
      size="subtitle"
    />
    <section class="campus-detail__panels__container">
      <p
        v-for="(teacher, index) in total"
        :key="index"
      >
        <span v-if="teacher.count > 0" class="campus-detail__panels__title text-left mb-2 font-weight-medium">
          <span class="font-bold">
            {{ teacher.count }}
          </span>

          <span>
            {{ teacher.name }}
          </span>
        </span>
      </p>
      <MessageInPanel from="students-panel" />
    </section>

    <section
      v-if="others.length > 0"
      class="campus-detail__panels__container mt-4 mb-3"
    >
      <picture class="pl-2 mt-3 mb-4 d-flex flex-row align-center">
        <img src="@/assets/icons/person-vector.svg" width="20px" alt="person" />
        <img class="ml-1" src="@/assets/icons/person-vector.svg" width="20px" alt="person" />
        <h3
          v-t="'campus-details.team.support'"
          class="campus-detail__panels__title text-left mb-2 mt-2 subtitle-2 ml-2"
        />
      </picture>

      <h3
        v-for="({ support_label, quantity, descrip }, index) in others"
        :key="index"
        class="campus-detail__panels__title text-left mb-2 font-weight-medium ml-5"
      >
        <div v-if="support_label.id === 99 && descrip !== null">
          {{ quantity > 0 ? quantity : "" }} {{ descrip }}
        </div>
        <div v-else>
          {{ quantity > 0 ? quantity : "" }} {{ support_label.support_name }}
        </div>
      </h3>
    </section>
    <Partnership
      v-for="partnership in partnerships"
      :key="partnership.id"
      class="mb-5"
      :partnership="partnership"
    />
  </section>
</template>
<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import Partnership from '@/components/organisms/campus/Partnership.vue';
import MessageInPanel from './MessageInPanel.vue';

export default {
  name: 'TeachersPanel',
  components: {
    TextAtom,
    Partnership,
    MessageInPanel,
  },
  props: {
    total: {
      type: Object,
      default: () => {},
    },
    others: {
      type: Array,
      default: () => [],
    },
    description: {
      type: String,
      default: '',
    },
    partnerships: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
