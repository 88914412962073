<template>
  <div class="properties-grid">
    <PropertyCategoryTile
      v-for="({ key, loading, data }) in properties"
      :key="`${key}_${campus.campus_code}_${Math.random()}}`"
      :campus="campus"
      :property-key="key"
      :loading="loading"
      :property-data="data"
      :only-icon="onlyIcon"
      :in-favorites="inFavorites"
      in-grid
      :type-configuration="typeConfiguration"
      :default-data="defaultData"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PropertyCategoryTile from '@/components/molecules/tiles/PropertyCategoryTile.vue';
import { getCampusAdmissionSystemLabel } from '@/utils/categories/admissionRisk';
import { CAMPUS_PROPERTIES } from '@/constants/category';
import { TRAVEL_METHOD } from '@/constants/general';
import { extractCoordinates } from '@/utils/geolocation';

export default {
  name: 'PropertiesGrid',
  components: {
    PropertyCategoryTile,
  },
  props: {
    campus: {
      type: Object,
      required: true,
    },
    propertyKeys: {
      type: Array,
      required: true,
    },
    typeConfiguration: {
      type: String,
      default: '',
    },
    defaultData: {
      type: Object,
      default: () => ({}),
    },
    onlyIcon: {
      type: Boolean,
      default: false,
    },
    inFavorites: {
      type: Boolean,
      default: false,
    },
    abortPrevious: {
      type: Boolean,
      default: true,
    },
    location: {
      type: Object,
      validate: (location) => {
        if (!location || (location.lat && location.lng)) return true;
        return false;
      },
      default: () => undefined,
    },
  },
  data() {
    return {
      propertyDataLoading: {},
      propertyData: {},
      travelTimesFetched: false,
    };
  },
  computed: {
    ...mapGetters({
      homeLocation: 'authentication/homeLocation',
    }),
    properties() {
      return this.propertyKeys.map((key) => (
        {
          key,
          loading: this.propertyDataLoading[key],
          data: this.propertyDataLoading[key] ? null : this.propertyData[key],
        }
      ));
    },
  },
  watch: {
    campus: {
      handler() {
        this.setLoaders();
        this.propertyData = {};
        this.travelTimesFetched = false;
        this.triggerPropertyLoading();
      },
      deep: true,
    },
  },
  mounted() {
    this.setLoaders();
    this.triggerPropertyLoading();
  },
  methods: {
    ...mapActions({
      simulateForCampusCard: 'simulation/simulateForCampusCard',
      getTravelTimes: 'geoTools/getTravelTimes',
      getBusRouteTimes: 'geoTools/getBusRouteTimes',
    }),
    setLoaders() {
      this.propertyKeys.forEach((key) => {
        this.$set(this.propertyDataLoading, key, true);
      });
    },
    setPropertyData(key, data) {
      this.$set(this.propertyData, key, data);
      this.$set(this.propertyDataLoading, key, false);
    },
    triggerPropertyLoading() {
      const { BUS, WALK, CAR } = TRAVEL_METHOD;
      const {
        ADMISSION_RISK, WALK_TIME, CAR_TIME, BUS_TIME,
      } = CAMPUS_PROPERTIES;
      this.travelTimesFetched = false;
      // https://stackoverflow.com/a/70442272
      const propertyLoaderTriggers = {
        [ADMISSION_RISK]: () => this.getAdmissionPropertyData(),
        [WALK_TIME]: () => this.getTravelTimeData(WALK),
        [CAR_TIME]: () => this.getTravelTimeData(CAR),
        [BUS_TIME]: () => this.getTravelTimeData(BUS),
      };
      this.propertyKeys.forEach((key) => {
        const propertyDataLoaderMethod = propertyLoaderTriggers[key];
        const hasTrigger = propertyDataLoaderMethod !== undefined;
        this.$set(this.propertyDataLoading, key, hasTrigger);
        if (hasTrigger) {
          propertyDataLoaderMethod();
        }
      });
    },
    getAdmissionPropertyData() {
      const { hasRisk } = getCampusAdmissionSystemLabel(this.campus);
      const { ADMISSION_RISK } = CAMPUS_PROPERTIES;
      if (this.defaultData[ADMISSION_RISK] || this.typeConfiguration === 'FEEDBACK') {
        this.setPropertyData(ADMISSION_RISK, { risk: this.defaultData[ADMISSION_RISK] });
      } else if (hasRisk) {
        this.simulateForCampusCard({ campus: this.campus, abortPrevious: this.abortPrevious })
          .then((riskData) => {
            this.setPropertyData(ADMISSION_RISK, riskData);
          })
          .catch(() => {
            this.setPropertyData(ADMISSION_RISK, null);
          });
      } else {
        this.setPropertyData(ADMISSION_RISK, null);
      }
    },
    getTravelTimeData(travelMethod) {
      const { BUS, WALK, CAR } = TRAVEL_METHOD;
      const { WALK_TIME, CAR_TIME, BUS_TIME } = CAMPUS_PROPERTIES;
      const travelTimePayload = {
        campusCode: this.campus.campus_code,
        campusLat: this.campus.location.lat,
        campusLng: this.campus.location.lon,
        ...extractCoordinates(this.location || this.homeLocation),
      };
      if ([WALK, CAR].includes(travelMethod) && !this.travelTimesFetched) {
        this.getTravelTimes(travelTimePayload).then(({ walkData, carData }) => {
          this.setPropertyData(WALK_TIME, walkData);
          this.setPropertyData(CAR_TIME, carData);
        });
        this.travelTimesFetched = true;
      } else if (travelMethod === BUS) {
        this.getBusRouteTimes(travelTimePayload).then(({ busData }) => {
          this.setPropertyData(BUS_TIME, busData);
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">

.properties-grid {
    width: 100%;
    display: flex;
    gap: 0.5rem;
}

</style>
