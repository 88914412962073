import { getDistanceBetweenCoordinates, kilometersToMiles } from '@/utils/geolocation';
import { CAMPUS_PROPERTIES } from '@/constants/category';
import { DISTANCE_LABELS } from '@/constants/labels';
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import { MEASUREMENT_UNIT } from '@/constants/general';
import { buildCategoryObject, buildNoInfoCategoryObject } from './shared';

// AUXILIARY FUNCTIONS

const getDistanceCategoryLabelByDistance = (distance) => (
  Object.values(DISTANCE_LABELS).find((label) => +distance <= Number(label.UPPER_BOUND))
);

/**
 * Get the category of a campus distance property based on the campus data and the tenant
 * @param {*} campus - The campus data, as returned by the elastic search API
 * @param {*} store - The vuex store instance (`import store from '@/store'`)
 * @returns {CampusPropertyCategory} - The category of the distance property
 */
const getCampusDistanceCategory = (campus, store, typeConfiguration, defaultData) => {
  if (typeConfiguration === 'FEEDBACK' && defaultData?.DISTANCE) {
    const propertyKey = CAMPUS_PROPERTIES.DISTANCE;
    let categoryKey = null;

    if (+defaultData.DISTANCE === 4) {
      categoryKey = 'LOW';
    } else if (+defaultData.DISTANCE === 3) {
      categoryKey = 'MEDIUM_LOW';
    } else if (+defaultData.DISTANCE === 2) {
      categoryKey = 'MEDIUM';
    } else if (+defaultData.DISTANCE === 1) {
      categoryKey = 'HIGH';
    }

    const category = buildCategoryObject(propertyKey, categoryKey, { label: defaultData.DISTANCE, icon: 'WALK' });
    category.label = campus.distance;

    return category;
  }
  const propertyKey = CAMPUS_PROPERTIES.DISTANCE;
  const userHomeLocation = store.getters['authentication/homeLocation'];
  const hasDataToCalculate = Object.keys(userHomeLocation).length > 0 && Object.keys(campus
    ?.location).length > 0;

  if (!hasDataToCalculate) return buildNoInfoCategoryObject(propertyKey);

  const campusLocation = {
    lat: campus?.location?.lat,
    lng: campus?.location?.lon,
  };
  let distance = getDistanceBetweenCoordinates(userHomeLocation, campusLocation);

  distance = TENANT_CONFIGURATION.DEFAULTS.MEASUREMENT_UNIT === MEASUREMENT_UNIT.MI
    ? kilometersToMiles(distance)
    : distance;

  const label = `${distance} ${TENANT_CONFIGURATION.DEFAULTS.MEASUREMENT_UNIT}`;

  const { TETHER_CATEGORY: categoryKey, TRAVEL_METHOD } = getDistanceCategoryLabelByDistance(distance);

  const icon = TRAVEL_METHOD;
  return buildCategoryObject(propertyKey, categoryKey, { label, icon });
};

export {
  getCampusDistanceCategory,
  getDistanceCategoryLabelByDistance,
};
