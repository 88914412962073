<template>
  <div class="link-account">
    <div>
      <ModalHeader
        :content-sections="['actions', 'title', 'logo']"
        :title-text="'user_register.register_code.title'"
        small-logo
        @back="$emit('backStep')"
      />
      <ModalContent
        :content-sections="['smallTitle', 'subtitle', 'registerInput']"
        :small-title-text="'user_register.link_account.title'"
        :subtitle-text="'user_register.link_account.sub_title'"
        :input-section="inputContent"
        @setValue="setValue"
      />
    </div>
    <CallToActions
      :types="['main']"
      :loader="linkLoading"
      :active-value="isActive"
      :main-button-text="'user_register.welcome.login'"
      :spaced="false"
      @continue="setLinkAccounts"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';

export default {
  name: 'LinkAccount',
  components: {
    ModalHeader,
    ModalContent,
    CallToActions,
  },
  data() {
    return {
      email: '',
      password: '',
      inputContent: [
        {
          value: null,
          noInfoError: false,
          placeholder: 'views.login.name.email',
          inputType: 'email',
          password: 0,
          show: true,
        },
        {
          value: '',
          noInfoError: false,
          placeholder: 'views.login.name.password',
          inputType: 'password',
          password: 1,
          show: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      linkLoading: 'authentication/linkLoading',
      identificationResult: 'authentication/identificationResult',
      legalGuardianNeedsData: 'authentication/legalGuardianNeedsData',
      legalGuardianUUID: 'authentication/legalGuardianUUID',
    }),
    isActive() {
      if (this.email && this.password) {
        return true;
      }
      return false;
    },
    resultData() {
      return [this.identificationResult, this.legalGuardianUUID];
    },
  },
  watch: {
    resultData: {
      handler(newValue) {
        // TODO: External Login Flow
        const [identificationResult, legalGuardianUUID] = newValue;
        const { done, found } = identificationResult;
        this.setLinkLoading({ bool: !(legalGuardianUUID) });
        if (done && this.legalGuardianNeedsData) {
          this.$emit('toRegisterType');
        } else if (done && found && legalGuardianUUID) {
          // TODO: Notify successful link
          this.$router.push('/map');
        }
        // else: Legal guardian not found
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      doIdentification: 'authentication/doIdentification',
      setLinkLoading: 'authentication/setLinkLoading',
    }),
    setValue(mainValue, passwordInput) {
      if (passwordInput === 0) {
        this.inputContent[0].noInfoError = false;
        this.email = mainValue;
      }
      if (passwordInput === 1) {
        this.inputContent[1].noInfoError = false;
        this.password = mainValue;
      }
    },
    async setLinkAccounts() {
      if (!this.email && !this.password) {
        this.inputContent[0].noInfoError = true;
        this.inputContent[1].noInfoError = true;
        return null;
      }
      this.setLinkLoading({ bool: true });
      await this.$recaptchaLoaded();
      const captcha = await this.$recaptcha('login');
      const identification = {
        email: this.email,
        password: this.password,
        captcha,
      };
      // When linking accounts, we need to login first. After a successful login, the linkAccounts action will be called
      return this.doIdentification({
        identification,
        linkAccounts: true,
      });
    },
  },
};
</script>
