<template>
  <ConfirmationDialog
    v-if="activeModal === 'PaymentStatus'"
    :title="{ path: `payments.dialog.${status}.title`, args: textArgs }"
    :subtitle="{ path: `payments.dialog.${status}.subtitle`, args: textArgs }"
    :hide-cancel-button="!showDoubleButtons"
    :confirm-button-text="showDoubleButtons ? `payments.dialog.${status}.yes` : null"
    :cancel-button-text="showDoubleButtons ? `payments.dialog.${status}.no` : null"
    value
    @confirm="confirm"
    @cancel="cancel"
  />
</template>

<script>
import ConfirmationDialog from '@/components/molecules/dialogs/ConfirmationDialog.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PaymentStatusModal',
  components: {
    ConfirmationDialog,
  },
  computed: {
    ...mapGetters({
      activeModal: 'utils/activeModal',
      activeModalData: 'utils/activeModalData',
      activeModalCallbacks: 'utils/activeModalCallbacks',
    }),
    status() {
      return this.activeModalData?.status || null;
    },
    textArgs() {
      return this.activeModalData?.textArgs || null;
    },
    showDoubleButtons() {
      const doubleButtons = ['exit', 'retry'];
      return doubleButtons.includes(this.status);
    },
  },
  methods: {
    ...mapActions({
      deactivateModal: 'utils/deactivateModal',
    }),
    confirm() {
      if (this.activeModalCallbacks.onConfirm) this.activeModalCallbacks.onConfirm();
      this.deactivateModal();
    },
    cancel() {
      if (this.activeModalCallbacks.onCancel) this.activeModalCallbacks.onCancel();
      this.deactivateModal();
    },
  },

};
</script>
