import { analyticsApi } from '@/api';

export default {
  getSections({ roleId }) {
    const params = { role_id: roleId };
    return analyticsApi.get('analytics/role-sections/get', { params });
  },
  getSectionGraphs({ roleId, sectionId }) {
    const params = { role_id: roleId, section_id: sectionId };
    return analyticsApi.get('analytics/section-graphs/get/', { params });
  },
  getCampusGraph({ campusCode, graphId }) {
    const params = { campus_code: campusCode, graph_id: graphId };
    return analyticsApi.get('analytics/campus-graph/get/', { params });
  },
};
