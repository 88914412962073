var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-main",class:{
    'register-main--width-auto': _vm.iconType === '',
    'register-main--warning-icon': _vm.iconType === 'warning',
    'register-main--info-icon': _vm.iconType === 'info',
  }},[_c('v-icon',{class:{
      'register-main--avatar-icon': _vm.avatar,
    },style:(`color: ${_vm.color} !important`),attrs:{"size":_vm.setSize()}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }