import { TETHER_CATEGORY, CAMPUS_PROPERTIES, CAMPUS_PAYMENT_TYPES as PAYMENT_TYPES } from '@/constants/category';
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import { getLocaleProperty } from '@/utils/locale';
import { amountDisplayName } from '@/utils/currency';
import { buildCategoryObject, buildNoInfoCategoryObject } from './shared';
import { matchScholarships } from '../scholarships';

const getCampusPaymentObject = (
  { campus, paymentType = PAYMENT_TYPES.MONTHLY_TUITION, program = null },
) => {
  // Case 1. There's a program and the campus has a payment object for that program
  const campusProgramPaymentObject = campus?.institution_payment_campus?.find(
    (payment) => payment.payment_type?.id === paymentType && payment.program === program,
  );
  if (campusProgramPaymentObject) return campusProgramPaymentObject;
  // Case 2. There's a program and the campus doesn't have a payment object for that program, we return
  // the generic payment object for the campus (where program is null)
  return campus?.institution_payment_campus?.find(
    (payment) => payment.payment_type?.id === paymentType && payment.program === null,
  );
};

/**
 * Get the category of a campus payment property based on the campus data and the tenant
 * @param {*} campusData - The campus data, as returned by the elastic search API
 * @returns {PropertyCategory} - The category of the payment property
 */
const getCampusPaymentCategory = (campus, store, typeConfiguration, defaultData) => {
  const propertyKey = CAMPUS_PROPERTIES.PAYMENT;
  const { CATEGORIES: { PAYMENT }, DEFAULTS: { CURRENCY } } = TENANT_CONFIGURATION;
  // TODO: For now, we'll only show NO-INFO, because the data is not in the elastic search
  const campusPaymentObject = getCampusPaymentObject({ campus, paymentType: PAYMENT_TYPES.MONTHLY_TUITION }) || {};

  const hasDataPayment = Object.keys(campusPaymentObject).length > 0;

  if (!hasDataPayment) return buildNoInfoCategoryObject(propertyKey);

  const campusPaymentCategoryObject = campusPaymentObject?.payment_category;

  const categoryKey = PAYMENT[campusPaymentCategoryObject.id];

  // Scholarships Logic. If the student is eligible for a scholarship and the campus offers it, we
  // show the scholarship name as the label and the category as HIGH (free)
  const overrides = {};
  const matchingScholarships = matchScholarships(
    {
      student: store?.getters?.['authentication/currentStudent'],
      campus,
      options: { forceEligibility: store?.getters?.['authentication/forceScholarshipEligibility'] },
    },
  );
  if (matchingScholarships.length > 0) {
    const [scholarship] = matchingScholarships;
    overrides.category = TETHER_CATEGORY.HIGH;

    if (overrides.category !== categoryKey) {
      // overrides.icon = `${propertyKey}-${scholarship.key}`;
      overrides.label = scholarship.name;
      overrides.description = scholarship.tooltip;
    }

    // TODO: Find agreement options and get locale name
  }

  if (!categoryKey || categoryKey === TETHER_CATEGORY.NO_DATA) return buildNoInfoCategoryObject(propertyKey);

  const label = (
    campusPaymentObject?.fixed_value
      ? amountDisplayName(campusPaymentObject.fixed_value, campusPaymentObject.currency || CURRENCY)
      : getLocaleProperty(campusPaymentCategoryObject, 'payment_category_name')
  );
  const buildCategory = buildCategoryObject(
    propertyKey,
    overrides.category ?? categoryKey,
    { label, ...overrides },
    typeConfiguration,
  );

  buildCategory.label = defaultData.PAYMENT || buildCategory.label;
  return buildCategory;
};

export {
  getCampusPaymentObject,
  getCampusPaymentCategory,
};
