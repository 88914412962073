import { ELASTIC_SEARCH_FIELDS } from '@/constants/search';
import services from '@/services';

const getDefaultState = () => ({
  isLoadingFeedback: true,
  typeFeedback: '',
  feedback: {},
  feedbackId: null,
  // deprecated
  loadingFeedback: true,
  feedbackApplication: {},
  feedbackResults: {},
  isShowDigitalProfile: false,
  currentAddressStudentInFeedback: {},
});
const state = getDefaultState();
const getters = {
  isLoadingFeedback: (state) => state.isLoadingFeedback,
  typeFeedback: (state) => state.typeFeedback,
  feedback: (state) => state.feedback,
  feedbackId: (state) => state.feedbackId,
  // deprecated
  loadingFeedback: (state) => state.loadingFeedback,
  feedbackApplication: (state) => state.feedbackApplication,
  isShowDigitalProfile: (state) => state.isShowDigitalProfile,
  currentAddressStudentInFeedback: (state) => state.currentAddressStudentInFeedback,
  feedbackResults: (state) => state.feedbackResults,
};

const mutations = {
  setFeedbackId(state, value) {
    state.feedbackId = value;
  },
  setIsLoadingFeedback(state, value) {
    state.isLoadingFeedback = value;
  },
  setTypeFeedback(state, value) {
    state.typeFeedback = value;
  },
  setFeedback(state, data) {
    state.feedback = data;
  },
  // deprecated
  setFeedbackApplication(state, data) {
    state.feedbackApplication = data;
  },
  setLoadingFeedback(state, data) {
    state.loadingFeedback = data;
  },
  setIsShowDigitalProfile(state, value) {
    state.isShowDigitalProfile = value;
  },
  setCurrentAddressStudentInFeedback(state, value) {
    state.currentAddressStudentInFeedback = value;
  },
  setFeedbackResults(state, data) {
    state.feedbackResults = data;
  },
};
const actions = {
  setFeedbackId({ commit }, value) {
    commit('setFeedbackId', value);
  },
  getUserData({ rootGetters }) {
    const data = {};
    const { firstName } = rootGetters['authentication/legalGuardian'];
    const dataStudents = rootGetters['authentication/students'];

    data.userName = firstName ?? '';
    data.students = dataStudents;
    return data;
  },
  async getFeedback({ commit, dispatch }, typeFeedback) {
    commit('setTypeFeedback', typeFeedback);
    commit('setIsLoadingFeedback', true);
    const userData = await dispatch('getUserData');

    const typesFeedback = {
      application: dispatch('getFeedbackApplication', userData),
    };

    await typesFeedback[typeFeedback];
  },
  async getFeedbackApplication({ commit, dispatch }, userData) {
    let dataOfElasticSearch = [];
    const dataFeedback = {
      ...userData,
      students: [],
      simulateFamily: {},
    };
    const payloadInformationOfCampuses = {
      campuses: [],
      validation: ELASTIC_SEARCH_FIELDS.CAMPUS_CODE,
    };
    // set new promise for each student recommendation
    const promisesRecommendationsOfStudents = userData.students
      .map((student) => services.feedbackService.recommendations(student.uuid));
    let recommendationsOfStudents;
    const promisesOfStudents = userData.students.map((student) => services.feedbackService.application(student.uuid));
    let applicationsOfStudents = await Promise.allSettled(promisesOfStudents);
    applicationsOfStudents = applicationsOfStudents.filter(
      (application) => application.status !== 'rejected',
    );

    const { data: simulateFamily } = await services.feedbackService.simulateFamily();
    dataFeedback.simulateFamily = simulateFamily;

    // get data of request feedback of student
    applicationsOfStudents.forEach((application) => {
      const { data: dataApplicationOfStudent } = application.value;
      let dataOfStudent = {};

      dataOfStudent = dataApplicationOfStudent.map((data) => ({
        applications: data.api_response.portfolio,
        unmatched: data.api_response.unmatched,
        year: data.api_response.year,
        firstName: data.applicant_firstname,
        lastName: data.applicant_lastname,
        uuid: data.applicant_uuid,
        location:
          userData.students
            .find((student) => student.uuid === data.applicant_uuid)
            ?.address.find((address) => address.address_name.id === 99)
          ?? userData.students.find((student) => student.uuid === data.applicant_uuid)?.address[0],
      }));

      payloadInformationOfCampuses.campuses.push(
        ...dataOfStudent[0].applications.map(({ campus }) => campus.campus_code),
      );
      dataFeedback.students.push(...dataOfStudent);
    });

    recommendationsOfStudents = await Promise.allSettled(promisesRecommendationsOfStudents);
    recommendationsOfStudents = recommendationsOfStudents.filter(
      (recommendation) => recommendation.status !== 'rejected',
    );

    // const getDataRecommendations = recommendationsOfStudents.map(({ value }) => value.data.map((campus) => campus))
    //   .flat()
    //   .filter((recommendation) => recommendation.rec_order > 0)
    //   .map((campus) => campus.rec_link_perfil.slice(campus.rec_link_perfil.search('school') + 7));

    // payloadInformationOfCampuses.campuses.push(...getDataRecommendations);

    // get information of campuses
    dataOfElasticSearch = (await dispatch('elasticSearch/getCampusesByValidations', payloadInformationOfCampuses, {
      root: true,
    })) ?? [];

    // recommendationsOfStudents.forEach((recommendation) => {
    //   recommendation.value.data.forEach((campus) => {
    //     // eslint-disable-next-line no-param-reassign
    // campus.campus = dataOfElasticSearch.find((data) =>
    // data.campus_code === campus.rec_link_perfil.slice(campus.rec_link_perfil.search('school') + 7)) ?? {};
    //   });
    // });
    // set sector in data of feedback
    dataFeedback.students = dataFeedback.students.map((student) => ({
      ...student,
      applications: student.applications.map((application) => {
        const campus = dataOfElasticSearch.find((data) => data.campus_code === application.campus.campus_code)
          || {};
        return {
          ...application,
          campus,
          location: campus.location ?? {},
          campus_admission_system: campus.campus_admission_system ?? {},
          programs: campus.programs ?? [],
          agreements: campus.agreement_set ?? [],
        };
      }),
    }));

    // set recommendations in data of feedback
    dataFeedback.students = dataFeedback.students.map((student) => ({
      ...student,
      recommendations:
        recommendationsOfStudents.find(
          (recommendation) => recommendation.value?.data[0]?.applicant_uuid === student.uuid,
        )?.value?.data ?? {},
    }));

    commit('setFeedback', dataFeedback);
    commit('setIsLoadingFeedback', false);
  },
  setCurrentAddressStudentInFeedback({ commit }, value) {
    commit('setCurrentAddressStudentInFeedback', value);
  },
  setIsShowDigitalProfile({ commit }, value) {
    commit('setIsShowDigitalProfile', value);
  },
  // eslint-disable-next-line no-unused-vars
  async retriveFeedbackApplications({ commit, dispatch, rootGetters }, uuid) {
    commit('setLoadingFeedback', true);
    commit('setIsShowDigitalProfile', false);

    const dataFeedback = {};
    const { firstName } = rootGetters['authentication/legalGuardian'];
    const dataStudents = rootGetters['authentication/students'];

    dataFeedback.firstName = firstName;
    dataFeedback.uuid = 'f53ba32e-372b-4e51-abe3-7df027d413fa';
    dataFeedback.students = await dispatch('retrieveApplications', dataStudents);

    const sendDataRecommendation = {
      students: dataFeedback.students,
      uuid,
    };

    const recommendationsWithStudents = await dispatch(
      'retrieveRecommendations',
      sendDataRecommendation,
    );

    dataFeedback.students = recommendationsWithStudents;

    commit('setFeedbackApplication', dataFeedback);
    commit('setLoadingFeedback', false);
  },

  async retrieveApplications({ dispatch }, students) {
    const applicantsRequest = [];

    async function getApplications(student, counter) {
      const { data: applications } = await services.feedbackService.application(student.uuid);
      const applicationsWithLocation = await dispatch(
        'elasticSearch/getCampusesByValidations`',
        applications[0],
      );
      applicationsWithLocation.api_response.portfolio = applicationsWithLocation
        .api_response.portfolio.sort((a, b) => a.rank - b.rank);

      applicantsRequest.push({ ...applicationsWithLocation, ...student });

      if (applicantsRequest.length !== students.length) {
        await getApplications(students[counter + 1], counter + 1);
      }
    }
    await getApplications(students[0], 0);
    return applicantsRequest;
  },
  // eslint-disable-next-line no-empty-pattern
  async getLocationsSchools({}, listSchools) {
    const newListSchools = { ...listSchools };
    // const totalSchools = listSchools.api_response.portfolio.length;

    const uuids = listSchools.api_response.portfolio.map((school) => ({
      fieldname: ELASTIC_SEARCH_FIELDS.CAMPUS_CODE,
      fieldvalue: school.campus.campus_code,
    }));

    const { data: listLocation } = await services.elasticSearchService.elasticSearchCampuses(uuids);

    newListSchools.api_response.portfolio.forEach((school) => {
      // eslint-disable-next-line no-param-reassign
      school.campus.location = listLocation.results.find(
        (schoolLocation) => schoolLocation.campus_code === school.campus.campus_code,
      ).location;
    });

    return newListSchools;
  },
  // eslint-disable-next-line no-empty-pattern
  async retrieveRecommendations({}, dataStudents) {
    const { students } = dataStudents;

    let applicantsRequest = [...students];

    const { data: recommendationsApplicants } = await services.feedbackService.recommendations();
    // eslint-disable-next-line array-callback-return
    recommendationsApplicants.forEach((applicant) => {
      // eslint-disable-next-line no-param-reassign
      applicant.totalRecommendations = applicant.recommendations;
      // eslint-disable-next-line no-param-reassign
      applicant.locationDefault = applicant.def_location;
    });

    // eslint-disable-next-line array-callback-return, consistent-return
    applicantsRequest = applicantsRequest.map((student) => {
      const findRecommendations = recommendationsApplicants.find(
        // eslint-disable-next-line camelcase
        ({ applicant_uuid }) => student.applicant_uuid === applicant_uuid,
      );

      if (findRecommendations !== undefined) {
        return {
          ...student,
          recommendation: findRecommendations.recommendations,
          schoolsAtLocations: findRecommendations.schools_at_location,
          totalRecommendations: findRecommendations.totalRecommendations,
          locationDefault: findRecommendations.locationDefault,
        };
      }
    });

    return applicantsRequest.filter((applicant) => applicant !== undefined);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
