<template>
  <div class="register-main" @click="clickNumberSelect()" @keydown="/* TODO: */">
    <v-select
      v-model="value"
      :placeholder="placeholder"
      height="48"
      item-value="item"
      class="register-main--select"
      :items="setNumberList()"
      @change="setValue(value)"
    />
  </div>
</template>

<script>
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'SmallNumberSelect',
  components: {},
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    totalNumbers: {
      type: Number,
      default: 0,
    },
    startNumber: {
      type: Number,
      default: 1,
    },
    initialValue: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: 1,
    },
    clickMixpanelTag: {
      type: String,
      default: null,
    },
    mixpanelTag: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      value: null,
    };
  },
  watch: {
    initialValue() {
      this.value = this.initialValue;
    },
  },
  created() {
    if (this.initialValue !== null) {
      this.value = this.initialValue;
    }
  },
  methods: {
    setNumberList() {
      const numberArray = Array(this.totalNumbers)
        .fill(this.startNumber)
        .map((x, y) => x + y);
      const stringNumberArray = numberArray.map((num) => num.toString().padStart(2, '0'));
      if (this.startNumber > 1) {
        stringNumberArray.sort((a, b) => b - a);
      }
      return stringNumberArray;
    },
    setValue(item) {
      if (this.mixpanelTag) {
        trackMixPanel(this.mixpanelTag);
      }
      this.$emit('setValue', this.index, item);
    },
    clickNumberSelect() {
      if (this.clickMixpanelTag) {
        trackMixPanel(this.clickMixpanelTag);
      }
    },
  },
};
</script>
