var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bg-primary-dark w-full d-flex flex-column"},[_c('GmapMap',{staticClass:"map",attrs:{"id":"map","center":_vm.getLocationStudent,"zoom":13.3,"options":{
      disableDefaultUI: true,
      clickableIcons: false,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      scrollwheel: false,
      draggable: false,
      styles: _vm.stylesGoogleMap,
      gestureHandling: 'greedy',
    }}},[_c('GmapCustomMarker',{attrs:{"id":"student-location","marker":_vm.getLocationStudent}},[_c('img',{attrs:{"src":require(`@/assets/feedback/marker-student.svg`),"width":"18","alt":"marker student"}})]),(_vm.recommendations.length > 0)?[_vm._l((_vm.getLocationRecommendations),function(marker,index){return _c('GmapCustomMarker',{key:index + Math.random(),attrs:{"marker":marker}},[_c('img',{attrs:{"src":require(`@/assets/feedback/marker-white.svg`),"width":"18","alt":"marker student"}})])}),_vm._l((_vm.getLocationRecommendationsPaymentPerformance),function(marker,index){return _c('GmapCustomMarker',{key:index + Math.random(),attrs:{"marker":marker}},[_c('img',{attrs:{"src":require(`@/assets/feedback/marker-blue.svg`),"width":"18","alt":"marker student"}})])})]:_vm._e(),_vm._l((_vm.getLocationApplications),function(marker,index){return _c('GmapCustomMarker',{key:index + Math.random(),attrs:{"marker":marker}},[_c('img',{attrs:{"src":require(`@/assets/feedback/marker-favorite.svg`),"width":"18","alt":"marker student"}})])}),_c('GmapCircle',{attrs:{"id":"student-location-radius","center":_vm.getLocationStudent,"radius":3000,"visible":true,"options":_vm.mapCircle}})],2),_c('div',{staticClass:"w-full d-flex flex-column align-center justify-center py-7"},[_c('div',{staticClass:"w-full d-flex flex-row justify-start",staticStyle:{"max-width":"300px"}},[_c('img',{attrs:{"src":require("@/assets/feedback/marker-student.svg"),"width":"18","alt":"marker student"}}),_c('TextAtom',{staticClass:"ml-2",attrs:{"value":"Tu zona de interés ","color":"secondary-light","font":"inter"}})],1),_c('div',{staticClass:"w-full d-flex flex-row justify-start mt-5",staticStyle:{"max-width":"300px"}},[_c('img',{attrs:{"src":require("@/assets/feedback/marker-favorite.svg"),"width":"18","alt":"marker student"}}),_c('TextAtom',{staticClass:"ml-2",attrs:{"value":"Establecimientos a los que postulate","color":"secondary-light","font":"inter"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }