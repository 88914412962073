<template>
  <section class="w-100 full-height d-flex flex-column">
    <!-- Loader feedback -->
    <div v-if="isLoadingFeedback" class="full-width h-full d-flex justify-center align-center">
      <LoaderCircles />
    </div>
    <!-- Feedback -->
    <FeedbackContainer v-else class="slide-bottom" />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LoaderCircles from '@/components/atoms/loaders/LoaderCircles.vue';
import FeedbackContainer from '@/components/organisms/feedback/FeedbackContainer.vue';

export default {
  name: 'FeedbackView',
  components: {
    FeedbackContainer,
    LoaderCircles,
  },
  metaInfo() {
    return {
      title: `${this.$tc('dashboard.breadcrumbs.app_name')} | ${this.$tc('dashboard.breadcrumbs.feedback')}`,
    };
  },
  computed: {
    ...mapGetters({
      isLoadingFeedback: 'feedback/isLoadingFeedback',
    }),
  },
  async mounted() {
    await this.getFeedback(this.$route.query.feedback);
  },
  methods: {
    ...mapActions({
      getFeedback: 'feedback/getFeedback',
    }),
  },
};
</script>
