<template>
  <div class="help-information--list">
    <div class="user_register_container h-full">
      <PasswordRecovery v-if="inStep(-1)" @toRegister="toStep(2)" @backStep="backStep" />
      <LoginModal
        v-if="inStep(0)"
        in-waiting-list
        @backStep="$emit('back')"
        @nextStep="toStep(4)"
        @toRecovery="toStep(-1)"
        @toRegister="toStep(1)"
        @toVerification="toStep(2)"
        @toAccountLinking="toStep(3)"
        @skipVerification="toStep(4)"
      />
      <RegisterData
        v-if="inStep(1)"
        in-waiting-list
        @nextStep="nextStep"
        @backStep="$emit('back')"
        @setLocationModal="toStep(2)"
        @skipVerification="toStep(4)"
        @toAccountLinking="toStep(3)"
      />
      <RegisterCode
        v-if="inStep(2)"
        in-waiting-list
        @nextStep="toStep(4)"
        @backStep="backStep"
      />
      <LinkAccount
        v-if="inStep(3)"
        @nextStep="nextStep"
        @backStep="backStep"
      />
      <Identification
        v-if="inStep(4)"
        pre-fill
        in-waiting-list
        :pre-fill-data="currentUserToEdit"
        :in-student-flow="inStudentFlow"
        :is-new-student="inStudentFlow && initializingStudent"
        in-detailed-register
        @nextStep="nextStep"
        @backStep="backStepNationality"
      />
      <Nationality
        v-if="inStep(5)"
        pre-fill
        in-waiting-list
        :pre-fill-data="currentUserToEdit"
        :in-student-flow="inStudentFlow"
        @nextStep="nationalityFollowUp"
        @backStep="backStep"
      />
      <Contact
        v-if="inStep(6)"
        pre-fill
        in-waiting-list
        :pre-fill-data="contactPreferences"
        :register-type="1"
        @nextStep="nextStepContact"
        @backStep="backStepContact"
      />
      <StudentSelection
        v-if="inStep(7)"
        in-waiting-list
        @nextStep="studentSelectionFollowUp"
        @toNewStudentRegister="toNewStudentRegister"
        @backStep="toStep(6)"
      />
      <DateOfBirth
        v-if="inStep(8)"
        pre-fill
        in-waiting-list
        :pre-fill-data="currentUserToEdit"
        :in-student-flow="inStudentFlow"
        :register-type="2"
        @nextStep="nextStep"
        @backStep="toStep(5)"
      />
      <RelationshipModal
        v-if="inStep(9)"
        pre-fill
        in-waiting-list
        :pre-fill-data="currentUserToEdit"
        @backStep="toStep(8)"
        @nextStep="$emit('next', currentStep)"
      />
    </div>
    <div>
      <SvgLogo
        v-if="currentStep > 3"
        class="clickable help-information--icon"
        :logo="'help-icon.svg'"
        left
        no-margin
        alt="help icon"
        @logoClick="helpModal = true"
      />
      <v-dialog v-model="helpModal" max-width="350">
        <HelpInformation @close="helpModal = false" />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import SvgLogo from '@/components/atoms/logos/SvgLogo.vue';
import HelpInformation from '@/components/organisms/digital_enrollment/HelpInformation.vue';
import Contact from '@/components/organisms/register/Contact.vue';
import DateOfBirth from '@/components/organisms/register/DateOfBirth.vue';
import Identification from '@/components/organisms/register/Identification.vue';
import LinkAccount from '@/components/organisms/register/LinkAccount.vue';
import LoginModal from '@/components/organisms/register/LoginModal.vue';
import Nationality from '@/components/organisms/register/Nationality.vue';
import PasswordRecovery from '@/components/organisms/register/PasswordRecovery.vue';
import RegisterCode from '@/components/organisms/register/RegisterCode.vue';
import RegisterData from '@/components/organisms/register/RegisterData.vue';
import RelationshipModal from '@/components/organisms/register/RelationshipModal.vue';
import StudentSelection from '@/components/organisms/register/StudentSelection.vue';
import { LABELS } from '@/constants/explorer/labels';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'WaitingListRegister',
  components: {
    PasswordRecovery,
    LoginModal,
    RegisterData,
    RegisterCode,
    LinkAccount,
    Nationality,
    Identification,
    Contact,
    DateOfBirth,
    RelationshipModal,
    StudentSelection,
    HelpInformation,
    SvgLogo,
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      stepStack: [1],
      inStudentFlow: false,
      initializingStudent: true,
      helpModal: false,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'institutions/campusDetails',
      isGuest: 'authentication/isGuest',
      currentStudent: 'authentication/currentStudent',
      legalGuardian: 'authentication/legalGuardian',
      contactPreferences: 'userRegister/contactPreferences',
      students: 'authentication/students',
    }),
    currentStep() {
      return this.stepStack[this.stepStack.length - 1];
    },
    currentUserToEdit() {
      return this.inStudentFlow ? this.currentStudent : this.legalGuardian;
    },
  },
  watch: {
    step: {
      handler(newVal) {
        this.restartProcess(newVal);
      },
    },
  },
  mounted() {
    this.checkLabels({ keysLabels: [LABELS.NATIONALITIES] });
    this.restartProcess(this.step);
    this.setStudentFlow(false);
    // this.setLoginModal({ modal: 'applicationFlow' });
    if (this.step === 9) {
      this.setStudentFlow(true);
    }
  },
  methods: {
    ...mapActions({
      setLoginModal: 'authentication/setLoginModal',
      checkLabels: 'explorer/checkLabels',
    }),
    restartProcess(customStep) {
      const newStep = customStep !== undefined ? customStep : this.step;
      this.stepStack = [newStep];
    },
    inStep(...steps) {
      return steps.includes(this.currentStep);
    },
    nextStep() {
      const nextStep = this.currentStep + 1;
      this.stepStack.push(nextStep);
    },
    backStep() {
      this.stepStack.pop();
    },
    toStep(step, { skipCurrent = false } = {}) {
      // Jump to a specific step. Do not use to reset the process, use restartProcess instead.
      // If skipCurrent is true, the current step will be skipped in the historial
      if (skipCurrent) {
        this.stepStack = [...this.stepStack.slice(0, -1), step];
      } else {
        this.stepStack.push(step);
      }
    },
    setStudentFlow(value) {
      this.inStudentFlow = value;
    },
    nextStepContact() {
      if (this.students.length > 0) {
        this.toStep(7);
      } else {
        this.toStep(4);
      }
      this.setStudentFlow(true);
    },
    backStepNationality() {
      if (this.inStudentFlow) {
        this.toStep(7);
      } else {
        this.$emit('back');
      }
    },
    backStepContact() {
      this.setStudentFlow(false);
      this.toStep(5);
    },
    nationalityFollowUp() {
      if (this.inStudentFlow) {
        this.toStep(8);
      } else {
        this.toStep(6);
      }
    },
    studentSelectionFollowUp() {
      this.setStudentFlow(true);
      this.toStep(4);
    },
    toNewStudentRegister() {
      this.setStudentFlow(true);
      this.setStudentInitialization(true);
      this.toStep(4);
    },
    setStudentInitialization(value) {
      this.initializingStudent = value;
    },
  },
};
</script>
