import jwtDecode from 'jwt-decode';
import { isEmbedded } from '@/utils/embedded';

const intercomInterface = {
  boot: ({
    userId, name, email, creationDate,
  } = {}) => {
    if (isEmbedded()) return;
    const appId = window.intercomSettings?.app_id ?? null;
    if (!appId) {
      return;
    }
    const payload = userId
      ? {
        app_id: appId,
        user_id: userId,
        name,
        email,
        created_at: creationDate,
      }
      : {
        app_id: appId,
      };
    window.Intercom('boot', payload);
  },
  shutdown: () => {
    window.Intercom('shutdown');
  },
  updateUser({ token }) {
    const { user_id: userId, email } = jwtDecode(token);
    window.Intercom('update', {
      user_id: userId,
      email,
    });
  },
  setGuestUser() {
    window.Intercom('update', {
      user_id: null,
      email: null,
      name: null,
    });
  },
  updateUserName({
    name = null,
  }) {
    if (!name) {
      return;
    }
    window.Intercom('update', {
      name,
    });
  },
  hide() {
    window.Intercom('hide');
  },
  show() {
    window.Intercom('show');
  },
};

export default {
  install(Vue, options = {}) {
    const { appId, ...otherOptions } = options;
    window.intercomSettings = {
      app_id: appId,
      ...otherOptions,
    };
    const initializeIntercom = () => {
      const w = window;
      const ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        const d = document;
        const i = (...args) => {
          i.c(args);
        };
        i.q = [];
        i.c = (args) => {
          i.q.push(args);
        };
        w.Intercom = i;
        const l = () => {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = `https://widget.intercom.io/widget/${appId}`;
          const x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    };

    initializeIntercom();

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$intercom = intercomInterface;
  },
  ...intercomInterface,
};
