<template>
  <div class="register-main register-main--spaced deg-waiting-list deg-waiting-list--welcome">
    <div>
      <ImageAtom
        class="deg-waiting-list--line clickable"
        :source="require('@/assets/icons/back-blue.svg')"
        :alt-text="'back'"
        @click="$emit('back')"
        @keydown="$emit('back')"
      />
      <SmallTitle
        class="deg-waiting-list--welcome__title"
        :text="'digital_enrollment.waiting-list.welcome.title'"
        center-left
        primary
      />
      <MainTitle
        class="pt-0"
        :text="'digital_enrollment.waiting-list.welcome.title_2'"
        secondary
        left-align
      />
      <ImageAtom
        class="deg-waiting-list--line"
        :source="require('@/assets/waitingList/dge-line.svg')"
        :alt-text="'dge-line'"
      />
      <SmallTitle
        :text="campusDetail.campus_name"
        center-left
      />
      <p class="deg-waiting-list--welcome__shift">
        {{ shift?.gradeName }} - {{ shift?.name }}
      </p>
      <ContentModal
        :content-sections="['subtitle']"
        :subtitle-center="false"
        :subtitle-arg="campusDetail.campus_name"
        :subtitle-text="'digital_enrollment.waiting-list.welcome.information'"
        subtitle-light
      />
    </div>
    <CallToActions
      class="mb-4"
      :class="mobile ? 'deg-waiting-list--welcome__button' : 'deg-waiting-list--list__continue'"
      :option-text1="'digital_enrollment.waiting-list.welcome.button'"
      :types="['option']"
      :spaced="false"
      option-btn-no-margin
      @nextStep="$emit('next')"
    />
  </div>
</template>

<script>
import ImageAtom from '@/components/atoms/images/Image.vue';
import MainTitle from '@/components/atoms/titles/MainTitle.vue';
import SmallTitle from '@/components/atoms/titles/SmallTitle.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ContentModal from '@/components/molecules/modals/Content.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'WaitingListSchool',
  components: {
    ImageAtom,
    CallToActions,
    ContentModal,
    MainTitle,
    SmallTitle,
  },
  props: {
    shift: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      campusDetail: 'institutions/campusDetails',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>
