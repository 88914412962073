var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-main register-main--spaced"},[_c('div',{staticClass:"fill-width"},[_c('ModalHeader',{attrs:{"content-sections":_vm.currentPath === '/login/phone_reset' ? ['title', 'logo'] : ['actions', 'title', 'logo'],"title-text":'user_register.register_code.title',"small-logo":""},on:{"back":function($event){return _vm.backMethod()}}}),_c('ModalContent',{attrs:{"content-sections":_vm.showResend ? ['smallTitle'] : ['smallTitle', 'subtitle', 'registerInput'],"small-title-text":_vm.setSmallTitle(),"subtitle-text":!_vm.storeEmail
          ? 'user_register.password-recovery.subtitle_phone'
          : 'user_register.password-recovery.subtitle_email',"input-section":_vm.inputContent,"error-types":!_vm.storeEmail ? [] : ['email'],"input-text-select":!_vm.storeEmail ? true : false,"text-select-items":_vm.listCodeArea,"small-title-primary-color":_vm.showResend ? true : false},on:{"setValue":_vm.setValue,"isInvalidEmail":_vm.isInvalidEmail,"setTextSelectValue":_vm.setDialCode}}),(_vm.showResend && !_vm.resendError)?_c('CallToActions',{staticStyle:{"margin-top":"40px"},attrs:{"types":['question', 'link'],"link-text":!_vm.storeEmail
          ? 'user_register.password-recovery.question_phone_text'
          : 'views.login.password-recovery.question-text',"question-text":!_vm.storeEmail
          ? 'user_register.password-recovery.link_phone_text'
          : 'views.login.password-recovery.link-text'},on:{"toLink":function($event){return _vm.resetPassword()}}}):_vm._e()],1),_c('CallToActions',{attrs:{"spaced":false,"types":['main'],"active-value":true,"loader":_vm.recoveryLoader,"main-button-text":_vm.resendError
        ? 'user_register.password-recovery.to_register_btn'
        : 'user_register.register_data.btn.send'},on:{"continue":function($event){_vm.resendError ? _vm.toRegister() : _vm.resetPassword()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }