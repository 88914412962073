<template>
  <v-tooltip
    bottom
    content-class="custom-tooltip"
    :disabled="typeConfiguration === 'FEEDBACK' && propertyKey === 'ADMISSION_RISK' || onlyIcon || !propertyTooltip"
  >
    <template #activator="{ on, attrs }">
      <div
        class="property-category-tile"
        v-bind="attrs"
        :class="{
          'property-category-tile--hidden': hide,
          'property-category-tile--in-grid': inGrid,
          'property-category-tile--flex': inFavorites,
        }"
        v-on="on"
      >
        <p v-if="!onlyIcon" v-t="propertyTitle" class="property-category-tile__title" />
        <p v-if="inFavorites" v-t="propertyLabel" class="property-category-tile__label" />
        <div v-if="showLoadingSpinner" class="property-category-tile__loader">
          <LoadingSpinner show :size="25" />
        </div>
        <template v-else>
          <span v-if="showYearRisk" class="year-risk">2024</span>
          <Flag
            :background="propertyStyle.backgroundColor"
            :color="propertyStyle.color"
            :label="propertyLabel"
            :icon="propertyCategoryIcon"
            :styling="onlyIcon ? 'icon-round' : 'icon-label'"
          />
        </template>
      </div>
    </template>
    <span v-t="propertyTooltip" />
  </v-tooltip>
</template>

<script>
import CONFIG from '@/config';
import LoadingSpinner from '@/components/atoms/loaders/LoaderCircular.vue';
import Flag from '@/components/molecules/flags/Flag.vue';
import { TETHER_CATEGORY } from '@/constants/category';
import { getCampusPropertyCategory } from '@/utils/categories';
import store from '@/store';

export default {
  name: 'PropertyCategoryTile',
  components: {
    LoadingSpinner,
    Flag,
  },
  props: {
    defaultData: {
      type: Object,
      default: () => ({}),
    },
    typeConfiguration: {
      type: String,
      default: '',
    },
    /**
     * The property key to be used to get the property category data and identify the property. Please make sure
     * that the property key is obtained from the system constants
     * @type {String}
     * @required
     * @example 'PAYMENT'
     */
    propertyKey: {
      type: String,
      required: true,
    },
    /**
     * Elastic response data for the campus. used to check that the campus details are loaded
     * @type {Object}
     * @required
     * @example { campus_code: '123', ... }
     */
    campus: {
      type: Object,
      required: true,
    },
    /**
     * Object with any additional data to be used to get the property category data
     * Meant to be used when the property category data is not in the campus details
     * and is fetched from another endpoint, so the tile can wait for the data to be loaded
     * @type {Object}
     * @default {}
     */
    propertyData: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Whether to show the loading spinner or not. It has two purposes:
     * 1. To show the loading spinner when the campus details are not loaded yet
     * 2. To show the loading spinner when any specific property category data is being fetched
     * @type {Boolean}
     * @default false
     */
    loading: {
      type: Boolean,
      default: () => false,
    },
    /**
     * Whether to show only the icon or the icon and the label. The tile transforms to a simple round icon
     * @type {Boolean}
     * @default false
     */
    onlyIcon: {
      type: Boolean,
      default: () => false,
    },
    /**
     * Whether to show the tile in a grid or not. This is used to make the tile take the whole width of the grid
     * @type {Boolean}
     * @default false
     */
    inGrid: {
      type: Boolean,
      default: () => false,
    },
    /**
     * Whether to hide the whole component if the property category data is unknown. Note that this is done using
     * the visibility property, so the component will still be rendered, but not visible (so it will still take space)
     * @type {Boolean}
     * @default false
     */
    hideUnknown: {
      type: Boolean,
      default: () => false,
    },
    /**
     * This is used to make the design tile take the whole width of the grid and change the design of the tile
     * @type {Boolean}
     * @default false
     */
    inFavorites: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    showYearRisk() {
      return this.propertyKey === 'ADMISSION_RISK' && CONFIG.tenant === 'chile' && this.typeConfiguration === 'FEEDBACK';
    },
    showLoadingSpinner() {
      return this.loading || !this.propertyCategoryData;
    },
    propertyCategoryData() {
      return getCampusPropertyCategory({
        defaultData: this.defaultData,
        typeConfiguration: this.typeConfiguration,
        campus: this.campus,
        propertyKey: this.propertyKey,
        propertyData: this.propertyData,
        store,
      });
    },
    propertyCategory() {
      return this.propertyCategoryData?.category;
    },
    propertyTitle() {
      return this.propertyCategoryData?.title;
    },
    propertyStyle() {
      return this.propertyCategoryData?.style || {};
    },
    propertyLabel() {
      return (this.onlyIcon && !this.inFavorites) ? null : (this.propertyCategoryData?.label || 'map.card.no-info');
    },
    propertyCategoryIcon() {
      return this.propertyCategoryData?.icon;
    },
    propertyTooltip() {
      if (this.showLoadingSpinner) {
        return this.$t('map.card.loading', { asset: this.$t(this.propertyTitle) });
      }
      return this.propertyCategoryData?.description;
    },
    hide() {
      return this.hideUnknown && this.propertyCategory === TETHER_CATEGORY.NO_DATA;
    },
  },
};
</script>

<style scoped lang="scss">
.year-risk {
  position: absolute;
  color: white;
  padding-left: 0.7rem;
  padding-top: 0.2rem;
}

.property-category-tile {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 0.7rem;

  &--in-grid {
    flex: 1;
  }

  &--hidden {
    visibility: hidden;
  }

  &--flex{
    display: flex;
    flex-direction: row-reverse;
    justify-content: left;
    align-items: center;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 12px;
    letter-spacing: 0.0025em;
    color: var(--neutral-850);
    min-height: 25px;
    font-size: 0.7rem;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 12px;
    letter-spacing: 0.0025em;
    color: var(--primary-dark);
    min-height: 25px;
    font-size: 0.8rem;
    margin-left: 17px;
  }

  &__loader {
    height: 70px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // padding: 0.5rem;
    background-color: var(--neutral-50);
  }
}

</style>
