<template>
  <section>
    <OptionButtons
      v-if="mainActionButtonContent.includes('option')"
      :text="optionsButtonsText"
      :no-margin="optionButtonNoMargin"
      :square-borders="optionButtonSquare"
      :is-active="activeOptionButton"
      :loader="optionButtonLoader"
      :legacy-primary="optionLegacyColor"
      :primary-medium="optionPrimaryMedium"
      :legacy-medium="optionLegacyMedium"
      :color="color"
      :mixpanel-tag="optionMixpanelTag"
      :text-small="optionSmallText"
      :svg-icon="svgIcon"
      :disabled-active="optionDisabledActive"
      :disabled="optionDisabled"
      :solid-background="solidBackground"
      @nextStep="$emit('setAction')"
    />
  </section>
</template>

<script>
import OptionButtons from '@/components/atoms/buttons/OptionButtons.vue';

export default {
  name: 'MainActionButtons',
  components: {
    OptionButtons,
  },
  props: {
    mainActionButtonContent: {
      type: Array,
      default() {
        return [];
      },
    },
    optionsButtonsText: {
      type: String,
      default: '',
    },
    optionButtonNoMargin: {
      type: Boolean,
      default: false,
    },
    optionButtonSquare: {
      type: Boolean,
      default: false,
    },
    activeOptionButton: {
      type: Boolean,
      default: false,
    },
    optionButtonLoader: {
      type: Boolean,
      default: false,
    },
    optionLegacyColor: {
      type: Boolean,
      default: false,
    },
    optionPrimaryMedium: {
      type: Boolean,
      default: false,
    },
    optionLegacyMedium: {
      type: Boolean,
      default: false,
    },
    optionMixpanelTag: {
      type: String,
      default: null,
    },
    optionSmallText: {
      type: Boolean,
      default: false,
    },
    svgIcon: {
      type: String,
      default: null,
    },
    optionDisabledActive: {
      type: Boolean,
      default: false,
    },
    optionDisabled: {
      type: Boolean,
      default: false,
    },
    solidBackground: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#5F2AF3',
    },
  },
};
</script>
