<template>
  <button type="button" class="register-main--google" depressed @click="googleClick()">
    <div class="register-main--google--container">
      <img style="margin-right: 12px" src="@/assets/icons/google.svg" alt="Iniciar sesión con Google" />
      <span v-t="'views.login.google'" class="register-main--google--container--text" />
    </div>
  </button>
</template>

<script>
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'GoogleInput',
  components: {},
  props: {
    mixpanelTag: {
      type: String,
      default: null,
    },
  },
  methods: {
    googleClick() {
      if (this.mixpanelTag) {
        trackMixPanel(this.mixpanelTag);
      }
      this.$emit('signInGoogle');
    },
  },
};
</script>
