<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <ModalContent
        :content-sections="['smallTitle', 'subtitle']"
        :small-title-text="$t('user_register.shift_priority.title')"
        small-title-left
        :subtitle-text="$t('user_register.shift_priority.subtitle', { name: currentStudent.name })"
        :subtitle-arg="Object.keys(currentStudent).length > 0 ? currentStudent.first_name : ''"
      />
      <div v-for="index in numberOfPriorities" :key="index">
        <ModalContent
          :content-sections="['textSelect']"
          :text-select-placeholders="
            $t('user_register.shift_priority.placeholder_nth_priority', {
              enumeration: $t(`user_register.shift_priority.enumeration.${index - 1}`),
            })
          "
          :text-select-items="nthSelectionItems(index)"
          :text-select-initial-value="nthSelectionValue(index)"
          :text-select-disabled="priorities.length < index - 1"
          :text-select-index="index"
          :text-select-no-info-error="noInfoError"
          text-select-item-text="shift_name"
          text-select-return-object
          @setTextSelectValue="setValue"
        />
      </div>
    </div>
    <CallToActions
      :types="['main']"
      :active-value="isActive"
      :main-button-text="'user_register.continue'"
      small
      :spaced="false"
      @continue="nextStep()"
    />
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import CONFIG from '@/config';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ShiftPriority',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
  },
  props: {
    preFill: {
      type: Boolean,
      default: false,
    },
    preFillData: {
      type: Object,
      default: () => ({}),
    },
    // If true, the component will use the preFillData or default values to fill the form
    // and will skip the step
    useDefaults: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      priorities: [],
      noInfoError: false,
    };
  },
  computed: {
    ...mapGetters({
      currentStudent: 'authentication/currentStudent',
      tenantShifts: 'digitalEnrollment/filteredTenantShifts',
      applicantShiftPriorities: 'digitalEnrollment/applicantShiftPriorities',
      tenantId: 'digitalEnrollment/tenantId',
    }),
    numberOfPriorities() {
      const shiftWithoutNoInfo = this.tenantShifts.filter((shift) => shift.id !== 0);
      return Math.min(shiftWithoutNoInfo.length, 3);
    },
    selectedPriorities() {
      return this.priorities.map(({ id }) => id).filter((id) => id !== 0);
    },
    isActive() {
      return this.priorities.length >= this.numberOfPriorities;
    },
  },
  watch: {
    preFillData: {
      handler() {
        if (this.preFill) {
          this.doPreFill();
        }
      },
      deep: true,
    },
    preFill: {
      handler(newVal) {
        if (!newVal) {
          this.priorities = [];
        }
      },
    },
  },
  beforeMount() {
    // FIXME: HARDCODED IDS PER TENANT
    const defaultShiftIds = {
      colombia: [6, 1, 2, 3, 4, 5, 7, 8, 9, 10],
      palmira: [6, 1, 2, 3, 4, 5, 7, 8, 9, 10],
      chile: [3, 1, 2, 4, 6, 7, 5],
      newhaven: [1],
    };
    if (this.useDefaults) {
      const defaultShiftPriorities = this.preFillData.priorities || defaultShiftIds[CONFIG.tenant] || [];
      const tenantShiftIds = this.tenantShifts.map(({ id }) => id);
      this.priorities = defaultShiftPriorities.filter((id) => tenantShiftIds.includes(id)).map((id) => ({ id }));
      this.nextStep({ skip: true });
    }
  },
  mounted() {
    if (this.tenantShifts.length === 0) {
      // In the border case where we don't have shifts for a tenant (we don't have GradeTracks), we skip this step
      // Shouldn't happen in production, but it's a good safeguard
      this.$emit('nextStep');
    } else if (this.preFill) {
      this.doPreFill();
    }
  },
  methods: {
    ...mapActions({
      setApplicantShiftPriorities: 'digitalEnrollment/setApplicantShiftPriorities',
      errorSnackbar: 'utils/error',
    }),
    nextStep({ skip = false } = {}) {
      if (this.isActive) {
        const shiftPriorities = {
          applicant_uuid: this.currentStudent.uuid,
          campus_code: this.tenantId,
          shift_priorities: this.selectedPriorities,
        };
        this.setApplicantShiftPriorities({ shiftPriorities }).then(() => {
          this.$emit('nextStep', { skip });
        }).catch((error) => {
          this.errorSnackbar(error);
        });
      } else {
        this.noInfoError = true;
      }
    },
    nthSelectionItems(n) {
      const priorityIndex = n - 1;
      const nthSelectedId = this.priorities.length >= n ? this.priorities[priorityIndex].id : -1;
      const previouslySelectedIds = this.selectedPriorities.slice(0, n);
      return this.tenantShifts.filter(
        ({ id }) => id === nthSelectedId || !previouslySelectedIds.includes(id) || id === 0,
      );
    },
    nthSelectionValue(n) {
      return this.priorities.length >= n ? this.priorities[n - 1] : null;
    },
    setValue(index, value) {
      if (this.priorities.length < index) {
        this.noInfoError = false;
        this.priorities.push(value);
      } else if (this.priorities.length === index) {
        this.priorities[index - 1] = value;
      } else {
        this.priorities.splice(index - 1, this.priorities.length, value);
      }
    },
    doPreFill() {
      this.priorities = this.preFillData?.priorities ?? [];
    },
  },
};
</script>
