<template>
  <div class="register-main fill-width" style="margin-left: 14px">
    <div v-if="valueErrors.length > 0" class="fill-width flex-row align-center">
      <img src="@/assets/icons/validation-warning.svg" alt="validation" />
      <div class="d-flex flex-column">
        <p v-for="(error, index) in valueErrors" :key="index" class="register-main--error">
          {{ error }}
        </p>
      </div>
    </div>
    <p v-if="noInfoError" v-t="'views.login.error.email.required'" class="register-main--error" />
  </div>
</template>

<script>
export default {
  name: 'InputError',
  components: {},
  props: {
    valueErrors: {
      type: Array,
      default() {
        return [];
      },
    },
    noInfoError: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
