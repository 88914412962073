<template>
  <div class="message-card">
    <!-- Add your card content here -->
    <div class="card-content">
      <div style="position: relative">
        <v-icon
          v-if="!message.thumbnail"
          color="#5627FF"
          style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)"
        >
          mdi-town-hall
        </v-icon>
        <v-img
          v-else
          contain
          height="80px"
          width="80px"
          style="border-radius: 11px 0px 0px 11px"
          aspect-ratio="1/1"
          :src="message.thumbnail"
          alt="imagen-proceso"
        />
      </div>
      <div class="card-text">
        <h2>{{ message.title }}</h2>
        <h3 v-if="message.subtitle">
          {{ message.subtitle }}
        </h3>
        <div class="card-actions">
          <v-btn
            small
            flat
            color="#E0E7FF"
            style="color: #0c1461"
            append-icon="chevron-right"
            class="text-none ms-2"
            @click="openLink(message.link)"
          >
            Postular
            <v-icon color="0c1461"> mdi-chevron-right </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <!--<div class="card-content pt-2 pb-2">
      <div class="card-text">
        <div class="d-flex">
          <div v-if="message.thumbnail" class="image-wrapper">
            <img :src="message.thumbnail" alt="imagen-proceso" />
          </div>
          <div class="d-flex">
            <h2 class="mt-1">{{ message.title }}</h2>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small icon color="primary" dark v-bind="attrs" v-on="on"
                  ><v-icon small>mdi-help</v-icon>
                </v-btn>
              </template>
              <span>{{ message.description }}</span>
            </v-tooltip>
          </div>
        </div>
        <h3 v-if="message.subtitle">{{ message.subtitle }}</h3>

        <v-btn @click="openLink(message.link)">Postular</v-btn>
      </div>
    </div>-->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isFavorite: false,
    };
  },
  computed: {
    ...mapGetters({
      favoriteGuestList: "favorites/favoriteGuestList",
    }),
  },
  created() {
    this.isFavorite = this.checkIfIsFavorite();
  },
  methods: {
    openLink(link) {
      window.open(link, "_blank");
    },
    ...mapActions({
      updateCurrentSchool: "institutions/updateCurrentSchool",
      setGuestFavorite: "favorites/setGuestFavorite",
      deleteGuestFavorite: "favorites/deleteGuestFavorite",
    }),
    checkIfIsFavorite() {
      return this.favoriteGuestList.some(
        (favorite) => favorite.campus_code === this.message.campusCode,
      );
    },
  },
};
</script>

<style scoped>
.message-card {
  /* Add your custom styles for the message card */
  border-radius: 10.211px;
  margin-bottom: 10px;
  width: 100%;
  height: 100px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
  margin-left: 10px;
}
.card-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.image-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.card-content img {
  max-height: 100px;
}
.card-text {
  flex: 2;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  text-align: left;
}
.card-text h2 {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.card-text h3 {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.card-text div {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}
.card-actions {
  display: flex;
  margin-top: 5px;
}
.card-ver {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-left: 10px;
  background-color: #e0e7ff;
  border-radius: 8px;
  width: 118px;
  cursor: pointer;
}
.card-postular {
  background-color: #5627ff;
  height: 100%;
}
.card-postular p {
  color: white;
  font-weight: bold;
}
.set-favorite {
  cursor: pointer;
}
.set-favorite img {
  height: 40px;
  width: 40px;
}
</style>
