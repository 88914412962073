<template>
  <section class="performance-donut">
    <p
      class="campus-detail__no-info pb-5"
      v-if="!thereAreStats"
      v-t="'campus-details.school_info'"
    />
    <div v-else>
      <div class="option__radio--button px-0 mx-0">
        <input
          :disabled="!thereAreStatsCategory.math"
          class="mr-1"
          type="radio"
          :id="`radioMath${number}`"
          v-model="showStat"
          value="math"
        />
        <label class="mr-1" :for="`radioMath${number}`" v-t="'campus-details.test.math'" />

        <input
          :disabled="!thereAreStatsCategory.language"
          type="radio"
          :id="`radioLanguage${number}`"
          v-model="showStat"
          value="language"
        />
        <label :for="`radioLanguage${number}`" v-t="'campus-details.test.language'" />
      </div>
      <div class="d-flex px-5 mt-4">
        <picture>
          <img width="11px" src="@/assets/iconsChile/campusDetail/arrow-down.svg" />
        </picture>
        <div class="ml-4 mt-2">
          <h1 class="performance-donut__total">{{ stat[showStat].total }}</h1>
          <h2 class="performance-donut__students mt-3" v-t="'campus-details.test.students'" />
          <h2 class="performance-donut__porcentage mt-1" v-t="'campus-details.test.porcentage'" />
        </div>
      </div>

      <vc-donut
        class="mt-5 mb-7"
        background="white"
        foreground="grey"
        :size="190"
        unit="px"
        :thickness="50"
        :sections="sectionsDonut[showStat]"
        :total="100"
        :start-angle="0"
        :auto-adjust-text-size="true"
      >
      </vc-donut>

      <div class="mt-2 ml-1 mb-8 d-flex flex-column justify-center align-center">
        <p class="d-inline-block performance__results performance--bg-advanced">
          <span
            v-t="{
              path: 'campus-details.test.students_level',
              args: { porcentage: stat[showStat].advanced.toFixed(1) },
            }"
          />
          <span class="performance--bold" v-t="'campus-details.test.advanced'" />
        </p>

        <p class="d-inline-block performance__results performance--bg-satisfactory">
          <span
            v-t="{
              path: 'campus-details.test.students_level',
              args: { porcentage: stat[showStat].satisfactory.toFixed(1) },
            }"
          />
          <span class="performance--bold" v-t="'campus-details.test.satisfactory'" />
        </p>

        <p class="d-inline-block performance__results performance--bg-minimum">
          <span
            v-t="{
              path: 'campus-details.test.students_level',
              args: { porcentage: stat[showStat].minimum.toFixed(1) },
            }"
          />
          <span class="performance--bold" v-t="'campus-details.test.minimum'" />
        </p>

        <p class="d-inline-block performance__results performance--bg-insufficient">
          <span
            v-t="{
              path: 'campus-details.test.students_level',
              args: { porcentage: stat[showStat].insufficient.toFixed(1) },
            }"
          />
          <span class="performance--bold" v-t="'campus-details.test.insufficient'" />
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PerformanceChartDonut',
  data() {
    return {
      showStat: 'math',
      stat: {
        math: {},
        language: {},
      },
      sectionsDonut: {},
    };
  },
  props: {
    stats: {
      type: Array,
    },
    number: {
      type: Number,
    },
  },
  mounted() {
    this.showStat = this.thereAreStatsCategory.math ? 'math' : 'language';

    this.stats.forEach((stat) => {
      this.formatStat(stat);
    });
    this.sectionsDonut = {
      math: [
        { value: this.stat.math.advanced, color: '#22ADE9' },
        { value: this.stat.math.satisfactory, color: '#F29FCC' },
        { value: this.stat.math.minimum, color: '#D57BFF' },
        { value: this.stat.math.insufficient, color: '#929697' },
      ],
      language: [
        { value: this.stat.language.advanced, color: ' #22ADE9' },
        { value: this.stat.language.satisfactory, color: '#F29FCC' },
        { value: this.stat.language.minimum, color: '#D57BFF' },
        { value: this.stat.language.insufficient, color: '#929697' },
      ],
    };
  },
  methods: {
    formatStat(stat) {
      this.stat[stat.area_name === 'Matemáticas' ? 'math' : 'language'] = {
        ...this.calcStat(stat.results),
        total: stat.total_evaluated,
      };
    },
    calcStat(results) {
      const calc = {
        insufficient: 0,
        minimum: 0,
        satisfactory: 0,
        advanced: 0,
      };

      results.forEach((result) => {
        switch (result.level) {
          case 'Insuficiente':
            calc.insufficient = this.porcentage(result.value);
            break;

          case 'Minimo':
            calc.minimum = this.porcentage(result.value);
            break;

          case 'Satisfactorio':
            calc.satisfactory = this.porcentage(result.value);
            break;

          case 'Avanzado':
            calc.advanced = this.porcentage(result.value);
            break;

          default:
            break;
        }
      });

      return calc;
    },
    porcentage(number) {
      return number * 100;
    },
  },
  computed: {
    thereAreStats() {
      if (
        (this.stats[0].total_evaluated === 0 && this.stats[1].total_evaluated === 0) ||
        (this.stats[0].total_evaluated === null && this.stats[1].total_evaluated === null)
      ) {
        return false;
      }

      return true;
    },
    thereAreStatsCategory() {
      const categoryEnable = { math: false, language: false };
      this.stats.forEach((stat) => {
        if (stat.area_name === 'Matemáticas') {
          categoryEnable.math =
            stat.total_evaluated === 0 || stat.total_evaluated === null
              ? categoryEnable.math
              : true;
        } else if (stat.area_name === 'Lenguaje') {
          categoryEnable.language =
            stat.total_evaluated === 0 || stat.total_evaluated === null
              ? categoryEnable.language
              : true;
        }
      });
      return categoryEnable;
    },
  },
};
</script>
